import { Button, Col, Form, Icon, message, Modal, Row, Spin, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import CustomTextEditor from './CustomTextEditor'
import FormKnowledgeError from './FormKnowledgeError'
import { FormComponentProps } from 'antd/lib/form'
import { AnalysisProblems, Problem } from '../../../Model'
import { getAllAnalysis, updateAnalysis } from '../../../service'
import { UploadFile } from 'antd/lib/upload/interface'
import FSS from '../../../../../file-server-storage'
import { UploadLink } from '../../../../../knowledge-management/knowledge/uploads'
import { checkRolesState, RoleType } from '../../../../../authorization-module/permissions'
import { v4 as uuid } from 'uuid'

interface Params {
    problem?: Problem
    isRenderPTask: boolean
}

type Props = FormComponentProps & Params
const AnalysisList: React.FC<Props> = (props: Props) => {
    const [isVisibleModal, setIsVisibleModal] = useState(false)
    const [dataCauseNote, setDataCauseNote] = useState<string>()
    const [dataWorkaround, setDataWorkaround] = useState<string>()
    const [dataFixNotes, setDataFixNotes] = useState<string>()
    const [dataCauseNoteForInit, setDataCauseNoteForInit] = useState<string>()
    const [dataWorkaroundForInit, setDataWorkaroundForInit] = useState<string>()
    const [dataFixNotesForInit, setDataFixNotesForInit] = useState<string>()
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [fileListForInit, setFileListForInit] = useState<UploadFile[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [dataAnalysis, setDataAnalysis] = useState<AnalysisProblems>()
    const [loadingData, setLoadingData] = useState(false)

    useEffect(() => {
        if (props.problem && props.problem.id) {
            setLoadingData(true)
            getAllAnalysis(props.problem.id?.toString(), props.isRenderPTask).then((analysis: AnalysisProblems) => {
                setDataAnalysis(analysis)
                setDataCauseNoteForInit(analysis.causeNote)
                setDataWorkaroundForInit(analysis.workaround)
                setDataFixNotesForInit(analysis.fixNote)
                const convertModel = analysis.pbAnalysisUpload?.map((it) => {
                    it.uid = uuid().slice(0, 4)
                    return it
                })
                if (convertModel) {
                    setFileList(convertModel)
                    setFileListForInit(convertModel)
                }
            }).finally(() => {
                setLoadingData(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.problem])

    const handleSubmit = (e: any): void => {
        e.preventDefault()
        props.form.validateFields(async (err: any, values) => {
            if (!err) {
                const CheckValueDataCauseNote = (dataCauseNote)?.replace(/<[^>]*>/g, '')
                const CheckValueDataWorkaround = (dataWorkaround)?.replace(/<[^>]*>/g, '')
                const CheckValuedataFixNotes = (dataFixNotes)?.replace(/<[^>]*>/g, '')
                setIsSubmitted(true)
                if (CheckValueDataCauseNote?.trim() !== '' && CheckValueDataWorkaround?.trim() !== '' && CheckValuedataFixNotes?.trim() !== '') {
                    if ((CheckValueDataCauseNote?.trim() || '')?.length > 2 && (CheckValuedataFixNotes?.trim() || '')?.length > 2) {
                        setIsLoading(true)
                        const convertData: AnalysisProblems = {
                            causeNote: dataCauseNote || '',
                            fixNote: dataFixNotes || '',
                            workaround: dataWorkaround,
                            problemId: props.problem?.id!!
                        }
                        setDataCauseNoteForInit(dataCauseNote)
                        setDataWorkaroundForInit(dataWorkaround)
                        setDataFixNotesForInit(dataFixNotes)
                        console.log(convertData)
                        checkUploadSaveAnalysisProblems(convertData)
                    }
                }
            }
        })
    }

    const checkUploadSaveAnalysisProblems = (convertData: AnalysisProblems) => {
        if (fileList.length > 0 && fileList.length <= 5) {
            FSS.putFile(fileList, 'problem', (uploadLink: UploadLink[]) => {
                convertData.pbAnalysisUpload = uploadLink?.map((data) => {
                    return {
                        name: data.name,
                        url: data.url,
                        type: data.type
                    } as UploadFile
                })
                setFileListForInit(fileList)
                updateAnalysis(dataAnalysis?.id?.toString()!!, convertData, props.isRenderPTask).then(() => {
                    message.success('The data has been saved successfully.')
                }).catch((err) => {
                    message.error(`Unable to save data. Please check and try again. ${err?.data?.message}`)
                }).finally(() => {
                    setIsLoading(false)
                })
            })
        } else if (fileList.length > 5) {
            setIsLoading(false)
            message.error('Upload File Max 5')
        } else {
            updateAnalysis(dataAnalysis?.id?.toString()!!, convertData, props.isRenderPTask).then(() => {
                message.success('The data has been saved successfully.')
            }).catch((err) => {
                message.error(`Unable to save data. Please check and try again. ${err?.data?.message}`)
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }

    const checkDisableCreateRootCauseAnalysis = () => {
        let result: boolean = false
        const listOpem = ['New', 'Assess', 'Root Cause Analysis']
        if (props.problem?.pbStatus.status) {
            if (listOpem.includes(props.problem?.pbStatus.status)) {
                result = false
            } else {
                result = true
            }
        } else {
            result = false
        }
        return result
    }

    const getDataCausenote = (data) => {
        setDataCauseNote(data)
    }

    const getDataWorkaround = (data) => {
        setDataWorkaround(data)
    }

    const getDataFixNotes = (data) => {
        setDataFixNotes(data)
    }

    const getUploadFile = (data) => {
        setFileList(data)
    }

    const closeModalConfirm = () => {
        Modal.confirm({
            title: <b style={{ color: '#323276', fontSize: '25px', fontWeight: 'bold' }}>Are you sure?</b>,
            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>Do you want to leave this page ?</span>,
            okText: 'Yes',
            cancelText: 'No',
            okType: 'danger',
            autoFocusButton: 'cancel',
            className: 'custom-modal-wo',
            onOk() {
                setIsVisibleModal(false)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    return (
        <div>
            <Form>
                <Spin spinning={loadingData}>
                    <div className='display-scrollbar-height500' style={{ paddingRight: 10 }}>
                        <Row>
                            <Col span={12}>
                                <div><p style={{ fontSize: '20px' }}><b>Analysis/Solution</b></p></div>
                            </Col>
                            <Col span={12} style={{ textAlign: 'end' }}>
                                <Button type='primary' onClick={() => setIsVisibleModal(true)} disabled={!checkRolesState(RoleType.Problem, 'UpdateProblem')}> <Icon type="plus" /> Create Knowledge Error</Button>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={24}>
                                <CustomTextEditor label='Cause note' isUploadFile={false} isrequired={true} getData={getDataCausenote} isSubmit={isSubmitted} displayIndex={0} intializeData={dataAnalysis?.causeNote} disable={checkDisableCreateRootCauseAnalysis()} />
                            </Col>
                            <Col span={24}>
                                <CustomTextEditor label='Workaround' isUploadFile={false} isrequired={false} getData={getDataWorkaround} isSubmit={isSubmitted} displayIndex={1} intializeData={dataAnalysis?.workaround} disable={checkDisableCreateRootCauseAnalysis()} />
                            </Col>
                            <Col span={24}>
                                <CustomTextEditor label='Fix notes' isUploadFile={true} isrequired={true} getData={getDataFixNotes} isSubmit={isSubmitted} displayIndex={2} getUploadFile={getUploadFile} intializeData={dataAnalysis?.fixNote} disable={checkDisableCreateRootCauseAnalysis()} fileList={fileList} />
                            </Col>
                        </Row>
                    </div>
                    <hr />
                    <Row>
                        <Col span={24} style={{ textAlign: 'end' }}>
                            <Button type='primary' onClick={handleSubmit} loading={isLoading} disabled={!checkRolesState(RoleType.Problem, 'UpdateProblem') || props.problem?.pbStatus.status === 'Closed'}> Save </Button>
                        </Col>
                    </Row>
                </Spin>
            </Form>
            <Modal
                title={<div><b>Create Knowledge</b> <Tag>My Note</Tag></div>}
                visible={isVisibleModal}
                onCancel={closeModalConfirm}
                footer={null}
                width={'70%'}
                maskClosable={false}
            >
                <FormKnowledgeError problem={props.problem} setIsVisibleModal={setIsVisibleModal} checkUploadSaveAnalysisProblems={checkUploadSaveAnalysisProblems} initCauseNote={dataCauseNoteForInit} initWorkaround={dataWorkaroundForInit} initFixNotes={dataFixNotesForInit} fileList={fileListForInit} closeModal={closeModalConfirm} isVisibleModal={isVisibleModal} />
            </Modal>
        </div>
    )
}

const AnalysisListWrapped = Form.create<Props>({ name: 'AnalysisListWrapped' })(AnalysisList)
export default AnalysisListWrapped
