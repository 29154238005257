import { Button, Card, Col, Form, Row, Spin, Steps, message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import SyncPMStep1Form from './SyncPMStep1Form'
import SyncPMStep2Form from './SyncPMStep2Form'
import { Asset, AssetGroupType, PreventiveWithAsset, pmUploadLink } from '../../Model'
import { SelectedImageAssetContext } from './ContextAPI/SelectedImageAsset'
import { SelectedImagePMContext } from './ContextAPI/SelectedImagePM'
import { AssetOwnerLocation, updateAssetOwnerLocation } from '../../../msp-wo/asset-management'
import { FormComponentProps } from 'antd/lib/form'
import { PeopleContext } from '../../ContextAPI/UserHRContext'
import { FieldDetail } from '../../../incident-management/incident'
import { People } from '../../../authorization-module/people/model'
import moment from 'moment'
import SyncPMStep3Form from './SyncPMStep3Form'
import { AssetGroupContext } from '../../ContextAPI/AssetGroupContext'
import { SetNoneAssetDetailFieldListContext } from './ContextAPI/SetNoneAssetDetailFieldList'
import { SetNoneCustomFieldListContext } from './ContextAPI/SetNoneCustomFieldList'
import { getAllUsersByUserId } from '../../../authorization-module/user-role/duck/action'

interface Param {
    dataPM?: PreventiveWithAsset
    isVisibleModalSyncdata: boolean
    setIsVisibleModalSyncdata: Function
    form?: any
    dataOriginalAsset?: Asset
}

type Props = Param & FormComponentProps

const { Step } = Steps
const steps = [
    {
        title: 'Choose images',
        content: 'First-content'
    },
    {
        title: 'Verify asset data',
        content: 'Second-content'
    },
    {
        title: 'Confirm to sync asset',
        content: 'Second-content'
    }
]

const SyncDataPMToAsset: React.FC<Props> = (props: Props) => {
    const { validateFields, getFieldValue } = props.form
    const [currentStep, setCurrentStep] = useState<number>(0)
    const { SelectedImageAssetState, GetSelectedImageAsset } = useContext<any>(SelectedImageAssetContext)
    const { SelectedImagePMState, GetSelectedImagePM } = useContext<any>(SelectedImagePMContext)
    const [dataOriginalAsset, setdataOriginalAsset] = useState<Asset>()
    const [isCheckedSyncData, setIsCheckedSyncData] = useState<boolean>(false)
    const [isSendingData, setIsSendingData] = useState<boolean>(false)
    const { PeopleState, GetPeople } = useContext<any>(PeopleContext)
    const [isLoading] = useState<boolean>(false)
    const [tempDataPM, setTempDataPM] = useState<PreventiveWithAsset>()
    const [adjustField, setAdjustField] = useState<Asset>()
    const { GetAssetGroup, AssetGroupState } = useContext<any>(AssetGroupContext)
    const { SetNoneAssetDetailFieldListState, GetSetNoneAssetDetailFieldList } = useContext<any>(SetNoneAssetDetailFieldListContext)
    const { SetNoneCustomFieldListState, GetSetNoneCustomFieldList } = useContext<any>(SetNoneCustomFieldListContext)

    useEffect(() => {
        if (props.isVisibleModalSyncdata) {
            setCurrentStep(0)
            GetSelectedImageAsset([])
            GetSelectedImagePM([])
            setTempDataPM(props.dataPM)
            const temptype = AssetGroupState.filter((it) => it.assetGroup.id === props.dataPM?.asset.assetGroup?.id)
            GetAssetGroup(temptype)
            const tempAdjust: Asset = { ...props.dataPM?.preventiveMaintenance.pmAdjust?.pmAdjustData!! }
            // eslint-disable-next-line no-unused-expressions
            tempAdjust?.customField?.map((it) => {
                // eslint-disable-next-line no-return-assign
                return it.value = it.valueAdjust
            })
            setAdjustField(tempAdjust)
            getAllUsersByUserId(props.dataPM?.preventiveMaintenance.pmAdjust?.pmAdjustData?.people?.employeeId || props.dataPM?.asset.people?.employeeId || '').then((res) => {
                GetPeople(res)
            })
        } else {
            setAdjustField(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isVisibleModalSyncdata])

    useEffect(() => {
        if (props.dataOriginalAsset) {
            setdataOriginalAsset(props.dataOriginalAsset)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataOriginalAsset])

    const nextStep = () => {
        setCurrentStep(currentStep + 1)
    }

    const nextStepAndValidate = () => {
        validateFields(async (err, values) => {
            if (!err) {
                const assetGroups = sessionStorage.getItem('assetGroup')
                let findAssetGroupType: AssetGroupType | undefined
                if (assetGroups) {
                    const asset = JSON.parse(assetGroups)
                    if (asset) {
                        findAssetGroupType = asset.find((data: AssetGroupType) => { return data.type === values.TypeAdSync })
                    }
                }
                let findPeople: People | undefined
                if (PeopleState) {
                    findPeople = PeopleState.find((data: People) => { return data.employeeId === values.User_IDAdSync })
                }
                const fieldDetails: FieldDetail[] = (dataOriginalAsset?.customField || []).map((obj: FieldDetail, idx) => {
                    let value: string | undefined = ''
                    if (obj.name) {
                        if (obj.inputType === 'Checkbox' && Array.isArray(getFieldValue(obj.name + 'AdSync'))) {
                            value = SetNoneCustomFieldListState.includes(obj.name) ? null : (getFieldValue(obj.name + 'AdSync') || []).join('|') || obj.value
                        } else if (obj.inputType === 'DatePicker' && props.form.getFieldValue(obj.name)) {
                            value = SetNoneCustomFieldListState.includes(obj.name) ? null : moment(getFieldValue(obj.name + 'AdSync')).format('YYYY-MM-DD') || obj.value
                        } else {
                            if (getFieldValue(obj.name + 'AdSync') && typeof getFieldValue(obj.name + 'AdSync') === 'string') {
                                value = SetNoneCustomFieldListState.includes(obj.name) ? null : getFieldValue(obj.name + 'AdSync')?.replace(/ +/g, ' ')?.trim() || obj.value
                            } else {
                                value = SetNoneCustomFieldListState.includes(obj.name) ? null : getFieldValue(obj.name + 'AdSync') || obj.value
                            }
                        }
                    }
                    const fieldDetail: FieldDetail = {
                        id: obj.id,
                        name: obj.name,
                        value: obj.name ? value : undefined,
                        label: obj.label,
                        description: obj.description,
                        inputType: obj.inputType,
                        dataType: obj.dataType,
                        selectOption: obj.selectOption,
                        defaultValue: obj.defaultValue,
                        placeholder: obj.placeholder,
                        isRequireFields: Boolean(obj.isRequireFields),
                        assetGroup: obj.assetGroup,
                        headerGroup: obj.headerGroup,
                        isShowPm: obj.isShowPm
                    }
                    return fieldDetail
                })
                const dataAssetModel: Asset = {
                    id: props?.dataPM?.asset?.id || undefined,
                    assetTag: SetNoneAssetDetailFieldListState.includes('Asset_Tag') ? null : values.Asset_TagAdSync || dataOriginalAsset?.assetTag,
                    serialNumber: SetNoneAssetDetailFieldListState.includes('Serial_Number') ? null : values.Serial_NumberAdSync || dataOriginalAsset?.serialNumber,
                    assetName: SetNoneAssetDetailFieldListState.includes('Asset_Name') ? null : values.Asset_NameAdSync || dataOriginalAsset?.assetName,
                    manufacturer: SetNoneAssetDetailFieldListState.includes('Manufacturer') ? null : values.ManufacturerAdSync || dataOriginalAsset?.manufacturer,
                    model: SetNoneAssetDetailFieldListState.includes('Model') ? null : values.ModelAdSync || dataOriginalAsset?.model,
                    status: values.Asset_StatusAdSync || dataOriginalAsset?.status,
                    brand: SetNoneAssetDetailFieldListState.includes('Brand') ? null : values.BrandAdSync || dataOriginalAsset?.brand,
                    assetGroupType: SetNoneAssetDetailFieldListState.includes('Type') ? null : findAssetGroupType || dataOriginalAsset?.assetGroupType,
                    site: SetNoneAssetDetailFieldListState.includes('Site') ? null : values.SiteAdSync || dataOriginalAsset?.site,
                    location: SetNoneAssetDetailFieldListState.includes('Site') ? null : values.SiteAdSync ? values.LocationAdSync : dataOriginalAsset?.location,
                    building: SetNoneAssetDetailFieldListState.includes('Site') ? null : values.SiteAdSync ? values.BuildingAdSync : dataOriginalAsset?.building,
                    floor: SetNoneAssetDetailFieldListState.includes('Site') ? null : values.SiteAdSync ? values.FloorAdSync : dataOriginalAsset?.floor,
                    zone: SetNoneAssetDetailFieldListState.includes('Zone') ? null : values.ZoneAdSync || dataOriginalAsset?.zone,
                    // employeeName: values.User_NameAd,
                    people: SetNoneAssetDetailFieldListState.includes('User_ID') ? null : values.User_IDAdSync ? findPeople ? {
                        id: findPeople.id,
                        employeeId: findPeople.employeeId,
                        firstName: findPeople.firstName,
                        lastName: findPeople.lastName,
                        department: { departmentName: findPeople.departmentName }
                    } as any : null : dataOriginalAsset?.people,
                    // ownerId: values.Owner_IDAd,
                    // ownerName: values.Owner_NameAd,
                    // supervisorName: values.Supervisor_NameAd,
                    // supervisorId: values.Supervisor_IDAd,
                    // department: values.DepartmentAd,
                    // assetLoanId: values.Loan_IDAd,
                    // peripheralSet: values.SETAd,
                    pairedItAsset: SetNoneAssetDetailFieldListState.includes('Paired_IT_Asset') ? null : values.Paired_IT_AssetAdSync || dataOriginalAsset?.pairedItAsset,
                    customField: fieldDetails
                }
                delete dataAssetModel?.createdDatetime
                delete dataAssetModel?.assetGroup
                setAdjustField(dataAssetModel)
                setCurrentStep(currentStep + 1)
            }
        })
    }

    const onFinish = (e: any) => {
        e.preventDefault()
        validateFields(async (err, values) => {
            if (!err) {
                let findPeople: People | undefined
                if (PeopleState) {
                    findPeople = PeopleState.find((data: People) => { return data.employeeId === values.User_IDCompleteSync })
                }
                const assetGroups = sessionStorage.getItem('assetGroup')
                let findAssetGroupType: AssetGroupType | undefined
                if (assetGroups) {
                    const asset = JSON.parse(assetGroups)
                    if (asset) {
                        findAssetGroupType = asset.find((data: AssetGroupType) => { return data.type === values.TypeCompleteSync })
                    }
                }

                const fieldDetails: FieldDetail[] = (dataOriginalAsset?.customField || []).map((obj: FieldDetail) => {
                    let value = ''
                    if (obj.name) {
                        if (obj.inputType === 'Checkbox' && Array.isArray(getFieldValue(obj.name + 'CompleteSync'))) {
                            value = (getFieldValue(obj.name + 'CompleteSync') || []).join('|')
                        } else if (obj.inputType === 'DatePicker' && props.form.getFieldValue(obj.name + 'CompleteSync')) {
                            value = moment(getFieldValue(obj.name + 'CompleteSync')).format('YYYY-MM-DD')
                        } else {
                            if (getFieldValue(obj.name + 'CompleteSync') && typeof getFieldValue(obj.name + 'CompleteSync') === 'string') {
                                value = getFieldValue(obj.name + 'CompleteSync')?.replace(/ +/g, ' ')?.trim()
                            } else {
                                value = getFieldValue(obj.name + 'CompleteSync')
                            }
                        }
                    }
                    const fieldDetail: FieldDetail = {
                        id: obj.id,
                        name: obj.name,
                        value: obj.name ? value : undefined,
                        label: obj.label,
                        description: obj.description,
                        inputType: obj.inputType,
                        dataType: obj.dataType,
                        selectOption: obj.selectOption,
                        defaultValue: obj.defaultValue,
                        placeholder: obj.placeholder,
                        isRequireFields: Boolean(obj.isRequireFields),
                        assetGroup: obj.assetGroup,
                        headerGroup: obj.headerGroup,
                        isShowPm: obj.isShowPm
                    }
                    return fieldDetail
                })
                // const dataAssetModel: Asset = {
                //     assetTag: values.Asset_TagCompleteSync || dataOriginalAsset?.assetTag,
                //     serialNumber: values.Serial_NumberCompleteSync || dataOriginalAsset?.serialNumber,
                //     assetName: values.Asset_NameCompleteSync || dataOriginalAsset?.assetName,
                //     manufacturer: values.ManufacturerCompleteSync || dataOriginalAsset?.manufacturer,
                //     model: values.ModelCompleteSync || dataOriginalAsset?.model,
                //     status: values.Asset_StatusCompleteSync || dataOriginalAsset?.status,
                //     brand: values.BrandCompleteSync || dataOriginalAsset?.brand,
                //     assetGroupType: findAssetGroupType || dataOriginalAsset?.assetGroupType,
                //     site: values.SiteCompleteSync || dataOriginalAsset?.site,
                //     location: values.LocationCompleteSync || dataOriginalAsset?.location,
                //     building: values.BuildingCompleteSync || dataOriginalAsset?.building,
                //     floor: values.FloorCompleteSync || dataOriginalAsset?.floor,
                //     zone: values.ZoneCompleteSync || dataOriginalAsset?.zone,
                //     // employeeName: values.User_NameAd,
                //     people: findPeople ? {
                //         id: findPeople.id,
                //         employeeId: findPeople.employeeId,
                //         firstName: findPeople.firstName,
                //         lastName: findPeople.lastName,
                //         department: { departmentName: findPeople.departmentName }
                //     } as any : undefined,
                //     // ownerId: values.Owner_IDAd,
                //     // ownerName: values.Owner_NameAd,
                //     // supervisorName: values.Supervisor_NameAd,
                //     // supervisorId: values.Supervisor_IDAd,
                //     // department: values.DepartmentAd,
                //     // assetLoanId: values.Loan_IDAd,
                //     // peripheralSet: values.SETAd,
                //     pairedItAsset: values.Paired_IT_AssetCompleteSync || dataOriginalAsset?.pairedItAsset,
                //     customField: fieldDetails
                // }
                const dataAssetModel: Asset = {
                    assetTag: values.Asset_TagCompleteSync,
                    serialNumber: values.Serial_NumberCompleteSync,
                    assetName: values.Asset_NameCompleteSync,
                    manufacturer: values.ManufacturerCompleteSync,
                    model: values.ModelCompleteSync,
                    status: values.Asset_StatusCompleteSync,
                    brand: values.BrandCompleteSync,
                    assetGroupType: findAssetGroupType || null,
                    site: values.SiteCompleteSync,
                    location: values.LocationCompleteSync,
                    building: values.BuildingCompleteSync,
                    floor: values.FloorCompleteSync,
                    zone: values.ZoneCompleteSync,
                    // employeeName: values.User_NameAd,
                    people: findPeople ? {
                        id: findPeople.id
                        // employeeId: findPeople.employeeId,
                        // firstName: findPeople.firstName,
                        // lastName: findPeople.lastName,
                        // department: { departmentName: findPeople.departmentName }
                    } as any : null,
                    // ownerId: values.Owner_IDAd,
                    // ownerName: values.Owner_NameAd,
                    // supervisorName: values.Supervisor_NameAd,
                    // supervisorId: values.Supervisor_IDAd,
                    // department: values.DepartmentAd,
                    // assetLoanId: values.Loan_IDAd,
                    // peripheralSet: values.SETAd,
                    pairedItAsset: values.Paired_IT_AssetCompleteSync,
                    customField: fieldDetails
                }
                delete dataAssetModel?.createdDatetime
                delete dataAssetModel?.assetGroup
                const tempImgAttach = dataOriginalAsset?.attachFile ? JSON.parse(dataOriginalAsset?.attachFile) : []
                const filterData = tempImgAttach.filter((it: pmUploadLink) => SelectedImageAssetState?.includes(it.url!!))
                if (props.dataPM?.preventiveMaintenance.pmUploadLink) {
                    filterData.push(...props.dataPM.preventiveMaintenance.pmUploadLink?.filter((it: pmUploadLink) => SelectedImagePMState?.includes(it.url!!)))
                }
                dataAssetModel.attachFile = JSON.stringify(filterData)
                dataAssetModel.syncDataFromPMId = props.dataPM?.preventiveMaintenance.id
                setIsSendingData(true)
                updateAssetOwnerLocation(String(props.dataPM?.asset.id!!), dataAssetModel as unknown as AssetOwnerLocation).then((res) => {
                    if (res) {
                        message.success('The data has been updated successfully.')
                    }
                }).catch(() => {
                    message.error('Unable to save data. Please check and try again.')
                }).finally(() => {
                    props.setIsVisibleModalSyncdata(false)
                    setIsSendingData(false)
                    GetSetNoneAssetDetailFieldList([])
                    GetSetNoneCustomFieldList([])
                })
            }
        })
    }

    const handleBackStep = () => {
        // if (currentStep !== 0) {
        //     handleSetDefaultAdjustFields()
        // }
        if (currentStep === 1) {
            // eslint-disable-next-line handle-callback-err
            validateFields(async (err, values) => {
                const assetGroups = sessionStorage.getItem('assetGroup')
                let findAssetGroupType: AssetGroupType | undefined
                if (assetGroups) {
                    const asset = JSON.parse(assetGroups)
                    if (asset) {
                        findAssetGroupType = asset.find((data: AssetGroupType) => { return data.type === values.TypeAdSync })
                    }
                }
                let findPeople: People | undefined
                if (PeopleState) {
                    findPeople = PeopleState.find((data: People) => { return data.employeeId === values.User_IDAdSync })
                }
                const fieldDetails: FieldDetail[] = (dataOriginalAsset?.customField || []).map((obj: FieldDetail) => {
                    let value = ''
                    if (obj.name) {
                        if (obj.inputType === 'Checkbox' && Array.isArray(getFieldValue(obj.name + 'AdSync'))) {
                            value = (getFieldValue(obj.name + 'AdSync') || []).join('|')
                        } else if (obj.inputType === 'DatePicker' && props.form.getFieldValue(obj.name)) {
                            value = moment(getFieldValue(obj.name + 'AdSync')).format('YYYY-MM-DD')
                        } else {
                            if (getFieldValue(obj.name + 'AdSync') && typeof getFieldValue(obj.name + 'AdSync') === 'string') {
                                value = getFieldValue(obj.name + 'AdSync')?.replace(/ +/g, ' ')?.trim()
                            } else {
                                value = getFieldValue(obj.name + 'AdSync')
                            }
                        }
                    }
                    const fieldDetail: FieldDetail = {
                        id: obj.id,
                        name: obj.name,
                        value: obj.name ? value : undefined,
                        label: obj.label,
                        description: obj.description,
                        inputType: obj.inputType,
                        dataType: obj.dataType,
                        selectOption: obj.selectOption,
                        defaultValue: obj.defaultValue,
                        placeholder: obj.placeholder,
                        isRequireFields: Boolean(obj.isRequireFields),
                        assetGroup: obj.assetGroup,
                        headerGroup: obj.headerGroup,
                        isShowPm: obj.isShowPm
                    }
                    return fieldDetail
                })
                const dataAssetModel: Asset = {
                    assetTag: values.Asset_TagAdSync,
                    serialNumber: values.Serial_NumberAdSync,
                    assetName: values.Asset_NameAdSync,
                    manufacturer: values.ManufacturerAdSync,
                    model: values.ModelAdSync,
                    status: values.Asset_StatusAdSync,
                    brand: values.BrandAdSync,
                    assetGroupType: findAssetGroupType,
                    site: values.SiteAdSync,
                    location: values.LocationAdSync,
                    building: values.BuildingAdSync,
                    floor: values.FloorAdSync,
                    zone: values.ZoneAdSync,
                    // employeeName: values.User_NameAd,
                    people: findPeople ? {
                        id: findPeople.id,
                        employeeId: findPeople.employeeId,
                        firstName: findPeople.firstName,
                        lastName: findPeople.lastName,
                        department: { departmentName: findPeople.departmentName }
                    } as any : undefined,
                    // ownerId: values.Owner_IDAd,
                    // ownerName: values.Owner_NameAd,
                    // supervisorName: values.Supervisor_NameAd,
                    // supervisorId: values.Supervisor_IDAd,
                    // department: values.DepartmentAd,
                    // assetLoanId: values.Loan_IDAd,
                    // peripheralSet: values.SETAd,
                    pairedItAsset: values.Paired_IT_AssetAdSync,
                    customField: fieldDetails
                }
                delete dataAssetModel?.createdDatetime
                delete dataAssetModel?.assetGroup
                setAdjustField(dataAssetModel)
                setCurrentStep(currentStep + 1)
            })
        }
        setCurrentStep(currentStep - 1)
    }

    // const handleSetDefaultAdjustFields = () => {
    //     if (adjustField && dataOriginalAsset) {
    //         for (let i = 0; i <= Object.keys(adjustField).length; i++) {
    //             const tempkey = Object.keys(adjustField)[i]
    //             if (tempkey !== 'customField' && tempkey !== 'people') {
    //                 if (adjustField[tempkey] === dataOriginalAsset[tempkey]) {
    //                     adjustField[tempkey] = undefined
    //                 }
    //             } else if (tempkey === 'people') {
    //                 if (adjustField.people?.employeeId === dataOriginalAsset.people?.employeeId) {
    //                     adjustField.people = undefined
    //                 }
    //             } else if (tempkey === 'customField') {
    //                 const tempcustomFields = adjustField.customField?.map((it, idx) => {
    //                     if (props.dataPM?.preventiveMaintenance.pmAdjust?.pmAdjustData?.customField?.[idx]?.value === it.value) {
    //                         it.value = undefined
    //                     }
    //                     return it
    //                 })
    //                 adjustField.customField = tempcustomFields
    //             }
    //         }
    //     }
    // }

    return (
        <div>
            <Spin spinning={isLoading}>
                <Form
                    name="SyncDataPM"
                    layout={'vertical'}
                    onSubmit={onFinish}
                >
                    {/* <p className="main-title-orange" >Sync Image to Asset</p>
                    <p>Note: [Step 1 : Choose images] A maximum of 20 images can be selected and the selected images will be displayed in the original asset.</p>
                    <p style={{marginLeft: 37}}>[Step 2 : Verify asset data] Set none will set fields that you checked to none. If you don't checked it will be replace by original asset details.</p> */}

                    <Card>
                        <Row>
                            <p style={{ fontWeight: 'bold' }}>{props.dataPM?.asset.serialNumber} / {props.dataPM?.asset.assetGroup?.name}</p>
                            <div style={{ color: '#757F8D' }}>
                                {currentStep === 0 ? <p>Note: You can choose up to 20 images, and the selected images will be displayed in the original asset.</p> : null}
                                {currentStep === 1 ? <p>Note: Selecting &quot;Set None &quot; will clear the fields you checked. If you do not check it, the fields will be replaced with the original asset details.</p> : null}
                                {currentStep === 2 ? <p>Note: Verify the data carefully, then select to confirm the data synchronization.</p> : null}
                            </div>
                            <Col md={2} lg={4} />
                            <Col md={20} lg={16} style={{ margin: '20px 0px' }}>
                                <Steps current={currentStep}>
                                    {steps.map(item => (
                                        <Step key={item.title} title={item.title} />
                                    ))}
                                </Steps>
                            </Col>
                            <Col md={2} lg={4} />
                        </Row>
                        <div>
                            {currentStep === 0 ? <SyncPMStep1Form dataPM={props.dataPM?.preventiveMaintenance} currentStep={currentStep} setCurrentStep={setCurrentStep} dataOriginalAsset={dataOriginalAsset} /> : null}
                            {currentStep === 1 ? <SyncPMStep2Form dataOriginalAsset={dataOriginalAsset} currentStep={currentStep} isCheckedSyncData={isCheckedSyncData} setIsCheckedSyncData={setIsCheckedSyncData} dataPM={tempDataPM} form={props.form} adjustField={adjustField} /> : null}
                            {currentStep === 2 ? <SyncPMStep3Form dataOriginalAsset={dataOriginalAsset} currentStep={currentStep} isCheckedSyncData={isCheckedSyncData} setIsCheckedSyncData={setIsCheckedSyncData} dataPM={props.dataPM} form={props.form} completeField={adjustField} /> : null}
                        </div>
                        <br />
                        <Row>
                            {currentStep === 0
                                ? <Row>
                                    <Col span={20} />
                                    <Col span={2} >
                                        <Button style={{ float: 'right' }} type='primary' onClick={nextStep} loading={isLoading}>
                                            Next
                                        </Button >
                                    </Col>
                                    <Col span={2} />
                                </Row> : null}
                            {currentStep === 1
                                ? <Row>
                                    <Form.Item>
                                        <Col span={18} />
                                        <Col span={4} style={{ display: 'flex', justifyContent: 'end' }}>
                                            <Button type='default' onClick={handleBackStep} style={{ marginRight: 5 }} loading={isLoading}>
                                                Back
                                            </Button>
                                            <Button type='primary' onClick={nextStepAndValidate} loading={isLoading}>
                                                Next
                                            </Button>
                                        </Col>
                                        <Col span={2} />
                                    </Form.Item>
                                </Row> : null}
                            {currentStep === 2
                                ? <Row>
                                    <Form.Item>
                                        <Col span={18} />
                                        <Col span={4} style={{ display: 'flex', justifyContent: 'end' }}>
                                            <Button type='default' onClick={handleBackStep} style={{ marginRight: 5 }} loading={isLoading}>
                                                Back
                                            </Button>
                                            <Button type='primary' disabled={!isCheckedSyncData} loading={isSendingData} onClick={onFinish}>
                                                Submit
                                            </Button>
                                        </Col>
                                        <Col span={2} />
                                    </Form.Item>
                                </Row> : null}
                        </Row>
                    </Card>
                </Form>
            </Spin>
        </div>
    )
}
// const WrappedSyncDataPMToAsset = Form.create<Props>({ name: 'SyncDataPMToAsset' })(SyncDataPMToAsset)
export default SyncDataPMToAsset
