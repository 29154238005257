/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Card, Col, Drawer, Dropdown, Form, Icon, Input, Menu, message, Modal, Radio, Row, Select, Table, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { Space } from '../../common-components/Space'
import ExpandListWo from './subTableListWo/ExpandListWo'
import { StoreState } from '../../store'
import { connect, useDispatch } from 'react-redux'
import { getAllWorkOrdersWithPagination, getWoColumnSetting, setWoColumnSetting } from './service'
import { FormComponentProps } from 'antd/lib/form'
import { UpdateWorkOrderPageEvent } from './state-event'
import { MyFilter, WorkOrderList, woSettingColumn } from './model'
import { People } from '../../authorization-module/people/model'
import { getAllPeople } from '../../authorization-module/user-role/duck/action'
import { RoleType, checkRolesState } from '../../authorization-module/permissions'
import { waitingGetSessions } from '../../guard/route'
import { ColumnNormal } from '../../ReportComponent/components/Model'
import { convertCamelCaseToSentenceCase } from '../../knowledge-management/util'
import ModifyColumnWo from './ModifyColumnWo'

const mapStateToProps = (state: StoreState) => {
    return {
        workOrderList: state.workOrderList,
        totalWorkOrderContent: state.totalWorkOrderContent,
        workOrdersPagination: state.workOrdersPagination
    }
}

type StateProps = ReturnType<typeof mapStateToProps>
const { Option } = Select
const { confirm } = Modal
interface DispatchProps {
    // getAllWorkOrders: (any, search: any, filterStatus?: string) => Promise<number>
    getAllWorkOrdersWithPagination: (page: number, pageSize: number, search?: string, filterStatus?: string,
        fieldName?: string, orderBy?: string, searchFieldValue?: MyFilter[]) => Promise<number>
}

type Props = StateProps & DispatchProps & FormComponentProps

const { Search } = Input
const WoList: React.FC<Props> = (props: Props) => {
    const [SearchText, setSearchText] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [totalWO, setTotalWO] = useState<number>(props.workOrdersPagination.total || 0)
    const [isChangeStatus, setIsChangeStatus] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const status = ['All Open', 'New', 'Assigned', 'InProgress', 'Complete', 'Closed', 'Cancelled']
    const [filterStatus, setFilterStatus] = useState<string | undefined>('All Open')
    const { getFieldDecorator, resetFields, setFieldsValue, validateFields } = props.form
    const dispatch = useDispatch()
    const [username, setUsername] = useState<string>('')
    const [orderBy, setOrderBy] = useState<string>('desc')
    const [sortBy, setSortBy] = useState<string>('createdDatetime')
    const [searchFieldValue, setSearchFieldValue] = useState<MyFilter[]>()
    const [requesterFilter, setRequesterFilter] = useState<People[]>([])
    const [dataSource, setDataSource] = useState<WorkOrderList[]>(props.workOrderList)
    const [openSettingList, setOpenSettingList] = useState<boolean>(false)
    const [defaultFieldSetting, setDefaultFieldSetting] = useState<woSettingColumn[]>([])
    const [defaultOtherHeader, setDefaultOtherHeader] = useState<woSettingColumn[]>([])
    const [modifyColumn, setModifyColumn] = useState<woSettingColumn[]>([])
    const [otherHeader, setOtherHeader] = useState<woSettingColumn[]>([])
    const [columns, setColumns] = useState<ColumnNormal[]>([])
    const [isFetchColumn, setIsFetchColumn] = useState<boolean>(false)

    useEffect(() => {
        const user = sessionStorage.getItem('username')
        setUsername(user!!)
        getAllPeople().then((dataUser) => {
            setRequesterFilter(dataUser)
            getWoColumnSetting().then((res) => {
                if (res) {
                    setIsLoading(true)
                    setOtherHeader(res.otherHeader)
                    setDefaultOtherHeader(res.otherHeader)
                    const fixedColumns = res.data.filter(field => field.columnPosition === 'FIXED')

                    const flexibleColumns = res.data.filter(field => field.columnPosition === 'FLEXIBLE')

                    const customColumns = [
                        ...fixedColumns.filter(field => field.columnName === 'number'),
                        ...flexibleColumns,
                        ...fixedColumns.filter(field => field.columnName !== 'number')
                    ]

                    setModifyColumn(customColumns)
                    setDefaultFieldSetting(customColumns)
                    const modify = customColumns.map(item => item.columnName)
                    const other = res.otherHeader.map(item => item.columnName)
                    const allHeader = [...modify, ...other]

                    const dataSetUser: string[] = dataUser.map(it => it.fullName)
                    const uniqueUser = Array.from(new Set(dataSetUser))
                    // eslint-disable-next-line array-callback-return
                    const temp = customColumns.map((fields) => {
                        const findDataIndex = allHeader.find((data) => data === fields.columnName)
                        if (findDataIndex) {
                            // console.log(findDataIndex)

                            const columnModel: ColumnNormal = {
                                title: convertCamelCaseToSentenceCase(findDataIndex),
                                dataIndex: findDataIndex,
                                key: findDataIndex,
                                sorter: true,
                                ...(
                                    findDataIndex === 'number' ? getColumnSearchProps('number')
                                        : findDataIndex === 'topic' ? getColumnSearchProps('topic')
                                            : findDataIndex === 'requester' ? getColumnSearchProps('Requester', 'selectMultiple', uniqueUser)
                                                : findDataIndex === 'status' ? getColumnSearchProps('WO status', 'selectMultiple', ['New', 'Assigned', 'InProgress', 'Complete', 'Closed', 'Cancelled'])
                                                    : findDataIndex === 'type' ? getColumnSearchProps('type', 'selectMultiple', ['Incident', 'Request'])
                                                        : {}
                                )
                            }

                            if (findDataIndex === 'supportTeam') {
                                columnModel.title = 'Assignment Group'
                                columnModel.render = (row, data) => (
                                    <div style={{ margin: 'unset' }}>
                                        <Tooltip title={row}>
                                            <span>{row?.length > 30 ? row?.slice(0, 30) + '...' : row}</span>
                                        </Tooltip>
                                    </div>
                                )
                            } else if (findDataIndex === 'assignee') {
                                columnModel.title = 'Assigned To'
                            } else if (findDataIndex === 'number') {
                                columnModel.title = 'Work Order Number'
                                columnModel.render = (row, data) => (
                                    <div style={{ margin: 'unset' }}>{<Link to={`/WoFormEdit/${data.id}`} target="_blank" onClick={() => waitingGetSessions()}>{row}</Link>}</div>
                                )
                            } else if (findDataIndex === 'topic') {
                                columnModel.render = (row, data) => (
                                    <div style={{ margin: 'unset' }}>
                                        <Tooltip title={row}>
                                            <span>{row?.length > 30 ? row.slice(0, 30) + '...' : row}</span>
                                        </Tooltip>
                                    </div>
                                )
                            } else if (findDataIndex === 'workflowName') {
                                columnModel.render = (row, data) => (
                                    <div style={{ margin: 'unset' }}>
                                        <Tooltip title={row}>
                                            <span>{row?.length > 30 ? row.slice(0, 30) + '...' : row}</span>
                                        </Tooltip>
                                    </div>
                                )
                            } else if (findDataIndex === 'description') {
                                columnModel.render = (row, data) => (
                                    <div style={{ margin: 'unset' }}>
                                        <Tooltip title={row}>
                                            <span>{row?.length > 30 ? row.slice(0, 30) + '...' : row}</span>
                                        </Tooltip>
                                    </div>
                                )
                            }
                            return columnModel
                        }
                    })
                    setColumns(temp as any)
                    setIsLoading(false)
                }
            })
        })
        // search(null, filterStatus)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.workOrderList) {
            setDataSource(props.workOrderList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.workOrderList])

    useEffect(() => {
        setPage(1)
        setTotalWO(props.workOrdersPagination.total || 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.workOrdersPagination.total])

    // useEffect(() => {
    //     search(null, filterStatus)
    //     setIsChangeStatus(false)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    useEffect(() => {
        setIsLoading(true)
        props.getAllWorkOrdersWithPagination(page, pageSize, SearchText.trim(), filterStatus === 'All Open' ? 'All Open Status' : filterStatus, sortBy, orderBy, searchFieldValue).then((res) => {
            if (res) {
                setIsLoading(false)
            }
        }).catch((err) => {
            setIsLoading(false)
            message.error(`Failed fetching data. ${err}`)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, sortBy, orderBy, searchFieldValue, isChangeStatus, isFetchColumn])

    const search = (value, filter) => {
        setIsLoading(true)
        props.getAllWorkOrdersWithPagination(page, pageSize, value ? value.trim() : null, filter === 'All Open' ? 'All Open Status' : filter, sortBy, orderBy, searchFieldValue).then((res) => {
            if (res) {
                setIsLoading(false)
            }
        }).catch((err) => {
            setIsLoading(false)
            message.error(`Failed fetching data. ${err}`)
        })
    }

    const checkTextPlaceholder = (value: string): string => {
        let tempValue: string = value
        if (tempValue === 'number') {
            tempValue = 'WorkOrder Number'
        } else if (tempValue === 'topic') {
            tempValue = 'Topic'
        } else if (tempValue === 'contactUserName') {
            tempValue = 'Requester'
        } else if (tempValue === 'status') {
            tempValue = 'Status'
        } else if (tempValue === 'contactUserLocation') {
            tempValue = 'Location'
        } else if (tempValue === 'supportTeam') {
            tempValue = 'Assignment group'
        } else if (tempValue === 'assignee') {
            tempValue = 'Assigned to'
        } else if (tempValue === 'lastModifiedDatetime') {
            tempValue = 'Updated date'
        } else if (tempValue === 'closedJobs') {
            tempValue = 'Job'
        } else if (tempValue === 'type') {
            tempValue = 'Ticket Type'
        } else if (tempValue === 'createdDatetime') {
            tempValue = 'Created Date'
        } else if (tempValue === 'jobTopic') {
            tempValue = 'Job Topic'
        } else if (tempValue === 'workOrder.jobTopic') {
            tempValue = 'Job Topic'
        } else if (tempValue === 'workOrder.closedJobs') {
            tempValue = 'Job Status'
        }
        return tempValue
    }

    const getColumnSearchProps = (dataIndex: any, inputType?: string, dataSource?: any[]) => ({
        // eslint-disable-next-line react/display-name
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any): any => (
            <div style={{ padding: 8 }}>
                {inputType === 'selectMultiple' && dataSource?.length !== 0 ? <Select
                    mode='multiple'
                    onChange={(e: any) => setSelectedKeys(e)}
                    value={selectedKeys}
                    placeholder={`Search ${checkTextPlaceholder(dataIndex)}`}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                >
                    {dataSource?.map((it, index) => {
                        return <Option key={index} value={it}>{it}</Option>
                    })}
                </Select> : null}
                {inputType === 'select' && dataSource?.length !== 0 ? <Select
                    placeholder={`Search ${checkTextPlaceholder(dataIndex)}`}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                >
                    {dataSource?.map((it, index) => {
                        return <Option key={index} value={it}>{it}</Option>
                    })}
                </Select> : null}
                {inputType === undefined ? <Input
                    placeholder={`Search ${checkTextPlaceholder(dataIndex)}`}
                    value={selectedKeys[0]}
                    onChange={(e: any) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                /> : null}
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90, marginRight: 5 }}>
                    Reset
                </Button>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8, color: 'red' }}
                >
                    Search
                </Button>
            </div>
        ),
        // onFilter: (value: any, record: any) =>
        //     record.workOrder[dataIndex] ? record.workOrder[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        // eslint-disable-next-line react/display-name
        filterIcon: (filtered: any) => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                console.log(visible)
            }
        }
    })

    const handleSearch = (selectedKeys: any, confirm: any) => {
        confirm()
    }

    const handleReset = (clearFilters: any) => {
        clearFilters()
    }

    const getProperty = (obj, key) => {
        return obj[key]
    }

    // const sorter = (a: string, b: string) => (a !== null && b !== null ? (a || '').localeCompare(b || '') : (b || '').localeCompare(a || ''))
    const convertFieldName = (key) => {
        return key.split('.')[1]
    }
    const trimArray = (array) => {
        return array.map((data) => { return data.trim() })
    }
    const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        if (sorter.field) {
            if (sorter.order === 'ascend') {
                setOrderBy('ASC')
                if (sorter.field) {
                    setSortBy(sorter.field)
                } else {
                    setSortBy('createdDatetime')
                }
            } else if (sorter.order === 'descend') {
                setOrderBy('DESC')
                if (sorter.field) {
                    setSortBy(sorter.field)
                } else {
                    setSortBy('createdDatetime')
                }
            } else {
                setSortBy('createdDatetime')
            }
        }

        if (filters) {
            const listNewObj: MyFilter[] = []
            for (const key of Object.keys(filters)) {
                const newObject: MyFilter = {
                    key: '',
                    value: undefined
                }

                if (getProperty(filters, key).length > 0) {
                    let targetKey = ''
                    if (key === 'requester') {
                        targetKey = 'fullName'
                    } else {
                        targetKey = key
                    }
                    newObject.key = convertFieldName(key) || targetKey
                    newObject.value = trimArray(getProperty(filters, key))
                    listNewObj.push(newObject)
                } else {
                    setSearchFieldValue(listNewObj)
                }
            }
            console.log(listNewObj)
            setSearchFieldValue(listNewObj)
        }

        setPageSize(pagination.pageSize)
        setPage(pagination.current)
    }

    const handleSearchWO = (value) => {
        if (value.trim() === '') {
            resetFields(['SearchWO'])
        }
        setSearchText(value.trim())
        resetFields([
            'Filter'
        ])
        if (value.trim()) {
            setFilterStatus(undefined)
            search(value.trim(), undefined)
            setFieldsValue({ SearchWO: value?.trim() })
        } else {
            search(value.trim(), 'All Open')
            setFilterStatus('All Open Status')
            setFieldsValue({ SearchWO: value?.trim() })
        }
    }

    // const initialColumns = [
    //     {
    //         title: <span className='bold-titleWo'>Work Order Number</span>,
    //         dataIndex: 'workOrder.number',
    //         key: 'workOrder.number',
    //         // width: '13%',
    //         sorter: (a, b) => sorter(a.workOrder.number, b.workOrder.number),
    //         ...getColumnSearchProps('number'),
    //         render: (record) => {
    //             const incNo = props.workOrderList.find(res => res.workOrder?.number === record)
    //             return (
    //                 <div style={{ margin: 'unset' }}>{<Link to={`/WoFormEdit/${incNo?.workOrder?.id}`} target="_blank" onClick={() => waitingGetSessions()}>{record}</Link>}</div>
    //             )
    //         }
    //     },
    //     {
    //         title: <span className='bold-titleWo'>Topic</span>,
    //         dataIndex: 'workOrder.topic',
    //         key: 'workOrder.topic',
    //         width: '15%',
    //         sorter: (a, b) => sorter(a.workOrder.topic, b.workOrder.topic),
    //         ...getColumnSearchProps('topic'),
    //         render: (record) => {
    //             return (
    //                 <>
    //                     {
    //                         <Tooltip placement="top" title={record}>
    //                             <p style={{ margin: 'unset' }}>{record?.length > 30 ? (<>{record.substring(0, 30) + '...'}</>) : (<>{record}</>)}</p>
    //                             {/* <p style={{ margin: 'unset' }}>{record}</p> */}
    //                         </Tooltip>
    //                     }
    //                 </>
    //             )
    //         }
    //     },
    //     {
    //         title: <span className='bold-titleWo'>Requester</span>,
    //         dataIndex: 'workOrder.userId.fullName',
    //         key: 'workOrder.userId.fullName',
    //         sorter: (a, b) => sorter(a.workOrder.contactUserName, b.workOrder.contactUserName),
    //         ...getColumnSearchProps('contactUserName', 'selectMultiple', requesterFilter.map((it, index) => { return it.fullName })),
    //         render: (record) => {
    //             return (
    //                 <>
    //                     {
    //                         <Tooltip placement="top" title={record}>
    //                             <p style={{ margin: 'unset' }}>{record?.length > 10 ? (<>{record.substring(0, 10) + '...'}</>) : (<>{record}</>)}</p>
    //                         </Tooltip>
    //                     }
    //                 </>
    //             )
    //         }
    //     },
    //     {
    //         title: <span className='bold-titleWo'>WO Status</span>,
    //         dataIndex: 'workOrder.status',
    //         key: 'workOrder.status',
    //         sorter: (a, b) => sorter(a.workOrder.status, b.workOrder.status),
    //         ...getColumnSearchProps('WO status', 'selectMultiple', ['New', 'Assigned', 'InProgress', 'Complete', 'Closed', 'Cancelled'])
    //         // ...getColumnSearchProps('WO status')
    //     },
    //     {
    //         title: <span className='bold-titleWo'>Ticket Type</span>,
    //         dataIndex: 'workOrder.type',
    //         key: 'workOrder.type',
    //         sorter: (a, b) => sorter(a.workOrder.type, b.workOrder.type),
    //         ...getColumnSearchProps('type', 'selectMultiple', ['Incident', 'Request'])
    //         // ...getColumnSearchProps('type')
    //     },
    //     {
    //         title: <span className='bold-titleWo'>Assignment Group</span>,
    //         dataIndex: 'workOrder.supportTeam',
    //         key: 'workOrder.supportTeam',
    //         sorter: (a, b) => sorter(a.workOrder.supportTeam, b.workOrder.supportTeam)
    //     },
    //     {
    //         title: <span className='bold-titleWo'>Assigned To</span>,
    //         dataIndex: 'workOrder.assigneeEmail',
    //         key: 'workOrder.assigneeEmail',
    //         sorter: (a, b) => sorter(a.workOrder.assigneeEmail, b.workOrder.assigneeEmail),
    //         render: (record) => {
    //             return (
    //                 <>
    //                     {
    //                         <Tooltip placement="top" title={record}>
    //                             <p style={{ margin: 'unset' }}>{record?.length > 10 ? (<>{record.substring(0, 10) + '...'}</>) : (<>{record}</>)}</p>
    //                             {/* <p style={{ margin: 'unset' }}>{record}</p> */}
    //                         </Tooltip>
    //                     }
    //                 </>
    //             )
    //         }
    //     },
    //     // {
    //     //     title: <span className='bold-titleWo'>Appointment Date</span>,
    //     //     dataIndex: 'workOrder.appointmentDate',
    //     //     key: 'workOrder.appointmentDate',
    //     //     sorter: (a, b) => sorter(a.workOrder.appointmentDate, b.workOrder.appointmentDate)
    //     // },
    //     // {
    //     //     title: <span className='bold-titleWo'>Estimate Time</span>,
    //     //     dataIndex: 'workOrder.estimateTime',
    //     //     key: 'workOrder.estimateTime',
    //     //     sorter: (a, b) => sorter(a.workOrder.estimateTime, b.workOrder.estimateTime)
    //     // },
    //     {
    //         title: <span className='bold-titleWo'>Created Date</span>,
    //         dataIndex: 'workOrder.createdDatetime',
    //         key: 'workOrder.createdDatetime',
    //         sorter: (a, b) => sorter(a.workOrder.createdDatetime, b.workOrder.createdDatetime)
    //         // ...getColumnSearchProps('createdDatetime')
    //     },
    //     {
    //         title: <span className='bold-titleWo'>Update Date</span>,
    //         dataIndex: 'workOrder.lastModifiedDatetime',
    //         key: 'workOrder.lastModifiedDatetime',
    //         sorter: (a, b) => sorter(a.workOrder.lastModifiedDatetime, b.workOrder.lastModifiedDatetime)
    //     },
    //     // {
    //     //     title: 'Job Topic',
    //     //     dataIndex: 'workOrder.jobTopic',
    //     //     render: (record) => {
    //     //         return (
    //     //             <>
    //     //                 {
    //     //                     <Tooltip placement="top" title={record}>
    //     //                         <p style={{ margin: 'unset' }}>{record?.length > 10 ? (<>{record.substring(0, 10) + '...'}</>) : (<>{record}</>)}</p>
    //     //                     </Tooltip>
    //     //                 }
    //     //             </>
    //     //         )
    //     //     },
    //     //     sorter: (a, b) => sorter(a.workOrder.jobTopic, b.workOrder.jobTopic),
    //     //     ...getColumnSearchProps('workOrder.jobTopic')
    //     // },
    //     // {
    //     //     title: 'Job Status',
    //     //     dataIndex: 'workOrder.jobStatus',
    //     //     key: 'workOrder.jobStatus',
    //     //     sorter: (a, b) => sorter(a.workOrder.jobStatus, b.workOrder.jobStatus),
    //     //     ...getColumnSearchProps('workOrder.closedJobs', 'selectMultiple', ['Created', 'Open', 'InProgress', 'Completed', 'Cancel'])
    //     //     // ...getColumnSearchProps('workOrder.closedJobs')
    //     // },
    //     {
    //         title: <span className='bold-titleWo'>Progress</span>,
    //         dataIndex: 'progress',
    //         key: 'progress',
    //         align: 'center' as const,
    //         sorter: (a, b) => sorter(a.workOrder.closedJobs, b.workOrder.closedJobs)
    //     }
    // ]

    const handleIsChangeStatus = (isChange) => {
        setIsChangeStatus(isChange)
    }

    const expandedRowRender = (expandedRow, index: number, indent: number, expanded: boolean) => {
        return <ExpandListWo woId={expandedRow.id}
            woNumber={expandedRow.number}
            username={username}
            handleIsChangeStatus={handleIsChangeStatus}
            isCloseAndCancel={expandedRow?.status === 'Closed' || expandedRow?.status === 'Cancelled'} />
    }

    const handleFilterStatus = (e) => {
        setFilterStatus(e.target.value)
        setIsLoading(true)

        props.getAllWorkOrdersWithPagination(page, pageSize, '', e.target.value === 'All Open' ? 'All Open Status' : e.target.value, sortBy, orderBy).then((res) => {
            if (res) {
                setIsLoading(false)
            }
        }).catch((err) => {
            setIsLoading(false)
            message.error(`Failed fetching data. ${err}`)
        })
    }

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={() => setOpenSettingList(true)}> <Icon type="bars" />Setting List</Menu.Item>
        </Menu>
    )

    const filterGroup = (dataSource: string[]) => {
        return (
            <Row style={{ marginTop: 20 }}>
                <Col span={12}>
                    {getFieldDecorator('Filter', {
                        initialValue: filterStatus
                    })(
                        <Radio.Group onChange={(value) => {
                            handleFilterStatus(value)
                        }}>
                            {dataSource.map((item) => {
                                return (
                                    <Radio.Button
                                        key={item}
                                        value={item}
                                    >{item}</Radio.Button>
                                )
                            })}
                        </Radio.Group>
                    )}
                </Col>
            </Row>
        )
    }

    const handleOnClickPageNumber = (page: number) => {
        dispatch(UpdateWorkOrderPageEvent.build(page))
        setPage(page)
    }

    const handleAddField = (index) => {
        const newKey = modifyColumn.length > 0
            ? Math.max(...modifyColumn.map(field => field.columnOrder)) + 1
            : 1
        const newField = {
            columnLabel: '',
            columnName: '',
            columnOrder: newKey,
            columnPosition: 'FLEXIBLE'
        }
        const insertField = index + 1

        const customModify = [
            ...modifyColumn.slice(0, insertField),
            newField,
            ...modifyColumn.slice(insertField)
        ]
        setModifyColumn(customModify)

    }

    function warning() {
        Modal.warning({
            title: <span style={{ fontSize: '25px', fontWeight: 'bold', color: '#323276' }}>Warning</span>,
            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>At least one field must be specified.</span>,
            className: 'custom-modal-wo'
        })
    }

    const handleRemoveField = (key) => {
        const flexibleColumns = modifyColumn.filter(field => field.columnPosition !== 'FIXED')
        if (flexibleColumns.length === 1) {
            warning()
            return
        }

        const removeField = modifyColumn.find(field => field.columnOrder === key)?.columnName
        const updatedModifyColumn = modifyColumn.filter(field => field.columnOrder !== key)

        setModifyColumn(updatedModifyColumn)

        if (removeField) {
            let customLabel = convertCamelCaseToSentenceCase(removeField)

            if (removeField === 'assignee') {
                customLabel = 'Assigned To'
            } else if (removeField === 'supportTeam') {
                customLabel = 'Assignment Group'
            }

            setOtherHeader(prevOtherHeader => [
                ...prevOtherHeader,
                { columnName: removeField, columnLabel: customLabel, columnOrder: key, columnPosition: 'FLEXIBLE' }
            ])
        }
    }

    const handleChangeField = (key, value) => {
        const prevValue = modifyColumn.find(field => field.columnOrder === key)?.columnName
        
        setModifyColumn(prevModifyColumn => {
            return prevModifyColumn.map(field => 
                field.columnOrder === key 
                ? {...field, columnLabel: convertCamelCaseToSentenceCase(value), columnName: value, columnPosition: 'FLEXIBLE'}
                : field
            )
        })

        setOtherHeader(prevOtherHeader => {
            if (prevValue && prevValue !== value) {
                const filterOptionOtherHeader = prevOtherHeader.filter(option => option.columnName !== value)
                return [...filterOptionOtherHeader, { columnName: prevValue, columnLabel: convertCamelCaseToSentenceCase(prevValue), columnOrder: key, columnPosition: 'FLEXIBLE' }]
            }
            return prevOtherHeader.filter(option => option.columnName !== value)
        })
    }

    const handleCloseSetting = () => {
        confirm({
            title: <b style={{ color: '#323276', fontSize: '25px', fontWeight: 'bold' }}>Are you sure?</b>,
            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>Do you want to leave this page ?</span>,
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                setModifyColumn(defaultFieldSetting)
                setOtherHeader(defaultOtherHeader)
                setOpenSettingList(false)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const handleSaveModifyColumn = () => {
        validateFields((err, values) => {
            if (!err) {
                const fixedColumns = modifyColumn.filter(field => field.columnPosition === 'FIXED')

                const flexibleColumns = modifyColumn.filter(field => field.columnPosition === 'FLEXIBLE')

                const customColumns = [
                    ...fixedColumns.filter(field => field.columnName === 'number'),
                    ...flexibleColumns,
                    ...fixedColumns.filter(field => field.columnName !== 'number')
                ]

                const body = customColumns.map((fields) => ({
                    columnName: fields.columnName,
                    columnOrder: fields.columnOrder,
                    columnPosition: fields.columnPosition
                }))

                const allHeader = [...customColumns.map(field => field.columnName), ...otherHeader.map(field => field.columnName)]

                setIsLoading(true)
                setIsFetchColumn(true)
                setDefaultFieldSetting(modifyColumn)
                setDefaultOtherHeader(otherHeader)
                setWoColumnSetting(body).then((res) => {
                    if (res) {
                        const temp: ColumnNormal[] = []
                        res.forEach((columns) => {
                            const findDataIndex = allHeader.find((data) => data === columns.columnName)
                            if (findDataIndex) {
                                const columnModel: ColumnNormal = {
                                    title: convertCamelCaseToSentenceCase(findDataIndex),
                                    dataIndex: findDataIndex,
                                    key: findDataIndex,
                                    sorter: true,
                                    ...(
                                        findDataIndex === 'number' ? getColumnSearchProps('number')
                                            : findDataIndex === 'topic' ? getColumnSearchProps('topic')
                                                : findDataIndex === 'contactUserName' ? getColumnSearchProps('contactUserName', 'selectMultiple', requesterFilter.map(it => it.fullName))
                                                    : findDataIndex === 'WO status' ? getColumnSearchProps('WO status', 'selectMultiple', ['New', 'Assigned', 'InProgress', 'Complete', 'Closed', 'Cancelled'])
                                                        : findDataIndex === 'type' ? getColumnSearchProps('type', 'selectMultiple', ['Incident', 'Request'])
                                                            : {}
                                    )
                                }

                                if (findDataIndex === 'supportTeam') {
                                    columnModel.title = 'Assignment Group'
                                    columnModel.render = (row, data) => (
                                        <div style={{ margin: 'unset' }}>
                                            <Tooltip title={row}>
                                                <span>{row?.length > 30 ? row?.slice(0, 30) + '...' : row}</span>
                                            </Tooltip>
                                        </div>
                                    )
                                } else if (findDataIndex === 'assignee') {
                                    columnModel.title = 'Assigned To'
                                } else if (findDataIndex === 'number') {
                                    columnModel.title = 'WorkOrder Number'
                                    columnModel.render = (row, data) => (
                                        <div style={{ margin: 'unset' }}>{<Link to={`/WoFormEdit/${data.id}`} target="_blank" onClick={() => waitingGetSessions()}>{row}</Link>}</div>
                                    )
                                } else if (findDataIndex === 'topic') {
                                    columnModel.render = (row, data) => (
                                        <div style={{ margin: 'unset' }}>
                                            <Tooltip title={row}>
                                                <span>{row?.length > 30 ? row.slice(0, 30) + '...' : row}</span>
                                            </Tooltip>
                                        </div>
                                    )
                                } else if (findDataIndex === 'workflowName') {
                                    columnModel.render = (row, data) => (
                                        <div style={{ margin: 'unset' }}>
                                            <Tooltip title={row}>
                                                <span>{row?.length > 30 ? row.slice(0, 30) + '...' : row}</span>
                                            </Tooltip>
                                        </div>
                                    )
                                } else if (findDataIndex === 'description') {
                                    columnModel.render = (row, data) => (
                                        <div style={{ margin: 'unset' }}>
                                            <Tooltip title={row}>
                                                <span>{row?.length > 30 ? row.slice(0, 30) + '...' : row}</span>
                                            </Tooltip>
                                        </div>
                                    )
                                }

                                temp.push(columnModel)
                            }
                        })
                        setColumns(temp)
                        setOpenSettingList(false)
                        setIsLoading(false)
                        setIsFetchColumn(false)
                        message.success('The data has been updated successfully.')
                    }
                }).catch((err) => {
                    console.log(err)
                    setOpenSettingList(false)
                    setIsLoading(false)
                    setIsFetchColumn(false)
                })
            }
        })
    }

    const settingList = (
        <Form>
            <Row style={{ borderBottom: '1px solid #D0D5DD', paddingBottom: '10px' }}>
                <Form.Item label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Fixed</span>}>
                    <Col span={18}>
                        {modifyColumn.filter((data) => data.columnName === 'number').map((fields, index) => (
                            <div style={{ display: 'flex', alignItems: 'center', padding: '8px 8px', justifyContent: 'space-between' }} key={index}>
                                <Input style={{ fontSize: '14px' }} value={fields.columnLabel} disabled={true} />
                            </div>
                        ))}
                    </Col>
                </Form.Item>
            </Row>
            <Row style={{ borderBottom: '1px solid #D0D5DD', paddingBottom: '10px' }}>
                <Form.Item label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Modify</span>}>
                    {modifyColumn.filter((data) => data.columnPosition !== 'FIXED').map((fields, index) => (
                        <div key={index}>
                            <ModifyColumnWo
                                form={props.form}
                                fieldsData={fields}
                                columnKey={index + 1}
                                otherHeader={otherHeader}
                                handleAddField={handleAddField}
                                handleChangeField={handleChangeField}
                                handleRemoveField={handleRemoveField}
                            />
                        </div>
                    ))}
                </Form.Item>
            </Row>
            <Row style={{ borderBottom: '1px solid #D0D5DD', paddingBottom: '10px' }}>
                <Form.Item label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Fixed</span>}>
                    <Col span={18}>
                        {modifyColumn.filter((data) => data.columnName === 'createdDatetime' || data.columnName === 'lastModifiedDatetime' || data.columnName === 'progress').map((fields, index) => (
                            <div style={{ display: 'flex', alignItems: 'center', padding: '8px 8px', justifyContent: 'space-between', marginBottom: '10px' }} key={index}>
                                <Input style={{ fontSize: '14px' }} value={fields.columnLabel} disabled={true} />
                                {/* <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{fields.columnLabel}</span>
                                <img src={IconSorter} style={{ marginLeft: 10 }} /> */}
                            </div>
                        ))}
                    </Col>
                </Form.Item>
            </Row>
            <Form.Item style={{ textAlign: 'end', marginTop: 10 }}>
                <Button htmlType='button' onClick={handleCloseSetting}>
                    Cancel
                </Button>
                <Button type='primary' htmlType='button' style={{ marginLeft: 5 }} onClick={handleSaveModifyColumn}>
                    Save
                </Button>
            </Form.Item>
        </Form>
    )

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>Work Order</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={20} />
            <Drawer
                title={<span style={{ color: '#FA541C', fontSize: '20px', fontWeight: 'bold' }}>Setting List</span>}
                placement='right'
                closable={false}
                onClose={() => { setOpenSettingList(false) }}
                visible={openSettingList}
                width={window.innerWidth <= 768 ? '70%' : '30%'}
                bodyStyle={{ overflow: 'auto' }}
                maskClosable={false}
            >
                {settingList}
            </Drawer>
            <Card style={{ overflow: 'auto' }}>
                {/* <Row gutter={16}>
                    <Col span={24}>
                        <span className='text-herder-Orenge'>Work Order</span>
                    </Col>
                </Row> */}
                <Row gutter={16}>
                    <Col span={12} xs={24} sm={24} md={12} lg={12}>
                        <Form>
                            <Tooltip placement='topLeft' title={'Search Work Order Number and Topic'}>
                                <Form.Item>
                                    {getFieldDecorator('SearchWO', {
                                    })(
                                        <Search
                                            placeholder="Search Work Order Number and Topic"
                                            onSearch={handleSearchWO}
                                            allowClear
                                            style={{ width: '60%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Form>
                    </Col>
                    <Col span={12} xs={24} sm={24} md={12} lg={12} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <Col style={{ textAlign: 'end' }}>
                            <Dropdown overlay={menu} trigger={['click']}>
                                <Button type='default' className='custom-menu-settingList' >
                                    <Icon type='more' style={{ display: 'flex' }} />
                                </Button>
                            </Dropdown>
                        </Col>
                        <Link to={'/WoForm'} ><Button type="primary" icon='plus' style={{ float: 'right' }} disabled={!checkRolesState(RoleType.WO, 'CreateWorkOrder')}>Add New</Button></Link>
                    </Col>
                </Row>
                {filterGroup(status)}
                <br />
                <div style={{ whiteSpace: 'nowrap', width: '100%' }}>
                    <Table
                        rowKey={record => record?.id as string}
                        columns={columns}
                        dataSource={dataSource}
                        bodyStyle={{ overflow: 'auto' }}
                        expandedRowRender={expandedRowRender}
                        defaultExpandAllRows={isLoading}
                        onChange={handleTableChange}
                        pagination={
                            dataSource && dataSource.length > 0
                                ? {
                                    pageSize: pageSize,
                                    current: page,
                                    total: totalWO,
                                    showSizeChanger: true,
                                    onChange: (event) => { handleOnClickPageNumber(event) }
                                }
                                : false}
                        loading={isLoading}
                    />
                </div>
            </Card>
        </>
    )
}

const WoListPage = Form.create<Props>({ name: 'WoList' })(WoList)

export default connect(mapStateToProps, {
    getAllWorkOrdersWithPagination
})(WoListPage)
