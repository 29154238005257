/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { AutoComplete, Button, Col, Dropdown, Form, Icon, Menu, message, Modal, Row, Spin, Tag, Tooltip } from 'antd'
import { StoreState } from '../../store'
import IncidentTimeIndex from './IncidentTimeIndex'

import { Incident, IncidentWithSlaCalculations, Satisfaction, TicketStatus } from './index'
// import ModalPending from './PendingDialog'
import { addSatisfaction, GetAllTicketRelatedTicketIncView, markIncidentViewed, updateIncident } from './service'

import { disableByStatus, notEmpty, tokenDecode } from '../../common-misc'
import { FetchingState } from '../../common-components'
import { SlaCalculation } from '../../sla-management/sla-calculation/model'
import { SlaGoal } from '../../sla-management/sla-policy/model'
// import {checkRolesState, RoleType} from '../../authorization-module/permissions'
import { PriorityLevel } from '../priority'
import { FormComponentProps } from 'antd/es/form'
import { SelectValue } from 'antd/lib/select'
import { getAllWorkLogByIncidentId } from '../worklog/service'
import { SupportTeam } from './model'
import { UserSupportTeam } from '../../authorization-module/support-team-management/model'
import CustomerSat from './CustomerSatModal'
// import {People} from '../../authorization-module/people/model'
import { getTicketStatus } from '../status'
import { getWoRelatedByIncidentId, WoRalateIncidentCard } from '../../msp-wo/wo-management'
import { ticketPacksByincidentId } from '../../serviceCatalog/Catalog/service'
import moment from 'moment'
import RelateList from './RelateList'
import SlaTimeUsedUpPercentage2 from './SlaTimeUsedUpPercentage2'
import { waitingGetSessions } from '../../guard/route'
import { getAllGeneralIncidentSetting } from '../general-incident-setting/service'

const mapStateToProps = (state: StoreState) => {
    return {
        statuses: state.statuses,
        supportTeamsWithPermission: state.supportTeamsWithPermission,
        incidentState: state.incidentState,
        workLog: state.workLog,
        generalIncidentSetting: state.generalIncidentSetting,
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    updateIncident: (incident: Incident) => Promise<number>
    getAllWorkLogByIncidentId: (incidentId: string) => Promise<number>
    addSatisfaction: (satisfaction: Satisfaction) => Promise<number>
    getAllGeneralIncidentSetting: () => Promise<number>
}

interface Params {
    incident: IncidentWithSlaCalculations
    xxx: any
    slaCalculationsFetchingState: FetchingState
    setIncidentsFetchingState: Function
    priority: PriorityLevel[]
    isMedium: boolean
}
// const {SubMenu} = Menu
type Props = StateProps & DispatchProps & Params & FormComponentProps
let tempAllStatus: TicketStatus[] = []
const idTenantFreshService = process.env.REACT_APP_ID_TENANT_FRESH_SERVICE
const IncidentRowEnhance = (props: Props) => {
    const format = 'YYYY-MM-DD HH:mm:ss'
    const incident = props.incident
    // const [myModal, setMyModal] = useState<JSX.Element | null>()
    const [visibleModal, setVisibleModal] = useState(false)
    const [isRequireAssignGroup, setIsRequireAssignGroup] = useState<boolean>(false)
    const [isRequireAssignee, setIsRequireAssignee] = useState<boolean>(false)
    const [assignee, setAssignee] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [currentStatus, setCurrentStatus] = useState<string>()
    const [, setOldStatused] = useState<string>()
    const [isSpinning] = useState<boolean>(false)
    const [mySupportTeamName, setMySupportTeamName] = useState<string[]>()
    const { getFieldDecorator } = props.form
    const [rowId] = useState<number>()
    const [disableAssignTo, setDisabledAssignTo] = useState<boolean>(false)
    const [, setSlaCalculationsFetchingState] = useState<FetchingState>(props.slaCalculationsFetchingState)
    const [isSatOpen, setIsSatOpen] = useState<boolean>(false)
    const [isSLAOpen, setIsSLAOpen] = useState<boolean>(false)
    const [ticketType, setTicketType] = useState<string>('Incident')
    const dispatch = useDispatch()
    const [, setChildTicket] = useState<number[]>([])
    const [, setParentTicket] = useState<number[]>([])
    const [, GetTicketRelated] = useState<any>()
    const [dataWoRalateIncident, setDataWoRalateIncident] = useState<WoRalateIncidentCard[]>([])
    const [visibleRelated, setVisibleRelated] = useState(false)
    const splitIdTenantFreshService = idTenantFreshService.split(',')
    const isTanentFreshService = splitIdTenantFreshService.includes(tokenDecode()?.tenantID.toString())

    useEffect(() => {
        if (props.incident?.status?.value !== null) {
            setCurrentStatus(props.incident?.status?.value)
        }
        if (props.supportTeamsWithPermission.length !== 0) {
            const supportTeamName = props.supportTeamsWithPermission.map((supportTeam: SupportTeam) => {
                return supportTeam?.name
            })
            setMySupportTeamName(supportTeamName || [])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.supportTeamsWithPermission])

    useEffect(() => {
        if (rowId) {
            fetchAllWorklog(rowId)
            fetchTicketRelated(rowId)
            ticketPacksByincidentId(rowId?.toString()!!).then((data) => {
                if (data.tickets) {
                    const convertDataToWoRalateIncident: WoRalateIncidentCard[] = data.tickets.map((it) => {
                        return {
                            id: it.ticketId,
                            description: JSON.parse(it.ticketCreationRequestBody).description,
                            item_id: it.itemId,
                            order_id: it.ticketNumber,
                            wo_status: it.ticketStatus,
                            workflow_name: JSON.parse(it.ticketCreationRequestBody).workflowName,
                            workflow_number: JSON.parse(it.ticketCreationRequestBody).workflowNumber
                        } as WoRalateIncidentCard
                    })
                    const mergeData = [...dataWoRalateIncident, ...convertDataToWoRalateIncident]
                    const arrayUniqueByKey = [...new Map(mergeData.map(item =>
                        [item.id, item])).values()]
                    setDataWoRalateIncident(arrayUniqueByKey)
                }
            })
            fetchDataWoWithIncident()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowId])

    useEffect(() => {
        disableByStatus(currentStatus, setDisabledAssignTo, props.incident?.user?.email, props.incident.supportTeam)
        if (props.incident.ticketType) {
            // const ticketType = props.incident?.incidentSetting?.find(it => it.incGeneralSetting.settingType === 'ticketType')?.incGeneralSetting.value!!
            setTicketType(props.incident.ticketType)
        }
        if (props.incident?.supportTeam?.name) {
            const findData = props.supportTeamsWithPermission.find((it) => { return it.name === props.incident?.supportTeam?.name!! })
            if (!findData) {
                props.setIncidentsFetchingState(FetchingState.NotStarted)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStatus, props.incident])

    useEffect(() => {
        if (tempAllStatus.length === 0 && props.statuses.length !== 0) {
            tempAllStatus = props.statuses
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.statuses])

    useEffect(() => {
        setSlaCalculationsFetchingState(props.slaCalculationsFetchingState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.slaCalculationsFetchingState])

    const fetchAllWorklog = (rowId) => props.getAllWorkLogByIncidentId(rowId)

    const fetchTicketRelated = (rowId) => GetAllTicketRelatedTicketIncView(rowId).then((res) => {
        GetTicketRelated(res)
        setChildTicket(res.child.map((it) => { return it.id }))
        setParentTicket(res.parent.map((it) => { return it.id }))
    })

    const fetchDataWoWithIncident = () => {
        getWoRelatedByIncidentId(props.incident?.id?.toString()!!).then((data) => {
            if (data.length > 0) {
                const mergeData = [...dataWoRalateIncident, ...data]
                const arrayUniqueByKey = [...new Map(mergeData.map(item =>
                    [item.id, item])).values()]
                setDataWoRalateIncident(arrayUniqueByKey)
            }
        })
    }

    const handleOkRequireField = (e: any) => {
        e.preventDefault()
        props.form.validateFields((err: any, fieldsValue: any) => {
            let newStatus = ''
            if (!err) {
                setIsLoading(true)
                if (fieldsValue.Assignment_group !== null && fieldsValue.Assigned_to !== null) {
                    newStatus = 'InProgress'
                } else {
                    newStatus = 'Assigned'
                }
                setCurrentStatus(newStatus)
                const supportTeam: SupportTeam = props.supportTeamsWithPermission.find(it => it.name === fieldsValue.Assignment_group)!!
                const assignee: UserSupportTeam | undefined = supportTeam.assignees.find(it => it.users.fullName === fieldsValue.Assigned_to)
                const ticketStatus: TicketStatus = getTicketStatus(props.statuses, ticketType, newStatus)
                const request: any = {
                    id: incident.id,
                    supportTeam: { id: supportTeam.id },
                    user: assignee?.users.id ? { id: assignee?.users.id } : undefined,
                    ticketStatus: { id: ticketStatus.id }
                }
                props.updateIncident(request).then(res => {
                    if (res === 200) {
                        setIsLoading(false)
                        setVisibleModal(false)
                        setOldStatused('')
                    }
                }).catch((err) => {
                    setIsLoading(false)
                    setVisibleModal(false)
                    message.error(`Unable to update data. Please try again later. ${err}`)
                })
            }
        })
    }

    const handleCancelRequireField = () => {
        setVisibleModal(false)
        setIsRequireAssignee(false)
        setIsRequireAssignGroup(false)
        props.form.setFieldsValue({
            Assignment_group: incident.supportTeam?.name,
            Assigned_to: incident.user?.email
        })
    }

    const buildTooltipTextForSlaTag = (incident: IncidentWithSlaCalculations) => {
        const findSlaGoal = (slaCalculation: SlaCalculation): SlaGoal =>
            props.incidentState.slaPolicyHistories.get(slaCalculation.slaPolicyHistoryId)?.slaGoals
                .find((it) => it.name === slaCalculation.slaGoalName)!!

        const findSlaName = (slaCalculation: SlaCalculation) =>
            props.incidentState.slaPolicyHistories.get(slaCalculation.slaPolicyHistoryId)?.name || ''

        return (<div>
            {notEmpty(incident.slaCalculations)
                ? incident.slaCalculations.map((it) => (
                    <p key={it.id!! + it.lastModifiedDatetime}>
                        <SlaTimeUsedUpPercentage2 incident={incident} slaCalculation={it} slaGoal={findSlaGoal(it)} slaName={findSlaName(it)} />
                    </p>
                ))
                : null
            }
        </div>)
    }

    const FilterSupportTeam = (e: SelectValue) => {
        props.form.setFieldsValue({
            Assigned_to: null
        })
        const targetSupportTeams = props.supportTeamsWithPermission.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === e
        })
        if (targetSupportTeams.length !== 0) {
            targetSupportTeams.forEach((res: any) => {
                const user = res.assignees?.map(it => {
                    return it.users.fullName || ''
                })
                setAssignee(user)
            })
        } else {
            setAssignee([])
        }
    }

    const FilterAssignee = () => {
        const valAssignee = props.form.getFieldValue('Assignment_group')
        const targetSupportTeams = props.supportTeamsWithPermission.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === valAssignee
        })
        targetSupportTeams.forEach((res) => {
            const user: string[] = []
            res.assignees?.map(it => {
                user.push(it.users.firstName + ' ' + it.users.lastName)
            })
            setAssignee(user)
        })
    }

    const checkAssignee = (e: SelectValue) => {
        if (e) {
            FilterSupportTeam(e)
        } else {
            props.form.setFieldsValue({
                Assigned_to: null
            })
        }
    }

    const onBlurTeam = (value: SelectValue) => {
        if (mySupportTeamName) {
            const isTeam = mySupportTeamName.includes((value as string))
            if (!isTeam) {
                props.form.setFieldsValue({
                    Assignment_group: null
                })
            }
        }
    }

    const onBlurAssignee = (value: SelectValue) => {
        if (assignee) {
            const isAssignee = assignee.includes((value as string))
            if (!isAssignee) {
                props.form.setFieldsValue({
                    Assigned_to: null
                })
            }
        }
    }

    const isSATOpen = () => {
        if (incident.isSatisfaction) {
            setIsSatOpen(true)
        }
    }

    function setTitle(): string {
        return `Please Require filed (${props.incident.number})`
    }

    const menuRelate = (
        <Menu>
            <RelateList visibleRelated={visibleRelated} setVisibleRelated={setVisibleRelated} incidentAndSLA={props.incident} />
        </Menu>
    )

    const handleVisibleChange = () => {
        setVisibleRelated(!visibleRelated)
    }

    const handleVisibleChangeSLA = () => {
        setIsSLAOpen(!isSLAOpen)
    }

    const menuSLA = (
        <Menu>
            {/* <SlaTimeUsedUpPercentageEnhance incidentAndSLA={props.incident}/> */}
            <div style={{ padding: 15, minWidth: 350 }}>
                <Row gutter={[26, 26]} style={{ borderBottom: '1px solid rgb(224, 224, 224' }}>
                    <Col span={16}>
                        <div style={{ fontSize: 15, fontWeight: 500 }}>SLAs ( {incident.number} )</div>
                    </Col>
                    <Col span={8}>
                        <div style={{ textAlign: 'end' }}> <Icon type="close" onClick={() => setIsSLAOpen(false)} /></div>
                    </Col>
                </Row>
                <br />
                {buildTooltipTextForSlaTag(incident)}
            </div>
        </Menu>
    )
    console.log(props.isMedium)
    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col sm={24} md={16} lg={16} style={{ marginTop: 8, paddingBottom: 0, lineHeight: '25px' }}>
                    {props.isMedium ? <div style={{ textAlign: 'end', marginBottom: 5 }}>
                        <span >
                            {incident.viewed === false ? (<span>
                                <Tag color="#87d068">NEW</Tag>
                            </span>) : null}
                        </span>
                        <span>
                            {/* {incident.isRelated ? <Dropdown overlay={menuRelate} trigger={['click']} visible={visibleRelated} onVisibleChange={handleVisibleChange} >
                                <Tag color="#FAAD14">Related</Tag>
                            </Dropdown> : null} */}
                        </span>
                        <span>
                            {incident.isSla ? <Dropdown overlay={menuSLA} trigger={['click']} visible={isSLAOpen} onVisibleChange={handleVisibleChangeSLA} >
                                <Tag color="#F6712E">SLA</Tag>
                            </Dropdown> : null}
                            <div style={{ float: 'right', display: incident.isSatisfaction ? 'contents' : 'none' }}>
                                {!incident.satisfactionDone && (props.incident?.status?.value === 'Resolved' || props.incident.ticketStatus?.status === 'Resolved')
                                    ? <Icon type="info-circle" theme="filled" style={{ color: '#0050B3', cursor: 'pointer' }} onClick={() => isSATOpen()} /> : null}
                            </div>
                        </span>
                    </div> : null}
                    <Row gutter={[16, 16]}>
                        {props.isMedium ? <>
                            <Col sm={24} md={9} lg={9} style={{ paddingBottom: 0, paddingTop: 0, wordBreak: 'break-word' }}>
                                <Row>
                                    <Col span={10}>
                                        <span onClick={() => waitingGetSessions()}>
                                            <Link to={`/IncidentDetailsView/${props.incident.id}`} target="_blank" 
                                                // onClick={() => !props.incident.viewed ? markIncidentViewed(props.incident.id!!, dispatch) : undefined}
                                            >
                                                {props.incident.number}
                                            </Link>
                                        </span>
                                    </Col>
                                    <Col span={14}>{incident.subject.length > 50 ? (<>{incident.subject.substring(0, 50) + ' ...'}</>) : (<>{incident.subject}</>)}</Col>
                                </Row>
                            </Col>
                        </> : <>
                            <Col sm={6} md={4} lg={4} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                <span onClick={() => waitingGetSessions()}>
                                    <Link to={`/IncidentDetailsView/${props.incident.id}`} target="_blank" 
                                        // onClick={() => !props.incident.viewed ? markIncidentViewed(props.incident.id!!, dispatch) : undefined}
                                    >
                                        {props.incident.number}
                                    </Link>
                                </span>
                            </Col>
                            <Tooltip title={incident.subject} placement='topLeft'>
                                <Col sm={17} md={incident.viewed === false || incident.isRelated || incident.isSla ? 11 : 19} lg={incident.viewed === false || incident.isRelated || incident.isSla ? 11 : 119} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                    {incident.subject.length > 50 ? (<>{incident.subject.substring(0, 50) + ' ...'}</>) : (<>{incident.subject}</>)}
                                </Col>
                            </Tooltip>
                        </>}
                        {incident.viewed === false || incident.isRelated || incident.isSla
                            ? <Col sm={24} md={9} lg={9} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                <div style={{ textAlign: 'end', display: props.isMedium ? 'none' : 'block' }}>
                                    <span >
                                        {incident.viewed === false ? (<span>
                                            <Tag color="#87d068">NEW</Tag>
                                        </span>) : null}
                                    </span>
                                    <span>
                                        {incident.isRelated ? <Dropdown overlay={menuRelate} trigger={['click']} visible={visibleRelated} onVisibleChange={handleVisibleChange} >
                                            <Tag color="#FAAD14">Related</Tag>
                                        </Dropdown> : null}
                                    </span>
                                    <span>
                                        {incident.isSla ? <Dropdown overlay={menuSLA} trigger={['click']} visible={isSLAOpen} onVisibleChange={handleVisibleChangeSLA}>
                                            <Tag color="#F6712E">SLA</Tag>
                                        </Dropdown> : null}
                                        <div style={{ float: 'right', display: incident.isSatisfaction ? 'contents' : 'none'  }}>
                                            {!incident.satisfactionDone && (props.incident?.status?.value === 'Resolved' || props.incident.ticketStatus?.status === 'Resolved')
                                                ? <Icon type="info-circle" theme="filled" style={{ color: '#0050B3', cursor: 'pointer' }} onClick={() => isSATOpen()} /> : null}
                                        </div>
                                    </span>
                                </div>
                            </Col> : <Col sm={24} md={1} lg={1} style={{ paddingBottom: 0, paddingTop: 0, textAlign: 'end' }}>
                                <div style={{ float: 'right', display: incident.isSatisfaction ? 'contents' : 'none'  }}>
                                    {!incident.satisfactionDone && (props.incident?.status?.value === 'Resolved' || props.incident.ticketStatus?.status === 'Resolved')
                                        ? <Icon type="info-circle" theme="filled" style={{ color: '#0050B3', cursor: 'pointer' }} onClick={() => isSATOpen()} /> : null}
                                </div>
                            </Col>}
                    </Row>
                    <Row gutter={[16, 16]}>
                        {props.isMedium ? <>
                            <Col sm={24} md={9} lg={9} xl={9} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                <Row>
                                    <Col span={10}>Requester : </Col>
                                    <Col span={14}>{incident.requesterName || incident.people.fullName}</Col>
                                </Row>
                            </Col>
                        </> : <>
                            <Col sm={6} md={4} lg={4} xl={4} style={{ paddingBottom: 0, paddingTop: 0 }}><div>Requester :</div></Col>
                            <Col sm={18} md={11} lg={11} xl={11} style={{ paddingBottom: 0, paddingTop: 0 }}><div>{incident?.requesterName || incident?.people?.fullName}</div></Col>
                        </>}
                        {props.isMedium ? <Row style={{ padding: '0px 8px' }}>
                            <Col span={10}>Last modify date : </Col>
                            <Col span={14}>{moment(props.incident.lastModifiedDatetime).format(format)}</Col>
                        </Row> : <Col sm={24} md={9} lg={9} xl={9} style={{ paddingBottom: 0, paddingTop: 0 }}>
                            Last modify date : {moment(props.incident.lastModifiedDatetime).format(format)}
                        </Col>}
                    </Row>
                    <Row>
                        {props.isMedium ? <>
                            <Col sm={24} md={9} lg={9} xl={9} style={{ paddingBottom: 0 }}>
                                <Row>
                                    <Col span={10}>Created : </Col>
                                    <Col span={14}>{moment(props.incident.createdDatetime).format(format)}</Col>
                                </Row>
                            </Col>
                        </> : <>
                            <Col sm={6} md={4} lg={4} xl={4} style={{ paddingBottom: 0, paddingTop: 0 }}><div>Created : </div></Col>
                            <Col sm={18} md={11} lg={11} xl={11} style={{ paddingBottom: 0, paddingTop: 0 }}><div>&nbsp;{moment(props.incident.createdDatetime).format(format)}</div></Col>
                        </>}
                        <Col sm={24} md={9} lg={9} xl={9} style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: props.isMedium ? 0 : 10 }}>
                            <div><IncidentTimeIndex incident={incident} isMedium={props.isMedium} incidentState={props.incidentState} /></div>
                        </Col>
                    </Row>
                </Col>
                <Col sm={24} md={8} lg={8} style={{ paddingBottom: 0, borderLeft: '1px solid rgb(224, 224, 224' }}>
                    <Spin spinning={isSpinning}>
                        <Row gutter={[16, 16]} style={{ paddingBottom: 0, paddingLeft: 24, lineHeight: '24px' }}>
                            <Col sm={24} md={24} lg={24}>
                                <Row>
                                    <Col span={props.isMedium ? 11 : 8}>Assigned to :</Col>
                                    <Col span={props.isMedium ? 13 : 16}>
                                        <Tooltip
                                            placement="top" title={
                                                (incident.assignedGroups === null || incident.supportTeam === null) && (incident.assignedTo === null || incident.user === null) ? 'None' : (incident.assignedGroups?.value !== null || incident.supportTeam !== null) && (incident.assignedTo === null || incident.user === null) ? (incident.assignedGroups?.value || incident.supportTeam?.name) + ' / None' : (incident.assignedGroups?.value || incident.supportTeam?.name) + ' / ' + (incident.assignedTo?.value || incident.user?.fullName)}>
                                            <span>
                                                {(incident.assignedGroups?.value || incident.supportTeam) ? ((incident.assignedGroups?.value?.length || incident.supportTeam?.name.length || ''.length) > 18 ? (
                                                    <span>
                                                        {(incident.assignedGroups?.value || incident.supportTeam!!.name || '')?.substring(0, 18) + '... / '}{(incident?.assignedTo?.value || incident?.user?.fullName || '')?.toString().length > 18 ? (incident.assignedTo?.value || incident.user?.fullName!! || 'None')?.substring(0, 18) + '...' : (incident.assignedTo?.value || incident.user?.fullName!! || 'None')}
                                                    </span>) : (<span>
                                                        {(incident.assignedGroups?.value || incident.supportTeam?.name) + ' / '}{(incident.assignedTo?.value || incident.user?.fullName || '')?.length > 18 ? ((incident.assignedTo?.value || incident.user?.fullName) || 'None')?.substring(0, 18) + '...' : (incident.assignedTo?.value || incident.user?.fullName!! || 'None')}
                                                    </span>)) : (
                                                    <span>
                                                        None
                                                    </span>
                                                )
                                                }
                                            </span></Tooltip>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={props.isMedium ? 11 : 8}>Status :</Col>
                                    <Col span={props.isMedium ? 13 : 16}>
                                        {props.incident?.status?.value || props.incident.ticketStatus?.status}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={props.isMedium ? 11 : 8}>Priority :</Col>
                                    <Col span={props.isMedium ? 13 : 16}>
                                        {isTanentFreshService && incident.freshserviceTicketPriority !== null ? <>
                                            {props.priority.map((priority: PriorityLevel, index) => {
                                                return (
                                                    (incident.freshserviceTicketPriority?.priority?.name || incident.freshserviceTicketPriority?.name) === priority.name ? (
                                                        <Tag style={{ backgroundColor: priority.tagColor, color: '#fff' }}
                                                            key={index}>{(incident.freshserviceTicketPriority?.priority?.name || incident.freshserviceTicketPriority?.name)}</Tag>
                                                    ) : null
                                                )
                                            })}
                                        </> : <>
                                            {props.priority.map((priority: PriorityLevel, index) => {
                                                return (
                                                    (incident.priority?.priority?.name || incident.priority?.name) === priority.name ? (
                                                        <Tag style={{ backgroundColor: priority.tagColor, color: '#fff' }}
                                                            key={index}>{(incident.priority?.priority?.name || incident.priority?.name)}</Tag>
                                                    ) : null
                                                )
                                            })}
                                        </>}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Spin>
                </Col>
            </Row>
            <div>
                <Modal
                    visible={visibleModal}
                    title={setTitle()}
                    onOk={handleOkRequireField}
                    onCancel={handleCancelRequireField}
                    footer={[
                        <Button key="back" onClick={handleCancelRequireField}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" onClick={handleOkRequireField}
                            disabled={disableAssignTo} loading={isLoading}>
                            Save
                        </Button>
                    ]}
                >
                    <Form onSubmit={handleOkRequireField}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label={'Assignment group'}>
                                    {getFieldDecorator('Assignment_group'
                                        , {
                                            initialValue: incident.supportTeam?.name || incident.assignedGroups?.value,
                                            rules: [{
                                                required: isRequireAssignGroup,
                                                whitespace: true,
                                                message: 'Assignment Group is required.'
                                            }]
                                        })(
                                            <AutoComplete
                                                style={{ width: '100%' }}
                                                dataSource={(mySupportTeamName || []).map((it) => {
                                                    return it
                                                })}
                                                onChange={(e) => checkAssignee(e)}
                                                onSelect={(e) => FilterSupportTeam(e)}
                                                onBlur={(e) => onBlurTeam(e)}
                                                placeholder={'Select an assignment group'}
                                            />
                                        )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={24}>
                                <Form.Item label={'Assigned to'}>
                                    {getFieldDecorator('Assigned_to'
                                        , {
                                            initialValue: incident.user?.email || incident?.assignedTo?.value,
                                            rules: [{
                                                required: isRequireAssignee,
                                                whitespace: true,
                                                message: 'Assigned is required'
                                            }]
                                        }
                                    )(
                                        <AutoComplete style={{ width: '100%' }}
                                            dataSource={assignee}
                                            onFocus={() => FilterAssignee()}
                                            onBlur={(e) => onBlurAssignee(e)}
                                            placeholder={'Select an assignee'}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Modal title="Rate Your Experirnce" visible={isSatOpen} width={500} onCancel={() => setIsSatOpen(false)}
                    footer={false}>
                    <CustomerSat incident={incident} setModalClose={() => setIsSatOpen(false)} isOpenfromList={true} />
                </Modal>
            </div>
        </div>
    )
}

const WrappedIncidentRowEnhance = Form.create<Props>()(IncidentRowEnhance)
export default connect(
    mapStateToProps, {
    updateIncident,
    getAllWorkLogByIncidentId: getAllWorkLogByIncidentId,
    addSatisfaction,
    getAllGeneralIncidentSetting
})(WrappedIncidentRowEnhance)
