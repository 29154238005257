import React from 'react'

import {SlaCalculation, SlaCalculationFunc, SlaStage, SlaStatus} from '../../sla-management/sla-calculation/model'
import {IncidentWithSlaCalculations} from './model'
import {SlaGoal} from '../../sla-management/sla-policy/model'
import RunningClock, {RunningClockMode} from '../sla/RunningClock'
import {timeDiffAsString} from '../../common-time'
import moment from 'moment'
import { Col, Row, Tag, Tooltip } from 'antd'

interface Params {
    incident: IncidentWithSlaCalculations
    slaCalculation: SlaCalculation
    slaGoal: SlaGoal
    slaName: string
}

type Props = Params

interface State {
    isSoon: boolean
}

class SlaTimeUsedUpPercentage2 extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            isSoon: false
        }
    }

    getIsSoon(): boolean {
        return this.state.isSoon
    }

    setIsSoon(value: boolean) {
        this.setState({...this.state, isSoon: value})
    }

    matchStageAndStatus(stage: SlaStage, status: SlaStatus): boolean {
        return this.props.slaCalculation.slaStage === stage && this.props.slaCalculation.slaStatus === status
    }

    renderTagForFreshService(slaCalculation): JSX.Element | null {
        // กำหนดค่า input datetime
        const inputTime = moment(slaCalculation.slaTargetTime, "YYYY-MM-DD HH:mm:ss");

        // เวลาปัจจุบัน
        const now = moment();

        // คำนวณความต่างของเวลา
        let diff = inputTime.diff(now); // ค่าจะเป็นบวกหรือลบก็ได้
        const isNegative = diff < 0; // เช็คว่าค่าติดลบไหม
        diff = Math.abs(diff); // เอาค่า absolute เพื่อนำไปคำนวณ

        const duration = moment.duration(diff);

        // ดึงค่าที่สำคัญที่สุด 2 ตำแหน่ง
        const timeUnits = [
            { unit: "W", value: Math.floor(duration.asDays() / 7) },
            { unit: "D", value: Math.floor(duration.asDays() % 7) },
            { unit: "H", value: duration.hours() },
            { unit: "m", value: duration.minutes() },
            { unit: "s", value: duration.seconds() }
        ];

        // กรองเฉพาะค่าที่ไม่เป็น 0
        const filtered = timeUnits.filter(t => t.value > 0)
        // เลือกแค่ 2 ตำแหน่งแรก
        const result = filtered.slice(0, 2).map(t => `${t.value}${t.unit}`).join(" ")

        return (
            <span>
                {slaCalculation.currentlyPaused ? 
                <Tag style={{ marginLeft: 5 }}>Pending</Tag> : 
                <Tag style={{ marginLeft: 5 }} color={isNegative ? 'volcano' : 'green'}>{slaCalculation.slaStage !== 'Completed' ? isNegative ? `-${result}` : result || "0s" : 'Completed'}</Tag>}
            </span>
        )
    }

    renderIfInProgressMet(): JSX.Element | null {
        if (!this.matchStageAndStatus(SlaStage.InProgress, SlaStatus.Met)) {
            return null
        }
        const _this = this
        const timeLeftProvider = function (): moment.Duration {
            return SlaCalculationFunc.calculateInProgressTimeLeftDuration(_this.props.slaCalculation, _this.props.slaGoal)
        }
        const prefixProvider = function (): string {
            const timeGoal = SlaCalculationFunc.calculateSlaGoalTargetTime(_this.props.slaGoal)
            const percent = timeGoal.asSeconds() > 0 ? (timeLeftProvider().asSeconds() / timeGoal.asSeconds() * 100) : 100
            const soon = percent < 20
            _this.setIsSoon(soon)
            return `Due${soon ? ' soon' : ''} in next`
        }
        return (
            <Row gutter={[8, 8]} style={{color: '#4F4F4F'}}>
                <Col span={8}> <Tooltip placement="top" title={_this.props.slaName}> {_this.props.slaName.length >= 10 ? _this.props.slaName?.substring(0, 10) + '...' : _this.props.slaName} ({_this.props.slaCalculation.slaGoalName})</Tooltip></Col>
                {/* <span style={{color: this.getIsSoon() ? '#ED9E28' : '#2F80ED', fontSize: '18px'}}>• </span> */}
                <Col span={6} style={{ textAlign: 'center' }}><Tag color={this.getIsSoon() ? '#ED9E28' : '#2F80ED'} key={_this.props.slaCalculation.slaStatus}>{this.getIsSoon() ? 'Due Soon' : 'Due In'}</Tag> </Col>
                <Col span={8}><RunningClock prefixProvider={prefixProvider} durationProvider={timeLeftProvider} mode={RunningClockMode.TimeLeft}/></Col>
                <Col span={2}>{_this.renderTagForFreshService(_this.props.slaCalculation)}</Col>
            </Row>
        )
    }

    renderIfInProgressMissed(): JSX.Element | null {
        if (!this.matchStageAndStatus(SlaStage.InProgress, SlaStatus.Missed)) {
            return null
        }
        const _this = this
        const durationMissedProvider = function (): moment.Duration {
            return SlaCalculationFunc.calculateInProgressMissedDuration(_this.props.slaCalculation, _this.props.slaGoal)
        }
        return (
            <Row gutter={[8, 8]} style={{color: '#4F4F4F'}}>
                <Col span={8}> <Tooltip placement="top" title={_this.props.slaName}> {_this.props.slaName.length >= 10 ? _this.props.slaName?.substring(0, 10) + '...' : _this.props.slaName} ({_this.props.slaCalculation.slaGoalName})</Tooltip></Col>
                <Col span={6} style={{color: '#EB5757', fontSize: 18, textAlign: 'center'}}> <span><Tag color={'#EB5757'} key={_this.props.slaCalculation.slaStatus}>Missed</Tag> </span></Col>
                <Col span={8}><RunningClock durationProvider={durationMissedProvider} mode={RunningClockMode.TimeElapsed}/></Col>
                <Col span={2}>{_this.renderTagForFreshService(_this.props.slaCalculation)}</Col>
            </Row>
        )
    }

    renderIfCompletedMissed(): JSX.Element | null {
        if (!this.matchStageAndStatus(SlaStage.Completed, SlaStatus.Missed)) {
            return null
        }
        const _this = this
        return (
            <Row gutter={[8, 8]} style={{color: '#4F4F4F'}}>
                <Col span={8}> <Tooltip placement="top" title={_this.props.slaName}> {_this.props.slaName.length >= 10 ? _this.props.slaName?.substring(0, 10) + '...' : _this.props.slaName} ({_this.props.slaCalculation.slaGoalName})</Tooltip></Col>
                <Col span={6} style={{
                    color: '#726E6D',
                    textAlign: 'center'
                }}><span><Tag color={'#726E6D'} key={_this.props.slaCalculation.slaStatus}>Overdue</Tag> </span> </Col>
                <Col span={8}>{timeDiffAsString(_this.props.slaCalculation.slaTargetTime, _this.props.slaCalculation.measurementClockStopTime!!, true)}</Col>
                <Col span={2}>{_this.renderTagForFreshService(_this.props.slaCalculation)}</Col>
            </Row>
        )
    }

    renderIfCompletedMet(): JSX.Element | null {
        const _this = this
        if (!this.matchStageAndStatus(SlaStage.Completed, SlaStatus.Met)) {
            return null
        }
        return (
            <Row gutter={[8, 8]}>
                <Col span={9} ><Tooltip placement="top" title={_this.props.slaName}> {_this.props.slaName.length >= 10 ? _this.props.slaName?.substring(0, 10) + '...' : _this.props.slaName} ({_this.props.slaCalculation.slaGoalName})</Tooltip> </Col>
                <Col span={6} style={{ textAlign: 'center'}}><span style={{color: '#4DC977'}}><Tag color={'green'} key={_this.props.slaCalculation.slaStatus}>Completed</Tag></span> </Col>
                <Col span={9} >Completed in time</Col>
            </Row>
        )
    }

    render() {
        return (
            <>
                {this.renderIfInProgressMissed()}
                {this.renderIfInProgressMet()}
                {this.renderIfCompletedMissed()}
                {this.renderIfCompletedMet()}
                {/* {
                props.slaStage === 'Cancelled' && props.slaStatus === 'Cancelled'
                    ? (<Badge color="#4F4F4F" text={'Cancelled SLA'} />)
                    : null
                    <Badge color="#2F80ED" text={'Completed in time'} />
            } */}
            </>
        )
    }
}

export default SlaTimeUsedUpPercentage2
