import { Button, Checkbox, Col, Icon, Input, message, Modal, Row, Spin, Tag } from 'antd'
import Form, { FormComponentProps } from 'antd/lib/form/Form'
import React, { useEffect, useState } from 'react'
import { CompactPicker } from 'react-color'
import { addOrUpdateGeneralProblems, getAllGeneralProblems } from '../states/service'
import { generalProblem, ProblemCloseReasonStatu } from '../states/model'
import { Redirect } from 'react-router-dom'

interface params {
    nameForm: string
    TypeProblemid?: string
}
const { confirm } = Modal
type Props = params & FormComponentProps
const CustomManageColors: React.FC<Props> = (props: Props) => {
    const {getFieldDecorator, getFieldValue, resetFields, validateFields} = props.form
    const [tagColor, setTagColor] = useState('#4D4D4D')
    const [listColor, setListColor] = useState<ProblemCloseReasonStatu[]>([])
    const [data, setData] = useState<generalProblem>()
    const [showTag, setShowTag] = useState(true)
    const [validateStatus, setValidateStatus] = useState(false)
    const [isCheck, setIsCheck] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isRedirect, setIsRedirect] = useState(false)

    useEffect(() => {
        if (props.TypeProblemid) {
            setIsLoading(true)
            setListColor([])
            getAllGeneralProblems(props.TypeProblemid).then((data) => {
                console.log(data)
                setData(data)
                setListColor(data.problemCloseReasonStatus)
                if (data.reAnalysis) {
                    setIsCheck(true)
                } else {
                    setIsCheck(false)
                }
            }).catch(() => {
                setData(undefined)
            }).finally(() => { setIsLoading(false) })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.TypeProblemid])

    const onChangeCheck = () => {
        setIsCheck(!isCheck)
    }

    const handleAddData = () => {
        if (getFieldValue(`ClosedReason_${props.nameForm}`)?.trim().length > 2) {
            setValidateStatus(false)
            const name = getFieldValue(`ClosedReason_${props.nameForm}`)?.trim()
            const data: ProblemCloseReasonStatu = {
                tagColor: tagColor,
                name: name,
                active: true
            }
            const checkDublicated = listColor.find((it: ProblemCloseReasonStatu) => { return it.name === name })
            if (!checkDublicated) {
                listColor.push(data)
                setListColor(listColor)
                setShowTag(true)
                resetFields([`ClosedReason_${props.nameForm}`])
            } else {
                resetFields([`ClosedReason_${props.nameForm}`])
            }
        } else {
            setValidateStatus(true)
        }
    }

    const handleCloseTag = (item) => {
        const filterData = listColor?.filter((color) => { return color.tagColor !== item.tagColor })
        setListColor(filterData)
    }

    const renderPriorityTag = () => {
        return listColor?.map((item) => {
            return (
                <Tag
                    key={item.name}
                    color={item.tagColor}
                    style={{ margin: '5px' }}
                    // onClick={() => onClickTag(item)}
                    onClose={() => handleCloseTag(item)}
                    closable={true}
                >
                    {item.name}
                </Tag>
            )
        })
    }

    const renderTitel = () => {
        if (props.nameForm === '1') {
            return 'General - Problem'
        } else if (props.nameForm === '2g1') {
            return 'General - Problem task'
        } else if (props.nameForm === '3g1') {
            return 'General - Problem task'
        }
    }

    const renderSubTitel = () => {
        if (props.nameForm === '1') {
            return 'Problem'
        } else if (props.nameForm === '2g1') {
            return 'Problem task  - Root cause analysis'
        } else if (props.nameForm === '3g1') {
            return 'Problem task  - General'
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        validateFields((_err, values) => {
            if (!_err) {
                const addKeyOrderNumber = listColor.map((data, index) => {
                    return {
                        ...data,
                        orderNumber: index
                    }
                })
                const convertData: any = {
                    id: data?.id || null,
                    pbTypeId: Number(props.TypeProblemid),
                    reAnalysis: isCheck,
                    problemCloseReasonStatus: addKeyOrderNumber
                }
                console.log(convertData)
                setIsLoading(true)
                addOrUpdateGeneralProblems(convertData).then(() => {
                    message.success('The Update has finished successfully')
                }).catch((error) => {
                    message.error(error.response.data.message || 'Please complete all custom fields.')
                }).finally(() => {
                    setIsLoading(false)
                })
            }
        })
    }

    const onChangeClosedReason = (e) => {
        if (e?.trim()?.length < 3) {
            setValidateStatus(true)
        } else {
            setValidateStatus(false)
        }
    }

    const leavePageModal = () => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you want to leave this page ?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                setIsRedirect(true)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    return (
        <div>
            {isRedirect ? (<Redirect to="/setting" />) : null}
            <Spin spinning={isLoading}>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col span={21} xs={24} sm={24} md={12} lg={12}>
                            <p className='text-herder-Orenge'>{renderTitel()}</p>
                        </Col>
                        <Col span={3} style={{ textAlign: 'end'}} xs={24} sm={24} md={12} lg={12}>
                            <span>
                                <Button onClick={() => leavePageModal()}>Cancel</Button>
                                <Button type='primary' style={{ marginLeft: 10 }} htmlType='submit' disabled={listColor.length === 0}>Submit</Button>
                            </span>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} >
                            <div style={{ backgroundColor: '#F8F8F9', padding: 15, borderRadius: 5, marginRight: 5 }}>
                                <div className='common-font-topic'>{renderSubTitel()}</div>
                                <Form.Item label={<><span style={{ color: 'red' }}>*</span> Closed Reason</>} className='custom-formLabel-wo' validateStatus={validateStatus ? 'error' : undefined} help={validateStatus ? getFieldValue(`ClosedReason_${props.nameForm}`)?.trim().length < 3 ? 'Please enter more than 3 characters.' : 'Please enter your Closed Reason.' : ''}>
                                    {getFieldDecorator(`ClosedReason_${props.nameForm}`, {
                                        rules: [{required: false, message: 'Please enter your Closed Reason.'}, { min: 3, message: 'Please enter more than 3 characters.' }, {whitespace: true}]
                                    })(
                                        <Input placeholder='impact' onChange={(e) => onChangeClosedReason(e.target.value)}/>
                                    )}
                                </Form.Item>
                                <br/>
                                <CompactPicker
                                    color={tagColor}
                                    onChangeComplete={(color) => setTagColor(color.hex)}
                                    className='custom-compact-picker'
                                />
                                <br/>
                                <br/>
                                <div style={{ textAlign: 'end' }}>
                                    <Button type='primary' htmlType='button' onClick={() => handleAddData()}><Icon type='plus' /> Add</Button>
                                </div>
                            </div>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} >
                            <div style={{ backgroundColor: '#F8F8F9', padding: 15, borderRadius: 5, minHeight: 280, marginLeft: 5 }}>
                                <div className='bold-titleWo'>Display Close Reason</div>
                                <div style={{ backgroundColor: '#fff', borderRadius: '15px', height: '150px', padding: 5, marginTop: 10, border: '1px solid #323276' }}>
                                    { showTag ? renderPriorityTag() : null}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    {props.nameForm === '1' ? <Row>
                        <Col span={24}>
                            <div style={{ backgroundColor: '#F8F8F9', padding: 15, borderRadius: 5 }}>
                                <div className='common-font-topic'>Re-Analysis Setting</div>
                                <br/>
                                <Checkbox onChange={onChangeCheck} checked={isCheck}><b>Re-Analysis when status problem is Closed</b></Checkbox>
                            </div>
                        </Col>
                    </Row> : null}
                </Form>
            </Spin>

        </div>
    )
}

const CustomManageColorsFields = Form.create<Props>()(CustomManageColors)
export default CustomManageColorsFields
