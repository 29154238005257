import React, {useEffect, useState} from 'react'
import {Button, Card, Form, Icon, Input, message, Modal, Spin} from 'antd'
import {FormComponentProps} from 'antd/es/form'
import Logo from '../assets/images/LogoGoService.png'
import {UserLogin} from '../authorization-module/user-role/duck/model'
import Footer from '../common-components/DefaultFooter'
import {getRequestParamMapping, loginMSP, verify2fa} from '../authorization-module/login/service'
import {Link} from 'react-router-dom'
import {createHmac} from 'crypto'
import {tokenDecode} from '../common-misc'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'

import ImageBg01 from '../assets/images/BG01.png'
import ImageBg02 from '../assets/images/BG02.png'
import ImageBg03 from '../assets/images/BG03.png'
import ImageBg04 from '../assets/images/BG04.png'
import Modal2FA from './Modal2FA'
import {decryptBody} from '../common-misc/encryptBody'
import {deleteCookie, getCookie, setCookie} from '../guard/route'
// import moment from 'moment'

type Props = FormComponentProps

const Login: React.FC<Props> = (props: Props) => {
    const {getFieldDecorator, getFieldValue} = props.form
    const [isLogin, setIsLogin] = useState<boolean>(false)
    const [isFetchData, setIsFetchData] = useState<boolean>(false)
    const [isVisible, setIsVisible] = useState<boolean>(false)
    const [isGenQRCode, setIsGenQRCode] = useState(false)
    const [validateCode, setValidateCode] = useState(false)
    const [isSubmitted2fa, setIsSubmitted2fa] = useState(false)
    // console.log(moment(1680307200 * 1000).format('DD MMM YYYY hh:mm a'))
    useEffect(() => {
        // work around for ntl site
        deleteCookie('access_token')
        sessionStorage.clear()
        getRequestParamMapping().then(r => {
            setCookie('param', r, 1)
            localStorage.setItem('param', r)
        }
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const service = tokenDecode()?.service.serviceId || []
        const checkTokenExpire = getCookie('access_token')
        if (checkTokenExpire && service) {
            const decoded: any = jwt_decode(checkTokenExpire)
            const exp = decoded?.exp || 0
            if (Date.now() < (exp * 1000)) {
                // if (service[0] === 1) {
                //     window.location.href = '/Dashboard'
                // }
                // if (service[0] === 2) {
                //     window.location.href = '/assetList'
                // }
                // if (service[0] === 3) {
                //     window.location.href = '/Preventive'
                // }
                // if (service[0] === 4) {
                //     window.location.href = '/WoList'
                // }
                // if (service[0] === 5) {
                //     window.location.href = '/Knowledge'
                // }
                window.location.href = '/home'
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin])

    const handleSubmit = e => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                setIsFetchData(true)
                const secret = 'MSPTeamWuhoo'
                const password = createHmac('sha256', secret)
                    .update(values.password)
                    .digest('hex')

                const convertData: UserLogin = {
                    username: values.username,
                    password: password
                }
                loginMSP(convertData).then(res => {
                    // sessionStorage.setItem('mspUser', '1')
                    if (res) {
                        sessionStorage.setItem('username', values.username)
                        const searchResult = decryptBody(res.data.data!!)
                        console.log(searchResult)
                        if (searchResult.token !== null) {
                            setIsLogin(true)
                        } else {
                            setIsGenQRCode(searchResult.isGenerateQrCode)
                            setIsVisible(true)
                        }
                    }
                }).catch((_err) => {
                    console.log(_err)
                    if (_err) {
                        if (_err.response) {
                            if (_err?.response?.status === 401) {
                                message.error(_err.response.data.message)
                            } else if (_err?.response?.status === 403) {
                                message.error("Sorry, you don't have permission to access this data.")
                            } else if (_err?.response?.status === 404) {
                                message.error('Email address not found. Please check and try again.')
                            } else {
                                message.error('Something went wrong. Please try again later.')
                            }
                        }
                    } else {
                        message.error('Connection timed out.')
                    }
                }).finally(() => {
                    setIsLogin(false)
                    setIsFetchData(false)
                })
            }
        })
    }

    const getCode = (code) => {
        setIsSubmitted2fa(true)
        setValidateCode(false)
        verify2fa(code).then((res) => {
            if (res) {
                setIsLogin(true)
                setCookie('access_token', res.token, 1)
            }
        }).catch(() => {
            setValidateCode(true)
        }).finally(() => {
            setIsSubmitted2fa(false)
        })
    }

    const handleCloseModal = (value) => {
        setIsVisible(value)
    }

    return (
        <>
            <div style={{
                backgroundColor: '#323276',
                height: '100%'
                // color: '#ffffff'

            }}>
                <img src={ImageBg01} className='background-left-form-login' alt='bg1'/>
                <img src={ImageBg02} className='background-right-form-login' alt='bg2'/>
                <img src={ImageBg03} className='background-bottom-left-form-login' alt='bg3'/>
                <img src={ImageBg04} className='background-bottom-right-form-login' alt='bg4'/>

                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-50px',
                    marginRight: '-100px',
                    transform: 'translate(-50%, -50%)'
                }}>

                    <img src={Logo} alt={'logo'} style={{
                        marginTop: '10%',
                        width: '420px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingBottom: '2%'
                    }}/>

                    <Card
                        style={{minWidth: 'auto', margin: 'auto', borderRadius: '1%', backgroundColor: '#fff'}}
                        bodyStyle={{height: 380, padding: 52}}
                    >
                        <Spin spinning={isFetchData}>
                            <Form onSubmit={handleSubmit}>
                                <div style={{
                                    color: '#323276',
                                    textAlign: 'center',
                                    paddingBottom: 2,
                                    fontSize: '20px',
                                    fontWeight: 'bold'
                                }}>Log in
                                </div>
                                <Form.Item>
                                    <div style={{lineHeight: '270%'}}>
                                        <span className="text-login">Email</span>
                                        {getFieldDecorator('username', {
                                            rules: [{required: true, message: 'Please enter your email address.'}, {
                                                type: 'email',
                                                message: 'The email address is not valid.'
                                            }
                                            ]
                                        })(
                                            <Input
                                                prefix={<Icon type="user"
                                                    style={{color: 'rgba(0,0,0,.25)', marginLeft: '2%'}}/>}
                                                placeholder="Email"
                                                className='inputLogin'
                                            />
                                        )}
                                    </div>

                                </Form.Item>
                                <Form.Item>
                                    <div style={{lineHeight: '270%'}}>
                                        <span className="text-login">Password</span>
                                        {getFieldDecorator('password', {
                                            rules: [{required: true, message: 'Please input your Password'}]
                                        })(
                                            <Input
                                                prefix={<Icon type="lock"
                                                    style={{color: 'rgba(0,0,0,.25)', marginLeft: '2%'}}/>}
                                                type="password"
                                                placeholder="Password"
                                                className='inputLogin'
                                            />
                                        )}
                                    </div>
                                </Form.Item>
                                <Form.Item>
                                    <div style={{lineHeight: '20px'}}>
                                        <Link className="login-form-forgot" to={'/account/forgotPassword'}
                                            style={{color: '#1A73E8'}}>Forgot password</Link>
                                    </div>
                                    <Button type="primary" htmlType="submit" className="login-form-button">
                                        Log in
                                    </Button>
                                </Form.Item>

                                {/* <div style={{ textAlign: 'center' }}><span style={{ color: '#828282' }}>Are you a new user? </span><Link to={'/freeRegister'} style={{ color: '#1A73E8' }}> Register for 60 Days free trial</Link></div> */}
                            </Form>
                        </Spin>
                        <Modal
                            title=""
                            visible={isVisible}
                            onOk={() => setIsVisible(false)}
                            closable={false}
                            footer={null}
                            bodyStyle={{padding: 0}}
                            // onCancel={() => setIsVisible(false)}
                        >
                            <Modal2FA email={getFieldValue('username') || ''} getCode={getCode}
                                isgenQRCode={isGenQRCode} validateCode={validateCode} isSubmitted={isSubmitted2fa}
                                handleCloseModal={handleCloseModal}/>
                        </Modal>
                    </Card>
                </div>
            </div>

            <Footer/>
        </>

    )
}

const WrappedNormalLoginForm = Form.create(
    {
        name: 'normal_login'
    }
)(Login)

export default WrappedNormalLoginForm
