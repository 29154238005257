import { Col, Form, Row, Radio, Icon, Checkbox, InputNumber, DatePicker, Input, Button } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import moment from 'moment'
import React from 'react'
import {Redirect} from 'react-router-dom'

interface Params {
    current: number
    isCancel: boolean
    setIsCancel: Function
    setCurrent: Function
    getData: Function
}

type Props = Params & FormComponentProps
const ChoosePackage: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, getFieldValue } = props.form

    const onCancel = () => {
        props.setIsCancel(true)
    }

    const nextCurrent = () => {
        props.form.validateFields((err, values) => {
            if (!err) {
                console.log(values)
                props.getData(values)
                props.setCurrent(props.current + 1)
            }
        })
    }

    const disabledTime = (current, disable?) => {
        // Disable times before 10:00 AM and after 6:00 PM
        if (disable) {
            return current && current < moment(disable).startOf('day')
        } else {
            return current && current < moment().endOf('day')
        }
    }

    const disabledTimeEnd = (current, disable) => {
        // Disable times before 10:00 AM and after 6:00 PM
        return current && current < moment(disable).add({ months: 1 }).startOf('day')
    }

    return (
        <div style={{ padding: '2%', margin: '2% 5%', border: '1px solid #E5E5E5', borderRadius: '10px' }}>
            {props.isCancel ? (<Redirect to="/SubscriptionList" />) : null}
            <Form>
                <Row>
                    <Col span={12} style={{ borderRight: '1px solid #E5E5E5' }}>
                        <h3><b><span style={{ color: 'red' }}>* </span> Choose Services </b></h3>
                        <Form.Item>
                            {getFieldDecorator('chooseServices', {
                                rules: [{ required: true}]
                            })(
                                <Checkbox.Group style={{ width: '100%', lineHeight: 'unset', marginLeft: 25 }}>
                                    <Row>
                                        <Col span={12}>
                                            <Checkbox value="Ticket"><Icon type="warning" /> Ticket Management</Checkbox>
                                        </Col>
                                        <Col span={12}>
                                            <Checkbox value="Asset"><Icon type="laptop" /> Asset Management</Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Checkbox value="Problem"><Icon type="security-scan" /> Problem Management</Checkbox>
                                        </Col>
                                        <Col span={12}>
                                            <Checkbox value="Knowledge"><Icon type="bulb" /> Knowledge Management</Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Checkbox value="WorkOrder"><Icon type="profile" /> Work Order Management</Checkbox>
                                        </Col>
                                        <Col span={12}>
                                            <Checkbox value="Preventive"><Icon type="tool" /> Preventive Management</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            )}
                        </Form.Item>
                        <br />
                        <h3><b>Service Configuration</b></h3>
                        <br/>
                        <div style={{marginLeft: 25}}>
                            <Row>
                                <Col span={6} style={{ paddingTop: 3 }}><h3>Storage size</h3></Col>
                                <Col span={4}>
                                    <Form.Item >
                                        {getFieldDecorator('Storage', {
                                            initialValue: 0 // getOrder?.packageSubscription.find((it) => it.packages.packageType === 's')?.subscriptionTotal
                                        })(
                                            <InputNumber min={0} max={1000} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={14} style={{ paddingTop: 7 }}>Unit ( Baht per unit)</Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col span={6} style={{ paddingTop: 3 }}><h3>Database size</h3></Col>
                                <Col span={4}>
                                    <Form.Item >
                                        {getFieldDecorator('Database', {
                                            initialValue: 0 // getOrder?.packageSubscription.find((it) => it.packages.packageType === 'd')?.subscriptionTotal
                                        })(
                                            <InputNumber min={0} max={1000} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={14} style={{ paddingTop: 7 }}>Unit ( Baht per unit)</Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col span={6} style={{ paddingTop: 3 }}><h3>Go service Access</h3></Col>
                                <Col span={4}>
                                    <Form.Item >
                                        {getFieldDecorator('User', {
                                            initialValue: 0 // getOrder?.packageSubscription.find((it) => it.packages.packageType === 'u')?.subscriptionTotal
                                        })(
                                            <InputNumber min={0} max={1000} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={14} style={{ paddingTop: 7 }}>User ( Baht per user)</Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col span={6} style={{ paddingTop: 3 }}><h3>Self-Portal Access</h3></Col>
                                <Col span={4}>
                                    <Form.Item >
                                        {getFieldDecorator('People', {
                                            initialValue: 0 // getOrder?.packageSubscription.find((it) => it.packages.packageType === 'p')?.subscriptionTotal
                                        })(
                                            <InputNumber min={0} max={1000}/>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={14} style={{ paddingTop: 7 }}>User ( Baht per user)</Col>
                            </Row>
                            <br/>
                        </div>
                        <h3><b><span style={{ color: 'red' }}>* </span> Started Package - End  Package </b></h3>
                        <br/>
                        <div style={{marginLeft: 25}}>
                            <Row>
                                <Col span={10}>
                                    <Form.Item >
                                        {getFieldDecorator('startDate', {
                                            rules: [{ required: true }]
                                        })(
                                            <DatePicker placeholder='Start Date' style={{ width: '95%'}} disabledDate={disabledTime}/>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item >
                                        {getFieldDecorator('endDate', {
                                            rules: [{ required: true }]
                                        })(
                                            <DatePicker placeholder='Ent Date' style={{ width: '95%'}} disabledDate={(current) => disabledTimeEnd(current, getFieldValue('startDate'))}/>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={4} style={{ textAlign: 'end', marginTop: 7, paddingRight: 30 }}>
                                    <div>{moment(getFieldValue('endDate')).diff(moment(getFieldValue('startDate')), 'days')} Day</div>
                                </Col>
                            </Row>
                        </div>
                        <br/>
                        <h3><b><span style={{ color: 'red' }}>* </span> Do you want me to clear all the data to start over? </b></h3>
                        <br/>
                        <div style={{marginLeft: 25}}>
                            <Form.Item >
                                {getFieldDecorator('isClear', {
                                    initialValue: 1,
                                    rules: [{ required: true }]
                                })(
                                    <Radio.Group>
                                        <Radio value={1}>Store Information</Radio>
                                        <Radio value={2}>Clear All Data</Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={12} style={{ paddingLeft: '2%' }}>
                        <h3><b><span style={{ color: 'red' }}>* </span> Infomation </b></h3>
                        <div style={{ color: '4F4F4F'}}>Please check the accuracy.</div>
                        <br/>
                        <div style={{marginLeft: 25}}>
                            <Form.Item label='Company Name'>
                                {getFieldDecorator('companyName', {
                                    rules: [{required: true }]
                                })(
                                    <Input placeholder='Company Name' style={{ width: '100%' }}/>
                                )}
                            </Form.Item>
                            <Form.Item label='Tax ID'>
                                {getFieldDecorator('taxID')(
                                    <Input placeholder='Tax ID' style={{ width: '100%' }}/>
                                )}
                            </Form.Item>
                            <Form.Item label='Billing Address'>
                                {getFieldDecorator('billingAddress', {
                                    rules: [{required: true }]
                                })(
                                    <Input.TextArea rows={4} autoSize={{ minRows: 4, maxRows: 4}} placeholder='Billing Address' style={{ width: '100%' }}/>
                                )}
                            </Form.Item>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item label='First Name'>
                                        {getFieldDecorator('firstName', {
                                            rules: [{required: true }]
                                        })(
                                            <Input placeholder='First Name' style={{ width: '100%' }}/>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label='Last Name'>
                                        {getFieldDecorator('lastName', {
                                            rules: [{required: true }]
                                        })(
                                            <Input placeholder='Last Name' style={{ width: '100%' }}/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item label='Phone Number'>
                                {getFieldDecorator('phone', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please enter your Phone Number.'
                                        },
                                        {
                                            pattern: /^[0-9\b]+$/,
                                            message: 'Please enter a 10-digit number.'
                                        }
                                    ]
                                })(
                                    <Input placeholder='Phone Number' style={{ width: '100%' }}/>
                                )}
                            </Form.Item>
                        </div>
                    </Col>
                </Row>

                <div style={{ textAlign: 'end' }}>
                    <Button onClick={onCancel} style={{ marginRight: 5 }}>
                            Cancel
                    </Button>
                    <Button type="primary" onClick={nextCurrent}>
                            Next <Icon type="right" />
                    </Button>
                </div>
            </Form>
        </div>
    )
}

const MyChoosePackage = Form.create<Props>({ name: 'ChoosePackage' })(ChoosePackage)
export default MyChoosePackage
