import React from 'react'
import { Empty, Table, Tag, Tooltip } from 'antd'
import moment from 'moment'
import { ForIncident } from '../../sla-management/sla-calculation/service'
import { connect } from 'react-redux'
import { StoreState } from '../../store'
import { FetchingState, fetchWithState } from '../../common-components'
import TimeLeft from './TimeLeft'
import { SlaCalculationFunc } from '../../sla-management/sla-calculation/model'
import RunningClock, { RunningClockMode } from './RunningClock'

const mapStateToProps = (state: StoreState) => {
    return {
        slaCalculationDataSuit: state.incidentState?.slaCalculationDataSuit
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllSlaCalculationByTicketIds: (ticketIds: string[]) => Promise<number>
}

interface Params {
    incidentId: string
    incidentStatus: string
    pendingUntil?: any
    isShowListPage?: boolean
}

type Props = StateProps & DispatchProps & Params

interface State {
    slaInformation: object[] | undefined
    slaCalculationDataSuitFetchingState: FetchingState
}

class SlaDisplay extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            slaInformation: undefined,
            slaCalculationDataSuitFetchingState: FetchingState.NotStarted
        }
    }

    setSlaInformation(value: object[] | undefined) {
        this.setState({ ...this.state, slaInformation: value })
    }

    setSlaCalculationDataSuitFetchingState(value: FetchingState) {
        this.setState({ ...this.state, slaCalculationDataSuitFetchingState: value })
    }

    componentDidMount(): void {
        this.fetchSlaCalculation()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.slaCalculationDataSuit !== this.props.slaCalculationDataSuit) {
            this.setSlaInformation(this.buildSlaInformation())
        }
    }

    fetchSlaCalculation() {
        const _this = this
        async function f() {
            return _this.props.getAllSlaCalculationByTicketIds([_this.props.incidentId])
        }
        fetchWithState(this.state.slaCalculationDataSuitFetchingState, (s) => { this.setSlaCalculationDataSuitFetchingState(s as FetchingState) }, f)
    }

    buildSlaInformation(): object[] {
        if (!this.props.slaCalculationDataSuit) {
            return []
        }

        const dataState: object[] = []
        console.log(this.props.slaCalculationDataSuit)
        const slaCalculationsMap = this.props.slaCalculationDataSuit!!.slaCalculationsMap

        const slaPolicyHistories = this.props.slaCalculationDataSuit!!.slaPolicyHistories
        const businessHourHistories = this.props.slaCalculationDataSuit!!.businessHourHistories

        for (const incidentId in slaCalculationsMap) {
            const slaCalculations = slaCalculationsMap[incidentId]
            for (const slaCalculation of slaCalculations) {
                const policyHistoryId = slaCalculation.slaPolicyHistoryId
                const businessHourHistoryId = slaCalculation.businessHourHistoryId
                const policyHistory = slaPolicyHistories.find((ph: any) => ph.id === policyHistoryId)
                const businessHourHistory = businessHourHistories.find((bh: any) => bh.id === businessHourHistoryId)
                let calculateDuedate: string = ''
                let timeRemaining: JSX.Element
                console.log('slaStage : ', slaCalculation.slaStage)
                console.log('slaStatus : ', this.props.incidentStatus)
                if (slaCalculation.slaStage === 'InProgress' && this.props.incidentStatus === 'Pending') {
                    calculateDuedate = 'Pending'
                    timeRemaining = <TimeLeft until={this.props.pendingUntil} wordingWhenTimedUp="Time's up" />
                } else {
                    calculateDuedate = moment(slaCalculation.slaTargetTime).format('YYYY-MM-DD HH:mm:ss')
                    const slaPolicyHistory = slaPolicyHistories?.find((it) => it.id === slaCalculation.slaPolicyHistoryId)
                    const slaGoal = slaPolicyHistory?.slaGoals?.find((it) => it.name === slaCalculation.slaGoalName)
                    if (slaCalculation.slaStage === 'InProgress') {
                        if (slaCalculation.slaStatus === 'Met') {
                            const durationProvider = function (): moment.Duration {
                                if (slaPolicyHistory && slaGoal) {
                                    return SlaCalculationFunc.calculateInProgressTimeLeftDuration(slaCalculation, slaGoal)
                                } else {
                                    return moment.duration(0)
                                }
                            }
                            timeRemaining = <RunningClock durationProvider={durationProvider} mode={RunningClockMode.TimeLeft} wordingWhenCountDownToZero="Time's up" />
                        } else {
                            const durationProvider = function (): moment.Duration {
                                if (slaPolicyHistory && slaGoal) {
                                    return SlaCalculationFunc.calculateInProgressMissedDuration(slaCalculation, slaGoal)
                                } else {
                                    return moment.duration(0)
                                }
                            }
                            timeRemaining = <RunningClock durationProvider={durationProvider} mode={RunningClockMode.TimeElapsed} />
                        }
                    } else if (slaCalculation.slaStage === 'Completed') {
                        if (slaCalculation.slaStatus === 'Met') {
                            const durationProvider = function (): moment.Duration {
                                if (slaPolicyHistory && slaGoal) {
                                    return SlaCalculationFunc.calculateCompletedTimeLeftDuration(slaCalculation, slaGoal)
                                } else {
                                    return moment.duration(0)
                                }
                            }
                            timeRemaining = <RunningClock durationProvider={durationProvider} mode={RunningClockMode.TimeLeft} wordingWhenCountDownToZero="Time's up" />
                        } else {
                            const durationProvider = function (): moment.Duration {
                                if (slaPolicyHistory && slaGoal) {
                                    return SlaCalculationFunc.calculateCompletedOverusedDuration(slaCalculation, slaGoal)
                                } else {
                                    return moment.duration(0)
                                }
                            }
                            timeRemaining = <RunningClock durationProvider={durationProvider} mode={RunningClockMode.TimeElapsed} />
                        }
                        timeRemaining = <span className="sladisplay-stage-complete">{timeRemaining}</span>
                    } else {
                        timeRemaining = <>-</>
                    }
                }

                dataState.push({
                    key: incidentId,
                    name: policyHistory?.name,
                    slagoal: slaCalculation.slaGoalName,
                    starttime: moment(slaCalculation.measurementClockStartTime).format('YYYY-MM-DD HH:mm:ss'),
                    businesshours: businessHourHistory?.name,
                    duedate: calculateDuedate,
                    timeremaining: timeRemaining,
                    slastage: slaCalculation.slaStage,
                    tags: ['' + slaCalculation.slaStatus + '']
                })
            }
        }
        return dataState
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        // Table
        const columns = [
            {
                title: 'SLA Name',
                dataIndex: 'name',
                key: 'name',
                // eslint-disable-next-line react/display-name
                render: (text: any) => <span>{text}</span>
            },
            {
                title: 'SLA Goal',
                dataIndex: 'slagoal',
                key: 'slagoal'
            },
            {
                title: 'Business Hours',
                dataIndex: 'businesshours',
                key: 'businesshours'
            },
            {
                title: 'Start Time',
                dataIndex: 'starttime',
                key: 'starttime'
            },
            {
                title: 'Due Date',
                dataIndex: 'duedate',
                key: 'duedate',
                render: (data, row) => {
                    // กำหนดค่า input datetime
                    const inputTime = moment(data, "YYYY-MM-DD HH:mm:ss");

                    // เวลาปัจจุบัน
                    const now = moment();

                    // คำนวณความต่างของเวลา
                    let diff = inputTime.diff(now); // ค่าจะเป็นบวกหรือลบก็ได้
                    const isNegative = diff < 0; // เช็คว่าค่าติดลบไหม
                    diff = Math.abs(diff); // เอาค่า absolute เพื่อนำไปคำนวณ

                    const duration = moment.duration(diff);

                    // ดึงค่าที่สำคัญที่สุด 2 ตำแหน่ง
                    const timeUnits = [
                        { unit: "W", value: Math.floor(duration.asDays() / 7) },
                        { unit: "D", value: Math.floor(duration.asDays() % 7) },
                        { unit: "H", value: duration.hours() },
                        { unit: "m", value: duration.minutes() },
                        { unit: "s", value: duration.seconds() }
                    ];
                    console.log(row)
                    // กรองเฉพาะค่าที่ไม่เป็น 0
                    const filtered = timeUnits.filter(t => t.value > 0);
                    // เลือกแค่ 2 ตำแหน่งแรก
                    const result = filtered.slice(0, 2).map(t => `${t.value}${t.unit}`).join(" ");
                    return <span>
                    {data === 'Pending' ? 'Pending' : moment(data).format('YYYY-MM-DD HH:mm:ss')}
                    {data === 'Pending' ? '' : <Tag style={{ marginLeft: 5 }} color={isNegative ? 'volcano' : 'green'}>{row.slastage !== 'Completed' ? isNegative ? `-${result}` : result || "0s" : 'Completed'}</Tag>}
                </span>
                }
            },
            {
                title: 'Time Remain/Overuse',
                dataIndex: 'timeremaining',
                key: 'timeremaining'
            },
            {
                title: 'SLA Stage',
                dataIndex: 'slastage',
                key: 'slastage'
            },
            {
                title: 'SLA Status',
                key: 'tags',
                dataIndex: 'tags',
                // eslint-disable-next-line react/display-name
                render: (tags: { map: (arg0: (tag: any) => JSX.Element) => React.ReactNode; }) => (
                    <span>
                        {tags.map(tag => {
                            let color = tag.length > 5 ? 'geekblue' : 'green'
                            if (tag === 'Missed') {
                                color = 'volcano'
                            }
                            return (
                                <Tag color={color} key={tag}>
                                    {tag.toUpperCase()}
                                </Tag>
                            )
                        })}
                    </span>
                )
            }
        ]

        const columns2 = [
            {
                title: 'Time Remain/Overuse',
                dataIndex: 'timeremaining',
                key: 'timeremaining'
            },
            {
                title: '',
                key: 'tags',
                dataIndex: 'tags',
                // eslint-disable-next-line react/display-name
                render: (tags: { map: (arg0: (tag: any) => JSX.Element) => React.ReactNode; }) => (
                    <span>
                        {tags.map(tag => {
                            let color = tag.length > 5 ? 'geekblue' : 'green'
                            if (tag === 'Missed') {
                                color = 'volcano'
                            }
                            return (
                                <Tag color={color} key={tag}>
                                    {tag.toUpperCase()}
                                </Tag>
                            )
                        })}
                    </span>
                )
            }
        ]
        console.log(this.state.slaInformation)
        return (
            <>
                {this.state.slaInformation ? <Table columns={this.props.isShowListPage ? columns2 : columns} dataSource={this.state.slaInformation} key={`slaInformation${this.props.slaCalculationDataSuit?.versionTimestamp}`} scroll={this.props.isShowListPage ? {} : { x: 'max-content' }} pagination={this.props.isShowListPage ? false : undefined} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </>
        )
    }
}

export default connect(
    mapStateToProps
    , { getAllSlaCalculationByTicketIds: ForIncident.getAllSlaCalculationByTicketIds }
)(SlaDisplay)
