import React, { useEffect, useState } from 'react'
import {StoreState} from '../../store'
import {connect} from 'react-redux'
import moment from 'moment'
import ViewSelection from './ViewSelection'
import {IncidentFilter, notifyCurrentViewCriteriaChanged} from './index'
import {AgentFilter, CategoryFilter, CreatedFilter, PriorityFilter, RequesterFilter} from './incident-filter'
import StatusFilter from './incident-filter/StatusFilter'
import {message} from 'antd'
import {Categories} from '../category'
import {PriorityLevel} from '../priority'
import {IncidentViewState} from './state-model'
import {TicketStatus} from '../incident'
import TicketTypeFilter from './incident-filter/TicketTypeFilter'
import {ResponseValueWithId} from '../general-incident-setting'
import { getAllStatusUnique } from '../status'

interface StateProps {
    statuses: TicketStatus[]
    categories: Categories[]
    impacts: PriorityLevel[]
    priorities: PriorityLevel[]
    incidentViewState: IncidentViewState
    criteria: IncidentFilter
    notifyCurrentViewCriteriaChanged: (criteria: IncidentFilter) => Promise<void>
    channels: ResponseValueWithId[]
    ticketTypes: ResponseValueWithId[]
    isLoading: Function
}

type Props = StateProps

const IncidentFilterForm: React.FC<Props> = (props: Props) => {
    // const [loading, setLoading] = useState<boolean>(false)
    const [criteria, setCriteria] = useState<IncidentFilter | undefined>(props.incidentViewState?.currentView?.criteria || props.incidentViewState?.currentView?.incFilter)
    const [TicketStatus, setTicketStatus] = useState<TicketStatus[]>([])
    // let criteria = props.incidentViewState?.currentView?.criteria || props.incidentViewState?.currentView?.incFilter

    useEffect(() => {
        getAllStatusUnique().then((res) => {
            setTicketStatus(res)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.incidentViewState?.currentView?.criteria || props.incidentViewState?.currentView?.incFilter) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // criteria = props.incidentViewState?.currentView?.criteria || props.incidentViewState?.currentView?.incFilter
            setCriteria(props.incidentViewState?.currentView?.criteria || props.incidentViewState?.currentView?.incFilter)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.incidentViewState])

    const notifyCurrentViewCriteriaChanged =
        props.notifyCurrentViewCriteriaChanged as (IncidentFilter) => Promise<undefined>

    function modifyCriteria(criteria: IncidentFilter) {
        notifyCurrentViewCriteriaChanged(criteria).catch((error) => message.error(error.toString()))
    }

    const onAgentCriteriaChanged = (value: string[]) => {
        modifyCriteria({
            ...criteria,
            assignedToMe: value.includes('assignedToMe'),
            unassigned: value.includes('unassigned'),
            assignedToMyTeam: value.includes('assignedToMyTeam')
        })
    }

    const onCategoryChanged = (value: string[]) => {
        const tempValue = value.length > 0 ? value.join(',') : undefined
        modifyCriteria({...criteria, categories: tempValue})
    }

    const onRequesterChanged = (value: string | undefined) => {
        modifyCriteria({...criteria, requesterName: value})
    }

    const onTicketTypeChanged = (value: string[]) => {
        const tempValue = value.length > 0 ? value.join(',') : undefined
        modifyCriteria({...criteria, ticketTypes: tempValue})
    }

    const onStatusChanged = (value: string[]) => {
        const tempValue = value.length > 0 ? value.join(',') : undefined
        modifyCriteria({...criteria, statuses: tempValue})
    }

    const onPriorityChanged = (value: string[]) => {
        const tempValue = value.length > 0 ? value.join(',') : undefined
        modifyCriteria({...criteria, priorities: tempValue})
    }

    const onCreatedValueChanged = (value: string | undefined | string[]) => {
        const patch: IncidentFilter = {}
        if (typeof value === 'string' || value === undefined) {
            patch.created = value as string
            if (value === undefined) {
                patch.created = 'anytime'
                patch.createdStart = undefined
                patch.createdEnd = undefined
            }
        } else {
            const format = (time: string) => moment(time).format('YYYY-MM-DD')
            patch.createdStart = format(value[0])
            patch.createdEnd = format(value[1])
            patch.created = undefined
        }
        modifyCriteria({...criteria, ...patch})
    }

    const reload = () => {
        props.isLoading(true)
        setCriteria(props.incidentViewState?.currentView?.incFilter)
        notifyCurrentViewCriteriaChanged(props.incidentViewState?.currentView?.incFilter)
    }

    return (
        <>
            <ViewSelection reload={reload} />
            <h3 className={'sub-title'}>Filter</h3>
            <span className='feck-link' onClick={() => {
                reload()
            }}>Reset to Default</span><br/><br/>
            {/* <Spin spinning={loading}> */}
            <AgentFilter key={0} onChanged={onAgentCriteriaChanged} criteria={criteria} isLoading={props.isLoading}/><br/>
            <TicketTypeFilter criteria={criteria} onChanged={onTicketTypeChanged} ticketTypes={props.ticketTypes} isLoading={props.isLoading}/><br/>
            <RequesterFilter key={1} onChanged={onRequesterChanged} criteria={criteria} isLoading={props.isLoading}/><br/>
            {/* <DueByFilter key={2} onChanged={onDueByChanged} criteria={criteria}/><br/> */}

            <CreatedFilter key={3} onChanged={onCreatedValueChanged} criteria={criteria} isLoading={props.isLoading}/><br/>
            <StatusFilter key={4} statuses={TicketStatus} onChanged={onStatusChanged} criteria={criteria} isLoading={props.isLoading}/><br/>
            <PriorityFilter key={6} priorities={props.priorities} onChanged={onPriorityChanged} criteria={criteria} isLoading={props.isLoading}/><br/>
            <CategoryFilter key={5} categories={props.categories} onChanged={onCategoryChanged} criteria={criteria} isLoading={props.isLoading}/><br/>
            {/* </Spin> */}
        </>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        statuses: state.statuses,
        categories: state.categories,
        impacts: state.impacts,
        priorities: state.priorities,
        incidentViewState: state.incidentViewState,
        criteria: state.incidentViewState.currentView!!.criteria,
        channels: state.generalIncidentSetting?.channels!!,
        ticketTypes: state.generalIncidentSetting?.ticketTypes!!
    }
}

export default connect(mapStateToProps, {
    notifyCurrentViewCriteriaChanged
})(IncidentFilterForm)
