import React, { useEffect, useState } from 'react'
import { Button, Card, Checkbox, Col, Icon, message, Modal, Row } from 'antd'
import { Asset, Preventive, PreventiveWithAsset, pmUploadLink } from '../Model'
import { UploadFile } from 'antd/lib/upload/interface'
import FSS from '../../file-server-storage'
import { UploadLink } from '../../file-server-storage/model'
import SignaturePad from 'react-signature-canvas'
import styles from './styles.module.css'
import moment from 'moment'
import { Redirect } from 'react-router-dom'
import exportIcon from '../../common-file/icon-file'
import './detailPrint.css'
import { updatePreventiveById } from '../services'
import { PMActivityField } from '../../configurable-fields'

interface Param {
    Group?: string
    isView: boolean
    dataPM?: PreventiveWithAsset
    dataAsset?: Asset
    isOnline: boolean
    AssetID?: number
    anotherUser: string
    dataPMComplete?: Preventive
}

type Props = Param
const DetailPMForPrint: React.FC<Props> = (props: Props) => {
    const [isShowSignPadModal, setIsShowSignPadModal] = useState<boolean>(false)
    const [isCheck, setIsCheck] = useState<boolean>(false)
    const [Who, setWho] = useState<string>()
    const [isRedirect, SetIsRedirect] = useState<boolean>(false)
    const [userSignature, setUserSignature] = useState<string>()
    const [userSignatureDate, setUserSignatureDate] = useState<string>()
    const [pmTeamSignature, setPmTeamSignature] = useState<string>()
    const [pmTeamSignatureDate, setPmTeamSignatureDate] = useState<string>()
    const [pmApproveSignature, setPmApproveSignature] = useState<string>()
    const [pmApproveSignatureDate, setPmApproveSignatureDate] = useState<string>()
    const [another, setIsAnother] = useState<boolean>(false)
    const [fullNameOwner, setFullNameOwner] = useState<string>()
    const filterPmAdjusted = props.dataPMComplete?.pmAdjust

    const filterCustomFieldsAssetDetails = props.dataPMComplete?.pendingReason && props.dataPMComplete?.pmAdjust?.pmAdjustData === null
        ? props.dataPMComplete?.pmAdjust?.originalAsset?.customField?.filter((it) => {
            return it.headerGroup === 'AssetDetails' && it.isShowPm === true
        })
        : props.dataPMComplete?.pmAdjust?.pmAdjustData?.customField?.filter((it) => {
            return it.headerGroup === 'AssetDetails' && it.isShowPm === true
        })
    let sigPad: any = {}

    useEffect(() => {
        if (props.dataPMComplete) {
            if (props.dataPMComplete.userSignature) {
                const file = JSON.parse(props.dataPMComplete.userSignature)
                const uploadFile: UploadFile = {
                    uid: file.name || file[0].name,
                    name: file.name || file[0].name,
                    status: 'done',
                    url: file.url || file[0].url,
                    type: file.type || file[0].type,
                    thumbUrl: exportIcon(file.type!! || file[0].type),
                    size: 0
                }
                setUserSignature(uploadFile.url)
                setUserSignatureDate(props.dataPMComplete?.userSignatureDatetime)
                setFullNameOwner(JSON.parse(props.dataPMComplete?.userSignature || '')?.fullName)
            }
            if (props.dataPMComplete.pmTeamSignature) {
                const file = JSON.parse(props.dataPMComplete.pmTeamSignature)
                const uploadFile: UploadFile = {
                    uid: file.name || file[0].name,
                    name: file.name || file[0].name,
                    status: 'done',
                    url: file.url || file[0].url,
                    type: file.type || file[0].type,
                    thumbUrl: exportIcon(file.type!! || file[0].type),
                    size: 0
                }
                setPmTeamSignature(uploadFile.url)
                setPmTeamSignatureDate(props.dataPMComplete.pmTeamSignatureDatetime)
            }
            if (props.dataPMComplete.pmApproverSignature) {
                const file = JSON.parse(props.dataPMComplete.pmApproverSignature)
                const uploadFile: UploadFile = {
                    uid: file.name || file[0].name,
                    name: file.name || file[0].name,
                    status: 'done',
                    url: file.url || file[0].url,
                    type: file.type || file[0].type,
                    thumbUrl: exportIcon(file.type!! || file[0].type),
                    size: 0
                }
                setPmApproveSignature(uploadFile.url)
                setPmApproveSignatureDate(props.dataPMComplete.pmApproverSignatureDatetime)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataPMComplete])

    useEffect(() => {
        if (props.dataPM) {
            if (props.dataPM.preventiveMaintenance.userSignature) {
                const file = JSON.parse(props.dataPM.preventiveMaintenance.userSignature)
                const uploadFile: UploadFile = {
                    uid: file.name || file[0].name,
                    name: file.name || file[0].name,
                    status: 'done',
                    url: file.url || file[0].url,
                    type: file.type || file[0].type,
                    thumbUrl: exportIcon(file.type!! || file[0].type),
                    size: 0
                }
                setUserSignature(uploadFile.url)
                setUserSignatureDate(props.dataPM.preventiveMaintenance.userSignatureDatetime)
            }
            if (props.dataPM.preventiveMaintenance.pmTeamSignature) {
                const file = JSON.parse(props.dataPM.preventiveMaintenance.pmTeamSignature)
                const uploadFile: UploadFile = {
                    uid: file.name || file[0].name,
                    name: file.name || file[0].name,
                    status: 'done',
                    url: file.url || file[0].url,
                    type: file.type || file[0].type,
                    thumbUrl: exportIcon(file.type!! || file[0].type),
                    size: 0
                }
                setPmTeamSignature(uploadFile.url)
                setPmTeamSignatureDate(props.dataPM.preventiveMaintenance.pmTeamSignatureDatetime)
            }
            if (props.dataPM.preventiveMaintenance.pmApproverSignature) {
                const file = JSON.parse(props.dataPM.preventiveMaintenance.pmApproverSignature)
                const uploadFile: UploadFile = {
                    uid: file.name || file[0].name,
                    name: file.name || file[0].name,
                    status: 'done',
                    url: file.url || file[0].url,
                    type: file.type || file[0].type,
                    thumbUrl: exportIcon(file.type!! || file[0].type),
                    size: 0
                }
                setPmApproveSignature(uploadFile.url)
                setPmApproveSignatureDate(props.dataPM.preventiveMaintenance.pmApproverSignatureDatetime)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataPM])

    useEffect(() => {
        if (!props.anotherUser || props.anotherUser === '' || props.anotherUser === null) {
            setIsAnother(false)
        } else {
            setIsAnother(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [another, props.anotherUser])

    const onChangeCheckBox = (e: { target: { checked: any; }; }) => {
        console.log(`checked = ${e.target.checked}`)
        setIsCheck(e.target.checked)
    }

    const renderPMActivity = (PM?: Preventive) => {
        const tempCheck: PMActivityField[] = []
        if (PM?.pmActivityCustomField) {
            (PM?.pmActivityCustomField || []).forEach((res) => {
                if (res.customField) {
                    tempCheck.push({ ...res.customField!!, remark: res.remark })
                }
            })
        }
        return <>
            {tempCheck.map((res, index) => {
                if (res) {
                    return <div key={index} className="striped">{index + 1}. {res.activityLabel}
                        {res.description ? <div style={{ paddingLeft: '1em', color: '#828282' }} >{res.description}</div> : null}
                        {res.remark ? <div style={{ paddingLeft: '1em' }} >Remark : <span style={{ color: '#828282' }}>{res.remark}</span></div> : null}
                    </div>
                } else {
                    return null
                }
            })}
        </>
    }

    const clearSign = () => {
        try {
            sigPad.clear()
        } catch (e) {
            sigPad = {}
        }
    }

    const confirm = () => {
        if (sigPad.isEmpty()) {
            return
        }

        const base64String = sigPad.getTrimmedCanvas().toDataURL('image/png')
        clearSign()

        UpdateSign(base64String)
        setIsShowSignPadModal(false)
    }

    const UpdateSign = (base64String: string) => {
        const convertData = props.dataPM!!
        const Time = moment().format('YYYY-MM-DD HH:mm:ss')
        if (Who === 'User') {
            convertData.preventiveMaintenance.userSignature = base64String
            convertData.preventiveMaintenance.userSignatureDatetime = Time
            setUserSignature(base64String)
            setUserSignatureDate(Time)
        } else if (Who === 'Engineer') {
            convertData.preventiveMaintenance.pmTeamSignature = base64String
            convertData.preventiveMaintenance.pmTeamSignatureDatetime = Time
            setPmTeamSignature(base64String)
            setPmTeamSignatureDate(Time)
        } else if (Who === 'Approved') {
            convertData.preventiveMaintenance.pmApproverSignature = base64String
            convertData.preventiveMaintenance.pmApproverSignatureDatetime = Time
            setPmApproveSignature(base64String)
            setPmApproveSignatureDate(Time)
        }
        if (convertData.preventiveMaintenance.pmAdjust) {
            convertData.preventiveMaintenance.pmAdjust = filterPmAdjusted
        }
        convertData.preventiveMaintenance.asset = props.dataPM?.asset!!
        if (convertData.preventiveMaintenance.userSignature && convertData.preventiveMaintenance.pmTeamSignature) {
            convertData.preventiveMaintenance.pmStatus = 'Completed'
        }

        if (props.isOnline) {
            const stringLength = base64String.length - 'data:image/png;base64,'.length
            const fileSignature: UploadFile = {
                uid: 'signature_pm' + Who + moment(),
                name: 'signature_pm' + Who + moment(),
                status: 'done',
                url: base64String,
                type: 'image/png',
                size: stringLength
            }
            const fileList: UploadFile[] = []
            fileList.push(fileSignature)
            FSS.putFile(fileList, 'pm', (uploadLink: UploadLink[]) => {
                // const signature = { attachedSignatureReplace: JSON.stringify(uploadLink) }
                if (Who === 'User') {
                    convertData.preventiveMaintenance.userSignature = JSON.stringify(uploadLink[0])
                } else if (Who === 'Engineer') {
                    convertData.preventiveMaintenance.pmTeamSignature = JSON.stringify(uploadLink[0])
                } else if (Who === 'Approved') {
                    convertData.preventiveMaintenance.pmApproverSignature = JSON.stringify(uploadLink[0])
                }
                updatePreventiveById(convertData.preventiveMaintenance.id!!, convertData.preventiveMaintenance).then((res) => {
                    if (res) {
                        setIsCheck(false)
                        message.success('Update Success')
                        if (convertData.preventiveMaintenance.userSignature && convertData.preventiveMaintenance.pmTeamSignature) {
                            SetIsRedirect(true)
                        }
                    }
                }).catch((err) => {
                    setIsCheck(false)
                    message.error('Unable to update ticket. Please check and try again.' + err)
                })
            })
        } else {
            const getKeys = sessionStorage.getItem('key')
            if (getKeys) {
                const keys: string[] = JSON.parse(getKeys)
                keys.push(convertData.asset.assetTag)
                sessionStorage.setItem('key', JSON.stringify([...new Set(keys)]))
            } else {
                sessionStorage.setItem('key', JSON.stringify([convertData.asset.assetTag]))
            }
            convertData.isUpdate = true
        }
    }

    const checkWhoSign = (e: string) => {
        setWho(e)
        setIsShowSignPadModal(true)
    }

    const renderImages = (data: pmUploadLink[] | string) => {
        const dataArray = typeof data === 'string' ? JSON.parse(data) : data

        return dataArray?.map((item, index) => {
            if (item.url) {
                return <Col md={6} sm={12} xs={24} className='page-break' key={index}>
                    <div style={{ textAlign: 'center', border: '1px solid #d9d9d9', borderRadius: '4px' }}>
                        <img src={item.url} alt={'img' + index} className='image-container' />
                    </div>
                </Col>
            } else {
                return null
            }
        })
    }

    return (
        <div>
            {isRedirect ? <Redirect to="/Preventive" /> : null}
            <div className="herder-form">Preventive Maintenance Form</div>
            <Modal
                title="Signature"
                visible={isShowSignPadModal}
                footer={false}
            >
                <Checkbox onChange={onChangeCheckBox} checked={isCheck}><span>By sign this form, I am hereby that I have already checked and confirmed that all the information and the equipment is in correct as specified in the document and have, therefore, affixed my signature below.</span>
                    <br />
                    <span>โดยการลงนามในแบบฟอร์มนี้ ข้าพเจ้าขอรับรองว่าได้ตรวจสอบและยืนยันว่าข้อมูลและอุปกรณ์ทั้งหมดถูกต้อง
                        ตามที่ระบุไว้ในเอกสาร และได้ลงลายมือชื่อไว้ด้านล่างแล้ว</span></Checkbox>
                <br /><br />
                <SignaturePad ref={(ref) => {
                    sigPad = ref
                }} canvasProps={{ className: styles.sigPad }} clearOnResize={true} />
                <br />
                <div style={{ textAlign: 'end' }}>
                    <Button htmlType="button" onClick={() => {
                        setIsShowSignPadModal(false)
                        clearSign()
                    }}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="button" className="ml5" onClick={confirm} disabled={!isCheck}>
                        Submit
                    </Button>
                </div>
            </Modal>
            {props.dataPMComplete?.attachFile && JSON.parse(props.dataPMComplete?.attachFile).length > 0 ? <><Row gutter={[16, 16]} type='flex'>
                <Col span={24}>
                    <Card title="Original Images Asset" className="card-back">
                        {renderImages(props.dataPMComplete?.attachFile ?? [])}
                    </Card>
                </Col>
            </Row> <br /></> : null}

            {props.dataPMComplete?.pmUploadLink.find((it) => it.name !== null) ? <><Row gutter={[16, 16]} type='flex'>
                <Col span={24}>
                    <Card title="Desired Image for PM" className="card-back">
                        {renderImages(props.dataPMComplete?.pmUploadLink ?? [])}
                    </Card>
                </Col>
            </Row> <br /> </> : null}

            <Row>
                <Col span={24}>
                    <Card title="Asset Details" className="card-back page-break">
                        <Col span={12} xs={24} sm={24} md={24} lg={12}>
                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <div>Serial Number :</div>
                                </Col>
                                <Col span={12}>
                                    <div>{filterPmAdjusted?.pmAdjustData?.serialNumber || props.dataPMComplete?.pmAdjust?.originalAsset?.serialNumber || props.dataAsset?.serialNumber || '-'}</div>
                                </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <div>Asset Status :</div>
                                </Col>
                                <Col span={12}>
                                    <div>{filterPmAdjusted?.pmAdjustData?.status || props.dataPMComplete?.pmAdjust?.originalAsset?.status || props.dataAsset?.status || '-'}</div>
                                </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <div>Asset Tag :</div>
                                </Col>
                                <Col span={12}>
                                    <div>{filterPmAdjusted?.pmAdjustData?.assetTag || props.dataPMComplete?.pmAdjust?.originalAsset?.assetTag || props.dataAsset?.assetTag || '-'}</div>
                                </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <div>Asset Name :</div>
                                </Col>
                                <Col span={12}>
                                    <div>{filterPmAdjusted?.pmAdjustData?.assetName || props.dataPMComplete?.pmAdjust?.originalAsset?.assetName || props.dataAsset?.assetName || '-'}</div>
                                </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <div>Manufacturer :</div>
                                </Col>
                                <Col span={12}>
                                    <div>{filterPmAdjusted?.pmAdjustData?.manufacturer || props.dataPMComplete?.pmAdjust?.originalAsset?.manufacturer || props.dataAsset?.manufacturer || '-'}</div>
                                </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <div>Model :</div>
                                </Col>
                                <Col span={12}>
                                    <div>{filterPmAdjusted?.pmAdjustData?.model || props.dataPMComplete?.pmAdjust?.originalAsset?.model || props.dataAsset?.model || '-'}</div>
                                </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <div>Brand :</div>
                                </Col>
                                <Col span={12}>
                                    <div>{filterPmAdjusted?.pmAdjustData?.brand || props.dataPMComplete?.pmAdjust?.originalAsset?.brand || props.dataAsset?.brand || '-'}</div>
                                </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <div>Type :</div>
                                </Col>
                                <Col span={12}>
                                    <div>{filterPmAdjusted?.pmAdjustData?.assetGroupType?.type || props.dataPMComplete?.pmAdjust?.originalAsset?.assetGroupType?.type || props.dataAsset?.assetGroupType?.type || '-'}</div>
                                </Col>
                            </Row>

                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <div>Owner ID :</div>
                                </Col>
                                <Col span={12}>
                                    <div>{filterPmAdjusted?.pmAdjustData?.people?.employeeId || props.dataPMComplete?.pmAdjust?.originalAsset?.people?.employeeId || props.dataAsset?.people?.employeeId || '-'}</div>
                                </Col>
                            </Row>

                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <div>Owner Name :</div>
                                </Col>
                                <Col span={12}>
                                    <div>{filterPmAdjusted?.pmAdjustData?.people?.fullName || props.dataPMComplete?.pmAdjust?.originalAsset?.people?.fullName || props.dataAsset?.people?.fullName || '-'}</div>
                                </Col>
                            </Row>

                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <div>Department :</div>
                                </Col>
                                <Col span={12}>
                                    <div>{filterPmAdjusted?.pmAdjustData?.people?.employeeId ? filterPmAdjusted?.pmAdjustData?.people?.department?.departmentName : filterPmAdjusted?.pmAdjustData?.people?.department?.departmentName || props.dataPMComplete?.pmAdjust?.originalAsset?.people?.department?.departmentName || props.dataAsset?.people?.department?.departmentName}</div>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={12} xs={24} sm={24} md={24} lg={12}>
                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <div>Site :</div>
                                </Col>
                                <Col span={12}>
                                    <div>{filterPmAdjusted?.pmAdjustData?.site || props.dataPMComplete?.pmAdjust?.originalAsset?.site || props.dataAsset?.site || '-'}</div>
                                </Col>
                            </Row>

                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <div>Location :</div>
                                </Col>
                                <Col span={12}>
                                    <div>{filterPmAdjusted?.pmAdjustData?.location || props.dataPMComplete?.pmAdjust?.originalAsset?.location || props.dataAsset?.location || '-'}</div>
                                </Col>
                            </Row>

                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <div>Building :</div>
                                </Col>
                                <Col span={12}>
                                    <div>{filterPmAdjusted?.pmAdjustData?.building || props.dataPMComplete?.pmAdjust?.originalAsset?.building || props.dataAsset?.building || '-'}</div>
                                </Col>
                            </Row>

                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <div>Floor :</div>
                                </Col>
                                <Col span={12}>
                                    <div>{filterPmAdjusted?.pmAdjustData?.floor || props.dataPMComplete?.pmAdjust?.originalAsset?.floor || props.dataAsset?.floor || '-'}</div>
                                </Col>
                            </Row>

                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <div>Zone :</div>
                                </Col>
                                <Col span={12}>
                                    <div>{filterPmAdjusted?.pmAdjustData?.zone || props.dataPMComplete?.pmAdjust?.originalAsset?.zone || props.dataAsset?.zone || '-'}</div>
                                </Col>
                            </Row>

                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <div>Paired IT Asset :</div>
                                </Col>
                                <Col span={12}>
                                    <div>{filterPmAdjusted?.pmAdjustData?.pairedItAsset || props.dataPMComplete?.pmAdjust?.originalAsset?.pairedItAsset || props.dataAsset?.pairedItAsset || '-'}</div>
                                </Col>
                            </Row>

                            {filterCustomFieldsAssetDetails ? filterCustomFieldsAssetDetails?.map((data, index) => {
                                return <Row gutter={[8, 8]} key={index}>
                                    <Col span={12}>
                                        <div>{data.label} :</div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{data.valueAdjust || data.value}</div>
                                    </Col>
                                </Row>
                            }) : null}

                        </Col>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={24}>
                    <Card title="PM Activity" className="card-back page-break">
                        {props.dataPMComplete?.pendingReason ? null : renderPMActivity(props.dataPM?.preventiveMaintenance || props.dataPMComplete)}
                    </Card>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={24}>
                    <Card title="PM Team" className="card-back page-break">
                        <Row gutter={[8, 8]}>
                            <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>PM Name :</div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{props.dataPM?.preventiveMaintenance?.pmUser.fullName || props.dataPMComplete?.pmUser.fullName || '-'}</div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Completed Date :</div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{props.dataPMComplete?.completedDatetime || props.dataPMComplete?.lastModifiedDatetime || '-'}</div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={24}>
                    <Card title="Issue" className="card-back page-break">
                        <p>{props.dataPM?.preventiveMaintenance?.commentPicture || props.dataPMComplete?.commentPicture}</p>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={24}>
                    <Card title="Reason" className="card-back page-break">
                        <Row>
                            <Col span={24}>
                                <p>{props.dataPMComplete?.reworkReason ? <>Rework Reason : {props.dataPMComplete.reworkReason}</> : null}</p>
                                <p>{props.dataPMComplete?.otherReason || props.dataPMComplete?.pendingReason ? <>Pending Reason : {props.dataPMComplete?.otherReason || props.dataPMComplete?.pendingReason}</> : null}</p>
                                <p>{props.dataPMComplete?.isPmApproved && props.dataPMComplete?.ignoredReason ? <div>Approve Reason : {props.dataPMComplete?.ignoredReason}</div> : null}</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row >
                <Col span={24}>
                    <Checkbox checked={true}>By sign this form, I am hereby that I have already checked and confirmed
                        that all the information and the equipment is in correct as specified in the document and have,
                        therefore, affixed my signature below.
                        โดยการลงนามในแบบฟอร์มนี้ ข้าพเจ้าขอรับรองว่าได้ตรวจสอบและยืนยันว่าข้อมูลและอุปกรณ์ทั้งหมดถูกต้อง
                        ตามที่ระบุไว้ในเอกสาร และได้ลงลายมือชื่อไว้ด้านล่างแล้ว</Checkbox>
                </Col>
            </Row>
            <br />
            <Row gutter={[8, 8]} >
                <Col span={props.dataPMComplete?.isPmApproved ? 8 : 12} md={props.dataPMComplete?.isPmApproved ? 8 : 12} xl={props.dataPMComplete?.isPmApproved ? 8 : 12} xs={24} sm={8}>
                    <div className="box-signature page-break">
                        <p>Owner - Sign Confirm</p>
                        <p>Sign Date : {userSignatureDate || '-'}</p>
                        <div style={{ height: 80, textAlign: 'center' }}>{userSignature
                            ? <img src={userSignature} alt='userSignature'
                                style={{ maxWidth: '100%', maxHeight: '100%' }} /> : null}</div>
                        <br />
                        <Row>
                            <Col span={22}>
                                <hr style={{ marginTop: 20 }} />
                            </Col>
                            <Col span={2}><p> {!props.isView && !userSignature
                                ? <span className="feck-link"><Icon type='edit'
                                    onClick={() => checkWhoSign('User')} /></span> : null}</p>
                            </Col>
                        </Row>
                        <div
                            style={{ textAlign: 'center' }}>( {filterPmAdjusted?.pmAdjustData?.people?.fullName || props.dataPM?.preventiveMaintenance?.asset?.people?.fullName || props.dataPMComplete?.asset?.people?.fullName || fullNameOwner} )
                        </div>
                    </div>
                </Col>
                <Col span={props.dataPMComplete?.isPmApproved ? 8 : 12} md={props.dataPMComplete?.isPmApproved ? 8 : 12} xl={props.dataPMComplete?.isPmApproved ? 8 : 12} xs={24} sm={8}>
                    <div className="box-signature page-break">
                        <p>PM Team - Sign Confirm</p>
                        <p>Sign Date : {pmTeamSignatureDate || '-'}</p>
                        <div style={{ height: 80, textAlign: 'center' }}>{pmTeamSignature
                            ? <img src={pmTeamSignature} alt='pmTeamSignature'
                                style={{ maxWidth: '100%', maxHeight: '100%' }} /> : null}</div>
                        <br />
                        <Row>
                            <Col span={22}>
                                <hr style={{ marginTop: 20 }} />
                            </Col>
                            <Col span={2}><p>{!props.isView && !pmTeamSignature
                                ? <span className="feck-link"><Icon type='edit' onClick={() => checkWhoSign('Engineer')} /></span> : null}</p>
                            </Col>
                        </Row>
                        <div
                            style={{ textAlign: 'center' }}>( {props.dataPM?.preventiveMaintenance?.pmUser?.fullName || props.dataPMComplete?.pmUser.fullName} )
                        </div>
                    </div>
                </Col>
                {props.dataPMComplete?.isPmApproved ? <Col span={8} md={8} xl={8} xs={24} sm={8}>
                    <div className="box-signature page-break">
                        <p>PM Approve - Sign Confirm</p>
                        <p>Sign Date : {pmApproveSignatureDate || '-'}</p>
                        <div style={{ height: 80, textAlign: 'center' }}>{pmApproveSignature
                            ? <img src={pmApproveSignature} alt='pmApproveSignature'
                                style={{ maxWidth: '100%', maxHeight: '150%' }} /> : null}</div>
                        <br />
                        <Row>
                            <Col span={22}>
                                <hr style={{ marginTop: 20 }} />
                            </Col>
                            <Col span={2}><p>{!props.isView && !pmApproveSignature
                                ? <span className="feck-link"><Icon type='edit' onClick={() => checkWhoSign('Approved')} /></span> : null}</p>
                            </Col>
                        </Row>
                        <div
                            style={{ textAlign: 'center' }}>( {props.dataPMComplete?.pmApprover?.fullName || props.dataPMComplete?.pmApprover?.fullName} )
                        </div>
                    </div>
                </Col> : null}
            </Row>
        </div>
    )
}

export default DetailPMForPrint
