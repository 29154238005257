import { Button, Col, Icon, Row } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'

interface Props {
    name: string
    timeToSync: string
}
const formatDateTime = 'YYYY-MM-DD HH:mm:ss'
const BtnSyncData: React.FC<Props> = (props: Props) => {
    const [ishandleSync, setIshandleSync] = useState<boolean>(false)
    const [isSyncCompleted, setIsSyncCompleted] = useState<boolean>(false)
    const [isCompletedWithErrors, setIsCompletedWithErrors] = useState<boolean>(false)

    const handleSync = () => {
        setIshandleSync(true)
        setIsSyncCompleted(false)

        // รอ call api ตาม name
        setTimeout(() => {
            setIshandleSync(false)
            setIsSyncCompleted(true)
            const random_boolean = Math.random() < 0.5;
            setIsCompletedWithErrors(random_boolean)
        }, 3000)
    }

    return (
        <Button 
            style={{ width: '100%', textAlign: 'left', height: 'auto', padding: 12, marginBottom: 5, border: !isSyncCompleted ? '1px solid #D0D5DD' : isCompletedWithErrors ? '1px solid red' : '1px solid green' }} 
            className={'btn-setting'}
            onClick={handleSync}
         >
            <Row>
                <Col span={4}>
                    {isSyncCompleted ? <Icon type={isCompletedWithErrors ? "close-circle" : "check-circle"} className='icon-setting' style={{ fontSize: 35, marginTop: 8, color: isCompletedWithErrors ? 'red' : 'green'}} /> 
                    : <Icon type={"sync"} className='icon-setting' style={{ fontSize: 35, marginTop: 8 }} spin={ishandleSync} /> }
                </Col>
                <Col span={20}>
                    <div className="text-herder">{props.name}</div>
                    <div style={{ color: '#475467', fontSize:'13px', fontWeight: 'bold'}}>last Sync : {moment(props.timeToSync).format(formatDateTime)}</div>
                </Col>
            </Row>
        </Button>
    )
}

export default BtnSyncData