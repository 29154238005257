/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
/* eslint-disable dot-notation */
import React, { useContext, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { StoreState } from '../../store'
import IncidentStatusUpdater from './IncidentStatusUpdater'
import {
    Button,
    Card,
    Col,
    Collapse,
    DatePicker,
    Drawer,
    Dropdown,
    Empty,
    Form,
    Icon,
    Input,
    Layout,
    Menu,
    message,
    Modal,
    Pagination,
    Row,
    Spin,
    Tag,
    Timeline,
    TimePicker,
    Tooltip,
    Upload
} from 'antd'
import 'react-quill/dist/quill.snow.css' // ES6
import CommentForm from '../comment/CommentForm'
import { Link, useParams, Redirect } from 'react-router-dom'
import moment from 'moment'
import CommentSection from '../comment/CommentSection'
import {
    addSatisfaction,
    DeletedKnowledgeRelatedIncidentView,
    DeletedTicketRelatedTicketIncView,
    GetAllKnowledgeRelatedInIncidentView,
    GetAllTicketRelatedTicketIncView,
    getIncidentById,
    updateIncident,
    updateIncidentStatus
} from './service'
import { getAllWorkLogByIncidentId } from '../worklog/service'
import { FieldDetail, Incident, ticketRelateAsset, ticketRelated, TicketStatus } from './model'
import { FormComponentProps } from 'antd/es/form'
import { ForIncident } from '../../sla-management/sla-calculation/service'
import { getLeastTargetTimeOfAllInProgressSlaCalculations } from '../misc'
import { FetchingState, fetchWithState, SpinningFetcher } from '../../common-components'
import { Worklog, WorklogChange } from '../worklog'
import { getAllStatus } from '../status'
import { IncidentWebSocketChannel, SLAWebSocketChannel } from './web-socket-channel'
import { GetIncidentByIdSuccessEvent } from './state-event'
import { checkRolesState, RoleType, scopeMapping, ScopeType } from '../../authorization-module/permissions'
import { getAllPriority, PriorityLevel, PriorityMatrix } from '../priority'
import { countLines, CutTextToReadMore, disableByStatusForIncidentDetailView, DownloadAnyFile, tokenDecode } from '../../common-misc'
import { UploadLink } from '../../knowledge-management/knowledge/uploads'
import { UploadFile } from 'antd/lib/upload/interface'
import exportIcon from '../../common-file/icon-file'
import SlaDisplay from '../sla/SlaDisplay'
import CustomerSat from './CustomerSatModal'
import { Satisfaction } from '.'
import { SlaCalculation } from '../../sla-management/sla-calculation/model'
import DefaultFooter from '../../common-components/DefaultFooter'
import SelectRelateDataTable from './relatedAsset/SelectRelateDataTable'
import { DeletedTicketRelatedIncidentView, GetAllTicketRelatedInIncidentView } from './relatedAsset/service'

import { AssetRelatedContext } from '../../Preventive/ContextAPI/IncidentTicketRelatedContext'
import DisplayRelateWorkflow from './relateWorkflow/DisplayRelateWorkflow'
import { TicketRelatedContext } from '../../Preventive/ContextAPI/TicketRelatedContext'
import { KnowledgeRelatedContext } from '../../Preventive/ContextAPI/KnowledgeRelatedContext'
import { ticketPacksByincidentId } from '../../serviceCatalog/Catalog'
import { WoRalateIncidentCard, getWoRelatedByIncidentId } from '../../msp-wo/wo-management'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import MyFormCreateTemplete from './FormCreateTemplete'
import ReactHtmlParser from 'react-html-parser'
import { getCookie, waitingGetSessions } from '../../guard/route'
import RelateProblemHome from './relateProblem/RelateProblemHome'
import ReactQuill, { Quill } from 'react-quill'
import ImageResize from 'quill-image-resize-module-react'
import FSS from '../../file-server-storage'
import RenderTicketRelateOther from './RenderTicketRelateOther'
import { getAllGeneralIncidentSetting } from '../general-incident-setting/service'
import ResolvedTicketModal from './ResolvedTicketModal'

const mapStateToProps = (state: StoreState) => {
    return {
        incident: state.incidentState?.incident,
        statuses: state.statuses,
        priority: state.priorities,
        workLog: state.workLog,
        slaCalculationDataSuit: state.incidentState?.slaCalculationDataSuit,
        comments: (state.comments || []),
        supportTeamsWithPermission: state.supportTeamsWithPermission,
        generalIncidentSetting: state.generalIncidentSetting,
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllStatus: (ticketType: string | undefined) => Promise<number>
    updateIncident: (incident: Incident) => Promise<number>
    getAllWorkLogByIncidentId: (incidentId: string) => Promise<number>
    getAllSlaCalculationByTicketIds: (ticketIds: string[]) => Promise<number>
    getAllPriority: () => Promise<number>
    addSatisfaction: (satisfaction: Satisfaction) => Promise<number>
    getAllGeneralIncidentSetting: () => Promise<number>
}

interface Params {
    id: string
}
Quill.register('modules/imageResize', ImageResize)
const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }, { color: [] }],
        ['link', 'image'],
        ['clean']
    ],
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
}

const formats = [
    'alt', 'height', 'width', 'style', 'size',
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'align', 'color',
    'link', 'image'
]
type Props = StateProps & DispatchProps & FormComponentProps
const { Header, Content } = Layout
const { confirm } = Modal
const { Panel } = Collapse
let tempMyStatus: any[] = []
let tempDataWo: WoRalateIncidentCard[] = []
const idTenantFreshService = process.env.REACT_APP_ID_TENANT_FRESH_SERVICE
const IncidentDetailsView: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch()
    const { id } = useParams<Params>()

    const [incidentFetchingState, setIncidentFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    // const [workLogFetchingState, setWorkLogFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [slaCalculationsFetchingState, setSlaCalculationFetchingState] = useState<FetchingState>(FetchingState.NotStarted)

    const [ticketNumber, setTicketNumber] = useState<string>()
    const [createdDatetime, setcreatedDatetime] = useState<string>()
    const [topic, setTopic] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [requester, setRequester] = useState<string>()
    const [company, setCompany] = useState<string>()
    const [priority, setPriority] = useState<PriorityMatrix>()
    const [currentStatus, setCurrentStatus] = useState<TicketStatus>()
    const [pendingReason, setPendingReason] = useState<string | null>()
    const [pendingUntil, setPendingUntil] = useState<string | null>()
    const [SLACalDueDate, setSLACalDueDate] = useState<string>()
    const [initialPendingUntilDate, setInitialPendingUntilDate] = useState<string | null>()
    const [initialPendingUntilTime, setInitialPendingUntilTime] = useState<string | null>()
    const [initialPendingReason, setInitialPendingReason] = useState<string>()
    const [changeTitle, setChangeTitle] = useState<string>()
    const [changeDescription, setChangeDescription] = useState<string>()
    const [lastStatusTimestamp, setLastStatusTimestamp] = useState<string>()
    const [email, setEmail] = useState<string>()
    const [phone, setPhone] = useState<string>()
    const [tempStatus, setTempStatus] = useState<TicketStatus>()
    const [, setRequesterId] = useState<number>()
    const [showWorkLog, setShowWorkLog] = useState<boolean>(false)
    const [showProblem, setShowProblem] = useState<boolean>(false)
    const [showSla, setShowSla] = useState<boolean>(false)
    const [oldStatus, setOldStatused] = useState<TicketStatus | undefined>(props.incident?.ticketStatus)
    const [commentText, setCommentText] = useState<string>('')
    const [isReadMoreTopic, setIsReadMoreTopic] = useState<boolean>(false)
    const [isReadMoreDesc, setIsReadMoreDesc] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isEditIncidentLoading, setIsEditIncidentLoading] = useState<boolean>(false)
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [disabledSaveButton, setDisabledSaveButton] = useState<boolean>(false)
    const [ticketType, setTicketType] = useState<string>()
    const [channel, setChannel] = useState<string>()
    const [isNonDesktop, setIsNonDesktop] = useState<boolean>(false)
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)
    const [isSmall, setIsSmall] = useState<boolean>(false)
    const [isSatOpen, setIsSatOpen] = useState<boolean>(false)
    const [isSatisfactionDone, setIsSatisfactionDone] = useState<number>()
    const [isDisableAddResolution, setIsDisableAddResolution] = useState<boolean>(false)
    const [showAssetRelated, setshowAssetRelated] = useState<boolean>(false)
    const [showWorkflowRelated, setshowWorkflowRelated] = useState<boolean>(false)
    const [isModalRelatedVisible, setIsModalRelatedVisible] = useState<boolean>(false)
    const [tabpaneName, setTabpaneName] = useState<string>('')
    const { GetAssetRelated, AssetRelatedState } = useContext<any>(AssetRelatedContext)
    const { GetTicketRelated, TicketRelatedState } = useContext<any>(TicketRelatedContext)
    const [showTicketRelated, setShowTicketRelated] = useState<boolean>(false)
    const [childTicket, setChildTicket] = useState<number[]>([])
    const [parentTicket, setParentTicket] = useState<number[]>([])
    const [showKnowledgeRelated, setShowKnowledgeRelated] = useState<boolean>(false)
    const { GetKnowledgeRelated, KnowledgeRelatedState } = useContext<any>(KnowledgeRelatedContext)
    const [ticketCurrentPage, setTicketCurrentPage] = useState<number>(1)
    const [assetCurrentPage, setAssetCurrentPage] = useState<number>(1)
    const [kmCurrentPage, setKmCurrentPage] = useState<number>(1)
    const [dataWoRalateIncident, setDataWoRalateIncident] = useState<WoRalateIncidentCard[]>([])
    const [isCancelModal, setIsCancelModal] = useState<boolean>(false)
    const [isShowAutoResolve, setIsShowAutoResolve] = useState<boolean>(false)
    const decoded: any = jwt_decode(getCookie('access_token') || '')
    const [isVisibleModelTemplate, setIsVisibleModelTemplate] = useState<boolean>(false)
    const [dataStatus, setDataStatus] = useState<TicketStatus[]>([])
    const [tempAllStatus, setTempAllStatus] = useState<TicketStatus[]>([])
    const [isLoadingInc, setLoading] = useState(false)
    const [isLoadingDataRelated, setIsLoadingDataRelated] = useState<boolean>(false)
    const [isfetchOptionSuccess, setIsFetchOptionSuccess] = useState<boolean>(false)
    const [detail, setDetail] = useState<string>('')
    const [textError, setTextError] = useState<string>('')
    const [isValidateActive, setIsValidateActive] = useState(true)
    const [department, setDepartment] = useState<string>()
    const [redirectViewTicket, setRedirectViewTicket] = useState<boolean>(false)
    const [isVisibleResolvedModal, setIsvisibleResolvedModal] = useState<boolean>(false)
    const [IsCustomerSatisfaction, setIsCustomerSatisfaction] = useState<boolean>(false)
    const splitIdTenantFreshService = idTenantFreshService.split(',')
    const isTanentFreshService = splitIdTenantFreshService.includes(tokenDecode()?.tenantID.toString())

    useEffect(() => {
        // setTimeout(() => {
        //     localStorage.clear()
        // }, 1000)
        IncidentWebSocketChannel.subscribe()
        SLAWebSocketChannel.subscribe()
        IncidentWebSocketChannel.subscribeToIncidentIds([Number(id)!!])
        SLAWebSocketChannel.subscribeToIncidentIds([Number(id)!!])
        setLoading(true)
        fetchWithState(incidentFetchingState, setIncidentFetchingState, fetchIncident, (incident) => {
            dispatch(GetIncidentByIdSuccessEvent.build(incident))
            setLoading(false)
        })
        const promiseAll: any[] = []
        promiseAll.push(fetchAllPriority())
        promiseAll.push(fetchAllWorkLog())
        promiseAll.push(props.getAllGeneralIncidentSetting())
        Promise.all(promiseAll).catch((err) => {
            message.error(`Unable to retrieve the data. Please try again later. ${err}`)
        })
        // fetchWithState(workLogFetchingState, setWorkLogFetchingState, fetchAllWorkLog)
        // fetchAllPriority().catch((err) => message.error(`Unable to retrieve priorities. Please try again later. ${err}`))
        fetchTicketRelated()
        if (!checkRolesState(RoleType.Incident, 'UpdateIncident')) {
            setRedirectViewTicket(true)
        }
        if (!checkRolesState(RoleType.Incident, 'ViewIncident')) {
            // warningTicketChangeGroup()
            setRedirectViewTicket(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.incident !== null && isfetchOptionSuccess) {
            if (dataStatus.length === 0) {
                const ticketType = props.incident?.incidentSetting?.find(it => it.incGeneralSetting.settingType === 'ticketType')?.incGeneralSetting.value
                fetchAllStatus(ticketType!!)
            }
            setIncident(props.incident!!)
            setDetail(props.incident?.description || '')
            // ticketPacksByincidentId(props.incident?.id?.toString()!!).then((data) => {
            //     console.log(data)
            //     if (data.tickets) {
            //         const convertDataToWoRalateIncident: WoRalateIncidentCard[] = data.tickets.map((it) => {
            //             return {
            //                 id: it.ticketId,
            //                 description: JSON.parse(it.ticketCreationRequestBody).description,
            //                 item_id: it.itemId,
            //                 order_id: it.ticketNumber,
            //                 wo_status: it.ticketStatus,
            //                 workflow_name: JSON.parse(it.ticketCreationRequestBody).workflowName,
            //                 workflow_number: JSON.parse(it.ticketCreationRequestBody).workflowNumber
            //             } as WoRalateIncidentCard
            //         })
            //         const mergeData = [...dataWoRalateIncident, ...convertDataToWoRalateIncident]
            //         const arrayUniqueByKey = [...new Map(mergeData.map(item =>
            //             [item['id'], item])).values()]
            //         setDataWoRalateIncident(arrayUniqueByKey)
            //     }
            // })
            // fetchDataWoWithIncident()
            if (props.supportTeamsWithPermission.length === 0) {
                if (props.incident?.supportTeam?.name === undefined || props.incident.user?.email !== decoded.sub) {
                    // warningTicketChangeGroup()
                    setRedirectViewTicket(true)
                }
            } else if (props.incident?.supportTeam?.name === undefined) {
                if (scopeMapping(decoded.permission.incident.scope) !== ScopeType.GlobalAccess) {
                    // warningTicketChangeGroup()
                    setRedirectViewTicket(true)
                }
            } else if (props.incident?.supportTeam?.name !== undefined && props.supportTeamsWithPermission.length !== 0) {
                const findData = props.supportTeamsWithPermission.find((it) => { return it.name === props.incident?.supportTeam?.name!! })
                if (!findData) {
                    // warningTicketChangeGroup()
                    setRedirectViewTicket(true)
                }
            }

            if (props.incident.viewed === false) {
                props.updateIncident(
                    {
                        id: id,
                        viewed: true,
                        customField: props.incident?.customField
                    } as unknown as Incident
                )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.incident, props.supportTeamsWithPermission, isfetchOptionSuccess])

    const getFetchSuccess = (value) => {
        setIsFetchOptionSuccess(value)
    }

    useEffect(() => {
        if (props.statuses) {
            setDataStatus(props.statuses)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.statuses])

    useEffect(() => {
        disableByStatusForIncidentDetailView(currentStatus?.status, setDisabledSaveButton, props.incident?.user?.email, props?.incident?.supportTeam, setIsDisableAddResolution)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStatus?.status])

    useEffect(() => {
        fetchWithState(
            slaCalculationsFetchingState,
            setSlaCalculationFetchingState,
            fetchSlaCalculation,
            undefined,
            (err) => message.error(`Unable to retrieve SLA calculations. Please try again later. ${err}`)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.incident, showSla, slaCalculationsFetchingState])

    useEffect(() => {
        reCalculateDueDate()
        fetchAllWorkLog().catch((err) => message.error(`Unable to retrieve work log data. Please try again later. ${err}`))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.incident?.slaCalculations, showSla])

    useEffect(() => {
        props.comments.forEach((comment) => {
            if (comment.isResolution) {
                setCommentText(comment.text!!)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.comments])

    useEffect(() => {
        if (window.innerWidth < 992) {
            setIsNonDesktop(true)
        } else {
            setIsNonDesktop(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNonDesktop])

    useEffect(() => {
        if (window.innerWidth < 576) {
            setIsSmall(true)
        } else {
            setIsSmall(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSmall])

    useEffect(() => {
        if (props.statuses) {
            setTempAllStatus(props.statuses)
            tempMyStatus = props.statuses
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.statuses])

    useEffect(() => {
        if (props.statuses.length !== 0 && tempAllStatus.length !== 0) {
            if (props.incident) {
                const findDataIsCustomerSatisfaction = props.incident?.incidentSetting?.find((it) => {
                    return it.incGeneralSetting?.settingType === 'customerSatisfaction' && it.incGeneralSetting?.value === '1';
                })
                if (findDataIsCustomerSatisfaction) {
                    setIsCustomerSatisfaction(true)
                } else {
                    setIsCustomerSatisfaction(false)
                }
                if (props.incident.ticketStatus?.status === 'InProgress' && props.incident?.resolution !== null && !isShowAutoResolve) {
                    // fetch relate ticket and wo for check status related before close ticket
                    const promiseAll: any[] = []
                    promiseAll.push(fetchTicketRelated())
                    promiseAll.push(fetchDataWoWithIncident())
                    promiseAll.push(setIsShowAutoResolve(true))
                    Promise.all(promiseAll).then(() => {
                        // if (findDataIsCustomerSatisfaction) {
                        //     // autoResolved()
                        // }
                    }).catch((err) => {
                        message.error(`Unable to retrieve the data. Please try again later. ${err}`)
                    })
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempAllStatus, props.incident, props.statuses])

    const fetchAllStatus = (ticketType: string) => props.getAllStatus(ticketType)

    const fetchAllWorkLog = () => props.getAllWorkLogByIncidentId(id)

    const fetchSlaCalculation = () => props.getAllSlaCalculationByTicketIds([id])

    const fetchAllPriority = () => props.getAllPriority()

    const fetchAssetRelated = () => {
        setIsLoadingDataRelated(true)
        GetAllTicketRelatedInIncidentView(id).then((res) => {
            GetAssetRelated(res)
        }).finally(() => {
            setIsLoadingDataRelated(false)
        })
    }

    function warningTicketChangeGroup() {
        Modal.warning({
            title: 'Warning',
            content: "Sorry, you don't have permission to access this data.",
            onOk() {
                window.close()
            },
            onCancel() {
                window.close()
            },
            maskClosable: false
        })
    }

    function autoResolved() {
        confirm({
            title: 'Status Updated to Resolved',
            content: 'Do you want to change the status from In Progress to Resolved ?',
            onOk() {
                const findStatus = (tempMyStatus || tempAllStatus || props.statuses).find((status) => status.status === 'Resolved')
                if (dataWoRalateIncident?.length !== 0 || tempDataWo?.length !== 0) {
                    var checkWorkFlowStatus = (dataWoRalateIncident.length > 0 ? dataWoRalateIncident : tempDataWo)?.find((it) => {
                        return it.wo_status !== 'Complete' && it.wo_status !== 'Cancelled' && it.wo_status !== 'Closed'
                    })
                }

                if (TicketRelatedState.child?.length !== 0) {
                    var checkTicketStatus = TicketRelatedState.child?.find((it) => {
                        return it.ticketStatus.status !== 'Resolved' && it.ticketStatus.status !== 'Cancelled' && it.ticketStatus.status !== 'Closed'
                    })
                }
                if (findStatus && !checkTicketStatus && !checkWorkFlowStatus) {
                    changeMyStatus(findStatus)
                } else {
                    Modal.warning({
                        title: <b style={{ color: '#323276' }}>Warning</b>,
                        content: <p style={{ color: '#323276' }}>This ticket is related to other tickets and workflows. Please complete them before proceeding.</p>
                    })
                }
                tempDataWo = []
            },
            onCancel() {
                console.log('Cancel')
                tempDataWo = []
            }
        })
    }

    const fetchTicketRelated = async () => {
        setIsLoadingDataRelated(true)
        await GetAllTicketRelatedTicketIncView(id).then((res) => {
            GetTicketRelated(res)
            setChildTicket(res.child.map((it) => { return it.id }))
            setParentTicket(res.parent.map((it) => { return it.id }))
        }).finally(() => {
            setIsLoadingDataRelated(false)
        })
    }

    const fetchKnowledgeRelated = () => {
        setIsLoadingDataRelated(true)
        GetAllKnowledgeRelatedInIncidentView(id).then((res) => {
            GetKnowledgeRelated(res)
        }).finally(() => {
            setIsLoadingDataRelated(false)
        })
    }

    const fetchDataWoWithIncident = async () => {
        if (decoded.service.serviceId.some((it) => it === 4)) {
            setIsLoadingDataRelated(true)
            await getWoRelatedByIncidentId(props.incident?.id?.toString()!!).then((data) => {
                if (data.length > 0) {
                    const mergeData = [...dataWoRalateIncident, ...data]
                    const arrayUniqueByKey = [...new Map(mergeData.map(item =>
                        [item['id'], item])).values()]
                    tempDataWo = arrayUniqueByKey
                    setDataWoRalateIncident(arrayUniqueByKey)
                }
            }).finally(() => {
                setIsLoadingDataRelated(false)
            })
        }
    }

    const reCalculateDueDate = () => {
        const targetTime = getLeastTargetTimeOfAllInProgressSlaCalculations(props.incident?.slaCalculations || [])
        if (targetTime === '') {
            setSLACalDueDate('NoData')
        } else {
            setSLACalDueDate(moment(targetTime).format('YYYY-MM-DD HH:mm:ss'))
        }
    }

    const fetchIncident = () => getIncidentById(Number(id))

    const setEmailAndPhone = (value: any) => {
        if (value) {
            if (value.length > 0) {
                return value
            } else {
                return '-'
            }
        } else {
            return '-'
        }
    }

    const setIncident = (incident: Incident) => {
        if (incident.number !== null) {
            setTicketNumber(incident.number)
        }
        if (incident.createdDatetime !== null) {
            setcreatedDatetime(incident.createdDatetime)
        }
        if (incident.subject !== null) {
            setTopic(incident.subject)
        }
        if (incident.description !== null) {
            setDescription(incident.description)
        }
        if (incident.people?.fullName !== null) {
            setRequester(incident.people?.fullName)
        }
        if (incident.people?.id !== null) {
            setRequesterId(incident.people?.id)
        }
        setEmail(setEmailAndPhone(incident.people?.email))
        setPhone(setEmailAndPhone(incident.people?.tel))
        if (incident.company !== null) {
            setCompany(incident.company)
        }
        if (incident.priority !== null) {
            if (isTanentFreshService) {
                if (incident.freshserviceTicketPriority === null) {
                    setPriority(incident.priority)
                } else {
                    setPriority(incident.freshserviceTicketPriority)
                }
            } else {
                setPriority(incident.priority)
            }
        }
        if (incident.ticketStatus !== null) {
            setCurrentStatus(incident.ticketStatus)
            setTempStatus(incident.ticketStatus)
        }
        if (incident.pendingReason !== null) {
            setPendingReason(incident.pendingReason)
        }
        if (incident.pendingUntil !== null) {
            setPendingUntil(incident.pendingUntil)
        }
        if (incident.lastStatusTimestamp !== null) {
            setLastStatusTimestamp(incident.lastStatusTimestamp)
        }
        if (incident.incidentSetting !== null) {
            // eslint-disable-next-line no-unused-expressions
            incident.incidentSetting?.forEach(it => {
                if (it.incGeneralSetting.settingType === 'channel') {
                    setChannel(it.incGeneralSetting.value)
                }
                if (it.incGeneralSetting.settingType === 'ticketType') {
                    setTicketType(it.incGeneralSetting.value)
                }
            })
        }
        if (incident.satisfactionDone !== null) {
            setIsSatisfactionDone(incident.satisfactionDone)
        }
        if (incident.people.department !== null) {
            setDepartment(incident.people.department?.departmentName)
        }
    }

    const setUpdatePending = async () => {
        if (pendingUntil && pendingReason) {
            const pendingDate = pendingUntil.split(' ')
            const pendingTime = pendingUntil.split(' ')
            const momentPendingDate = moment(pendingDate[0])
            const momentPendingTime = moment(pendingTime[1], 'HH:mm:ss')
            setInitialPendingUntilDate((momentPendingDate as unknown as string))
            setInitialPendingUntilTime((momentPendingTime as unknown as string))
            await setInitialPendingReason(pendingReason)
        } else {
            console.log(pendingUntil)
        }
    }

    // Data Menu for dropdown status
    const changeMyStatus = (newStatus: TicketStatus): void => {
        if (newStatus.status === 'Pending') {
            if (currentStatus?.status === 'Pending') {
                const tempOldStatus = oldStatus
                setOldStatused(tempOldStatus)
            } else {
                setOldStatused(currentStatus)
            }
            openModalPending()
        } else if (newStatus.status === 'InProgress' || newStatus.status === 'Resolved' || newStatus.status === 'Closed') {
            // if (newStatus.status === 'Resolved' && commentText === '') {
            //     if (isTanentFreshService) {
            //         if (props.incident?.resolution === '' || props.incident?.resolution === undefined || props.incident?.resolution === null) {
            //             message.error('Unsuccessfully, Please input Resolution in Add note field.')
            //         }
            //     } else {
            //         message.error('Unsuccessfully, Please input Resolution in Add note field.')
            //     }
            //     if (oldStatus.status !== 'InProgress') {
            //         message.error('Please change status to In Progress before Resolved Ticket.')
            //     } else {
            //         setIsvisibleResolvedModal(true)
            //     }
            // } else 
            if (newStatus.status === 'Resolved' && (!(props.incident?.user && props.incident.supportTeam))) {
                setTempStatus(newStatus)
                message.error('Unsuccessful. Please fill in all required (*) fields.')
                const tempOldStatus = oldStatus
                setOldStatused(tempOldStatus)
            } else {
                if (newStatus.status === 'Resolved') {
                    const satisfaction: Satisfaction = {
                        incidentId: Number(id),
                        satisfactionDone: 0
                    }
                    console.log(satisfaction)

                    if (props.incident?.ticketStatus.status === 'Resolved') {
                        return
                    }

                    if (!(props.incident?.user && props.incident.supportTeam)) {
                        setTempStatus(newStatus)
                        message.error('Unsuccessful. Please fill in all required (*) fields.')
                        const tempOldStatus = oldStatus
                        setOldStatused(tempOldStatus)
                    } else {
                        const checkTicketStatus = TicketRelatedState.child?.find((it) => {
                            console.log(it)
                            return it?.ticketStatus?.status !== 'Resolved' && it?.ticketStatus?.status !== 'Cancelled' && it?.ticketStatus?.status !== 'Closed'
                        })
                        const checkWorkFlowStatus = dataWoRalateIncident?.find((it) => {
                            return it.wo_status !== 'Complete' && it.wo_status !== 'Cancelled' && it.wo_status !== 'Closed'
                        })
                        if (TicketRelatedState.child?.length > 0 && dataWoRalateIncident?.length === 0) {
                            if (!checkTicketStatus) {
                                // props.updateIncident(
                                //     {
                                //         id: id,
                                //         ticketStatus: newStatus,
                                //         resolution: commentText ? commentText.replace(/<[^>]*>/g, '') : null,
                                //         satisfactionDone: 0
                                //     } as unknown as Incident
                                // ).then((res) => {
                                //     if (res) {
                                //         setIsSatOpen(true)
                                //     }
                                // }).catch((err) => {
                                //     console.log(err)
                                //     if (isTanentFreshService) {
                                //         ModalFreshService(err)
                                //     }
                                // })
                                // setCurrentStatus(newStatus)
                                setTempStatus(newStatus)
                                const tempOldStatus = oldStatus
                                setOldStatused(tempOldStatus)
                                setIsvisibleResolvedModal(true)
                            } else {
                                Modal.warning({
                                    title: <b style={{ color: '#323276' }}>Warning</b>,
                                    content: <p style={{ color: '#323276' }}>It was related to some ticket, You have to complete thoses.</p>
                                })
                            }
                        } else if (dataWoRalateIncident?.length > 0 && TicketRelatedState.child?.length === 0) {
                            if (!checkWorkFlowStatus) {
                                // props.updateIncident(
                                //     {
                                //         id: id,
                                //         ticketStatus: newStatus,
                                //         resolution: commentText ? commentText.replace(/<[^>]*>/g, '') : null,
                                //         satisfactionDone: 0
                                //     } as unknown as Incident
                                // ).then((res) => {
                                //     if (res) {
                                //         setIsSatOpen(true)
                                //     }
                                // }).catch((err) => {
                                //     console.log(err)
                                //     if (isTanentFreshService) {
                                //         ModalFreshService(err)
                                //     }
                                // })
                                // setCurrentStatus(newStatus)
                                setTempStatus(newStatus)
                                const tempOldStatus = oldStatus
                                setOldStatused(tempOldStatus)
                                setIsvisibleResolvedModal(true)
                            } else {
                                Modal.warning({
                                    title: <b style={{ color: '#323276' }}>Warning</b>,
                                    content: <p style={{ color: '#323276' }}>It was related to some workflow, You have to complete thoses.</p>
                                })
                            }
                        } else if (TicketRelatedState.child?.length > 0 && dataWoRalateIncident?.length > 0) {
                            if (!checkTicketStatus && !checkWorkFlowStatus) {
                                // props.updateIncident(
                                //     {
                                //         id: id,
                                //         ticketStatus: newStatus,
                                //         resolution: commentText ? commentText.replace(/<[^>]*>/g, '') : null,
                                //         satisfactionDone: 0
                                //     } as unknown as Incident
                                // ).then((res) => {
                                //     if (res) {
                                //         setIsSatOpen(true)
                                //     }
                                // }).catch((err) => {
                                //     console.log(err)
                                //     if (isTanentFreshService) {
                                //         ModalFreshService(err)
                                //     }
                                // })
                                // setCurrentStatus(newStatus)
                                setTempStatus(newStatus)
                                const tempOldStatus = oldStatus
                                setOldStatused(tempOldStatus)
                                setIsvisibleResolvedModal(true)
                            } else {
                                Modal.warning({
                                    title: <b style={{ color: '#323276' }}>Warning</b>,
                                    content: <p style={{ color: '#323276' }}>This ticket is related to other tickets and workflows. Please complete them before proceeding.</p>
                                })
                            }
                        } else {
                            // props.updateIncident(
                            //     {
                            //         id: id,
                            //         ticketStatus: newStatus,
                            //         resolution: commentText ? commentText.replace(/<[^>]*>/g, '') : null,
                            //         satisfactionDone: 0
                            //     } as unknown as Incident
                            // ).then((res) => {
                            //     if (res) {
                            //         setIsSatOpen(true)
                            //     }
                            // }).catch((err) => {
                            //     console.log(err)
                            //     if (isTanentFreshService) {
                            //         ModalFreshService(err)
                            //     }
                            // })
                            // setCurrentStatus(newStatus)
                            setTempStatus(newStatus)
                            const tempOldStatus = oldStatus
                            setOldStatused(tempOldStatus)
                            setIsvisibleResolvedModal(true)
                        }
                    }
                } else {
                    if (!(props.incident?.user && props.incident.supportTeam)) {
                        setTempStatus(newStatus)
                        message.error('Unsuccessful. Please fill in all required (*) fields.')
                        const tempOldStatus = oldStatus
                        setOldStatused(tempOldStatus)
                    } else if (newStatus.status === 'Closed' && (props.incident.satisfactionDone === null || props.incident.satisfactionDone === 0) && IsCustomerSatisfaction) {
                        message.error('Unsuccessful. Please complete the satisfaction survey.')
                        const tempOldStatus = oldStatus
                        setOldStatused(tempOldStatus)
                    } else if (newStatus.status === 'Closed' && commentText === '') {
                        message.error("Unsuccessful. Please enter a resolution in the 'Add Note' field.")
                        const tempOldStatus = oldStatus
                        setOldStatused(tempOldStatus)
                    } else {
                        props.updateIncident(
                            {
                                id: id,
                                ticketStatus: newStatus,
                                resolution: commentText ? commentText.replace(/<[^>]*>/g, '') : null,
                                customField: props.incident?.customField
                            } as unknown as Incident
                        ).then(() => {
                            message.success('The data has been updated successfully.')
                        }).catch((err) => {
                            if (isTanentFreshService) {
                                ModalFreshService(err)
                            } else {
                                message.error(`Unable to save changes. Please try again later. ${err}`)
                            }
                        })
                        setCurrentStatus(newStatus)
                        setTempStatus(newStatus)
                        const tempOldStatus = oldStatus
                        setOldStatused(tempOldStatus)
                    }
                }
            }
        } else if (newStatus.status === 'Assigned') {
            if (!props.incident?.supportTeam) {
                setTempStatus(newStatus)
                message.error('Unsuccessful. Please fill in all required (*) fields.')
                const tempOldStatus = oldStatus
                setOldStatused(tempOldStatus)
            } else {
                props.updateIncident(
                    {
                        id: id,
                        ticketStatus: newStatus,
                        customField: props.incident?.customField
                    } as unknown as Incident
                ).then(() => {
                    message.success('The data has been updated successfully.')
                }).catch((err) => {
                    if (isTanentFreshService) {
                        ModalFreshService(err)
                    } else {
                        message.error(`Failed saving changes. ${err}`)
                    }
                })
                setCurrentStatus(newStatus)
                setTempStatus(newStatus)
                const tempOldStatus = oldStatus
                setOldStatused(tempOldStatus)
            }
        } else if (newStatus.status === 'Cancelled') {
            Modal.warning({
                title: <b style={{ color: '#323276' }}>Are you sure?</b>,
                content: <p style={{ color: '#323276' }}>You want to Cancelled this ticket?</p>,
                onOk: () => {
                    setTempStatus(newStatus)
                    setCurrentStatus(newStatus)
                    props.updateIncident(
                        {
                            id: id,
                            ticketStatus: newStatus,
                            customField: props.incident?.customField
                        } as unknown as Incident
                    ).then(() => {
                        message.success('The data has been updated successfully.')
                    }).catch((err) => {
                        if (isTanentFreshService) {
                            ModalFreshService(err)
                        } else {
                            message.error(`Failed saving changes. ${err}`)
                        }
                    })
                    setOldStatused(oldStatus)
                }
            })
        } else {
            setTempStatus(newStatus)
            setCurrentStatus(newStatus)
            props.updateIncident(
                {
                    id: id,
                    ticketStatus: newStatus,
                    customField: props.incident?.customField
                } as unknown as Incident
            ).then(() => {
                message.success('The data has been updated successfully.')
            }).catch((err) => {
                if (isTanentFreshService) {
                    ModalFreshService(err)
                } else {
                    message.error(`Failed saving changes. ${err}`)
                }
            })
            setOldStatused(oldStatus)
        }
        tempDataWo = []
    }

    const ModalFreshService = (err) => {
        if (err?.response?.data.status === 403) {
            const match = err?.response?.data?.message.match(/\{.*\}/) // หาส่วน JSON ในข้อความ
            if (match) {
                const parsedData = JSON.parse(match[0])
                Modal.warning({
                    title: <b style={{ color: '#323276' }}>{parsedData.code || 'Access_denied'}</b>,
                    content: <p style={{ color: '#323276' }}>{parsedData.message || 'You are not authorized to perform this action.'}</p>
                })
            }
        } else if (err?.response?.data.status === 400) {
            const match = err?.response?.data?.message.match(/\{.*\}/) // หาส่วน JSON ในข้อความ
            if (match) {
                const parsedData = JSON.parse(match[0])
                Modal.warning({
                    title: <b style={{ color: '#323276' }}>{parsedData?.description || 'Validation failed'}</b>,
                    content: <p style={{ color: '#323276' }}>{
                        parsedData?.errors.map((err: any, index: number) => {
                            return <p key={index}>
                                <b>{err.field}:</b> {err.message}
                            </p>
                        })
                    }</p>
                })
            }
        } else {
            message.error(`Failed saving changes. ${err}`)
        }
    }

    // modal Pending
    const [visiblePending, setVisiblePending] = useState(false)
    const openModalPending = () => {
        setVisiblePending(true)
        props.form.setFieldsValue({
            datePending: '',
            timePending: null,
            PendingReason: null
        })
    }

    const openEditModalPending = () => {
        setUpdatePending().catch((err) => message.error(`Unable to update data. Please try again later. ${err}`))
        setVisiblePending(true)
    }

    const handlePendingOk = (e: any) => {
        e.preventDefault()

        props.form.validateFields((err: any, fieldsValue: any) => {
            if (err) {
                return
            }
            setIsLoading(true)
            fieldsValue.PendingReason = fieldsValue.PendingReason ? fieldsValue.PendingReason.trim() : undefined
            // Should format date value before submit.
            const values = {
                ...fieldsValue,
                datePending: fieldsValue['datePending'].format('YYYY-MM-DD'),
                timePending: fieldsValue['timePending'].format('HH:mm:ss')
            }
            const newStatus: TicketStatus = dataStatus.find(it => it.status === 'Pending')!!
            const dueDatePending: string = values.datePending + ' ' + values.timePending + '.000'
            const reasonOfPending = values.PendingReason
            // eslint-disable-next-line prefer-const
            updateIncidentStatus(Number(id), newStatus, reasonOfPending, dueDatePending, props.incident?.customField)
                .then((data) => {
                    message.success('The data has been updated successfully.')
                    setVisiblePending(false)
                    setCurrentStatus(data.ticketStatus)
                    setPendingUntil(data.pendingUntil)
                    setPendingReason(data.pendingReason)
                    setTempStatus(data.ticketStatus)
                    setIsLoading(false)
                })
                .catch((err) => {
                    setIsLoading(false)
                    message.error(err.response.data.message)
                })
        })
        setInitialPendingReason('')
        setInitialPendingUntilDate(null)
        setInitialPendingUntilTime(null)
    }

    const handlePendingCancel = () => {
        props.form.setFieldsValue({
            datePending: initialPendingUntilDate,
            timePending: initialPendingUntilTime,
            PendingReason: initialPendingReason
        })
        setVisiblePending(false)
    }

    const changeOldStatus = (check) => {
        if (check) {
            setOldStatused(undefined)
        }
    }

    // eslint-disable-next-line camelcase
    const my_menu = () => {
        let currentKey: number
        return (
            <Menu>{
                // eslint-disable-next-line react/prop-types
                dataStatus.map((status, keys: number) => {
                    const currentStatus = status.status
                    if (currentStatus === props.incident?.ticketStatus.status) {
                        currentKey = keys
                        if (oldStatus === undefined) {
                            const workLog = (props.workLog || []).filter(value => value.type === 'Pending')[0]
                            if (workLog) {
                                const value = workLog.changes.filter(obj => obj.fieldName === 'ticketStatus' && obj.oldValue !== null)[0]
                                if (value) {
                                    const oldValue = dataStatus.find(it => it.status === value.oldValue)
                                    setOldStatused(oldValue)
                                }
                            }
                        }
                    }
                    if (currentKey || currentKey === 0) {
                        return checkRolesState(RoleType.Incident, 'CloseIncident', props.incident?.createdBy) && !checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident?.createdBy)
                            // can close but can't update
                            ? (
                                // status = closed and current status = resolved
                                keys === 5 && currentKey === 4 ? (
                                    <Menu.Item key={keys} onClick={() => changeMyStatus(status)}>
                                        <span>{currentStatus}</span>
                                    </Menu.Item>
                                ) : (
                                    <Menu.Item key={keys} onClick={() => changeMyStatus(status)} disabled={true}>
                                        <span>{currentStatus}</span>
                                    </Menu.Item>
                                )
                            ) : (
                                currentKey <= keys || keys === 3
                                    ? (
                                        props.incident?.ticketStatus.status === 'Resolved' && keys === 5 ? (
                                            <Menu.Item key={keys} onClick={() => changeMyStatus(status)}
                                                disabled={!checkRolesState(RoleType.Incident, 'CloseIncident', props.incident?.createdBy)}>
                                                <span>{currentStatus}</span>
                                            </Menu.Item>
                                        ) : (
                                            keys === 5 ? (
                                                <Menu.Item key={keys} onClick={() => changeMyStatus(status)}
                                                    disabled={true}>
                                                    <span>{currentStatus}</span>
                                                </Menu.Item>
                                            ) : (
                                                <Menu.Item key={keys} onClick={() => changeMyStatus(status)}>
                                                    <span>{currentStatus}</span>
                                                </Menu.Item>
                                            )
                                        )
                                    )
                                    : (
                                        <Menu.Item key={keys} onClick={() => changeMyStatus(status)}
                                            disabled={true}>
                                            <span>{currentStatus}</span>
                                        </Menu.Item>
                                    )
                            )
                    } else {
                        if (props.incident?.ticketStatus.status === 'Pending') {
                            let oldValue
                            if (!oldStatus) {
                                const workLog = (props.workLog || []).filter(value => value.type === 'Pending')
                                if (workLog) {
                                    let findChange
                                    workLog.forEach((data) => {
                                        findChange = data.changes.find(obj => obj.fieldName === 'ticketStatus' && obj.oldValue !== null)
                                    })
                                    if (findChange) {
                                        oldValue = dataStatus.find(it => it.status === findChange.oldValue)
                                    }
                                }
                            }
                            return keys === 3 ? (
                                <Menu.Item key={keys} onClick={() => changeMyStatus(status)}>
                                    <span>{currentStatus}</span>
                                </Menu.Item>
                            ) : (
                                <Menu.Item key={keys} onClick={() => changeMyStatus(status)}
                                    disabled={oldStatus?.status ? oldStatus?.status !== currentStatus : oldValue?.status !== currentStatus}>
                                    <span>{currentStatus}</span>
                                </Menu.Item>
                            )
                        } else if (props.incident?.ticketStatus.status === 'Resolved') {
                            return (<Menu.Item key={keys} onClick={() => changeMyStatus(status)} disabled={true}>
                                <span>{currentStatus}</span>
                            </Menu.Item>)
                        } else {
                            return (
                                <Menu.Item key={keys} onClick={() => changeMyStatus(status)}
                                    disabled={true}>
                                    <span>{currentStatus}</span>
                                </Menu.Item>
                            )
                        }
                    }
                })
            }
            </Menu>
        )
    }

    // Modal Popup
    const [visible, setVisible] = useState(false)
    const showModal = () => {
        setChangeTitle(topic)
        setChangeDescription(description)
        setVisible(true)
    }

    const handleChangeTitle = (e) => {
        setChangeTitle(e.target.value)
        props.form.setFieldsValue({ Topic: topic })
    }

    // const handleChangeDescription = (e) => {
    //     setChangeDescription(e.target.value)
    //     props.form.setFieldsValue({ Description: description })
    // }

    const checkSaveButton = () => {
        let disableButton = true
        if (changeTitle && changeDescription) {
            disableButton = false
        }
        return disableButton
    }
    const onSubmitSubjectAndDescription = () => {
        setIsEditIncidentLoading(true)
        setIsValidateActive(false)
        const detailValue = detail || ''
        const CheckValue = (detailValue).replace(/<[^>]*>/g, '')
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        const pattern = /^[A-Za-z0-9ก-๙]{1}/
        if (detail !== '') {
            if (CheckValue === '' || CheckValue.length <= 2 || !pattern.test(CheckValue.replace(/ +/g, '').trim())) {
                if (CheckValue === '') {
                    const element = document.getElementById('edit-description') as HTMLElement
                    element.style.border = '1px solid red'
                    setTextError('Description is required')
                    setIsEditIncidentLoading(false)
                    return
                } else {
                    const element = document.getElementById('edit-description')  as HTMLElement
                    if (pattern.test(CheckValue.replace(/ +/g, '').trim())) {
                        if (CheckValue.replace(/ +/g, '').trim().length >= 3) {
                            setTextError('')
                        } else {
                            setTextError('Enter more than 3 characters')
                            element.style.border = '1px solid red'
                            setIsEditIncidentLoading(false)
                            return
                        }
                    } else {
                        if (format.test(CheckValue.replace(/ +/g, '').trim())) {
                            setTextError('Enter more than 1 letter and can not contain any of the following characters ' + format)
                            element.style.border = '1px solid red'
                            setIsEditIncidentLoading(false)
                            return
                        } else {
                            setTextError('Enter more than 3 characters')
                            element.style.border = '1px solid red'
                            setIsEditIncidentLoading(false)
                            return
                        }
                    }
                }
                return
            }
        } else if (detail === '') {
            const element = document.getElementById('edit-description')  as HTMLElement
            element.style.border = '1px solid red'
            setTextError('Description is required')
            setIsEditIncidentLoading(false)
            return
        }

        uploadImageInTextArea(detail, (newDetail) => {
            props.updateIncident(
                {
                    id: Number(id),
                    subject: changeTitle || '',
                    description: newDetail || '',
                    customField: props.incident?.customField
                } as Incident
            )
                .then(() => {
                    message.success('The data has been updated successfully.')
                    setVisible(false)
                    setIsEditIncidentLoading(false)
                })
                .catch((err) => {
                    setIsEditIncidentLoading(false)
                    if (isTanentFreshService) {
                        ModalFreshService(err)
                    } else {
                        message.error(`Unable to save changes. Please try again later. ${err}`)
                    }
                })
        })
        // props.updateIncident(
        //     {
        //         id: Number(id),
        //         subject: changeTitle || '',
        //         description: changeDescription || ''
        //     } as Incident
        // )
        //     .then(() => {
        //         message.success('The update has finished successfully.')
        //         setVisible(false)
        //         setIsEditIncidentLoading(false)
        //     })
        //     .catch((err) => {
        //         setIsEditIncidentLoading(false)
        //         message.error(`Failed saving changes. ${err}`)
        //     })
    }

    const handleCancel = () => {
        props.form.setFieldsValue({
            Topic: topic,
            Description: description
        })
        setDetail(description)
        setVisible(false)
        setPreviewVisible(false)
    }

    // Button Show SLA and Table

    const handleShowWorkLog = () => {
        setShowWorkLog(!showWorkLog)
        fetchAllWorkLog().catch((err) => message.error(`Unable to retrieve work log data. Please try again later. ${err}`))
        setShowSla(false)
        setshowAssetRelated(false)
        setshowWorkflowRelated(false)
        setShowTicketRelated(false)
        setShowKnowledgeRelated(false)
        setShowProblem(false)
    }

    const handleShowSLA = () => {
        setShowSla(!showSla)
        setShowWorkLog(false)
        setshowAssetRelated(false)
        setshowWorkflowRelated(false)
        setShowTicketRelated(false)
        setShowKnowledgeRelated(false)
        setShowProblem(false)
    }

    // Control Panel of Reply, Forward, AddNote, Discuss

    const controlStatusDetail = () => {
        if (currentStatus?.status === 'Pending') {
            return <div>
                <Row style={{ marginTop: isNonDesktop ? '10px' : '0px' }}><Col lg={8} md={6} xs={9} style={{ color: '#EB5757' }}>Pending until </Col><Col lg={15} style={{ color: '#EB5757' }}>{moment((pendingUntil as string)).format('YYYY-MM-DD HH:mm:ss')}
                    {/* <span style={{ color: '#EB5757' }}>Pending until {moment((pendingUntil as string)).format('YYYY-MM-DD HH:mm:ss')}</span> */}
                    <Tooltip placement="bottom" title={pendingReason}>
                        <Icon style={{ marginLeft: '10px', backgroundColor: '#E0E0E0', borderRadius: '3px', width: '20px' }} type="ellipsis" />
                    </Tooltip>
                    <span onClick={openEditModalPending}><Icon type="edit" /></span></Col></Row>
            </div>
        } else {
            if (SLACalDueDate === 'NoData') {
                return <div />
            } else {
                return <div>
                    {/* <Row style={{ marginTop: isNonDesktop ? '10px' : '0px' }}><Col lg={8} md={6} xs={9} style={{ color: '#323276' }}>Created : </Col><Col lg={15} style={{ color: '#323276' }}>{moment(createdDatetime).format('YYYY-MM-DD HH:mm:ss')}</Col></Row> */}
                    <span style={{ marginTop: isNonDesktop ? '10px' : '0px', color: '#323276' }}>Due Date : </span><span style={{ color: '#323276' }}>{SLACalDueDate}</span>
                </div>
            }
        }
    }

    const { TextArea } = Input
    const { getFieldDecorator } = props.form

    const getSlaPolicy = (slaPolicyHistory) => {
        return props.slaCalculationDataSuit?.slaPolicyHistories.find((sla: any) => sla.id === slaPolicyHistory)
    }

    const convertCamelCaseToSentenceCase = (fieldValue: string) => {
        // adding space between strings
        const result = fieldValue.replace(/([A-Z])/g, ' $1')

        // converting first character to uppercase and join it to the final string
        return result.charAt(0).toUpperCase() + result.slice(1)
    }
    const mappingWord = (wording: string) => {
        if (wording === 'supportTeam') {
            return 'assignmentGroup'
        }
        if (wording === 'assignee') {
            return 'assignedTo'
        } else {
            return wording
        }
    }

    const createSLAWorklog = (worklog: Worklog) => {
        const getNewValue = (fieldName: string) => {
            console.log(fieldName)
            console.log(worklog)
            return (worklog.changes || []).find((change) => change.fieldName === fieldName)?.newValue
        }

        const getOldValue = (fieldName: string) => {
            return (worklog.changes || []).find((change) => change.fieldName === fieldName)?.oldValue
        }
        const slaCalculationsMap: SlaCalculation[] = props.slaCalculationDataSuit!!.slaCalculationsMap[id]
        let slaPolicyName, slaGoalName, slaStatus
        let elements: JSX.Element = <div />
        if (worklog.slaCalculationId != null) {
            const slaCalculation = slaCalculationsMap.find((slaCal) => slaCal.id === worklog.slaCalculationId)
            console.log('slaCalculation', slaCalculation)
            if (slaCalculation) {
                const slaHistory = getSlaPolicy(slaCalculation.slaPolicyHistoryId)
                console.log('slaHistory', slaHistory)
                slaPolicyName = slaHistory?.name
                slaGoalName = slaCalculation.slaGoalName
                slaStatus = slaCalculation.slaStatus
            }
            if (worklog.type === 'SlaAttached') {
                if (!worklog.createdBy) {
                    worklog.createdBy = 'System'
                }
                elements = <Timeline.Item color={'blue'}>{renderWorkLog(moment(worklog.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), ` ${worklog.createdBy} attached to SLA goal ${getNewValue('slaGoalName')} of SLA policy ${slaPolicyName} at status ${getNewValue('slaStatus')} and stage ${getNewValue('slaStage')}`)}</Timeline.Item>
            }
            if (worklog.type === 'SlaDetached') {
                if (!worklog.createdBy) {
                    worklog.createdBy = 'System'
                }
                elements = <Timeline.Item color={'blue'}>{renderWorkLog(moment(worklog.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), ` ${worklog.createdBy} detached from SLA goal ${slaGoalName} of SLA policy  ${slaPolicyName} `)}</Timeline.Item>
            }
            if (worklog.type === 'SlaChanged') {
                (worklog.changes || []).forEach((ch: WorklogChange) => {
                    if (!worklog.createdBy) {
                        worklog.createdBy = 'System'
                        if (ch.fieldName === 'slaStatus') {
                            elements = <Timeline.Item color={'blue'}>{renderWorkLog(moment(worklog.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), ` SLA goal ${slaGoalName} of SLA policy ${slaPolicyName}  has status changed from ${getOldValue('slaStatus')} to ${getNewValue('slaStatus')} `)} </Timeline.Item>
                        }
                    } else {
                        if (ch.fieldName === 'slaStage') {
                            elements = <Timeline.Item color={'blue'}>{renderWorkLog(moment(worklog.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), ` SLA goal ${slaGoalName} of SLA policy ${slaPolicyName}  has stage changed from ${getOldValue('slaStage')} to ${getNewValue('slaStage')} with status as ${slaStatus} `)} </Timeline.Item>
                        }
                    }
                })
            }
            return elements
        }
        return <div />
    }

    const renderWorkLog = (dateFormat: string, data: string) => {
        return <Row>
            <Col span={4}>
                {dateFormat}
            </Col>
            <Col span={20}>
                {data}
            </Col>
        </Row>
    }

    const displayWorkLog = () => {
        // eslint-disable-next-line prefer-const
        let workLogElements: JSX.Element[] = []
        // eslint-disable-next-line react/prop-types
        // eslint-disable-next-line react/prop-types
        for (const wl of props.workLog) {
            let statusBeforePending: string | undefined
            let pendingUntil: string | undefined

            const getNewValue = (fieldName: string) => {
                return (wl.changes || []).find((change) => change.fieldName === fieldName)?.newValue
            }

            const getMoreValueOnCreateOfAssignGroup = (fieldNameMore: string) => {
                const result = (wl.changes || []).find((change) => change.fieldName === fieldNameMore)
                if (result) {
                    if (result.newValue !== '') {
                        return ', Assignment group as ' + (wl.changes || []).find((change) => change.fieldName === fieldNameMore)?.newValue
                    } else {
                        return ''
                    }
                } else {
                    return ''
                }
            }

            const getMoreValueOnCreateOfAssignee = (fieldNameAssign: string) => {
                const result = (wl.changes || []).find((change: WorklogChange) => change.fieldName === fieldNameAssign)
                if (result) {
                    // eslint-disable-next-line quotes
                    if (result.newValue !== "") {
                        return ', Assigned to as ' + (wl.changes || []).find((change: WorklogChange) => change.fieldName === fieldNameAssign)?.newValue
                    } else {
                        return ''
                    }
                } else {
                    return ''
                }
            }

            const getMoreValueOnCreateOfAssetRelated = (fieldName: string) => {
                const result = (wl.changes || []).find((change) => change.fieldName === fieldName)
                if (result) {
                    if (result.newValue !== null) {
                        return ', ' + (wl.changes || []).find((change) => change.fieldName === fieldName)?.newValue
                    } else {
                        return ''
                    }
                } else {
                    return ''
                }
            }

            const createDeleteAssetRelated = () => {
                return (wl.changes || []).find((change) => change.fieldName === 'assetRelated' || 'knowledgeRelated')?.newValue
            }

            const createTicketRelated = () => {
                return (wl.changes || []).find((change) => change.fieldName === 'RelatedTicket')?.newValue
            }

            const deleteTicketRelated = () => {
                return (wl.changes || []).find((change) => change.fieldName === 'DeleteRelatedTicket')?.newValue
            }

            const UpdateTicketRelatedWorkFlow = () => {
                return (wl.changes || []).find((change) => change.fieldName === 'Workflows')?.newValue
            }

            const AddedNote = () => {
                return (wl.changes || []).find((change) => change.fieldName === 'AddNote')?.newValue
            }

            if (wl.type === 'Create') {
                // workLogElements.push(<Timeline.Item color={'green'}>{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ${wl.createdBy} created ticket with Status as ${getNewValue('ticketStatus')}, ${getNewValue('priority')}${getMoreValueOnCreateOfAssignGroup('Assignment Group')}${getMoreValueOnCreateOfAssignee('Assignee')}`}</Timeline.Item>)
                workLogElements.push(<Timeline.Item color={'green'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} created ticket with Status as ${getNewValue('ticketStatus')}, ${getNewValue('priority')}${getMoreValueOnCreateOfAssignGroup('Assignment Group')}${getMoreValueOnCreateOfAssignee('Assignee')} ${getMoreValueOnCreateOfAssetRelated('RelatedTicket')} ${getMoreValueOnCreateOfAssetRelated('assetRelated')} ${getMoreValueOnCreateOfAssetRelated('relatedKm')} ${getMoreValueOnCreateOfAssetRelated('Workflows')}`)}</Timeline.Item>)
            } else if (wl.type === 'Pending') {
                (wl.changes || []).forEach((ch: any) => {
                    if (ch.fieldName === 'ticketStatus') {
                        statusBeforePending = ch.oldValue
                    }
                    if (ch.fieldName === 'pendingUntil') {
                        pendingUntil = ch.newValue
                    }
                })
                if (statusBeforePending !== undefined) {
                    // workLogElements.push(<Timeline.Item dot={<Icon type="pause-circle" style={{ fontSize: '16px' }} />} color="red">{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ${wl.createdBy} changed status from ${statusBeforePending} to Pending until ${moment(pendingUntil).format('YYYY-MM-DD HH:mm:ss')}`}</Timeline.Item>)
                    workLogElements.push(<Timeline.Item dot={<Icon type="pause-circle" style={{ fontSize: '16px' }} />} color="red">{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} changed status from ${statusBeforePending} to Pending until ${moment(pendingUntil).format('YYYY-MM-DD HH:mm:ss')}`)}</Timeline.Item>)
                } else {
                    // workLogElements.push(<Timeline.Item dot={<Icon type="pause-circle" style={{ fontSize: '16px' }} />} color="red">{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ${wl.createdBy} changed Pending until ${moment(pendingUntil).format('YYYY-MM-DD HH:mm:ss')}`}</Timeline.Item>)
                    workLogElements.push(<Timeline.Item dot={<Icon type="pause-circle" style={{ fontSize: '16px' }} />} color="red">{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} changed Pending until ${moment(pendingUntil).format('YYYY-MM-DD HH:mm:ss')}`)}</Timeline.Item>)
                }
            } else if (wl.type === 'Update') {
                (wl.changes || []).forEach((ch: WorklogChange) => {
                    if (ch.fieldName === 'category' || ch.fieldName === 'subcategory' || ch.fieldName === 'item') {
                        return null
                    } else {
                        if (!wl.createdBy) {
                            wl.createdBy = 'system'
                        }
                        if (ch.fieldName === 'pendingUntil' && ch.oldValue !== null) {
                            // workLogElements.push(<Timeline.Item color="blue">{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ${wl.createdBy} changed Pending until ${moment(ch.oldValue!!).format('YYYY-MM-DD HH:mm:ss')}`}</Timeline.Item>)
                            workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} changed Pending until ${moment(ch.oldValue!!).format('YYYY-MM-DD HH:mm:ss')}`)}</Timeline.Item>)
                        } else if (ch.fieldName === 'resolution') {
                            // workLogElements.push(<Timeline.Item color={'blue'}>{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ${wl.createdBy} added ${convertCamelCaseToSentenceCase(mappingWord(ch.fieldName))}`}</Timeline.Item>)
                            workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} added ${convertCamelCaseToSentenceCase(mappingWord(ch.fieldName))}`)}</Timeline.Item>)
                        } else {
                            // workLogElements.push(<Timeline.Item color={'blue'}>{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ${wl.createdBy} changed ${convertCamelCaseToSentenceCase(mappingWord(ch.fieldName))} from ${ch.oldValue} to ${ch.newValue}`}</Timeline.Item>)
                            workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} changed ${convertCamelCaseToSentenceCase(mappingWord(ch.fieldName))} from ${ch.oldValue} to ${ch.newValue}`)}</Timeline.Item>)
                        }
                    }
                })
            } else if (wl.type === 'SlaAttached') {
                workLogElements.push(createSLAWorklog(wl))
            } else if (wl.type === 'SlaDetached') {
                workLogElements.push(createSLAWorklog(wl))
            } else if (wl.type === 'SlaChanged') {
                workLogElements.push(createSLAWorklog(wl))
                // (wl.changes || []).forEach((ch: WorklogChange) => {
                //     if (!wl.createdBy) {
                //         wl.createdBy = 'System'
                //         if (ch.fieldName === 'slaStatus') {
                //             workLogElements.push(<Timeline.Item color={'blue'}>{moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss') + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0 SLA goal ${slaGoalName} of SLA policy ${slaPolicyName}  has status changed from ${getOldValue('slaStatus')} to ${getNewValue('slaStatus')} `} </Timeline.Item>)
                //         }
                //     } else {
                //         if (ch.fieldName === 'slaStage') {
                //             workLogElements.push(<Timeline.Item color={'blue'}>{moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss') + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0 SLA goal ${slaGoalName} of SLA policy ${slaPolicyName}  has stage changed from ${getOldValue('slaStage')} to ${getNewValue('slaStage')} with status as ${slaStatus} `} </Timeline.Item>)
                //         }
                //     }
                // })
            } else if (wl.type === 'DeleteAssetRelated') {
                workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} ${createDeleteAssetRelated()}`)}</Timeline.Item>)
            } else if (wl.type === 'RelatedAsset') {
                workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} ${createDeleteAssetRelated()}`)}</Timeline.Item>)
            } else if (wl.type === 'RelatedKm') {
                workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} ${createDeleteAssetRelated()}`)}</Timeline.Item>)
            } else if (wl.type === 'DeleteKmRelated') {
                workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} ${createDeleteAssetRelated()}`)}</Timeline.Item>)
            } else if (wl.type === 'DeleteRelatedTicket') {
                workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} ${deleteTicketRelated()}`)}</Timeline.Item>)
            } else if (wl.type === 'RelatedTicket') {
                workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} ${createTicketRelated()}`)}</Timeline.Item>)
            } else if (wl.type === 'RelateWorkflow') {
                workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} ${UpdateTicketRelatedWorkFlow()}`)}</Timeline.Item>)
            } else if (wl.type === 'Comment') {
                workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} ${AddedNote()}`)}</Timeline.Item>)
            }
        }
        return (
            <Timeline style={{ marginLeft: '40px', marginTop: '10px' }}>
                {workLogElements.map((temps: any, i: number) => {
                    return (
                        <div key={i}>{temps}</div>
                    )
                })}
            </Timeline>
        )
    }

    const ToggleIsReadMoreTopic = () => {
        setIsReadMoreTopic(!isReadMoreTopic)
    }

    const ToggleIsReadMoreDesc = () => {
        setIsReadMoreDesc(!isReadMoreDesc)
    }

    // const ToggleIsReadMoreResolution = () => {
    //     setIsReadMoreResolution(!isReadMoreResolution)
    // }

    const handleDownload = (file) => {
        const link = document.createElement('a')
        link.href = file.url
        link.download = file.name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png') || ((file.url) as string).includes('jpeg') || ((file.url) as string).includes('PNG')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const fileListView = () => {
        const fileList = props.incident!!.uploadLink?.map((file: UploadLink) => {
            const uploadFile: UploadFile = {
                uid: file.name,
                name: file.name,
                status: 'done',
                url: file.url,
                type: file.type,
                size: 0,
                thumbUrl: exportIcon(file.type)
            }
            return uploadFile
        })
        return (
            <Upload
                listType="picture"
                className={'upload-list-inline'}
                fileList={fileList}
                onPreview={(e: any) => handlePreview(e)}
                // onChange={(e: any) => setFileList(fileList)}
                onDownload={(e: any) => handleDownload(e)}
                showUploadList={{ showRemoveIcon: false, showDownloadIcon: true }}
            />
        )
        // (<Upload
        //     listType="picture-card"
        //     fileList={fileList}
        //     showUploadList={{ showRemoveIcon: false }}
        //     onPreview={handlePreview}
        // />)
    }

    const onFilterOpen = () => {
        setIsFilterOpen(true)
    }

    const onFilterClose = () => {
        setIsFilterOpen(false)
    }

    const leavePageModal = () => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you want to close this page ?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                window.close()
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const handleshowAsset = () => {
        setshowAssetRelated(!showAssetRelated)
        setShowTicketRelated(false)
        setShowWorkLog(false)
        setShowSla(false)
        setshowWorkflowRelated(false)
        setShowKnowledgeRelated(false)
        setShowProblem(false)
        if (!showAssetRelated) {
            fetchAssetRelated()
        }
    }

    const handleshowWorkFlow = () => {
        setTabpaneName('Workflow')
        setshowWorkflowRelated(!showWorkflowRelated)
        setShowWorkLog(false)
        setShowSla(false)
        setshowAssetRelated(false)
        setShowKnowledgeRelated(false)
        setShowProblem(false)
        setShowTicketRelated(false)
        if (!showWorkflowRelated) {
            fetchDataWoWithIncident()
            ticketPacksByincidentId(props.incident?.id?.toString()!!).then((data) => {
                console.log(data)
                if (data.tickets) {
                    const convertDataToWoRalateIncident: WoRalateIncidentCard[] = data.tickets.map((it) => {
                        return {
                            id: it.ticketId,
                            description: JSON.parse(it.ticketCreationRequestBody).description,
                            item_id: it.itemId,
                            order_id: it.ticketNumber,
                            wo_status: it.ticketStatus,
                            workflow_name: JSON.parse(it.ticketCreationRequestBody).workflowName,
                            workflow_number: JSON.parse(it.ticketCreationRequestBody).workflowNumber
                        } as WoRalateIncidentCard
                    })
                    const mergeData = [...dataWoRalateIncident, ...convertDataToWoRalateIncident]
                    const arrayUniqueByKey = [...new Map(mergeData.map(item =>
                        [item['id'], item])).values()]
                    setDataWoRalateIncident(arrayUniqueByKey)
                }
            })
        }
    }

    const handleShowTicket = () => {
        setShowTicketRelated(!showTicketRelated)
        setshowAssetRelated(false)
        setShowWorkLog(false)
        setShowSla(false)
        setShowKnowledgeRelated(false)
        setShowProblem(false)
        setshowWorkflowRelated(false)
        console.log(showTicketRelated)
        if (!showTicketRelated) {
            fetchTicketRelated()
        }
    }

    const handleShowProblems = () => {
        setShowProblem(!showProblem)
        setshowAssetRelated(false)
        setShowWorkLog(false)
        setShowSla(false)
        setShowKnowledgeRelated(false)
        setshowWorkflowRelated(false)
        setShowTicketRelated(false)
    }

    const displayProblemRelate = () => {
        return <div>
            <RelateProblemHome dataIncident={props.incident!!} />
        </div>
    }

    const displayTicketRelated = () => {
        const tempparent = TicketRelatedState?.parent?.map((it) => {
            it.relateType = 'parent'
            return it
        }) || []
        const tempchild = TicketRelatedState?.child?.map((it) => {
            it.relateType = 'child'
            return it
        }) || []
        const tempTicketSliceShow = [...tempparent, ...tempchild]?.slice((ticketCurrentPage - 1) * 10, ticketCurrentPage * 10)
        if (ticketCurrentPage > 1 && tempTicketSliceShow?.length === 0) {
            setTicketCurrentPage(ticketCurrentPage - 1)
        }
        return (
            <div>
                <Spin spinning={isLoadingDataRelated}>
                    <Row>
                        <Col span={21}>
                            <p style={{ paddingTop: 2 }} className='sub-title'>Ticket Related</p>
                        </Col>
                        <Col span={3} style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: -10 }} >
                            {!isTanentFreshService
                                ? <Button disabled={!checkRolesState(RoleType.Incident, 'UpdateIncident') || props.incident?.ticketStatus.status === 'Resolved' || props.incident?.ticketStatus.status === 'Cancelled' || props.incident?.ticketStatus.status === 'Closed'}
                                    className='feck-link-Button'
                                    onClick={handleShowTicketRelatedModal}
                                    style={{ border: 'none', boxShadow: 'none', marginRight: -10 }}
                                    type='dashed'
                                >
                                    Select Ticket
                                </Button>
                                : null
                            }
                        </Col>
                    </Row>
                    <br /> {tempTicketSliceShow?.length > 0 ? <div style={{ overflowY: 'scroll', maxHeight: 600 }}>
                        {tempTicketSliceShow?.map((it: ticketRelated, index: number) => {
                            return <>
                                <div key={it.id} className='TicketRelateCard'>
                                    <Row>
                                        <Col span={23}>
                                            <span><b>Ticket Number :</b> <Link to={!checkRolesState(RoleType.Incident, 'UpdateIncident') ? `/IncidentViewMoreDetails/${it.id}` : `/IncidentDetailsView/${it.id}`} target="_blank" onClick={() => waitingGetSessions()}>{it.number}</Link>
                                                <Tag style={{ backgroundColor: isTanentFreshService && it?.freshserviceTicketPriority !== null ? it?.freshserviceTicketPriority?.tagColor : it.priority?.tagColor, color: '#fff', marginLeft: 10 }}>{isTanentFreshService && it?.freshserviceTicketPriority !== null ? it?.freshserviceTicketPriority?.name : it.priority?.name}</Tag>
                                                {it.relateType === 'parent' ? <Tooltip placement='bottom' title={'Parent of this ticket'}>
                                                    <Tag style={{ color: '#fff', backgroundColor: '#EB5757' }}>Parent</Tag>
                                                    {/* <Icon type='logout' style={{ color: '#fff', backgroundColor: '#EB5757', borderRadius: 7, padding: 5 }} /> */}
                                                </Tooltip> : <Tooltip placement='bottom' title={'Child of this ticket'}>
                                                    <Tag style={{ color: '#fff', backgroundColor: '#4DC977' }}>Child</Tag>
                                                    {/* <Icon type='login' style={{ color: '#fff', backgroundColor: '#4DC977', borderRadius: 7, padding: 5 }} /> */}
                                                </Tooltip>}
                                            </span>
                                        </Col>
                                        <Col span={1} >
                                            <Tooltip title='Delete' placement={'bottom'}>
                                                {it.relateType === 'parent' ? null : <Button className='TicketRelateCard-DeleteButton'
                                                    disabled={(!checkRolesState(RoleType.Incident, 'UpdateIncident')) || props.incident?.ticketStatus.status === 'Resolved' || props.incident?.ticketStatus.status === 'Cancelled' || props.incident?.ticketStatus.status === 'Closed'}
                                                    onClick={(e) => { showConfirmDelteTicketRelated(it?.id) }}
                                                    icon="delete">
                                                </Button>}
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <span>Topic : {it.topic}</span>
                                        </Col>
                                        <Col span={6} >
                                            <span>Status : {it.status}</span>
                                        </Col>
                                        <Col span={6}>
                                            <span>Requester : {it.requester}</span>
                                        </Col>
                                    </Row>
                                    {it.relateType === 'parent'
                                        ? <Row style={{ marginTop: '20px' }}>
                                            <Collapse>
                                                <Panel header="Related Other" key="1">
                                                    {it.relatedOther !== null
                                                        ? it.relatedOther?.map((data, index) => {
                                                            return <RenderTicketRelateOther ticket={data} isTanentFreshService={isTanentFreshService}/>
                                                        })
                                                        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    }
                                                </Panel>
                                            </Collapse>
                                        </Row>
                                        : null
                                    }
                                </div>
                            </>
                        })} </div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    {/* {TicketRelatedState.parent.length > 0 ? tempTicketParentSliceShow.map((it: Incident, index: number) => {
                        return <>
                            <div key={it.id} className='TicketRelateCard'>
                                <Row>
                                    <Col span={23}>
                                        <span>Ticket Number : {it.number} <Tag style={{ backgroundColor: it.priority?.priority?.tagColor, color: '#fff', marginLeft: 10 }}>{it.priority?.priority?.name}</Tag></span>
                                    </Col>
                                    <Col span={1}>
                                        <Tooltip placement='bottom' title={'Parent of this ticket'}>
                                            <Icon type='logout' style={{ color: '#fff', backgroundColor: '#EB5757', borderRadius: 7, padding: 5 }} />
                                        </Tooltip>
                                    </Col>
                                    <Col span={1} >
                                        <Button className='TicketRelateCard-DeleteButton'
                                            disabled={props.incident?.ticketStatus.status === 'Resolved' || props.incident?.ticketStatus.status === 'Cancelled' || props.incident?.ticketStatus.status === 'Closed'}
                                            onClick={(e) => { showConfirmDelteTicketRelated(it?.id) }}
                                            icon="delete">
                                        </Button>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        <span>Topic : {it.subject}</span>
                                    </Col>
                                    <Col span={12} >
                                        <span>Status : {it.ticketStatus.status}</span>
                                    </Col>
                                    <Col span={6}>
                                        <span>Requester : {it.people.fullName}</span>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    }) : null}
                    {TicketRelatedState.child.length > 0 ? tempTicketChildSliceShow.map((it: Incident, index: number) => {
                        return <>
                            <div key={it.id} className='TicketRelateCard'>
                                <Row>
                                    <Col span={22}>
                                        <span>Ticket Number : {it.number} <Tag style={{ backgroundColor: it.priority?.priority?.tagColor, color: '#fff', marginLeft: 10 }}>{it.priority?.priority?.name}</Tag></span>
                                    </Col>
                                    <Col span={1}>
                                        <Tooltip placement='bottom' title={'Child of this ticket'}>
                                            <Icon type='login' style={{ color: '#fff', backgroundColor: '#4DC977', borderRadius: 7, padding: 5 }} />
                                        </Tooltip>
                                    </Col>
                                    <Col span={1} >
                                        <Button className='TicketRelateCard-DeleteButton'
                                            disabled={props.incident?.ticketStatus.status === 'Resolved' || props.incident?.ticketStatus.status === 'Cancelled' || props.incident?.ticketStatus.status === 'Closed'}
                                            onClick={(e) => { showConfirmDelteTicketRelated(it?.id) }}
                                            icon="delete">
                                        </Button>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        <span>Topic : {it.subject}</span>
                                    </Col>
                                    <Col span={12} >
                                        <span>Status : {it.ticketStatus.status}</span>
                                    </Col>
                                    <Col span={6}>
                                        <span>Requester : {it.people.fullName}</span>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    }) : null} */}
                    {TicketRelatedState?.parent?.length === 0 && TicketRelatedState?.child?.length === 0 ? null
                        : <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 20 }}>
                            <Pagination total={TicketRelatedState?.parent?.length + TicketRelatedState?.child?.length} pageSize={10} current={ticketCurrentPage} onChange={(page) => setTicketCurrentPage(page)} />
                        </div>}
                </Spin>
            </div>
        )
    }

    const displayAssetRelated = () => {
        const tempAssetSliceShow = AssetRelatedState.slice((assetCurrentPage - 1) * 10, assetCurrentPage * 10)
        if (assetCurrentPage > 1 && tempAssetSliceShow.length === 0) {
            setAssetCurrentPage(assetCurrentPage - 1)
        }
        return (
            <div>
                <Spin spinning={isLoadingDataRelated}>
                    <Row>
                        <Col span={21}>
                            <p style={{ paddingTop: 2 }} className='sub-title'>Asset Related</p>
                        </Col>
                        <Col span={3} style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: -10 }} >
                            <Button disabled={!checkRolesState(RoleType.Incident, 'UpdateIncident') || props.incident?.ticketStatus.status === 'Resolved' || props.incident?.ticketStatus.status === 'Cancelled' || props.incident?.ticketStatus.status === 'Closed'}
                                className='feck-link-Button'
                                onClick={handleShowAssetRelatedModal}
                                style={{ border: 'none', boxShadow: 'none', marginRight: -10 }}
                                type='dashed'
                            >
                                Select Asset
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    {props.incident?.ticketRelateAsset?.length !== 0 || AssetRelatedState.length > 0 ? <> <div style={{ overflowY: 'scroll', maxHeight: 600 }}> {tempAssetSliceShow.map((it: ticketRelateAsset, index: number) => {
                        return <>
                            <div key={it.id} className='TicketRelateCard'>
                                <Row>
                                    <Col span={23}>
                                        <span>Serial Number : {it.serialNumber}</span>
                                    </Col>
                                    <Col span={1} >
                                        <Tooltip title='Delete' placement={'bottom'}>
                                            <Button className='TicketRelateCard-DeleteButton'
                                                disabled={props.incident?.ticketStatus.status === 'Resolved' || props.incident?.ticketStatus.status === 'Cancelled' || props.incident?.ticketStatus.status === 'Closed'}
                                                onClick={(e) => { showConfirmDeleteAssetRelated(it?.id) }}
                                                icon="delete">
                                            </Button>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={8}>
                                        <span>Owner ID : {it.originalAsset.people?.employeeId} - {it.originalAsset.people?.fullName}</span>
                                    </Col>
                                    <Col span={8}>
                                        <span>Location : {it.originalAsset.location}</span>
                                    </Col>
                                    <Col span={8}>
                                        <span>Asset Status : {it.originalAsset.status}</span>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    })}</div>
                        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 20 }}>
                            <Pagination total={AssetRelatedState.length} pageSize={10} current={assetCurrentPage} onChange={(page) => setAssetCurrentPage(page)} />
                        </div>
                    </> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </Spin>
            </div>
        )
    }
    const showConfirmDeleteAssetRelated = (assetId: number | undefined) => {
        confirm({
            title: 'Are you sure ?',
            content: 'Do you confirm that you want to delete this data ?',
            onOk() {
                const DeleteData = { id: assetId, active: false }
                DeletedTicketRelatedIncidentView(assetId, DeleteData).then((res) => {
                    fetchAssetRelated()
                }).catch((err) => {
                    message.error(err)
                })
            },
            onCancel() { }
        })
    }

    const handleShowKnowledgeRelatedModal = () => {
        setIsModalRelatedVisible(true)
        setTabpaneName('Knowledge')
    }

    const handleShowKnowledge = () => {
        setShowKnowledgeRelated(!showKnowledgeRelated)
        setshowAssetRelated(false)
        setShowWorkLog(false)
        setShowSla(false)
        setShowTicketRelated(false)
        setshowWorkflowRelated(false)
        setShowProblem(false)
        if (showKnowledgeRelated === false) {
            fetchKnowledgeRelated()
        }
    }

    const displayKnowledgeRelated = () => {
        const tempKnowledgeSliceShow = KnowledgeRelatedState.slice((kmCurrentPage - 1) * 10, kmCurrentPage * 10)
        if (kmCurrentPage > 1 && tempKnowledgeSliceShow.length === 0) {
            setKmCurrentPage(kmCurrentPage - 1)
        }
        return (
            <div>
                <Spin spinning={isLoadingDataRelated}>
                    <Row>
                        <Col span={21}>
                            <p style={{ paddingTop: 2 }} className='sub-title'>Knowledge Related</p>
                        </Col>
                        <Col span={3} style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                            <Button disabled={!checkRolesState(RoleType.Incident, 'UpdateIncident') || props.incident?.ticketStatus.status === 'Resolved' || props.incident?.ticketStatus.status === 'Cancelled' || props.incident?.ticketStatus.status === 'Closed'}
                                className='feck-link-Button'
                                onClick={handleShowKnowledgeRelatedModal}
                                style={{ border: 'none', boxShadow: 'none', marginRight: -10 }}
                                type='dashed'
                            >
                                Select Knowledge
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    {KnowledgeRelatedState.length > 0 ? <> <div style={{ overflowY: 'scroll', maxHeight: 600 }}> {tempKnowledgeSliceShow.map((it) => {
                        return <div className='TicketRelateCard' key={it.id}>
                            <Row>
                                <Col span={23} >
                                    Knowledge Number :
                                    {it.visible ? <Link to={`/KnowledgeRelatedPreview/${it.id}/Related`} target="_blank" onClick={() => waitingGetSessions()}> {it.ticketNumber}</Link>
                                        : <span className='feck-link' onClick={warningOpenKm}> {it.ticketNumber}</span>}
                                </Col>
                                <Col span={1} >
                                    <Tooltip title='Delete' placement={'bottom'}>
                                        <Button className='TicketRelateCard-DeleteButton'
                                            disabled={props.incident?.ticketStatus.status === 'Resolved' || props.incident?.ticketStatus.status === 'Cancelled' || props.incident?.ticketStatus.status === 'Closed'}
                                            onClick={(e) => { showConfirmDeleteKmRelated(it.ticketNumber) }}
                                            icon="delete">
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={16}>
                                    <span>Topic : {it.topic}</span>
                                </Col>
                                <Col span={8} >
                                    <span>Created By : {it.createdByDisplay}</span>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col span={24}>
                                    <span style={{ marginRight: '15px' }}>
                                        <Icon type="like" theme="filled" />
                                        <span style={{ marginLeft: '10px' }}>{it.helpful ? it.helpful : 0}</span>
                                    </span>
                                    <span style={{ marginRight: '15px' }}>
                                        <Icon type="dislike" theme="filled" />
                                        <span style={{ marginLeft: '10px' }}>{it.notHelpful ? it.notHelpful : 0}</span>
                                    </span>
                                    <span style={{ marginRight: '15px' }}>
                                        <Icon type="eye" theme="filled" />
                                        <span style={{ marginLeft: '10px' }}>{it.views ? it.views : 0}</span>
                                    </span>
                                    <span style={{ marginRight: '15px' }}>
                                        <Icon type="check-square" />
                                        <span style={{ marginLeft: '10px' }}>{it.used ? it.used : 0}</span>
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    })}
                    </div>
                        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 20 }}>
                            <Pagination total={KnowledgeRelatedState.length} pageSize={10} current={kmCurrentPage} onChange={(page) => setKmCurrentPage(page)} />
                        </div>
                    </> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </Spin>
            </div>
        )
    }

    const showConfirmDelteTicketRelated = (ticketNumber: number | undefined) => {
        confirm({
            title: 'Are you sure ?',
            content: 'Do you confirm that you want to delete this data ?',
            onOk() {
                const ticketId = id
                DeletedTicketRelatedTicketIncView(ticketId, ticketNumber).then(() => {
                    fetchTicketRelated()
                }).catch((err) => {
                    message.error(err)
                })
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const showConfirmDeleteKmRelated = (kmNumber: string) => {
        confirm({
            title: 'Are you sure ?',
            content: 'Do you confirm that you want to delete this data ?',
            onOk() {
                DeletedKnowledgeRelatedIncidentView(id, kmNumber).then(() => {
                    fetchKnowledgeRelated()
                }).catch((err) => {
                    message.error(err)
                })
            },
            onCancel() { }
        })
    }
    const handleShowAssetRelatedModal = () => {
        setIsModalRelatedVisible(true)
        setTabpaneName('Asset')
    }
    const handleShowTicketRelatedModal = () => {
        setIsModalRelatedVisible(true)
        setTabpaneName('Ticket')
    }

    const warningOpenKm = () => {
        Modal.warning({
            title: 'Warning',
            content: "Sorry, you don't have permission to access this data."
        })
    }

    const closeRelateModal = () => {
        confirm({
            title: 'Are you sure ?',
            content: 'Do you want to leave this page ?',
            onOk() {
                setIsModalRelatedVisible(false)
                props.form.resetFields(['onSearch'])
                setIsCancelModal(true)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }
    const onTextEditorChange = (value: string) => {
        const CheckValue = (value).replace(/<[^>]*>/g, '')
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        const pattern = /^[A-Za-z0-9ก-๙]{1}/
        const element = document.getElementById('edit-description')  as HTMLElement
        if (value) {
            setDetail(value)
            if (CheckValue === '') {
                if (isValidateActive) {
                    element.style.border = '1px solid red'
                    setTextError('Description is required')
                }
            } else {
                if (pattern.test(CheckValue.replace(/ +/g, '').trim())) {
                    if (CheckValue.replace(/ +/g, '').trim().length >= 3) {
                        element.style.border = '0px solid red'
                        setTextError('')
                    } else {
                        element.style.border = '1px solid red'
                        setTextError('Enter more than 3 characters')
                    }
                } else {
                    element.style.border = '1px solid red'
                    if (format.test(CheckValue.replace(/ +/g, '').trim())) {
                        setTextError('Enter more than 1 letter and can not contain any of the following characters ' + format)
                    } else {
                        setTextError('Enter more than 3 characters')
                    }
                }
            }
        }
    }

    const uploadImageInTextArea = (value: string, callBack: Function) => {
        if (value.search('<img')) {
            const MyFileList: UploadFile[] = []
            const temp: string[] = []
            const pattern = /<([A-Z][A-Z0-9]*)\b[^>]*>(.*?)<\/\1>|<([A-Z][A-Z0-9]*).*?\/>/gi
            const HTMLTags = value.match(pattern)
            if (HTMLTags) {
                // when new image is not last
                for (let i = 0; i < HTMLTags.length; i++) {
                    if (HTMLTags[i].search('<img') !== -1) {
                        const tagDatasource = HTMLTags[i].split('<img src="data:')
                        tagDatasource.forEach(tagData => {
                            if (tagData.search('image') !== -1) {
                                temp.push(tagData.split('"')[0])
                            }
                        })
                    } else {
                        temp.push(HTMLTags[i])
                    }
                }
            }

            const newValue: string[] = []
            temp.forEach((it, index) => {
                if (it && it.startsWith('image')) {
                    const fileType = it.split(';')[0]
                    const fileExtention = fileType.split('/')[1] || ''
                    const deleteTagHtml = 'data:' + it.split('">')[0]
                    const ts = `${Math.round((new Date()).getTime() / 1000)}-${index}`
                    const id = Math.floor(Math.random() * 1000)
                    const ConvertUploadLink: UploadFile = {
                        uid: `${id}-${ts}`,
                        name: `${id}-${ts}.${fileExtention}`,
                        type: fileType,
                        url: deleteTagHtml,
                        size: deleteTagHtml.length
                    }
                    MyFileList.push(ConvertUploadLink)
                }
            })

            if (MyFileList.length === 0) callBack(value)

            FSS.putFile(MyFileList, 'knowledgeEditor', (uploadLink) => {
                if (uploadLink) {
                    // eslint-disable-next-line no-unused-expressions
                    uploadLink?.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
                    if (HTMLTags) {
                        let imgIndex = 0
                        HTMLTags.forEach((it, index) => {
                            if (HTMLTags[index].search('<img') !== -1) {
                                const inside64 = HTMLTags[index].split('<img src="data:')
                                console.log('inside64', inside64)
                                inside64.forEach(tagData => {
                                    if (tagData.startsWith('image')) {
                                        const insideReplace = tagData.split('"')[0]
                                        imgIndex++
                                        newValue.push(...tagData.replace(insideReplace, `<img src="${uploadLink[imgIndex - 1].url}`))
                                    } else {
                                        newValue.push(...tagData)
                                    }
                                })
                            } else {
                                newValue.push(HTMLTags[index])
                            }
                        })
                    }
                    const joinV = newValue.join('')
                    console.log(joinV)
                    setDetail(joinV.replace(/width="(\d+)"/g, 'width="$1px"'))
                    callBack(joinV.replace(/width="(\d+)"/g, 'width="$1px"'))
                }
            })
        }
    }
    const handleCloseResolvedModal = () => {
        setIsvisibleResolvedModal(false)
    }

    return (
        <>
            {redirectViewTicket ? (<Redirect to={`/IncidentViewMoreDetails/${id}`} />) : null}
            <Layout>
                <Header style={{ position: 'fixed', zIndex: 2, width: '100%', backgroundColor: '#fff', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                    <Row>
                        <Col span={12}>
                            <div>
                                <h3 style={{ color: '#323276' }}> <Icon type="arrow-left" onClick={() => { window.location.href = '/IncidentList' }} /> <b>{props.incident?.number}</b></h3>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div style={{ textAlign: 'end' }}>
                                <Icon type="close-square" theme="filled" style={{ color: '#FA541C', fontSize: 24 }} onClick={() => leavePageModal()} />
                            </div>
                        </Col>
                    </Row>
                </Header>
                <Modal
                    title={<>
                        <Icon type="snippets" style={{ fontSize: 32, color: '#fa541c' }} /> Create Ticket
                    </>}
                    visible={isVisibleModelTemplate}
                    onCancel={() => setIsVisibleModelTemplate(false)}
                    footer={null}
                >
                    <MyFormCreateTemplete setVisible={setIsVisibleModelTemplate} incident={props.incident} />
                </Modal>
                <Content style={{ padding: '0 50px', marginTop: 64 }}>
                    <Spin spinning={isLoadingInc}>
                        <Row gutter={24}>
                            <br />
                            {/* Left Box */}
                            {isNonDesktop
                                ? <Row>
                                    <Col>
                                        <Button onClick={onFilterOpen} style={{ color: 'blue', border: 'none', marginBottom: '10px', float: 'right' }}><Icon type="filter" />Filter</Button>
                                    </Col>
                                </Row>
                                : null}
                            <Col span={18} lg={18} md={24} sm={24} xs={24}>
                                {/* <Card style={{ backgroundColor: '#F0FFF5', borderLeft: '10px solid #4DC977', boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}> */}
                                <Card style={{ boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                                    <Row >
                                        {isNonDesktop
                                            ? <Row>
                                                <Col span={2} style={{ direction: 'rtl', float: 'right' }}>
                                                    {isSatisfactionDone === 0 ? <Icon type="info-circle" theme="filled" style={{ color: '#0050B3', cursor: 'pointer' }} onClick={() => setIsSatOpen(true)} /> : null}
                                                </Col>
                                                <Col span={8} lg={8} md={4} sm={10} xs={10} style={{ direction: 'rtl', float: 'right', display: props.incident?.ticketStatus.status === 'Cancelled' || props.incident?.ticketStatus.status === 'Closed' ? 'none' : 'block' }}>
                                                    <span onClick={showModal}><Icon type="edit" /></span>
                                                </Col>
                                            </Row>
                                            : null}
                                        <Col lg={3} md={6} xs={9}>
                                            <span style={{ color: '#2F80ED' }}><b>Ticket Number :</b> </span>
                                        </Col>
                                        <Col lg={5} md={15} xs={12}>
                                            <span style={{ color: '#2F80ED' }}>{ticketNumber}</span>
                                        </Col>
                                        <Col lg={4} md={6} xs={9} style={{ marginTop: isNonDesktop ? '10px' : '0px', zIndex: 1 }}>
                                            <Dropdown
                                                disabled={!checkRolesState(RoleType.Incident, 'CloseIncident', props.incident?.createdBy) && !checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident?.createdBy)} overlay={() => my_menu()} trigger={['click']}>
                                                <span className="ant-dropdown-link" style={{ cursor: 'pointer' }}>{currentStatus?.status}<Icon type="down" style={{ marginBottom: '5px' }} /></span>
                                            </Dropdown>

                                            {/* Show Modal Pending */}
                                            <div>
                                                <Modal
                                                    visible={visiblePending}
                                                    title={<b>Pending to Date</b>}
                                                    onOk={handlePendingOk}
                                                    onCancel={handlePendingCancel}
                                                    footer={[
                                                        <Button key="back" onClick={handlePendingCancel}>
                                                            Cancel
                                                        </Button>,
                                                        <Button key="submit" type="primary" onClick={handlePendingOk} loading={isLoading}
                                                            disabled={!checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident?.createdBy) ||
                                                                (disabledSaveButton && currentStatus?.status !== 'Pending')}>
                                                            Save
                                                        </Button>
                                                    ]}
                                                >
                                                    <Form onSubmit={handlePendingOk}>
                                                        <Row gutter={16}>
                                                            <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                                                <Form.Item label={<b style={{ color: '#323276' }}>Date</b>}>
                                                                    {getFieldDecorator('datePending', {
                                                                        initialValue: initialPendingUntilDate,
                                                                        rules: [
                                                                            {
                                                                                type: 'object',
                                                                                required: true,
                                                                                message: 'Please select date!'
                                                                            }
                                                                        ]
                                                                    })(<DatePicker style={{ width: '100%' }}
                                                                        disabledDate={current => {
                                                                            return current!! < moment().startOf('day')
                                                                        }}
                                                                    />)}
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                                                <Form.Item label={<b style={{ color: '#323276' }}>Time</b>}>
                                                                    {getFieldDecorator('timePending', {
                                                                        initialValue: initialPendingUntilTime,
                                                                        rules: [
                                                                            {
                                                                                type: 'object',
                                                                                required: true,
                                                                                message: 'Please select time!'
                                                                            }
                                                                        ]
                                                                    })(<TimePicker style={{ width: '100%' }} />)}
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row>

                                                            <Form.Item label={<b style={{ color: '#323276' }}>Pending Reason</b>}>
                                                                {getFieldDecorator('PendingReason', {
                                                                    initialValue: initialPendingReason,
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            whitespace: true,
                                                                            message: 'Please input pending reason!'
                                                                        }
                                                                    ]
                                                                })(<TextArea style={{ marginTop: '10px' }} rows={4} placeholder={'Pending reason'} maxLength={255} />)}
                                                            </Form.Item>
                                                        </Row>
                                                    </Form>

                                                </Modal>
                                            </div>
                                        </Col>

                                        <Col lg={3} md={4} sm={10} xs={10} style={{ marginTop: isNonDesktop ? '10px' : '0px' }}>
                                            {props.priority.map((value: PriorityLevel, index) => {
                                                return (
                                                    priority?.priority?.name === value.name ? (
                                                        <Tag style={{ backgroundColor: value.tagColor, color: '#fff' }} key={index}>{priority?.priority?.name}</Tag>
                                                    ) : null
                                                )
                                            })}
                                        </Col>
                                        {!isNonDesktop
                                            ? <Row>
                                                <Col lg={8} md={4} sm={10} xs={10} style={{ direction: 'rtl' }}>
                                                    <span style={{ cursor: 'pointer', display: props.incident?.ticketStatus?.status === 'Cancelled' || props.incident?.ticketStatus?.status === 'Closed' ? 'none' : 'block' }}>
                                                        <span onClick={showModal}><Icon type="edit" /></span>
                                                    </span>
                                                </Col>
                                                <Col lg={1} style={{ direction: 'rtl' }}>
                                                    {isSatisfactionDone === 0 && IsCustomerSatisfaction ? <Icon type="info-circle" theme="filled" style={{ color: '#0050B3', cursor: 'pointer' }} onClick={() => setIsSatOpen(true)} /> : null}
                                                </Col>
                                            </Row>
                                            : null}
                                    </Row>

                                    {/* Show Modal Edit */}
                                    <Modal
                                        title={<b>Edit Topic and Description</b>}
                                        onCancel={handleCancel}
                                        visible={visible}
                                        width={'50%'}
                                        footer={[
                                            <Button key="back" onClick={handleCancel}>
                                                Cancel
                                            </Button>,
                                            <Button key="submit" type="primary"
                                                loading={isEditIncidentLoading}
                                                onClick={onSubmitSubjectAndDescription}
                                                disabled={checkSaveButton() || !checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident?.createdBy) || disabledSaveButton}>
                                                Save
                                            </Button>
                                        ]}
                                        maskClosable={false}
                                    >
                                        <Row>
                                            <Form.Item label={<b style={{ color: '#323276' }}>Topic</b>}>
                                                {getFieldDecorator('Topic', {
                                                    initialValue: topic,
                                                    rules: [
                                                        { required: true, message: 'Topic is required' }, { min: 3, message: 'Please enter more than 3 characters.' }
                                                    ]
                                                })(
                                                    <Input onChange={handleChangeTitle} placeholder="Topic" style={{ marginTop: '10px' }} maxLength={255} />
                                                )}
                                            </Form.Item>
                                        </Row>
                                        <Row>
                                            {/* <Form.Item label="Description">
                                            {getFieldDecorator('Description', {
                                                initialValue: description,
                                                rules: [
                                                    { required: true, message: 'Description is required.' }, { min: 3, message: 'Please enter more than 3 characters.' }
                                                ]
                                            })(
                                                <TextArea rows={4} onChange={handleChangeDescription} placeholder="Description" style={{ marginTop: '10px' }} />
                                            )}
                                        </Form.Item> */}
                                            <Form.Item label={<b style={{ color: '#323276' }}>Description</b>} required >
                                                <ReactQuill modules={modules} formats={formats} value={detail} onChange={(e: any) => { onTextEditorChange(e) }} style={{ overflow: 'hide' }} id='edit-description'/>
                                            </Form.Item>
                                            <span style={{ color: 'red' }}>{textError}</span>
                                        </Row>
                                    </Modal>

                                    <Row style={{ marginTop: '10px' }}>
                                        <Col span={6} lg={8} md={24} xs={24} >
                                            {/* <span>Create : {createdDatetime}</span> */}
                                            {(currentStatus?.status === 'New' || currentStatus?.status === 'InProgress' || currentStatus?.status === 'Assigned')
                                                ? <Row><Col lg={9} md={6} xs={9} ><b>Created : </b></Col><Col lg={15}>{moment(createdDatetime).format('YYYY-MM-DD HH:mm:ss')}</Col></Row>
                                                : <Row><Col lg={9} md={6} xs={9} ><b>{currentStatus?.status} : </b>  </Col><Col lg={15}>{moment(lastStatusTimestamp).format('YYYY-MM-DD HH:mm:ss')}</Col></Row>}
                                        </Col>
                                        <Col lg={12} md={24} xs={24}>
                                            {/* <span>Due by in 10 days (on Wed, 5 Feb at 5:00 PM )</span> */}
                                            {controlStatusDetail()}
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '10px' }}>
                                        <Col span={6} lg={8} md={24} xs={24}>
                                            {/* <span>Create : {createdDatetime}</span> */}
                                            {<Col lg={9} md={6} xs={9}><b>Ticket Type : </b></Col>}
                                            {<Col>{ticketType}</Col>}
                                        </Col>
                                        <Col lg={10} md={24} xs={24} style={{ marginTop: isNonDesktop ? '10px' : '0px' }}>
                                            {/* <span>Due by in 10 days (on Wed, 5 Feb at 5:00 PM )</span> */}
                                            {<Col lg={9} md={6} xs={9}><b>Channel :</b> </Col>}
                                            {<Col lg={10} style={{ marginLeft: !isNonDesktop ? '10px' : '0px' }}>{channel}</Col>}
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '10px' }}>
                                        <Col span={3} lg={3} md={6} xs={9} style={{ textAlign: 'justify' }}>
                                            <b>Topic : </b>
                                        </Col>
                                        <Col span={20} lg={20} md={15} xs={14} id='topic' style={{ textAlign: 'justify', textJustify: 'auto', overflowWrap: 'break-word', fontWeight: 'normal' }}>
                                            {isReadMoreTopic
                                                ? <div>{topic}</div>
                                                : <div style={CutTextToReadMore(2)}>
                                                    {topic}
                                                </div>}
                                            {countLines('topic') >= 2 ? !isReadMoreTopic ? <span style={{ fontSize: '14px' }} className='feck-link' onClick={ToggleIsReadMoreTopic}>Read more</span> : <span style={{ fontSize: '14px' }} className='feck-link' onClick={ToggleIsReadMoreTopic}>Read less</span> : null}
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '10px' }} >
                                        <Col span={3} lg={3} md={6} xs={9}>
                                            <b>Description : </b>
                                        </Col>
                                        <Col span={20} lg={20} md={15} xs={14} id='des' style={{ textAlign: 'justify', textJustify: 'auto', overflowWrap: 'break-word', overflowY: 'auto', padding: 0 }} className='ql-editor'>
                                            {isReadMoreDesc
                                                ? <div>{ReactHtmlParser(description)}</div>
                                                : <div style={{ maxHeight: '300px' }}>
                                                    {ReactHtmlParser(description)}
                                                </div>}
                                            {/* {countLines('des') >= 5 || description?.length >= 700 || document.getElementById('des')?.offsetHeight > 300 ? !isReadMoreDesc ? <span className='feck-link' onClick={ToggleIsReadMoreDesc}>Read more</span> : <span className='feck-link' onClick={ToggleIsReadMoreDesc}>Read less</span> : null} */}
                                        </Col>
                                    </Row>
                                    {props.incident?.uploadLink != null && props.incident?.uploadLink?.length
                                        ? <Row style={{ marginTop: '10px' }} >
                                            <Col span={3} lg={3} md={6} xs={9}>
                                                <b>Attachments : </b>
                                            </Col>
                                            <Col span={20} lg={20} md={15} xs={14} id='des' style={{ textAlign: 'justify', textJustify: 'auto', overflowWrap: 'break-word', overflowY: 'auto' }}>
                                                <Row>
                                                    {fileListView()}
                                                    <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)} >
                                                        <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Row>
                                            </Col>
                                        </Row> : null
                                    }
                                    {/* <Row style={{ marginTop: '10px' }} gutter={16}>
                                <Col span={3} lg={3} md={6} xs={9}>
                                    <span className={'label-requester'}>Resolution : </span>
                                </Col>
                                <Col span={20} lg={20} md={15} xs={14} id='Resolution' style={{ textAlign: 'justify', textJustify: 'auto', overflowWrap: 'break-word' }}>
                                    {isReadMoreResolution
                                        ? <div>{ReactHtmlParser(commentText)}</div>
                                        : <div style={CutTextToReadMore(5)}>
                                            {ReactHtmlParser(commentText)}
                                        </div>}
                                    {countLines('Resolution') >= 5 || commentText?.length >= 700 ? !isReadMoreResolution ? <span className='feck-link' onClick={ToggleIsReadMoreResolution}>Read more</span> : <span className='feck-link' onClick={ToggleIsReadMoreResolution}>Read less</span> : null}
                                </Col>
                            </Row> */}

                                </Card>
                                <Card size={'small'} style={{ boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                                    <Button shape="round" className={`workLogButton${showWorkLog === true ? '-focus' : ''}`} onClick={handleShowWorkLog}>
                                        WorkLog
                                    </Button>

                                    <Button shape="round" className={`workLogButton${showSla === true ? '-focus' : ''}`} onClick={handleShowSLA}>
                                        SLA Status
                                    </Button>

                                    <Button shape="round" className={`workLogButton${showTicketRelated === true ? '-focus' : ''}`} onClick={handleShowTicket} disabled={!decoded.service.serviceId.includes(1)}>
                                        Ticket Related
                                    </Button>

                                    {decoded.service.serviceId.includes(2) ? <Button shape="round" className={`workLogButton${showAssetRelated === true ? '-focus' : ''}`} onClick={() => handleshowAsset()} disabled={!decoded.service.serviceId.includes(2)}>
                                        Asset Related
                                    </Button> : null}

                                    {decoded.service.serviceId.includes(4) && ticketType === 'Request' ? <Button shape="round" className={`workLogButton${showWorkflowRelated === true ? '-focus' : ''}`} onClick={handleshowWorkFlow} disabled={!decoded.service.serviceId.includes(4)}>
                                        Workflow Related
                                    </Button> : null}

                                    {decoded.service.serviceId.includes(5) ? <Button shape="round" className={`workLogButton${showKnowledgeRelated === true ? '-focus' : ''}`} onClick={handleShowKnowledge} disabled={!decoded.service.serviceId.includes(5)}>
                                        Knowledge Related
                                    </Button> : null}

                                    {decoded.service.serviceId.includes(8) ? <Button shape="round" className={`workLogButton${showProblem === true ? '-focus' : ''}`} onClick={handleShowProblems} disabled={!decoded.service.serviceId.includes(8)}>
                                        Problem Related
                                    </Button> : null}

                                    {showWorkLog ? (<div style={{ height: '200px', overflowY: 'scroll', marginTop: '20px' }}>{displayWorkLog()}</div>) : null}
                                    {showSla
                                        ? (<div>
                                            <Spin spinning={isLoadingDataRelated}>
                                                <br />
                                                <SpinningFetcher fetchingState={slaCalculationsFetchingState}>
                                                    <SlaDisplay incidentId={id} incidentStatus={currentStatus?.status!!} pendingUntil={pendingUntil} />
                                                </SpinningFetcher>
                                            </Spin>
                                        </div>)
                                        : null
                                    }
                                    {showTicketRelated ? (<div style={{ border: '1px solid #BDBDBD', margin: '20px', padding: '20px' }}>{displayTicketRelated()}</div>) : null}
                                    {showAssetRelated ? (<div style={{ border: '1px solid #BDBDBD', margin: '20px', padding: '20px' }}>{displayAssetRelated()}</div>) : null}
                                    {showWorkflowRelated ? (<div style={{ border: '1px solid #BDBDBD', margin: '20px', padding: '20px' }}><DisplayRelateWorkflow setIsModalRelatedVisible={setIsModalRelatedVisible} incident={props.incident!!} dataWoRelateIncident={dataWoRalateIncident} isLoadingDataRelated={isLoadingDataRelated} /></div>) : null}
                                    {showKnowledgeRelated ? (<div style={{ border: '1px solid #BDBDBD', margin: '20px', padding: '20px' }}>{displayKnowledgeRelated()}</div>) : null}
                                    {showProblem ? (<div style={{ border: '1px solid #BDBDBD', margin: '20px', padding: '20px' }}>{displayProblemRelate()}</div>) : null}
                                </Card>
                                {props.incident?.resolution && <CommentSection incidentId={id} isShowResolution={true} />}
                                <CommentForm incidentId={id}
                                    requesterEmail={email}
                                    ticketNumber={ticketNumber}
                                    requester={requester}
                                    topic={topic}
                                    description={description}
                                    createdDatetime={createdDatetime}
                                    isDisableStatus={disabledSaveButton}
                                    isSmall={isSmall}
                                    isDisableAddResolution={isDisableAddResolution}
                                />
                                <CommentSection incidentId={id} isShowResolution={false} />

                                {/* Open Editor */}
                                {/* Reply */}
                            </Col>
                            {/* Right Box */}
                            {!isNonDesktop
                                ? <Col lg={6} md={24} sm={24} xs={24}>
                                    <Card >
                                        <h3 style={{ color: '#323276', fontWeight: 'bold' }}>Requester</h3>
                                        <Row>
                                            <Col offset={1}>
                                                <label><b>Requester :</b></label>
                                                <label style={{ marginLeft: '10PX' }}>{requester}</label>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '10px' }}>
                                            <Col offset={1}>
                                                <label><b>Email :</b></label>
                                                <label style={{ marginLeft: '10PX' }}>{email}</label>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '10px' }}>
                                            <Col offset={1}>
                                                <label><b>Phone Number :</b></label>
                                                <label style={{ marginLeft: '10PX' }}>{phone}</label>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '10px' }}>
                                            <Col offset={1}>
                                                <label><b>Department :</b></label>
                                                <label style={{ marginLeft: '10px' }}>{department}</label>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '10px' }}>
                                            <Col offset={1}>
                                                <label><b>Company :</b></label>
                                                <label style={{ marginLeft: '10PX' }}>{company}</label>
                                            </Col>
                                        </Row>
                                        {/* <Row style={{ marginTop: '10px' }}>
                                <Col offset={1}>
                                    <label style={{ color: '#448AFF' }}>Resent Ticket</label>
                                </Col>
                            </Row> */}
                                        <div style={{ border: '1px solid #E0E0E0', marginTop: '30px', marginBottom: '30px' }} />
                                        <Row>
                                            <IncidentStatusUpdater currentStatus={tempStatus} changeOldStatus={changeOldStatus} oldStatus={oldStatus?.status} myCustomFields={props.incident?.customField} getFetchSuccess={getFetchSuccess} />
                                        </Row>
                                    </Card>
                                </Col>
                                : <Drawer
                                    title="Requester"
                                    placement="right"
                                    closable={false}
                                    onClose={onFilterClose}
                                    visible={isFilterOpen}
                                    width={window.innerWidth >= 768 ? '40%' : '80%'}
                                >
                                    <Col span={6} lg={6} md={24} sm={24} xs={24}>
                                        <h3 style={{ color: '#323276', fontWeight: 'bold' }}>Requester</h3>
                                        <Row>
                                            <Col offset={1}>
                                                <label><b>Requester :</b></label>
                                                <label style={{ marginLeft: '10PX' }}>{requester}</label>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '10px' }}>
                                            <Col offset={1} span={5}>
                                                <label><b>Email :</b></label>
                                            </Col>
                                            <Col span={18}>
                                                <label >{email}</label>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '10px' }}>
                                            <Col offset={1}>
                                                <label><b>Phone Number :</b></label>
                                                <label style={{ marginLeft: '10PX' }}>{phone}</label>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '10px' }}>
                                            <Col offset={1}>
                                                <label><b>Company :</b></label>
                                                <label style={{ marginLeft: '10PX' }}>{company}</label>
                                            </Col>
                                        </Row>
                                        {/* <Row style={{ marginTop: '10px' }}>
                            <Col offset={1}>
                                <label style={{ color: '#448AFF' }}>Resent Ticket</label>
                            </Col>
                        </Row> */}
                                        <div style={{ border: '1px solid #E0E0E0', marginTop: '30px', marginBottom: '30px' }} />
                                    </Col>
                                    <Row>
                                        <Col span={24} xs={24} sm={24} md={24} lg={24}>
                                            <IncidentStatusUpdater currentStatus={tempStatus} changeOldStatus={changeOldStatus} oldStatus={oldStatus?.status} getFetchSuccess={getFetchSuccess} />
                                            <Button onClick={onFilterClose} style={{ float: 'right', marginRight: '10px' }}>Cancel</Button>
                                        </Col>
                                    </Row>
                                </Drawer>
                            }
                        </Row>
                    </Spin>
                </Content>
                <DefaultFooter />
                <Modal title="Rate Your Experirnce" visible={isSatOpen} width={500} onCancel={() => setIsSatOpen(false)} footer={false}>
                    <CustomerSat incident={props.incident!!} setModalClose={() => setIsSatOpen(false)} />
                </Modal>
                <Modal
                    title={<b>{tabpaneName === 'Workflow' ? tabpaneName : tabpaneName + ' List'}</b>}
                    visible={isModalRelatedVisible}
                    onCancel={() => { closeRelateModal() }}
                    closable={true}
                    footer={null}
                    width={tabpaneName === 'Workflow' ? '70%' : '90%'}
                >
                    <SelectRelateDataTable
                        modalVisisle={isModalRelatedVisible}
                        fetchAssetRelateddata={fetchAssetRelated}
                        fetchTicketRelateddata={fetchTicketRelated}
                        isFromIncidentView={true}
                        ticketRelatedId={props.incident?.id}
                        childTicket={childTicket}
                        parentTicket={parentTicket}
                        setModalvisible={setIsModalRelatedVisible}
                        tabpaneName={tabpaneName}
                        fetchKnowledgeRelateddata={fetchKnowledgeRelated}
                        fetchDataWoWithIncident={fetchDataWoWithIncident}
                        isCancelModal={isCancelModal}
                        setIsCancelModal={setIsCancelModal}
                    />
                </Modal>
                <Modal
                    title={<b>Resolution</b>}
                    visible={isVisibleResolvedModal}
                    footer={null}
                    onCancel={handleCloseResolvedModal}
                    width='70%'
                    maskClosable={false}
                >
                    <ResolvedTicketModal form={props.form} incidentId={id} ticketType={ticketType} setIsvisibleResolvedModal={setIsvisibleResolvedModal} isvisibleResolvedModal={isVisibleResolvedModal} setCurrentStatus={setCurrentStatus} setisSatOpen={setIsSatOpen} />
                </Modal>
            </Layout>
        </>
    )
}

const DescriptionTicketIncident = Form.create({ name: 'register' })(IncidentDetailsView)

export default connect(mapStateToProps,
    {
        getAllStatus,
        getAllWorkLogByIncidentId,
        updateIncident,
        getAllSlaCalculationByTicketIds: ForIncident.getAllSlaCalculationByTicketIds,
        getAllPriority,
        addSatisfaction,
        getAllGeneralIncidentSetting
    })(DescriptionTicketIncident)
