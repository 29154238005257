/* eslint-disable no-useless-escape */
import React from 'react'
import { FormComponentProps } from 'antd/lib/form'
import { locationHierarchyOption, siteHierarchyOption } from '../model'
import { Col, Row, Form, Select, Input, Button } from 'antd'

interface Params {
    dataSite: siteHierarchyOption[]
    dataLocations: locationHierarchyOption[]
    isDisable: boolean
    setIsDisable: Function
    changeSite: Function
    onCancel: Function
}

type Props = Params & FormComponentProps
const { Option } = Select
const RenderLocationTab: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, getFieldValue, resetFields } = props.form

    const validateLocation = (_: any, value: any, callback) => {
        const pattern = /^[A-Za-z0-9ก-๙]{1}/
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        // eslint-disable-next-line no-useless-escape
        if (value) {
            if (pattern.test(value.replace(/ +/g, '').trim())) {
                const dataSite = props.dataSite.find(data => data.siteName === getFieldValue('siteLocation'))
                const isDuplicate = dataSite?.locations.some((it => it.locationName.toLowerCase().trim() === value.toLowerCase().trim()))
                if (isDuplicate) {
                    callback('Location Name already exists. It must be unique. Please try another one.')
                } else {
                    callback()
                }
            } else {
                if (format.test(value.replace(/ +/g, '').trim())) {
                    callback(`Field Label must not contain these characters: ${format}`)
                }
                callback('Default value is not valid.')
            }
        } else {
            callback()
        }
    }

    const onCancel = () => {
        props.onCancel()
    }

    const handleChangeSite = () => {
        resetFields(['location'])
    }

    return (
        <Row gutter={[10, 10]}>
            <Col span={9} md={9} sm={24}>
                <Form.Item label={'Site Name'}>
                    {getFieldDecorator('siteLocation',
                        {
                            rules: [{ required: true, message: 'Site Name is required.' }]
                        })(
                        <Select placeholder="Site Name" onSelect={() => props.setIsDisable(false)} onChange={handleChangeSite} allowClear>
                            {props.dataSite.map((it, index) => {
                                return (<Option value={it.siteName} key={index}>{it.siteName}</Option>)
                            })}
                        </Select>
                    )}
                </Form.Item>
            </Col>
            <Col span={9} md={9}>
                <Form.Item label={'Location Name'}>
                    {getFieldDecorator('location',
                        {
                            rules: [
                                { required: true, message: 'Location Name is required.' },
                                { validator: validateLocation }
                            ]
                        }
                    )(
                        <Input placeholder={'Location Name'} disabled={props.isDisable} allowClear />
                    )}
                </Form.Item>
            </Col>
            <Col span={3} lg={3} md={3} sm={24} xs={24}>
                <Form.Item style={{ marginTop: 40 }}>
                    <Button type="default" style={{ width: '-webkit-fill-available' }} htmlType="reset" onClick={onCancel}>Cancel</Button>
                </Form.Item>
            </Col>
            <Col span={3} lg={3} md={3} sm={24} xs={24}>
                <Form.Item style={{ marginTop: 40 }}>
                    <Button className="addCriteriaButton" style={{ width: '-webkit-fill-available' }}
                        htmlType="submit">Submit</Button>
                </Form.Item>
            </Col>
        </Row>
    )
}

export default RenderLocationTab
