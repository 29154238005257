import { Button, Card, Col, Form, Icon, Input, message, Modal, Row, Spin, Upload } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { UploadFile } from 'antd/lib/upload/interface'
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'
import { connect } from 'react-redux'
import { DownloadAnyFile, tokenDecode } from '../../common-misc'
import KnowledgeFormUpload from '../../knowledge-management/knowledge/uploads/KnowledgeFormUpload'
import { StoreState } from '../../store'
import { Incident, TicketStatus } from './model'
import { updateIncident } from '../incident/service'
import { addComment, CommentType, Comment } from '../comment'
import FSS from '../../file-server-storage'
import { UploadLink } from '../../knowledge-management/knowledge/uploads'
import { getTicketStatus } from '../status'
import exportIcon from '../../common-file/icon-file'

const mapStateToProps = (state: StoreState) => {
    return {
        incident: state.incidentState.incident,
        comments: (state.comments || []),
        statuses: state.statuses,
        generalIncidentSetting: state.generalIncidentSetting
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    addComment: (comment: Comment) => Promise<number>
    updateIncident: (incident: Incident) => Promise<number>
}

interface Params {
    form: any
    incidentId: number
    ticketType: string
    setIsvisibleResolvedModal: (open: boolean) => void
    isvisibleResolvedModal: boolean
    setCurrentStatus: (status: TicketStatus) => void
    setisSatOpen: (open: boolean) => void
}

type Props = StateProps & DispatchProps & Params & FormComponentProps

const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link'],
        ['clean']
    ]
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet', 'indent',
    'link'
]
const idTenantFreshService = process.env.REACT_APP_ID_TENANT_FRESH_SERVICE
const fileSizeMax = Number(process.env.REACT_APP_FILE_SIZE_ALL_UPLOAD)
const ResolvedTicketModal = (props: Props) => {
    const { getFieldDecorator } = props.form
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewVisible, setPreviewVisible] = useState(false)
    const [text, setText] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [previewImage, setPreviewImage] = useState<string>()
    const [checkUploadFile, setCheckUploadFile] = useState<boolean>(false)
    const splitIdTenantFreshService = idTenantFreshService.split(',')
    const isTanentFreshService = splitIdTenantFreshService.includes(tokenDecode()?.tenantID.toString())

    useEffect(() => {
        if (props.isvisibleResolvedModal) {
            setFileList([])
            setErrorMessage('')
            const elements = document.getElementsByClassName('ql-editor');
            const lastElement = elements.item(elements.length - 1) as HTMLElement
            if (lastElement) {
                lastElement.style.border = '0px solid red'
            }
        } else {
            setTimeout(() => setText(''), 100)
        }
    }, [props.isvisibleResolvedModal])

    const handleEditorChange = (content: string) => {
        const elements = document.getElementsByClassName('ql-editor');
        const lastElement = elements.item(elements.length - 1) as HTMLElement
        const CheckValue = (content).replace(/<[^>]*>/g, '')
        if (content) {
            setText(content)
            if (CheckValue === '' || CheckValue.replace(/ +/g, '').trim() === '') {
                lastElement.style.border = '1px solid red'
                setErrorMessage('Required')
            } else {
                lastElement.style.border = '0px solid red'
                setErrorMessage('')
            }
        }
    }

    const handleSubmit = () => {
        if (checkUploadFile) {
            // message.error('The maximum allowed file size is 40MB.')
            return
        }
        const elements = document.getElementsByClassName('ql-editor');
        const lastElement = elements.item(elements.length - 1) as HTMLElement
        const CheckValue = (text).replace(/<[^>]*>/g, '')
        if (text) {
            setText(text)
            if (CheckValue === '' || CheckValue.replace(/ +/g, '').trim() === '') {
                lastElement.style.border = '1px solid red'
                setErrorMessage('Required')
            } else {
                Modal.confirm({
                    title: 'Resolved Ticket?',
                    content: 'You want to change your status from Inprogress to Resolved?',
                    onOk: () => {
                        AddDataToBackend(fileList)
                    },
                    onCancel: () => { }
                })
            }
        } else {
            lastElement.style.border = '1px solid red'
            setErrorMessage('Required')
        }
    }

    const AddDataToBackend = (fileList: UploadFile[]) => {
        if (fileList.length > 25) {
            message.error('Upload File Max 25')
        } else {
            const comment: Comment = {
                incidentId: Number(props.incidentId),
                text: text,
                uploadLink: fileList,
                type: CommentType.AddNote,
                from: props.form.getFieldValue('From'),
                to: props.form.getFieldValue('To') || props.form.getFieldValue('NotifyAgents'),
                isResolution: true,
                visibleToCustomer: false,
            }

            // @ts-ignore
            // const anyFileList: any[] = props.incident?.uploadLink?.length === 0 ? fileList : props.incident?.uploadLink?.concat(fileList)
            const incData: any = {
                id: Number(props.incidentId),
                resolution: text,
                satisfactionDone: 0,
                ticketStatus: getTicketStatus(props.statuses, props.ticketType, 'Resolved'),
                customField: props.incident?.customField
            }
            setIsLoading(true)
            props.updateIncident(incData).then(() => {
                props.setIsvisibleResolvedModal(false)
                props.setCurrentStatus(getTicketStatus(props.statuses, props.ticketType, 'Resolved'))
                setTimeout(() => {
                    const findDataIsCustomerSatisfaction = props.incident?.incidentSetting?.find((it) => {
                        return it.incGeneralSetting?.settingType === 'customerSatisfaction' && it.incGeneralSetting?.value === '1';
                    })
                    if (findDataIsCustomerSatisfaction) {
                        props.setisSatOpen(true)
                    }
                }, 500)
                message.success('The data has been updated successfully.')
            }).catch((err) => {
                if (isTanentFreshService) {
                    // กรณี FreshService error ให้แสดงเป็น modal
                    if (isTanentFreshService) {
                        ModalFreshService(err)
                    } else {
                        message.error(`Failed saving changes. ${err}`)
                    }
                } else {
                    message.error(`Failed updating incident. ${err}`)
                }
            }).finally(() => {
                clearAll()
                setIsLoading(false)
            })

            uploadImageInTextArea(text, (newText) => {
                comment.text = newText
                if (fileList.length > 0 && fileList.length <= 25) {
                    FSS.putFile(fileList, 'incidentComment', (uploadLink: UploadLink[]) => {
                        if (comment.uploadLink) {
                            comment.uploadLink = uploadLink.map((it) => {
                                return {
                                    name: it.name,
                                    url: it.url,
                                    type: it.type
                                }
                            })
                        } else {
                            comment.uploadLink = uploadLink
                        }
                        console.log(comment)
                        saveComment(comment)
                    })
                } else {
                    console.log(comment);
                    saveComment(comment)
                }
            })
        }
    }

    const uploadImageInTextArea = (value: string, callBack: Function) => {
        if (value.search('<img')) {
            const MyFileList: UploadFile[] = []
            const result: string[] = value.split('<img src="data:')
            const newValue = [result[0]]
            result.forEach((it, index) => {
                if (it.startsWith('image')) {
                    const fileType = it.split(';')[0]
                    const fileExtention = fileType.split('/')[1] || ''
                    const deleteTagHtml = 'data:' + it.split('">')[0]
                    const ts = `${Math.round((new Date()).getTime() / 1000)}-${index}`
                    const ConvertUploadLink: UploadFile = {
                        uid: `${props.incidentId}-${ts}`,
                        name: `${props.incidentId}-${ts}.${fileExtention}`,
                        type: fileType,
                        url: deleteTagHtml,
                        size: deleteTagHtml.length
                    }
                    MyFileList.push(ConvertUploadLink)
                }
            })

            if (MyFileList.length === 0) callBack(value)
            FSS.putFile(MyFileList, 'incidentCommentEditor', (uploadLink: UploadLink[]) => {
                result.forEach((it, index) => {
                    if (index !== 0) {
                        let replaceImageValue = it.split('">')
                        replaceImageValue[0] = `<img src="${uploadLink[index - 1].url}`
                        replaceImageValue = replaceImageValue.map((it, index) => {
                            if (it.startsWith('<img src="')) {
                                return it + '">'
                            }
                            return it
                        })
                        newValue.push(...replaceImageValue)
                    }
                })
                const joinV = newValue.join('')
                setText(joinV)
                callBack(joinV)
            })
        }
    }

    const saveComment = async (comment) => {
        const res = await props.addComment(comment)
        if (res === 201) {
            setIsLoading(false)
            props.form.setFieldsValue({
                from: undefined,
                to: undefined
            })
            clearAll()
        } else {
            message.error('something is wrong')
        }
    }

    const clearAll = () => {
        setFileList([])
        setErrorMessage('')
        const elements = document.getElementsByClassName('ql-editor');
        const lastElement = elements.item(elements.length - 1) as HTMLElement
        if (lastElement) {
            lastElement.style.border = '0px solid red'
        }
        props.form.setFieldsValue({
            NotifyAgents: undefined
        })
    }

    const ModalFreshService = (err) => {
        if (err?.response?.data.status === 403) {
            const match = err?.response?.data?.message.match(/\{.*\}/) // หาส่วน JSON ในข้อความ
            if (match) {
                const parsedData = JSON.parse(match[0])
                Modal.warning({
                    title: <b style={{ color: '#323276' }}>{parsedData.code || 'Access_denied'}</b>,
                    content: <p style={{ color: '#323276' }}>{parsedData.message || 'You are not authorized to perform this action.'}</p>
                })
            }
        } else if (err?.response?.data.status === 400) {
            const match = err?.response?.data?.message.match(/\{.*\}/) // หาส่วน JSON ในข้อความ
            if (match) {
                const parsedData = JSON.parse(match[0])
                Modal.warning({
                    title: <b style={{ color: '#323276' }}>{parsedData?.description || 'Validation failed'}</b>,
                    content: <p style={{ color: '#323276' }}>{
                        parsedData?.errors.map((err: any, index: number) => {
                            return <p key={index}>
                                <b>{err.field}:</b> {err.message}
                            </p>
                        })
                    }</p>
                })
            }
        } else {
            message.error(`Failed saving changes. ${err}`)
        }
    }

    const validateUpload = (_rule: any, value: any, callback) => {
            if (value) {
                if (value?.fileList?.length > 0) {
                    let AllFileSize = 0
                    value?.fileList?.forEach((data) => {
                        AllFileSize += data.size
                    })
                    if (AllFileSize >= fileSizeMax) {
                        callback('Maximum permitted size of 40 MB.')
                        setCheckUploadFile(true)
                    } else {
                        callback()
                        setCheckUploadFile(false)
                    }
                } else {
                    callback()
                    setCheckUploadFile(false)
                }
            } else {
                callback()
                setCheckUploadFile(false)
            }
        }
    
        const handleCheckMAxUploadFile = (): boolean => {
            let isCheck = false
            let AllFileSize = 0
            fileList.forEach((data) => {
                AllFileSize += data.size
            })
            if (AllFileSize >= fileSizeMax) {
                isCheck = true
            }
            return isCheck
        }
    
        const base64MimeType = (encoded: any) => {
            if (!encoded) return
            const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
            if (mime && mime.length) return mime[1]
        }
    
        const uploadProps = {
                multiple: true,
                onRemove: (file: any) => {
                    setFileList(state => {
                        const index = state.indexOf(file)
                        const newFileList = state.slice()
                        newFileList.splice(index, 1)
                        // deleteObjectInS3(file.uid).catch((err) => message.error(`Failed delete object in S3 data. ${err}`))
                        return newFileList
                    })
                },
                beforeUpload: (file: any) => {
                    const fileTypes = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xlsx', 'csv']
                    const extension = file.name.split('.').pop().toLowerCase()
                    const isSuccess = fileTypes.indexOf(extension) < 0
                    if (isSuccess) {
                        message.error('Please upload only the specified file types : ' + fileTypes.join(','))
                        return false
                    }
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onloadend = function (e: any) {
                        console.log(e)
                        // if (file.size > fileSize) {
                        //     message.error('Maximum permitted size of 5 Mb')
                        //     return false
                        // }
                        if (handleCheckMAxUploadFile()) {
                            // message.error('The maximum allowed file size is 40MB.')
                            return false
                        }
                        let fillOffFileList = fileList
                        fillOffFileList.push({
                            uid: file.uid,
                            name: file.name,
                            status: 'done',
                            url: reader.result?.toString(),
                            type: base64MimeType(reader.result),
                            size: e.total,
                            thumbUrl: exportIcon(base64MimeType(reader.result))
                        })
                        fillOffFileList = fillOffFileList.slice(0)
                        
                        setFileList(fillOffFileList)
                        console.log(fileList)
                    }
        
                    return false
                },
                showUploadList: { showDownloadIcon: false }
            }
    
            const getBase64 = (file: Blob) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => resolve(reader.result)
                    reader.onerror = error => reject(error)
                })
            }
    
            const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob; type: string }) => {
                    if (!file.url && !file.preview) {
                        file.preview = await getBase64(file.originFileObj)
                    }
                    // if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png')) {
                    if (file.type.includes('image')) {
                        setPreviewVisible(true)
                        setPreviewImage(file.url || file.preview)
                    } else {
                        DownloadAnyFile(file)
                    }
                }

    return (
        <Spin spinning={isLoading}>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col lg={3} md={5} xs={10}>
                    <div style={{ marginBottom: 20 }}><b>Notify to :</b> </div>
                </Col>
                <Col span={13} >
                    <Form.Item>
                        {getFieldDecorator('NotifyAgents',
                            {
                                // initialValue: showReply ? props.requesterEmail : undefined,
                                rules: [{ type: 'email' }]
                            })(
                                <Input
                                    style={{ width: '87%', marginLeft: 5 }}
                                    placeholder="Notify to"
                                    disabled={!isTanentFreshService}
                                />
                            )}
                    </Form.Item>
                </Col>
                <Col span={6} />
            </Row>
            <div>
                <Row>
                    <Col span={24} lg={24} md={24} sm={24} xs={24}
                        style={{ marginBottom: '20px', marginTop: '10px' }}>
                            <Form.Item>
                                {getFieldDecorator('Upload',
                                    {
                                        rules: [{ validator: validateUpload }]
                                    })(
                                        <Upload {...uploadProps}
                                            listType="picture"
                                            className={'upload-list-inline'}
                                            fileList={fileList}
                                            onPreview={(e: any) => handlePreview(e)}
                                        >
                                            <Button disabled={handleCheckMAxUploadFile()}>
                                                <Icon type="upload" /> Upload
                                            </Button>
                                            <span style={{ color: '#323276', marginLeft: 5 }}>(File Size &lt; 40MB)</span>
                                        </Upload>
                                )}
                            </Form.Item>
                    </Col>
                    <Modal visible={previewVisible} footer={null}
                        onCancel={() => setPreviewVisible(false)}>
                        <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                </Row>
                <br />

                <Row>
                    <Form.Item>
                        <ReactQuill
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            onChange={(e: any) => handleEditorChange(e)}
                            value={text}
                        />
                    </Form.Item>
                    <span style={{ color: 'red' }}>{errorMessage}</span>
                </Row>
                <br />
                <Row>
                    <Form.Item>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button type='primary' loading={isLoading} onClick={handleSubmit}>
                                {/* {showAddNote ? 'Add note' : 'Send'} */}
                                Save
                            </Button>
                        </Col>
                    </Form.Item>
                </Row>
            </div>
        </Spin>
    )
}



export default connect(
    mapStateToProps,
    { addComment, updateIncident })(ResolvedTicketModal)