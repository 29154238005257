/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Table, Tooltip } from 'antd'
// import MyStep from '../stepAndSignature/MyStep'
import { StoreState } from '../../../store'
import { connect } from 'react-redux'
import { getAllJob, Job, getAllJobwithListWorkflow } from '../../Relate/Job'
// import { Link } from 'react-router-dom'
// import {  } from '../../Relate/Job'
// import { waitingGetSessions } from '../../../guard/route'
interface Params {
    woId: string,
    username: string,
    handleIsChangeStatus: (boolean) => void
    permission?: boolean,
    roleId?: number,
    isCloseAndCancel: boolean
}
const mapStateToProps = (state: StoreState) => {
    return {
        job: state.job
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllJob: (woId: string) => Promise<Job[] | undefined>
}

type Props = DispatchProps & StateProps & Params

const TableJob: React.FC<Props> = (props: Props) => {
    const [data, setData] = useState<Job[] | undefined>([])
    // const [isChangeStatus, setIsChangeStatus] = useState<boolean>(false)
    // const [currentJobNo, setCurrentJobNo] = useState<string>('')
    // const [, setAssignmentGroup] = useState<string>('')
    const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false)

    useEffect(() => {
        fetchData(props.woId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect(() => {
    //     if (data) {
    //         const reduxJobsId = props.job.map(job => job.jobNo)
    //         const currentJobsId = data.map(job => job.jobNo)
    //         if (arrayEquals(reduxJobsId, currentJobsId)) {
    //             setData(props.job)
    //         }
    //         setCurrentJobNo(getCurrentJobNo(data))
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [data])

    // useEffect(() => {
    //     const jobData = {
    //         lastModifiedBy: props.username,
    //         jobStatus: ''
    //     }

    //     const job = data?.find((job) => {
    //         if (job.jobNo === currentJobNo) {
    //             return job
    //         }
    //     })
    //     if (job && job.jobStatus === 'Created') {
    //         if (job.jobTopic === 'Approve') {
    //             jobData.jobStatus = 'Awaiting Approval'
    //             props.handleIsChangeStatus(true)
    //             updateJobStatus(job.id!!, jobData).then(() => {
    //                 fetchJobData()
    //             })
    //         } else {
    //             jobData.jobStatus = 'Open'
    //             props.handleIsChangeStatus(true)
    //             updateJobStatus(job.id!!, jobData).then(() => {
    //                 fetchJobData()
    //             })
    //         }
    //     } else if (job?.jobStatus === 'In Progress' && (data && data[data.length - 1].jobNo === job.jobNo)) {
    //         if (sessionStorage.getItem('isClose') === 'true') {
    //             props.handleIsChangeStatus(true)
    //         }
    //         sessionStorage.removeItem('isClose')
    //     } else {
    //         fetchJobData()
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isChangeStatus, currentJobNo])

    const fetchData = (woId: string) => {
        setIsLoadingTable(true)
        // getAllJobWoList(woId).then((res) => {
        //     console.log('res : ',res)
        //     setData(res)
        // }).finally(() => {
        //     setIsLoadingTable(false)
        // })
        getAllJobwithListWorkflow(woId).then((data) => {
            console.log('res : ', data)
            setData(data)
        }).finally(() => {
            setIsLoadingTable(false)
        })
    }

    // const arrayEquals = (a, b) => {
    //     return Array.isArray(a) &&
    //         Array.isArray(b) &&
    //         a.length === b.length &&
    //         a.every((val, index) => val === b[index])
    // }

    // const fetchJobData = () => {
    //     props.getAllJob(props.woId).then(jobs => {
    //         setData(jobs)
    //     })
    // }

    const columns = [
        {
            title: '',
            dataIndex: '',
            width: '3%'
        },
        {
            title: <span className='bold-titleWo'>Job Number</span>,
            dataIndex: 'jobNo',
            key: 'jobNo',
            render: (record) => {
                // const jobNo = data!!.find(res => res?.jobNo === record)
                return (
                    // <div style={{ margin: 'unset' }}>{<Link to={`/WoFormEdit/${props.woId}`} target="_blank" onClick={() => waitingGetSessions()}></Link>}</div>
                    <div>{record}</div>
                )
            }
            // width: '10%'
        },
        {
            title: <span className='bold-titleWo'>Job Topic</span>,
            dataIndex: 'jobTopic',
            key: 'jobTopic'
            // width: '20%'
        },
        {
            title: <span className='bold-titleWo'>Job Status</span>,
            dataIndex: 'jobStatus',
            key: 'jobStatus'
            // width: '7%'
        },
        {
            title: <span className='bold-titleWo'>Assignment Group</span>,
            dataIndex: 'supportTeam',
            key: 'supportTeam',
            render: (row, data) => (
                <div>
                    <Tooltip title={row}>
                        <span>{row?.length > 80 ? row?.slice(0, 80) + '...' : row}</span>
                    </Tooltip>
                </div>
            )
            // width: '15%'
        },
        {
            title: <span className='bold-titleWo'>Assigned To</span>,
            dataIndex: 'assignee',
            key: 'assignee'
            // width: '15%'
        },
        {
            title: <span className='bold-titleWo'>Last Modified Datetime</span>,
            dataIndex: 'lastModifiedDatetime',
            key: 'lastModifiedDatetime'
            // width: '15%'
        }
    ]

    // const handleChangeStatus = (change: boolean) => {
    //     if (data) {
    //         setIsChangeStatus(change)
    //         setCurrentJobNo(getCurrentJobNo(data))
    //         console.log(currentJobNo)
    //     }
    // }

    // const getAssignmentGroup = (value) => {
    //     setAssignmentGroup(value)
    // }

    // const expandedRowRender = (expandedRow, index) => {
    //     const currentJob: boolean = checkIsCurrentJob(index, data!!, expandedRow)
    //     return <MyStep isDirection={true} isSignature={!(expandedRow.templateNo === 7 || expandedRow.templateNo === 6)} job={expandedRow} isCurrentJob={currentJob}
    //         handleChangeStatus={handleChangeStatus} woId={props.woId || ''} username={props.username} isStatus={isChangeStatus} vSpaceIncidentNo={expandedRow.ticketNo || ''}
    //         getDataFromMyStep={getAssignmentGroup}
    //     />
    // }

    return (
        <>
            <Table
                loading={isLoadingTable}
                dataSource={data || []}
                columns={columns}
                // expandedRowRender={props.permission || props.isCloseAndCancel ? undefined : expandedRowRender}
                pagination={false}
                rowKey='id'
            />
        </>
    )
}

export default connect(mapStateToProps,
    {
        getAllJob
    })(TableJob)
