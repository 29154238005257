/* eslint-disable no-useless-escape */
import React, { useState, useCallback, useRef, useEffect } from 'react'
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    ReactFlowInstance,
    Connection,
    Edge,
    Node,
    useNodesState,
    useEdgesState,
    MiniMap,
    SnapGrid,
    Controls,
    Background
} from 'react-flow-renderer'

import Sidebar from './Sidebar'
import './dnd.css'
import JobNodes from './JobNode'
import WorkOrderNodes from './WorkOrderNode'
import Approvals from './Approval'
import StartNodes from './StartNode'
import { Button, message, Row, Col, Form, Input, Layout, Icon, Modal, Spin } from 'antd'
import { WorkFlow, MyNode, Properties } from '../../Service/Model'
import { addWorkFlow, getAllWorkflowNoRedux, getWorkFlowById, updateWorkFlow } from '../../Service/service'
import { useParams, Redirect } from 'react-router-dom'
import moment from 'moment'
import { connect } from 'react-redux'
import { StoreState } from '../../../../store'
import { FormComponentProps } from 'antd/es/form'

const mapStateToProps = (state: StoreState) => {
    return {}
}

type StateProps = ReturnType<typeof mapStateToProps>
const { Header } = Layout
const { confirm } = Modal
interface DispatchProps {
    addWorkFlow: (workFlow: WorkFlow) => Promise<number>;
    updateWorkFlow: (id: number, workFlow: WorkFlow) => Promise<number>;
}

type Props = StateProps & DispatchProps & FormComponentProps

// let idNode = 0
const getId = () => `dndnode_${moment().format()}`
const nodeTypes = {
    startNode: StartNodes,
    jobNode: JobNodes,
    workOrderNode: WorkOrderNodes,
    approval: Approvals
}

const MyDrawflow = (props: Props) => {
    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>()
    const [isRedirect, setIsRedirect] = useState(false)
    const [workflowData, setWorkflowData] = useState<WorkFlow>()
    const [nodes, setNodes, onNodesChange] = useNodesState([])
    const [edges, setEdges, onEdgesChange] = useEdgesState([])
    const [loading, setLoading] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState(false)
    const [listNames, setListNames] = useState<string[]>([])
    const { id }: any = useParams()
    const onConnect = useCallback((params: Connection | Edge) => {
        setEdges((eds) => addEdge(params, eds))
        console.log(edges)
        console.log(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setEdges])
    const onInit = (rfi: ReactFlowInstance) => setReactFlowInstance(rfi)
    const onNodesDelete = (nodes: Node[]) => console.log('nodes delete', nodes)
    const isView = window.location.pathname.includes('/WorkflowFormView')
    const { getFieldDecorator, setFieldsValue } = props.form

    const initialNodes: Node[] = [{
        id: '1',
        type: 'workOrderNode',
        data: { data: { supportTeam: undefined, assignee: undefined, notify: undefined }, form: props.form },
        position: { x: 0, y: 0 }
    }]

    const snapGrid: SnapGrid = [25, 25]

    const reactFlowWrapper = useRef<any>(null)

    useEffect(() => {
        // setTimeout(() => {
        //     localStorage.clear()
        // }, 1000)
        getAllWorkflowNoRedux().then((res: WorkFlow[]) => {
            const listName = res.map((wf) => { return wf.name.trim().toLowerCase() })
            setListNames(listName)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (id) {
            setLoading(true)
            getWorkFlowById(id).then((res: any) => {
                if (res) {
                    setWorkflowData(res)
                    setFieldsValue({
                        name: res.name,
                        description: res.description
                    })
                    const flow = JSON.parse(res.renderingAttributes || [])
                    if (flow) {
                        for (const it of flow.nodes) {
                            if (JSON.stringify(it.data) !== '{}') {
                                it.data.form = props.form
                            } else {
                                it.data.form = props.form
                            }
                        }
                        // flow.nodes.map((it: Node) => {
                        //     if (JSON.stringify(it.data) !== '{}') {
                        //         it.data.form = props.form
                        //     } else {
                        //         it.data.form = props.form
                        //     }
                        // })
                        setNodes((flow.nodes as Node[]) || [])
                        setEdges((flow.edges as Edge[]) || [])
                    }
                }
            }).finally(() => {
                setLoading(false)
            })
        } else {
            setNodes(initialNodes)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    // Function to create adjacency list from edges
    function createAdjList(edges) {
        const adjList = {}
        edges.forEach(edge => {
            const { source, target } = edge
            if (!adjList[source]) adjList[source] = []
            adjList[source].push(target)
        })
        return adjList
    }

    // Function to detect cycle using DFS
    function hasCycle(adjList) {
        const visited = new Set()
        const stack = new Set()

        function dfs(node) {
            if (stack.has(node)) return true // cycle detected
            if (visited.has(node)) return false

            visited.add(node)
            stack.add(node)

            if (adjList[node]) {
                for (const neighbor of adjList[node]) {
                    if (dfs(neighbor)) return true
                }
            }

            stack.delete(node)
            return false
        }

        for (const node in adjList) {
            if (dfs(node)) return true
        }

        return false
    }

    const onSave = useCallback((e) => {
        if (reactFlowInstance) {
            e.preventDefault()
            let requiredErrorString = 'Please input '
            const flow = reactFlowInstance.toObject()
            const tempNodes: MyNode[] = []
            let check = false
            const adjList = createAdjList(flow.edges)
            // Check for cycles
            const containsCycle = hasCycle(adjList)
            if (containsCycle) {
                message.error('You cannot link to this job node due to an infinite loop.')
                return
            }
            if (flow.nodes.length - flow.edges.length > 1) {
                message.error('Please enter all connected nodes.')
                return
            }
            flow.nodes.forEach((node) => {
                const filterIdsource = flow.edges.map((edge) => {
                    if (edge.target === node.id) {
                        return edge.source
                    } else {
                        return undefined
                    }
                })
                if (node.type === 'workOrderNode') {
                    if (!node?.data?.properties?.supportTeam) {
                        if (!requiredErrorString.includes('Work Order [assignment group]')) {
                            node.position = { x: node.position.x - 1, y: node.position.y }
                            requiredErrorString === 'Please input ' ? requiredErrorString = requiredErrorString + 'Work Order [assignment group]' : requiredErrorString = requiredErrorString + ', Work Order [assignment group]'
                            check = true
                        }
                    }
                    tempNodes.push({
                        id: node.id,
                        type: 'WorkOrder',
                        properties: node?.data?.properties,
                        dependencyIds: filterIdsource.filter((data) => data !== undefined && data !== '1')
                    })
                } else if (node.type === 'approval') {
                    if (!node?.data?.properties?.supportTeam) {
                        if (!requiredErrorString.includes('Approval [approval group]')) {
                            node.position = { x: node.position.x - 1, y: node.position.y }
                            requiredErrorString === 'Please input ' ? requiredErrorString = requiredErrorString + 'Approval [approval group]' : requiredErrorString = requiredErrorString + ', Approval [approval group]'
                            check = true
                        }
                    }
                    tempNodes.push({
                        id: node.id,
                        type: 'Approval',
                        properties: node?.data?.properties,
                        dependencyIds: filterIdsource.filter((data) => data !== undefined && data !== '1')
                    })
                } else if (node.type === 'jobNode') {
                    console.log(node?.data?.properties)
                    if (!node?.data?.properties?.supportTeam) {
                        if (!requiredErrorString.includes('Job [assignment group]')) {
                            node.position = { x: node.position.x - 1, y: node.position.y }
                            requiredErrorString === 'Please input ' ? requiredErrorString = requiredErrorString + 'Job [assignment group]' : requiredErrorString = requiredErrorString + ', Job [assignment group]'
                        }
                        check = true
                    }
                    if (!node?.data?.properties?.topic) {
                        if (!requiredErrorString.includes('Job [topic]')) {
                            node.position = { x: node.position.x - 1, y: node.position.y }
                            requiredErrorString === 'Please input ' ? requiredErrorString = requiredErrorString + 'Job [topic]' : requiredErrorString = requiredErrorString + ', Job [topic]'
                            check = true
                        }
                    }
                    if (node?.data?.properties?.signature === true && !node?.data?.properties?.documentName) {
                        if (!requiredErrorString.includes('Job [documentName]')) {
                            node.position = { x: node.position.x - 1, y: node.position.y }
                            requiredErrorString === 'Please input ' ? requiredErrorString = requiredErrorString + 'Job [documentName]' : requiredErrorString = requiredErrorString + ', Job [documentName]'
                            check = true
                        }
                    }
                    tempNodes.push({
                        id: node.id,
                        type: 'Task',
                        properties: node?.data?.properties,
                        dependencyIds: filterIdsource.filter((data) => data !== undefined && data !== '1')
                    })
                }
            })

            if (check) {
                setNodes(flow.nodes)
                setEdges(flow.edges)
            }

            props.form.validateFields(async (_err: any, values: any) => {
                if (!_err) {
                    const convertData: WorkFlow = {
                        name: values.name?.trim(),
                        description: values.description?.trim(),
                        renderingAttributes: JSON.stringify(flow),
                        nodes: tempNodes,
                        activated: true
                    }

                    if (!convertData.name) {
                        message.warn('Please enter a Name')
                    }
                    if (!check) {
                        setIsLoading(true)
                        if (id) {
                            props.updateWorkFlow(id, convertData).then(() => {
                                message.success('The data has been updated successfully.')
                                setIsRedirect(true)
                            }).finally(() => {
                                setIsLoading(false)
                            })
                        } else {
                            props.addWorkFlow(convertData).then(() => {
                                message.success('The data has been saved successfully.')
                                setIsRedirect(true)
                            }).catch(() => {
                                message.error('Unable to save data. Please check and try again.')
                            }).finally(() => {
                                setIsLoading(false)
                            })
                        }
                    } else {
                        message.warn(requiredErrorString)
                    }
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reactFlowInstance])

    const onRestore = () => {
        leavePage()
    }

    const onDragOver = useCallback((event) => {
        event.preventDefault()
        event.dataTransfer.dropEffect = 'move'
    }, [])

    const onDrop = useCallback(
        (event) => {
            event.preventDefault()

            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
            const type = event.dataTransfer.getData('application/reactflow')

            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return
            }

            if (reactFlowInstance) {
                const position = reactFlowInstance.project({
                    x: event.clientX - reactFlowBounds.left,
                    y: event.clientY - reactFlowBounds.top
                })
                const nodeId = getId()
                const properties: Properties = {
                    assignee: undefined,
                    expense: undefined,
                    notify: undefined,
                    supportTeam: undefined,
                    topic: undefined
                }
                const newNode: Node = {
                    id: nodeId,
                    type,
                    position,
                    data: { properties: properties, form: props.form }
                }
                setNodes((nds) => {
                    return nds.concat(newNode)
                })
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reactFlowInstance]
    )

    const deleteKeyCode = ['Delete', 'Backspace']

    const leavePageModal = () => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you want to close this page ?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                window.close()
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const leavePage = () => {
        confirm({
            title: 'Are you sure?',
            content: ' Do you want to leave this page ?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                setIsRedirect(true)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const handleChangeNodes = (changes) => {
        if (changes[0].id === '1' && changes[0].type === 'remove') {
            return
        }
        onNodesChange(changes)
    }

    const validateName = (_: any, value: any, callback) => {
        if (value) {
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            if (format.test(value.trim().charAt(0))) {
                callback('Field Label can not contain any of the following characters ' + format)
            }
            if (listNames.includes(value.trim().toLowerCase()) && !isLoading) {
                if (id && workflowData) {
                    const filterData = listNames.filter((name) => { return name !== workflowData!!.name.trim().toLowerCase() })
                    if (filterData.includes(value.trim().toLowerCase())) {
                        callback('This entry already exists in the system.')
                    } else {
                        callback()
                    }
                } else {
                    callback('This entry already exists in the system.')
                }
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    return (
        <Spin spinning={loading}>
            <Form onSubmit={onSave}>
                <div className={isView ? '' : 'My-card'}>
                    {isRedirect ? <Redirect to='/WorkflowHome' /> : null}
                    {!isView ? <>
                        <p className='HerderText'>Create New Workflow</p>

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="Workflow Name">
                                    {getFieldDecorator('name',
                                        {
                                            rules: [{
                                                required: true,
                                                whitespace: true,
                                                message: 'Workflow Name is required.'
                                            },
                                            {
                                                validator: validateName
                                            }
                                            ]
                                        })(
                                        <Input type="text" placeholder="Workflow Name" maxLength={255} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Description">
                                    {getFieldDecorator('description',
                                        {
                                            rules: [{
                                                required: false,
                                                whitespace: true,
                                                message: 'Description is required.'
                                            }]
                                        })(
                                        <Input type="text" placeholder="Description" maxLength={255} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <div style={{ textAlign: 'right' }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ float: 'right', marginLeft: 15, marginTop: 5 }} loading={isLoading}>Save</Button>
                                <Button onClick={() => onRestore()}>Cancel</Button>
                            </Form.Item>
                        </div>

                    </> : <>
                        <Header style={{ width: '100%', backgroundColor: '#fff', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                            <Row>
                                <Col span={12}>
                                    <div>
                                        <h3 style={{ color: '#323276' }}>{workflowData?.workflowNumber} : {workflowData?.name}</h3>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div style={{ textAlign: 'end' }}>
                                        <Icon type="close-square" theme="filled" style={{ color: '#FA541C', fontSize: 24 }} onClick={() => leavePageModal()} />
                                    </div>
                                </Col>
                            </Row>
                        </Header>
                    </>}
                    <br />
                    <div className="dndflow" style={{ height: 1000 }}>
                        <ReactFlowProvider>
                            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                                <ReactFlow
                                    nodes={nodes}
                                    edges={edges}
                                    onEdgesChange={onEdgesChange}
                                    onNodesChange={(changes) => handleChangeNodes(changes)}
                                    onConnect={onConnect}
                                    onInit={onInit}
                                    onDrop={onDrop}
                                    onDragOver={onDragOver}
                                    nodeTypes={nodeTypes}
                                    snapToGrid={true}
                                    snapGrid={snapGrid}
                                    onNodesDelete={onNodesDelete}
                                    deleteKeyCode={deleteKeyCode}
                                    fitView
                                >
                                    {/* <Controls setNodes={setNodes} setEdges={setEdges} /> */}
                                    <Background color="#99b3ec" />
                                    <Controls />
                                    <MiniMap />
                                </ReactFlow>
                            </div>
                            {!isView ? <Sidebar /> : null}
                        </ReactFlowProvider>
                    </div>
                </div>
            </Form>
        </Spin>
    )
}

const MyDrawFlowMain = Form.create<Props>({ name: 'MyDrawflow' })(MyDrawflow)
export default connect(mapStateToProps, {
    addWorkFlow, updateWorkFlow
})(MyDrawFlowMain)
