import React from 'react'
import { Icon, Row, Col, Button } from 'antd'
import { Link } from 'react-router-dom'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import { StoreState } from '../../store'
import { connect } from 'react-redux'
import { tokenDecode } from '../../common-misc'
const mapStateToProps = (state: StoreState) => {
    return {
        permission: state.currentUserRole
    }
}

interface Props {
    
}
// const idTenantFreshService = process.env.REACT_APP_ID_TENANT_FRESH_SERVICE
const settingDash: React.FC<Props> = (props: Props) => {
    // const splitIdTenantFreshService = idTenantFreshService.split(',')
    // const isTanentFreshService = splitIdTenantFreshService.includes(tokenDecode()?.tenantID.toString())
    return (
        <div style={{ marginLeft: 30 }}>
            <p className='text-herder-Orenge'>Setting</p>
            <hr style={{ border: '1px solid #E5E5E5' }} />
            <br />
            <div className="container-fluid justify-content-center m-2" >
                <div className="row p-1">
                    <div className="col-12">
                        {/* <Card className="myCardSetting"> */}
                        <div style={{}}>
                            <div className="text-herder">General Parameter</div>
                            <Row gutter={32} style={{ padding: '30px 5px 30px' }}>
                                <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                    <Button style={{ width: '100%', textAlign: 'left', height: 'auto', padding: 12, marginBottom: 5 }} className='btn-setting' disabled={!checkRolesState(RoleType.Admin, 'ManageTicketSetting')}>
                                        <Link to="/GeneralIncidentSettingForm"> <p><Icon type="setting" className='icon-setting' /></p>
                                            <span className={'menu-setting-page text-details'}>General Parameter</span> </Link>
                                    </Button>
                                </Col>
                                <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                    <Button style={{ width: '100%', textAlign: 'left', height: 'auto', padding: 12, marginBottom: 5 }} className='btn-setting' disabled={!checkRolesState(RoleType.Admin, 'ManageTicketSetting')}>
                                        <Link to="/PrioritySetting"><p><Icon type="exclamation-circle" className='icon-setting' /></p>
                                            <span className={'menu-setting-page text-details'}>Priority</span></Link>
                                    </Button>
                                </Col>
                                <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                    <Button style={{ width: '100%', textAlign: 'left', height: 'auto', padding: 12, marginBottom: 5 }} className='btn-setting' disabled={!checkRolesState(RoleType.Admin, 'ManageTicketSetting')}>
                                        <Link to="/CategorySetting" > <p><Icon type="code-sandbox" className='icon-setting' /></p>
                                            <span className={'menu-setting-page text-details'}>Category</span></Link>
                                    </Button>
                                </Col>
                                {/* {isTanentFreshService ? <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                    <Button style={{ width: '100%', textAlign: 'left', height: 'auto', padding: 12, marginBottom: 5 }} className='btn-setting' disabled={!checkRolesState(RoleType.Admin, 'ManageTicketSetting')}>
                                        <Link to="/SyncData" > <p><Icon type="cloud-sync" className='icon-setting' /></p>
                                            <span className={'menu-setting-page text-details'}>Sync Data</span></Link>
                                    </Button>
                                </Col> : null} */}
                            </Row>
                        </div>
                        {/* </Card> */}
                        {/* <Card className="myCardSetting"> */}
                        <div className="text-herder">SLA Settings</div>
                        <Row gutter={32} style={{ padding: '30px 5px 30px' }}>
                            <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                <Button style={{ width: '100%', textAlign: 'left', height: 'auto', padding: 12, marginBottom: 5 }} className='btn-setting' disabled={!checkRolesState(RoleType.Admin, 'ManageSLASetting')}>
                                    <Link to="/GlobalSetting"> <p><Icon type="setting" className='icon-setting' /></p>
                                        <span className={'menu-setting-page text-details'}>General SLA Setting</span> </Link>
                                </Button>
                            </Col>
                            <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                <Button style={{ width: '100%', textAlign: 'left', height: 'auto', padding: 12, marginBottom: 5 }} className='btn-setting' disabled={!checkRolesState(RoleType.Admin, 'ManageSLASetting')}>
                                    <Link to="/SlaPolicySettingList"> <p><Icon type="clock-circle" className='icon-setting' /></p>
                                        <span className={'menu-setting-page text-details'}>SLA Management</span>
                                    </Link>
                                </Button>
                            </Col>
                            <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                <Button style={{ width: '100%', textAlign: 'left', height: 'auto', padding: 12, marginBottom: 5 }} className='btn-setting' disabled={!checkRolesState(RoleType.Admin, 'ManageSLASetting')}>
                                    <Link to="/BusinessHourSettingList"> <p><Icon type="calendar" className='icon-setting' /></p>
                                        <span className={'menu-setting-page text-details'}>Business Hours</span> </Link>
                                </Button>
                            </Col>
                        </Row>
                        {/* </Card> */}
                        {/* <Card className="myCardSetting"> */}
                        <div className="text-herder">Security Settings</div>
                        <Row gutter={32} style={{ padding: '30px 5px 30px' }}>
                            <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                <Button style={{ width: '100%', textAlign: 'left', height: 'auto', padding: 12, marginBottom: 5 }} className='btn-setting' disabled={!checkRolesState(RoleType.Admin, 'ManageRoleAndPermission')}>
                                    <Link to="/RoleAndPermissionsSettingList"> <p><Icon type="key" className='icon-setting' /></p>
                                        <span className={'menu-setting-page text-details'}>Role & Permission</span></Link>
                                </Button>
                            </Col>
                            {/* <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                    <Button type="dashed" style={{ width: '100%', textAlign: 'left' }} disabled={!checkRolesState(RoleType.Security, 'ManagePeopleUserGroup')}>
                                        <Link to="/UserSetting"> <Icon type="user" />
                                            <span className={'menu-setting-page text-details'}>User Management</span> </Link>
                                    </Button>
                                </Col> */}
                            <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                <Button style={{ width: '100%', textAlign: 'left', height: 'auto', padding: 12, marginBottom: 5 }} className='btn-setting' disabled={!checkRolesState(RoleType.Admin, 'ManagePeopleUserGroup')}>
                                    <Link to="/PeopleSetting"> <p><Icon type="user" className='icon-setting' /></p>
                                        <span className={'menu-setting-page text-details'}>User Management</span> </Link>
                                </Button>
                            </Col>
                            <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                <Button style={{ width: '100%', textAlign: 'left', height: 'auto', padding: 12, marginBottom: 5 }} className='btn-setting' disabled={!checkRolesState(RoleType.Admin, 'ManagePeopleUserGroup')}>
                                    <Link to="/SupportTeamSettingList"> <p><Icon type="team" className='icon-setting' /></p>
                                        <span className={'menu-setting-page text-details'}>Group</span> </Link>
                                </Button>
                            </Col>
                        </Row>
                        {/* </Card> */}
                        {/* <Card className="myCardSetting"> */}
                        <div className="text-herder">General Settings</div>
                        <Row gutter={32} style={{ padding: '30px 5px 30px' }}>
                            <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                <Button style={{ width: '100%', textAlign: 'left', height: 'auto', padding: 12, marginBottom: 5 }} className='btn-setting' disabled={!checkRolesState(RoleType.Admin, 'ManageGeneralSetting')}>
                                    <Link to="/ConfigurationFiledSettingList"> <p><Icon type="bars" className='icon-setting' /></p>
                                        <span className={'menu-setting-page text-details'}>Custom Field Setting</span> </Link>
                                </Button>
                            </Col>
                            <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                <Button style={{ width: '100%', textAlign: 'left', height: 'auto', padding: 12, marginBottom: 5 }} className='btn-setting' disabled={!checkRolesState(RoleType.Admin, 'ManageGeneralSetting')}>
                                    <Link to="/CompanySetting"> <p><Icon type="home" className='icon-setting' /></p>
                                        <span className={'menu-setting-page text-details'}>Department & Company Setting</span> </Link>
                                </Button>
                            </Col>
                        </Row>

                        {tokenDecode()?.service.serviceId.includes(2) ? <div>
                            <div className='text-herder'>Asset Setting</div>
                            <Row gutter={32} style={{ padding: '30px 5px 30px' }}>
                                <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                    <Button style={{ width: '100%', textAlign: 'left', height: 'auto', padding: 12, marginBottom: 5 }} className='btn-setting' disabled={!checkRolesState(RoleType.Admin, 'ManageAssetSetting')}>
                                        <Link to="/asset-general"> <p><Icon type="setting" className='icon-setting' /></p>
                                            <span className={'menu-setting-page text-details'}>Asset Setting</span> </Link>
                                    </Button>
                                </Col>
                            </Row>
                        </div> : null}

                        {tokenDecode()?.service.serviceId.includes(8) ? <div>
                            <div className="text-herder">Problem Setting</div>
                            <Row gutter={32} style={{ padding: '30px 5px 30px' }}>
                                <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                    <Button style={{ width: '100%', textAlign: 'left', height: 'auto', padding: 12, marginBottom: 5 }} className='btn-setting' disabled={!checkRolesState(RoleType.Admin, 'ManageProblemSetting')}>
                                        <Link to="/problem-general"> <p><Icon type="setting" className='icon-setting' /></p>
                                            <span className={'menu-setting-page text-details'}>Problem Setting</span> </Link>
                                    </Button>
                                </Col>
                            </Row>
                        </div> : null}
                        {/* </Card> */}
                        {tokenDecode()?.roleLevel === 0
                            ? <div>
                                <div className="text-herder">Tenant Settings</div>
                                <Row gutter={32} style={{ padding: '30px 5px 30px' }}>
                                    <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                        <Button style={{ width: '100%', textAlign: 'left', height: 'auto', padding: 12, marginBottom: 5 }} className='btn-setting' >
                                            <Link to="/TenantProfile"> <p><Icon type="bank" className='icon-setting' /></p>
                                                <span className={'menu-setting-page text-details'}>Tenant Profile</span> </Link>
                                        </Button>
                                    </Col>

                                    {/* <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                        <Button style={{ width: '100%', textAlign: 'left', height: 'auto', padding: 12, marginBottom: 5 }} className='btn-setting'
                                            // disabled={tokenDecode()?.roleLevel === 0}
                                        >
                                            <Link to="/SubscriptionList"> <p><Icon type="dollar" className='icon-setting'/></p>
                                                <span className={'menu-setting-page text-details'}>Subscription</span> </Link>
                                        </Button>
                                    </Col> */}
                                </Row>
                            </div> : null
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default connect(mapStateToProps)(settingDash)
