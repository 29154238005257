import React, {PropsWithChildren, useState} from 'react'
import {Breadcrumb, Button, Card, Icon, message, Upload, Modal, Tabs, Menu, Dropdown, Spin} from 'antd'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {StoreState} from '../../store'
import {Category, exportTempleteCategory, importExcelCategoryFromBackend} from '../../incident-management/category'
import XLSX from 'sheetjs-style'
import {UploadFile} from 'antd/lib/upload/interface'
import CategoryTab from './CategoryTab'
import SubCategoriesTab from './SubCategoriesTab'
import ItemTab from './ItemTab'
import { getExportCategory } from '../../msp-portal'

const mapStateToProps = (state: StoreState) => {
    return {categories: state.categories}
}

type StateProps = ReturnType<typeof mapStateToProps>

// interface DispatchProps {
//     importExcel: (category: Category[]) => Promise<number>;
// }

type Props = StateProps & PropsWithChildren<any>

const CategoryForm = (props: Props) => {
    const {TabPane} = Tabs

    const [, setMyFile] = useState<UploadFile[]>([])
    const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false)
    const [fetchCategory, setFetchCategory] = useState<boolean>(true)
    const [fetchSubCategory, setFetchSubCategory] = useState<boolean>(false)
    const [fetchItems, setFetchItems] = useState<boolean>(false)
    const [isLoadingTemplete, setIsLoadingTemplete] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // useEffect(() => {
    //     getAllCatName()
    // }, [])

    const downloadData = async () => {
        setIsLoadingExport(true)
        getExportCategory().finally(() => {
            setIsLoadingExport(false)
        })
        // exportExcel().then((response) => {
        //     if (response) {
        //         const arrayHerder = ['category', 'subCategory', 'items']
        //         const aoa: string[][] = []
        //         aoa.push(arrayHerder)
        //         const cat = response.category.map(category => {
        //             return [category.name, '', '']
        //         })
        //         const sub = response.subcategory.map(subcategory => {
        //             return [subcategory.category, subcategory.name, '']
        //         })
        //         const items = response.items.map(items => {
        //             return [items.category, items.subCategory, items.name]
        //         })
        //         const CategoryToOBJ = [...cat, ...sub, ...items]
        //         console.log(CategoryToOBJ)

        //         aoa.push(...CategoryToOBJ)
        //         const ws1 = XLSX.utils.aoa_to_sheet(aoa)
        //         const style = {
        //             font: {
        //                 bold: true,
        //                 color: '000000',
        //                 sz: '11'
        //             },
        //             fill: {
        //                 type: 'pattern',
        //                 patternType: 'solid',
        //                 fgColor: { rgb: 'd5d6f0' }
        //             },
        //             border: {
        //                 right: {
        //                     style: 'thin',
        //                     color: '000000'
        //                 },
        //                 left: {
        //                     style: 'thin',
        //                     color: '000000'
        //                 },
        //                 bottom: {
        //                     style: 'medium',
        //                     color: '000000'
        //                 }
        //             }
        //         }
        //         ws1.A1.s = style
        //         ws1.B1.s = style
        //         ws1.C1.s = style
        //         console.log(ws1)

        //         ws1['!cols'] = fixWidthExcel(CategoryToOBJ, ws1)
        //         const wb = XLSX.utils.book_new()
        //         XLSX.utils.book_append_sheet(wb, ws1, 'Category')
        //         XLSX.writeFile(wb, 'Category.xlsx')
        //     }
        // }).finally(() => {
        //     setIsLoadingExport(false)
        // })
    }

    const checkAllnullUndifyEmtString = (value?: string) => {
        let check = false
        console.log(value)
        if (value !== undefined && value !== null && value !== '') {
            check = true
        }
        return check
    }

    const prepCategoryDatas = (data: Category[]) => {
        const prepCategoryData: any[] = []
        const prepSubategoryData: any[] = []
        const prepItemsData: any[] = []
        // const rowStart = 2
        data.forEach((element, rowNumber) => {
            if (checkAllnullUndifyEmtString(element.Category) && checkAllnullUndifyEmtString(element.Subcategory) && checkAllnullUndifyEmtString(element.Items)) {
                const index = prepItemsData.findIndex(object => object.Subcategory === element.Subcategory && object.name === element.Items)
                if (index === -1) {
                    prepItemsData.push({
                        name: element.Items,
                        category: element.Category,
                        subCategory: element.Subcategory,
                        row: rowNumber
                    })
                }
            }
            if (element.Category && element.Subcategory) {
                const index = prepSubategoryData.findIndex(object => object.Category === element.Category && object.name === element.Subcategory)
                if (index === -1) {
                    prepSubategoryData.push({
                        name: element.Subcategory,
                        category: element.Category,
                        row: rowNumber
                    })
                }
            }
            if (element.Category) {
                const index = prepCategoryData.findIndex(object => object.name === element.Category)
                if (index === -1) {
                    prepCategoryData.push({name: element.Category, row: rowNumber})
                }
            }
        })
        console.log({category: prepCategoryData, subcategory: prepSubategoryData, items: prepItemsData})
        return {category: prepCategoryData, subcategory: prepSubategoryData, items: prepItemsData}
    }

    function Warning(Text: string) {
        Modal.warning({
            title: 'Warning',
            content: Text,
            onOk: () => {
                setIsLoading(false)
                console.log(isLoading)
                Modal.destroyAll()
            },
            className: 'modal-not-show-close'
        })
    }

    const uploadProps = {
        multiple: true,
        onRemove: (file: any) => {
            setMyFile(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        onChange(info) {
            console.log(info)
            if (info.file.status !== 'uploading') {
                console.log(info.file.status)
            }
            if (info.file.status === 'done') {
                message.success(`The file ${info.file.name} was uploaded successfully.`)
            } else if (info.file.status === 'error') {
                setMyFile([])
            }
        },
        beforeUpload: (file: any) => {
            console.log(file)

            const isXlsx = file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            if (isXlsx) {
                message.error('You are only allowed to upload xlsx files.')
                setMyFile([])
            } else {
                const reader = new FileReader()
                reader.readAsBinaryString(file)
                reader.onloadend = function (e: any) {
                    const bstr = e.target.result
                    const wb = XLSX.read(bstr, {type: 'binary'})
                    const wsName = wb.SheetNames[0]
                    const ws = wb.Sheets[wsName]
                    const data: any[] = XLSX.utils.sheet_to_json(ws)
                    console.log('data', data)
                    if (!data[0]?.hasOwnProperty('Category') && !data[0]?.hasOwnProperty('Subcategory') && !data[0]?.hasOwnProperty('PM Items')) {
                        Failed('Unable to upload the file due to an incorrect format. Please try again later.')
                        return
                    }
                    Warning('The file is being imported and verified. Please wait...')
                    try {
                        const prepData = prepCategoryDatas(data)
                        console.log(prepData)
                        setIsLoading(true)
                        importExcelCategoryFromBackend(prepData).then(() => {
                            // props.getAllCategoriesWithPagination(1, 10, '0', '', [true, false], 'createdDate', 'desc')
                            // props.getAllCategoriesWithPagination(1, 10, '0', '', [true, false], 'createdDate', 'desc').then(() => {
                            // message.success('You have Successfully import the data.')
                            setMyFile([])
                            setFetchCategory(true)
                            setFetchSubCategory(true)
                            setFetchItems(true)
                            // })
                        }).finally(() => {
                            setIsLoading(false)
                        })

                        // props.importExcel(prepData).then(() => {
                        //     // props.getAllCategoriesWithPagination(1, 10, '0', '', [true, false], 'createdDate', 'desc').then(() => {
                        //     message.success('You have Successfully import the data.')
                        //     setMyFile([])
                        //     setFetchCategory(true)
                        //     setFetchSubCategory(true)
                        //     setFetchItems(true)
                        //     // })
                        // }).catch((err) => {
                        //     if (err?.response?.status === 400) {
                        //         let rowNumber = ''
                        //         const errorText = 'Please enter Category, Subcategory and Items correctly. Excel Row No.'
                        //         const data: [] = decryptBody(err.response.data.data)
                        //         data.forEach((res) => {
                        //             rowNumber = rowNumber === '' ? res : rowNumber + ', ' + res
                        //         })
                        //         rowNumber = 'Excel Row No. ' + rowNumber
                        //         Modal.warning({
                        //             title: <b style={{color: '#323276'}}>Warning</b>,
                        //             content: renderResultImportError(errorText, rowNumber),
                        //             okText: 'OK',
                        //             okType: 'danger',
                        //             onOk: () => {
                        //                 window.location.reload()
                        //             },
                        //             onCancel: () => {
                        //                 window.location.reload()
                        //             }
                        //         })
                        //     } else {
                        //         message.error('Save operation failed ' + err.response?.data?.message)
                        //     }
                        // })
                    } catch (error: any) {
                        message.error('Unable to upload the file due to an incorrect format. Please try again later.')
                    }
                    // if (data[0]?.hasOwnProperty('category') && data[0]?.hasOwnProperty('subCategory') && data[0]?.hasOwnProperty('item')) {

                    // } else {

                    // }
                }
            }
            return isXlsx
        },
        showUploadList: {showDownloadIcon: false}
    }

    // const renderResultImportError = (errorText: string, rowNumber: string) => {
    //     return <div>
    //         <p><b style={{color: '#323276', fontWeight: '600'}}>{errorText}</b><br></br><b
    //             style={{color: '#FA541C', fontWeight: '600'}}>{rowNumber}</b></p>
    //     </div>
    // }

    function Failed(Text: string) {
        Modal.error({
            title: 'Failed',
            content: Text,
            onOk: () => {
                setIsLoading(false)
                Modal.destroyAll()
            },
            className: 'modal-not-show-close'
        })
    }

    const menu = (
        <Menu>
            <Menu.Item>
                <div>
                    <Upload
                        {...uploadProps}
                        listType="text"
                        className="upload-list-inline"
                        showUploadList={false}
                        multiple={false}
                        style={{ width: '100%' }}
                    >
                        <div style={{ width: 160 }}><Icon type="download" /> Import Category</div>
                    </Upload>
                </div>
            </Menu.Item>
            <Menu.Item>
                <Spin spinning={isLoadingTemplete}>
                    <div onClick={() => downloadTempleteCategory()}>
                        <Icon type="profile" /> Template Import Category
                    </div>
                </Spin>
            </Menu.Item>
        </Menu>
    )

    const downloadTempleteCategory = () => {
        setIsLoadingTemplete(true)
        exportTempleteCategory().finally(() => {
            setIsLoadingTemplete(false)
        })
    }

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Category</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{float: 'right', marginTop: '-25px'}}>
                {/* <Upload {...uploadProps} listType="text" className="upload-list-inline" showUploadList={false}
                    multiple={false}>
                    <Button>
                        <Icon type="download"/> Import
                    </Button>
                </Upload> */}
                <Button style={{marginLeft: '8px'}} onClick={() => downloadData()} className="addCriteriaButton"
                    loading={isLoadingExport}>
                    <Icon type="upload"/> Export
                </Button>
                <Dropdown overlay={menu}>
                    <Button style={{marginLeft: '8px'}} type='primary' loading={isLoadingTemplete}>
                        Import <Icon type="down" />
                    </Button>
                </Dropdown>
            </div>
            <br/>
            <Card>
                <h3 className={'main-title'}>Category</h3>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Category" key="1">
                        <CategoryTab fetchCategory={fetchCategory} setFetchCategory={setFetchCategory} setFetchSubCategory={setFetchSubCategory} setFetchItems={setFetchItems}/>
                    </TabPane>
                    <TabPane tab="Subcatagory" key="2">
                        <SubCategoriesTab fetchSubCategory={fetchSubCategory} setFetchSubCategory={setFetchSubCategory}
                            setFetchItems={setFetchItems}/>
                    </TabPane>
                    <TabPane tab="Items" key="3">
                        <ItemTab fetchItems={fetchItems} setFetchItems={setFetchItems}/>
                    </TabPane>
                </Tabs>
            </Card>
        </>
    )
}

// const SlaCategorySettingPage = Form.create({ name: 'CategoryForm' })(CategoryForm)

export default connect(mapStateToProps
)(CategoryForm)
// export default CategoryForm
