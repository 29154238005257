/* eslint-disable no-useless-escape */
import { AutoComplete, Button, Col, Form, Input, Row, Select, Spin, message } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import React, { useEffect, useState } from 'react'
import { PriorityLevel, getAllPriority, resolvePriority } from '../priority'
import { StoreState } from '../../store'
import { connect } from 'react-redux'
import { SearchIncidentForCreateTemplates, createTemplates, getAllGeneralIncidentSetting, getTemplateByid, updateTemplates } from './service'
import { getAllImpact } from '../impact'
import { getAllUrgency } from '../urgency'
import { getAllStatus } from '../status'
import { getAllSupportTeamByPermission } from '../support-team'
import { getAllCategory, getAllItems, getAllSubCategory } from '../category'
import { SupportTeam } from '../incident'
import { UserSupportTeam } from '../../authorization-module/support-team-management/model'
import { ticketNumber } from './model'

const mapStateToProps = (state: StoreState) => {
    return {
        incidents: state.incidentState?.incidents || [],
        impacts: state.impacts,
        urgencies: state.urgencies,
        priority: state.priorities,
        categories: state.categories,
        subCategories: state.subCategories,
        items: state.items,
        supportTeamsWithPermission: state.supportTeamsWithPermission,
        statuses: state.statuses,
        userRolesAndPermissions: state.userRolesAndPermissions,
        generalIncidentSetting: state.generalIncidentSetting
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllCategory: () => Promise<number>
    getAllSubCategory: () => Promise<number>
    getAllItems: () => Promise<number>
    getAllSupportTeamByPermission: () => Promise<number>
    getAllStatus: (status: string | undefined) => Promise<number>
    getAllPriority: () => Promise<number>
    getAllGeneralIncidentSetting: () => Promise<number>
    getAllImpact: () => Promise<number>
    getAllUrgency: () => Promise<number>
}

interface OwnProps {
    dataEdit: any
    setIsVisible: Function
    setSelectData: Function
    fetchData: Function
    isView: boolean
    isVisible: boolean
}

type Props = OwnProps & FormComponentProps & StateProps & DispatchProps
const { Option } = Select
const { TextArea } = Input
let mySupportTeamName: string[] = []
const EditTemplate: React.FC<Props> = (props: Props) => {
    const {getFieldDecorator, resetFields, getFieldValue, setFieldsValue} = props.form
    const [isLoading, setIsLoading] = useState(false)
    const [dataSelectIncident, setDataSelectIncident] = useState<any>()
    const [isLoadingSearch, setIsLoadingSearch] = useState(false)
    const [, setAssignee] = useState<UserSupportTeam[]>([])
    const [myPriority, setMyPriority] = useState<{ id?: number, value?: string }>()
    const [dataSearch, setDatasearch] = useState<any[]>([])

    useEffect(() => {
        // fetchDataToImpact().catch((err) => message.error(`Unable to load the initial impact data. Please try again later. ${err}`))
        // fetchDataToUrgency().catch((err) => message.error(`Unable to load the initial urgency data. Please try again later. ${err}`))
        // fetchDataToStatus(undefined).catch((err) => message.error(`Failed loading initial status data. ${err}`))
        // fetchPriority().catch((err) => message.error(`Failed loading initial priority data. ${err}`))
        // fetchTicketTypeAndChannel()
        // props.getAllSupportTeamByPermission().catch((err) => message.error(`Unable to load the support team data. Please try again later. ${err}`))
        const promiseAll: any[] = []
        promiseAll.push(fetchDataToImpact())
        promiseAll.push(fetchDataToUrgency())
        promiseAll.push(fetchDataToStatus(undefined))
        promiseAll.push(fetchPriority())
        promiseAll.push(props.getAllGeneralIncidentSetting())
        promiseAll.push(props.getAllSupportTeamByPermission())
        Promise.all(promiseAll).catch((err) => {
            message.error(`Unable to retrieve the data. Please try again later. ${err}`)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.dataEdit) {
            setIsLoadingSearch(true)
            getTemplateByid(props.dataEdit.id).then((res) => {
                setDataSelectIncident(res)
                setMyPriority({
                    id: res.priority.priority.id,
                    value: res.priority.priority.name
                })
                setFieldsValue({
                    templateName: res.templateName,
                    Impact: res.priority.impact.id,
                    Urgency: res.priority.urgency.id,
                    Priority: res.priority.priority.name,
                    TicketType: res.ticketType.id,
                    topicName: res.topic,
                    description: res.description,
                    Assignment_group: res.assignmentGroup.value,
                    Assigned_to: res.assignedTo.value
                })
            }).finally(() => {
                setIsLoadingSearch(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataEdit])

    useEffect(() => {
        mySupportTeamName = props.supportTeamsWithPermission.map((supportTeam: SupportTeam) => {
            return supportTeam.name
        })
        console.log(mySupportTeamName)
        if (dataSelectIncident) {
            const targetSupportTeams = props.supportTeamsWithPermission.filter((supportTeam: SupportTeam) => {
                return supportTeam.name === dataSelectIncident?.assignmentGroup?.value
            })
            if (targetSupportTeams.length !== 0) {
                targetSupportTeams.forEach((res) => {
                    setAssignee(res.assignees)
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.supportTeamsWithPermission, dataSelectIncident])

    useEffect(() => {
        if (!props.isVisible) {
            resetFields()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isVisible])

    const fetchDataToStatus = (status: string | undefined) => {
        return props.getAllStatus(status)
    }

    const fetchDataToImpact = () => {
        return props.getAllImpact()
    }

    const fetchDataToUrgency = () => {
        return props.getAllUrgency()
    }

    const fetchPriority = () => {
        return props.getAllPriority()
    }

    // const fetchTicketTypeAndChannel = () => {
    //     props.getAllGeneralIncidentSetting().catch((err) => message.error(`Unable to load the initial general ticket settings data. Please try again later. ${err}`))
    // }

    const leavePageModal = () => {
        resetFields()
        setDataSelectIncident(undefined)
        // props.setIsVisible(false)
        // props.setSelectData(undefined)
    }

    const submitFrom = (e) => {
        e.preventDefault()
        props.form.validateFieldsAndScroll((_error, values) => {
            if (!_error) {
                setIsLoading(true)
                const findteam = props.supportTeamsWithPermission.find((teams) => { return teams.name === values.Assignment_group })
                if (findteam) {
                    var findUser = findteam.assignees.find((data) => {
                        return data.id?.toString() === values.Assigned_to?.toString() || data.users.fullName === values.Assigned_to?.toString()
                    })
                    console.log(findUser)
                }
                const convertData = {
                    templateName: values.templateName?.replace(/ +/g, ' ')?.trim(),
                    ticketNumber: dataSelectIncident?.ticketNumber,
                    priorityId: {
                        id: myPriority?.id
                    },
                    ticketTypeId: {
                        id: values.TicketType
                    },
                    topic: values.topicName?.replace(/ +/g, ' ')?.trim(),
                    description: values.description?.replace(/ +/g, ' ')?.trim(),
                    // assignmentGroupId: findteam?.id ? {
                    //     id: findteam?.id
                    // } : null,
                    // assignedToId: findUser?.users.id ? {
                    //     id: findUser?.users.id
                    // } : null,
                    activate: true
                }
                console.log(convertData)
                if (props.dataEdit) {
                    updateTemplates(props.dataEdit.id, convertData).then(() => {
                        message.success('The data has been updated successfully.')
                        props.fetchData()
                        resetFields()
                        setIsLoading(false)
                        props.setSelectData(undefined)
                        setDataSelectIncident(undefined)
                        props.setIsVisible(false)
                    }).catch(() => {
                        // resetFields(['templateName'])
                        setIsLoading(false)
                    })
                } else {
                    createTemplates(convertData).then((res) => {
                        message.success('The data has been saved successfully.')
                        props.fetchData()
                        setDataSelectIncident(undefined)
                        props.setIsVisible(false)
                        setIsLoading(false)
                        props.setSelectData(undefined)
                        resetFields()
                    }).catch(() => {
                        // resetFields(['templateName'])
                        setIsLoading(false)
                    })
                }
            }
        })
    }

    const searchData = () => {
        const search = getFieldValue('search')
        if (search.length >= 3) {
            // setIsLoadingSearch(true)
            SearchIncidentForCreateTemplates(search).then((res) => {
                setDatasearch(res)
            })
        }
    }

    const onSelect = (e) => {
        const res = dataSearch.find((it) => { return it.ticketNumber === e })
        if (res) {
            setIsLoadingSearch(true)
            setDataSelectIncident(res)
            setMyPriority({
                id: res.priority.priority.id,
                value: res.priority.priority.name
            })
            setFieldsValue({
                Impact: res.priority.impact.id,
                Urgency: res.priority.urgency.id,
                Priority: res.priority.priority.name,
                TicketType: res.ticketType.id,
                topicName: res.topic,
                description: res.description,
                Assignment_group: res.assignmentGroup.value,
                Assigned_to: res.assignedTo.value
            })
            setIsLoadingSearch(false)
        }
    }

    // const checkAssignee = (e: SelectValue) => {
    //     if (e) {
    //         FilterSupportTeam(e).catch((err) => message.error(`Unable to filter support team. Please try again later. ${err}`))
    //     } else {
    //         props.form.setFieldsValue({
    //             Assigned_to: null
    //         })
    //     }
    // }

    const callApiGetPriorities = async () => {
        const myImpact = props.form.getFieldValue('Impact')
        const myUrgency = props.form.getFieldValue('Urgency')
        const impact = props.impacts?.find(it => it.id === myImpact)?.id!!
        const urgency = props.urgencies?.find(it => it.id === myUrgency)?.id!!
        if (impact !== undefined && urgency !== undefined) {
            resolvePriority(impact, urgency).then((res) => {
                setMyPriority({
                    id: Number(res?.id),
                    value: res.priority?.name
                })
                props.form.setFieldsValue({
                    Priority: res.priority?.name
                })
            }).catch((err) => {
                message.error(`${err}`)
            })
        }
    }

    // const FilterSupportTeam = async (e: SelectValue) => {
    //     await props.form.setFieldsValue({
    //         Assigned_to: null
    //     })
    //     const target = props.supportTeamsWithPermission.find(it => it.name === e)
    //     if (props.form.getFieldValue('Assignment_group') === '') {
    //         props.form.setFields({
    //             Assignment_group: null,
    //             Assigned_to: null
    //         })
    //     }
    //     const targetSupportTeams = props.supportTeamsWithPermission.filter((supportTeam: SupportTeam) => {
    //         return supportTeam.name === e
    //     })
    //     if (targetSupportTeams.length !== 0) {
    //         targetSupportTeams.forEach((res) => {
    //             setAssignee(res.assignees)
    //         })
    //     } else {
    //         setAssignee([])
    //     }
    // }

    // const onBlurTeam = (value: SelectValue) => {
    //     if (mySupportTeamName) {
    //         const isTeam = mySupportTeamName.includes((value as string))
    //         if (!isTeam) {
    //             props.form.setFieldsValue({
    //                 Assignment_group: undefined
    //             })
    //             setAssignee([])
    //         }
    //     }
    // }

    // const changeAssigned = (e: SelectValue) => {
    //     if (e === '') {
    //         props.form.setFieldsValue({
    //             Assigned_to: null
    //         })
    //         setAssignee([])
    //     }
    // }

    // const onBlurAssignee = (value: SelectValue) => {
    //     const currentAssignGroup = props.form.getFieldValue('Assignment_group')
    //     if (assignee) {
    //         const isAssignee = assignee.find(it => it.id?.toString() === value)
    //         if (!isAssignee && !currentAssignGroup) {
    //             props.form.setFieldsValue({
    //                 Assigned_to: undefined
    //             })
    //         } else if (!isAssignee) {
    //             props.form.setFieldsValue({
    //                 Assigned_to: undefined
    //             })
    //         }
    //     }
    // }

    // const FilterAssignee = () => {
    //     const valAssignee = props.form.getFieldValue('Assignment_group')
    //     const targetSupportTeams = props.supportTeamsWithPermission.filter((supportTeam: SupportTeam) => {
    //         return supportTeam.name === valAssignee
    //     })
    //     targetSupportTeams.forEach((res) => {
    //         setAssignee(res.assignees)
    //     })
    // }

    const validateTopicAndDes = (_rule: any, value: any, callback) => {
        if (value) {
            const CheckValue = value.replace(/ +/g, '').trim()
            // const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            // const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (CheckValue.length >= 3) {
                callback()
            } else {
                callback('Please enter more than 3 characters.')
            }
            // if (pattern.test(CheckValue)) {
            //     if (CheckValue.length >= 3) {
            //         callback()
            //     } else {
            //         callback('Please enter more than 3 characters.')
            //     }
            // } else {
            // eslint-disable-next-line
            // if (format.test(CheckValue)) {
            //     callback('Please enter more than 1 characters. and must not contain these characters: ' + format)
            // } else {
            //     callback('Please enter more than 3 characters.')
            // }
            // }
        } else {
            callback()
        }
    }

    const validateTemplate = (_rule: any, value: any, callback) => {
        if (value) {
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (pattern.test(value.replace(/ +/g, '').trim())) {
                if (value.replace(/ +/g, '').trim().length >= 3) {
                    callback()
                } else {
                    callback('Please enter more than 3 characters.')
                }
            } else {
                // eslint-disable-next-line
                if (format.test(value.replace(/ +/g, '').trim())) {
                    callback('Please enter more than 1 characters. and must not contain these characters: ' + format)
                } else {
                    callback('Please enter more than 3 characters.')
                }
            }
        } else {
            callback()
        }
    }

    return (
        <div>
            <h3 className={'main-title'}>Ticket Template</h3>
            <Form layout="vertical">
                {props.dataEdit ? <>Clone Ticket Number : {props.dataEdit?.ticketNumber === 'null' ? '-' : props.dataEdit?.ticketNumber} <br/></> : <Row>
                    <Col span={14}>
                        <Form.Item >
                            {getFieldDecorator('search')(
                                <AutoComplete
                                    dataSource={dataSearch.slice(0, 10).map((item: ticketNumber, index: number) => {
                                        return <Option value={item.ticketNumber?.toString()}
                                            key={index}>{`${item.ticketNumber} : ${item.topic}`}</Option>
                                    })}
                                    placeholder="Can Search Topic and Ticket Number"
                                    onSearch={() => searchData()}
                                    onSelect={onSelect}
                                    style={{ width: 415 }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    {/* <Col span={14}>
                        <Button type='primary' htmlType='button' onClick={searchData} >Select</Button>
                    </Col> */}
                </Row>}
                <br/>
                <Spin spinning={isLoadingSearch}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label={'Template Name'}>
                                {getFieldDecorator('templateName', {
                                    rules: [
                                        {required: true, message: 'Template Name is required'},
                                        {
                                            validator: validateTemplate
                                        }
                                    ]
                                })(
                                    <Input placeholder='Template Name' disabled={props.isView} maxLength={60}/>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12} lg={12} md={12} sm={24} xs={24}>
                            <Form.Item label="Impact">
                                {getFieldDecorator('Impact', {
                                    rules: [{
                                        required: true
                                    }]
                                })(
                                    <Select placeholder="Select an impact" onSelect={() => callApiGetPriorities()} disabled={props.isView}>
                                        {props.impacts.length === 0 ? null : (props.impacts.map((item: PriorityLevel, index: number) => {
                                            return <Option value={item.id} key={index}>{item.name}</Option>
                                        }))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12} lg={12} md={12} sm={24} xs={24}>
                            <Form.Item label="Urgency">
                                {getFieldDecorator('Urgency', {
                                    rules: [{
                                        required: true
                                    }]
                                })(
                                    <Select placeholder="Select an urgency" onSelect={() => callApiGetPriorities()} disabled={props.isView}>
                                        {props.urgencies.length === 0 ? null : (props.urgencies.map((item: PriorityLevel, index: number) => {
                                            return <Option value={item.id} key={index}>{item.name}</Option>
                                        }))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12} lg={12} md={12} sm={24} xs={24}>
                            <Form.Item label="Ticket Type">
                                {getFieldDecorator('TicketType', {
                                    rules: [{ required: true}]
                                })(
                                    <Select placeholder="Select an Ticket Type" disabled={props.isView}>
                                        {props.generalIncidentSetting.ticketTypes?.length === 0 ? null : (props.generalIncidentSetting.ticketTypes?.map((item, index: number) => {
                                            return <Option value={item.id} key={index}>{item.value}</Option>
                                        }))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12} lg={12} md={12} sm={24} xs={24}>
                            <Form.Item label="Priority">
                                {getFieldDecorator('Priority', {
                                    rules: [{
                                        required: true
                                    }]
                                })(
                                    <Input disabled={true} placeholder="Priority"/>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Topic">
                                {getFieldDecorator('topicName', {
                                    rules: [{
                                        required: true, message: 'Topic is required'
                                    },
                                    { validator: validateTopicAndDes }
                                    ]
                                })(
                                    <Input placeholder={'Topic'} maxLength={255} disabled={props.isView}/>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Description">
                                {getFieldDecorator('description', {
                                    rules: [{
                                        required: true, message: 'Description is required.'
                                    }, {
                                        validator: validateTopicAndDes
                                    }]
                                })(
                                    <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 4 }} placeholder={'Description'} disabled={props.isView} maxLength={255}/>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Row gutter={16}>
                        <Col span={12} lg={12} md={12} sm={24} xs={24}>
                            <Form.Item label="Assignment group">
                                {getFieldDecorator('Assignment_group', { initialValue: '' })(
                                    <AutoComplete
                                        dataSource={(mySupportTeamName || []).map((it) => {
                                            return it
                                        })}
                                        onChange={(e) => checkAssignee(e)}
                                        onSelect={(e) => FilterSupportTeam(e)}
                                        onBlur={(e) => onBlurTeam(e)}
                                        allowClear={true}
                                        placeholder={'Select an assignment group'}
                                        disabled={props.isView}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12} lg={12} md={12} sm={24} xs={24}>
                            <Form.Item label="Assigned to">
                                {getFieldDecorator('Assigned_to', { initialValue: '' })(
                                    <AutoComplete
                                        dataSource={assignee.map((item, index: number) => {
                                            return <Option value={item.id?.toString()}
                                                key={index}>{item.users.fullName}</Option>
                                        })}
                                        onChange={changeAssigned}
                                        onFocus={() => FilterAssignee()}
                                        onBlur={(e) => onBlurAssignee(e)}
                                        allowClear={true}
                                        placeholder={'Select an assignee'}
                                        disabled={props.isView}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col span={24}>
                            <Button type="primary"
                                loading={isLoading}
                                htmlType="submit"
                                disabled={props.isView}
                                onClick={submitFrom}
                                style={{float: 'right', marginBottom: '5px'}}>Submit</Button>
                            <Button disabled={props.isView} onClick={leavePageModal} style={{float: 'right', marginRight: 10}}>Clear</Button>
                        </Col>
                    </Row>
                </Spin>

            </Form>
        </div>
    )
}

const EditTemplateForm = Form.create<Props>()(EditTemplate)
export default connect(mapStateToProps, {
    getAllCategory,
    getAllSubCategory,
    getAllItems,
    getAllSupportTeamByPermission,
    getAllStatus,
    getAllPriority,
    getAllUrgency,
    getAllImpact,
    getAllGeneralIncidentSetting
})(EditTemplateForm)
