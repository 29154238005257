import React, { useEffect, useState } from 'react'
import Form, { FormComponentProps } from 'antd/lib/form'
import { StoreState } from '../../store'
import { connect } from 'react-redux'
import { Button, Card, Col, Icon, Row, Modal, Spin, message } from 'antd'
import TableCustomFields from './Components/TableCustomFields'
import { CustomFiledProblem } from './state/model'
import { addOrUpdateCustomFieldsProblem, getAllCustomFieldProblem } from './state/services'
import { v4 as uuid } from 'uuid'

const mapStateToProps = (state: StoreState) => {
    return {
        customFieldProblems: state.customFieldProblems
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllCustomFieldProblem: (value: string) => Promise<number>;
}

interface Param {
    TypeProblemid?: string
}

type Props = FormComponentProps & StateProps & DispatchProps & Param
const { confirm } = Modal

const ProblemsCustomField: React.FC<Props> = (props: Props) => {
    const [isEditMode, setIsEditMode] = useState(false)
    const [arr, setArr] = useState<CustomFiledProblem[]>([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (props.TypeProblemid) {
            setIsLoading(true)
            props.getAllCustomFieldProblem(props.TypeProblemid).finally(() => { setIsLoading(false) })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.TypeProblemid])

    useEffect(() => {
        if (props.customFieldProblems) {
            const convertData = props.customFieldProblems.map((data) => {
                return {
                    ...data,
                    key: uuid().slice(0, 8)
                }
            })
            setArr(convertData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.customFieldProblems])

    const getData = (data) => {
        setArr(data)
    }

    function showConfirm() {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm saving this data ?',
            onOk() {
                props.form.validateFields((_err) => {
                    console.log(_err)
                    if (!_err) {
                        console.log(arr)
                        setIsLoading(true)
                        const convertData: any = arr.map((data) => {
                            return {
                                dataType: data.dataType,
                                defaultValue: data.defaultValue,
                                description: data.description,
                                inputType: data.inputType,
                                label: data.label,
                                name: data.name,
                                placeholder: data.placeholder,
                                customFieldOrder: data.customFieldOrder,
                                pbType: {
                                    id: data.pbType?.id || props.TypeProblemid
                                },
                                active: data.active,
                                selectOption: data.selectOption,
                                key: data.key,
                                isRequireFields: data.isRequireFields
                            }
                        })
                        addOrUpdateCustomFieldsProblem(convertData).then(() => {
                            message.success('The Update has finished successfully')
                            setIsEditMode(false)
                        }).catch((_err) => {
                            message.error(_err.response.data.message || 'Please complete all custom fields.')
                        }).finally(() => {
                            setIsLoading(false)
                        })
                    }
                })
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    return (
        <div>
            <Card style={{ overflow: 'auto', minHeight: 650 }}>
                <Spin spinning={isLoading}>
                    <Form>
                        <Row>
                            <Col span={12} xs={24} sm={24} md={12} lg={12}>{!isEditMode ? <h3 className={'main-title text-herder-Orenge'}>Custom Field setting - Problem</h3>
                                : <h3 className={'main-title text-herder-Orenge'}><Icon type="arrow-left" onClick={() => setIsEditMode(false)}/> &nbsp; Custom Field setting - Problem</h3>}
                            </Col>
                            <Col span={12} xs={24} sm={24} md={12} lg={12} style={{ textAlign: 'end' }}>{!isEditMode ? <Button type='primary' onClick={() => setIsEditMode(true)}><Icon type="plus" /> Add / Edit</Button>
                                : <div><Button onClick={() => setIsEditMode(false)}>Cancel</Button> &nbsp; <Button type='primary' disabled={arr.length === 0} onClick={() => showConfirm()}>Save</Button></div>}
                            </Col>
                        </Row>
                        <br />
                        {!isEditMode ? <TableCustomFields dataSource={arr} isEditMode={isEditMode} form={props.form} getData={getData} nameForm='Problem'/> : <div>
                            <Card style={{ overflow: 'auto'}}>
                                <TableCustomFields dataSource={arr} isEditMode={isEditMode} form={props.form} getData={getData} nameForm='Problem'/>
                            </Card>
                        </div>}
                    </Form>
                </Spin>
            </Card>
        </div>
    )
}

const ProblemsFields = Form.create<Props>()(ProblemsCustomField)
export default connect(mapStateToProps, {
    getAllCustomFieldProblem
})(ProblemsFields)
