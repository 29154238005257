import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Tag, Tooltip } from 'antd'
import { CommentType } from './model'

interface Props {
    text: string
    type: CommentType
}

const MAX_LENGTH = 490

const CommentRender: React.FC<Props> = (props: Props) => {
    const [readMore, setReadMore] = useState<boolean>(false)
    const [isForword, setIsForword] = useState<boolean>(false)

    const ToggleIsForword = () => {
        setIsForword(!isForword)
    }

    const ToggleIsReadMore = () => {
        setReadMore(!readMore)
    }

    const CommentText = (props: Props) => {
        return (
            <div className='ql-editor' style={{ minHeight: 100, padding: 0, marginRight: 20 }}>
                <div style={{ overflow: 'hidden', overflowX: 'auto' }}>
                    {readMore ? ReactHtmlParser(props.text) : props.text?.length > MAX_LENGTH ? ReactHtmlParser(props.text?.slice(0, MAX_LENGTH) + '...') : ReactHtmlParser(props.text)}
                </div>
                {props.type === CommentType.AddNote || props.type === CommentType.Reply ? <>
                    {readMore ? null : props.text?.length > MAX_LENGTH ? <span className='feck-link' onClick={ToggleIsReadMore}>Read more</span> : null}
                </> : null}
            </div>
        )
    }

    return (
        <>
            {props.type === CommentType.AddNote || props.type === CommentType.Reply ? CommentText(props) : null}

            {props.type === CommentType.Forward ? <Tooltip title={isForword ? 'Hide quoted text' : 'Show quoted text'}><Tag onClick={ToggleIsForword}>...</Tag></Tooltip> : null}
            <br />
            {isForword ? CommentText(props) : null}
        </>
    )
}

export default CommentRender
