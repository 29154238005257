/* eslint-disable no-useless-escape */
/* eslint-disable no-prototype-builtins */
import { Form, Button, Col, Dropdown, Icon, Input, Menu, message, Modal, Row, Select, Table, Tabs, Upload } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import React, { useEffect, useRef, useState } from 'react'
import { deleteItemsAssetSetting, getAllitemsAssetLocation, getAllItemsAssetSettingWithPagination, createItemOneTier, getAssetGroupType, deleteItemAssetGroupType, deleteAssetLocation, createItemAssetGroupType, getTemplateOnetier, getTemplateAssetGroupType, getTemplateAssetLocation, getDropdownOption, getHieracrhyOption, getAllAssetGroupType, importOnetierByExcel, importAssetGroupType, importAssetLocationByExcel, getAssetGroups, deleteItemAssetGroups, getTemplateAssetGroups, createItemAssetGroups, importAssetGroupsByExcel } from '../service'
import { assetLocation, buildingHierarchyOption, childGeneral, generalAssetSetting, importAssetLocation, itemData, itemDataAssetSetting, itemGroupType, locationHierarchyOption, siteHierarchyOption } from '../model'
import { getAllAssetGroup } from '../../msp-wo/asset-management'
import { AssetGroups } from '../../configurable-fields'
import XLSX from 'sheetjs-style'
import { getExportAssetSetting } from '../../msp-portal'
import RenderTabAssetLocationTabs from './renderTabAssetLocation'

interface params {
    general: generalAssetSetting
    name: string
    keyId: number
    child?: childGeneral[]
    keyMenu: string
}

type Props = params & FormComponentProps
const { TabPane } = Tabs
const { Search } = Input
const { confirm } = Modal
const CustomAssetSetting: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, resetFields, validateFields } = props.form
    const [dataSource, setDataSource] = useState<itemDataAssetSetting[]>([])
    const [totalDataSource, setTotalDataSource] = useState<number>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [assetGroupsType, setAssetGroupsType] = useState<AssetGroups[]>([])
    const [nameAssetGroupsType, setNameAssetGroupType] = useState<string>('')
    const [assetGroupId, setAssetGroupId] = useState<number>(1)
    const [isDeleteItem, setIsDeleteItem] = useState<boolean>(false)
    const [selectedItemKeys, setSelectedItemKeys] = useState<number[]>([])
    const [itemDeleteSelected, setItemDeleteSelected] = useState<number[]>([])
    const [keyLocation, setKeyLocation] = useState<string>('site')
    const [keyLocationLabel, setKeyLocationLabel] = useState<string>('Site')
    const [dataAssetLocation, setDataAssetLocation] = useState<assetLocation[]>([])
    const [totalAssetLocation, setTotalAssetLocation] = useState<number>()
    const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false)
    const [searchValue, setSearchValue] = useState<string>('')
    const [assetStatusAll, setAssetStatusAll] = useState<string[]>([])
    const [brandAll, setBrandAll] = useState<string[]>([])
    const [modelAll, setModelAll] = useState<string[]>([])
    const [zoneAll, setZoneAll] = useState<string[]>([])
    const [typeAll, setTypeAll] = useState<string[]>([])
    const [assetGroupAll, setAssetGroupAll] = useState<string[]>([])
    const [page, setPage] = useState<number>(1)
    const [sortBy, setSortBy] = useState<string>('lastModifiedDatetime,desc')
    const [loadingImportAssetLocation, setLoadingImportAssetLocation] = useState<boolean>(false)
    const [isDeleteAssetLocation, setIsDeleteAssetLocation] = useState<boolean>(false)
    const [siteAll, setSiteAll] = useState<siteHierarchyOption[]>([])
    const [locationAll, setLocationAll] = useState<locationHierarchyOption[]>([])
    const [buildingAll, setBuildingAll] = useState<buildingHierarchyOption[]>([])
    const pageSize = 10

    const prevPropsName = useRef(props.name)

    useEffect(() => {
        fetchAllAssetGroup()
    }, [])

    useEffect(() => {
        if (props.name !== 'Asset Location' && props.name !== 'Type' && props.name !== 'Asset Group') {
            fetchData()
        } else if (props.name === 'Asset Location') {
            if (keyLocation) {
                fetchDataAssetLocation()
                fetchHierarchyOption()
            }
        } else if (props.name === 'Type') {
            fetchDataAssetGroupType()
            getAllAssetGroupType().then((res) => {
                if (res) {
                    const filterGroup = res.filter((group) => {
                        const assetGroup = group.assetGroup
                        return assetGroup.name === nameAssetGroupsType
                    })
                    const tempType = filterGroup.map(data => data.type)
                    setTypeAll(tempType)
                }
            })
        } else if (props.name === 'Asset Group') {
            fetchDataAssetGroups()
        }
        fetchDataOptions()
        if (props.name !== prevPropsName.current) {
            setIsDeleteItem(false)
            setItemDeleteSelected([])
            setSelectedItemKeys([])
            setPage(1)
            setSortBy('lastModifiedDatetime,desc')
            setSearchValue('')
            resetFields([`search_${keyLocationLabel}`, `search_${props.keyMenu}`])
        }
        prevPropsName.current = props.name
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.name, keyLocation, page, pageSize, sortBy, assetGroupId])
    
    const fetchHierarchyOption = () => {
        const params = keyLocation.toUpperCase()
        getHieracrhyOption(params).then((res) => {
            if (res && res.length > 0) {
                setSiteAll(res)
                const mapLocation = res.flatMap((it) => { return it.locations }).filter(location => location.locationName !== '-')
                const mapBuilding = res.flatMap((site) => {
                    return site.locations.flatMap((location) => {
                        return location.buildings.filter(building => building.buildingName !== '-')
                    })
                })
                setLocationAll(mapLocation)
                setBuildingAll(mapBuilding)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const fetchAllAssetGroup = () => {
        getAllAssetGroup().then((res) => {
            if (res) {
                const tempName = res.map(data => data.name)
                setAssetGroupAll(tempName)
                setAssetGroupsType(res)
                setNameAssetGroupType(res[0].name)
                setAssetGroupId(res[0].id)
            }
        })
    }

    const fetchData = () => {
        setIsDeleteItem(false)
        setItemDeleteSelected([])
        setSelectedItemKeys([])
        setIsLoading(true)
        setDataSource([])
        setTotalDataSource(0)
        let tempSearch
        if (props.name !== prevPropsName.current) {
            tempSearch = ''
        } else {
            tempSearch = searchValue
        }
        getAllItemsAssetSettingWithPagination(String(props.keyId), tempSearch, page - 1, pageSize, sortBy).then((res) => {
            if (res) {
                setDataSource(res.content)
                setTotalDataSource(res.totalElements)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const fetchDataAssetGroupType = () => {
        setIsDeleteItem(false)
        setItemDeleteSelected([])
        setSelectedItemKeys([])
        setIsLoading(true)
        setDataSource([])
        setTotalDataSource(0)
        let tempSearch
        if (props.name !== prevPropsName.current) {
            tempSearch = ''
        } else {
            tempSearch = searchValue
        }
        getAssetGroupType(tempSearch, page - 1, pageSize, sortBy, assetGroupId).then((res) => {
            if (res) {
                setDataSource(res.content)
                setTotalDataSource(res.totalElements)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const fetchDataAssetLocation = () => {
        setIsDeleteItem(false)
        setItemDeleteSelected([])
        setSelectedItemKeys([])
        setIsLoading(true)
        setDataAssetLocation([])
        setTotalAssetLocation(0)
        let tempSearch
        if (props.name !== prevPropsName.current) {
            tempSearch = ''
        } else {
            tempSearch = searchValue
        }
        const findCheckKeysLocation = props.child?.find((it) => it.label.toLowerCase() === keyLocation.toLowerCase())
        if (findCheckKeysLocation) {
            getAllitemsAssetLocation(findCheckKeysLocation.id, tempSearch, page - 1, pageSize, sortBy).then((res) => {
                if (res) {
                    setDataAssetLocation(res.content)
                    setTotalAssetLocation(res.totalElements)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }

    const fetchDataAssetGroups = () => {
        setIsDeleteItem(false)
        setItemDeleteSelected([])
        setSelectedItemKeys([])
        setIsLoading(true)
        setDataAssetLocation([])
        setTotalAssetLocation(0)
        let tempSearch
        if (props.name !== prevPropsName.current) {
            tempSearch = ''
        } else {
            tempSearch = searchValue
        }
        getAssetGroups(tempSearch, page - 1, pageSize, sortBy).then((res) => {
            if (res) {
                setDataSource(res.content)
                setTotalDataSource(res.totalElements)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const onFetchDataAssetLocation = () => {
        fetchDataAssetLocation()
        fetchHierarchyOption()
    }

    const fetchDataOptions = () => {
        let paramItems = ''
        if (props.name !== 'Asset Location' && props.name !== 'Type') {
            if (props.name === 'Asset Status') {
                paramItems = 'assetStatus'
            } else {
                paramItems = props.name.toLowerCase()
            }
            getDropdownOption(paramItems).then((res) => {
                if (res) {
                    if (props.name === 'Asset Status') {
                        setAssetStatusAll(res)
                    } else if (props.name === 'Brand') {
                        setBrandAll(res)
                    } else if (props.name === 'Model') {
                        setModelAll(res)
                    } else if (props.name === 'Zone') {
                        setZoneAll(res)
                    }
                }
            })
        }
    }

    const fetchAllAssetGroupType = () => {
        getAllAssetGroupType().then((res) => {
            if (res) {
                const filterGroup = res.filter((group) => {
                    const assetGroup = group.assetGroup
                    return assetGroup.name === nameAssetGroupsType
                })
                const tempType = filterGroup.map(data => data.type)
                setTypeAll(tempType)
            }
        })
    }

    const checkColumnForRender = (general: generalAssetSetting) => {
        if (general && props.name === general.label) {
            if (props.name !== 'Type' && props.name !== 'Asset Group') {
                return [
                    {
                        title: general.label,
                        dataIndex: 'itemData',
                        key: general.key,
                        width: '33%',
                        sorter: true
                    },
                    {
                        title: 'Last Modified By',
                        dataIndex: 'lastModifiedBy',
                        key: 'lastModifiedBy',
                        width: '33%'
                    },
                    {
                        title: 'Last Modified Datetime',
                        dataIndex: 'lastModifiedDatetime',
                        key: 'lastModifiedDatetime',
                        width: '33%'
                    }
                ]
            } else if (props.name === 'Type') {
                return [
                    {
                        title: general.label,
                        dataIndex: 'type',
                        key: general.key,
                        width: '33%',
                        sorter: true
                    },
                    {
                        title: 'Last Modified By',
                        dataIndex: 'lastModifiedBy',
                        key: 'lastModifiedBy',
                        width: '33%'
                    },
                    {
                        title: 'Last Modified Datetime',
                        dataIndex: 'lastModifiedDatetime',
                        key: 'lastModifiedDatetime',
                        width: '33%',
                        render: (data, row: any) => (
                            <>
                                {row.lastModifiedDatetime ? row.lastModifiedDatetime : '-'}
                            </>
                        )
                    }
                ]
            } else if (props.name === 'Asset Group') {
                return [
                    {
                        title: general.label,
                        dataIndex: 'name',
                        key: general.key,
                        width: '33%',
                        sorter: true
                    },
                    {
                        title: 'Last Modified By',
                        dataIndex: 'lastModifiedBy',
                        key: 'lastModifiedBy',
                        width: '33%'
                    },
                    {
                        title: 'Last Modified Datetime',
                        dataIndex: 'lastModifiedDatetime',
                        key: 'lastModifiedDatetime',
                        width: '33%',
                        render: (data, row: any) => (
                            <>
                                {row.lastModifiedDatetime ? row.lastModifiedDatetime : '-'}
                            </>
                        )
                    }
                ]
            }
        }
    }

    const getColumnAsssetLocation = (tab: string) => {
        if (tab === 'site') {
            return [
                {
                    title: 'Site',
                    dataIndex: 'name',
                    key: 'site',
                    width: '33%',
                    sorter: true
                },
                {
                    title: 'Last Modified By',
                    dataIndex: 'lastModifiedBy',
                    key: 'lastModifiedBy',
                    width: '33%'
                },
                {
                    title: 'Last Modified Datetime',
                    dataIndex: 'lastModifiedDatetime',
                    key: 'lastModifiedDatetime',
                    width: '33%'
                }
            ]
        } else if (tab === 'location') {
            return [
                {
                    title: 'Location',
                    dataIndex: 'name',
                    key: 'location',
                    sorter: true
                },
                {
                    title: 'Site',
                    dataIndex: 'parent.name',
                    key: 'site',
                    sorter: true
                },
                {
                    title: 'Last Modified By',
                    dataIndex: 'lastModifiedBy',
                    key: 'lastModifiedBy'
                },
                {
                    title: 'Last Modified Datetime',
                    dataIndex: 'lastModifiedDatetime',
                    key: 'lastModifiedDatetime'
                }
            ]
        } else if (tab === 'building') {
            return [
                {
                    title: 'Building',
                    dataIndex: 'name',
                    key: 'building',
                    sorter: true
                },
                {
                    title: 'Location',
                    dataIndex: 'parent.name',
                    key: 'location',
                    sorter: true
                },
                {
                    title: 'Site',
                    dataIndex: 'parent.parent.name',
                    key: 'site',
                    sorter: true
                },
                {
                    title: 'Last Modified By',
                    dataIndex: 'lastModifiedBy',
                    key: 'lastModifiedBy'
                },
                {
                    title: 'Last Modified Datetime',
                    dataIndex: 'lastModifiedDatetime',
                    key: 'lastModifiedDatetime'
                }
            ]
        } else if (tab === 'floor') {
            return [
                {
                    title: 'Floor',
                    dataIndex: 'name',
                    key: 'floor',
                    sorter: true
                },
                {
                    title: 'Building',
                    dataIndex: 'parent.name',
                    key: 'building',
                    sorter: true
                },
                {
                    title: 'Location',
                    dataIndex: 'parent.parent.name',
                    key: 'location',
                    sorter: true
                },
                {
                    title: 'Site',
                    dataIndex: 'parent.parent.parent.name',
                    key: 'site',
                    sorter: true
                },
                {
                    title: 'Last Modified By',
                    dataIndex: 'lastModifiedBy',
                    key: 'lastModifiedBy'
                },
                {
                    title: 'Last Modified Datetime',
                    dataIndex: 'lastModifiedDatetime',
                    key: 'lastModifiedDatetime'
                }
            ]
        } else {
            return []
        }
    }

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
    }

    const handleChangeAssetGroupType = (name, id) => {
        resetFields(['Type'])
        setNameAssetGroupType(name)
        setAssetGroupId(id)
    }

    const menu = () => {
        return (
            <Menu>
                {assetGroupsType.map((it, index) => {
                    return (<Menu.Item key={index} onClick={() => handleChangeAssetGroupType(it.name, it.id)}>
                        <span>{it.name}</span>
                    </Menu.Item>)
                })}
            </Menu>
        )
    }

    const handleCheckDeleteItem = () => {
        if (props.name !== 'Type' && props.name !== 'Asset Location' && props.name !== 'Asset Group') {
            return deleteItemSelect(selectedItemKeys)
        } else if (props.name === 'Type') {
            return deleteAssetGroupType(selectedItemKeys)
        } else if (props.name === 'Asset Location') {
            return deleteItemAssetLocation(selectedItemKeys)
        } else if (props.name === 'Asset Group') {
            return deleteAssetGroups(selectedItemKeys)
        }
    }

    const deleteItemSelect = (selectedItemKeys: number[]) => {
        confirm({
            title: <span style={{ fontSize: '25px', fontWeight: 'bold', color: '#323276' }}>Are you sure?</span>,
            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>Do you confirm that you want to delete this data ?</span>,
            okText: 'Yes',
            cancelText: 'No',
            className: 'custom-modal-wo',
            onOk() {
                setIsLoading(true)
                deleteItemsAssetSetting(selectedItemKeys).then((res) => {
                    if (res) {
                        message.success('The data has been deleted successfully.')
                        fetchData()
                        fetchDataOptions()
                    }
                }).catch((err) => {
                    console.log(err)
                })
            }
        })
    }

    const deleteAssetGroupType = (selectedItemKeys: number[]) => {
        confirm({
            title: <span style={{ fontSize: '25px', fontWeight: 'bold', color: '#323276' }}>Are you sure?</span>,
            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>Do you confirm that you want to delete this data ?</span>,
            okText: 'Yes',
            cancelText: 'No',
            className: 'custom-modal-wo',
            onOk() {
                setIsLoading(true)
                deleteItemAssetGroupType(selectedItemKeys).then((res) => {
                    if (res) {
                        fetchDataAssetGroupType()
                        fetchAllAssetGroupType()
                    }
                }).catch((err) => {
                    console.log(err)
                })
            }
        })
    }

    const deleteItemAssetLocation = (selectedItemKeys: number[]) => {
        confirm({
            title: <span style={{ fontSize: '25px', fontWeight: 'bold', color: '#323276' }}>Are you sure?</span>,
            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>Do you confirm that you want to delete this data ?</span>,
            okText: 'Yes',
            cancelText: 'No',
            className: 'custom-modal-wo',
            onOk() {
                setIsLoading(true)
                deleteAssetLocation(selectedItemKeys).then((res) => {
                    if (res) {
                        fetchDataAssetLocation()
                        fetchHierarchyOption()
                        setIsDeleteAssetLocation(true)
                    }
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    setIsDeleteAssetLocation(false)
                })
            }
        })
    }

    const deleteAssetGroups = (selectedItemKeys: number[]) => {
        confirm({
            title: <span style={{ fontSize: '25px', fontWeight: 'bold', color: '#323276' }}>Are you sure?</span>,
            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>Do you confirm that you want to delete this data ?</span>,
            okText: 'Yes',
            cancelText: 'No',
            className: 'custom-modal-wo',
            onOk() {
                setIsLoading(true)
                deleteItemAssetGroups(selectedItemKeys).then((res) => {
                    if (res) {
                        fetchDataAssetGroups()
                        fetchAllAssetGroup()
                    }
                }).catch((err) => {
                    console.log(err)
                })
            }
        })
    }

    const handleDeleteItem = () => {
        setIsDeleteItem(true)
    }

    const handleRowDeleteSelection = (selectedRowsKeys, selectedRows) => {
        const selectedRowIds = selectedRows.map((data) => data.id)
        const uniqueIds = [...new Set([...itemDeleteSelected, ...selectedRowIds])]
        const filterUniqueIds = uniqueIds.filter(id => selectedRowIds.includes(id))
        setSelectedItemKeys([...selectedRowsKeys])
        setItemDeleteSelected(filterUniqueIds)
    }

    const rowDeleteItemSelection = {
        selectedItemKeys: selectedItemKeys,
        onChange: handleRowDeleteSelection,
        getCheckboxProps: (record) => ({
            disabled: record.assetInUse === 1
        })
    }

    const handleCancelDeleteItem = () => {
        setIsDeleteItem(false)
        setSelectedItemKeys([])
        setItemDeleteSelected([])
    }

    const changeTabLocation = (e) => {
        setKeyLocation(e)
        if (e === 'site') {
            setKeyLocationLabel('Site')
        } else if (e === 'location') {
            setKeyLocationLabel('Location')
        } else if (e === 'building') {
            setKeyLocationLabel('Building')
        } else {
            setKeyLocationLabel('Floor')
        }
        resetFields()
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        validateFields((err, values) => {
            if (!err && values[props.keyMenu] !== undefined) {
                if (props.name !== 'Type' && props.name !== 'Asset Group') {
                    const itemsValues = values[props.keyMenu]
                    const newItemsData: itemData = {
                        itemId: props.keyId,
                        items: itemsValues
                    }
                    setIsLoading(true)
                    createItemOneTier(newItemsData).then((res) => {
                        if (res) {
                            if (props.name === 'Asset Status') {
                                setAssetStatusAll((prev) => [...prev, ...itemsValues])
                            } else if (props.name === 'Brand') {
                                setBrandAll((prev) => [...prev, ...itemsValues])
                            } else if (props.name === 'Model') {
                                setModelAll((prev) => [...prev, ...itemsValues])
                            } else if (props.name === 'Zone') {
                                setZoneAll((prev) => [...prev, ...itemsValues])
                            }
                            message.success('The data has been saved successfully.')
                            fetchData()
                        }
                    }).catch((err) => {
                        console.log(err)
                        setIsLoading(false)
                    })
                    resetFields()
                } else if (props.name === 'Type') {
                    const itemsValues = values[props.keyMenu]
                    const newItemGroupType: itemGroupType = {
                        assetGroupId: assetGroupId,
                        types: itemsValues
                    }
                    setIsLoading(true)
                    createItemAssetGroupType(newItemGroupType).then((res) => {
                        if (res) {
                            setTypeAll((prev) => [...prev, ...itemsValues])
                            message.success('The data has been saved successfully.')
                            fetchDataAssetGroupType()
                        }
                    }).catch((err) => {
                        console.log(err)
                        setIsLoading(false)
                    })
                    resetFields()
                } else if (props.name === 'Asset Group') {
                    const itemGroups = values[props.keyMenu]
                    setIsLoading(true)
                    createItemAssetGroups(itemGroups).then((res) => {
                        if (res) {
                            setAssetGroupAll((prev) => [...prev, ...itemGroups])
                            message.success('The data has been saved successfully.')
                            fetchDataAssetGroups()
                            fetchAllAssetGroup()
                        }
                    }).catch((err) => {
                        console.log(err)
                        setIsLoading(false)
                    })
                    resetFields()
                }
            }
        })
    }

    const handleCancel = () => {
        resetFields()
    }

    const exportItemData = (type: string) => {
        setIsLoadingExport(true)
        if (type === 'Asset Status') {
            type = 'assetStatus'
        } else if (type === 'Asset Location') {
            type = 'assetLocation'
        } else if (type === 'Type') {
            type = 'assetGroupType '
        } else if (type === 'Asset Group') {
            type = 'assetGroup'
        } else {
            type = type.toLowerCase()
        }
        getExportAssetSetting(type).then((res) => {
        }).catch((err) => {
            console.log(err)
            setIsLoadingExport(false)
        }).finally(() => {
            setIsLoadingExport(false)
        })
    }

    const checkSearchTab = (value) => {
        if (props.name !== 'Type' && props.name !== 'Asset Group') {
            handleSearchOneTier(value)
        } else if (props.name === 'Type') {
            handleSearchAssetGroupType(value)
        } else if (props.name === 'Asset Group') {
            handleSearchAssetGroups(value)
        }
    }

    const handleSearchOneTier = (value) => {
        setSearchValue(value?.trim())
        searchOneTire(value?.trim())
    }

    const handleSearchAssetLocation = (value) => {
        setSearchValue(value?.trim())
        searchAssetLocation(value?.trim())
    }

    const handleSearchAssetGroupType = (value) => {
        setSearchValue(value?.trim())
        searchAssetGroupType(value?.trim())
    }

    const handleSearchAssetGroups = (value) => {
        setSearchValue(value?.trim())
        searchAssetGroups(value?.trim())
    }

    const searchOneTire = (value) => {
        setIsLoading(true)
        setDataSource([])
        setTotalDataSource(0)
        setPage(1)
        getAllItemsAssetSettingWithPagination(String(props.keyId), value !== '' ? value : '', page - 1, pageSize, 'itemData,desc').then((res) => {
            if (res) {
                setDataSource(res.content)
                setTotalDataSource(res.totalElements)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const searchAssetLocation = (value) => {
        setIsLoading(true)
        setDataAssetLocation([])
        setTotalAssetLocation(0)
        setPage(1)
        const findCheckKeysLocation = props.child?.find((it) => it.label.toLowerCase() === keyLocation.toLowerCase())
        if (findCheckKeysLocation) {
            getAllitemsAssetLocation(findCheckKeysLocation.id, value !== '' ? value : '', page - 1, pageSize, 'name,desc').then((res) => {
                if (res) {
                    setDataAssetLocation(res.content)
                    setTotalAssetLocation(res.totalElements)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }

    const searchAssetGroupType = (value) => {
        setIsLoading(true)
        setDataSource([])
        setTotalDataSource(0)
        setPage(1)
        getAssetGroupType(value !== '' ? value : '', page - 1, pageSize, 'type,asc', assetGroupId).then((res) => {
            if (res) {
                setDataSource(res.content)
                setTotalDataSource(res.totalElements)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const searchAssetGroups = (value) => {
        setIsLoading(true)
        setDataSource([])
        setTotalDataSource(0)
        setPage(1)
        getAssetGroups(value !== '' ? value : '', page - 1, pageSize, sortBy).then((res) => {
            if (res) {
                setDataSource(res.content)
                setTotalDataSource(res.totalElements)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    function Failed(Text: string) {
        Modal.error({
            title: 'Failed',
            content: Text,
            onOk: () => {
                Modal.destroyAll()
            },
            className: 'modal-not-show-close'
        })
    }

    const uploadPropsOneTier = {
        multiple: false,
        onRemove: (file: any) => {

        },
        beforeUpload: (file: any) => {
            const isXlsx = file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            if (isXlsx) {
                Failed('You are only allowed to upload xlsx files.')
            } else {
                const reader = new FileReader()
                reader.readAsBinaryString(file)
                reader.onloadend = function (e: any) {
                    const bstr = e.target.result
                    const wb = XLSX.read(bstr, { type: 'binary', cellText: false, cellDates: true })
                    let data: any[] = []
                    if (wb.SheetNames.length !== 0) {
                        wb.SheetNames.forEach((name, index) => {
                            if (props.name !== 'Type' && props.name !== 'Asset Group') {
                                if (index === 0) {
                                    const wsName = wb.SheetNames[0]
                                    const ws = wb.Sheets[wsName]
                                    data = XLSX.utils.sheet_to_json(ws, { defval: '', raw: true, blankrows: true })

                                    const columMapping = {
                                        'Asset Status': 'Asset Status',
                                        Brand: 'Brand',
                                        Model: 'Model',
                                        Zone: 'Zone'
                                    }

                                    const checkMappingColumn = columMapping[props.name]

                                    if (!data[0]?.hasOwnProperty(checkMappingColumn)) {
                                        Failed('Unable to upload the file due to an incorrect format. Please try again later.')
                                        return
                                    }

                                    if (data.length !== 0 && data.length <= 1000) {
                                        const itemsArray: string[] = []

                                        data.forEach((it, index) => {
                                            const item = it[checkMappingColumn] !== undefined && it[checkMappingColumn] !== null
                                                ? it[checkMappingColumn].trim()
                                                : ''

                                            itemsArray.push(item)
                                        })

                                        const formattedImport = {
                                            itemId: props.keyId,
                                            items: itemsArray
                                        }

                                        setIsLoading(true)
                                        importOnetierByExcel(formattedImport).then(() => {
                                            fetchData()
                                            fetchDataOptions()
                                        }).catch((err) => {
                                            console.log(err)
                                        })
                                    } else if (data.length > 1000) {
                                        Failed(`Please do not upload ${checkMappingColumn} over the limit (maximum is 1000 rows).`)
                                        setIsLoading(false)
                                    }
                                }
                            } else if (props.name === 'Type') {
                                if (index === 0) {
                                    const wsName = wb.SheetNames[0]
                                    const ws = wb.Sheets[wsName]
                                    data = XLSX.utils.sheet_to_json(ws, { defval: '', raw: true, blankrows: true })

                                    if (!data[0]?.hasOwnProperty('Asset Type')) {
                                        Failed('Unable to upload the file due to an incorrect format. Please try again later.')
                                        return
                                    }

                                    if (data.length !== 0 && data.length <= 1000) {
                                        const typesArray: string[] = []

                                        data.forEach((it, index) => {
                                            const types = it['Asset Type'] !== undefined && it['Asset Type'] !== null
                                                ? it['Asset Type'].trim()
                                                : ''

                                            typesArray.push(types)
                                        })

                                        const formattedImportType = {
                                            assetGroupId: assetGroupId,
                                            types: typesArray
                                        }

                                        importAssetGroupType(formattedImportType).then(() => {
                                            fetchDataAssetGroupType()
                                            fetchDataOptions()
                                        }).catch((err) => {
                                            console.log(err)
                                        })
                                    } else if (data.length > 1000) {
                                        Failed('Please do not upload Asset Type over the limit (maximum is 1000 rows).')
                                        setIsLoading(false)
                                    }
                                }
                            } else if (props.name === 'Asset Group') {
                                if (index === 0) {
                                    const wsName = wb.SheetNames[0]
                                    const ws = wb.Sheets[wsName]
                                    data = XLSX.utils.sheet_to_json(ws, { defval: '', raw: true, blankrows: true })

                                    if (!data[0]?.hasOwnProperty('Asset Group')) {
                                        Failed('Unable to upload the file due to an incorrect format. Please try again later.')
                                        return
                                    }

                                    if (data.length !== 0 && data.length <= 1000) {
                                        const groupsArray: string[] = []

                                        data.forEach((it, index) => {
                                            const groups = it['Asset Group'] !== undefined && it['Asset Group'] !== null
                                                ? it['Asset Group'].trim()
                                                : ''

                                            groupsArray.push(groups)
                                        })

                                        importAssetGroupsByExcel(groupsArray).then((res) => {
                                            fetchDataAssetGroups()
                                            fetchDataOptions()
                                        }).catch((err) => {
                                            console.log(err)
                                        })
                                    } else if (data.length > 1000) {
                                        Failed('Please do not upload Asset Group over the limit (maximum is 1000 rows).')
                                        setIsLoading(false)
                                    }
                                }
                            }
                        })
                    }
                }
            }
            return false
        },
        showUploadList: false
    }

    const uploadPropsAssetLocation = {
        multiple: false,
        onRemove: (file: any) => {

        },
        beforeUpload: (file: any) => {
            const isXlsx = file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            if (isXlsx) {
                Failed('You are only allowed to upload xlsx files.')
            } else {
                const reader = new FileReader()
                reader.readAsBinaryString(file)
                reader.onloadend = function (e: any) {
                    const bstr = e.target.result
                    const wb = XLSX.read(bstr, { type: 'binary', cellText: false, cellDates: true })
                    let data: any[] = []
                    if (wb.SheetNames.length !== 0) {
                        wb.SheetNames.forEach((name, index) => {
                            if (index === 0) {
                                const wsName = wb.SheetNames[0]
                                const ws = wb.Sheets[wsName]
                                data = XLSX.utils.sheet_to_json(ws)

                                if (!data[0]?.hasOwnProperty('Site') && !data[0]?.hasOwnProperty('Location') && !data[0]?.hasOwnProperty('Building') && !data[0]?.hasOwnProperty('Floor')) {
                                    Failed('Save operation failed, the uploaded file has an incorrect format.')
                                    return
                                }

                                if (data.length !== 0 && data.length <= 1000) {
                                    const convertData: importAssetLocation[] = []

                                    data.forEach((it) => {
                                        const row = {
                                            site: it.hasOwnProperty('Site') ? it.Site.trim() : undefined,
                                            location: it.hasOwnProperty('Location') ? it.Location.trim() : undefined,
                                            building: it.hasOwnProperty('Building') ? it.Building.trim() : undefined,
                                            floor: it.hasOwnProperty('Floor') ? it.Floor.trim() : undefined
                                        }

                                        convertData.push(row)
                                    })
                                    console.log('convertData : ', convertData)
                                    importAssetLocationByExcel(convertData).then(() => {
                                        fetchDataAssetLocation()
                                        fetchHierarchyOption()
                                        setLoadingImportAssetLocation(true)
                                    }).catch((err) => {
                                        console.log(err)
                                    }).finally(() => {
                                        setLoadingImportAssetLocation(false)
                                    })
                                } else if (data.length > 1000) {
                                    Failed('Please do not upload Asset Location over the limit (maximum is 1000 rows).')
                                }
                            }
                        })
                    }
                }
            }
            return false
        },
        showUploadList: false
    }

    const handleCheklUploadProps = () => {
        if (props.name !== 'Asset Location') {
            return { ...uploadPropsOneTier }
        } else if (props.name === 'Asset Location') {
            return { ...uploadPropsAssetLocation }
        }
    }

    const handleGetTemplate = () => {
        if (props.name !== 'Type' && props.name !== 'Asset Location' && props.name !== 'Asset Group') {
            getTemplateOnetier(props.name)
        } else if (props.name === 'Type') {
            getTemplateAssetGroupType()
        } else if (props.name === 'Asset Location') {
            getTemplateAssetLocation()
        } else if (props.name === 'Asset Group') {
            getTemplateAssetGroups()
        }
    }

    const menuImport = (
        <Menu>
            <Menu.Item>
                <Upload {...handleCheklUploadProps()} style={{ minWidth: 100 }}>
                    <div className="addCriteriaButton">
                        <Icon type="download" /> Import {props.name}
                    </div>
                </Upload>
            </Menu.Item>
            <Menu.Item>
                <div className="addCriteriaButton" onClick={handleGetTemplate}>
                    <Icon type="file-done" /> Template Import {props.name}
                </div>
            </Menu.Item>
        </Menu>
    )

    const validateCheckDuplicate = (_: any, value: any, callback) => {
        const pattern = /^[A-Za-z0-9ก-๙]{1}/
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        // eslint-disable-next-line no-useless-escape
        if (Array.isArray(value)) {
            let isDuplicate = false

            if (value.some((item) => item.replace(/ +/g, '').trim() === '')) {
                callback('Default value is not valid.')
                return
            }

            if (value.some((item) => !pattern.test(item.replace(/ +/g, '').trim()))) {
                callback(`Field Label must not contain these characters: ${format}`)
                return
            }

            if (props.name === 'Asset Status') {
                isDuplicate = value.some((item) => {
                    return assetStatusAll.some((it) => it.toLowerCase().replace(/ +/g, '').trim() === item.toLowerCase().replace(/ +/g, '').trim())
                })
            } else if (props.name === 'Brand') {
                isDuplicate = value.some((item) => {
                    return brandAll.some((it) => it.toLowerCase().replace(/ +/g, '').trim() === item.toLowerCase().replace(/ +/g, '').trim())
                })
            } else if (props.name === 'Model') {
                isDuplicate = value.some((item) => {
                    return modelAll.some((it) => it.toLowerCase().replace(/ +/g, '').trim() === item.toLowerCase().replace(/ +/g, '').trim())
                })
            } else if (props.name === 'Asset Group') {
                isDuplicate = value.some((item) => {
                    return assetGroupAll.some((it) => it.toLowerCase().replace(/ +/g, '').trim() === item.toLowerCase().replace(/ +/g, '').trim())
                })
            } else if (props.name === 'Type') {
                isDuplicate = value.some((item) => {
                    return typeAll.some((it) => it.toLowerCase().replace(/ +/g, '').trim() === item.toLowerCase().replace(/ +/g, '').trim())
                })
            } else if (props.name === 'Zone') {
                isDuplicate = value.some((item) => {
                    return zoneAll.some((it) => it.toLowerCase().replace(/ +/g, '').trim() === item.toLowerCase().replace(/ +/g, '').trim())
                })
            }
            if (isDuplicate) {
                callback(`${props.name} already exists. It must be unique. Please try another one.`)
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    const handleCheckLoadingLocation = (value) => {
        setIsLoading(value)
    }

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.order === 'ascend') {
            if (sorter.field) {
                setSortBy(sorter.field + ',asc')
            } else {
                if (props.name !== 'Type' && props.name !== 'Asset Location') {
                    setSortBy('itemData,asc')
                } else if (props.name === 'Type') {
                    setSortBy('type, asc')
                } else if (props.name === 'Asset Location') {
                    setSortBy('name, asc')
                }
            }
        } else if (sorter.order === 'descend') {
            if (sorter.field) {
                setSortBy(sorter.field + ',desc')
            } else {
                if (props.name !== 'Type' && props.name !== 'Asset Location') {
                    setSortBy('itemData,asc')
                } else if (props.name === 'Type') {
                    setSortBy('type, asc')
                } else if (props.name === 'Asset Location') {
                    setSortBy('name, asc')
                }
            }
        } else {
            setSortBy('lastModifiedDatetime,desc')
        }
    }

    return (
        <div>
            <Row>
                <span style={{ color: '#323276', fontSize: '20px', fontWeight: 'bold' }}>{props.name}</span>
            </Row>
            <Row>
                {props.name === 'Type'
                    ? <Col>
                        <div style={{ marginBottom: 10, marginTop: 10 }}>
                            <span style={{ color: 'red' }}>* <span style={{ color: '#323276' }}>Group : </span></span>
                            <Dropdown overlay={() => menu()} trigger={['click']}>
                                <span style={{ color: '#323276', fontWeight: 'bold' }}>
                                    {nameAssetGroupsType} <Icon type='down' />
                                </span>
                            </Dropdown>
                        </div>
                    </Col>
                    : null}
                <Col style={{ textAlign: 'right' }}>
                    <Button type={'ghost'} className="addCriteriaButton" style={{ marginRight: 10 }} loading={isLoadingExport} onClick={() => exportItemData(props.name)}><Icon type="upload" /> Export</Button>
                    <Dropdown overlay={menuImport}>
                        <Button type='primary'> <Icon type="download" /> Import <Icon type="down" /> </Button>
                    </Dropdown>
                </Col>
            </Row>
            <Row>
                {props.name === 'Asset Location' && props.child
                    ? (
                        <Tabs defaultActiveKey='1' onChange={changeTabLocation}>
                            {props.child.map((data) => {
                                return <TabPane tab={data.label} key={data.key} >
                                    <RenderTabAssetLocationTabs
                                        dataSite={siteAll}
                                        dataLocation={locationAll}
                                        dataBuilding={buildingAll}
                                        locationTab={data.label}
                                        child={props.child!!}
                                        onFetchData={onFetchDataAssetLocation}
                                        onLoading={handleCheckLoadingLocation}
                                        currentTab={keyLocation}
                                        onImportData={loadingImportAssetLocation}
                                        onDeleteData={isDeleteAssetLocation}
                                        form={props.form}
                                    />
                                    <br />
                                    <Row>
                                        <Col span={12}>
                                            {!isDeleteItem
                                                ? <Button type="default" onClick={handleDeleteItem}>Selected Delete <Icon type='delete' /> </Button>
                                                : (
                                                    <>
                                                        <Button type='primary' onClick={handleCheckDeleteItem} disabled={selectedItemKeys.length === 0}>Selected Delete <Icon type='delete' /> ({selectedItemKeys?.length})</Button>
                                                        <Button style={{ marginLeft: 5 }} onClick={handleCancelDeleteItem}>Cancel</Button>
                                                    </>
                                                )
                                            }
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            <Form.Item>
                                                {getFieldDecorator(`search_${keyLocationLabel}`)(
                                                    <Search
                                                        placeholder={'Can Search ' + keyLocationLabel + ' Name'}
                                                        style={{ width: 300 }}
                                                        onSearch={handleSearchAssetLocation}
                                                        id={`search_${keyLocationLabel}`}
                                                        allowClear
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: 10 }}>
                                        <Table
                                            loading={isLoading}
                                            columns={getColumnAsssetLocation(keyLocation)}
                                            dataSource={dataAssetLocation}
                                            pagination={{
                                                pageSize: pageSize,
                                                current: page,
                                                total: totalAssetLocation,
                                                onChange: (event) => {
                                                    handleOnClickPageNumber(event)
                                                }
                                            }}
                                            onChange={handleTableChange}
                                            rowKey={'id'}
                                            rowSelection={isDeleteItem ? rowDeleteItemSelection : undefined}
                                        />
                                    </Row>
                                </TabPane>
                            })}
                        </Tabs>
                    ) : (
                        <Tabs defaultActiveKey='1'>
                            <TabPane tab={props.name}>
                                <Form onSubmit={handleSubmit}>
                                    <Row gutter={[10, 10]}>
                                        <Col md={18} sm={24}>
                                            <Form.Item label={props.name + ' Name'}>
                                                {getFieldDecorator(props.keyMenu,
                                                    {
                                                        rules: [
                                                            { required: true, message: `${props.name} is required` },
                                                            { validator: validateCheckDuplicate }
                                                        ]
                                                    }
                                                )(
                                                    <Select mode='tags' placeholder={props.name + ' Name'} id={'input_' + props.keyMenu} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={3} lg={3} md={3} sm={24} xs={24}>
                                            <Form.Item style={{ marginTop: 40 }}>
                                                <Button type="default" style={{ width: '-webkit-fill-available' }} htmlType="reset" onClick={handleCancel}>
                                                    Cancel
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                        <Col span={3} lg={3} md={3} sm={24} xs={24}>
                                            <Form.Item style={{ marginTop: 40 }}>
                                                <Button className="addCriteriaButton" style={{ width: '-webkit-fill-available' }} htmlType="submit">
                                                    Submit
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                <br />
                                <Row>
                                    <Col span={12}>
                                        {!isDeleteItem
                                            ? <Button type="default" onClick={handleDeleteItem}>Selected Delete <Icon type='delete' /> </Button>
                                            : (
                                                <>
                                                    <Button type='primary' onClick={handleCheckDeleteItem} disabled={selectedItemKeys.length === 0}>Selected Delete <Icon type='delete' /> ({selectedItemKeys?.length})</Button>
                                                    <Button style={{ marginLeft: 5 }} onClick={handleCancelDeleteItem}>Cancel</Button>
                                                </>
                                            )
                                        }
                                        {props.name === 'Asset Group' && isDeleteItem
                                            ? <Row> <p className={'content'} style={{ marginBottom: 0 }}>Note: Cannot delete an asset group that is in use by an asset.</p></Row>
                                            : null
                                        }
                                    </Col>
                                    <Col span={12} style={{ textAlign: 'right' }}>
                                        <Form.Item>
                                            {getFieldDecorator(`search_${props.keyMenu}`)( 
                                                <Search
                                                    placeholder={'Can Search ' + props.name}
                                                    style={{ width: 300 }}
                                                    onSearch={checkSearchTab}
                                                    id={`search_${props.keyMenu}`}
                                                    allowClear
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Table
                                        loading={isLoading}
                                        columns={checkColumnForRender(props.general)}
                                        dataSource={dataSource}
                                        pagination={{
                                            pageSize: pageSize,
                                            current: page,
                                            total: totalDataSource,
                                            onChange: (event) => {
                                                handleOnClickPageNumber(event)
                                            }
                                        }}
                                        onChange={handleTableChange}
                                        rowKey={'id'}
                                        rowSelection={isDeleteItem ? rowDeleteItemSelection : undefined}
                                    />
                                </Row>
                            </TabPane>
                        </Tabs>
                    )}
            </Row>
        </div>
    )
}

const CustomAssetSettingFields = Form.create<Props>()(CustomAssetSetting)
export default CustomAssetSettingFields
