import React, { useState } from 'react'
import ProblemsDetails from './ProblemsDetails'
import { Col, Icon, Layout, Modal, Row } from 'antd'

const { Header } = Layout
const { confirm } = Modal
const ViewPTask = () => {
    const [PTaskNumber, setPTaskNumber] = useState('')
    const leavePageModal = () => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you want to close this page ?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                window.close()
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const getPTaskNumber = (data) => {
        setPTaskNumber(data)
    }

    return (
        <div>
            <Header style={{ width: '100%', backgroundColor: '#fff', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                <Row>
                    <Col span={12}>
                        <div>
                            <h3 style={{ color: '#323276' }}>{PTaskNumber}</h3>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div style={{ textAlign: 'end' }}>
                            <Icon type="close-square" theme="filled" style={{ color: '#FA541C', fontSize: 24 }} onClick={() => leavePageModal()} />
                        </div>
                    </Col>
                </Row>
            </Header>
            <br />
            <div style={{ padding: '0px 30px'}}>
                <ProblemsDetails getPTaskNumber={getPTaskNumber}/>
            </div>
        </div>
    )
}

export default ViewPTask
