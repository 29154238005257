/* eslint-disable no-unused-expressions */
/* eslint-disable no-useless-escape */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import {
    Button,
    Card,
    Form,
    Icon,
    Input,
    InputNumber,
    Modal,
    Select,
    Spin,
    Switch,
    Table,
    Tooltip,
    Drawer,
    message
} from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { FormComponentProps } from 'antd/es/form'
import { CustomFiled, CustomFiledWithWorkFlow, mySelectOption } from './model'
// import { DndProvider } from 'react-dnd'
// import { DragableBodyRow, RNDContext } from './DragableBodyRow'
// import update from 'immutability-helper'
import { StoreState } from '../store'
import { connect } from 'react-redux'
import { getAllGeneralIncidentSetting } from '../incident-management/general-incident-setting/service'
import WoCustomForms from './WoCustomForm'
import { deleteCustomFiledWithWorkFlow, getAllCustomFiledWithWorkFlow, getAllWorkflowNoRedux } from '../serviceCatalog/workflow/Service/service'
import { WorkFlow } from '../serviceCatalog/workflow/Service/Model'
import ReactHtmlParser from 'react-html-parser'

const mapStateToProps = (state: StoreState) => {
    return {
        generalIncidentSetting: state.generalIncidentSetting,
        customFiledWithWorkFlow: state.customFiledWithWorkFlow
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllGeneralIncidentSetting: () => Promise<number>
    getAllCustomFiledWithWorkFlow: () => Promise<number>
    deleteCustomFiledWithWorkFlow: (is: string) => Promise<number>
}

type Props = FormComponentProps & StateProps & DispatchProps
const { Option } = Select
const { confirm } = Modal
const CardFiledWordFlow: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form
    const [data, setData] = useState<CustomFiledWithWorkFlow[]>([])
    const [dataSelect, setDataSelect] = useState<CustomFiledWithWorkFlow>()
    const [checkIsUpdate, setCheckIsUpdate] = useState<Boolean>(false)
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState(Object), [])
    const [isSpinning, setIsSpinning] = useState(false)
    const [isOpenCreate, setIsOpenCreate] = useState(false)
    const [workFlowList, setWorkflowList] = useState<WorkFlow[]>([])
    const [isDataEdit, setIsDataEdit] = useState<boolean>(false)

    useEffect(() => {
        setIsSpinning(true)
        fetchDataCustomfieldWorkflow()
        getAllWorkflowNoRedux().then((response) => {
            setWorkflowList(response)
            console.log(workFlowList)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        console.log(props.customFiledWithWorkFlow)
        if (props.customFiledWithWorkFlow) {
            const fetchCustomFieldData = props.customFiledWithWorkFlow?.map(it => {
                if (it.createdDatetime) { it.createdDatetime = undefined }
                it.createdBy = undefined
                it.lastModifiedBy = undefined
                it.lastModifiedDatetime = undefined
                return it
            })
            fetchCustomFieldData.sort((e1, e2) => e1.customFieldOrder!! < e2.customFieldOrder!! ? 1 : -1)
            setData(fetchCustomFieldData)
            setIsSpinning(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.customFiledWithWorkFlow])

    const AddData = () => {
        setIsOpenCreate(true)
    }

    const handleChangeLabel = (row: CustomFiled, value: string) => {
        row.label = value
        row.name = value
    }

    const handleChangeInputType = (row: CustomFiled, value, index: number) => {
        row.inputType = value
        const setValue = {}
        setValue['selectOption' + row.id + index] = undefined
        setValue['defaultValue' + row.id + index] = undefined
        setValue['placeholder' + row.id + index] = undefined
        row.defaultValue = ''
        row.selectOption = []
        row.placeholder = ''
        if (value === 'Checkbox' || value === 'DropdownSelect' || value === 'RadioButton') {
            setValue['dataType' + row.id + index] = 'String'
            row.dataType = 'String'
            setFieldsValue(setValue)
        } else if (value === 'DatePicker') {
            setValue['dataType' + row.id + index] = 'Date'
            row.dataType = 'Date'
            setFieldsValue(setValue)
        } else if (value === 'InputNumber') {
            setValue['dataType' + row.id + index] = 'Number'
            row.dataType = 'Number'
            setValue['defaultValue' + row.id + index] = 0
            row.defaultValue = 0
            setFieldsValue(setValue)
        } else {
            setValue['dataType' + row.id + index] = undefined
            row.dataType = undefined
            setFieldsValue(setValue)
        }
    }

    const handleChangeSelectOption = (row: CustomFiled, value) => {
        const addDataOption: mySelectOption[] = value.map((it) => {
            return {
                option: it,
                moduleName: 'Incident'
            }
        })
        row.selectOption = addDataOption
    }

    const handleChangeDefaultValue = (row: CustomFiled, value: string | number) => {
        row.defaultValue = value
    }

    const validateLabel = (_: any, value: any, callback) => {
        if (value) {
            // eslint-disable-next-line
            var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            if (format.test(value)) {
                // eslint-disable-next-line
                callback('Field Label can' + 't contain any of the following characters ' + format)
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    const validateMaxTag = (_: any, value: any, callback) => {
        if (value) {
            const valueIncorrect = (value || []).filter((it) => it.length >= 256)
            if (valueIncorrect.length !== 0) {
                callback('error')
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row, data, index) => (
                <>
                    <Tooltip placement="bottom" title={'Delete'}><Icon className="edit_icon" type="delete"
                        onClick={() => deleteRow(index, row)} /></Tooltip>
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row, data, index) => (
                <>
                    <Tooltip placement="bottom" title={'Edit'}><Icon className="del_icon" type="edit"
                        onClick={() => EditRow(row, index)} /></Tooltip>
                </>
            )
        },
        {
            title: <span><span className={'requiredIcon'}>* </span>Field label</span>,
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('label' + row.id + index, {
                            initialValue: row.label,
                            rules: [
                                {
                                    required: true, message: 'Field Label is required.'
                                },
                                {
                                    validator: validateLabel
                                }
                            ]
                        })(
                            <Input onChange={(e) => handleChangeLabel(row, e.target.value)} disabled={true} />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: <span> Field description</span>,
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('description' + row.id + index, {
                            initialValue: row.description
                        })(
                            <Input onChange={(e) => handleChangeLabel(row, e.target.value)} disabled={true} />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: <span><span className={'requiredIcon'}>* </span>Input type</span>,
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('inputType' + row.id + index, {
                            initialValue: row.inputType,
                            rules: [{ required: true, message: 'Input Type is required.' }]
                        })(
                            <Select onChange={(e) => handleChangeInputType(row, e, index)} allowClear disabled={true}>
                                <Option value="Checkbox">Checkbox</Option>
                                <Option value="DatePicker">Date picker</Option>
                                <Option value="DropdownSelect">Dropdown Select</Option>
                                <Option value="InputNumber">Input number</Option>
                                <Option value="RadioButton">Radio button</Option>
                                <Option value="TextBox">Text box</Option>
                            </Select>
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Default value',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('defaultValue' + row.id + index, {
                            initialValue: getFieldValue('dataType' + row.id + index) === 'Number' ? Number(row.defaultValue) : row.defaultValue,
                            rules: [
                                {
                                    type: getFieldValue('inputType' + row.id + index) === 'Checkbox' || getFieldValue('inputType' + row.id + index) === 'DatePicker' || getFieldValue('inputType' + row.id + index) === 'RadioButton'
                                        ? undefined
                                        : getFieldValue('dataType' + row.id + index)
                                            ? (getFieldValue('dataType' + row.id + index) as String).toLowerCase()
                                            : undefined,
                                    message: 'Default value is not valid.'
                                }, {
                                    required: false
                                }
                            ]
                        })(
                            getFieldValue('dataType' + row.id + index) === 'Number'
                                ? <InputNumber min={0} onChange={(e) => handleChangeDefaultValue(row, e!!)}
                                    style={{ width: '100%' }} defaultValue={0}
                                    disabled={true} />
                                : <Input onChange={(e) => handleChangeDefaultValue(row, e.target.value)}
                                    disabled={true} />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: <span>Select option</span>,
            dataIndex: '',
            key: '',
            render: (row, data, index) => {
                const tempdatashow: string[] = []
                const tempdatatooltips: string[] = []
                let numberSelectoption: string | null = null
                // eslint-disable-next-line array-callback-return
                data.selectOption.map((it, idx) => {
                    if (idx < 3) {
                        tempdatashow.push(it.option)
                    } else {
                        tempdatatooltips.push('- ' + it.option)
                    }
                })

                if (data.selectOption.length > 3) {
                    numberSelectoption = '+' + String(Number(data.selectOption.length) - 3)
                }

                const tooltipsSelectoption = () => {
                    return <Tooltip placement="bottom" title={ReactHtmlParser(tempdatatooltips.join('</br>'))}>{numberSelectoption}</Tooltip>
                }
                return (
                    <div key={index} >
                        {/* <Tooltip placement="bottom" title={tempdatatooltips.length > 0 ? ReactHtmlParser(tempdatatooltips.join('</br>')) : null}> */}
                        <Form.Item>
                            {getFieldDecorator('selectOption' + data.id + index, {
                                initialValue: tempdatashow.length > 0 ? numberSelectoption ? [...tempdatashow, tooltipsSelectoption()] : tempdatashow : undefined,
                                rules: [{
                                    required: (getFieldValue('inputType' + data.id + index) === 'DropdownSelect' || getFieldValue('inputType' + data.id + index) === 'Checkbox' || getFieldValue('inputType' + data.id + index) === 'RadioButton'),
                                    message: 'Select an option is required.'
                                }, {
                                    validator: validateMaxTag
                                }]
                            })(
                                <Select
                                    disabled={true}
                                    mode="tags" onChange={(e => handleChangeSelectOption(row, e))} />
                            )}
                        </Form.Item>
                        {/* </Tooltip> */}
                    </div>)
            }

        },
        {
            title: <span><span className={'requiredIcon'}>* </span>Workflow</span>,
            dataIndex: '',
            key: '',
            render: (row, data, index) => {
                const workflowname: string[] = []
                const workflowTooltip: string[] = []
                let numberWorkflow: string | null = null
                data.workflows?.map((it, idx) => {
                    if (idx < 2) {
                        workflowname.push(it.name)
                    } else {
                        workflowTooltip.push('- ' + it.name)
                    }
                })
                if (data.workflows.length > 2) {
                    numberWorkflow = '+' + String(Number(data.workflows.length) - 2)
                }
                const tooltipsWorkflow = () => {
                    return <Tooltip placement="bottom" title={ReactHtmlParser(workflowTooltip.join('</br>'))}>{numberWorkflow}</Tooltip>
                }
                return <div key={index}>
                    {/* <Tooltip placement="bottom" title={ReactHtmlParser(workflowTooltip.join('</br>'))}> */}
                    <Form.Item>
                        {getFieldDecorator('workflow' + row.id + index, {
                            initialValue: workflowname ? numberWorkflow ? [...workflowname, tooltipsWorkflow()] : workflowname : undefined
                        })(
                            <Select
                                disabled={true}
                                mode="tags" onChange={(e => handleChangeSelectOption(row, e))} />
                        )}
                    </Form.Item>
                    {/* </Tooltip> */}
                </div>
            }
        },
        {
            title: 'Require field',
            dataIndex: '',
            key: '',
            render: (row) => (
                <>
                    <Tooltip placement="bottom" title={row.isRequireFields ? 'Deactivate' : 'Activate'} >
                        <Switch checked={row.isRequireFields} onChange={() => toggleActiveness(row.isRequiredField)} disabled={true} />
                    </Tooltip>
                </>
            )
        }
    ]

    const EditRow = (row, index) => {
        setIsOpenCreate(true)
        setDataSelect(row)
        setIsDataEdit(true)
    }

    const deleteRow = (index, row) => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete this data?',
            okText: 'OK',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'Cancel',
            async onOk() {
                // const tempData2 = data.filter((obj) => obj?.key !== row.key)
                // setData(tempData2)
                props.deleteCustomFiledWithWorkFlow(row.id)
                message.success('The data has been deleted successfully.')
            },
            onCancel() {
            }
        })
    }

    const toggleActiveness = (data: CustomFiled) => {
        data.isRequireFields = !data.isRequireFields
        setCheckIsUpdate(!checkIsUpdate)
        forceUpdate()
    }

    // const menu = () => {
    //     return (
    //         <Menu>
    //             {props.generalIncidentSetting?.ticketTypes?.map((it, index) => {
    //                 return (<Menu.Item key={index} onClick={() => setTicketTypes(it.value)}>
    //                     <span>{it.value}</span>
    //                 </Menu.Item>)
    //             })}
    //         </Menu>
    //     )
    // }

    // const setTicketTypes = (text: string) => {
    //     setSelectTicketType(text!!)
    // }

    // const components = {
    //     body: {
    //         row: DragableBodyRow
    //     }
    // }

    // const moveRow = useCallback(
    //     (dragIndex, hoverIndex) => {
    //         const dragRow = data[dragIndex]
    //         setData(
    //             update(data, {
    //                 $splice: [
    //                     [dragIndex, 1],
    //                     [hoverIndex, 0, dragRow]
    //                 ]
    //             })
    //         )
    //     },
    //     [data]
    // )

    // const manager = useRef(RNDContext)

    const renderTable = () => {
        return (
            // <DndProvider manager={manager.current.dragDropManager!!}>
            <Spin spinning={isSpinning}>
                <Table
                    columns={columns}
                    dataSource={data}
                    // components={components}
                    rowKey='id'
                    // onRow={(record, index) => ({
                    //     index,
                    //     moveRow
                    // })}
                    scroll={{ x: 768 }}
                />
            </Spin>
            // </DndProvider>
        )
    }

    const onCloseCreate = () => {
        confirm({
            title: 'Are you sure ?',
            content: 'Do you want to leave this page ? ',
            okText: 'OK',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'Cancel',
            async onOk() {
                setIsOpenCreate(false)
                setIsDataEdit(false)
            },
            onCancel() {
            }
        })
    }

    const fetchDataCustomfieldWorkflow = () => {
        props.getAllCustomFiledWithWorkFlow().then((res) => {
            setCheckIsUpdate(false)
        }).catch((_err) => {
            setCheckIsUpdate(true)
            setData([])
        }).finally(() => {
            setIsSpinning(false)
        })
    }

    return (
        <>
            <Card style={{ overflow: 'auto' }}>
                <h3 className={'main-title text-herder-Orenge'}>Custom Field Setting</h3>
                {/* <div style={{ marginBottom: 10 }}>
                    <span style={{ color: 'red' }}>* <span style={{ color: '#323276' }}>Ticket Type : </span></span>
                    <Dropdown overlay={() => menu()} trigger={['click']}>
                        <span style={{ color: '#323276', fontWeight: 'bold' }}>
                            {selectTicketType} <Icon type="down" />
                        </span>
                    </Dropdown>
                </div> */}
                <Button onClick={() => AddData()} htmlType="button" className="addCriteriaButton">Add</Button>
                <br /><br />
                <Form>
                    {renderTable()}
                </Form>
                <Drawer
                    title={`${isDataEdit ? 'Edit' : 'Add'} Custom field setting WorkOrder`}
                    placement="right"
                    closable={false}
                    onClose={onCloseCreate}
                    width={350}
                    visible={isOpenCreate}
                    maskClosable={false}
                >
                    <WoCustomForms setIsOpenCreate={setIsOpenCreate} isOpenCreate={isOpenCreate} dataEdit={dataSelect} fetchDataCustomfield={fetchDataCustomfieldWorkflow} isDataEdit={isDataEdit} setIsDataEdit={setIsDataEdit} />
                </Drawer>
            </Card>
        </>
    )
}

const CardFiledWordFlows = Form.create<Props>()(CardFiledWordFlow)
export default connect(mapStateToProps, {
    getAllGeneralIncidentSetting,
    getAllCustomFiledWithWorkFlow,
    deleteCustomFiledWithWorkFlow
})(CardFiledWordFlows)
