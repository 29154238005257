import React from 'react'
import { ProblemRelateByTicket } from '../../../problem-management/problems/Model'
import { Row, Col, Tooltip, Tag, Badge, Button, Icon, message, Modal } from 'antd'
import { deleteRelateProblemByTicketId } from '../../../problem-management/problems/service'

interface Param {
    data?: ProblemRelateByTicket
    setIsDeleted?: Function
}

type Props = Param
const { confirm } = Modal
const DisplayProblem: React.FC<Props> = (props: Props) => {
    const getTagColorByStatus = (status: string) => {
        if (status === 'New') {
            return '#00c3ff'
        } else if (status === 'Assess') {
            return '#FFC107'
        } else if (status === 'Root Cause Analysis') {
            return '#FD7E14'
        } else if (status === 'Fix In Progress') {
            return '#6F42C1'
        } else if (status === 'Resolved') {
            return '#28A745'
        } else if (status === 'Closed') {
            return '#2848a7'
        } else if (status === 'Cancelled') {
            return '#6C757D'
        }
    }

    const deleteProblemRelateTicket = (id) => {
        confirm({
            title: 'Are you sure ?',
            content: 'Do you confirm that you want to delete this data ?',
            onOk() {
                if (id) {
                    deleteRelateProblemByTicketId(id).then(() => {
                        message.success('The data has been deleted successfully.')
                        if (props.setIsDeleted) {
                            props.setIsDeleted(true)
                        }
                    })
                }
            },
            onCancel() { }
        })
    }

    return (
        <div>
            <div style={{ padding: 15, boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgb(0 0 0 / 1%) 0px 6px 20px 0px', borderRadius: '5px', marginBottom: 10 }}>
                <div style={{ backgroundColor: props.data?.pbStatus === 'Assess' ? '#FFFAF1' : '#F5F9FF', borderRadius: '5px', padding: 10 }}>
                    <Row>
                        <Col span={6} xs={24} sm={24} md={6} lg={6} xl={6}>
                            <Tooltip title={props.data?.topic}><div style={{ color: '#F6712E' }}> <b>{props.data?.number} : {(props.data?.topic || '')?.length > 30 ? props.data?.topic.slice(0, 30) + ' ...' : props.data?.topic}</b></div></Tooltip>
                        </Col>
                        <Col span={18} style={{ textAlign: 'end' }} xs={24} sm={24} md={18} lg={18} xl={18}>
                            {props.data?.pbCloseReasonStatusDetail ? <Tag color={props.data?.pbCloseReasonStatusDetail?.tagColor || ''}>{props.data?.pbCloseReasonStatusDetail.name}</Tag> : null}
                            {props.data?.pbStatus === 'Assess' ? <Tag color='#ED9E28'>Awaiting Approval</Tag> : null}
                            {props.data?.viewed === true ? <Tag color="green">New</Tag> : null}
                            <Button htmlType='button' size='small' onClick={(e) => { deleteProblemRelateTicket(props.data?.id) }}><Icon type="delete" /></Button>
                        </Col>
                    </Row>
                </div>
                <div style={{ padding: 10 }}>
                    <div>
                        <Row>
                            <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Row>
                                    <Col span={4} xs={6} sm={6} md={4} lg={4} xl={4}>
                                        <p><b>Status : </b></p>
                                    </Col>
                                    <Col span={20} xs={18} sm={18} md={20} lg={20} xl={20}>
                                        <p> <Tag style={{ borderRadius: '10px' }}><Badge color={getTagColorByStatus(props.data?.pbStatus!!)} text={props.data?.pbStatus} /></Tag></p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Row>
                                    <Col span={4} xs={7} sm={7} md={4} lg={4} xl={4}>
                                        <p><b>Priority : </b></p>
                                    </Col>
                                    <Col span={20} xs={17} sm={17} md={20} lg={20} xl={20}>
                                        <p>{props.data?.pbPriority ? <Tag style={{ borderRadius: '10px' }} color={props.data?.pbPriority.tagColor}>{props.data?.pbPriority.name}</Tag> : null}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Row>
                                    <Col span={4} xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <p><b>Assignment Group : </b></p>
                                    </Col>
                                    <Col span={20} xs={18} sm={18} md={18} lg={18} xl={18}>
                                        <p> {props.data?.assignmentGroup} </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Row>
                                    <Col span={4} xs={6} sm={6} md={4} lg={4} xl={4}>
                                        <p><b>Assigned To : </b></p>
                                    </Col>
                                    <Col span={20} xs={18} sm={18} md={20} lg={20} xl={20}>
                                        <p> {props.data?.assignedTo || '-'} </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Row>
                                    <Col span={4} xs={7} sm={7} md={4} lg={4} xl={4}>
                                        <span><b>Start Date : </b></span>
                                    </Col>
                                    <Col span={20} xs={17} sm={17} md={20} lg={20} xl={20}>
                                        <span> {props.data?.startDate}</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Row>
                                    <Col span={4} xs={6} sm={6} md={4} lg={4} xl={4}>
                                        <span><b>Due Date : </b></span>
                                    </Col>
                                    <Col span={20} xs={18} sm={18} md={20} lg={20} xl={20}>
                                        <span> {props.data?.endDate}</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DisplayProblem
