import { Card, Col, Dropdown, Empty, Icon, Menu, Modal, Row, Spin } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import DisplayWorkNote from './Related/DisplayWorkNote'
import DisplayWorkLog from './Related/DisplayWorkLog'
import { AssetRelatedContext } from '../../../Preventive/ContextAPI/IncidentTicketRelatedContext'
import { TicketRelatedContext } from '../../../Preventive/ContextAPI/TicketRelatedContext'
import { KnowledgeRelatedContext } from '../../../Preventive/ContextAPI/KnowledgeRelatedContext'
import DisplayAsset from './Related/DisplayAsset'
import DisplayKnowledge from './Related/DisplayKnowledge'
import DisplyTicket from './Related/DisplyTicket'
import { Problem, ProblemStatus } from '../Model'
import ProblemTaskManament from './Related/ProblemTask/ProblemTaskManament'
import AnalysisList from './Related/AnalysisAndSolution/AnalysisList'
import { getRelateAssetAndProblems, getRelateKnowledgeAndProblems, getRelateTicketAndProblems } from '../service'
import SelectRelateDataTableProblems from './Related/SelectRelateDataTableProblems'
import { checkRolesState, RoleType } from '../../../authorization-module/permissions'
import NumberOFPTask from './SingletonClass/NumberOFPTask'

interface Params {
    id: string
    getTabName: Function
    reloadWorknoteMain: Function
    isRenderPTask: boolean
    PTaskLength: number
    getPTask: Function
    getStatus?: ProblemStatus
    problem?: Problem
    isRootCause?: boolean
    getIsReloadWorknote?: Function
}

type Props = Params
const { confirm } = Modal
const TabDetailViewProblem = (props: Props) => {
    const [tabName, setTabName] = useState('')
    const [isLoadingAsset, setIsLoadingAsset] = useState(false)
    const [isLoadingTicket, setIsLoadingTicket] = useState(false)
    const [isLoadingKnowledge, setIsLoadingKnowledge] = useState(false)
    const [isModalVissible, setIsModalVisible] = useState<boolean>(false)
    const [isCancelModal, setIsCancelModal] = useState<boolean>(false)
    const [isNonApproved, setIsNonApproved] = useState(false)
    const [countPTask, setCountPTask] = useState(props.PTaskLength)
    const { GetAssetRelated, AssetRelatedState } = useContext<any>(AssetRelatedContext)
    const { GetTicketRelated, TicketRelatedState } = useContext<any>(TicketRelatedContext)
    const { GetKnowledgeRelated, KnowledgeRelatedState } = useContext<any>(KnowledgeRelatedContext)
    const isPTask = window.location.pathname.includes('ProblemTaskDetailView')

    useEffect(() => {
        if (props.problem) {
            if (props.problem.pbStatus.status === 'Assess') {
                setIsNonApproved(true)
            } else {
                setIsNonApproved(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.problem])

    useEffect(() => {
        props.getTabName(tabName)
        if (tabName === 'Ticket') {
            setIsLoadingTicket(true)
            getRelateTicketAndProblems(props.id).then((data) => {
                const convertData = data.map((it) => {
                    return {
                        relateId: it.id,
                        id: it.ticket.id,
                        ticketNumber: it.ticket.ticketNumber,
                        topic: it.ticket.topic,
                        priority: it.ticket.priority,
                        ticketStatus: it.ticket.ticketStatus,
                        requesterName: it.ticket.requesterName,
                        active: it.active
                    }
                })
                GetTicketRelated(convertData)
            }).finally(() => { setIsLoadingTicket(false) })
        } else if (tabName === 'Asset') {
            setIsLoadingAsset(true)
            getRelateAssetAndProblems(props.id).then((data) => {
                const convertData = data.map((it) => {
                    return {
                        relateId: it.id,
                        id: it?.asset?.id || it.id,
                        serialNumber: it?.asset?.serialNumber || it.serialNumber,
                        status: it?.asset?.status || it.status,
                        assetName: it?.asset?.assetName || it.assetName,
                        assetGroup: it?.asset?.assetGroup || it.assetGroup || it.assetGroupName,
                        site: it?.asset?.site || it.site,
                        location: it?.asset?.location || it.location,
                        building: it?.asset?.building || it.building,
                        floor: it?.asset?.floor || it.floor,
                        people: it?.asset?.people || it.people,
                        pbAssetOriginal: it.pbAssetOriginal,
                        active: it.active
                    }
                })
                console.log(convertData)
                GetAssetRelated(convertData)
            }).finally(() => { setIsLoadingAsset(false) })
        } else if (tabName === 'Knowledge') {
            setIsLoadingKnowledge(true)
            getRelateKnowledgeAndProblems(props.id, props.isRenderPTask).then((data) => {
                const convertData = data.map((it) => {
                    return {
                        relateId: it.id,
                        id: it.knowledge.id,
                        kmNumber: it.knowledge.kmNumber,
                        topic: it.knowledge.topic,
                        helpful: it.knowledge.helpful,
                        notHelpful: it.knowledge.notHelpful,
                        views: it.knowledge.views,
                        used: it.knowledge.used,
                        createdBy: it.knowledge.createdBy,
                        active: it.active
                    }
                })
                GetKnowledgeRelated(convertData)
            }).finally(() => { setIsLoadingKnowledge(false) })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabName, props.id])

    useEffect(() => {
        setCountPTask(props.PTaskLength)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.PTaskLength])

    const menu = (
        <Menu>
            <Menu.Item onClick={() => setTabName('Asset')}>
                Asset
            </Menu.Item>
            <Menu.Item onClick={() => setTabName('Knowledge')}>
                Knowledge
            </Menu.Item>
            <Menu.Item onClick={() => setTabName('Ticket')}>
                Ticket
            </Menu.Item>
        </Menu>
    )

    const CardHerder = (
        <div style={{ backgroundColor: '#f5f5f5', padding: 5, borderRadius: '10px' }}>
            <Row>
                <Col span={3} style={{ cursor: 'pointer', textAlign: 'center' }} xs={12} sm={12} lg={3} xl={3}>
                    {isPTask ? <div style={{ cursor: 'pointer', textAlign: 'center' }} onClick={() => setTabName('Knowledge')}>
                        <div style={{ padding: 6, margin: 4, borderRadius: '5px', background: tabName === 'Knowledge' ? '#E3E3E3' : '#f5f5f5' }}><Icon type="file-add" /> Knowledge</div>
                    </div> : <Dropdown overlay={menu}>
                        <div style={{ padding: 6, margin: 4, borderRadius: '5px' }}><Icon type="apartment" /> &nbsp; Related &nbsp; <Icon type="down" /></div>
                    </Dropdown>}
                </Col>
                <Col span={3} style={{ cursor: 'pointer', textAlign: 'center', borderRight: '1px solid #bfbfbf', borderLeft: '1px solid #bfbfbf' }} onClick={() => setTabName('WorkNote')} xs={12} sm={12} lg={3} xl={3}>
                    <div style={{ padding: 6, margin: 4, borderRadius: '5px', background: tabName === 'WorkNote' ? '#E3E3E3' : '#f5f5f5' }}><Icon type="file-add" /> Work Note</div>
                </Col>
                <Col span={3} style={{ cursor: 'pointer', textAlign: 'center' }} onClick={() => setTabName('WorkLog')} xs={12} sm={12} lg={3} xl={3}>
                    <div style={{ padding: 6, margin: 4, borderRadius: '5px', background: tabName === 'WorkLog' ? '#E3E3E3' : '#f5f5f5' }}><Icon type="clock-circle" /> Work Log</div>
                </Col>
                {(props?.problem?.pbStatus?.status === 'Root Cause Analysis' || props?.problem?.pbStatus?.status === 'Fix In Progress' || props?.problem?.pbStatus?.status === 'Resolved' || props?.problem?.pbStatus?.status === 'Closed') && props.isRenderPTask === false ? <Col span={4} style={{ cursor: 'pointer', textAlign: 'center', borderLeft: '1px solid #bfbfbf' }} onClick={() => setTabName('ProblemTask')} xs={12} sm={12} lg={4} xl={4}>
                    <div style={{ padding: 6, margin: 4, borderRadius: '5px', background: tabName === 'ProblemTask' ? '#E3E3E3' : '#f5f5f5' }}><Icon type="container" /> Problem Task ({NumberOFPTask.getInstance().data || countPTask}) {props.problem.pbStatus.status === 'Root Cause Analysis' ? <Icon type="exclamation-circle" style={{ color: 'red' }} /> : null}</div>
                </Col> : null}
                {(props?.problem?.pbStatus?.status === 'Root Cause Analysis' || props?.problem?.pbStatus?.status === 'Fix In Progress' || props?.problem?.pbStatus?.status === 'Resolved' || (props?.problem?.pbStatus?.status === 'Closed' && props.isRenderPTask === false)) || (props.isRenderPTask === true && props?.isRootCause === true) ? <Col span={4} style={{ cursor: 'pointer', textAlign: 'center', borderLeft: '1px solid #bfbfbf' }} onClick={() => setTabName('Analysis')} xs={12} sm={12} lg={4} xl={4}>
                    <div style={{ padding: 6, margin: 4, borderRadius: '5px', background: tabName === 'Analysis' ? '#E3E3E3' : '#f5f5f5' }}><Icon type="audit" /> Analysis / Solution {props.problem?.pbStatus.status === 'Root Cause Analysis' ? <Icon type="exclamation-circle" style={{ color: 'red' }}/> : null }</div>
                </Col> : null}
            </Row>
        </div>
    )

    const showModal = (value: string) => {
        if (props.isRenderPTask) {
            setIsModalVisible(true)
        } else {
            if (!isNonApproved) {
                setIsModalVisible(true)
            }
        }
    }

    const closeModal = () => {
        confirm({
            title: <b style={{ color: '#323276', fontSize: '25px', fontWeight: 'bold' }}>Are you sure?</b>,
            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>Do you want to leave this page ?</span>,
            okText: 'OK',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'Cancel',
            className: 'custom-modal-wo',
            onOk() {
                setIsModalVisible(false)
                setIsCancelModal(true)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const reloadWorknote = (isReload: boolean) => {
        props.reloadWorknoteMain(isReload)
        if (props.getIsReloadWorknote) {
            props.getIsReloadWorknote(isReload)
        }
    }

    const getPTask = (data) => {
        props.getPTask(data)
    }

    const getCountPTask = (data) => {
        setCountPTask(data)
    }

    return (
        <div>
            <Card title={CardHerder} style={{ borderRadius: '10px', minHeight: '625px', maxHeight: '625px' }} bodyStyle={{ maxHeight: '645px', overflow: 'auto' }}>
                {tabName === '' ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : null}
                {tabName === 'Asset' ? <div className='display-scrollbar-height500'>
                    <div style={{ textAlign: 'end', paddingRight: 12, marginBottom: 5 }}>
                        {props.problem?.pbStatus.status === 'Cancelled' || props.problem?.pbStatus.status === 'Closed' ? null : !isNonApproved || !checkRolesState(RoleType.Problem, 'UpdateProblem') ? <span className="feck-link" onClick={() => { showModal(tabName) }} style={{ fontSize: '16px', fontWeight: 'bold' }}>Select {tabName}</span> : null}
                    </div>
                    {AssetRelatedState?.length > 0 ? <div>
                        <Spin spinning={isLoadingAsset}>
                            {AssetRelatedState?.map((it, index) => {
                                return <div key={index}>
                                    <DisplayAsset it={it} isNonApproved={isNonApproved} problems={props.problem}/>
                                </div>
                            })}
                        </Spin>
                    </div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </div> : null}
                {tabName === 'Knowledge' ? <div className='display-scrollbar-height500'>
                    <div style={{ textAlign: 'end', paddingRight: 12, marginBottom: 5 }}>
                        {props.isRenderPTask ? <span className="feck-link" style={{ fontSize: '16px', fontWeight: 'bold', display: props.problem?.pbStatus.status === 'Closed' ? 'none' : 'block' }} onClick={() => { showModal(tabName) }} >Select {tabName}</span> : props.problem?.pbStatus.status === 'Cancelled' || props.problem?.pbStatus.status === 'Closed' ? null
                            : !isNonApproved || !checkRolesState(RoleType.Problem, 'UpdateProblem') ? <span className="feck-link" onClick={() => { showModal(tabName) }} style={{ fontSize: '16px', fontWeight: 'bold' }}>Select {tabName}</span> : null}
                    </div>
                    {KnowledgeRelatedState?.length > 0 ? <div>
                        <Spin spinning={isLoadingKnowledge}>
                            {KnowledgeRelatedState?.map((it, index) => {
                                return <div key={index}>
                                    <DisplayKnowledge it={it} isNonApproved={isNonApproved} problems={props.problem}/>
                                </div>
                            })}
                        </Spin>
                    </div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </div> : null}
                {tabName === 'Ticket' ? <div className='display-scrollbar-height500'>
                    <div style={{ textAlign: 'end', paddingRight: 12, marginBottom: 5 }}>
                        {props.problem?.pbStatus.status === 'Cancelled' || props.problem?.pbStatus.status === 'Closed' ? null : !isNonApproved || !checkRolesState(RoleType.Problem, 'UpdateProblem') ? <span className="feck-link" onClick={() => { showModal(tabName) }} style={{ fontSize: '16px', fontWeight: 'bold' }}>Select {tabName}</span> : null}
                    </div>
                    {TicketRelatedState?.length > 0 ? <div>
                        <Spin spinning={isLoadingTicket}>
                            {TicketRelatedState?.map((it, index) => {
                                return <div key={index}>
                                    <DisplyTicket it={it} isNonApproved={isNonApproved} problems={props.problem}/>
                                </div>
                            })}
                        </Spin>
                    </div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </div> : null}
                {tabName === 'ProblemTask' ? <div>
                    <ProblemTaskManament problem={props.problem} getPTask={getPTask} getCountPTask={getCountPTask} />
                </div> : null}
                {tabName === 'Analysis' ? <div>
                    <AnalysisList problem={props.problem} isRenderPTask={props.isRenderPTask} />
                </div> : null}
                {tabName === 'WorkNote' ? <div>
                    <DisplayWorkNote id={props.id} reloadWorknote={reloadWorknote} isNonApproved={isNonApproved} isRenderPTask={props.isRenderPTask} problem={props.problem} />
                </div> : null}
                {tabName === 'WorkLog' ? <div>
                    <DisplayWorkLog problemNumber={props.problem?.number || ''} />
                </div> : null}
                <Modal
                    title={tabName === 'Workflow' ? tabName : tabName + ' List'}
                    visible={isModalVissible}
                    onCancel={() => closeModal()}
                    closable={true}
                    footer={null}
                    width={'90%'}
                >
                    <SelectRelateDataTableProblems modalVisisle={isModalVissible} setModalvisible={setIsModalVisible} tabpaneName={tabName} isCancelModal={isCancelModal} setIsCancelModal={setIsCancelModal} isFromProblemDetailView={true} />
                </Modal>
            </Card>
        </div>
    )
}

export default TabDetailViewProblem
