/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { AutoComplete, Button, Card, Col, Form, Icon, Input, message, Modal, Row, Switch, Upload } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormComponentProps } from 'antd/lib/form'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'
import { connect } from 'react-redux'
import { Comment, CommentType } from './model'
import { addComment } from './service'
import { StoreState } from '../../store'
import { UploadFile } from 'antd/lib/upload/interface'
import { getAllSupportTeamByPermission } from '../support-team'
import AutoAvatar from '../../common-components/autoAvatar'
import { getAllEmailGroupByCurrentUser } from '../support-team/service'
import { checkRolesState, loadRolesState, RoleType } from '../../authorization-module/permissions'
import { updateIncident } from '../incident/service'
import { Incident } from '../incident'
import { UploadLink } from '../../knowledge-management/knowledge/uploads/model'
import FSS from '../../file-server-storage'
import moment from 'moment'
import { DownloadAnyFile, tokenDecode } from '../../common-misc'
import exportIcon from '../../common-file/icon-file'

const mapStateToProps = (state: StoreState) => {
    return {
        incident: state.incidentState.incident,
        numberOfComments: state.comments.length,
        roleAndPermissions: state.roleAndPermissions,
        comments: (state.comments || [])
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    addComment: (comment: Comment) => Promise<number>
    getAllSupportTeamByPermission: () => Promise<number>
    loadRolesState: () => Promise<number>
    updateIncident: (incident: Incident) => Promise<number>
}

interface Params {
    incidentId: string
    requesterEmail?: string
    ticketNumber?: string
    requester?: string
    topic?: string
    description?: string
    createdDatetime?: string
    isDisableStatus: boolean
    isSmall: boolean
    isDisableAddResolution: boolean
}

type Props = StateProps & DispatchProps & Params & FormComponentProps

const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link'],
        ['clean']
    ]
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet', 'indent',
    'link'
]
const idTenantFreshService = process.env.REACT_APP_ID_TENANT_FRESH_SERVICE
const fileSizeMax = Number(process.env.REACT_APP_FILE_SIZE_ALL_UPLOAD)
const CommentForm: React.FC<Props> = (props: Props) => {
    const [showReply, setShowReply] = useState(false)
    const [showForward, setShowForward] = useState(false)
    const [showAddNote, setShowAddNote] = useState(true)
    const [, setShowDiscuss] = useState(false)
    const [text, setText] = useState<string>('')
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [isDisable, setIsDisable] = useState<boolean>(false)
    const [visibleToCustomer, setVisibleToCustomer] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [progressUpload, setProgressUpload] = useState<number>(0)
    const [dataFrom, setDataFrom] = useState<string[]>([])
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewImage, setPreviewImage] = useState<string>()
    // const previewImage = undefined
    const [isResolution, setIsResolution] = useState<boolean>(false)
    const [isCommentIdResolution, setIsCommentIdResolution] = useState<boolean>(false)
    let defaultText: string = ''
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const splitIdTenantFreshService = idTenantFreshService.split(',')
    const isTanentFreshService = splitIdTenantFreshService.includes(tokenDecode()?.tenantID.toString())

    useEffect(() => {
        getDataFrom()
        if (props.roleAndPermissions.length === 0) {
            props.loadRolesState()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect(() => {
    //     props.comments.forEach((comment) => {
    //         if (comment.isResolution) {
    //             setIsCommentIdResolution(true)
    //         }
    //     })
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [props.comments])

    useEffect(() => {
        if (props.incident && props.incident.resolution !== null) {
            setIsCommentIdResolution(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.incident])

    const getDataFrom = () => {
        const emailTeam: string[] = []
        const profileEmail: string = sessionStorage.getItem('username')!!
        getAllEmailGroupByCurrentUser().then((res) => {
            if (res) {
                res.forEach(element => {
                    if (element) {
                        emailTeam.push(element)
                    }
                })
                emailTeam.push(profileEmail)
                setDataFrom(emailTeam.filter((it) => !!it))
            }
        }).catch(() => {
            emailTeam.push(profileEmail)
            setDataFrom(emailTeam.filter((it) => !!it))
        })
    }
    const toggleShowReply = () => {
        setShowReply(!showReply)
        setShowForward(false)
        setShowAddNote(false)
        setShowDiscuss(false)
        defaultText = `Hi ${props.requester}, <br />
        Regarding to your ticket : ${props.ticketNumber}

        `
        setText(defaultText)
        clearAll()
    }

    const toggleShowForward = () => {
        setShowReply(false)
        setShowForward(!showForward)
        setShowAddNote(false)
        setShowDiscuss(false)
        // eslint-disable-next-line camelcase
        defaultText = `Created Incident : ${moment(props.createdDatetime).format('YYYY-MM-DD HH:mm:ss')}, ${props.requesterEmail!!} wrote: <br />
        Refer to ticket :  ${props.ticketNumber} <br />
        Topic: ${props.topic} <br />
        Description: ${props.description}
       
        `
        setText(defaultText)
        clearAll()
    }

    const toggleShowAddNote = () => {
        setShowReply(false)
        setShowForward(false)
        setShowAddNote(!showAddNote)
        setShowDiscuss(false)
        setVisibleToCustomer(false)
        setText(defaultText)
        clearAll()
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // eslint-disable-next-line handle-callback-err
        props.form.validateFieldsAndScroll((error) => {
            if (!error) {
                setIsLoading(true)
                const CheckValue = (text as string).replace(/<[^>]*>/g, '')
                if (CheckValue === '' || CheckValue.replace(/ +/g, '').trim() === '') { validateInput(text) } else {
                    validateInput(text)
                    if (fileList.length === 0) {
                        AddDataToBackend(fileList)
                        setIsDisable(true)
                    } else {
                        if (fileList.length <= 25) {
                            setIsDisable(true)
                            AddDataToBackend(fileList)
                            // uploadS3(fileList, (i) => {
                            //     if (i === fileList.length) {
                            //         AddDataToBackend(fileList)
                            //     }
                            // })
                        } else {
                            message.error('Upload File Max 25')
                            setIsLoading(false)
                        }
                    }
                }
            }
        })
    }
    // console.log(props.incident.resolution)
    const validateInput = (value: string) => {
        const CheckValue = (value).replace(/<[^>]*>/g, '')
        if (CheckValue === '' || CheckValue.replace(/ +/g, '').trim() === '') {
            setIsLoading(false)
            const qlEditor = document.getElementsByClassName('ql-editor')?.item(props?.incident?.resolution ? 2 : 1) as HTMLElement
            qlEditor.style.border = '1px solid red'
            if (CheckValue.trim().length === 0) {
                setErrorMessage('Required')
            } else {
                setErrorMessage('')
            }
        }
    }

    const clearAll = () => {
        setFileList([])
        setErrorMessage('')
        setProgressUpload(0)
        console.log('progressUpload', progressUpload)
        const element: any = document.getElementsByClassName('ql-editor')?.item(props?.incident?.resolution ? 2 : 1) as HTMLElement
        if (element) {
            element.style.border = '0px solid red'
        }
    }

    const AddDataToBackend = (fileList: UploadFile[]) => {
        if (fileList.length > 5) {
            message.error('Upload File Max 5')
        } else {
            const comment: Comment = {
                incidentId: Number(props.incidentId),
                text: text,
                visibleToCustomer: visibleToCustomer,
                uploadLink: fileList,
                type: CommentType.AddNote,
                from: props.form.getFieldValue('From'),
                to: props.form.getFieldValue('To') || props.form.getFieldValue('NotifyAgents'),
                isResolution: isResolution
            }

            if (showReply) {
                comment.visibleToCustomer = true
                comment.type = CommentType.Reply
            } else if (showForward) {
                comment.visibleToCustomer = true
                comment.type = CommentType.Forward
            }

            if (isResolution) {
                // @ts-ignore
                // const anyFileList: any[] = props.incident?.uploadLink?.length === 0 ? fileList : props.incident?.uploadLink?.concat(fileList)
                const incData: any = {
                    id: Number(props.incidentId),
                    resolution: text
                }

                props.updateIncident(incData).then(() => setIsLoading(false))
                    .catch((err) => {
                        setIsLoading(false)
                        if (isTanentFreshService) {
                            // กรณี FreshService error ให้แสดงเป็น modal
                            console.log(err.response.data)
                            console.log(err)
                            if (err.response.status === 400) {
                                Modal.warning({
                                    title: <b style={{ color: '#323276' }}>{err.response.data?.description || ''}</b>,
                                    content: <p style={{ color: '#323276' }}>{
                                        err.response.data?.errors.map((err: any, index: number) => {
                                            return <p key={index}>
                                                <b>{err.field}:</b> {err.message}
                                            </p>
                                        })
                                    }</p>
                                })
                            } else {
                                Modal.warning({
                                    title: <b style={{ color: '#323276' }}>{err.response.data?.code || ''}</b>,
                                    content: <p style={{ color: '#323276' }}>{err.response.data?.message || ''}</p>
                                })
                            }
                        } else {
                            message.error(`Unable to update data. Please try again later. ${err}`)
                        }
                    })
            }

            uploadImageInTextArea(text, (newText) => {
                comment.text = newText
                if (fileList.length > 0 && fileList.length <= 5) {
                    FSS.putFile(fileList, 'incidentComment', (uploadLink: UploadLink[]) => {
                        if (comment.uploadLink) {
                            comment.uploadLink = uploadLink.map((it) => {
                                return {
                                    name: it.name,
                                    url: it.url,
                                    type: it.type
                                }
                            })
                        } else {
                            comment.uploadLink = uploadLink
                        }
                        saveComment(comment)
                    })
                } else {
                    saveComment(comment)
                }
            })
        }
    }

    const saveComment = async (comment) => {
        const res = await props.addComment(comment)
        if (res === 201) {
            setIsLoading(false)
            setShowAddNote(false)
            setShowForward(false)
            setShowReply(false)
            setIsDisable(false)
            setIsResolution(false)
            props.form.setFieldsValue({
                from: undefined,
                to: undefined
            })
            clearAll()
            setText('')
        } else {
            message.error('Something went wrong.')
        }
    }

    const uploadImageInTextArea = (value: string, callBack: Function) => {
        if (value.search('<img')) {
            const MyFileList: UploadFile[] = []
            const result: string[] = value.split('<img src="data:')
            const newValue = [result[0]]
            result.forEach((it, index) => {
                if (it.startsWith('image')) {
                    const fileType = it.split(';')[0]
                    const fileExtention = fileType.split('/')[1] || ''
                    const deleteTagHtml = 'data:' + it.split('">')[0]
                    const ts = `${Math.round((new Date()).getTime() / 1000)}-${index}`
                    const ConvertUploadLink: UploadFile = {
                        uid: `${props.incidentId}-${ts}`,
                        name: `${props.incidentId}-${ts}.${fileExtention}`,
                        type: fileType,
                        url: deleteTagHtml,
                        size: deleteTagHtml.length
                    }
                    MyFileList.push(ConvertUploadLink)
                }
            })

            if (MyFileList.length === 0) callBack(value)
            FSS.putFile(MyFileList, 'incidentCommentEditor', (uploadLink: UploadLink[]) => {
                result.forEach((it, index) => {
                    if (index !== 0) {
                        let replaceImageValue = it.split('">')
                        replaceImageValue[0] = `<img src="${uploadLink[index - 1].url}`
                        replaceImageValue = replaceImageValue.map((it, index) => {
                            if (it.startsWith('<img src="')) {
                                return it + '">'
                            }
                            return it
                        })
                        newValue.push(...replaceImageValue)
                    }
                })
                const joinV = newValue.join('')
                setText(joinV)
                callBack(joinV)
            })
        }
    }

    const handleEditorChange = (content: string) => {
        const element: any = document.getElementsByClassName('ql-editor')?.item(props?.incident?.resolution ? 2 : 1) as HTMLElement
        const CheckValue = (content).replace(/<[^>]*>/g, '')
        if (content) {
            setText(content)
            if (CheckValue === '' || CheckValue.replace(/ +/g, '').trim() === '') {
                element.style.border = '1px solid red'
                setErrorMessage('Required')
            } else {
                element.style.border = '0px solid red'
                setErrorMessage('')
            }
        }
    }

    const toggleVisibleToCustomer = () => {
        setVisibleToCustomer(!visibleToCustomer)
    }

    const validateEmailDataForm = (_: any, value: any, callback) => {
        const mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        if (props.form.getFieldValue('From')) {
            if ((value as string).match(mailFormat)) {
                console.log('true')
                if (dataFrom.includes(value)) {
                    callback()
                } else {
                    callback('Email address not found. Please check and try again.')
                }
            } else {
                console.log('false')
                callback('From email address is not valid.')
            }
        } else {
            callback()
        }
    }

    // const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
    //     if (((file.url) as string).includes('jpg') || ((file.url) as string).includes('png')) {
    //         setPreviewVisible(true)
    //     } else {
    //         setPreviewVisible(false)
    //     }
    //     setPreviewImage(file.url || file.preview)
    // }
    const handleCancel = () => setPreviewVisible(false)

    const { getFieldDecorator } = props.form

    const checkResolution = (e) => {
        setIsResolution(e.target.checked)
    }

    const validateUpload = (_rule: any, value: any, callback) => {
        if (value) {
            if (value?.fileList?.length > 0) {
                let AllFileSize = 0
                value?.fileList?.forEach((data) => {
                    AllFileSize += data.size
                })
                if (AllFileSize >= fileSizeMax) {
                    callback('Maximum permitted size of 40 MB.')
                } else {
                    callback()
                }
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    const handleCheckMAxUploadFile = (): boolean => {
        let isCheck = false
        let AllFileSize = 0
        fileList.forEach((data) => {
            AllFileSize += data.size
        })
        if (AllFileSize >= fileSizeMax) {
            isCheck = true
        }
        return isCheck
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const uploadProps = {
        multiple: true,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                // deleteObjectInS3(file.uid).catch((err) => message.error(`Failed delete object in S3 data. ${err}`))
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xlsx', 'csv']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Please upload only the specified file types : ' + fileTypes.join(','))
                return false
            }
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                // if (file.size > fileSize) {
                //     message.error('Maximum permitted size of 5 Mb')
                //     return false
                // }
                if (handleCheckMAxUploadFile()) {
                    message.error('The maximum allowed file size is 40MB.')
                    return false
                }
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)

                setFileList(fillOffFileList)
            }

            return false
        },
        showUploadList: { showDownloadIcon: false }
    }

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob; type: string }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        // if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png')) {
        if (file.type.includes('image')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Card style={{ marginTop: '20px', cursor: 'pointer', boxShadow: ' 0 0 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }} size={'small'}>
                    <AutoAvatar title={sessionStorage.getItem('username') || ''} broken={false} username={sessionStorage.getItem('username')?.toString()} />
                    {isTanentFreshService ? null : <>
                        <Button disabled={true} type="link" style={{ marginRight: '10px', border: 'none', backgroundColor: 'rgba(0, 0, 0, 0)' }} onClick={() => toggleShowReply()}> <FontAwesomeIcon icon={['fas', 'reply']} /> <span>&nbsp;&nbsp;</span> Reply </Button>
                        <span style={{ borderLeft: '1px solid #323276' }} />
                    </>}
                    {isTanentFreshService ? null : <>
                        <Button disabled={true} type="link" style={{ marginRight: '10px', marginLeft: '10px', border: 'none', backgroundColor: 'rgba(0, 0, 0, 0)' }} onClick={() => toggleShowForward()}><FontAwesomeIcon icon={['fas', 'arrow-right']} /><span>&nbsp;&nbsp;</span>  Forward</Button>
                        <span style={{ borderLeft: '1px solid #323276' }} />
                    </>}
                    <Button disabled={!checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident?.createdBy)} type="link" style={{ marginRight: '10px', marginLeft: '10px', border: 'none', backgroundColor: 'rgba(0, 0, 0, 0)' }} onClick={() => toggleShowAddNote()}> <FontAwesomeIcon icon={['fas', 'sticky-note']} /> <span>&nbsp;&nbsp;</span> Add note</Button>
                    {isTanentFreshService ? null : <>
                        <span style={{ borderLeft: '1px solid #323276' }} />
                        <Button type="link" style={{ marginRight: '10px', marginLeft: '10px' }} disabled><FontAwesomeIcon icon={['fas', 'comment']} /><span>&nbsp;&nbsp;</span>  Discuss</Button>
                    </>}
                </Card>

                {showAddNote || showReply || showForward ? (<>
                    <Card style={{ boxShadow: '  0 8px 6px rgba(0, 0, 0, 0.2), 0 8px 20px rgba(0, 0, 0, 0.19)' }}>
                        <div style={{ marginBottom: '20px' }}>
                            {!isTanentFreshService ? <Row style={{ display: 'flex', alignItems: 'center' }}>
                                <Col md={2} xs={4} style={{ marginLeft: 20 }}>
                                    <AutoAvatar title={sessionStorage.getItem('username') || ''} broken={false} username={sessionStorage.getItem('username')?.toString()} />
                                </Col>
                                <Col span={7}>
                                    <div style={{ marginRight: '10px', alignContent: 'center' }}>
                                        {showReply ? (<>
                                            <p><Icon type="rollback" /><span>&nbsp;&nbsp;</span>
                                                Reply </p>
                                        </>) : null}
                                        {showForward ? (<>
                                            <p><Icon type="arrow-right" /><span>&nbsp;&nbsp;</span>
                                                Forward </p>
                                        </>) : null}
                                        {showAddNote ? (<>
                                            <p><Icon type="snippets" /><span>&nbsp;&nbsp;</span>
                                                Add note </p>
                                        </>) : null}
                                    </div>
                                </Col>
                                {!props.isSmall ? <>
                                    <Col span={13} style={{ direction: 'rtl', paddingRight: '10px' }}>
                                        {showAddNote ? (<span >Visible to the customer </span>) : null}
                                    </Col>
                                    <Col span={1} style={{ float: 'right' }}>
                                        {showAddNote ? <Switch checked={visibleToCustomer} disabled={true} onChange={() => toggleVisibleToCustomer()} /> : null}
                                    </Col>
                                </>
                                    : <><Row><Col span={23} style={{ direction: 'rtl', paddingRight: '40px' }}>
                                        {showAddNote ? (<span >Visible to the customer </span>) : null}
                                    </Col>
                                        <Col span={1} style={{ direction: 'rtl' }}>
                                            {showAddNote ? <Switch checked={visibleToCustomer} onChange={() => toggleVisibleToCustomer()} /> : null}
                                        </Col></Row></>}
                            </Row> : null}
                        </div>
                        <div>
                            {showForward || showReply ? (
                                <Row style={{ display: 'flex', alignItems: 'center' }}>
                                    <Col lg={2} md={3} xs={5} style={{ height: '100%' }}>
                                        <div style={{ marginLeft: 20 }}>From : </div><br /><br />
                                        <div style={{ marginLeft: 20, marginBottom: 8 }}>To : </div>
                                    </Col>
                                    <Col span={14} >
                                        <Form.Item>
                                            {getFieldDecorator('From', {
                                                initialValue: sessionStorage.getItem('profile_email'),
                                                rules: [{ required: true }, { validator: validateEmailDataForm }]
                                            })(
                                                <AutoComplete
                                                    style={{ width: '87%', marginLeft: 5 }}
                                                    dataSource={dataFrom}
                                                    placeholder="from"
                                                    filterOption={(inputValue, option) =>
                                                        (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item>
                                            {getFieldDecorator('To',
                                                {
                                                    initialValue: showReply ? props.requesterEmail : undefined,
                                                    rules: [{ type: 'email' }, { required: true }]
                                                })(
                                                    <Input
                                                        style={{ width: '87%', marginLeft: 5, marginTop: 10 }}
                                                        disabled={showReply}
                                                        placeholder="to"
                                                    />
                                                )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : null}
                            {showAddNote ? (
                                <Row style={{ display: 'flex', alignItems: 'center' }}>
                                    <Col lg={3} md={5} xs={10}>
                                        <div style={{ marginLeft: '20px', marginBottom: 20 }}>Notify to : </div>
                                    </Col>
                                    <Col span={13} >
                                        <Form.Item>
                                            {getFieldDecorator('NotifyAgents',
                                                {
                                                    initialValue: showReply ? props.requesterEmail : undefined,
                                                    rules: [{ type: 'email' }]
                                                })(
                                                    <Input
                                                        style={{ width: '87%', marginLeft: 5 }}
                                                        placeholder="Notify to"
                                                        disabled={!isTanentFreshService}
                                                    />
                                                )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} />
                                </Row>
                            ) : null}
                        </div>
                    </Card>

                    <div>
                        <Card style={{ boxShadow: ' 0 8px 6px  rgba(0, 0, 0, 0.2),  0 8px 20px  rgba(0, 0, 0, 0.19)' }}>
                            {/* <Row>
                                <KnowledgeFormUpload fileList={fileList} setFileList={(fileList: UploadFile[]) => { setFileList(fileList) }} />
                            </Row>
                            <Modal visible={previewVisible} footer={null} onCancel={() => handleCancel()} >
                                <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                            </Modal> */}
                            <Row>
                                <Col span={24} lg={24} md={24} sm={24} xs={24}
                                    style={{ marginBottom: '20px', marginTop: '10px' }}>
                                    <Form.Item>
                                        {getFieldDecorator('Upload',
                                            {
                                                rules: [{ validator: validateUpload }]
                                            })(
                                                <Upload {...uploadProps}
                                                    listType="picture"
                                                    className={'upload-list-inline'}
                                                    fileList={fileList}
                                                    onPreview={(e: any) => handlePreview(e)}
                                                >
                                                    <Button disabled={handleCheckMAxUploadFile()}>
                                                        <Icon type="upload" /> Upload
                                                    </Button>
                                                    <span style={{ color: '#323276', marginLeft: 5 }}>(File Size &lt; 40MB)</span>
                                                </Upload>
                                            )}
                                    </Form.Item>
                                </Col>
                                <Modal visible={previewVisible} footer={null}
                                    onCancel={() => setPreviewVisible(false)}>
                                    <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Row>
                            <br />

                            <Row>
                                <Form.Item>
                                    <ReactQuill
                                        theme="snow"
                                        modules={modules}
                                        formats={formats}
                                        onChange={(e: any) => handleEditorChange(e)}
                                        value={text}
                                    />
                                </Form.Item>
                                <span style={{ color: 'red' }}>{errorMessage}</span>
                            </Row>
                            {/* {showAddNote && !isCommentIdResolution ? <Checkbox disabled={props.isDisableAddResolution} onChange={checkResolution}>Add to Resolution</Checkbox> : null} */}
                            <br />
                            <Row>
                                <Form.Item>
                                    <Col span={24} style={{ textAlign: 'right' }}>
                                        <Button htmlType="submit" ghost disabled={isDisable} loading={isLoading}>
                                            {showAddNote ? 'Add note' : 'Send'}
                                        </Button>
                                    </Col>
                                </Form.Item>
                            </Row>
                        </Card>
                    </div>
                </>
                ) : null}
            </Form>
        </div >
    )
}

const MyCommentForm = Form.create<Props>()(CommentForm)

export default connect(
    mapStateToProps,
    { addComment, getAllSupportTeamByPermission, loadRolesState, updateIncident }
)(MyCommentForm)
