/* eslint-disable camelcase */
import { WOpeople } from '../../authorization-module/people/model'
import { BaseDocument, BaseEntity } from '../../common-model'
import { CustomFiledAsset } from '../../configurable-fields/model'
import { FieldDetail } from '../../incident-management/incident'
import { Asset } from '../Relate/Asset'
import { Job } from '../Relate/Job'

export interface WorkOrder extends BaseDocument {
    number?: string,
    description: string,
    priority: number,
    engineerGroup?: string,
    engineerRoleId?: number,
    engineerName?: string,
    topic: string,
    supportTeam?: string,
    assignee?: string,
    assigneeId?: string,
    assignmentGroupId?: number
    assigneeRoleId?: string,
    appointmentDate?: string,
    status: string,
    attachFile?: string,
    incidentNo?: string,
    contactUserName?: string,
    contactUserEmail?: string,
    contactUserPhone?: string,
    contactUserLocation?: string,
    estimateDate?: string,
    estimateDay?: number,
    estimateHour?: number,
    estimateMinute?: number,
    type: string,
    closedJobs?: string
    referRemedyHd?: string,
    assigneeEmail?: string,
    engineerEmail?: string,
    requester?: WOpeople,
    workflowId?: number,
    workflowName?: string,
    job?: Job[]
    customFields?: FieldDetail[] | CustomFiledAsset[],
    customerName?: string,
    customerCompanyName?: string,
    customerAddress?: string,
    customerPhone?: string
}

export interface AssignmentGroups {
    name: string,
    assignees: Assignees[],
    roleId: number
}

export interface Assignees {
    name: string,
    email: string,
    role: string,
    phone: string,
    roleId: number
}

export interface SupportTeamList {
    name: string,
    assignees: AssigneesPeople[]
}

export interface AssigneesPeople {
    id?: string
    fullName: string,
    email: string,
    name: string,
    phone: string,
    supportTeamId: number
    employeeId: string
}

export interface WorkOrderRelatedJob {
    workOrder: WorkOrder,
    jobs: Job
}

export interface WorkOrderList {
    id?: string
    workOrder?: WorkOrder,
    related?: Job[]
}

export interface TotalWorkOrderContent {
    total?: number
}

export interface predefinedInfo {
    ticketType: string,
    roleName: string,
    engineerName: string,
    roleId: string,
    owner: string,
    subject: string,
    description: string,
    contactUserName: string,
    contactUserPhone: string,
    contactUserEmail: string,
    contactUserLocation: string
}

export interface WorkOrderWithPagination extends BaseEntity {
    page?: number
    total?: number
    sortBy?: string
    orderBy?: any
}

export interface MyFilter {
    key: string
    value: any
}

export interface WoRalateIncidentCard {
    description?: string
    id?: number
    item_id?: string
    order_id?: string
    wo_status?: string
    workflow_name?: string
    workflow_number?: string
    canView: boolean
}

export interface WoSignatureForm extends BaseDocument {
    companyAddress: string,
    companyName: string,
    companyPhone: string,
    appointmentDatetime?: string,
    estimateDatetime?: string,
    userId?: WOpeople,
    customerName?: string,
    customerCompany?: string,
    customerAddress?: string,
    customerPhone?: string,
    documentName?: string,
    documentDate?: string,
    documentNumber?: string,
    jobNumber?: string,
    jobRelateAsset?: Asset[],
    requesterAddress?: string,
    requesterCompany?: string,
    requesterDepartment?: string,
    requesterPhone?: string,
    requesterId?: number,
    requesterName?: string,
    woId?: number,
    woNumber?: string,
    woTopic: string
}

export interface sentMailWorkOrder {
    email: string
    subject: string
    content: string
    woId: string
}

export interface woSettingColumn {
    columnLabel: string
    columnName: string
    columnOrder: number
    columnPosition: string
}

export interface woDashboardData {
    label: string
    total?: number[]
    totalPerHour?: number[]
    data?: number[]
    backgroundColor?: string
}

export interface woDashboardOverview {
    overviewTotalStatus: woDataTotal[]
    workOrderPerHours: woDashboardData[]
    sumPerHours: number[]
    workOrderStatusAndGroup: {
        groupNames: string[]
        dataSet: woDashboardData[]
    }
    workOrderStatusAndWorkflow: {
        workflowNames: string[]
        dataSet: woDashboardData[]
    }
}
export interface woDashboardTeam {
    workOrderStatusAndGroup: {
        groupNames: string[]
        dataSet: woDashboardData[]
    }
    workOrderStatusAndWorkflow: {
        workflowNames: string[]
        dataSet: woDashboardData[]
    }
}

export interface woDataTotal {
    label: string,
    total: number

}

export enum colorWoDashboardPiority {
    Low = '#009CE0',
    Meduim = '#68BC00',
    High = '#FE9200',
    Critical = '#D33115'
}

export enum colorWoDashboardStaus {
    New = '#EB5757',
    Assign = '#F3AF4B',
    InProgress = '#7AD7FF',
    Complete = '#71DA94',
    Close = '#B773FF',
    Cancel = '#98A2B3'
}

export interface bodyPreviewWOReport {
    startDate: string
    endDate: string
    page?: number
    size?: number
    sortBy?: string
    orderBy?: string
    selectedColumns: string[]
    selectedWorkflow?: string[]
}