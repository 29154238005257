import { Tabs, Modal } from 'antd'
import React, { useState } from 'react'
import SelectRelateDataTable from './SelectRelateDataTable'
import SelectedDataRelated from './SelectedDataRelated'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { getCookie } from '../../../guard/route'

interface Param {
    ticketTypeIsRequest?: boolean
}

type Props = Param

const { TabPane } = Tabs
const { confirm } = Modal

const RelatedTabpane: React.FC<Props> = (props: Props) => {
    const [isModalVissible, setIsModalVisible] = useState<boolean>(false)
    const [tabpaneSelectName, setTabpaneSelectName] = useState<string>('Ticket')
    const [isCancelModal, setIsCancelModal] = useState<boolean>(false)
    const decoded: any = jwt_decode(getCookie('access_token') || '')

    const ButtonSelectdata = (tabpane: string) => {
        return (
            <div style={{ textAlign: 'end' }}>
                <span className="feck-link" onClick={() => { showModal(tabpane) }} >Select {tabpane}</span>
            </div>
        )
    }

    const showModal = (value: string) => {
        setIsModalVisible(true)
        setTabpaneSelectName(value)
    }

    const closeModal = () => {
        confirm({
            title: 'Are you sure ?',
            content: 'Do you want to leave this page ?',
            onOk() {
                setIsModalVisible(false)
                setIsCancelModal(true)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    return (
        <div>
            <Tabs defaultActiveKey="1" type="card">
                <TabPane tab="Ticket" key="1" disabled={!decoded.service.serviceId.includes(1)}>
                    {ButtonSelectdata('Ticket')}
                    <br />
                    <SelectedDataRelated tabpaneName={'Ticket'} />
                </TabPane>
                {decoded.service.serviceId.includes(2) ? <TabPane tab="Asset" key="2" disabled={!decoded.service.serviceId.includes(2)}>
                    {ButtonSelectdata('Asset')}
                    <br />
                    <SelectedDataRelated tabpaneName={'Asset'} />
                </TabPane> : null}
                {decoded.service.serviceId.includes(5) ? <TabPane tab="Knowledge" key="3" disabled={!decoded.service.serviceId.includes(5)}>
                    {ButtonSelectdata('Knowledge')}
                    <br />
                    <SelectedDataRelated tabpaneName={'Knowledge'} />
                </TabPane> : null}
                {decoded.service.serviceId.includes(8) ? <TabPane tab="Problem" key="5" disabled={!decoded.service.serviceId.includes(8)}>
                    {ButtonSelectdata('Problem')}
                    <br />
                    <SelectedDataRelated tabpaneName={'Problem'} />
                </TabPane> : null}
                {props.ticketTypeIsRequest && decoded.service.serviceId.includes(4)
                    ? <TabPane tab="Workflow" key="4" disabled={!decoded.service.serviceId.includes(4)}>
                        {ButtonSelectdata('Workflow')}
                        <br />
                        <SelectedDataRelated tabpaneName={'Workflow'} />
                    </TabPane>
                    : null}
            </Tabs>
            <Modal
                title={<b>{tabpaneSelectName === 'Workflow' ? tabpaneSelectName : tabpaneSelectName + ' List'}</b>}
                visible={isModalVissible}
                onCancel={() => closeModal()}
                closable={true}
                footer={null}
                width={'90%'}
            >
                <SelectRelateDataTable modalVisisle={isModalVissible} setModalvisible={setIsModalVisible} tabpaneName={tabpaneSelectName} isCancelModal={isCancelModal} setIsCancelModal={setIsCancelModal} />
            </Modal>
        </div>
    )
}

export default RelatedTabpane
