import React, {useState} from 'react'
import {FormComponentProps} from 'antd/es/form'
import {Button, Card, Form, Input, message, Modal} from 'antd'
import BannerConfirmation from '../../assets/images/BannerConfirmationGoService.png'
import { forgotPassword } from './duck/action'

type Props = FormComponentProps

const ForgotPassword: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator } = props.form
    const [isUserExist, setIsUserExist] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleOnSubmit = e => {
        setIsLoading(true)
        const {form} = props
        e.preventDefault()
        form.validateFields((err, values) => {
            if (err) {
                setIsLoading(false)
                console.log('Received err of form: ', err)
            } else {
                forgotPassword(values.username, 'forgot')
                    .then(_res => {
                        setIsLoading(false)
                        // message.success('We've sent a password reset link to your email . Please check your inbox.')
                        success()
                    }).catch(error => {
                        setIsLoading(false)
                        if (error.response.status === 404) {
                            setIsUserExist(true)
                        } else {
                            setIsUserExist(false)
                            message.error('Unable to save data. Please check and try again.')
                        }
                    }).finally(() => {
                        setIsLoading(false)
                    })
                setIsLoading(false)
            }
        })
    }

    function success() {
        Modal.success({
            title: 'Success',
            content: (
                <div>
                    <p>We've sent a password reset link to your email . Please check your inbox.</p>
                </div>
            ),
            onOk() {
                // window.location.href = '/'
            }
        })
    }

    return (
        <>
            <div style={{minHeight: '100%', marginBottom: '-30px', display: 'flex', alignItems: 'center'}}>
                <Card
                    style={{ width: 350, height: '100%', margin: 'auto'}}
                    cover={
                        <div>
                            <img alt='BannerConfirmation' style={{width: '100%'}} src={BannerConfirmation}/>
                            <div style={{backgroundColor: '#323276', color: '#ffffff', paddingLeft: '18px', paddingBottom: '15px'}}>
                                <h4 style={{color: '#ffffff', textAlign: 'center', fontSize: '16px'}}>Forgot Password</h4>
                                <span>Please, enter your email. You will receive a link to create <br/> a new password via email.</span>
                            </div>
                        </div>
                    }
                >
                    <Form layout="vertical" onSubmit={handleOnSubmit}>
                        <Form.Item label={'Email'}>
                            {getFieldDecorator('username',
                                {
                                    initialValue: '',
                                    rules: [{required: true, message: 'Email is Required'},
                                        {type: 'email', message: 'Email is invalid'}]
                                })(
                                <Input onChange={() => { setIsUserExist(false) }}/>
                            )}
                        </Form.Item>
                        {isUserExist ? <Form.Item>
                            <span style={{color: 'red'}}>
                                The email does not exist in the system.
                                Please contact admin.
                            </span>
                        </Form.Item> : null}
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button" loading={isLoading}>
                                Send
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>

            </div>
            <footer style={{
                backgroundColor: '#323276',
                textAlign: 'center',
                height: '30px',
                color: '#ffffff',
                fontSize: 11
            }}>
                G-Able Company Limited
            </footer>
        </>
    )
}
const wrappedForgotPasswordForm = Form.create({ name: 'ForgotPassword' })(ForgotPassword)
export default wrappedForgotPasswordForm
