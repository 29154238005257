import { AutoComplete, Button, Checkbox, Col, DatePicker, Form, Icon, Input, InputNumber, message, Modal, Radio, Row, Select, Spin, Tag, Upload } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { StoreState } from '../../../../../store'
import { getAllPriorityProblem, getPriorityConfigurationProblem, PriorityLevelProblem, PriorityMatrixProblem, resolvePriorityProblem, TicketStatusProblems } from '../../../../priority'
import exportIcon from '../../../../../common-file/icon-file'
import { UploadFile } from 'antd/lib/upload/interface'
import { DownloadAnyFile } from '../../../../../common-misc'
import { SupportTeam } from '../../../../../incident-management/incident'
import { UserSupportTeam } from '../../../../../authorization-module/support-team-management/model'
import { SelectValue } from 'antd/lib/select'
import { People } from '../../../../../authorization-module/people/model'
import { FormComponentProps } from 'antd/lib/form'
import RelatedProblemHome from '../RelatedProblemHome'
import { Quill } from 'react-quill'
import ImageResize from 'quill-image-resize-module-react'
import { KnowledgeRelatedContext } from '../../../../../Preventive/ContextAPI/KnowledgeRelatedContext'
import { PbCustomFieldDetail, Problem, ProblemStatus, PTask } from '../../../Model'
import { ProblemType } from '../../../../general/states/model'
import { addAllTypeProblems } from '../../../../general/states/service'
import { getAllCustomFieldProblem } from '../../../../../configurable-fields/problems/state/services'
import { getAllUrgencyPriorty } from '../../../../urgency'
import { getAllImpactPriorty } from '../../../../impact'
import { getAllSupportTeamByPermission } from '../../../../../incident-management/support-team'
import { Categories, getAllCategory, getAllItems, getAllSubCategory } from '../../../../../incident-management/category'
import { CreatePTask, GetAllStatusProblem } from '../../../service'
import moment from 'moment'
import { CustomFiledProblem } from '../../../../../configurable-fields/problems/state/model'
import FSS from '../../../../../file-server-storage'
import { UploadLink } from '../../../../../knowledge-management/knowledge/uploads'
import { checkRolesState, RoleType } from '../../../../../authorization-module/permissions'

const mapStateToProps = (state: StoreState) => {
    return {
        priorityMatrixProblems: state.priorityMatrixProblems,
        impactProblem: state.impactProblem,
        urgencieProblem: state.urgencieProblem,
        priorityProblem: state.priorityProblem,
        generalProblems: state.generalProblems,
        categories: state.categories,
        subCategories: state.subCategories,
        items: state.items,
        supportTeamsWithPermission: state.supportTeamsWithPermission,
        customFieldProblems: state.customFieldProblems
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllCategory: () => Promise<number>
    getAllSubCategory: () => Promise<number>
    getAllItems: () => Promise<number>
    getAllSupportTeamByPermission: () => Promise<number>
    getAllPriorityProblem: () => Promise<number>
    getAllImpactPriorty: () => Promise<number>
    getAllUrgencyPriorty: () => Promise<number>
    getPriorityConfigurationProblem: () => Promise<number>
    getAllCustomFieldProblem: (value: string) => Promise<number>
}

interface Params {
    OpenPTaskName: string
    closeModal: Function
    problem?: Problem
    setIsVisible?: Function
}

const { Option } = Select
const { TextArea } = Input
const { confirm } = Modal
Quill.register('modules/imageResize', ImageResize)
// const modules = {
//     toolbar: [
//         [{ header: [1, 2, 3, 4, 5, 6, false] }],
//         ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//         [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }, { color: [] }],
//         ['link', 'image'],
//         ['clean']
//     ],
//     imageResize: {
//         parchment: Quill.import('parchment'),
//         modules: ['Resize', 'DisplaySize']
//     }
// }

// const formats = [
//     'alt', 'height', 'width', 'style', 'size',
//     'header',
//     'bold', 'italic', 'underline', 'strike', 'blockquote',
//     'list', 'bullet', 'indent', 'align', 'color',
//     'link', 'image'
// ]
const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)
type Props = FormComponentProps & Params & StateProps & DispatchProps
const ProblemTaskForm: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, getFieldValue, resetFields } = props.form
    const [myStatus, setMyStatus] = useState<TicketStatusProblems>()
    const [myPriority, setMyPriority] = useState<PriorityMatrixProblem>()
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewImage, setPreviewImage] = useState<string>()
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [tempAssignGroup, setTempAssignGroup] = useState<SupportTeam>()
    const [tempAssignTo, setTempAssignTo] = useState<string | undefined>(undefined)
    const [assignee, setAssignee] = useState<UserSupportTeam[]>([])
    const [, setUser] = useState<People | undefined>()
    const [typePTaskProblems, setPTaskTypeProblems] = useState<ProblemType[]>([])
    const { KnowledgeRelatedState, GetKnowledgeRelated } = useContext<any>(KnowledgeRelatedContext)
    const [isLoading, setIsLoading] = useState(false)
    const [allStatus, setAllStatus] = useState<ProblemStatus[]>([])
    const [, setMyCategory] = useState<string[]>([])
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
    const { id }: any = useParams()

    useEffect(() => {
        setIsLoading(true)
        GetKnowledgeRelated([])
        const promiseAll: any[] = []
        promiseAll.push(props.getAllSupportTeamByPermission())
        promiseAll.push(props.getAllCategory())
        promiseAll.push(props.getAllSubCategory())
        promiseAll.push(props.getAllItems())
        promiseAll.push(props.getAllPriorityProblem())
        promiseAll.push(props.getAllImpactPriorty())
        promiseAll.push(props.getAllUrgencyPriorty())
        promiseAll.push(props.getPriorityConfigurationProblem())
        addAllTypeProblems().then((res: ProblemType[]) => {
            if (res) {
                const filterTypyPTask = res.filter((p) => { return p.type === 'PTask' && p.active === true })
                setPTaskTypeProblems(filterTypyPTask)
            }
        })
        Promise.all(promiseAll).catch((err) => {
            message.error(`Unable to retrieve the data. Please try again later. ${err}`)
        }).finally(() => { setIsLoading(false) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const findData = typePTaskProblems.find((problem) => { return problem.subType === props.OpenPTaskName })
        if (findData && findData?.id) {
            props.getAllCustomFieldProblem(findData.id)
            GetAllStatusProblem(findData.id!!).then((status) => {
                setAllStatus(status)
                if (status[0]?.status?.toLowerCase() === 'new') {
                    setMyStatus(status[0])
                } else {
                    const findStatusAccess = allStatus.find((status) => { return status.status === 'New' && status.active === true })
                    if (findStatusAccess) {
                        setMyStatus(findStatusAccess as TicketStatusProblems)
                    }
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typePTaskProblems, props.OpenPTaskName])

    useEffect(() => {
        const temMyCategory: string[] = []
        if (props.categories) {
            props.categories.forEach((category: Categories) => {
                if (category.active) {
                    temMyCategory.push(category.name)
                }
            })
            setMyCategory(temMyCategory)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.categories])

    useEffect(() => {
        const dateService = moment(getFieldValue('StartDate'))
        const expiryDate = moment(getFieldValue('DueDate'))
        if (props.form.getFieldValue('StartDate') === null) {
            props.form.resetFields(['DueDate'])
        }
        if (dateService > expiryDate) {
            props.form.resetFields(['DueDate'])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.getFieldValue('StartDate')])

    const callApiGetPriorities = async () => {
        const myImpact = props.form.getFieldValue('Impact')
        const myUrgency = props.form.getFieldValue('Urgency')
        const impact = props.impactProblem?.find(it => it.id === myImpact)?.id!!
        const urgency = props.urgencieProblem?.find(it => it.id === myUrgency)?.id!!
        if (impact !== undefined && urgency !== undefined) {
            resolvePriorityProblem(impact, urgency).then((res) => {
                setMyPriority(res)
            }).catch((err) => {
                message.error(`${err}`)
            })
        }
    }

    const validateTopicAndDes = (_rule: any, value: any, callback) => {
        if (value) {
            const CheckValue = value.replace(/ +/g, '').trim()
            if (CheckValue.length >= 3) {
                callback()
            } else {
                callback('Please enter more than 3 characters.')
            }
        } else {
            callback()
        }
    }

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob; type: string }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        if (file.type.includes('image')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const uploadProps = {
        multiple: true,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                // deleteObjectInS3(file.uid).catch((err) => message.error(`Failed delete object in S3 data. ${err}`))
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xlsx', 'csv']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Please upload only the specified file types : ' + fileTypes.join(','))
                return false
            }
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                console.log(e)
                if (file.size > fileSize) {
                    message.error('The maximum allowed file size is 5MB.')
                    return false
                }
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                setFileList(fillOffFileList)
                console.log(fileList)
            }

            return false
        },
        showUploadList: { showDownloadIcon: false }
    }

    const FilterSupportTeam = async (e: SelectValue) => {
        await props.form.setFieldsValue({
            Assigned_to: null
        })
        const target = props.supportTeamsWithPermission.find(it => it.name === e)
        setTempAssignGroup(target)
        setUser(undefined)
        // changeMyStatus(getTicketStatus(props.statuses, ticketType, 'Assigned'))
        if (props.form.getFieldValue('Assignment_group') === '') {
            // changeMyStatus(getTicketStatus(props.statuses, ticketType, 'New'))
            props.form.setFields({
                Assignment_group: null,
                Assigned_to: null
            })
        }
        const targetSupportTeams = props.supportTeamsWithPermission.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === e
        })
        if (targetSupportTeams.length !== 0) {
            targetSupportTeams.forEach((res) => {
                setAssignee(res.assignees)
            })
        } else {
            setAssignee([])
        }
    }

    const onBlurTeam = (value: SelectValue) => {
        if (props.supportTeamsWithPermission.length !== 0) {
            const mySupportTeamName = props.supportTeamsWithPermission.map((data) => { return data.name })
            const isTeam = mySupportTeamName.includes((value as string))
            if (!isTeam) {
                props.form.setFieldsValue({
                    Assignment_group: undefined
                })
                // changeMyStatus(getTicketStatus(props.statuses, ticketType, 'New'))
                setAssignee([])
            }
        }
    }

    const FilterAssignee = () => {
        const valAssignee = props.form.getFieldValue('Assignment_group')
        const targetSupportTeams = props.supportTeamsWithPermission.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === valAssignee
        })
        targetSupportTeams.forEach((res) => {
            setAssignee(res.assignees)
        })
    }

    const checkAssignee = (e: SelectValue) => {
        if (e) {
            FilterSupportTeam(e).catch((err) => message.error(`Unable to filter support team. Please try again later. ${err}`))
        } else {
            props.form.setFieldsValue({
                Assigned_to: null
            })
            setTempAssignGroup(undefined)
            setTempAssignTo(undefined)
            setUser(undefined)
        }
    }

    const changeAssigned = (e: SelectValue) => {
        const currentAssignGroup = props.form.getFieldValue('Assignment_group')
        console.log(e)
        if (e === '' || e === undefined) {
            props.form.setFieldsValue({
                Assigned_to: null
            })
            setTempAssignTo(undefined)
            setAssignee([])
            const findStatusAccess = allStatus.find((status) => { return status.status === 'New' && status.active === true })
            if (findStatusAccess) {
                setMyStatus(findStatusAccess as TicketStatusProblems)
            }
        } else {
            if (currentAssignGroup && e) {
                // changeMyStatus(getTicketStatus(props.statuses, ticketType, 'InProgress'))
                const findStatusAccess = allStatus.find((status) => { return status.status === 'Assess' && status.active === true })
                if (findStatusAccess) {
                    setMyStatus(findStatusAccess as TicketStatusProblems)
                }
            } else {
                console.log('here')
                const findStatusAccess = allStatus.find((status) => { return status.status === 'New' && status.active === true })
                if (findStatusAccess) {
                    setMyStatus(findStatusAccess as TicketStatusProblems)
                }
            }
        }
    }

    const onBlurAssignee = (value: SelectValue) => {
        const currentAssignGroup = props.form.getFieldValue('Assignment_group')
        const target = assignee.find(it => it.id?.toString() === value)
        setUser(target?.users)
        if (assignee) {
            const isAssignee = assignee.find(it => it.id?.toString() === value)
            if (!isAssignee && !currentAssignGroup) {
                props.form.setFieldsValue({
                    Assigned_to: undefined
                })
                setTempAssignTo(undefined)
                // changeMyStatus(getTicketStatus(props.statuses, ticketType, 'New'))
            } else if (!isAssignee) {
                props.form.setFieldsValue({
                    Assigned_to: undefined
                })
                setTempAssignTo(undefined)
                // changeMyStatus(getTicketStatus(props.statuses, ticketType, 'Assigned'))
            }
        }
    }

    const handleSubmit = (e: any): void => {
        e.preventDefault()
        props.form.validateFields(async (err: any, values) => {
            if (!err) {
                setIsLoadingSubmit(true)
                const findAssignmentGroup = props.supportTeamsWithPermission.find((team) => { return team.name?.toString() === values.Assignment_group?.toString() })
                const findAssignedToId = assignee.find((user) => { return user.id?.toString() === values.Assigned_to?.toString() })
                const fieldDetails: PbCustomFieldDetail[] = (props.customFieldProblems || []).map((obj: CustomFiledProblem) => {
                    let value = ''
                    if (obj.name) {
                        if (obj.inputType === 'Checkbox') {
                            if (getFieldValue(obj.name)) {
                                if (typeof getFieldValue(obj.name) !== 'string') {
                                    value = getFieldValue(obj.name).join('|')
                                } else {
                                    value = getFieldValue(obj.name)
                                }
                            }
                        } else if (obj.inputType === 'DatePicker') {
                            value = moment(getFieldValue(obj.name)).format('YYYY-MM-DD')
                        } else {
                            value = getFieldValue(obj.name)
                        }
                    }
                    const fieldDetail: PbCustomFieldDetail = {
                        value: value,
                        customFields: { id: obj.id }
                    }
                    return fieldDetail
                })
                const convertDataRelateKm: any[] = KnowledgeRelatedState.map((km) => { return km.id!! })
                const findData = typePTaskProblems.find((problem) => { return problem.subType === props.OpenPTaskName })
                const convertData: PTask = {
                    problemId: id,
                    topic: values.topic,
                    description: values.description,
                    assignmentGroup: findAssignmentGroup ? { id: findAssignmentGroup?.id } : undefined,
                    assignedToId: findAssignedToId ? { id: findAssignedToId?.users.id } : undefined,
                    startDate: values.StartDate ? moment(values.StartDate).format('YYYY-MM-DD') : null,
                    endDate: values.DueDate ? moment(values.DueDate).format('YYYY-MM-DD') : null,
                    category: values.Category,
                    subcategory: values.subcategory,
                    item: values.Item,
                    pbStatus: { id: myStatus?.id },
                    pbPriority: { id: Number(myPriority?.id) },
                    pbType: Number(findData?.id!!),
                    ptaskCustomFieldDetail: fieldDetails,
                    ptaskRelateKnowledge: convertDataRelateKm
                }
                console.log(convertData)
                checkUploadSaveProblem(convertData)
            }
        })
    }

    const checkUploadSaveProblem = (convertData: PTask) => {
        if (fileList.length > 0 && fileList.length <= 5) {
            FSS.putFile(fileList, 'problem', (uploadLink: UploadLink[]) => {
                convertData.pbUpload = uploadLink?.map((data) => {
                    return {
                        name: data.name,
                        url: data.url,
                        type: data.type
                    } as UploadLink
                })
                CreatePTask(convertData).then((res) => {
                    Modal.success({
                        title: <b style={{ color: '#323276' }}>Success</b>,
                        content: renderResultCreateTicket(res.number!!),
                        okText: 'OK',
                        okType: 'danger',
                        onOk: () => {
                            console.log('ok')
                            props.closeModal()
                            resetFields()
                            GetKnowledgeRelated([])
                            setFileList([])
                            setMyPriority(undefined)
                            setTempAssignGroup(undefined)
                        },
                        onCancel: () => {
                            console.log('Cancel')
                            props.closeModal()
                            resetFields()
                            GetKnowledgeRelated([])
                            setFileList([])
                            setMyPriority(undefined)
                            setTempAssignGroup(undefined)
                        }
                    })
                }).finally(() => {
                    setIsLoadingSubmit(false)
                    resetFields()
                    GetKnowledgeRelated([])
                })
            })
        } else if (fileList.length > 5) {
            setIsLoadingSubmit(false)
            message.error('Upload File Max 5')
        } else {
            CreatePTask(convertData).then((res) => {
                Modal.success({
                    title: <b style={{ color: '#323276', fontSize: '25px', fontWeight: 'bold' }}>Success</b>,
                    content: <span style={{ fontSize: '16px' }}>{renderResultCreateTicket(res.number!!)}</span>,
                    okText: 'OK',
                    okType: 'danger',
                    className: 'custom-modal-wo',
                    onOk: () => {
                        console.log('ok')
                        props.closeModal()
                        resetFields()
                        GetKnowledgeRelated([])
                        setFileList([])
                        setMyPriority(undefined)
                        setTempAssignGroup(undefined)
                    },
                    onCancel: () => {
                        console.log('Cancel')
                        props.closeModal()
                        resetFields()
                        GetKnowledgeRelated([])
                        setFileList([])
                        setMyPriority(undefined)
                        setTempAssignGroup(undefined)
                    }
                })
            }).finally(() => {
                setIsLoadingSubmit(false)
                resetFields()
                GetKnowledgeRelated([])
            })
        }
    }

    // const onTextEditorChange = (value: string) => {
    //     if (value) {
    //         setDetail(value)
    //     }
    // }

    // const uploadProps2 = {
    //     multiple: true,
    //     onRemove: (file: any) => {
    //         setFileList2(state => {
    //             const index = state.indexOf(file)
    //             const newFileList = state.slice()
    //             newFileList.splice(index, 1)
    //             // deleteObjectInS3(file.uid).catch((err) => message.error(`Failed delete object in S3 data. ${err}`))
    //             return newFileList
    //         })
    //     },
    //     beforeUpload: (file: any) => {
    //         const fileTypes = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xlsx', 'csv']
    //         const extension = file.name.split('.').pop().toLowerCase()
    //         const isSuccess = fileTypes.indexOf(extension) < 0
    //         if (isSuccess) {
    //             message.error('Please upload only the specified file types : ' + fileTypes.join(','))
    //             return false
    //         }
    //         const reader = new FileReader()
    //         reader.readAsDataURL(file)
    //         reader.onloadend = function (e: any) {
    //             console.log(e)
    //             if (file.size > fileSize) {
    //                 message.error('The maximum allowed file size is 5MB.')
    //                 return false
    //             }
    //             let fillOffFileList = fileList2
    //             fillOffFileList.push({
    //                 uid: file.uid,
    //                 name: file.name,
    //                 status: 'done',
    //                 url: reader.result?.toString(),
    //                 type: base64MimeType(reader.result),
    //                 size: e.total,
    //                 thumbUrl: exportIcon(base64MimeType(reader.result))
    //             })
    //             fillOffFileList = fillOffFileList.slice(0)
    //             setFileList2(fillOffFileList)
    //             console.log(fileList)
    //         }

    //         return false
    //     },
    //     showUploadList: { showDownloadIcon: false }
    // }

    // const handlePreview2 = async (file: { url: any; preview: unknown; originFileObj: Blob; type: string }) => {
    //     if (!file.url && !file.preview) {
    //         file.preview = await getBase64(file.originFileObj)
    //     }
    //     if (file.type.includes('image')) {
    //         setPreviewVisible2(true)
    //         setPreviewImage2(file.url || file.preview)
    //     } else {
    //         DownloadAnyFile(file)
    //     }
    // }

    const renderResultCreateTicket = (incidentNumber: string) => {
        return <div>
            <p style={{ color: '#323276', fontWeight: 500, fontSize: '15px' }}>You have successfully saved the data<br></br></p>
            <p style={{ color: '#2F80ED', fontWeight: 500 }}>Problem Task Number: {incidentNumber}</p>
        </div>
    }

    const customFieldLayout = () => {
        let rowContents: JSX.Element[] = []
        // eslint-disable-next-line react/prop-types
        const contents = (props.customFieldProblems || []).reduce((acc: JSX.Element[], obj, index) => {
            rowContents.push(
                <div style={{ padding: '0px 10px' }}>
                    <Col span={12} lg={12} md={12} sm={24} xs={24} key={index} style={{ marginBottom: 10 }}>
                        {obj.inputType === 'Checkbox' ? (
                            <Form.Item label={obj.label} className='custom-formLabel-wo'>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue || undefined,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <Checkbox.Group style={{ width: '100%', minHeight: 20 }}>
                                        <Row>
                                            {
                                                obj.selectOption.map((it, i) => {
                                                    return <Col span={8} key={i}>
                                                        <Checkbox value={it.option}>{it.option}</Checkbox>
                                                    </Col>
                                                })
                                            }
                                        </Row>
                                    </Checkbox.Group>
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'DatePicker' ? (
                            <Form.Item label={obj.label} className='custom-formLabel-wo'>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue ? moment(obj.defaultValue as string) : undefined,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <DatePicker style={{ width: '100%' }} format={'YYYY-MM-DD'} />
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'DropdownSelect' ? (
                            <Form.Item label={obj.label} className='custom-formLabel-wo'>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue ? (obj.defaultValue as string) : undefined,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <Select style={{ width: '100%' }}
                                        placeholder={obj.placeholder as string || undefined} allowClear>
                                        {
                                            obj.selectOption.map((it, i) => {
                                                return <Option value={it.option} key={i}>{it.option}</Option>
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'InputNumber' ? (
                            <Form.Item label={obj.label} className='custom-formLabel-wo'>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue ? Number(obj.defaultValue) : undefined,
                                    rules: [
                                        { type: (obj.dataType as string).toLowerCase() || undefined },
                                        {
                                            required: obj.isRequireFields as boolean,
                                            message: obj.label + ' is required'
                                        }
                                    ]
                                })(
                                    <InputNumber min={0} style={{ width: '100%' }}
                                        placeholder={obj.placeholder as string || undefined} />
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'RadioButton' ? (
                            <Form.Item label={obj.label} className='custom-formLabel-wo'>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue || undefined,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <Radio.Group style={{ width: '100%', minHeight: 20 }}>
                                        {
                                            obj.selectOption.map((it, i) => {
                                                return <Radio value={it.option} key={i}>{it.option}</Radio>
                                            })
                                        }
                                    </Radio.Group>
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'TextBox' ? (
                            <Form.Item label={obj.label} className='custom-formLabel-wo'>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue || undefined,
                                    rules: [
                                        { type: (obj.dataType as string).toLowerCase() || undefined },
                                        {
                                            required: obj.isRequireFields as boolean,
                                            message: obj.label + ' is required'
                                        }
                                    ]
                                })(
                                    <Input placeholder={obj.placeholder as string || undefined} />
                                )}
                            </Form.Item>
                        ) : null}
                    </Col>
                </div>
            )
            if (index % 2 === 1) {
                acc.push(
                    <>
                        <Row gutter={16}>{rowContents}</Row>
                    </>
                )
                rowContents = []
            }
            return acc
        }, [])
        contents.push(<Row>{rowContents}</Row>)

        return (
            <div>
                {contents}
            </div>
        )
    }

    const habdleCloseModal = () => {
        confirm({
            title: <b style={{ color: '#323276', fontSize: '25px', fontWeight: 'bold' }}>Are you sure?</b>,
            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>Do you want to leave this page ?</span>,
            okText: 'Yes',
            cancelText: 'No',
            okType: 'danger',
            autoFocusButton: 'cancel',
            className: 'custom-modal-wo',
            maskClosable: false,
            onOk() {
                props.closeModal()
                resetFields()
                GetKnowledgeRelated([])
                setFileList([])
                setMyPriority(undefined)
                setTempAssignGroup(undefined)
            },
            onCancel() {
                console.log('Cancelled')
            }
        })
    }

    const disabledDate = (current, disable) => {
        return current && current < moment(disable).startOf('day')
    }

    const disabledDateInvert = (current, disable) => {
        return current && current > moment(disable).startOf('day')
    }

    const handleChangeStartDate = () => {
        resetFields(['DueDate'])
    }

    return (
        <div>
            <Spin spinning={isLoading}>
                <Form>
                    <Row gutter={[16, 16]}>
                        <Col span={12} style={{ borderRight: '1px solid #E0E0E0' }}>
                            <Row gutter={[16, 16]}>
                                <Col span={8} lg={8} md={8} xs={24} style={{ borderRight: '2px solid #ddd', paddingLeft: 12 }}>
                                    <h2 className="sub-title">Priority</h2>
                                    <span>
                                        {props.priorityProblem.map((priority, index) => {
                                            return (
                                                myPriority?.priority?.name === priority.name ? (
                                                    <Tag style={{ backgroundColor: priority.tagColor, color: '#fff' }}
                                                        key={index}>{myPriority.priority?.name}</Tag>
                                                ) : null
                                            )
                                        })}
                                    </span>
                                </Col>
                                <Col span={8} lg={8} md={8} xs={24} style={{ paddingLeft: 15 }}>
                                    <h2 className="sub-title">Status</h2>
                                    <span>{myStatus?.status}</span>
                                </Col>
                                <Col span={8} lg={8} md={8} xs={24}>
                                    <div><p><b>Type : </b></p></div>
                                    <Tag>{props.OpenPTaskName}</Tag>
                                </Col>
                            </Row>

                            {/* <Row>
                                <div><p><b>Type : </b></p></div>
                                <Tag>{props.OpenPTaskName}</Tag>
                            </Row> */}

                            <Row gutter={[16, 16]}>
                                <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item label="Impact" className='custom-formLabel-wo'>
                                        {getFieldDecorator('Impact', {
                                            rules: [{
                                                required: true
                                            }]
                                        })(
                                            <Select placeholder="Select an impact"
                                                onSelect={() => callApiGetPriorities()}>
                                                {props.impactProblem.length === 0 ? null : (props.impactProblem.map((item: PriorityLevelProblem, index: number) => {
                                                    return <Option value={item.id}
                                                        key={index}>{item.name}</Option>
                                                }))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item label="Urgency" className='custom-formLabel-wo'>
                                        {getFieldDecorator('Urgency', {
                                            rules: [{
                                                required: true
                                            }]
                                        })(
                                            <Select placeholder="Select an urgency"
                                                onSelect={() => callApiGetPriorities()}>
                                                {props.urgencieProblem.length === 0 ? null : (props.urgencieProblem.map((item: PriorityLevelProblem, index: number) => {
                                                    return <Option value={item.id}
                                                        key={index}>{item.name}</Option>
                                                }))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={24} lg={24} md={24} sm={24} xs={24} style={{ marginTop: 5 }}>
                                    <Form.Item label="Topic" className='custom-formLabel-wo'>
                                        {getFieldDecorator('topic',
                                            {
                                                rules: [{
                                                    required: true,
                                                    message: 'Topic is required'
                                                },
                                                { validator: validateTopicAndDes }]
                                            })(
                                            <Input type="text" placeholder="Topic" maxLength={255} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24} lg={24} md={24} sm={24} xs={24} style={{ height: 140, marginBottom: 7 }}>
                                    <Form.Item label="Description" className='custom-formLabel-wo'>
                                        {getFieldDecorator('description',
                                            {
                                                rules: [{
                                                    required: true,
                                                    message: 'Description is required.'
                                                }, { validator: validateTopicAndDes }]
                                            })(
                                            <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 4 }} placeholder={'Description'} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} lg={24} md={24} sm={24} xs={24}
                                    style={{ marginBottom: '20px', marginTop: '10px' }}>
                                    <Upload {...uploadProps}
                                        listType="picture"
                                        className={'upload-list-inline'}
                                        fileList={fileList}
                                        onPreview={(e: any) => handlePreview(e)}
                                    >
                                        <Button disabled={fileList.length >= 5}>
                                            <Icon type="upload" /> Upload
                                        </Button>
                                    </Upload>
                                </Col>
                            </Row>
                            <Modal visible={previewVisible} footer={null}
                                onCancel={() => setPreviewVisible(false)}>
                                <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                            </Modal>

                            <Row gutter={16}>
                                <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item label="Assignment Group" className='custom-formLabel-wo'>
                                        {getFieldDecorator('Assignment_group', {
                                            initialValue: tempAssignGroup?.name,
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Assignment Group is required.'
                                                }
                                            ]
                                        })(
                                            <AutoComplete
                                                dataSource={props.supportTeamsWithPermission?.map((item, index) => {
                                                    return <Option value={item.name?.toString()}
                                                        key={index}>{item.name}</Option>
                                                })}
                                                onChange={(e) => checkAssignee(e)}
                                                onSelect={(e) => FilterSupportTeam(e)}
                                                onBlur={(e) => onBlurTeam(e)}
                                                allowClear={true}
                                                placeholder={'Select an assignment group'}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item label="Assigned To" className='custom-formLabel-wo'>
                                        {getFieldDecorator('Assigned_to', {
                                            initialValue: tempAssignTo,
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Assigned to is required'
                                                }
                                            ]
                                        })(
                                            <AutoComplete
                                                dataSource={assignee?.map((item, index: number) => {
                                                    return <Option value={item.id?.toString()}
                                                        key={index}>{item.users.fullName}</Option>
                                                })}
                                                onChange={changeAssigned}
                                                onFocus={() => FilterAssignee()}
                                                onBlur={(e) => onBlurAssignee(e)}
                                                allowClear={true}
                                                placeholder={'Select an assignee'}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>

                                <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item label={'Start Date'} className='custom-formLabel-wo'>
                                        {getFieldDecorator('StartDate', {
                                            rules: [{ required: false, message: 'Please enter a Start Date.' }]
                                        })(
                                            <DatePicker style={{ width: '100%' }} onChange={() => handleChangeStartDate()} disabledDate={getFieldValue('DueDate') ? (current) => disabledDateInvert(current, getFieldValue('DueDate')) : undefined} />
                                        )}
                                    </Form.Item>
                                </Col>

                                <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item label={'Due Date'} className='custom-formLabel-wo'>
                                        {getFieldDecorator('DueDate', {
                                            rules: [{ required: false, message: 'Please enter a Due Date.' }]
                                        })(
                                            <DatePicker style={{ width: '100%' }} disabledDate={(current) => disabledDate(current, getFieldValue('StartDate'))} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                {customFieldLayout()}
                            </Row>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={12} lg={12}>
                            <div>
                                {/* {props.OpenPTaskName === 'General' ? <div>
                                    <h2 className="sub-title"><Icon type="file-add" /> Work Note</h2>
                                    <hr style={{ display: 'block', height: '1px', border: 0, borderTop: '1px solid #ccc' }}/>
                                    <div className='custom-formLabel-wo' style={{ marginBottom: 5 }}><b>Notify Agent</b></div>
                                    <Button htmlType="button" type={NotifyAgentType === 'Note' ? 'primary' : 'default'} style={{ marginRight: 5 }} onClick={() => setNotifyAgentType('Note')}> <Icon type="lock" /> Noted</Button>
                                    <Form.Item label={'Description'} className='custom-formLabel-wo'>
                                        <ReactQuill modules={modules} formats={formats} value={detail} onChange={(e: any) => { onTextEditorChange(e) }} style={{ overflow: 'hidden' }} />
                                    </Form.Item>
                                    <Row>
                                        <Col span={24} lg={24} md={24} sm={24} xs={24}
                                            style={{ marginBottom: '20px', marginTop: '10px' }}>
                                            <Upload {...uploadProps2}
                                                listType="picture"
                                                className={'upload-list-inline'}
                                                fileList={fileList2}
                                                onPreview={(e: any) => handlePreview2(e)}
                                                disabled={true}
                                            >
                                                <Button disabled={fileList2.length >= 5}>
                                                    <Icon type="upload" /> Upload
                                                </Button>
                                            </Upload>
                                        </Col>
                                    </Row>
                                    <Modal visible={previewVisible2} footer={null}
                                        onCancel={() => setPreviewVisible2(false)}>
                                        <img alt={previewImage2} style={{ width: '100%' }} src={previewImage2} />
                                    </Modal>
                                </div> : <div>
                                    <h2 className="sub-title">Related</h2>
                                    <RelatedProblemHome isShowOnlyTabKM={true}/>
                                </div>} */}
                                <div>
                                    <h2 className="sub-title">Related</h2>
                                    <RelatedProblemHome isShowOnlyTabKM={true} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <hr style={{ display: 'block', height: '1px', border: 0, borderTop: '1px solid #ccc' }} />
                    <Row>
                        <Col span={24}>
                            <Form.Item style={{ textAlign: 'end', marginTop: 10 }}>
                                <Button htmlType="button"
                                    onClick={() => habdleCloseModal()}
                                    style={{ marginRight: 10 }}>
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="button" onClick={handleSubmit} loading={isLoadingSubmit}
                                    disabled={!checkRolesState(RoleType.Problem, 'CreateProblemTask')}
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </div>
    )
}

const ProblemTaskFormWrapped = Form.create<Props>({ name: 'ProblemTaskFormWrapped' })(ProblemTaskForm)
export default connect(mapStateToProps, {
    getAllCategory,
    getAllSubCategory,
    getAllItems,
    getAllSupportTeamByPermission,
    getAllPriorityProblem,
    getAllImpactPriorty,
    getAllUrgencyPriorty,
    getPriorityConfigurationProblem,
    getAllCustomFieldProblem
})(ProblemTaskFormWrapped)
