import React, {useEffect, useState} from 'react'
import {Card, Col, Modal, Row, Upload, Icon} from 'antd'
import {getCommentListByIncidentId} from './service'
import moment from 'moment'
import {StoreState} from '../../store'
import {connect} from 'react-redux'
import {CommentType} from './model'
import {FileUpload} from '../../common-model'
import {UploadFile} from 'antd/es/upload/interface'
import {FetchingState, fetchWithState, SpinningFetcher} from '../../common-components'
import {CommentWebSocketChannel} from './web-socket-channel'
import AutoAvatar from '../../common-components/autoAvatar'
import CommentRender from './CommentRender'
import TimeRender from './TimeRender'
import exportIcon from '../../common-file/icon-file'
import { DownloadAnyFile } from '../../common-misc'
import ImageIsResolution from '../../assets/images/Vector.png'

const mapStateToProps = (state: StoreState) => ({
    comments: (state.comments || [])
})

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getCommentListByIncidentId: (id: string) => Promise<number>
}

interface Params {
    incidentId: string
    isShowResolution: boolean
}

type Props = StateProps & DispatchProps & Params

const CommentSection: React.FC<Props> = (props: Props) => {
    const [, setFileList] = useState<any>([])
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [commentsFetchingState, setCommentFetchingState] = useState<FetchingState>(FetchingState.NotStarted)

    useEffect(() => {
        CommentWebSocketChannel.subscribe()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.incidentId !== null) {
            CommentWebSocketChannel.subscribeToIncidentIds([props.incidentId])
            reloadAllComments()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.incidentId])

    useEffect(() => {
        fetchWithState(commentsFetchingState, setCommentFetchingState, (): Promise<number> => {
            return props.getCommentListByIncidentId(props.incidentId)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.incidentId, commentsFetchingState])

    const reloadAllComments = () => {
        setCommentFetchingState(FetchingState.NotStarted)
    }

    const handleChange = (fileList: any) => setFileList(fileList)

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png') || ((file.url) as string).includes('jpeg') || ((file.url) as string).includes('PNG')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const convertFileListFormat = (fileList: FileUpload[]) => {
        return fileList.map((dataFile, index) => {
            return {
                uid: index,
                name: `${dataFile.name}`,
                status: 'done',
                url: dataFile.url,
                thumbUrl: exportIcon(dataFile.type!!)
            } as unknown as UploadFile
        })
    }

    const handleDownload = (file) => {
        DownloadAnyFile(file)
    }

    const renderComments = () => {
        const findIsResolution = props.comments.find((data) => data?.isResolution === true)
        if (findIsResolution && props.isShowResolution) {
            return <div key={findIsResolution.id!!} style={{ marginBottom: '5px' }}>
                <Card size={'small'} style={{ boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }} className='CardSmallNoPadding'>
                    <Row style={{ alignItems: 'center', display: 'flex' }}>
                        <Col lg={2} md={4} xs={5} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <AutoAvatar title={findIsResolution.createdBy || ''} broken={false} username={findIsResolution.createdBy?.toString()}/>
                        </Col>
                        <Col lg={20} md={16} xs={14}>
                            <span className='feck-link'>{findIsResolution.createdBy}</span>
                            <span style={{ color: 'gray', fontSize: '12px', fontStyle: 'italic', marginLeft: 3 }}>
                                {findIsResolution?.type === CommentType.AddNote ? 'added a ' : null}
                                {findIsResolution?.type === CommentType.Forward ? 'forwarded ' : null}
                                {findIsResolution?.type === CommentType.Reply ? 'replied ' : null}
                                {findIsResolution?.type === CommentType.AddNote ? findIsResolution.visibleToCustomer ? 'public note ' : 'private note ' : null}
                                <TimeRender Date={findIsResolution.createdDatetime!!}/>
                                {' '} ago ({moment(findIsResolution.createdDatetime).format('YYYY-MM-DD HH:mm:ss')})
                            </span>
                        </Col>
                        <Col lg={2} md={4} xs={5} style={{textAlign: 'left'}}>
                            <img src={ImageIsResolution} alt='resolution' />
                        </Col>
                    </Row>
                    {/* <br /> */}
                    <Row style={{ alignItems: 'center' }}>
                        <Col lg={2} md={4} xs={5} style={{ justifyContent: 'center', alignItems: 'top', display: 'flex' }} >
                            {findIsResolution.visibleToCustomer ? null : <Icon type="lock" style={{ color: '#FF4003', fontSize: 16, paddingTop: '2%' }}/>}
                        </Col >
                        <Col lg={20} md={20} xs={19}>
                            {findIsResolution?.type === CommentType.Reply || findIsResolution?.type === CommentType.Forward ? (<>
                        From : <span style={{color: '#6A6C8F'}}>{findIsResolution.from}</span><br />
                        Replied to : <span style={{color: '#6A6C8F'}}>{findIsResolution.to}</span><br /><br />
                            </>) : null}
                            <CommentRender text={findIsResolution.text!!} type={findIsResolution?.type} />
                        </Col>
                        <Col lg={2} md={4} xs={5} />
                    </Row>
                    {/* <br /> */}
                    <Row>
                        <Col span={2}/>
                        <Col span={22}>
                            <Upload
                                listType="picture"
                                className={'upload-list-inline'}
                                fileList={convertFileListFormat(findIsResolution.uploadLink ? findIsResolution.uploadLink : [])}
                                onPreview={(e: any) => handlePreview(e)}
                                onChange={(e: any) => handleChange(e)}
                                onDownload={(e: any) => handleDownload(e)}
                                showUploadList={{ showRemoveIcon: false }}
                            />
                        </Col>
                    </Row>
                    <br/><br/>
                    <Modal visible={previewVisible} footer={null} onCancel={ () => setPreviewVisible(false)} >
                        <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                </Card>
            </div>
        } else {
            return props.comments.map((comment) => {
                return <div key={comment.id!!} style={{ marginBottom: '5px' }}>
                    {!comment.isResolution && !props.isShowResolution
                        ? <Card style={{ border: '1.5px solid #E0E0E0', boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }} >
                            <Row style={{ alignItems: 'center', display: 'flex' }}>
                                <Col lg={2} md={4} xs={5} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <AutoAvatar title={comment.createdBy || ''} broken={false} username={comment.createdBy?.toString()}/>
                                </Col>
                                <Col lg={22} md={20} xs={19}>
                                    <span className='feck-link'>{comment.createdBy}</span>
                                    <span style={{ color: 'gray', fontSize: '12px', fontStyle: 'italic', marginLeft: 3 }}>
                                        {comment?.type === CommentType.AddNote ? 'added a ' : null}
                                        {comment?.type === CommentType.Forward ? 'forwarded ' : null}
                                        {comment?.type === CommentType.Reply ? 'replied ' : null}
                                        {comment?.type === CommentType.AddNote ? comment.visibleToCustomer ? 'public note ' : 'private note ' : null}
                                        <TimeRender Date={comment.createdDatetime!!}/>
                                        {' '} ago ({moment(comment.createdDatetime).format('YYYY-MM-DD HH:mm:ss')})
                                    </span>
                                </Col>
                            </Row>
                            <br />
                            <Row style={{ alignItems: 'center' }}>
                                <Col lg={2} md={4} xs={5} style={{ justifyContent: 'center', alignItems: 'top', display: 'flex' }} >
                                    {comment.visibleToCustomer ? null : <Icon type="lock" style={{ color: '#FF4003', fontSize: 16, paddingTop: '2%' }}/>}
                                </Col >
                                <Col lg={20} md={20} xs={19} >
                                    {comment?.type === CommentType.Reply || comment?.type === CommentType.Forward ? (<>
                            From : <span style={{color: '#6A6C8F'}}>{comment.from}</span><br />
                            Replied to : <span style={{color: '#6A6C8F'}}>{comment.to}</span><br /><br />
                                    </>) : null}
                                    <CommentRender text={comment.text!!} type={comment?.type} />
                                </Col>
                                <Col lg={2} md={4} xs={5} />
                            </Row>
                            {/* <br /> */}
                            <Row>
                                <Col span={2}/>
                                <Col span={22}>
                                    <Upload
                                        listType="picture"
                                        className={'upload-list-inline'}
                                        fileList={convertFileListFormat(comment.uploadLink ? comment.uploadLink : [])}
                                        onPreview={(e: any) => handlePreview(e)}
                                        onChange={(e: any) => handleChange(e)}
                                        onDownload={(e: any) => handleDownload(e)}
                                        showUploadList={{ showRemoveIcon: false }}
                                    />
                                </Col>
                            </Row>
                            <Modal visible={previewVisible} footer={null} onCancel={ () => setPreviewVisible(false)} >
                                <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </Card> : null}
                </div>
            })
        }
    }

    return (
        <>
            <br />
            <SpinningFetcher fetchingState={commentsFetchingState}>
                {renderComments()}
            </SpinningFetcher>
        </>
    )
}

export default connect(mapStateToProps, {
    getCommentListByIncidentId
})(CommentSection)
