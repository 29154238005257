/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { Table, Tag, message } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { StoreState } from '../store'
import { getIncidentBacklog, getIncidentBacklogNOSLA } from './service'
import { getAllPriority, PriorityLevel } from '../incident-management/priority'
import { checkRolesState } from '../authorization-module/permissions/service'
import { RoleType } from '../authorization-module/permissions'
import { replaceSpae } from '../common-misc'
import { waitingGetSessions } from '../guard/route'

const mapStateToProps = (state: StoreState) => {
    return {
        DashboardIncidentBacklogMyTicket: state.DashboardIncidentBacklogMyTicket,
        priority: state.priorities
    }
}
interface DispatchProps {
    getIncidentBacklog: (showBy, created?, startDate?, endDate?, sortBy?, orderBy?, skip?, limit?) => Promise<any>
    getIncidentBacklogNOSLA: (showBy, created?, startDate?, endDate?, sortBy?, orderBy?, skip?, limit?) => Promise<any>
    getPriorityLevelByType: (type: string) => Promise<number>
}

type StateProps = ReturnType<typeof mapStateToProps>

interface Param {
    show: string,
    create: string,
    start?: string,
    end?: string,
    getChangeTable?: (sortBy, orderBy, skip, limit) => void
}

type Props = Param & StateProps & DispatchProps

const TableSLAIncidentForDashboard: React.FC<Props> = (props: Props) => {
    const [page, setPage] = useState<number>(1)
    const [orderBy, setOrderBy] = useState<string>('asc')
    const [sortBy, setSortBy] = useState<string>('number')
    const [pageSize, setPageSize] = useState<number>(10)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [data, setData] = useState<any[]>([])
    const [total, setTotal] = useState<number>(0)
    const location = useLocation()

    useEffect(() => {
        props.getPriorityLevelByType('priority')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setIsLoading(true)
        if (location.pathname === '/ReportDashBoardNoneSLA') {
            setTimeout(() => {
                props.getIncidentBacklogNOSLA(replaceSpae(props.show), props.create, props.start, props.end, sortBy, orderBy, (page - 1) * pageSize, pageSize).then((res) => {
                    if (res) {
                        setTotal(res.total)
                        setData(res.data)
                        if (res.total === 0 && location.pathname?.tolowercase() !== '/dashboard-all') {
                            message.error('No tickets match this filter. Please adjust the filter.')
                        }
                    }
                }).finally(() => { setIsLoading(false) })
            }, 1000)
        } else {
            props.getIncidentBacklog(replaceSpae(props.show), props.create, props.start, props.end, sortBy, orderBy, (page - 1) * pageSize, pageSize).then((res) => {
                if (res) {
                    setTotal(res.total)
                    setData(res.data)
                    if (res.total === 0 && location.pathname?.tolowercase() !== '/dashboard-all') {
                        message.error('No tickets match this filter. Please adjust the filter.')
                    }
                }
            }).finally(() => { setIsLoading(false) })
        }
        if (props.getChangeTable) {
            props.getChangeTable(sortBy, orderBy, 0, props.DashboardIncidentBacklogMyTicket.total)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, orderBy, sortBy, props.show, props.create, props.start, props.end, pageSize])

    useEffect(() => {
        setPage(1)
        // setIsLoading(true)
        // if (location.pathname === '/ReportDashBoardNoneSLA') {
        //     props.getIncidentBacklogNOSLA(replaceSpae(props.show), props.create, props.start, props.end, sortBy, orderBy, 0, pageSize).finally(() => { setIsLoading(false) })
        // } else {
        //     props.getIncidentBacklog(replaceSpae(props.show), props.create, props.start, props.end, sortBy, orderBy, 0, pageSize).finally(() => { setIsLoading(false) })
        // }
        // if (props.getChangeTable) {
        //     props.getChangeTable(orderBy, sortBy, 0, props.DashboardIncidentBacklogMyTicket.total)
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, props.show, props.create])

    const SLA = [
        {
            title: 'Ticket Number',
            dataIndex: 'number',
            sorter: true,
            render: (row, record) => (<>
                {checkRolesState(RoleType.Incident, 'ViewIncident')
                    ? <Link to={`/IncidentDetailsView/${record.id}`} target="_blank" onClick={() => waitingGetSessions()}>{row}</Link>
                    : <>{row}</>}
            </>)
        },
        {
            title: 'Topic',
            dataIndex: 'subject',
            sorter: true,
            width: '10%',
            render: (data) => (<>
                <span>{(data as string).length > 25 ? (data as string).substring(0, 25) + '...' : data}</span>
            </>)
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            sorter: true,
            render: (priority) => {
                let colorCode: string = ''
                props.priority.forEach((it: PriorityLevel) => {
                    if (priority === it.name) {
                        colorCode = it.tagColor
                    }
                })

                return (
                    <>
                        <Tag style={{ backgroundColor: colorCode, color: '#ffffff' }}>{priority}</Tag>
                    </>
                )
            }
        },
        {
            title: 'Category',
            dataIndex: 'category',
            sorter: true,
            // width: '5%',
            render: (data) => {
                return (
                    <>
                        <span>{data}</span>
                    </>
                )
            }
        },
        {
            title: 'SubCategory',
            dataIndex: 'subcategory',
            sorter: true,
            // width: '5%',
            render: (data) => {
                return (
                    <>
                        <span>{data}</span>
                    </>
                )
            }
        },
        {
            title: 'Item',
            dataIndex: 'item',
            sorter: true,
            // width: '5%',
            render: (data) => {
                return (
                    <>
                        <span>{data}</span>
                    </>
                )
            }
        },
        {
            title: 'Channel',
            dataIndex: 'channel',
            sorter: true,
            width: '5%',
            render: (data) => {
                return (
                    <>
                        <span>{data}</span>
                    </>
                )
            }
        },
        {
            title: 'Incident Status',
            dataIndex: 'status',
            sorter: true
        },
        {
            title: 'Assignment group',
            dataIndex: 'supportTeam',
            sorter: true,
            render: (data) => (<>
                {data ? <span>{(data as string).length > 15 ? (data as string).substring(0, 15) + '...' : data}</span> : ''}
            </>)
        },
        {
            title: 'Assigned to',
            dataIndex: 'assignee',
            sorter: true,
            render: (data) => (<>
                {data ? <span>{(data as string).length > 10 ? (data as string).substring(0, 10) + '...' : data}</span> : ''}
            </>)
        },
        {
            title: 'SLA Name',
            dataIndex: 'slaPolicyName',
            width: '5%',
            sorter: true,
            render: (data) => (<>
                {data ? <span>{(data as string).length > 25 ? (data as string).substring(0, 25) + '...' : data}</span> : ''}
            </>)
        },
        {
            title: 'SLA Goal',
            dataIndex: 'slaGoalName',
            sorter: true,
            width: '5%'
        },
        {
            title: 'Start Time',
            dataIndex: 'measurementClockStartTime',
            sorter: true,
            width: '5%',
            render: (row) => {
                if (row) {
                    return <span>{row.replace('.0', '')}</span>
                }
            }
        },
        {
            title: 'Due Date',
            dataIndex: 'slaTargetTime',
            sorter: true,
            width: '5%',
            render: (row) => {
                if (row) {
                    return <span>{row.replace('.0', '')}</span>
                }
            }
        },
        {
            title: 'SLA',
            dataIndex: 'slaTag',
            sorter: true,
            width: '5%',
            render: (data) => {
                return (
                    <>
                        <span>{data}</span>
                    </>
                )
            }
        },
        {
            title: 'Completed Time',
            dataIndex: 'measurementClockStopTime',
            sorter: true,
            render: (row) => {
                if (row) {
                    return <span>{row.replace('.0', '')}</span>
                }
            }
        },
        {
            title: 'Resolved Time',
            dataIndex: 'resolvedTime',
            sorter: true,
            render: (row) => {
                if (row) {
                    return <span>{row.replace('.0', '')}</span>
                }
            }
        }
    ]

    const INCIDENT = [
        {
            title: 'Ticket Number',
            dataIndex: 'number',
            sorter: true,
            render: (data, record) => (<>
                {checkRolesState(RoleType.Incident, 'ViewIncident')
                    ? <Link to={`/IncidentDetailsView/${record.id}`} target="_blank" onClick={() => waitingGetSessions()}>{data}</Link>
                    : <>{data}</>}
            </>)
        },
        {
            title: 'Topic',
            dataIndex: 'subject',
            sorter: true,
            render: (data) => (<>
                <span>{(data as string).length > 25 ? (data as string).substring(0, 25) + '...' : data}</span>
            </>)
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            sorter: true,
            render: (priority) => {
                let colorCode: string = ''
                props.priority.forEach((it: PriorityLevel) => {
                    if (priority === it.name) {
                        colorCode = it.tagColor
                    }
                })

                return (
                    <>
                        <Tag style={{ backgroundColor: colorCode, color: '#ffffff' }}>{priority}</Tag>
                    </>
                )
            }
        },
        {
            title: 'Category',
            dataIndex: 'category',
            sorter: true,
            width: '8%',
            render: (data) => {
                return (
                    <>{data}
                    </>
                )
            }
        },
        {
            title: 'SubCategory',
            dataIndex: 'subcategory',
            sorter: true,
            width: '9%',
            render: (data) => {
                return (
                    <>
                        <span>{data}</span>
                    </>
                )
            }
        },
        {
            title: 'Item',
            dataIndex: 'item',
            sorter: true,
            width: '8%',
            render: (data) => {
                return (
                    <>
                        <span>{data}</span>
                    </>
                )
            }
        },
        {
            title: 'Channel',
            dataIndex: 'channel',
            sorter: true,
            width: '8%',
            render: (data) => {
                return (
                    <>
                        <span>{data}</span>
                    </>
                )
            }
        },
        {
            title: 'Incident Status',
            dataIndex: 'status',
            sorter: true
        },
        {
            title: 'Assignment group',
            dataIndex: 'supportTeam',
            sorter: true,
            render: (data) => (<>
                {data ? <span>{(data as string).length > 15 ? (data as string).substring(0, 15) + '...' : data}</span> : ''}
            </>)
        },
        {
            title: 'Assigned to',
            dataIndex: 'assignee',
            sorter: true,
            render: (data) => (<>
                {data ? <span>{(data as string).length > 10 ? (data as string).substring(0, 10) + '...' : data}</span> : ''}
            </>)
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDatetime',
            sorter: true
        }
    ]

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
    }

    const setSorterField = (sorter: any) => {
        setOrderBy('ASC')
        if (sorter.field) {
            setSortBy(sorter.field)
        } else {
            setSortBy('number')
        }
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        if (sorter.order === 'ascend') {
            setSorterField(sorter)
        } else if (sorter.order === 'descend') {
            setOrderBy('DESC')
            if (sorter.field) {
                setSortBy(sorter.field)
            } else {
                setSortBy('number')
            }
        } else {
            setSorterField(sorter)
        }
        setPageSize(pagination.pageSize)
    }

    return (
        <>
            <Table
                columns={location.pathname.length < 17 ? SLA : INCIDENT}
                dataSource={data || []}
                rowKey= {obj => obj.number + obj.slaGoalName + obj.slaPolicyName}
                pagination={{
                    pageSize: pageSize,
                    current: page,
                    total: total,
                    onChange: (event) => { handleOnClickPageNumber(event) },
                    showSizeChanger: true
                }}
                onChange={handleChangeTable}
                loading={isLoading}
                scroll={{ x: 'max-content' }}
            />
        </>
    )
}

export default connect(mapStateToProps, {
    getIncidentBacklog, getPriorityLevelByType: getAllPriority, getIncidentBacklogNOSLA
})(TableSLAIncidentForDashboard)
