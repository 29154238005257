/* eslint-disable no-useless-escape */
import React from 'react'
import { FormComponentProps } from 'antd/lib/form'
import { Col, Row, Form, Input, Button, Select } from 'antd'
import { buildingHierarchyOption, locationHierarchyOption, siteHierarchyOption } from '../model'

interface Params {
    dataSite: siteHierarchyOption[]
    dataLocation: locationHierarchyOption[]
    dataBuilding: buildingHierarchyOption[]
    optionLocation: locationHierarchyOption[]
    optionBuilding: buildingHierarchyOption[]
    selectSite: Function
    selectLocation: Function
    isDisableLocation: boolean
    isDisableBuilding: boolean
    isDisableFloor: boolean
    setIsDisableFloor: Function
    onCancel: Function
}

type Props = Params & FormComponentProps
const { Option } = Select
const RenderFloorTab: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, getFieldValue, resetFields } = props.form

    const validateFloor = (_: any, value: any, callback) => {
        const pattern = /^[A-Za-z0-9ก-๙]{1}/
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        // eslint-disable-next-line no-useless-escape
        if (value) {
            if (pattern.test(value.replace(/ +/g, '').trim())) {
                const filterLocation = props.dataLocation.filter((it) => it.locationName === getFieldValue('locationFloor')).flatMap((it) => it.buildings)
                const findDataFloor = filterLocation.filter((it) => it.buildingName === getFieldValue('buildingFloor')).flatMap((it) => it.floors)
                const isDuplicate = findDataFloor?.some((it) => it.floorName.toLowerCase().trim() === value.toLowerCase().trim())
                if (isDuplicate) {
                    callback('Floor Name already exists. It must be unique. Please try another one.')
                } else {
                    callback()
                }
            } else {
                if (format.test(value.replace(/ +/g, '').trim())) {
                    callback(`Field Label must not contain these characters: ${format}`)
                }
                callback('Default value is not valid.')
            }
        } else {
            callback()
        }
    }

    const handleSelectSite = (site: string) => {
        props.selectSite(site)
    }

    const handleSelectLocation = (location: string) => {
        props.selectLocation(location)
    }

    const handleCancel = () => {
        props.onCancel()
    }

    const handleChangeSite = () => {
        resetFields(['locationFloor', 'buildingFloor', 'floor'])
    }

    const handleChangeLocation = () => {
        resetFields(['buildingFloor', 'floor'])
    }

    const handleChangeBuilding = () => {
        resetFields(['floor'])
    }

    return (
        <Row gutter={[10, 10]}>
            <Col span={4} md={4} sm={24}>
                <Form.Item label={'Site Name'}>
                    {getFieldDecorator('siteFloor',
                        {
                            rules: [{ required: true, message: 'Site Name is required.' }]
                        })(
                        <Select placeholder="Site Name" onSelect={handleSelectSite} onChange={handleChangeSite} allowClear>
                            {props.dataSite.map((it, index) => {
                                return (<Option value={it.siteName} key={index}>{it.siteName}</Option>)
                            })}
                        </Select>
                    )}
                </Form.Item>
            </Col>
            <Col span={4} md={4}>
                <Form.Item label={'Location Name'}>
                    {getFieldDecorator('locationFloor',
                        {
                            rules: [{ required: true, message: 'Location Name is required.' }]
                        })(
                        <Select placeholder="Location Name" disabled={props.isDisableLocation} onSelect={handleSelectLocation} onChange={handleChangeLocation} allowClear>
                            {props.optionLocation.map((it, index) => {
                                return (<Option value={it.locationName} key={index}>{it.locationName}</Option>)
                            })}
                        </Select>
                    )}
                </Form.Item>
            </Col>
            <Col span={4} md={4}>
                <Form.Item label={'Building Name'}>
                    {getFieldDecorator('buildingFloor',
                        {
                            rules: [{ required: true, message: 'Building Name is required.' }]
                        })(
                        <Select placeholder="Building" disabled={props.isDisableBuilding} onSelect={() => props.setIsDisableFloor(false)} onChange={handleChangeBuilding} allowClear>
                            {props.optionBuilding.map((it, index) => {
                                return (<Option value={it.buildingName} key={index}>{it.buildingName}</Option>)
                            })}
                        </Select>
                    )}
                </Form.Item>
            </Col>
            <Col span={4} md={4}>
                <Form.Item label={'Floor Name'}>
                    {getFieldDecorator('floor',
                        {
                            rules: [
                                { required: true, message: 'Floor Name is required.' },
                                { validator: validateFloor }
                            ]
                        })(
                        <Input placeholder={'Floor Name'} disabled={props.isDisableFloor} allowClear />
                    )}
                </Form.Item>
            </Col>
            <Col span={2}></Col>
            <Col span={3} lg={3} md={3} sm={24} xs={24}>
                <Form.Item style={{ marginTop: 40 }}>
                    <Button type="default" style={{ width: '-webkit-fill-available' }} htmlType="reset" onClick={handleCancel}>Cancel</Button>
                </Form.Item>
            </Col>
            <Col span={3} lg={3} md={3} sm={24} xs={24}>
                <Form.Item style={{ marginTop: 40 }}>
                    <Button className="addCriteriaButton" style={{ width: '-webkit-fill-available' }}
                        htmlType="submit">Submit</Button>
                </Form.Item>
            </Col>
        </Row>
    )
}

export default RenderFloorTab
