import React, {useEffect, useState} from 'react'
import {Col, DatePicker, Form, message, Row, Select} from 'antd'
import {FormComponentProps} from 'antd/es/form'
import {defaults} from 'react-chartjs-2'
import SatLayoutChart from './SatLayoutChart'
import {connect} from 'react-redux'
import {StoreState} from '../store'
import {getEmailSatisfactionNotDone, getSatDashboard} from './service'
import {ChartType, DataTotal} from './model'

// https://www.chartjs.org/docs/latest/configuration/tooltip.html <-- doc
// defaults.global.legend.position = 'bottom'
defaults.global.tooltips.backgroundColor = '#FFD8BF'
defaults.global.tooltips.bodyFontColor = '#fa5414'

const mapStateToProps = (state: StoreState) => {
    return {
        DashboardResponded: state.DashboardResponded,
        DashboardRemainder: state.DashboardRemainder
    }
}

interface DispatchProps {
    getSatDashboard: (created, Chart, startDate?, endDate?) => Promise<number>
    getEmailSatisfactionNotDone: (create, start, end) => Promise<DataTotal>
}

type StateProps = ReturnType<typeof mapStateToProps>

type Props = FormComponentProps & StateProps & DispatchProps
const { Option } = Select
const { RangePicker } = DatePicker
const CustomerSatDash: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator } = props.form
    const [created, setCreated] = useState<string>('last30Days')
    const [time, setTime] = useState<string[]>([])

    useEffect(() => {
        FetchDataSat()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [created, time])

    const FetchDataSat = () => {
        fetchData(ChartType.Responded, time)
        fetchData(ChartType.Remainder, time)
        props.getEmailSatisfactionNotDone(created, time[0], time[1]).catch((err) => message.error(`Unable to retrieve all statuses. Please try again later. ${err}`))
    }

    const fetchData = async (chart: ChartType, Time: string[]) => {
        if (Time.length !== 0) {
            await props.getSatDashboard(created, chart, Time[0], Time[1])
        } else {
            await props.getSatDashboard(created, chart)
        }
    }

    const changeCreated = (e) => {
        if (e !== 'anytime') {
            setTime([])
        }
        setCreated(e)
    }

    const changeTime = (value, dateString) => {
        setTime(dateString)
    }

    return (
        <div>
            <Form>
                <Row gutter={16}>
                    <Col span={20}/>
                    <Col xs={24} sm={24} md={4} lg={4}>
                        <span>Created :</span>
                        <Form.Item >
                            {getFieldDecorator('Created', {
                                initialValue: created
                            })(
                                <Select onChange={(e) => changeCreated(e)}>
                                    <Option value="last30Days">Last 30 days</Option>
                                    <Option value="today">Today</Option>
                                    <Option value="yesterday">Yesterday</Option>
                                    <Option value="thisWeek">This Week</Option>
                                    <Option value="thisMonth">This Month</Option>
                                    <Option value="anytime">Select Time Period</Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={18} />
                    <Col span={6}>{created === 'anytime' ? (<>
                        <Form.Item >
                            {getFieldDecorator('TimePeriod')(
                                <RangePicker
                                    // showTime={{ format: 'HH:mm' }}
                                    onChange={changeTime}
                                    format="YYYY-MM-DD"
                                    placeholder={['Start Time', 'End Time']}
                                    style={{
                                        width: '100%'
                                    }}
                                />
                            )}
                        </Form.Item>
                    </>) : null}</Col>
                </Row>
            </Form>
            <br />

            <SatLayoutChart onCreate={created} onTime={time} />

        </div>
    )
}

const SatWrappedNormalDashboardForm = Form.create<Props>()(CustomerSatDash)

export default connect(mapStateToProps, {
    getSatDashboard: getSatDashboard, getEmailSatisfactionNotDone: getEmailSatisfactionNotDone
})(SatWrappedNormalDashboardForm)
