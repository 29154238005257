import React, { memo, FC, useEffect, useState } from 'react'
import {Select, Col, Row, Card, Form} from 'antd'
import { Handle, Position, NodeProps, Connection, Edge, useUpdateNodeInternals } from 'react-flow-renderer'
import './dnd.css'
import approvalIcon from '../../../../assets/images/approval.png'
import { Properties } from '../../Service/Model'
import { StoreState } from '../../../../store'
import { connect } from 'react-redux'

const onConnect = (params: Connection | Edge) => console.log('handle onConnect', params)

const mapStateToProps = (state: StoreState) => {
    return {
        supportTeams: state.supportTeams
    }
}
const { Option } = Select
type StateProps = ReturnType<typeof mapStateToProps>
type Props = StateProps & NodeProps
const Approval: FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue } = props.data.form
    const updateNodeInternals = useUpdateNodeInternals()
    const [properties, setProperties] = useState<Properties>(props.data.properties)
    const isView = window.location.pathname.includes('/WorkflowFormView')
    const isPreviewWorkflow = window.location.pathname.includes('WoFormEdit')

    useEffect(() => {
        setFieldsValue({
            approvalGroupApproval: props.data.properties.supportTeam
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    useEffect(() => {
        updateNodeInternals(props.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data, props.id, updateNodeInternals])

    useEffect(() => {
        props.data.properties = properties
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [properties, props.data])

    const onChangeTeam = (e) => {
        const findAssigngroupId = props.supportTeams.find((data) => { return data.name === e })
        setProperties(prevState => {
            return {
                ...prevState,
                supportTeam: e,
                assignmentGroupId: Number(findAssigngroupId?.id)
            }
        })
    }

    return (
        <>
            <Handle type="target" position={Position.Top} onConnect={onConnect} />
            <Card
                title={
                    <div>
                        <Row>
                            <Col span={5} className="dotForWorkflowIcon" style={{backgroundColor: '#4DC977'}}><img src={approvalIcon} alt='approvalIcon' className="workFlowIcon" /></Col>
                            <Col span={19} style={{marginLeft: '10px', width: '80%'}}><p>Approval</p></Col>
                        </Row>
                    </div>
                }
                className="targetHandleStyle"
            >
                <Form.Item label="Approval group">
                    {getFieldDecorator('approvalGroupApproval' + props.id, {
                        initialValue: props.data?.properties?.supportTeam || undefined,
                        rules: [{
                            required: true,
                            message: 'Please enter your Approval Group.'
                        }]
                    })(
                        <Select style={{ width: '100%' }} placeholder="Select Approval group" allowClear onChange={(e) => onChangeTeam(e)} disabled={isView || isPreviewWorkflow}>
                            {props.supportTeams?.map((supportTeam, index) => {
                                return (<Option key={index} value={supportTeam.name}>{supportTeam.name}</Option>)
                            })}
                        </Select>
                    )}
                </Form.Item>
            </Card>
            <Handle type="source" position={Position.Bottom} id="a" isConnectable={props.isConnectable} />
        </>
    )
}

export default connect(mapStateToProps, {})(memo(Approval))
