import React, {useState} from 'react'
import {
    Row, Col, Divider, Button, Modal, Tooltip, AutoComplete, message, Form, Spin
} from 'antd'
import emoGood from '../../assets/images/satGood.png'
import emoNormal from '../../assets/images/satNormal.png'
import emoBad from '../../assets/images/satBad.png'
import {IncidentWithSlaCalculations} from '.'
import {markSatisfactionDone, sendSurvey, setSatisfaction} from './service'
import {searchAllPeople} from '../requester'
import {DataSourceItemObject} from 'antd/es/auto-complete'
import {People} from '../../authorization-module/people/model'
import {SelectValue} from 'antd/lib/select'
import {SatisfactionDone} from './model'
import TenantShareData from '../../authorization-module/login/TenantShareData'
import { encryptBody } from '../../common-misc/encryptBody'
import { FormComponentProps } from 'antd/es/form'
import { RoleType, checkRolesState } from '../../authorization-module/permissions'

interface Param {
    incident: IncidentWithSlaCalculations
    setModalClose: Function
    isOpenfromList?: boolean
}

type Props = Param & FormComponentProps

const CustomerSatModal: React.FC<Props> = (props: Props) => {
    const [requesterSuggestions, setRequesterSuggestions] = useState<People[]>([])
    const [requester, setRequester] = useState<People | undefined>()
    const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false)
    const [isSelectRequestLoading, setIsSelectRequestLoading] = useState<boolean>(false)
    const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false)
    const { getFieldDecorator, resetFields, getFieldValue } = props.form
    const onSearchRequester = (searchText: string) => {
        searchAllPeople(searchText).then((res: People[]) => {
            setRequesterSuggestions(res)
        })
    }

    const onSelectRequest = (value: SelectValue) => {
        const requester = requesterSuggestions.find((it) => it.id?.toString() === value)
        setRequester(requester)
    }

    function showConfirm(emo: string) {
        Modal.confirm({
            title: 'Are you sure ?',
            content: (
                <div>
                    You selected "{emo}". Do you want to submit your feedback ?
                </div>
            ),
            iconType: 'info-circle',
            okText: 'Confirm',
            cancelText: 'Cancel',
            okButtonProps: {
                type: 'primary'
            },
            cancelButtonProps: {
                type: 'default'
            },
            onOk() {
                if (!checkRolesState(RoleType.Incident, 'SatisfactionTicket') === false) {
                    const satisfactionDone: SatisfactionDone = {
                        incidentId: props.incident.id!!,
                        satResult: emo,
                        tenantId: parseInt(TenantShareData.getInstance().tenant!!) || parseInt(sessionStorage.getItem('tenant')!!)
                    }
                    setIsLoadingImage(true)
                    setSatisfaction(props.incident.id, encryptBody(JSON.stringify(satisfactionDone)))
                        .then(() => {
                            markSatisfactionDone(props.incident.id)
                                .then(() => window.location.reload())
                        })
                        .catch((err) => {
                            message.error(err)
                        })
                        .finally(() => {
                            props.setModalClose(false)
                            setIsLoadingImage(false)
                        })
                } else {
                    message.warning("Sorry, you don't have permission to access this data.")
                }
            }
        })
    }

    const requesterSend = () => {
        setIsRequestLoading(true)
        if (props.incident?.requesterId || props.incident?.people?.id) {
            sendSurvey(props.incident?.requesterId || props.incident?.people?.id!!, props.incident?.id!!)
                .then(() => {
                    message.success('The email has been sent successfully.')
                    setIsRequestLoading(false)
                })
                .catch((err) => {
                    message.error(`Failed to send email ${err}`)
                })
                .finally(() => {
                    props.setModalClose(false)
                })
        }
    }

    const requesterSelectSend = (userId: number) => {
        props.form.validateFields(async (err: any, values) => {
            if (!err) {
                setIsSelectRequestLoading(true)
                sendSurvey(userId, props.incident.id!!)
                    .then(() => {
                        message.success('The email has been sent successfully.')
                        setIsSelectRequestLoading(false)
                    })
                    .catch((err) => {
                        message.error(`Failed to send email ${err}`)
                    })
                    .finally(() => {
                        props.setModalClose(false)
                        resetFields(['requesterText'])
                    })
            } else {
                message.error("Please enter the requester's name.")
            }
        })
    }

    const blurAutoComplete = (e) => {
        if (!requester) {
            resetFields()
        }
    }

    return (
        <>
            <Row>
                <Col span={24} style={{textAlign: 'center'}}>
                    <Spin spinning={isLoadingImage}>
                        <img className="satButton" style={{width: '20%', cursor: 'pointer'}} alt='emoGood' src={emoGood} onClick={() => {
                            showConfirm('Very Satisfied')
                        }}/>
                        <img className="satButton" style={{width: '20%', cursor: 'pointer'}} alt='emoNormal' src={emoNormal}
                            onClick={() => {
                                showConfirm('Satisfied')
                            }}/>
                        <img className="satButton" style={{width: '20%', cursor: 'pointer'}} alt='emoBad' src={emoBad} onClick={() => {
                            showConfirm('Dissatisfied')
                        }}/>
                    </Spin>
                </Col>
            </Row>
            <Divider style={{opacity: '0.5'}}>or</Divider>
            <Row>
                <Col span={24} style={{textAlign: 'center'}}>
                    <Tooltip title={props.isOpenfromList ? props.incident?.requesterEmail : props.incident?.people?.email}>
                        <Button style={{width: '50%', minWidth: 240}} onClick={() => {
                            requesterSend()
                        }} loading={isRequestLoading}>Send to requester</Button>
                    </Tooltip>
                </Col>
            </Row>
            <Divider style={{opacity: '0.5'}}>or</Divider>
            <Row style={{textAlign: 'center'}}>
                <Col span={17} style={{textAlign: 'right'}}>
                    <Form>
                        {getFieldDecorator('requesterText', { rules: [{ required: true, whitespace: true, message: 'Please Enter Requester'}]})(
                            <AutoComplete
                                dataSource={(requesterSuggestions || []).map((it: People) => {
                                    return {
                                        value: it.id?.toString(),
                                        text: it.employeeId + ' : ' + it.firstName + ' ' + it.lastName
                                    } as DataSourceItemObject
                                })}
                                style={{width: '80%'}}
                                onSelect={onSelectRequest}
                                onSearch={onSearchRequester}
                                onChange={() => setRequester(undefined)}
                                onBlur={(e) => blurAutoComplete(e)}
                                placeholder="Name"
                                disabled={!checkRolesState(RoleType.Incident, 'SatisfactionTicket')}
                            >
                            </AutoComplete>
                        )}
                    </Form>
                </Col>
                <Col span={6} style={{textAlign: 'left', marginLeft: '5px'}}>
                    <Tooltip title={requester?.email!!}>
                        <Button onClick={() => {
                            requesterSelectSend(requester?.id!!)
                        }} loading={isSelectRequestLoading} disabled={!getFieldValue('requesterText') && !checkRolesState(RoleType.Incident, 'SatisfactionTicket')} >Send</Button>
                    </Tooltip>
                </Col>
            </Row>
        </>
    )
}

const CustomerSatModalForm = Form.create<Props>({ name: 'CustomerSatModal' })(CustomerSatModal)
export default CustomerSatModalForm
