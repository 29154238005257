import React, { useContext, useEffect, useState } from 'react'
import { Incident, ticketRelateAsset, ticketRelated, TicketStatus } from './model'
import { Button, Card, Col, Collapse, Drawer, Empty, Icon, Layout, message, Modal, Pagination, Row, Spin, Tag, Timeline, Tooltip } from 'antd'
import { useParams, Link } from 'react-router-dom'
import { FormComponentProps } from 'antd/es/form'
import { GetAllKnowledgeRelatedInIncidentView, GetAllTicketRelatedTicketIncView, getIncidentById } from './service'
import moment from 'moment'
import { countLines, CutTextToReadMore } from '../../common-misc'
import ReactHtmlParser from 'react-html-parser'
import jwt_decode from 'jwt-decode'
import { getCookie, waitingGetSessions } from '../../guard/route'
import CommentSection from '../comment/CommentSection'
import ViewMoreDetailCustomField from './ViewMoreDetailCustomField'
import { StoreState } from '../../store'
import { connect, useDispatch } from 'react-redux'
import { getAllPriority } from '../priority'
import { getAllWorkLogByIncidentId } from '../worklog/service'
import { SlaCalculation } from '../../sla-management/sla-calculation/model'
import { Worklog, WorklogChange } from '../worklog'
import SlaDisplay from '../sla/SlaDisplay'
import { FetchingState, fetchWithState, SpinningFetcher } from '../../common-components'
import { ForIncident } from '../../sla-management/sla-calculation/service'
import { TicketRelatedContext } from '../../Preventive/ContextAPI/TicketRelatedContext'
import { AssetRelatedContext } from '../../Preventive/ContextAPI/IncidentTicketRelatedContext'
import { GetAllTicketRelatedInIncidentView } from './relatedAsset/service'
import DisplayRelateWorkflow from './relateWorkflow/DisplayRelateWorkflow'
import { getWoRelatedByIncidentId, WoRalateIncidentCard } from '../../msp-wo/wo-management'
import { KnowledgeRelatedContext } from '../../Preventive/ContextAPI/KnowledgeRelatedContext'
import RelateProblemHome from './relateProblem/RelateProblemHome'
import { GetIncidentByIdSuccessEvent } from './state-event'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import RenderTicketRelateOther from './RenderTicketRelateOther'


const mapStateToProps = (state: StoreState) => {
    return {
        incident: state.incidentState?.incident,
        priority: state.priorities,
        workLog: state.workLog,
        slaCalculationDataSuit: state.incidentState?.slaCalculationDataSuit,
        comments: (state.comments || [])
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllPriority: () => Promise<number>
    getAllWorkLogByIncidentId: (incidentId: string) => Promise<number>
    getAllSlaCalculationByTicketIds: (ticketIds: string[]) => Promise<number>
}

interface Params {
    id: string
}

type Props = StateProps & DispatchProps & FormComponentProps
const { Header, Content } = Layout
const { confirm } = Modal
const { Panel } = Collapse
const ViewMoreDetailTicket: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch()
    const { id } = useParams<Params>()

    const [incidentFetchingState, setIncidentFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [dataIncident, setDataIncident] = useState<Incident>()
    const [isReadMoreTopic, setIsReadMoreTopic] = useState<boolean>(false)
    const [isReadMoreDesc, setIsReadMoreDesc] = useState<boolean>(false)
    const [isNonDesktop, setIsNonDesktop] = useState<boolean>(false)
    const [showWorkLog, setShowWorklog] = useState<boolean>(false)
    const [showSla, setShowSla] = useState<boolean>(false)
    const [showTicketRelated, setShowTicketRelated] = useState<boolean>(false)
    const [showAssetRelated, setShowAssetRelated] = useState<boolean>(false)
    const [showWorkflowRelated, setShowWorkflowRelated] = useState<boolean>(false)
    const [showKnowledgeRelated, setShowKnowledgeRelated] = useState<boolean>(false)
    const [showProblem, setShowProblem] = useState<boolean>(false)
    const [slaCalculationsFetchingState, setSlaCalculationFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [isLoadingDataRelated, setIsLoadingDataRelated] = useState<boolean>(false)
    const [currentStatus, setCurrentStatus] = useState<TicketStatus>()
    const [pendingUntil, setPendingUntil] = useState<string | null>()
    const { GetTicketRelated, TicketRelatedState } = useContext<any>(TicketRelatedContext)
    const [ticketCurrentPage, setTicketCurrentPage] = useState<number>(1)
    const [assetCurrentPage, setAssetCurrentPage] = useState<number>(1)
    const [kmCurrentPage, setKmCurrentPage] = useState<number>(1)
    const { GetAssetRelated, AssetRelatedState } = useContext<any>(AssetRelatedContext)
    const [dataWoRalateIncident, setDataWoRalateIncident] = useState<WoRalateIncidentCard[]>([])
    const { GetKnowledgeRelated, KnowledgeRelatedState } = useContext<any>(KnowledgeRelatedContext)
    const decoded: any = jwt_decode(getCookie('access_token') || '')

    useEffect(() => {
        if (id) {
            getIncidentById(id).then((res) => {
                if (res) {
                    setDataIncident(res)
                }
            })
        }
    }, [id])

    useEffect(() => {
        fetchAllPriority()
        fetchAllWorkLog()
        fetchTicketRelated()
        fetchWithState(incidentFetchingState, setIncidentFetchingState, fetchIncident, (incident) => {
            dispatch(GetIncidentByIdSuccessEvent.build(incident))
        })
        if (props.incident?.ticketStatus !== null) {
            setCurrentStatus(props.incident?.ticketStatus)
        }
        if (props.incident?.pendingUntil !== null) {
            setPendingUntil(props.incident?.pendingUntil)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.incident?.ticketStatus !== null) {
            setCurrentStatus(props.incident?.ticketStatus)
        }
        if (props.incident?.pendingUntil !== null) {
            setPendingUntil(props.incident?.pendingUntil)
        }
    }, [props.incident])

    useEffect(() => {
        if (props.comments.length !== 0) {
            setShowTicketRelated(false)
        } else {
            setShowTicketRelated(true)
        }
    }, [props.comments])

    useEffect(() => {
        fetchWithState(
            slaCalculationsFetchingState,
            setSlaCalculationFetchingState,
            fetchSlaCalculation,
            undefined,
            (err) => message.error(`Failed fetching SLA calculations. ${err}`)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSla, slaCalculationsFetchingState])

    useEffect(() => {
        if (window.innerWidth < 992) {
            setIsNonDesktop(true)
        } else {
            setIsNonDesktop(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNonDesktop])

    const fetchIncident = () => getIncidentById(Number(id))

    const fetchAllPriority = () => props.getAllPriority()

    const fetchAllWorkLog = () => props.getAllWorkLogByIncidentId(id)

    const fetchSlaCalculation = () => props.getAllSlaCalculationByTicketIds([id])

    const fetchTicketRelated = async () => {
        setIsLoadingDataRelated(true)
        await GetAllTicketRelatedTicketIncView(id).then((res) => {
            console.log('ticket related : ', res)
            GetTicketRelated(res)
        }).finally(() => {
            setIsLoadingDataRelated(false)
        })
    }

    const fetchAssetRelated = () => {
        setIsLoadingDataRelated(true)
        GetAllTicketRelatedInIncidentView(id).then((res) => {
            GetAssetRelated(res)
        }).finally(() => {
            setIsLoadingDataRelated(false)
        })
    }

    const fetchDataWoWithIncident = async () => {
        if (decoded.service.serviceId.some((it) => it === 4)) {
            setIsLoadingDataRelated(true)
            await getWoRelatedByIncidentId(props.incident?.id?.toString()!!).then((data) => {
                if (data.length > 0) {
                    const mergeData = [...dataWoRalateIncident, ...data]
                    const arrayUniqueByKey = [...new Map(mergeData.map(item =>
                        [item['id'], item])).values()]
                    setDataWoRalateIncident(arrayUniqueByKey)
                }
            }).finally(() => {
                setIsLoadingDataRelated(false)
            })
        }
    }

    const fetchKnowledgeRelated = () => {
        setIsLoadingDataRelated(true)
        GetAllKnowledgeRelatedInIncidentView(id).then((res) => {
            GetKnowledgeRelated(res)
        }).finally(() => {
            setIsLoadingDataRelated(false)
        })
    }

    const leavePageModal = () => {
        confirm({
            title: 'Are you sure?',
            content: 'You want to close this ticket?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                window.close()
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const ToggleIsReadMoreTopic = () => {
        setIsReadMoreTopic(!isReadMoreTopic)
    }

    const ToggleIsReadMoreDesc = () => {
        setIsReadMoreDesc(!isReadMoreDesc)
    }

    const handleShowWorklog = () => {
        setShowWorklog(!showWorkLog)
        fetchAllWorkLog().catch((err) => message.error(`Failed fetching work log. ${err}`))
        setShowSla(false)
        setShowTicketRelated(false)
        setShowAssetRelated(false)
        setShowWorkflowRelated(false)
        setShowKnowledgeRelated(false)
        setShowProblem(false)
    }

    const handleShowSla = () => {
        setShowSla(!showSla)
        setShowWorklog(false)
        setShowTicketRelated(false)
        setShowAssetRelated(false)
        setShowWorkflowRelated(false)
        setShowKnowledgeRelated(false)
        setShowProblem(false)
    }

    const handleShowTicket = () => {
        setShowTicketRelated(!showTicketRelated)
        setShowWorklog(false)
        setShowSla(false)
        setShowAssetRelated(false)
        setShowKnowledgeRelated(false)
        setShowProblem(false)
        if (!showTicketRelated) {
            fetchTicketRelated()
        }
    }

    const handleShowAsset = () => {
        setShowAssetRelated(!showAssetRelated)
        setShowWorklog(false)
        setShowSla(false)
        setShowTicketRelated(false)
        setShowWorkflowRelated(false)
        setShowKnowledgeRelated(false)
        setShowProblem(false)
        if (!showAssetRelated) {
            fetchAssetRelated()
        }
    }

    const handleShowWorkflow = () => {
        setShowWorkflowRelated(!showWorkflowRelated)
        setShowWorklog(false)
        setShowSla(false)
        setShowTicketRelated(false)
        setShowAssetRelated(false)
        setShowKnowledgeRelated(false)
        setShowProblem(false)
        if (!showWorkflowRelated) {
            fetchDataWoWithIncident()
        }
    }

    const handleShowKnowledge = () => {
        setShowKnowledgeRelated(!showKnowledgeRelated)
        setShowAssetRelated(false)
        setShowWorklog(false)
        setShowSla(false)
        setShowTicketRelated(false)
        setShowWorkflowRelated(false)
        setShowProblem(false)
        if (showKnowledgeRelated === false) {
            fetchKnowledgeRelated()
        }
    }

    const handleShowProblems = () => {
        setShowProblem(!showProblem)
        setShowAssetRelated(false)
        setShowWorklog(false)
        setShowSla(false)
        setShowKnowledgeRelated(false)
        setShowWorkflowRelated(false)
        setShowTicketRelated(false)
    }

    const getSlaPolicy = (slaPolicyHistory) => {
        return props.slaCalculationDataSuit?.slaPolicyHistories.find((sla: any) => sla.id === slaPolicyHistory)
    }

    const convertCamelCaseToSentenceCase = (fieldValue: string) => {
        // adding space between strings
        const result = fieldValue.replace(/([A-Z])/g, ' $1')

        // converting first character to uppercase and join it to the final string
        return result.charAt(0).toUpperCase() + result.slice(1)
    }

    const mappingWord = (wording: string) => {
        if (wording === 'supportTeam') {
            return 'assignmentGroup'
        }
        if (wording === 'assignee') {
            return 'assignedTo'
        } else {
            return wording
        }
    }

    const createSLAWorklog = (worklog: Worklog) => {
        const getNewValue = (fieldName: string) => {
            console.log(fieldName)
            console.log(worklog)
            return (worklog.changes || []).find((change) => change.fieldName === fieldName)?.newValue
        }

        const getOldValue = (fieldName: string) => {
            return (worklog.changes || []).find((change) => change.fieldName === fieldName)?.oldValue
        }
        const slaCalculationsMap: SlaCalculation[] = props.slaCalculationDataSuit!!.slaCalculationsMap[id]
        let slaPolicyName, slaGoalName, slaStatus
        let elements: JSX.Element = <div />
        if (worklog.slaCalculationId != null) {
            const slaCalculation = slaCalculationsMap.find((slaCal) => slaCal.id === worklog.slaCalculationId)
            console.log('slaCalculation', slaCalculation)
            if (slaCalculation) {
                const slaHistory = getSlaPolicy(slaCalculation.slaPolicyHistoryId)
                console.log('slaHistory', slaHistory)
                slaPolicyName = slaHistory?.name
                slaGoalName = slaCalculation.slaGoalName
                slaStatus = slaCalculation.slaStatus
            }
            if (worklog.type === 'SlaAttached') {
                if (!worklog.createdBy) {
                    worklog.createdBy = 'System'
                }
                elements = <Timeline.Item color={'blue'}>{renderWorkLog(moment(worklog.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), ` ${worklog.createdBy} attached to SLA goal ${getNewValue('slaGoalName')} of SLA policy ${slaPolicyName} at status ${getNewValue('slaStatus')} and stage ${getNewValue('slaStage')}`)}</Timeline.Item>
            }
            if (worklog.type === 'SlaDetached') {
                if (!worklog.createdBy) {
                    worklog.createdBy = 'System'
                }
                elements = <Timeline.Item color={'blue'}>{renderWorkLog(moment(worklog.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), ` ${worklog.createdBy} detached from SLA goal ${slaGoalName} of SLA policy  ${slaPolicyName} `)}</Timeline.Item>
            }
            if (worklog.type === 'SlaChanged') {
                (worklog.changes || []).forEach((ch: WorklogChange) => {
                    if (!worklog.createdBy) {
                        worklog.createdBy = 'System'
                        if (ch.fieldName === 'slaStatus') {
                            elements = <Timeline.Item color={'blue'}>{renderWorkLog(moment(worklog.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), ` SLA goal ${slaGoalName} of SLA policy ${slaPolicyName}  has status changed from ${getOldValue('slaStatus')} to ${getNewValue('slaStatus')} `)} </Timeline.Item>
                        }
                    } else {
                        if (ch.fieldName === 'slaStage') {
                            elements = <Timeline.Item color={'blue'}>{renderWorkLog(moment(worklog.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), ` SLA goal ${slaGoalName} of SLA policy ${slaPolicyName}  has stage changed from ${getOldValue('slaStage')} to ${getNewValue('slaStage')} with status as ${slaStatus} `)} </Timeline.Item>
                        }
                    }
                })
            }
            return elements
        }
        return <div />
    }

    const renderWorkLog = (dateFormat: string, data: string) => {
        return <Row>
            <Col span={4}>
                {dateFormat}
            </Col>
            <Col span={20}>
                {data}
            </Col>
        </Row>
    }

    const displayWorkLog = () => {
        // eslint-disable-next-line prefer-const
        let workLogElements: JSX.Element[] = []
        // eslint-disable-next-line react/prop-types
        console.log(props.workLog)
        // eslint-disable-next-line react/prop-types
        for (const wl of props.workLog) {
            let statusBeforePending: string | undefined
            let pendingUntil: string | undefined

            const getNewValue = (fieldName: string) => {
                return (wl.changes || []).find((change) => change.fieldName === fieldName)?.newValue
            }

            const getMoreValueOnCreateOfAssignGroup = (fieldNameMore: string) => {
                const result = (wl.changes || []).find((change) => change.fieldName === fieldNameMore)
                if (result) {
                    if (result.newValue !== '') {
                        return ', Assignment group as ' + (wl.changes || []).find((change) => change.fieldName === fieldNameMore)?.newValue
                    } else {
                        return ''
                    }
                } else {
                    return ''
                }
            }

            const getMoreValueOnCreateOfAssignee = (fieldNameAssign: string) => {
                const result = (wl.changes || []).find((change: WorklogChange) => change.fieldName === fieldNameAssign)
                if (result) {
                    // eslint-disable-next-line quotes
                    if (result.newValue !== "") {
                        return ', Assigned to as ' + (wl.changes || []).find((change: WorklogChange) => change.fieldName === fieldNameAssign)?.newValue
                    } else {
                        return ''
                    }
                } else {
                    return ''
                }
            }

            const getMoreValueOnCreateOfAssetRelated = (fieldName: string) => {
                const result = (wl.changes || []).find((change) => change.fieldName === fieldName)
                if (result) {
                    if (result.newValue !== null) {
                        return ', ' + (wl.changes || []).find((change) => change.fieldName === fieldName)?.newValue
                    } else {
                        return ''
                    }
                } else {
                    return ''
                }
            }

            const createDeleteAssetRelated = () => {
                return (wl.changes || []).find((change) => change.fieldName === 'assetRelated' || 'knowledgeRelated')?.newValue
            }

            const createTicketRelated = () => {
                return (wl.changes || []).find((change) => change.fieldName === 'RelatedTicket')?.newValue
            }

            const deleteTicketRelated = () => {
                return (wl.changes || []).find((change) => change.fieldName === 'DeleteRelatedTicket')?.newValue
            }

            const UpdateTicketRelatedWorkFlow = () => {
                return (wl.changes || []).find((change) => change.fieldName === 'Workflows')?.newValue
            }

            if (wl.type === 'Create') {
                // workLogElements.push(<Timeline.Item color={'green'}>{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ${wl.createdBy} created ticket with Status as ${getNewValue('ticketStatus')}, ${getNewValue('priority')}${getMoreValueOnCreateOfAssignGroup('Assignment Group')}${getMoreValueOnCreateOfAssignee('Assignee')}`}</Timeline.Item>)
                workLogElements.push(<Timeline.Item color={'green'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} created ticket with Status as ${getNewValue('ticketStatus')}, ${getNewValue('priority')}${getMoreValueOnCreateOfAssignGroup('Assignment Group')}${getMoreValueOnCreateOfAssignee('Assignee')} ${getMoreValueOnCreateOfAssetRelated('RelatedTicket')} ${getMoreValueOnCreateOfAssetRelated('assetRelated')} ${getMoreValueOnCreateOfAssetRelated('relatedKm')} ${getMoreValueOnCreateOfAssetRelated('Workflows')}`)}</Timeline.Item>)
            } else if (wl.type === 'Pending') {
                (wl.changes || []).forEach((ch: any) => {
                    if (ch.fieldName === 'ticketStatus') {
                        statusBeforePending = ch.oldValue
                    }
                    if (ch.fieldName === 'pendingUntil') {
                        pendingUntil = ch.newValue
                    }
                })
                if (statusBeforePending !== undefined) {
                    // workLogElements.push(<Timeline.Item dot={<Icon type="pause-circle" style={{ fontSize: '16px' }} />} color="red">{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ${wl.createdBy} changed status from ${statusBeforePending} to Pending until ${moment(pendingUntil).format('YYYY-MM-DD HH:mm:ss')}`}</Timeline.Item>)
                    workLogElements.push(<Timeline.Item dot={<Icon type="pause-circle" style={{ fontSize: '16px' }} />} color="red">{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} changed status from ${statusBeforePending} to Pending until ${moment(pendingUntil).format('YYYY-MM-DD HH:mm:ss')}`)}</Timeline.Item>)
                } else {
                    // workLogElements.push(<Timeline.Item dot={<Icon type="pause-circle" style={{ fontSize: '16px' }} />} color="red">{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ${wl.createdBy} changed Pending until ${moment(pendingUntil).format('YYYY-MM-DD HH:mm:ss')}`}</Timeline.Item>)
                    workLogElements.push(<Timeline.Item dot={<Icon type="pause-circle" style={{ fontSize: '16px' }} />} color="red">{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} changed Pending until ${moment(pendingUntil).format('YYYY-MM-DD HH:mm:ss')}`)}</Timeline.Item>)
                }
            } else if (wl.type === 'Update') {
                (wl.changes || []).forEach((ch: WorklogChange) => {
                    if (ch.fieldName === 'category' || ch.fieldName === 'subcategory' || ch.fieldName === 'item') {
                        return null
                    } else {
                        if (!wl.createdBy) {
                            wl.createdBy = 'system'
                        }
                        if (ch.fieldName === 'pendingUntil' && ch.oldValue !== null) {
                            // workLogElements.push(<Timeline.Item color="blue">{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ${wl.createdBy} changed Pending until ${moment(ch.oldValue!!).format('YYYY-MM-DD HH:mm:ss')}`}</Timeline.Item>)
                            workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} changed Pending until ${moment(ch.oldValue!!).format('YYYY-MM-DD HH:mm:ss')}`)}</Timeline.Item>)
                        } else if (ch.fieldName === 'resolution') {
                            // workLogElements.push(<Timeline.Item color={'blue'}>{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ${wl.createdBy} added ${convertCamelCaseToSentenceCase(mappingWord(ch.fieldName))}`}</Timeline.Item>)
                            workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} added ${convertCamelCaseToSentenceCase(mappingWord(ch.fieldName))}`)}</Timeline.Item>)
                        } else {
                            // workLogElements.push(<Timeline.Item color={'blue'}>{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0 ${wl.createdBy} changed ${convertCamelCaseToSentenceCase(mappingWord(ch.fieldName))} from ${ch.oldValue} to ${ch.newValue}`}</Timeline.Item>)
                            workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} changed ${convertCamelCaseToSentenceCase(mappingWord(ch.fieldName))} from ${ch.oldValue} to ${ch.newValue}`)}</Timeline.Item>)
                        }
                    }
                })
            } else if (wl.type === 'SlaAttached') {
                workLogElements.push(createSLAWorklog(wl))
            } else if (wl.type === 'SlaDetached') {
                workLogElements.push(createSLAWorklog(wl))
            } else if (wl.type === 'SlaChanged') {
                workLogElements.push(createSLAWorklog(wl))
                // (wl.changes || []).forEach((ch: WorklogChange) => {
                //     if (!wl.createdBy) {
                //         wl.createdBy = 'System'
                //         if (ch.fieldName === 'slaStatus') {
                //             workLogElements.push(<Timeline.Item color={'blue'}>{moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss') + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0 SLA goal ${slaGoalName} of SLA policy ${slaPolicyName}  has status changed from ${getOldValue('slaStatus')} to ${getNewValue('slaStatus')} `} </Timeline.Item>)
                //         }
                //     } else {
                //         if (ch.fieldName === 'slaStage') {
                //             workLogElements.push(<Timeline.Item color={'blue'}>{moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss') + `\xa0\xa0\xa0\xa0\xa0\xa0\xa0 SLA goal ${slaGoalName} of SLA policy ${slaPolicyName}  has stage changed from ${getOldValue('slaStage')} to ${getNewValue('slaStage')} with status as ${slaStatus} `} </Timeline.Item>)
                //         }
                //     }
                // })
            } else if (wl.type === 'DeleteAssetRelated') {
                workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} ${createDeleteAssetRelated()}`)}</Timeline.Item>)
            } else if (wl.type === 'RelatedAsset') {
                workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} ${createDeleteAssetRelated()}`)}</Timeline.Item>)
            } else if (wl.type === 'RelatedKm') {
                workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} ${createDeleteAssetRelated()}`)}</Timeline.Item>)
            } else if (wl.type === 'DeleteKmRelated') {
                workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} ${createDeleteAssetRelated()}`)}</Timeline.Item>)
            } else if (wl.type === 'DeleteRelatedTicket') {
                workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} ${deleteTicketRelated()}`)}</Timeline.Item>)
            } else if (wl.type === 'RelatedTicket') {
                workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} ${createTicketRelated()}`)}</Timeline.Item>)
            } else if (wl.type === 'RelateWorkflow') {
                workLogElements.push(<Timeline.Item color={'blue'}>{renderWorkLog(moment(wl.createdDatetime).format('YYYY-MM-DD HH:mm:ss'), `${wl.createdBy} ${UpdateTicketRelatedWorkFlow()}`)}</Timeline.Item>)
            }
        }
        return (
            <Timeline style={{ marginLeft: '40px', marginTop: '10px' }}>
                {workLogElements.map((temps: any, i: number) => {
                    return (
                        <div key={i}>{temps}</div>
                    )
                })}
            </Timeline>
        )
    }

    const displayTicketRelated = () => {
        const tempparent = TicketRelatedState?.parent?.map((it) => {
            it.relateType = 'Parent'
            return it
        }) || []
        const tempchild = TicketRelatedState?.child?.map((it) => {
            it.relateType = 'Child'
            return it
        }) || []
        const tempTicketSliceShow = [...tempparent, ...tempchild]?.slice((ticketCurrentPage - 1) * 10, ticketCurrentPage * 10)
        if (ticketCurrentPage > 1 && tempTicketSliceShow?.length === 0) {
            setTicketCurrentPage(ticketCurrentPage - 1)
        }
        return (
            <div>
                <Spin spinning={isLoadingDataRelated}>
                    <Row>
                        <Col span={21}>
                            <p style={{ paddingTop: 2 }} className='sub-title'>Ticket Related</p>
                        </Col>
                    </Row>
                    <br /> {tempTicketSliceShow?.length > 0 ? <div style={{ overflowY: 'scroll', maxHeight: 600 }}>
                        {tempTicketSliceShow?.map((it: ticketRelated, index: number) => {
                            return <>
                                <div key={it.id} className='TicketRelateCard'>
                                    <Row>
                                        <Col span={23}>
                                            <span>Ticket Number :<Link to={!checkRolesState(RoleType.Incident, 'UpdateIncident') ? `/IncidentViewMoreDetails/${it.id}` : `/IncidentDetailsView/${it.id}`} target="_blank" onClick={() => waitingGetSessions()}>{it.number}</Link>
                                                <Tag style={{ backgroundColor: it.priority?.tagColor, color: '#fff', marginLeft: 10 }}>{it.priority?.name}</Tag>
                                                {it.relateType === 'Parent' ? <Tooltip placement='bottom' title={'Parent of this ticket'}>
                                                    <Tag style={{ color: '#fff', backgroundColor: '#EB5757' }}>Parent</Tag>
                                                </Tooltip> : <Tooltip placement='bottom' title={'Child of this ticket'}>
                                                    <Tag style={{ color: '#fff', backgroundColor: '#4DC977' }}>Child</Tag>
                                                </Tooltip>}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <span>Topic : {it?.topic}</span>
                                        </Col>
                                        <Col span={6} >
                                            <span>Status : {it?.status}</span>
                                        </Col>
                                        <Col span={6}>
                                            <span>Requester : {it?.requester}</span>
                                        </Col>
                                    </Row>
                                    {it.relateType === 'parent'
                                        ? <Row style={{ marginTop: '20px' }}>
                                            <Collapse>
                                                <Panel header="Related Other" key="1">
                                                    {it.relatedOther !== null
                                                        ? it.relatedOther?.map((data, index) => {
                                                            return <RenderTicketRelateOther ticket={data} />
                                                        })
                                                        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    }
                                                </Panel>
                                            </Collapse>
                                        </Row>
                                        : null
                                    }
                                </div>
                            </>
                        })} </div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    {TicketRelatedState?.parent?.length === 0 && TicketRelatedState?.child?.length === 0 ? null
                        : <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 20 }}>
                            <Pagination total={TicketRelatedState?.parent?.length + TicketRelatedState?.child?.length} pageSize={10} current={ticketCurrentPage} onChange={(page) => setTicketCurrentPage(page)} />
                        </div>}
                </Spin>
            </div>
        )
    }

    const displayAssetRelated = () => {
        const tempAssetSliceShow = AssetRelatedState.slice((assetCurrentPage - 1) * 10, assetCurrentPage * 10)
        if (assetCurrentPage > 1 && tempAssetSliceShow.length === 0) {
            setAssetCurrentPage(assetCurrentPage - 1)
        }
        return (
            <div>
                <Spin spinning={isLoadingDataRelated}>
                    <Row>
                        <Col span={21}>
                            <p style={{ paddingTop: 2 }} className='sub-title'>Asset Related</p>
                        </Col>
                        <Col span={3} style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: -10 }} >
                        </Col>
                    </Row>
                    <br />
                    {props.incident?.ticketRelateAsset?.length !== 0 || AssetRelatedState.length > 0 ? <> <div style={{ overflowY: 'scroll', maxHeight: 600 }}> {tempAssetSliceShow.map((it: ticketRelateAsset, index: number) => {
                        return <>
                            <div key={it.id} className='TicketRelateCard'>
                                <Row>
                                    <Col span={23}>
                                        <span>Serial Number : {it.serialNumber}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={8}>
                                        <span>Owner ID : {it.originalAsset.people?.employeeId} - {it.originalAsset.people?.fullName}</span>
                                    </Col>
                                    <Col span={8}>
                                        <span>Location : {it.originalAsset.location}</span>
                                    </Col>
                                    <Col span={8}>
                                        <span>Asset Status : {it.originalAsset.status}</span>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    })}</div>
                        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 20 }}>
                            <Pagination total={AssetRelatedState.length} pageSize={10} current={assetCurrentPage} onChange={(page) => setAssetCurrentPage(page)} />
                        </div>
                    </> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </Spin>
            </div>
        )
    }

    const warningOpenKm = () => {
        Modal.warning({
            title: 'Warning',
            content: 'Sorry, This knowledge cannot be viewed.'
        })
    }

    const displayKnowledgeRelated = () => {
        const tempKnowledgeSliceShow = KnowledgeRelatedState.slice((kmCurrentPage - 1) * 10, kmCurrentPage * 10)
        if (kmCurrentPage > 1 && tempKnowledgeSliceShow.length === 0) {
            setKmCurrentPage(kmCurrentPage - 1)
        }
        return (
            <div>
                <Spin spinning={isLoadingDataRelated}>
                    <Row>
                        <Col span={21}>
                            <p style={{ paddingTop: 2 }} className='sub-title'>Knowledge Related</p>
                        </Col>
                    </Row>
                    <br />
                    {KnowledgeRelatedState.length > 0 ? <> <div style={{ overflowY: 'scroll', maxHeight: 600 }}> {tempKnowledgeSliceShow.map((it) => {
                        return <div className='TicketRelateCard' key={it.id}>
                            <Row>
                                <Col span={23} >
                                    Knowledge Number :
                                    {it.visible ? <Link to={`/KnowledgeRelatedPreview/${it.id}/Related`} target="_blank" onClick={() => waitingGetSessions()}> <b>{it.ticketNumber}</b></Link>
                                        : <span className='feck-link' onClick={warningOpenKm}> {it.ticketNumber}</span>}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={16}>
                                    <span><b>Topic :</b> {it.topic}</span>
                                </Col>
                                <Col span={8} >
                                    <span><b>Created By :</b> {it.createdByDisplay}</span>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col span={24}>
                                    <span style={{ marginRight: '15px' }}>
                                        <Icon type="like" theme="filled" />
                                        <span style={{ marginLeft: '10px' }}>{it.helpful ? it.helpful : 0}</span>
                                    </span>
                                    <span style={{ marginRight: '15px' }}>
                                        <Icon type="dislike" theme="filled" />
                                        <span style={{ marginLeft: '10px' }}>{it.notHelpful ? it.notHelpful : 0}</span>
                                    </span>
                                    <span style={{ marginRight: '15px' }}>
                                        <Icon type="eye" theme="filled" />
                                        <span style={{ marginLeft: '10px' }}>{it.views ? it.views : 0}</span>
                                    </span>
                                    <span style={{ marginRight: '15px' }}>
                                        <Icon type="check-square" />
                                        <span style={{ marginLeft: '10px' }}>{it.used ? it.used : 0}</span>
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    })}
                    </div>
                        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 20 }}>
                            <Pagination total={KnowledgeRelatedState.length} pageSize={10} current={kmCurrentPage} onChange={(page) => setKmCurrentPage(page)} />
                        </div>
                    </> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </Spin>
            </div>
        )
    }

    const displayProblemRelate = () => {
        return <div>
            <RelateProblemHome dataIncident={props.incident!!} />
        </div>
    }

    return (
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 2, width: '100%', backgroundColor: '#fff', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                <Row>

                    <Col span={12}>
                        <div>
                            <h3 style={{ color: '#323276' }}><Icon type="arrow-left" onClick={() => { window.location.href = '/IncidentList' }} /><b>{dataIncident?.number}</b></h3>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div style={{ textAlign: 'end' }}>
                            <Icon type="close-square" theme="filled" style={{ color: '#FA541C', fontSize: 24 }} onClick={() => leavePageModal()} />
                        </div>
                    </Col>
                </Row>
            </Header>
            <Content style={{ padding: '0 50px', marginTop: 64 }}>
                {/* <Spin> */}
                <Row gutter={24}>
                    <br />
                    <Col span={18} lg={18} md={24} sm={24} xs={24}>
                        <Card style={{ boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                            <Row>
                                <Col span={6} lg={8} md={24} xs={24} >
                                    <Col lg={9} md={6} xs={9}>
                                        <b>Ticket Number : </b>
                                    </Col>
                                    <Col lg={15}>
                                        <span> {dataIncident?.number}</span>
                                    </Col>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }}>
                                <Col span={6} lg={8} md={24} xs={24} >
                                    <Col lg={9} md={6} xs={9} ><b>Created :</b> </Col><Col lg={15}>{dataIncident?.createdDatetime ? moment(dataIncident.createdDatetime).format('YYYY-MM-DD HH:mm:ss') : "-"}</Col>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }}>
                                <Col span={6} lg={8} md={24} xs={24}>
                                    {<Col lg={9} md={6} xs={9}><b>Ticket Type :</b> </Col>}
                                    {<Col>{dataIncident?.incidentSetting[1].incGeneralSetting.value}</Col>}
                                </Col>
                                <Col lg={10} md={24} xs={24}>
                                    {<Col lg={6} md={6} xs={9}><b>Channel :</b> </Col>}
                                    {<Col lg={10}>{dataIncident?.incidentSetting[0].incGeneralSetting.value}</Col>}
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }}>
                                <Col span={3} lg={3} md={6} xs={9} style={{ textAlign: 'justify' }}>
                                    <span><b>Topic :</b> </span>
                                </Col>
                                <Col span={20} lg={20} md={15} xs={14} id='topic' style={{ textAlign: 'justify', textJustify: 'auto', overflowWrap: 'break-word', fontWeight: 'normal' }}>
                                    {isReadMoreTopic
                                        ? <div>{dataIncident?.subject}</div>
                                        : <div style={CutTextToReadMore(2)}>
                                            {dataIncident?.subject}
                                        </div>}
                                    {countLines('topic') >= 2 ? !isReadMoreTopic ? <span style={{ fontSize: '14px' }} className='feck-link' onClick={ToggleIsReadMoreTopic}>Read more</span> : <span style={{ fontSize: '14px' }} className='feck-link' onClick={ToggleIsReadMoreTopic}>Read less</span> : null}
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }}>
                                <Col span={3} lg={3} md={6} xs={9}>
                                    <span><b>Description :</b> </span>
                                </Col>
                                <Col span={20} lg={20} md={15} xs={14} id='des' style={{ textAlign: 'justify', textJustify: 'auto', overflowWrap: 'break-word' }}>
                                    {isReadMoreDesc
                                        ? <div>{ReactHtmlParser(dataIncident?.description!!?.split('\\n').join('<br/>'))}</div>
                                        : <div style={CutTextToReadMore(5)}>
                                            {ReactHtmlParser(dataIncident?.description!!?.split('\\n').join('<br/>'))}
                                        </div>}
                                    {countLines('des') >= 5 || dataIncident?.description!!?.length >= 700 ? !isReadMoreDesc ? <span className='feck-link' onClick={ToggleIsReadMoreDesc}>Read more</span> : <span className='feck-link' onClick={ToggleIsReadMoreDesc}>Read less</span> : null}
                                </Col>
                            </Row>
                        </Card>
                        <Card size={'small'} style={{ boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                            <Button shape="round" className={`workLogButton${showWorkLog === true ? '-focus' : ''}`} style={{ marginRight: '10px' }} onClick={handleShowWorklog}>
                                WorkLog
                            </Button>

                            <Button shape="round" className={`workLogButton${showSla === true ? '-focus' : ''}`} style={{ marginRight: '10px' }} onClick={handleShowSla}>
                                SLA Status
                            </Button>

                            <Button shape="round" className={`workLogButton${showTicketRelated === true ? '-focus' : ''}`} style={{ marginRight: '10px' }} onClick={handleShowTicket} disabled={!decoded.service.serviceId.includes(1)}>
                                Ticket Related
                            </Button>

                            {decoded.service.serviceId.includes(2) ? <Button shape="round" className={`workLogButton${showAssetRelated === true ? '-focus' : ''}`} style={{ marginRight: '10px' }} onClick={handleShowAsset} disabled={!decoded.service.serviceId.includes(2)}>
                                Asset Related
                            </Button> : null}

                            {decoded.service.serviceId.includes(4) ? <Button shape="round" className={`workLogButton${showWorkflowRelated === true ? '-focus' : ''}`} style={{ marginRight: '10px' }} onClick={handleShowWorkflow} disabled={!decoded.service.serviceId.includes(4)}>
                                Workflow Related
                            </Button> : null}

                            {decoded.service.serviceId.includes(5) ? <Button shape="round" className={`workLogButton${showKnowledgeRelated === true ? '-focus' : ''}`} style={{ marginRight: '10px' }} onClick={handleShowKnowledge} disabled={!decoded.service.serviceId.includes(5)}>
                                Knowledge Related
                            </Button> : null}

                            {decoded.service.serviceId.includes(8) ? <Button shape="round" className={`workLogButton${showProblem === true ? '-focus' : ''}`} style={{ marginRight: '10px' }} onClick={handleShowProblems} disabled={!decoded.service.serviceId.includes(8)}>
                                Problem Related
                            </Button> : null}

                            {showWorkLog ? (<div style={{ height: '200px', overflowY: 'scroll', marginTop: '20px' }}>{displayWorkLog()}</div>) : null}
                            {showSla
                                ? (<div>
                                    <Spin spinning={isLoadingDataRelated}>
                                        <br />
                                        <SpinningFetcher fetchingState={slaCalculationsFetchingState}>
                                            <SlaDisplay incidentId={id} incidentStatus={currentStatus?.status!!} pendingUntil={pendingUntil} />
                                        </SpinningFetcher>
                                    </Spin>
                                </div>)
                                : null
                            }
                            {showTicketRelated ? (<div style={{ border: '1px solid #BDBDBD', margin: '20px', padding: '20px' }}>{displayTicketRelated()}</div>) : null}
                            {showAssetRelated ? (<div style={{ border: '1px solid #BDBDBD', margin: '20px', padding: '20px' }}>{displayAssetRelated()}</div>) : null}
                            {showWorkflowRelated ? (<div style={{ border: '1px solid #BDBDBD', margin: '20px', padding: '20px' }}><DisplayRelateWorkflow incident={props.incident!!} dataWoRelateIncident={dataWoRalateIncident} isLoadingDataRelated={isLoadingDataRelated} /></div>) : null}
                            {showKnowledgeRelated ? (<div style={{ border: '1px solid #BDBDBD', margin: '20px', padding: '20px' }}>{displayKnowledgeRelated()}</div>) : null}
                            {showProblem ? (<div style={{ border: '1px solid #BDBDBD', margin: '20px', padding: '20px' }}>{displayProblemRelate()}</div>) : null}
                        </Card>
                        {dataIncident?.resolution && <CommentSection incidentId={id} isShowResolution={true} />}
                        <CommentSection incidentId={id} isShowResolution={false} />
                    </Col>
                    {!isNonDesktop
                        ? <Col lg={6} md={24} sm={24} xs={24}>
                            <Card>
                                <h3 style={{ color: '#323276', fontWeight: 'bold' }}>Requester</h3>
                                <Row>
                                    <Col offset={1}>
                                        <label><b>Requester :</b></label>
                                        <label style={{ marginLeft: '10px' }}>{dataIncident?.people.fullName}</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col offset={1}>
                                        <label><b>Email :</b></label>
                                        <label style={{ marginLeft: '10px' }}>{dataIncident?.people.email}</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col offset={1}>
                                        <label><b>Phone Number :</b></label>
                                        <label style={{ marginLeft: '10px' }}>{dataIncident?.people.tel || '-'}</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col offset={1}>
                                        <label><b>Department :</b></label>
                                        <label style={{ marginLeft: '10px' }}>{dataIncident?.people.department?.departmentName || '-'}</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col offset={1}>
                                        <label><b>Company :</b></label>
                                        <label style={{ marginLeft: '10px' }}>{dataIncident?.people?.company?.companyName || '-'}</label>
                                    </Col>
                                </Row>
                                <div style={{ border: '1px solid #E0E0E0', marginTop: '30px', marginBottom: '30px' }} />
                                <Row>
                                    <ViewMoreDetailCustomField dataIncident={dataIncident!!} customField={dataIncident?.customField} priority={props.priority} />
                                </Row>
                            </Card>
                        </Col>
                        : <Drawer
                            title="Requester"
                            placement="right"
                            closable={false}
                            width={window.innerWidth >= 768 ? '40%' : '80%'}
                        >

                        </Drawer>
                    }
                </Row>
                {/* </Spin> */}
            </Content>
        </Layout>
    )
}

export default connect(mapStateToProps,
    {
        getAllPriority,
        getAllWorkLogByIncidentId,
        getAllSlaCalculationByTicketIds: ForIncident.getAllSlaCalculationByTicketIds,
    }
)(ViewMoreDetailTicket)