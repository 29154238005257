/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react'
import {
    Breadcrumb,
    Button,
    Card,
    Dropdown,
    Form,
    Icon,
    Input,
    InputNumber,
    Menu,
    message,
    Modal,
    Select,
    Spin,
    Switch,
    Table,
    Tabs,
    Tooltip
} from 'antd'
// import { DndProvider } from 'react-dnd'
// import update from 'immutability-helper'
// import { DragableBodyRow, RNDContext } from './DragableBodyRow'
import { StoreState } from '../store'
import { FormComponentProps } from 'antd/lib/form'
import { connect } from 'react-redux'
import { getAllGeneralIncidentSetting } from '../incident-management/general-incident-setting/service'
import { Link, Redirect } from 'react-router-dom'
import { CustomFiled, CustomFileds, mySelectOption } from './model'
import {
    addCustomFields,
    getAllCustomFields,
    updateCustomFields
} from './service'
import CardFiled from './CardFiled'
import PMActivityFiled from './PMActivityFiled'
import { tokenDecode } from '../common-misc'
import WOCardFiled from './WOCardFiled'
import { v4 as uuid } from 'uuid'
import PMActivityPhotoFiled from './PMActivityPictureFiled'
// import Problems from './problems/Problems'
// import TaskProblems from './problems/TaskProblem'

const mapStateToProps = (state: StoreState) => {
    return {
        generalIncidentSetting: state.generalIncidentSetting,
        customFields: state.customFields
    }
}

type StateProps = ReturnType<typeof mapStateToProps>;

interface DispatchProps {
  getAllGeneralIncidentSetting: () => Promise<number>;
  getAllCustomFields: (value: string) => Promise<number>;
  addCustomFields: (customField: CustomFileds) => Promise<number>;
  updateCustomFields: (customField: CustomFileds) => Promise<number>;
  updateCustom: (customField: CustomFiled) => Promise<number>;
}
let arr: CustomFiled[] = []
let listsEdit: number[] = []
const { TabPane } = Tabs
// const { SubMenu } = Menu
type Props = StateProps & DispatchProps & FormComponentProps;
const DragSortingTable: React.FC<Props> = (props: Props) => {
    const {
        getFieldDecorator,
        getFieldValue,
        validateFields,
        setFieldsValue,
        resetFields
    } = props.form
    const { Option } = Select
    const { confirm } = Modal
    const [data, setData] = useState<CustomFiled[]>([])
    const [selectTicketType, setSelectTicketType] = useState<string>('')
    const [isRedirect, setIsRedirect] = useState<boolean>(false)
    const [checkIsUpdate, setCheckIsUpdate] = useState<boolean>(false)
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState(Object), [])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isRender, setIsRender] = useState<boolean>(false)
    const [isSpinning, setIsSpinning] = useState(false)
    const [isHasIncident, setIsHasIncident] = useState<boolean>(false)
    const [isHasAsset, setIsHasAsset] = useState<boolean>(false)
    const [isHasWO, setIsHasWO] = useState<boolean>(false)
    const [isHasPM, setIsHasPM] = useState<boolean>(false)
    const [tempListLabel, setTempListLabel] = useState<string[]>([])
    const [duplicatesLabel, setDuplicatesLabel] = useState<string[]>([])
    const [isMoveRow, setIsMoveRow] = useState<boolean>(false)
    const [defaultValue, setDefaultValue] = useState(1)
    let isSubmit = false
    // const [isCustomField, setCustomField] = useState(false);
    // const [isCustomPhoto, setCustomPhoto] = useState(false);

    // const handleCustomFieldSelect = () => {
    //   setCustomField(true);
    //   setCustomPhoto(false);
    // };
    // const handleCustomPhotoSelect = () => {
    //   setCustomPhoto(true);
    //   setCustomField(false);
    // };
    const [selectMenu, setSelectMenu] = useState('1')
    // const [, setSelectMenuProblem] = useState('1')
    const [currenTab, setCurrenTab] = useState<string>('0')

    useEffect(() => {
        initGeneralIncidentSetting()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectTicketType])

    useEffect(() => {
        const service = tokenDecode()?.service.serviceId
        console.log(service)
        if (service) {
            console.log(Math.min(...service))
            setDefaultValue(Math.min(...service))
            setCurrenTab(Math.min(...service).toString())
            if (service?.includes(1)) {
                setIsHasIncident(true)
            }
            if (service?.includes(2)) {
                setIsHasAsset(true)
            }
            if (service?.includes(3)) {
                setIsHasPM(true)
            }
            if (service?.includes(4)) {
                setIsHasWO(true)
            }
        }
    }, [])

    useEffect(() => {
        const fetchCustomFieldData = props.customFields?.fields?.map((it) => {
            it.createdDatetime = undefined
            it.createdBy = undefined
            it.lastModifiedBy = undefined
            it.lastModifiedDate = undefined
            it.key = uuid().slice(0, 8)
            return it
        })
        setData(fetchCustomFieldData)
        setIsSpinning(false)
    }, [props.customFields])

    useEffect(() => {
        if (
            props.generalIncidentSetting.ticketTypes &&
      props.generalIncidentSetting.ticketTypes?.length > 0 &&
      !isRender
        ) {
            setIsRender(true)
            setSelectTicketType(props.generalIncidentSetting.ticketTypes[0].value)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.generalIncidentSetting.ticketTypes])

    useEffect(() => {
        if (isMoveRow) {
            const listLabel2 = data?.map((obj) => {
                return obj.label?.toLocaleLowerCase() || ''
            })

            if (findDuplicates(listLabel2)) {
                setDuplicatesLabel(findDuplicates(listLabel2))
            } else {
                setDuplicatesLabel([])
            }
            setIsMoveRow(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMoveRow])

    const initGeneralIncidentSetting = () => {
        props.getAllGeneralIncidentSetting()
        setIsSpinning(true)
        if (selectTicketType) {
            props
                .getAllCustomFields(selectTicketType)
                .then(() => {
                    setCheckIsUpdate(false)
                    // setIsSpinning(false)
                })
                .catch((_err) => {
                    setCheckIsUpdate(true)
                    setData([])
                    setIsSpinning(false)
                })
        }
    }

    const AddData = () => {
        arr = []
        const listLabel = data?.map((obj) => {
            return obj.label || ''
        })
        setTempListLabel([...new Set(listLabel)])
        const small_id = uuid().slice(0, 8)
        const initData = {
            id: data?.length || 0,
            dataType: undefined,
            defaultValue: undefined,
            description: undefined,
            inputType: undefined,
            isRequireFields: false,
            label: undefined,
            name: undefined,
            placeholder: undefined,
            selectOption: undefined,
            key: small_id
        } as unknown as CustomFiled
        arr.push(initData)
        setData((data || []).concat(arr))
        forceUpdate()
    }

    const handleChangeLabel = (row: CustomFiled, value: string) => {
        row.label = value
        row.name = value
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const handleBlurLabel = (value: string) => {
    // console.log(duplicatesLabel)
    // if (duplicatesLabel.length !== 0) {
        const listLabel2 = data?.map((obj) => {
            return obj.label?.toLocaleLowerCase()?.trim() || ''
        })

        if (findDuplicates(listLabel2)) {
            setDuplicatesLabel(findDuplicates(listLabel2))
        } else {
            setDuplicatesLabel([])
        }
        // }
        tempListLabel.push(value)
    }

    const handleChangeDescription = (row: CustomFiled, value: string) => {
        row.description = value
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const handleChangeInputType = (row: CustomFiled, value, index: number) => {
        row.inputType = value
        const setValue = {}
        setValue['selectOption' + row.id + index] = undefined
        setValue['defaultValue' + row.id + index] = undefined
        setValue['placeholder' + row.id + index] = undefined
        row.defaultValue = ''
        row.selectOption = []
        row.placeholder = ''
        if (
            value === 'Checkbox' ||
      value === 'DropdownSelect' ||
      value === 'RadioButton'
        ) {
            setValue['dataType' + row.id + index] = 'String'
            row.dataType = 'String'
            setFieldsValue(setValue)
        } else if (value === 'DatePicker') {
            setValue['dataType' + row.id + index] = 'Date'
            row.dataType = 'Date'
            setFieldsValue(setValue)
        } else if (value === 'InputNumber') {
            setValue['dataType' + row.id + index] = 'Number'
            row.dataType = 'Number'
            setValue['defaultValue' + row.id + index] = 0
            row.defaultValue = 0
            setFieldsValue(setValue)
        } else {
            setValue['dataType' + row.id + index] = undefined
            row.dataType = undefined
            setFieldsValue(setValue)
        }

        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const handleChangeDataType = (row: CustomFiled, value, index: number) => {
        const setValue = {}
        row.dataType = value
        row.defaultValue = ''
        row.selectOption = []
        row.placeholder = ''
        setValue['selectOption' + row.id + index] = undefined
        setValue['defaultValue' + row.id + index] = undefined
        setValue['placeholder' + row.id + index] = undefined
        setFieldsValue(setValue)
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const handleChangeSelectOption = (row: CustomFiled, value) => {
        const selectedValue = value as Array<string>
        const pattern = /[A-Za-z0-9ก-๙]+/
        let isErrorPattern = false
        const arr_lower = selectedValue?.map((item) => item?.toLowerCase().trim())
        const lastValue = selectedValue[selectedValue.length - 1]
      ?.toLowerCase()
      .trim()
        arr_lower.pop()
        if (arr_lower.includes(lastValue)) {
            selectedValue.pop()
        }
        selectedValue.forEach((name) => {
            if (!pattern.test(name.replace(/ +/g, ' ').trim())) {
                selectedValue.pop()
                isErrorPattern = true
            }
        })
        if (isErrorPattern) {
            return
        }
        if (!isErrorPattern) {
            const addDataOption: mySelectOption[] = value?.map((it) => {
                return {
                    option: it,
                    moduleName: 'Incident'
                }
            })
            row.selectOption = addDataOption
            if (row.id) {
                listsEdit.push(row.id)
            }
        }
    }

    const handleChangeDefaultValue = (
        row: CustomFiled,
        value: string | number
    ) => {
        row.defaultValue = value
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const handleChangePlaceholder = (row: CustomFiled, value: string) => {
        row.placeholder = value
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const validateLabel = (_: any, value: any, callback) => {
        if (value) {
            // eslint-disable-next-line
      const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            // const checkLower = tempListLabel?.map((obj) => {
            //     return obj.toLowerCase() === value.toLowerCase()
            // })
            // const checkArray = checkLower.find((label) => {
            //     return label === true
            // })
            if (format.test(value)) {
                // eslint-disable-next-line
        // callback('Field Label can' + 't contain any of the following characters ' + format)
                callback()
            } else {
                // if ((tempListLabel.includes(value) && !isSubmit) || (checkArray && !isSubmit)) {
                //     callback('This entry already exists in the system.')
                // } else {
                //     callback()
                // }
                callback()
            }
        } else {
            callback()
        }
    }

    const validateMaxTag = (_: any, value: any, callback) => {
        if (value) {
            const valueIncorrect = value.filter((it) => it.length >= 256)
            if (valueIncorrect.length !== 0) {
                callback('error')
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    // const validateFieldName = (_: any, value: any, callback) => {
    //     // if (props.form.getFieldValue('RoleName')) {
    //     if (props.customFields.fields) {
    //         const fieldNameList = props.customFields.fields?.map((value) => {
    //             return value.name
    //         })
    //         // if (isEdit && value.trim() === defaultRoleAndPermissionName) {
    //         //     callback()
    //         // } else
    //         if (fieldNameList.includes(value.trim())) {
    //             callback('The Field name must be unique. Please try another one.')
    //         } else {
    //             callback()
    //         }
    //     }
    // // } else {
    // //     callback()
    // // }
    // }

    const getDefaultValue = (row: any, index: number) => {
        if (
            getFieldValue('inputType' + row.id + index) === 'Checkbox' ||
      getFieldValue('inputType' + row.id + index) === 'DatePicker' ||
      getFieldValue('inputType' + row.id + index) === 'RadioButton'
        ) {
            return undefined
        } else {
            if (getFieldValue('dataType' + row.id + index)) {
                return (
          getFieldValue('dataType' + row.id + index) as string
                ).toLowerCase()
            } else {
                return undefined
            }
        }
    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row, myData) => (
                <>
                    <Tooltip placement="bottom" title={'Add'}>
                        <Icon
                            className="del_icon"
                            type="plus-square"
                            onClick={() =>
                                addAfterRow(
                                    row.id,
                  data?.findIndex((it) => {
                      return it.key?.toString() === myData.key?.toString()
                  })
                                )
                            }
                        />
                    </Tooltip>
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row, data, index) => (
                <>
                    <Tooltip placement="bottom" title={'Delete'}>
                        <Icon
                            className="edit_icon"
                            type="minus-square"
                            onClick={() => deleteRow(index, row.label, row)}
                        />
                    </Tooltip>
                </>
            )
        },
        // {
        //     title: <span><span className={'requiredIcon'}>* </span>Field name</span>,
        //     dataIndex: '',
        //     key: '',
        //     render: (row, data, index) => (
        //         <>
        //             <Form.Item>
        //                 {getFieldDecorator('name' + row.name + index, {
        //                     initialValue: row.name,
        //                     rules: [{ required: true, message: 'Field Name is required.' }]
        //                 })(
        //                     <Input onChange={(e) => handleChangeName(row, e.target.value)} />
        //                 )}
        //             </Form.Item>
        //         </>
        //     )
        // },
        {
            title: (
                <span>
                    <span className={'requiredIcon'}>* </span>Field label
                </span>
            ),
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item
                        validateStatus={
                            row.label === ''
                                ? 'error'
                                : duplicatesLabel.includes(row.label?.toLocaleLowerCase())
                                    ? 'error'
                                    : undefined
                        }
                        help={
                            row.label === ''
                                ? 'Default value is not valid.'
                                : duplicatesLabel.includes(row.label?.toLocaleLowerCase())
                                    ? 'This entry already exists in the system.'
                                    : undefined
                        }
                    >
                        {getFieldDecorator('label' + row.id + index + row.key, {
                            initialValue: row.label,
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Field Label is required.'
                                },
                                {
                                    validator: !isSubmit ? validateLabel : undefined
                                }
                            ]
                        })(
                            <Input
                                onChange={(e) => handleChangeLabel(row, e.target.value)}
                                onBlur={(e) => handleBlurLabel(e.target.value)}
                                disabled={row.lastModifiedDatetime !== undefined}
                            />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Field description',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('description' + row.id + index, {
                            initialValue: row.description,
                            rules: [{ required: false }]
                        })(
                            <Input
                                onChange={(e) => handleChangeDescription(row, e.target.value)}
                            />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: (
                <span>
                    <span className={'requiredIcon'}>* </span>Input type
                </span>
            ),
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('inputType' + row.id + index, {
                            initialValue: row.inputType,
                            rules: [{ required: true, message: 'Input Type is required.' }]
                        })(
                            <Select
                                style={{ width: 135 }}
                                onChange={(e) => handleChangeInputType(row, e, index)}
                                disabled={row.lastModifiedDatetime !== undefined}
                                allowClear
                            >
                                <Option value="Checkbox">Checkbox</Option>
                                <Option value="DatePicker">Date picker</Option>
                                <Option value="DropdownSelect">Dropdown Select</Option>
                                <Option value="InputNumber">Input number</Option>
                                <Option value="RadioButton">Radio button</Option>
                                <Option value="TextBox">Text box</Option>
                            </Select>
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: (
                <span>
                    <span className={'requiredIcon'}>* </span>Data type
                </span>
            ),
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('dataType' + row.id + index, {
                            initialValue: row.dataType,
                            rules: [{ required: true, message: 'Data type is required' }]
                        })(
                            <Select
                                style={{ width: 135 }}
                                onChange={(e) => handleChangeDataType(row, e, index)}
                                disabled={row.lastModifiedDatetime !== undefined}
                                allowClear
                            >
                                {getFieldValue('inputType' + row.id + index) ===
                                    'DropdownSelect' ||
                                    getFieldValue('inputType' + row.id + index) === 'RadioButton' ||
                                    getFieldValue('inputType' + row.id + index) === 'TextBox' ||
                                    getFieldValue('inputType' + row.id + index) === 'Checkbox' ? (
                                        <Option value="String" key="1">
                                            String
                                        </Option>
                                    ) : null}
                                {getFieldValue('inputType' + row.id + index) === 'InputNumber' ? (
                                    <Option value="Number" key="2">
                                            Number
                                    </Option>
                                ) : null}
                                {getFieldValue('inputType' + row.id + index) === 'TextBox' ? (
                                    <Option value="Email" key="3">
                                        Email
                                    </Option>
                                ) : null}
                                {/* {getFieldValue('inputType' + row.id) === 'Checkbox' ? <Option value="Boolean" key='4'>Boolean</Option> : null} */}
                                {getFieldValue('inputType' + row.id + index) === 'DatePicker' ? (
                                    <Option value="Date" key="5">
                                        Date
                                    </Option>
                                ) : null}
                                {getFieldValue('inputType' + row.id + index) === 'DatePicker' ? (
                                    <Option value="DateTime" key="6">
                                        DateTime
                                    </Option>
                                ) : null}
                            </Select>
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Select option',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <div key={index}>
                    <Form.Item>
                        {getFieldDecorator('selectOption' + row.id + index, {
                            initialValue: row.selectOption
                                ? row.selectOption?.map((it) => {
                                    return it.option
                                })
                                : undefined,
                            rules: [
                                {
                                    required:
                    getFieldValue('inputType' + row.id + index) ===
                    'DropdownSelect' ||
                    getFieldValue('inputType' + row.id + index) ===
                    'Checkbox' ||
                    getFieldValue('inputType' + row.id + index) ===
                    'RadioButton',
                                    message: 'Select an option is required.'
                                },
                                {
                                    validator: validateMaxTag
                                }
                            ]
                        })(
                            <Select
                                disabled={
                                    !(
                                        getFieldValue('inputType' + row.id + index) ===
                    'DropdownSelect' ||
                    getFieldValue('inputType' + row.id + index) ===
                    'Checkbox' ||
                    getFieldValue('inputType' + row.id + index) ===
                    'RadioButton'
                                    )
                                }
                                mode="tags"
                                style={{ width: 135 }}
                                onChange={(e) => handleChangeSelectOption(row, e)}
                            />
                        )}
                    </Form.Item>
                </div>
            )
        },
        {
            title: 'Default value',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('defaultValue' + row.id + index, {
                            initialValue:
                getFieldValue('dataType' + row.id + index) === 'Number'
                    ? Number(row.defaultValue)
                    : row.defaultValue,
                            rules: [
                                {
                                    type: getDefaultValue(row, index),
                                    message: 'Default value is not valid.'
                                },
                                {
                                    required: false
                                }
                            ]
                        })(
                            getFieldValue('dataType' + row.id + index) === 'Number' ? (
                                <InputNumber
                                    min={0}
                                    onChange={(e) => handleChangeDefaultValue(row, e!!)}
                                    style={{ width: '100%' }}
                                    maxLength={255}
                                    disabled={
                                        getFieldValue('inputType' + row.id + index) ===
                    'Checkbox' ||
                    getFieldValue('inputType' + row.id + index) ===
                    'DatePicker' ||
                    getFieldValue('inputType' + row.id + index) ===
                    'RadioButton'
                                    }
                                />
                            ) : getFieldValue('inputType' + row.id + index) ===
                'DropdownSelect' ? (
                                    <Select
                                        disabled={
                                            getFieldValue('inputType' + row.id + index) === 'DatePicker'
                                        }
                                        onChange={(e) => handleChangeDefaultValue(row, e?.toString())}
                                        allowClear={true}
                                    >
                                        {(getFieldValue('selectOption' + row.id + index) || [])?.map(
                        (data, index) => {
                            return (
                                <Option value={data} key={index}>
                                    {data}
                                </Option>
                            )
                        }
                      )}
                                    </Select>
                                ) : (
                                    <Input
                                        onChange={(e) =>
                                            handleChangeDefaultValue(row, e.target.value)
                                        }
                                        maxLength={255}
                                        disabled={
                                            getFieldValue('inputType' + row.id + index) ===
                        'Checkbox' ||
                        getFieldValue('inputType' + row.id + index) ===
                        'DatePicker' ||
                        getFieldValue('inputType' + row.id + index) ===
                        'RadioButton'
                                        }
                                    />
                                )
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Placeholder',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('placeholder' + row.id + index, {
                            initialValue: row.placeholder,
                            rules: [{ required: false }]
                        })(
                            <Input
                                onChange={(e) => handleChangePlaceholder(row, e.target.value)}
                                disabled={
                                    getFieldValue('inputType' + row.id + index) === 'Checkbox' ||
                  getFieldValue('inputType' + row.id + index) ===
                  'DatePicker' ||
                  getFieldValue('inputType' + row.id + index) === 'RadioButton'
                                }
                            />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Require field',
            dataIndex: '',
            key: '',
            render: (row: CustomFiled) => (
                <>
                    <Tooltip
                        placement="bottom"
                        title={row.isRequireFields ? 'Deactivate' : 'Activate'}
                    >
                        <Switch
                            checked={row.isRequireFields}
                            onChange={() => toggleActiveness(row)}
                        />
                    </Tooltip>
                </>
            )
        }
    ]

    const findDuplicates = (arr) =>
        arr.filter((item, index) => arr.indexOf(item) !== index)

    const addAfterRow = (currentRow, index) => {
        arr = []
        data.forEach((obj) => {
            if (Number(obj.id) >= currentRow + 1) {
                obj.id = Number(obj.id)
            }
        })
        const listLabel = data?.map((obj) => {
            return obj.label || ''
        })
        const listLabel2 = data?.map((obj) => {
            return obj.label?.toLocaleLowerCase() || ''
        })

        if (findDuplicates(listLabel2)) {
            setDuplicatesLabel(findDuplicates(listLabel2))
        }
        setTempListLabel([...new Set(listLabel)])
        const small_id = uuid().slice(0, 8)
        data.splice(index + 1, 0, {
            id: currentRow + 1,
            dataType: undefined,
            defaultValue: undefined,
            description: undefined,
            inputType: undefined,
            isRequireFields: false,
            label: undefined,
            name: undefined,
            placeholder: undefined,
            selectOption: [],
            key: small_id
        })
        resetFields()
        setData((data || []).concat(arr))
    // setData(data)
    // validateFields()
    }

    const deleteRow = (index, value, mydata) => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete this data?',
            okText: 'OK',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'Cancel',
            async onOk() {
                const filterTempLabel = tempListLabel.filter((it) => it !== value)
                const tempData2 = data.filter((obj) => obj?.key !== mydata?.key)
                if (duplicatesLabel) {
                    const filterDuplicatesLabel = duplicatesLabel?.filter(
            (it) => it?.toLowerCase().trim() !== value?.toLowerCase().trim()
          )
                    setDuplicatesLabel(filterDuplicatesLabel)
                }
                setData(tempData2)
                setTempListLabel(filterTempLabel)
                message.success('The data has been deleted successfully.')
            },
            onCancel() { }
        })
    }

    const toggleActiveness = (data: CustomFiled) => {
        data.isRequireFields = !data.isRequireFields
        setCheckIsUpdate(!checkIsUpdate)
        forceUpdate()
    }

    const menu = () => {
        return (
            <Menu>
                {props.generalIncidentSetting?.ticketTypes?.map((it, index) => {
                    return (
                        <Menu.Item key={index} onClick={() => setTicketTypes(it.value)}>
                            <span>{it.value}</span>
                        </Menu.Item>
                    )
                })}
            </Menu>
        )
    }

    const setTicketTypes = (text: string) => {
        setSelectTicketType(text)
    }

    // const components = {
    //     body: {
    //         row: DragableBodyRow
    //     }
    // }

    // const moveRow = useCallback(
    //     (dragIndex, hoverIndex) => {
    //         const dragRow = data[dragIndex]
    //         setData(
    //             update(data, {
    //                 $splice: [
    //                     [dragIndex, 1],
    //                     [hoverIndex, 0, dragRow]
    //                 ]
    //             })
    //         )
    //         setIsMoveRow(true)
    //     },
    //     [data]
    // )

    // const manager = useRef(RNDContext)
    const renderTable = () => {
        return (
            // <DndProvider manager={manager.current.dragDropManager!!}>
            <Spin spinning={isSpinning}>
                <Table
                    columns={columns}
                    dataSource={data || []}
                    // components={components}
                    // rowKey='uuId'
                    // onRow={(record, index) => ({
                    //     index,
                    //     moveRow
                    // })}
                    scroll={{ x: 768 }}
                    // className="Table-CustomField"
                />
            </Spin>
            // </DndProvider>
        )
    }

    const goBack = () => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you want to leave this page ?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                listsEdit = []
                setIsRedirect(true)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const handleSubmit = (e: any): void => {
        e.preventDefault()
        isSubmit = true
        validateFields((_err) => {
            if (_err) {
                console.log('err')
                isSubmit = false
            } else {
                setIsLoading(true)
                let isError = false

                const uniqueValues = [
                    ...new Set(data?.map((v) => v.label?.toLocaleLowerCase()?.trim()))
                ]
                if (uniqueValues && uniqueValues.length !== data.length) {
                    isError = true
                }

                const convertData = data?.map((it, i) => {
                    const isEdit = [...new Set(listsEdit)].find((list) => list === it.id)
                    it.name = it.label
                        ? it.label!!.replaceAll(' ', '_').toLowerCase()
                        : undefined
                    it.label = it.label ? it.label.replace(/ +/g, ' ').trim() : undefined
                    if (isEdit !== undefined) {
                        it.id = undefined
                    }
                    if (!it.selectOption) {
                        it.selectOption = []
                    }
                    // delete it.key;
                    return { ...it, customFieldOrder: i, active: true }
                })
                const formData: CustomFileds = {
                    ticketType: selectTicketType,
                    fields: convertData
                }
                console.log('send : ', formData)
                if (!isError) {
                    // Update
                    props
                        .updateCustomFields(formData)
                        .then((res) => {
                            if (res === 200) {
                                setIsRedirect(true)
                                message.success('The Update has finished successfully')
                                setIsLoading(false)
                                listsEdit = []
                                isSubmit = false
                            }
                        })
                        .catch((_err) => {
                            setIsLoading(false)
                            isSubmit = false
                            message.error(_err.response.data.message || 'Please complete all custom fields.')
                        })
                } else {
                    setIsLoading(false)
                }
            }
        })
    }

    const customfieldmenu = (
        <Menu onClick={({ key }) => handleMenuSelect(key)}>
            <Menu.Item key="1">Set PM Activity</Menu.Item>
            <Menu.Item key="2">Set Upload Image</Menu.Item>
        </Menu>
    )

    const tabPMActivityMenu = (
        <Dropdown overlay={customfieldmenu} trigger={['hover']}>
            <span style={{ fontWeight: 'bold' }}>
        Preventive Maintenance&nbsp;
                <Icon type="down" />
            </span>
        </Dropdown>
    )

    const handleMenuSelect = (key) => {
        setSelectMenu(key)
    }

    // const customfieldmenuProblem = (
    //     <Menu onClick={({ key }) => handleMenuSelectProblem(key)}>
    //         <Menu.Item key="1">Problem</Menu.Item>
    //         <Menu.Item key="2">Problem Task</Menu.Item>
    //     </Menu>
    // )

    // const tabProblemMenu = (
    //     <Dropdown overlay={customfieldmenuProblem} trigger={['hover']}>
    //         <span style={{ fontWeight: 'bold' }}>
    //     Problem&nbsp;
    //             <Icon type="down" />
    //         </span>
    //     </Dropdown>
    // )

    // const handleMenuSelectProblem = (key) => {
    //     setSelectMenuProblem(key)
    // }

    return (
        <>
            {isRedirect ? <Redirect to="Setting" /> : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Custom Field Setting</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Tabs defaultActiveKey={defaultValue.toString()}
                activeKey={currenTab}
                onChange={(value) => setCurrenTab(value)} >
                <TabPane tab={<span style={{ fontWeight: 'bold' }}>Ticket</span>} key="1" disabled={!isHasIncident}>
                    <Card style={{ overflow: 'auto' }}>
                        <h3 className={'main-title text-herder-Orenge'}>Custom Field Setting</h3>
                        <div style={{ marginBottom: 10 }}>
                            <span style={{ color: 'red' }}>
                * <span style={{ color: '#323276' }}>Ticket Type : </span>
                            </span>
                            <Dropdown overlay={() => menu()} trigger={['click']}>
                                <span style={{ color: '#323276', fontWeight: 'bold' }}>
                                    {selectTicketType} <Icon type="down" />
                                </span>
                            </Dropdown>
                        </div>
                        <Button
                            onClick={() => AddData()}
                            htmlType="button"
                            className="addCriteriaButton"
                        >
              Add
                        </Button>
                        <br />
                        <br />
                        <Form onSubmit={handleSubmit}>
                            {renderTable()}
                            <Form.Item style={{ float: 'right', marginTop: 10 }}>
                                <Button
                                    htmlType="button"
                                    onClick={() => goBack()}
                                    style={{ marginRight: 10 }}
                                >
                  Cancel
                                </Button>
                                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </TabPane>

                {/* <TabPane tab={tabProblemMenu} key="6">
                    {selectMenuProblem === '1' ? <Problems /> : <TaskProblems />}
                </TabPane> */}

                <TabPane tab={<span style={{ fontWeight: 'bold' }}>Asset</span>} key="2" disabled={!isHasAsset}>
                    <CardFiled />
                </TabPane>

                <TabPane tab={tabPMActivityMenu} key="3" disabled={!isHasPM} >
                    {selectMenu === '1' ? <PMActivityFiled currenTab={currenTab} /> : <PMActivityPhotoFiled />}
                </TabPane>

                {/* <TabPane tab="Preventive Maintenance" key="3" disabled={!isHasAsset}>
           <PMActivityFiled />
          <Menu>
            <SubMenu key="dropdown" title="Dropdown">
              <Menu.Item key="">Set PM Activity</Menu.Item>
              <Menu.Item key="">Set Upload Image</Menu.Item>
            </SubMenu>
          </Menu>
          {isCustomField ? (
            <PMActivityFiled />
          ) : isCustomPhoto ? (
            <PMActivityPhotoFiled />
          ) : (
            <Menu>
              <SubMenu key="dropdown" title="Dropdown">
                <Menu.Item
                  key="customFieldSetting"
                  onClick={handleCustomFieldSelect}
                >
                  Set PM Activity
                </Menu.Item>
                <Menu.Item
                  key="customPhotoSetting"
                  onClick={handleCustomPhotoSelect}
                >
                  Set Upload Image
                </Menu.Item>
              </SubMenu>
            </Menu>
          )}
        </TabPane> */}
                <TabPane tab={<span style={{ fontWeight: 'bold' }}>Work Order</span>} key="4" disabled={!isHasWO}>
                    <WOCardFiled />
                </TabPane>
            </Tabs>
        </>
    )
}

const MyFormDragSortingTable = Form.create<Props>()(DragSortingTable)

export default connect(mapStateToProps, {
    getAllGeneralIncidentSetting,
    getAllCustomFields: getAllCustomFields,
    addCustomFields: addCustomFields,
    updateCustomFields: updateCustomFields
})(MyFormDragSortingTable)
