/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Drawer,
    Dropdown,
    Form,
    Icon,
    Input,
    Menu,
    message,
    Modal,
    Row,
    Select,
    Table,
    Tag,
    Tooltip
} from 'antd'
import { Link } from 'react-router-dom'
import { Space } from '../../common-components/Space'
import { StoreState } from '../../store'
import { connect } from 'react-redux'
import { assetSettingColumn, FilterAsset } from './model'
import { deleteAllAsset, getAssetColumnSetting, getAssetFilter, getAssetList, searchFetch, setAssetColumnSetting } from './service'
import { FormComponentProps } from 'antd/lib/form'
import ReactHtmlParser from 'react-html-parser'
import { decryptDataVspace, nullOrEmptyObject } from '../../common-misc'
// import Scanner from '../Relate/Asset/BarcodeScanner'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import { getDataAssetGroup, getDataLocationBuilding } from '../../Preventive/services'
import { AssetGroup, AssetGroupType } from '../../Preventive/Model'
import { LocationBuildingContext } from '../../Preventive/ContextAPI/LocationBuildingContext'
import PageService from '../PageService'
// import Barcode from '../../common-components/Barcode'
// import IconSorter from '../../assets/images/iconSorter.png'
import { ColumnNormal } from '../../ReportComponent/components/Model'
import { convertCamelCaseToSentenceCase } from '../../knowledge-management/util'
import BarcodeScannerWithZoom from '../../common-components/BarcodeSacnnerWithZoom'
import ModifyColumnAsset from './ModifyColumnAsset'

const mapStateToProps = (state: StoreState) => {
    return {
        assetOwnerLocationList: state.assetOwnerLocationList,
        filterAsset: state.filterAsset
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAssetList: (search: string | undefined, filter: FilterAsset | undefined, sortBy?, orderBy?, skip?, limit?) => Promise<number>
    getAssetFilter: () => Promise<number>
}

type Props = StateProps & DispatchProps & FormComponentProps

const { Search } = Input
const { Option } = Select
const { confirm } = Modal
const AssetList: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue, resetFields, validateFields, getFieldValue } = props.form
    const [isOnLoadingData, setIsOnLoadingData] = useState<boolean>(false)
    const [isOnLoadingFilter, setIsOnLoadingFilter] = useState<boolean>(true)
    const [isSearchChange, setIsSearchChange] = useState<boolean>(true)
    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const [barcodeVisible, setBarcodeVisible] = useState<boolean>(false)
    const [searchText, setSearchText] = useState<string>('')
    const [searchFilter, setSearchFilter] = useState<FilterAsset>()
    const [page, setPage] = useState<number>(PageService.getInstance().page)
    const [pageSize, setPageSize] = useState<number>(10)
    const [, setTotalWO] = useState<number>()
    const [, setIsAssetCenter] = useState<boolean>(true)
    const [isChangeSize, setIsChangeSize] = useState<boolean>(true)
    const { GetLocationBuilding, LocationBuildingState } = useContext<any>(LocationBuildingContext)
    const [location, setLocation] = useState<string[]>(LocationBuildingState)
    const [, setAssetType] = useState<string[]>(props.filterAsset.assetType?.slice(0, 20) || [])
    const [brand, setBrand] = useState<string[]>(props.filterAsset.brand?.slice(0, 20) || [])
    const [model, setModel] = useState<string[]>(props.filterAsset.model?.slice(0, 20) || [])
    const [orderBy, setOrderBy] = useState<string>('desc')
    const [sortBy, setSortBy] = useState<string>('number')
    const [assetGroups, setAssetGroups] = useState<AssetGroupType[]>([])
    const [dataLocation, setDataLocation] = useState<string[]>([])
    const [isDeleteAsset, setIsDeleteAsset] = useState<boolean>(false)
    const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([])
    const [assetDeleteSelected, setAssetDeleteSelected] = useState<number[]>([])
    const [openSettingList, setOpenSettingList] = useState<boolean>(false)
    const [defaultFieldSetting, setDefaultFieldSetting] = useState<assetSettingColumn[]>([])
    const [defaultOtherHeader, setDefaultOtherHeader] = useState<assetSettingColumn[]>([])
    const [otherHeader, setOtherHeader] = useState<assetSettingColumn[]>([])
    const [modifyColumn, setModifyColumn] = useState<assetSettingColumn[]>([])
    const dataVspaceInfo = decryptDataVspace()
    const [columns, setColumns] = useState<ColumnNormal[]>([])
    const [dataGroup, setDataGroup] = useState<AssetGroup[]>([])
    const [dataType, setDataType] = useState<AssetGroupType[]>([])
    const [dataSite, setDataSite] = useState<string[]>([])

    useEffect(() => {
        if (dataVspaceInfo?.roleId.toString()) {
            setIsAssetCenter(dataVspaceInfo.roleId.toString() === '444' || dataVspaceInfo.roleId.toString() === '351' || dataVspaceInfo.roleId.toString() === '473' || dataVspaceInfo.roleId.toString() === '474' || dataVspaceInfo.roleId.toString() === '475')
        }
        loadData()
        getDataAssetGroup().then((res) => {
            const tempDataGroup: AssetGroup[] = res.map((it) => { return it.assetGroup })
            const uniqueDataGroup = [...new Map(tempDataGroup.map((item) => 
                [item.name, item])).values()]
            setAssetGroups(res)
            setDataGroup(uniqueDataGroup)
        })
        getDataLocationBuilding().then((res) => {
            if (GetLocationBuilding) {
                GetLocationBuilding(res)
            }
        })
        getAssetColumnSetting().then((res) => {
            if (res) {
                setIsOnLoadingData(true)
                setDefaultFieldSetting(res.data)
                setDefaultOtherHeader(res.otherHeader)
                setModifyColumn(res.data)
                setOtherHeader(res.otherHeader)
                const modify = res.data.map(item => item.columnName)
                const other = res.otherHeader.map(item => item.columnName)
                const allHeader = [...modify, ...other]
                const temp: ColumnNormal[] = []
                res.data.forEach((fields) => {
                    const findDataIndex = allHeader.find((data) => data === fields.columnName)
                    if (findDataIndex) {
                        const columnModel: ColumnNormal = {
                            title: convertCamelCaseToSentenceCase(findDataIndex),
                            dataIndex: findDataIndex,
                            key: findDataIndex,
                            sorter: true
                        }

                        if (findDataIndex === 'serialNumber') {
                            columnModel.render = (row, data) => (
                                <>{<Link to={`/assetPreview/${data.id}/${row}`}>{row}</Link>}</>
                            )
                        } else if (findDataIndex === 'assetTag') {
                            columnModel.render = (row) => (
                                <>{row?.length > 25 ? (row as string).substring(0, 25) + '...' : row}</>
                            )
                        } else if (findDataIndex === 'ownerId') {
                            columnModel.title = 'Owner ID'
                        } else if (findDataIndex === 'status') {
                            columnModel.title = 'Asset Status'
                        } else if (findDataIndex === 'assetGroup') {
                            columnModel.title = 'Group'
                        } else if (findDataIndex === 'assetGroupType') {
                            columnModel.title = 'Type'
                        }
                        temp.push(columnModel)
                    }
                })
                setColumns(temp)
                setIsOnLoadingData(false)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (LocationBuildingState) {
            const tempSite: string[] = (LocationBuildingState || []).map((data) => {
                return data.site !== '' ? data.site : '-'
            })
            const temp: string[] = (LocationBuildingState || []).map((data) => {
                return data.location !== '' && data.location !== null ? data.location : '-'
            })
            const filterTempData = temp.filter((it) => it !== null)
            const filterTempDataSite = tempSite.filter((it) => it !== null)
            setLocation([...new Set(filterTempData)])
            // setDataLocation([...new Set(filterTempData)].slice(0, 20))
            setDataSite([...new Set(filterTempDataSite)].slice(0, 20))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [LocationBuildingState])

    const loadData = async () => {
        if (Object.keys(props.filterAsset).length === 0) {
            props.getAssetFilter().then(() => {
                setIsOnLoadingFilter(false)
            })
        } else {
            setIsOnLoadingFilter(false)
        }
    }

    useEffect(() => {
        if (searchFilter) {
            if (JSON.stringify(searchFilter) === '{}') {
                setPage(1)
                PageService.getInstance().page = 1
                resetFields()
            } else {
                setPage(1)
                PageService.getInstance().page = 1
                setFieldsValue({
                    Location: searchFilter?.location,
                    Status: searchFilter?.status,
                    Asset_Type: searchFilter?.assetType,
                    Brand: searchFilter?.brand,
                    Model: searchFilter?.model
                })
            }
        }
        if (!openFilter) {
            if (searchText !== '') {
                search(searchText, true)
            }
        }
        let getDatasessionStorage: any = sessionStorage.getItem('valueFilterAssetList')
        if (getDatasessionStorage) {
            getDatasessionStorage = JSON.parse(getDatasessionStorage)
            setSearchFilter(getDatasessionStorage as any)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openFilter])

    useEffect(() => {
        if (pageSize !== 10) {
            setPage(1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize])

    useEffect(() => {
        setPage(PageService.getInstance().page)
        setTotalWO(props.assetOwnerLocationList.total)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.assetOwnerLocationList.total])

    useEffect(() => {
        fecthAssetList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText, searchFilter, sortBy, orderBy, page])

    useEffect(() => {
        if (window.innerWidth > 1024) {
            setIsChangeSize(true)
        } else {
            setIsChangeSize(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.innerWidth])

    useEffect(() => {
        if (props.filterAsset) {
            setLocation(location?.slice(0, 20) || [])
            setAssetType(props.filterAsset.assetType?.slice(0, 20) || [])
            setBrand(props.filterAsset.brand?.slice(0, 20) || [])
            setModel(props.filterAsset.model?.slice(0, 20) || [])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterAsset.location])

    const fecthAssetList = () => {
        setIsOnLoadingData(true)
        setIsSearchChange(false)
        let getDatasessionStorage: any = sessionStorage.getItem('valueFilterAssetList')
        if (getDatasessionStorage) {
            getDatasessionStorage = JSON.parse(getDatasessionStorage)
        }

        props.getAssetList(searchText, getDatasessionStorage || searchFilter, sortBy, orderBy, page - 1, pageSize).then(() => {
            setIsOnLoadingData(false)
        }).catch((err) => {
            setIsOnLoadingData(false)
            message.error(`Failed fetching data. ${err}`)
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.order === 'ascend') {
            setOrderBy('ASC')
            if (sorter.field) {
                setSortBy(sorter.field)
            } else {
                setSortBy('number')
            }
        } else if (sorter.order === 'descend') {
            setOrderBy('DESC')
            if (sorter.field) {
                setSortBy(sorter.field)
            } else {
                setSortBy('number')
            }
        } else {
            setSortBy('number')
        }
        setPageSize(pagination.pageSize)
    }

    const search = (value?: string, forceSearch?: boolean) => {
        let filter: FilterAsset | undefined
        if (!nullOrEmptyObject(searchFilter)) {
            filter = searchFilter
            sessionStorage.setItem('valueFilterAssetList', JSON.stringify(filter))
        } else {
            filter = searchFilter
            sessionStorage.removeItem('valueFilterAssetList')
        }
        if (isSearchChange || forceSearch) {
            setIsOnLoadingData(true)
            setIsSearchChange(false)
            let getDatasessionStorage: any = sessionStorage.getItem('valueFilterAssetList')
            if (getDatasessionStorage) {
                getDatasessionStorage = JSON.parse(getDatasessionStorage)
            }
            props.getAssetList(value, getDatasessionStorage || filter, sortBy, orderBy, page - 1, pageSize).then(() => {
                setIsOnLoadingData(false)
            }).catch((err) => {
                setIsOnLoadingData(false)
                message.error(`Failed fetching data. ${err}`)
            })
        }
    }

    const handleSearchAsset = (value) => {
        setPage(1)
        PageService.getInstance().page = 1
        search(value?.trim())
        setSearchText(value?.trim())
        setIsSearchChange(true)
        setFieldsValue({ Search: value?.trim() })
    }

    const createOption = (dataSource: string[]) => {
        const options = dataSource.map((data) => {
            return (<Option key={data} title={data}>{data}</Option>)
        })
        return options
    }

    const handleOnclickPageNumber = (page: number) => {
        PageService.getInstance().page = page
        setPage(page)
    }

    const handleSubmit = () => {
        props.form.validateFields(async (_err, values) => {
            const ConvertData: FilterAsset = {
                location: values.Location,
                status: values.Status,
                assetType: values.Asset_Type,
                brand: values.Brand,
                model: values.Model
            }
            setSearchFilter(ConvertData)
            sessionStorage.setItem('valueFilterAssetList', JSON.stringify(ConvertData))
            setOpenFilter(false)
        })
    }

    const handleClose = () => {
        props.form.resetFields()
    }

    const searchFetch2 = (value: string) => {
        if (typeof value === 'string' && value.length >= 1) {
            const uniqueDataLocation = [...new Map(LocationBuildingState.map((item) => 
                [item.location, item])).values()]
            const filterStringLocation = uniqueDataLocation.map((it: any) => {return it.location }) 
            const filterNotNull = filterStringLocation.filter((it) => it !== null && it !== '')
            const filterData = filterNotNull.filter((data) => data.toUpperCase().includes(value.toUpperCase()))
            if (filterData.length > 0) {
                if (filterData.length < 20) {
                    setDataLocation(filterData)
                } else {
                    setDataLocation(filterData.slice(0, 20))
                }
            }
        } else {
            // if (location) {
            //     setDataLocation(location.slice(0, 20))
            // }
            const findDataSite = (LocationBuildingState || []).filter((it) => it.site === getFieldValue('Site'))
            const uniqueLocation = [...new Map(findDataSite.map((item) => 
                [item.location, item])).values()]
            const mapStringLocation = uniqueLocation.map((it: any) => { return it.location })
            if (uniqueLocation) {
                setDataLocation(mapStringLocation)
            }
        }
    }

    const handleOnChangeGroup = (value) => {
        if (value) {
            const filterGroup = assetGroups.filter((it) => { return it.assetGroup.name === value })
            setDataType(filterGroup)
        } else {
            setDataType([])
        }
    }

    const handleOnChangeSite = (value) => {
        if (value) {
            const filterLocation = (LocationBuildingState || []).filter((it) => { return it.site === value})
            const uniqueLocation = [...new Map(filterLocation.map((item) => 
                [item.location, item])).values()]
            const mapLocationString = uniqueLocation.map((it: any) => { return it.location })
            const filterNotNullData = mapLocationString.filter((it) => it !== null && it !== '')
            setDataLocation(filterNotNullData)
        } else {
            setDataLocation([])
        }
    }

    const Filter = (
        <Form>
            <Row>
                <Form.Item label="Site">
                    <Col md={24}>
                        {getFieldDecorator('Site')(
                            <Select placeholder='Select Site' style={{ width: '100%' }} onChange={handleOnChangeSite} allowClear>
                                {(dataSite || []).map((res, index) => {
                                    return <Option value={res} key={index}>{res}</Option>
                                })}
                            </Select>
                        )}
                    </Col>
                </Form.Item>
                <Form.Item label="Location">
                    <Col md={24}>
                        {getFieldDecorator('Location')(
                            <Select
                                // onSearch={(value) => { searchFetch(value, location || [], setLocation) }}
                                mode="multiple"
                                placeholder='Select Location'
                                style={{ width: '100%' }}
                                disabled={getFieldValue('Site') === undefined}
                                // onChange={(value) => { handleSearchFilter(value, 'location') }}
                                loading={isOnLoadingFilter}
                                onSearch={(e) => searchFetch2(e)}
                            >
                                {(dataLocation || []).map((res, index: number) => {
                                    return <Option value={res} key={index}>{res}</Option>
                                })}
                            </Select>
                        )}
                    </Col>
                </Form.Item>
                <Form.Item label="Asset Status">
                    <Col md={24}>
                        {getFieldDecorator('Status')(
                            <Select
                                mode="multiple"
                                placeholder='Select Asset Status'
                                style={{ width: '100%' }}
                                // onChange={(value) => { handleSearchFilter(value, 'status') }}
                                loading={isOnLoadingFilter}
                            >{createOption(props.filterAsset.status || [])}
                            </Select>
                        )}
                    </Col>
                </Form.Item>
                <Form.Item label="Asset Group">
                    <Col md={24}>
                        {getFieldDecorator('Asset_Group')(
                            <Select 
                                placeholder='Select Asset Group' 
                                style={{ width: '100%' }} 
                                // onSelect={handleSelectGroup} 
                                onChange={handleOnChangeGroup}
                                allowClear
                            >
                                {createOption((dataGroup || []).map((data) => { return data.name }))}
                            </Select>
                        )}
                    </Col>
                </Form.Item>
                <Form.Item label="Type">
                    <Col md={24}>
                        {getFieldDecorator('Asset_Type')(
                            <Select
                                onSearch={(value) => { searchFetch(value, props.filterAsset.assetType || [], setAssetType) }}
                                mode="multiple"
                                placeholder='Select Type'
                                disabled={getFieldValue('Asset_Group') === undefined}
                                style={{ width: '100%' }}
                                // onChange={(value) => { handleSearchFilter(value, 'assetType') }}
                                loading={isOnLoadingFilter}
                            >{createOption((dataType || []).map((data) => { return data.type }))}
                            </Select>
                        )}
                    </Col>
                </Form.Item>
                <Form.Item label="Brand">
                    <Col md={24}>
                        {getFieldDecorator('Brand')(
                            <Select
                                onSearch={(value) => { searchFetch(value, props.filterAsset.brand || [], setBrand) }}
                                mode="multiple"
                                placeholder='Select Brand'
                                style={{ width: '100%' }}
                                // onChange={(value) => { handleSearchFilter(value, 'brand') }}
                                loading={isOnLoadingFilter}
                            >{createOption(brand || [])}
                            </Select>
                        )}
                    </Col>
                </Form.Item>
                <Form.Item label="Model">
                    <Col md={24}>
                        {getFieldDecorator('Model')(
                            <Select
                                onSearch={(value) => { searchFetch(value, props.filterAsset.model || [], setModel) }}
                                mode="multiple"
                                placeholder='Select Model'
                                style={{ width: '100%' }}
                                // onChange={(value) => { handleSearchFilter(value, 'model') }}
                                loading={isOnLoadingFilter}
                            >{createOption(model || [])}
                            </Select>
                        )}
                    </Col>
                </Form.Item>
                <Form.Item style={{ textAlign: 'end' }}>
                    <Button htmlType="button" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="button" onClick={handleSubmit} style={{ marginLeft: 5 }}>
                        Submit
                    </Button>
                </Form.Item>
            </Row>
        </Form>
    )

    const searchFilterTag = (title: string, searchFilter: string[], onClose: Function) => {
        let tags: JSX.Element = (<></>)
        if (searchFilter.length > 0) {
            tags = (
                <Tooltip placement="bottom" title={ReactHtmlParser(searchFilter.map((filter: string) => { return `- ${filter}` }).join('</br>'))}>
                    <Tag style={{ margin: 10 }} closable onClose={onClose}>{title}</Tag>
                </Tooltip>)
        }
        return tags
    }

    const barcodeScanner = (value) => {
        if (value) {
            setBarcodeVisible(false)
            // eslint-disable-next-line no-useless-escape
            const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g
            const cleanedStr = value.replace(regex, '')
            setFieldsValue({
                Search: cleanedStr
            })
            // search(cleanedStr, true)
        }
    }

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={() => setOpenSettingList(true)}> <Icon type="bars" />Setting List</Menu.Item>
            <Menu.Item key="2" onClick={() => setIsDeleteAsset(true)} disabled={!checkRolesState(RoleType.Asset, 'DeleteAsset')}> <Icon type='delete' /> Delete Asset</Menu.Item>
        </Menu>
    )

    const handleRowChangeSelection = (selectedRowKeys, selectedRows) => {
        const selectedRowIds = selectedRows.map((data) => data.id)
        const uniqueIds = [...new Set([...assetDeleteSelected, ...selectedRowIds])]
        const filterUniqueIds = uniqueIds.filter(id => selectedRowIds.includes(id))
        setSelectRowKeys(selectedRowKeys)
        setAssetDeleteSelected(filterUniqueIds)
    }

    const rowDeleteAssetSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: handleRowChangeSelection
    }

    const handleConfirmDelete = () => {
        confirm({
            title: <span style={{ color: '#323276', fontSize: '25px', fontWeight: 'bold' }}>Are you sure ?</span>,
            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>Do you confirm Delete of the data ?</span>,
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                setIsOnLoadingData(true)
                deleteAllAsset(assetDeleteSelected).then((res) => {
                    if (res) {
                        setIsDeleteAsset(false)
                        setSelectRowKeys([])
                        message.success('You have Successfully deleted the data')
                        props.getAssetList(searchText, searchFilter, sortBy, orderBy, page - 1, pageSize).then(() => {
                            setIsOnLoadingData(false)
                        }).catch((err) => {
                            setIsOnLoadingData(false)
                            console.log(err)
                        })
                    }
                })
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const handleCancelDeleteAsset = () => {
        setSelectRowKeys([])
        setIsDeleteAsset(false)
    }

    const handleAddField = (index) => {
        const newKey = modifyColumn.length > 0 ? Math.max(...modifyColumn.map(field => field.columnOrder)) + 1 : 1
        const newField = { columnLabel: '', columnName: '', columnOrder: newKey, columnPosition: 'FLEXIBLE' }
        const insertField = [
            ...modifyColumn.slice(0, index + 1),
            newField,
            ...modifyColumn.slice(index + 1)
        ]

        setModifyColumn(insertField)
    }

    function warning() {
        Modal.warning({
            title: <span style={{ fontSize: '25px', fontWeight: 'bold', color: '#323276' }}>Warning</span>,
            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>At least one field must be specified.</span>,
            className: 'custom-modal-wo'
        })
    }

    const handleRemoveField = (key) => {
        if (modifyColumn.length <= 2) {
            warning()
            return
        }
        const removeField = modifyColumn.find(field => field.columnOrder === key)?.columnName
        const updatedModifyColumn = modifyColumn.filter(field => field.columnOrder !== key)
        setModifyColumn(updatedModifyColumn)

        if (removeField) {
            setOtherHeader(prevOtherHeader => [
                ...prevOtherHeader,
                {
                    columnName: removeField,
                    columnLabel: removeField === 'ownerId' ? 'Owner ID' : convertCamelCaseToSentenceCase(removeField),
                    columnOrder: key,
                    columnPosition: 'FLEXIBLE'
                }
            ])
        }
    }

    const handleChangeField = (key, value) => {
        const prevValue = modifyColumn.find(field => field.columnOrder === key)?.columnName
        
        setModifyColumn(prevModifyColumn => {
            return prevModifyColumn.map(field => 
                field.columnOrder === key 
                ? {...field, columnLabel: value === 'ownerId' ? 'Owner ID' : convertCamelCaseToSentenceCase(value), columnName: value, columnPosition: 'FLEXIBLE'}
                : field
            )
        })
        
        setOtherHeader(prevOtherHeader => {
            if (prevValue && prevValue !== value) {
                const filterOptionOtherHeader = prevOtherHeader.filter(option => option.columnName !== value)
                return [...filterOptionOtherHeader, { columnName: prevValue, columnLabel: prevValue === 'ownerId' ? 'Owner ID' : convertCamelCaseToSentenceCase(prevValue), columnOrder: key, columnPosition: 'FLEXIBLE' }]
            } 
            return prevOtherHeader.filter(option => option.columnName !== value)
        })
    }

    const handleCloseSetting = () => {
        confirm({
            title: <b style={{ color: '#323276', fontSize: '25px', fontWeight: 'bold' }}>Are you sure?</b>,
            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>Do you want to leave this page ?</span>,
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                setModifyColumn(defaultFieldSetting)
                setOtherHeader(defaultOtherHeader)
                setOpenSettingList(false)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const handleSaveModifyColumn = () => {
        validateFields((err, values) => {
            if (!err) {
                const body = modifyColumn.map((fields) => ({
                    columnName: fields.columnName,
                    columnOrder: fields.columnOrder,
                    columnPosition: fields.columnPosition
                }))

                const allHeader = [...modifyColumn.map(item => item.columnName), ...otherHeader.map(item => item.columnName)]
                setIsOnLoadingData(true)
                setDefaultFieldSetting(modifyColumn)
                setDefaultOtherHeader(otherHeader)
                setAssetColumnSetting(body).then((res) => {
                    if (res) {
                        const temp: ColumnNormal[] = []
                        res.forEach((columns) => {
                            const findDataIndex = allHeader.find((data) => data === columns.columnName)
                            if (findDataIndex) {
                                const columnModel: ColumnNormal = {
                                    title: convertCamelCaseToSentenceCase(findDataIndex),
                                    dataIndex: findDataIndex,
                                    key: findDataIndex,
                                    sorter: true
                                }
                                if (findDataIndex === 'serialNumber') {
                                    columnModel.render = (row, data) => (
                                        <>{<Link to={`/assetPreview/${data.id}/${row}`}>{row}</Link>}</>
                                    )
                                } else if (findDataIndex === 'assetTag') {
                                    columnModel.render = (row) => (
                                        <>{row?.length > 25 ? (row as string).substring(0, 25) + '...' : row}</>
                                    )
                                } else if (findDataIndex === 'ownerId') {
                                    columnModel.title = 'Owner ID'
                                } else if (findDataIndex === 'status') {
                                    columnModel.title = 'Asset Status'
                                } else if (findDataIndex === 'assetGroup') {
                                    columnModel.title = 'Group'
                                } else if (findDataIndex === 'assetGroupType') {
                                    columnModel.title = 'Type'
                                }
                                temp.push(columnModel)
                            }
                        })
                        setColumns(temp)
                        setOpenSettingList(false)
                        setIsOnLoadingData(false)
                        fecthAssetList()
                    }
                    message.success('The data has been updated successfully.')
                }).catch((err) => {
                    console.log(err)
                    setOpenSettingList(false)
                    setIsOnLoadingData(false)
                })
            }
        })
    }

    const settingList = (
        <Form>
            <Row style={{ borderBottom: '1px solid #D0D5DD', paddingBottom: '10px' }}>
                <Form.Item label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Fixed</span>}>
                    <Col span={18}>
                        {modifyColumn.filter((data) => data.columnPosition === 'FIXED').map((fields, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', padding: '8px 8px', justifyContent: 'space-between' }}>
                                <Input style={{ fontSize: '14px' }} value={fields.columnLabel} disabled={true} />
                            </div>
                        ))}
                    </Col>
                </Form.Item>
            </Row>
            <Row>
                <Form.Item label={<span style={{ fontSize: '16px', fontWeight: 'bold' }} >Modify</span>}>
                    {modifyColumn.filter((data) => data.columnPosition !== 'FIXED').map((fields, index) => (
                        <div key={index}>
                            <ModifyColumnAsset
                                form={props.form}
                                fieldsData={fields}
                                columnKey={index + 1}
                                otherHeader={otherHeader}
                                handleAddField={handleAddField}
                                handleChangeField={handleChangeField}
                                handleRemoveField={handleRemoveField}
                            />
                        </div>
                    ))}
                </Form.Item>
                <Form.Item style={{ textAlign: 'end' }}>
                    <Button htmlType="button" onClick={handleCloseSetting}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="button" style={{ marginLeft: 5 }} onClick={handleSaveModifyColumn}>
                        Save
                    </Button>
                </Form.Item>
            </Row>
        </Form>
    )

    const titleText = 'Can search Serial Number or Asset Tag or Asset Name and Owner ID'

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>Asset Management</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={20} />
            <Drawer
                title="Advanced Search"
                placement="left"
                closable={true}
                onClose={() => { setOpenFilter(false) }}
                visible={openFilter}
                width={window.innerWidth <= 768 ? '70%' : '30%'}
                bodyStyle={{ paddingBottom: 80, overflow: 'auto' }}
            >
                {Filter}
            </Drawer>
            <Drawer
                title={<span style={{ color: '#FA541C', fontSize: '20px', fontWeight: 'bold' }}>Setting List</span>}
                placement='right'
                closable={false}
                onClose={() => { setOpenSettingList(false) }}
                visible={openSettingList}
                width={window.innerWidth <= 768 ? '70%' : '30%'}
                bodyStyle={{ paddingBottom: 80, overflow: 'auto' }}
                maskClosable={false}
            >
                {settingList}
            </Drawer>
            <Card >
                <Row gutter={[8, 8]}>
                    <Col xs={20} sm={20} md={12} xl={8}>
                        <Form>
                            <Form.Item>
                                <Tooltip placement='bottom' title={titleText} overlayStyle={{ maxWidth: 500 }}>
                                    {getFieldDecorator('Search')(
                                        <Search
                                            placeholder="Can search Serial Number or Asset Tag or Asset Name and Owner ID"
                                            onSearch={handleSearchAsset}
                                            allowClear
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Tooltip>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col xs={4} sm={4} md={12} xl={1} style={{ paddingTop: 6 }}>
                        {isChangeSize ? null : <Button onClick={() => { setBarcodeVisible(!barcodeVisible) }} ><Icon type="barcode" style={{ margin: 0 }} /></Button>}
                    </Col>

                    <Col xs={24} sm={24} md={12} xl={15}>
                        {!isDeleteAsset
                            ? <Row gutter={[8, 8]} style={{ display: 'flex', justifyContent: 'flex-end'}}>
                                {/* <Col xs={2} sm={2} md={2} xl={2} style={{ textAlign: 'start' }}>
                                    <Dropdown overlay={menu} trigger={['click']}>
                                        <Button type='default' className='custom-menu-settingList' >
                                            <Icon type='more' style={{ display: 'flex' }} />
                                        </Button>
                                    </Dropdown>
                                </Col>
                                <Col xs={22} sm={22} md={22} xl={5}>
                                    <Link to={'/assetForm'} ><Button type="primary" style={{ float: 'right', marginLeft: 15, marginBottom: 5, padding: '0px 15px 0px 10px' }} icon="plus" disabled={!checkRolesState(RoleType.Asset, 'CreateAsset')}>Add New</Button></Link>
                                </Col> */}
                                <Col xs={22} sm={22} md={22} xl={5} style={{ display: 'contents' }}>
                                    <Link to={'/assetForm'} ><Button type="primary" style={{ float: 'right', marginLeft: 15, marginBottom: 5, padding: '0px 15px 0px 10px' }} icon="plus" disabled={!checkRolesState(RoleType.Asset, 'CreateAsset')}>Add New</Button></Link>
                                    <Dropdown overlay={menu} trigger={['click']}>
                                        <Button type='default' className='custom-menu-settingList' >
                                            <Icon type='more' style={{ display: 'flex' }} />
                                        </Button>
                                    </Dropdown>
                                </Col>
                            </Row>
                            : <Row gutter={[8, 8]} style={{ flexFlow: 'row-reverse', display: 'flex', flexWrap: window.innerWidth < 1200 ? 'wrap' : undefined }}>
                                <Col xs={24} sm={24} md={24} xl={5}>
                                    <Button type='primary' style={{ width: '100%' }} onClick={selectedRowKeys.length > 0 ? handleConfirmDelete : undefined}> <Icon type='delete' /> Delete ({selectedRowKeys.length > 0 ? selectedRowKeys.length : 0}) </Button>
                                </Col>
                                <Col xs={24} sm={24} md={24} xl={5}>
                                    <Button type="default" style={{ width: '100%' }} htmlType="reset" onClick={handleCancelDeleteAsset}>Cancel</Button>
                                </Col>
                            </Row>
                        }

                    </Col>
                    <Col xs={24} sm={20} md={20} xl={20}>
                        <Button size="large" style={{ border: 0, marginTop: 10 }} onClick={() => { setOpenFilter(true) }} loading={isOnLoadingData} ><Icon type="filter" theme="filled" /> Advanced Search</Button>
                        {searchFilter?.location ? searchFilterTag('Location', searchFilter.location, () => { delete searchFilter.location; search(searchText !== '' ? searchText : undefined, true) }) : null}
                        {searchFilter?.status ? searchFilterTag('Status', searchFilter.status, () => { delete searchFilter.status; search(searchText !== '' ? searchText : undefined, true) }) : null}
                        {searchFilter?.assetType ? searchFilterTag('Type', searchFilter.assetType, () => { delete searchFilter.assetType; search(searchText !== '' ? searchText : undefined, true) }) : null}
                        {searchFilter?.brand ? searchFilterTag('Brand', searchFilter.brand, () => { delete searchFilter.brand; search(searchText !== '' ? searchText : undefined, true) }) : null}
                        {searchFilter?.model ? searchFilterTag('Model', searchFilter.model, () => { delete searchFilter.model; search(searchText !== '' ? searchText : undefined, true) }) : null}
                    </Col>
                </Row>

                {<Modal
                    title="Scan Barcode"
                    visible={barcodeVisible}
                    onOk={() => { setBarcodeVisible(false) }}
                    onCancel={() => { setBarcodeVisible(false) }}
                    width={550}
                    footer={null}
                    maskClosable={false}
                    style={{ top: 10 }}
                >
                    <BarcodeScannerWithZoom barcodeScanner={barcodeScanner} barcodeVisible={barcodeVisible} />
                </Modal>}
                <br />
                <div style={{ whiteSpace: 'nowrap', width: '100%' }}>
                    <Table
                        rowKey="serialNumber"
                        columns={columns}
                        dataSource={props.assetOwnerLocationList.data!!}
                        onChange={handleTableChange}
                        bodyStyle={{ overflow: 'auto' }}
                        pagination={{
                            pageSize: pageSize,
                            current: page,
                            total: props.assetOwnerLocationList.total!!,
                            onShowSizeChange(current, size) {
                                setPageSize(size)
                                setPage(1)
                            },
                            onChange: (event) => { handleOnclickPageNumber(event) },
                            showSizeChanger: true
                        }}
                        scroll={{ x: 'max-content' }}
                        loading={isOnLoadingData}
                        rowSelection={isDeleteAsset ? rowDeleteAssetSelection : undefined}
                    />
                </div>
            </Card>
        </>
    )
}

const MyAssetList = Form.create({ name: 'AssetList' })(AssetList)

export default connect(
    mapStateToProps,
    { getAssetList, getAssetFilter }
)(MyAssetList)
