/* eslint-disable no-useless-escape */
import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Input, message, Modal, Row, Table, Tooltip} from 'antd'
import {FormComponentProps} from 'antd/lib/form'
import {StoreState} from '../../../store'
import {connect} from 'react-redux'
import {getAllAsset, searchAssetRelatedWo, searchAssetSerial} from './service'
import {createAssetRelateWoView} from '../../wo-management'
import {Asset} from './model'
import QRCode from '../../../common-components/QRCode'
import Scanner from '../Asset/BarcodeScanner'
import {AssetOwnerLocation, getAssetList} from '../../asset-management'

const mapStateToProps = (state: StoreState) => {
    return {
        asset: state.asset,
        assetOwnerLocationList: state.assetOwnerLocationList
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllAsset: () => Promise<number>
    getAssetList: (search: string | undefined, filter: undefined, sortBy?, orderBy?, skip?, limit?) => Promise<number>
}

interface Param {
    getData: Function,
    isAsset: boolean,
    setData: Asset[],
    assetvisible: boolean,
    assetSpare: boolean,
    woId: string,
    isEdit?: boolean
}

const { Search } = Input

type Props = Param & StateProps & DispatchProps & FormComponentProps
const SelectAsset: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [data, setDataAsset] = useState<any>(null)
    const [dataSearch, setDataSearch] = useState<AssetOwnerLocation[]>([])
    const [dataAssetSelected, setDataAssetSelected] = useState<Asset[]>([])
    const [visible, setVisible] = useState<boolean>(false)
    const [barcodeVisible, setBarcodeVisible] = useState<boolean>(false)
    const [valueScanner, setValueScanner] = useState<string | undefined>(undefined)
    const pageSize = 10
    const [page, setPage] = useState<number>(1)
    const [sortBy, setSortBy] = useState<string>('serialNumber')
    const [orderBy, setOrderBy] = useState<string>('desc')
    const [totalAsset, setTotalAsset] = useState<number>()
    const [onSearch, setOnSearch] = useState<string>('')
    const [tableLoading, setTableLoading] = useState<boolean>(false)
    const [dataAssetSelectedKeys, setDataAssetSelectedKeys] = useState<string[]>([])
    const [submittedKeys, setSubmittedKeys] = useState<string[]>([])

    useEffect(() => {
        if (props.assetOwnerLocationList) {
            setDataSearch(props.assetOwnerLocationList.data)
            setTotalAsset(props.assetOwnerLocationList.total)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.assetOwnerLocationList])

    useEffect(() => {
        if (getFieldValue('search') === '' || getFieldValue('search') === undefined) {
            setDataSearch([])
            setTotalAsset(0)
            setOnSearch('')
            setPage(1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSearch])

    useEffect(() => {
        setPage(1)
        setDataSearch([])
        setDataAssetSelected([])
        setOnSearch('')
        setFieldsValue({
            search: undefined
        })
        if (props.setData.length > 0 && Array.isArray(props.setData)) {
            const newKeysData = props.setData.map(item => item.serialNumber).filter(serialNumber => serialNumber !== undefined) as string[]
            setSubmittedKeys(newKeysData)
        }
        if (props.setData.length === 0) {
            setSubmittedKeys([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.setData])

    // useEffect(() => {
    //     if (window.innerWidth > 765) {
    //         setIsChangeSize(true)
    //     } else {
    //         setIsChangeSize(false)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [window.innerWidth])

    useEffect(() => {
        if (props.isAsset) {
            if (props.setData && data) {
                // const concatData = data.concat(props.setData)
                // const arrayUniqueByKey = [...new Map(concatData.map(item => [item.id, item])).values()]
                props.getData(data, props.isAsset)
            } else {
                props.getData(data, props.isAsset)
            }
        } else {
            // if (props.setData && dataSpare) {
            //     const concatData2 = dataSpare.concat(props.setData)
            //     const arrayUniqueByKey2 = [...new Map(concatData2.map(item => [item.id, item])).values()]
            //     props.getData(arrayUniqueByKey2, props.isAsset)
            // } else {
            //     props.getData(dataSpare, props.isAsset)
            // }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        setDataSearch([])
        setDataAssetSelected([])
        setOnSearch('')
        setPage(1)
        setFieldsValue({
            search: undefined
        })
        if (props.assetvisible === true) {
            const tempId: string[] = []
            for (const it of props.setData) {
                tempId.push(it.serialNumber!!)
            }
            // props.setData.map((it) => {
            //     tempId.push(it.serialNumber!!)
            // })
            setDataAssetSelectedKeys(tempId)
            setDataAssetSelected(props.setData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.assetvisible, props.assetSpare])

    useEffect(() => {
        if (onSearch && props.isEdit) {
            setIsLoading(true)
            setTableLoading(true)
            searchAssetRelatedWo(props.woId, onSearch.trimStart(), sortBy, orderBy, (page - 1) * pageSize, pageSize).then((res) => {
                setDataSearch(res.data)
                setTotalAsset(res.total)
                // if (res.data.length === 0) {
                //     message.info('No data available.')
                // }
                setFieldsValue({
                    search: onSearch.trimStart()
                })
                setIsLoading(false)
                setTableLoading(false)
            }).catch((err) => {
                setIsLoading(false)
                setTableLoading(false)
                message.error(`Unable to retrieve the data. Please try again later. ${err}`)
            })
        } else if (onSearch && !props.isEdit) {
            setIsLoading(true)
            setTableLoading(true)
            props.getAssetList(onSearch.trimStart(), undefined, sortBy, orderBy, (page - 1) * pageSize, pageSize).then((res) => {
                setFieldsValue({
                    search: onSearch.trimStart()
                })
                setIsLoading(false)
                setTableLoading(false)
            }).catch((err) => {
                setIsLoading(false)
                setTableLoading(false)
                message.error(`Unable to retrieve the data. Please try again later. ${err}`)
            })
        } else {
            setIsLoading(false)
            setTableLoading(false)
            setDataSearch([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortBy, orderBy, onSearch, page])

    const columns = [
        {
            title: <span className='bold-titleWo'>Serial Number</span>,
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            sorter: true
        },
        {
            title: <span className='bold-titleWo'>Location</span>,
            dataIndex: 'location',
            key: 'location',
            sorter: true
        },
        {
            title: <span className='bold-titleWo'>Asset Status</span>,
            dataIndex: 'status',
            key: 'status',
            sorter: true
        },
        {
            title: <span className='bold-titleWo'>Asset Tag</span>,
            dataIndex: 'assetTag',
            key: 'assetTag',
            sorter: true
        },
        {
            title: <span className='bold-titleWo'>Type</span>,
            dataIndex: 'assetGroupType.type',
            key: 'assetType',
            sorter: true
        },
        {
            title: <span className='bold-titleWo'>Asset Name</span>,
            dataIndex: 'assetName',
            key: 'assetName',
            sorter: true
        },
        {
            title: <span className='bold-titleWo'>Brand</span>,
            dataIndex: 'brand',
            key: 'brand',
            sorter: true
        },
        {
            title: <span className='bold-titleWo'>Model</span>,
            dataIndex: 'model',
            key: 'model',
            sorter: true
        },
        {
            title: <span className='bold-titleWo'>Owner</span>,
            dataIndex: 'usersId',
            key: 'usersId',
            sorter: true,
            render: (record, row) => {
                return (
                    <>
                        {row?.people ? row?.people?.employeeId + ' : ' + row?.people?.fullName : ''}
                    </>
                )
            }
        },
        {
            title: <span className='bold-titleWo'>Manufacturer</span>,
            dataIndex: 'manufacturer',
            key: 'manufacturer',
            sorter: true
        }
    ]

    // const CheckTypeData = (row, checkAsset) => {
    //     if (checkAsset === true) {
    //         const addKeyAsset = tempSelectedAsset.map((it) => {
    //             it.assetType = 'asset'
    //             return it
    //         })
    //     } else {
    //         const addKeySpare = dataAssetSelected.map((it) => {
    //             it.assetType = 'spare'
    //             return it
    //         })
    //         setDataAssetSpare(addKeySpare)
    //     }
    // }

    const barcodeScanner = (value) => {
        if (value) {
            setBarcodeVisible(false)
            setFieldsValue({
                search: value
            })
            onSearchFun(value)
        }
    }

    const onSearchFun = (value) => {
        if (!isLoading) {
            setIsLoading(true)
            if (value) {
                searchAssetSerial(value).then((res) => {
                    setDataSearch(res)
                    // if (res.length === 0) {
                    //     message.info('No data available.')
                    // }
                    setIsLoading(false)
                }).catch((err) => {
                    setIsLoading(false)
                    message.error(`Unable to retrieve the data. Please try again later. ${err}`)
                })
            } else {
                setIsLoading(false)
                setDataSearch([])
            }
        }
    }

    const handleRowSelection = (selectedRowKeys, selectedRows) => {
        if (selectedRowKeys.length > 50) {
            warning()
        } else if (selectedRowKeys.length <= 50) {
            setDataAssetSelectedKeys([...selectedRowKeys])
            setDataAssetSelected([...dataAssetSelected, ...selectedRows])
        }
    }

    const rowSelection = {
        selectedRowKeys: dataAssetSelectedKeys,
        getCheckboxProps: record => ({
            name: record.name
        }),
        onChange: handleRowSelection
    }
    const selectAsset = () => {
        // if (props.isAsset) {
        //     CheckTypeData(dataAssetSelected, true)
        // } else {
        //     CheckTypeData(dataAssetSelected, false)
        // }
        if (props.isAsset) {
            const tempSelectedAsset = dataAssetSelected.filter((it) => deselectedAssetRelated(it))
            const arrayUniqueByKey = [...new Map(tempSelectedAsset.map(item =>
                [item.serialNumber, item])).values()]
            setDataAsset(arrayUniqueByKey)
        }
        if (props.isEdit) {
            const newKeys = dataAssetSelectedKeys.filter(key => !submittedKeys.includes(key))
            createAssetRelateWoView(props.woId, newKeys).then(() => {
                setSubmittedKeys([...submittedKeys, ...newKeys])
                // setDataAssetSelectedKeys([])
            })
        }
    }

    const handleOK = () => {
        setVisible(false)
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const handleOKBarcode = () => {
        setBarcodeVisible(false)
    }

    const handleCancelBarcode = () => {
        setBarcodeVisible(false)
    }

    const getDataScan = (value) => {
        setVisible(false)
        const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g
        // eslint-disable-next-line no-useless-escape
        const cleanedStr = value.replace(regex, '')
        setValueScanner(cleanedStr)
        onSearchFun(cleanedStr)
    }

    const handleOnclickPageNumber = (page: number) => {
        setPage(page)
    }

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.order === 'ascend') {
            setOrderBy('ASC')
            if (sorter.columnKey) {
                setSortBy(sorter.columnKey)
            } else {
                setSortBy('serialNumber')
            }
        } else if (sorter.order === 'descend') {
            setOrderBy('DESC')
            if (sorter.columnKey) {
                setSortBy(sorter.columnKey)
            } else {
                setSortBy('serialNumber')
            }
        } else {
            setSortBy('serialNumber')
        }
    }

    const warning = () => {
        Modal.warning({
            title: <span style={{ color: '#323276', fontSize: '22px', fontWeight: 'bold'}}>Warning</span>,
            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>Please select no more than 50 asset.</span>,
            className: 'custom-modal-wo'
        })
    }

    const deselectedAssetRelated = (value) => {
        for (const serialNumber of dataAssetSelectedKeys) {
            if (value.serialNumber === serialNumber) {
                return value
            }
        }
    }

    return (
        <>
            <Row>
                <Col span={22}>
                    <Form>
                        <Form.Item >
                            <Tooltip placement='bottom' title={'Can search Serial Number, Asset Tag, Asset Name, Manufacturer and Owner'} overlayStyle={{maxWidth: 500}}>
                                {getFieldDecorator('search',
                                    {
                                        initialValue: valueScanner || undefined
                                    })(
                                    <Search
                                        placeholder="Can search Serial Number, Asset Tag, Asset Name, Manufacturer and Owner"
                                        allowClear
                                        onSearch={(e) => {
                                            setOnSearch(e)
                                            setPage(1)
                                        }}
                                        onKeyDown={(e: React.KeyboardEvent) => e.keyCode === 13 ? e.preventDefault() : ''}
                                        style={{ width: 450, margin: '0 10px' } }
                                    />
                                )}
                            </Tooltip>
                        </Form.Item>
                    </Form>
                </Col>
                {/* <Col span={4} style={{ textAlign: 'right' }}>
                    {isChangeSize ? null : <Icon style={{ marginLeft: '5%', marginTop: '3%' }} className="scan_icon" type="barcode" onClick={e => { setBarcodeVisible(true) }} />}
                </Col> */}
                <Col span={2} style={{ textAlign: 'right' }}>
                    <Button type='primary' htmlType='button' disabled={dataAssetSelected.length === 0 || !onSearch} onClick={() => selectAsset()}> Relate</Button>
                </Col>
            </Row>

            <Table
                loading={tableLoading}
                rowSelection={rowSelection}
                rowKey='serialNumber'
                columns={columns}
                dataSource={dataSearch}
                pagination= {{
                    pageSize: pageSize,
                    current: page,
                    total: totalAsset,
                    onChange: (event) => { handleOnclickPageNumber(event) }
                }}
                onChange={handleTableChange}
                style={{overflow: 'auto'}}
            />

            <Modal
                title="Scan QR-Code"
                visible={visible}
                onOk={handleOK}
                onCancel={handleCancel}
                footer={null}
            >
                <QRCode getDataScan={getDataScan} />
            </Modal>

            <Modal
                title="Scan Barcode"
                visible={barcodeVisible}
                onOk={handleOKBarcode}
                onCancel={handleCancelBarcode}
                width={700}
                footer={null}
            >
                {barcodeVisible
                    ? <Scanner barcodeScanner={barcodeScanner} />
                    : null}
                <p>{undefined}</p>
            </Modal>
        </>
    )
}

const searchForm = Form.create<Props>({ name: 'searchAsset' })(SelectAsset)

export default connect(mapStateToProps, {
    getAllAsset,
    getAssetList: getAssetList
})(searchForm)
