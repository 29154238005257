/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Col, Icon, Layout, Menu, Modal, Row } from 'antd'
import { Link, Redirect } from 'react-router-dom'
import GoServiceMiniLogo from '../assets/images/GoServiceMiniLogo.png'
import GoServiceLogoFull from '../assets/images/LogoGoService.png'
import UserDetails from './UserDetails'
import { connect } from 'react-redux'
import { StoreState } from '../store'
import { checkRolesState, loadRolesState, RoleState, RoleType } from '../authorization-module/permissions'
import { getAllSupportTeam } from '../incident-management/support-team'
import { SupportTeam } from '../incident-management/incident/model'
import { FetchingState, fetchWithState } from '../common-components'
import Footer from '../common-components/DefaultFooter'
import { renewToken } from '../authorization-module/login/service'
import moment from 'moment'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { tokenDecode } from '../common-misc'
import { getCookie } from '../guard/route'
import BubbleChat from './components/BubbleChat'
// import Page404 from './Page404'

const { Header, Sider, Content } = Layout

const mapStateToProps = (state: StoreState) => {
    return {
        userRolesAndPermissions: state.userRolesAndPermissions,
        permission: state.currentUserRole,
        supportTeam: state.supportTeams
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    loadRolesState: () => Promise<number>
    getAllSupportTeam: () => Promise<number>
}

interface Param {
    // auth?: Auth
    permission: RoleState
    supportTeam: SupportTeam[]
}

type Props = Param & StateProps & PropsWithChildren<Param> & DispatchProps
const activePathKm = sessionStorage.getItem('active_key_path')
const { confirm } = Modal
const { SubMenu } = Menu
const Sidebar: React.FC<Props> = (props: Props) => {
    const [collapsed, setCollapsed] = useState<boolean>(true)
    const [isFetchingRoleState, setFetchingRoleState] = useState<FetchingState>(FetchingState.NotStarted)
    const [isFetchingSupportTeam, setFetchingSupportTeam] = useState<FetchingState>(FetchingState.NotStarted)
    const [onBreakpoint, setOnBreakpoint] = useState<boolean>(false)
    const isMSPUser = getCookie('access_token') !== null
    const [online, setOnline] = useState(window.navigator.onLine)
    const [service, setService] = useState<any>([])
    const [, setToken] = useState<any>()
    // const [isOpenThisTab, setIsOpenThisTab] = useState<boolean>(true)
    // const [isPathTrue, setIsPathTrue] = useState<boolean>(true)
    const isShowNoti = sessionStorage.getItem('isShowNoti')
    const [isRedirect, setIsRedirect] = useState(false)

    const fetchData = () => {
        fetchWithState(isFetchingRoleState, setFetchingRoleState, props.loadRolesState)
        fetchWithState(isFetchingSupportTeam, setFetchingSupportTeam, props.getAllSupportTeam)
    }

    useEffect(() => {
        fetchData()
        // if (decryptDataVspace()) {
        //     const dataVspace = decryptDataVspace()!!
        //     setVspaceRole(dataVspace.roleId)
        // }
        window.addEventListener('online', () => setOnline(true))
        window.addEventListener('offline', () => setOnline(false))
        // const tempChildren: any = props.children
        // if (tempChildren) {
        //     const temp: string[] = (tempChildren).map((data) => {
        //         return data.props.path?.toLowerCase() || ''
        //     })

        // const splitPath = temp.map((data) => {
        //     return data.toLowerCase().split('/')[1]
        // })

        // if (splitPath.includes(window.location.pathname?.toLowerCase().split('/')[1])) {
        //     setIsPathTrue(true)
        // } else {
        //     setIsPathTrue(false)
        // }
        // }
        // console.log(getCookie('access_token'))
        // document.addEventListener('visibilitychange', function() {
        //     // Check if the page is hidden
        //     if (document.hidden) {
        //         console.log('User opened another tab')
        //         setIsOpenThisTab(false)
        //     } else {
        //         console.log('User is on this tab')
        //         setIsOpenThisTab(true)
        //     }
        // })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setService(tokenDecode()?.service.serviceId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        try {
            const token = getCookie('access_token') || ''
            const decoded: any = jwt_decode(token)
            setToken(decoded)
            const now = moment()
            sessionStorage.setItem('username', decoded?.sub)
            // sessionStorage.setItem('access_token', getCookie('access_token') || '')
            sessionStorage.setItem('tenant', decoded?.tenantID)
            if (typeof decoded === 'object') {
                const exp = decoded?.exp || 0
                const dayShouldRefreshToken = 3 * 60 * 60 * 1000
                const timer = window.setInterval(() => {
                    if (Date.now() >= (exp * 1000) - dayShouldRefreshToken) {
                        console.info('refresh')
                        renewToken()
                    }
                }, 60 * 60 * 1000)
                if (decoded?.service && isShowNoti === null) {
                    if (Array.isArray(decoded?.service)) {
                        (decoded?.service || []).forEach((data) => {
                            if (data.expireDate) {
                                if (moment(data.expireDate).diff(now, 'day') === 30) {
                                    messageNotifyExpire(data.serviceId, 30)
                                }
                                if (moment(data.expireDate).diff(now, 'day') === 15) {
                                    messageNotifyExpire(data.serviceId, 15)
                                }
                                if (moment(data.expireDate).diff(now, 'day') === 5) {
                                    messageNotifyExpire(data.serviceId, 5)
                                }
                                if (moment(data.expireDate).diff(now, 'day') === 1) {
                                    messageNotifyExpire(data.serviceId, 1)
                                }
                            }
                        })
                    }
                }

                return () => { // Return callback to run on unmount.
                    window.clearInterval(timer)
                }
            }
        } catch (error: any) {
            sessionStorage.clear()
            // window.location.href = '/'
            console.error(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigator.onLine, online])

    const messageNotifyExpire = (serviceId: number, day: number) => {
        if (serviceId === 1) {
            showConfirm('Service Incident Message', `The service ticket licenses will expire in ${day} days.`)
        }
        if (serviceId === 2) {
            showConfirm('Service Asset Message', `The service asset licenses will expire in ${day} days.`)
        }
        if (serviceId === 3) {
            showConfirm('Service Preventive Maintenance Message', `The service preventive maintenance licenses will expire in ${day} days.`)
        }
        if (serviceId === 4) {
            showConfirm('Service Work order Message', `The service work order licenses will expire in ${day} days.`)
        }
        if (serviceId === 5) {
            showConfirm('Service Knowledge Message', `The service knowledge licenses will expire in ${day} days.`)
        }
        if (serviceId === 6) {
            showConfirm('Service SLA Message', `The service SLA licenses will expire in ${day} days.`)
        }
    }

    const showConfirm = (title: string, content: string) => {
        confirm({
            title: title,
            content: content,
            iconType: 'exclamation-circle',
            okCancel: false,
            maskClosable: true,
            className: 'my-custom-class',
            onCancel() {
                sessionStorage.setItem('isShowNoti', 'true')
            }
        })
    }

    let pin = '1'
    const pathname = window.location.pathname
    if (pathname.includes('/Incident')) {
        pin = '2'
    } else if (pathname.includes('/Knowledge')) {
        pin = '6'
    } else if (pathname.includes('/Setting') || pathname.includes('/CategorySetting')) {
        pin = '7'
    } else if (pathname.includes('/Approval')) {
        pin = '8'
    } else if (pathname.includes('/assetList')) {
        pin = '3'
    } else if (pathname.includes('/Preventive')) {
        pin = '4'
    } else if (pathname.includes('/WoList')) {
        pin = '5'
    } else if (pathname.includes('/CatalogList')) {
        pin = '12'
    }

    if (!pathname.includes('/Knowledge') || !activePathKm) {
        sessionStorage.setItem('active_key_path', ',all')
    }
    const toggle = () => {
        setCollapsed(!collapsed)
    }

    const checkServiceInclude = (service: any, stringInclude: string[], key: string) => {
        const array2Numbers = stringInclude.map(Number)
        if (array2Numbers.some(item => service.includes(item))) {
            return sidebarMenuKey(key)
        } else {
            return null
        }
    }

    const clearTemplete = () => {
        sessionStorage.removeItem('cloneData')
    }

    const sidebarMenuKey = (key: string) => {
        if (key === '1') {
            return <Menu.Item key="1" onClick={clearTemplete}>
                <SidebarMenu title="Dashboard" collapsed={collapsed} path="/dashboard-all" icon="appstore" />
            </Menu.Item>
        } else if (key === '2') {
            // return <Menu.Item key="2" onClick={clearTemplete} disabled={!checkRolesState(RoleType.Incident, 'ViewIncident')}>
            //     <SidebarMenu title="Ticket Management" collapsed={collapsed} path="/IncidentList"
            //         icon="warning" />
            // </Menu.Item>
            return <SubMenu
                key="2"
                title={
                    <div style={collapsed ? { display: 'flex', flexFlow: 'row-reverse', paddingLeft: 10 } : undefined}>
                        <Icon type="warning" style={{ color: '#ffffff' }} />
                        <span style={{ color: '#041E42', fontWeight: 500 }}>Ticket Management</span>
                    </div>
                }
            >
                <Menu.Item onClick={clearTemplete} key="2" disabled={!checkRolesState(RoleType.Incident, 'ViewIncident')}><Link to={'/IncidentList'}>Ticket</Link></Menu.Item>
                <Menu.Item onClick={clearTemplete} key="13" disabled={!checkRolesState(RoleType.Incident, 'ViewReportTicket')}><Link to={'/IncidentReport'}>Report</Link></Menu.Item>
            </SubMenu>
        } else if (key === '16') {
            return <Menu.Item key="16" onClick={clearTemplete} disabled={!checkRolesState(RoleType.Problem, 'ViewProblem')}>
                <SidebarMenu title="Problem" collapsed={collapsed} path="/ProblemList" icon="security-scan" />
            </Menu.Item>
        } else if (key === 'sub3') {
            // return <Menu.Item key="3" onClick={clearTemplete} disabled={!checkRolesState(RoleType.Asset, 'ViewAsset')}>
            //     <SidebarMenu title="Asset Management" collapsed={collapsed} path="/assetList" icon="laptop" />
            // </Menu.Item>
            return <SubMenu
                key="sub3"
                title={
                    <div style={collapsed ? { display: 'flex', flexFlow: 'row-reverse', paddingLeft: 10 } : undefined}>
                        <Icon type="laptop" style={{ color: '#ffffff' }} />
                        <span style={{ color: '#041E42', fontWeight: 500 }}>Asset Maintenance</span>
                    </div>
                }
            >
                <Menu.Item onClick={clearTemplete} key="3" disabled={!checkRolesState(RoleType.Asset, 'ViewAsset')}><Link to={'/assetList'}>Asset List</Link></Menu.Item>
                <Menu.Item onClick={clearTemplete} key="12" disabled={!checkRolesState(RoleType.Asset, 'ViewAssetReport')}><Link to={'/reportAsset'}>Report</Link></Menu.Item>
            </SubMenu>
        } else if (key === 'sub1') {
            return <SubMenu
                key="sub1"
                title={
                    <div style={collapsed ? { display: 'flex', flexFlow: 'row-reverse', paddingLeft: 10 } : undefined}>
                        <Icon type="tool" style={{ color: '#ffffff' }} />
                        <span style={{ color: '#041E42', fontWeight: 500 }}>Preventive Maintenance</span>
                    </div>
                }
            >
                <Menu.Item onClick={clearTemplete} key="9" disabled={!checkRolesState(RoleType.PM, 'PMList')}><Link to={'/Preventive'}>PM List</Link></Menu.Item>
                <Menu.Item onClick={clearTemplete} key="10" disabled={!checkRolesState(RoleType.PM, 'CompletedPM')}><Link to={'/CompletePM'}>Complete PM</Link></Menu.Item>
                <Menu.Item onClick={clearTemplete} key="11" disabled={!checkRolesState(RoleType.PM, 'ReportPM')}><Link to={'/Report'}>Report</Link></Menu.Item>
            </SubMenu>
        } else if (key === '5') {
            return <SubMenu title={
                <div style={collapsed ? { display: 'flex', flexFlow: 'row-reverse', paddingLeft: 10 } : undefined}>
                    <Icon type="profile" style={{ color: '#ffffff' }} />
                    <span style={{ color: '#041E42', fontWeight: 500 }}>Work Order</span>
                </div>
            } >
                <Menu.Item onClick={clearTemplete} key="17" disabled={!checkRolesState(RoleType.WO, 'ViewWorkOrder')}><Link to={'/WoDashboard'}>Dashboard</Link></Menu.Item>
                <Menu.Item onClick={clearTemplete} key="18" disabled={!checkRolesState(RoleType.WO, 'ViewWorkOrder')}><Link to={'/WoList'}>Work Order</Link></Menu.Item>
                <Menu.Item onClick={clearTemplete} key="19" disabled={!checkRolesState(RoleType.WO, 'ViewReportWorkOrder')}><Link to={'/WoReport'}>Report</Link></Menu.Item>
            </SubMenu>
        } else if (key === '6') {
            // return <Menu.Item key="6" onClick={clearTemplete}>
            //     <SidebarMenu title="Knowledge Management" collapsed={collapsed} path="/Knowledge" icon="bulb" />
            // </Menu.Item>
            return <SubMenu
                        key="6"
                        title={
                            <div style={collapsed ? { display: 'flex', flexFlow: 'row-reverse', paddingLeft: 10 } : undefined}>
                                <Icon type="bulb" style={{ color: '#ffffff' }} />
                                <span style={{ color: '#041E42', fontWeight: 500 }}>Knowledge Management</span>
                            </div>
                        }
                    >
                        <Menu.Item onClick={clearTemplete} key="17" ><Link to={'/Knowledge-report'}>Dashboard</Link></Menu.Item>
                        <Menu.Item onClick={clearTemplete} key="6" ><Link to={'/Knowledge'}>Knowledge</Link></Menu.Item>
                    </SubMenu>
        } else if (key === '7') {
            return <Menu.Item key="7" onClick={clearTemplete} style={{ display: checkRoleAdminCenter() ? 'none': 'block' }}>
                <SidebarMenu title="Setting" collapsed={collapsed} path="/Setting" icon="setting" />
            </Menu.Item>
        } else if (key === '8') {
            return <Menu.Item key="8" onClick={clearTemplete} disabled={checkRoleApproverCenter()}>
                <SidebarMenu title="Approval Center" collapsed={collapsed} path="/ApprovalCenter"
                    icon="audit" />
            </Menu.Item>
        } else if (key === 'sub2') {
            // return <SubMenu
            //     key="sub2"
            //     title={
            //         <div style={collapsed ? { display: 'flex', flexFlow: 'row-reverse', paddingLeft: 10 } : undefined}>
            //             <Icon type="hdd" style={{ color: '#ffffff' }} />
            //             <span style={{ color: '#041E42', fontWeight: 500 }}>Service Catalog</span>
            //         </div>
            //     }
            // >
            //     <Menu.Item onClick={clearTemplete} key="12" disabled={!checkRolesState(RoleType.ServiceCatalog, 'ManageCatalogCategoryItemCatalog')}><Link to={'/CatalogList'}>Maintain Catalog</Link></Menu.Item>
            //     <Menu.Item onClick={clearTemplete} key="13" disabled={!checkRolesState(RoleType.ServiceCatalog, 'ManageWorkflow')}><Link to={'/WorkflowHome'}>Maintain Workflow</Link></Menu.Item>
            // </SubMenu>
            return <Menu.Item key="sub2" onClick={clearTemplete} disabled={!checkRolesState(RoleType.ServiceCatalog, 'ManageCatalogCategoryItemCatalog')}>
                <SidebarMenu title="Service Catalog" collapsed={collapsed} path="/CatalogList" icon="hdd" />
            </Menu.Item>
        } else if (key === '15') {
            return <Menu.Item key="15" onClick={clearTemplete} disabled={!checkRolesState(RoleType.Admin, 'ManageWorkflow')}>
                <SidebarMenu title="Maintain Workflow" collapsed={collapsed} path="/WorkflowHome" icon="apartment" />
            </Menu.Item>
        }
    }

    const checkRoleApproverCenter = () => {
        let check: boolean = true
        if (checkRolesState(RoleType.Knowledge, 'ApproveOrRejectKnowledge') || checkRolesState(RoleType.WO, 'ApproveOrRejectWorkOrder') || checkRolesState(RoleType.WO, 'ApproveOrRejectJob') || checkRolesState(RoleType.Problem, 'ApprovalProblem')) {
            check = false
        }
        return check
    }

    const checkRoleAdminCenter = () => {
        let check: boolean = true
        if (checkRolesState(RoleType.Admin, 'ManageTicketSetting') || checkRolesState(RoleType.Admin, 'ManageSLASetting') || checkRolesState(RoleType.Admin, 'ManageRoleAndPermission') || checkRolesState(RoleType.Admin, 'ManagePeopleUserGroup')
            || checkRolesState(RoleType.Admin, 'ManageGeneralSetting')|| checkRolesState(RoleType.Admin, 'ManageAssetSetting')) {
            check = false
        }
        return check
    }

    return (
        <>
            <Layout>
                {isRedirect ? (<Redirect to="/home" />) : null}
                <Sider
                    breakpoint="xs"
                    collapsedWidth={onBreakpoint ? '0' : '70'}
                    onBreakpoint={broken => {
                        // console.log(broken)
                        setOnBreakpoint(broken)
                    }}
                    onCollapse={(collapsed, type) => {
                        // console.log(collapsed, type)
                    }}
                    trigger={null} collapsible collapsed={collapsed}
                    width={onBreakpoint ? window.innerWidth : '220'}
                >

                    <div className="logo">
                        {collapsed
                            ? <img src={GoServiceMiniLogo} alt="logo" style={{ width: '75%', maxWidth: '50px', cursor: 'pointer' }} onClick={() => { setIsRedirect(true) }}/>
                            : <img src={GoServiceLogoFull} alt="logo" style={onBreakpoint ? { height: '100%', marginBottom: '2%', cursor: 'pointer' } : { width: '85%', cursor: 'pointer' }} onClick={() => { setIsRedirect(true) }}/>
                        }
                    </div>
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={[pin]} onClick={(e) => { setCollapsed(true) }}>
                        {/* checkServiceInclude(service, '1', '1') */}
                        {isMSPUser ? checkServiceInclude(service, ['1'], '1') : null}
                        {isMSPUser ? checkServiceInclude(service, ['1'], '2') : null}
                        {isMSPUser ? checkServiceInclude(service, ['8'], '16') : null}
                        {/* isVspaceUser */}
                        {isMSPUser ? checkServiceInclude(service, ['2'], 'sub3') : null}
                        {/* /!* isVspaceUser *!/ */}
                        {isMSPUser ? checkServiceInclude(service, ['3'], 'sub1') : null}
                        {/* /!* isVspaceUser *!/ */}
                        {isMSPUser ? checkServiceInclude(service, ['4'], '5') : null}
                        {isMSPUser ? checkServiceInclude(service, ['5'], '6') : null}
                        {isMSPUser ? sidebarMenuKey('7') : null}
                        {isMSPUser ? checkServiceInclude(service, ['4', '5', '7'], '8') : null}
                        {isMSPUser ? checkServiceInclude(service, ['7'], 'sub2') : null}
                        {isMSPUser ? checkServiceInclude(service, ['4', '7'], '15') : null}
                    </Menu>
                </Sider>
                <Layout id="main-content">
                    <Header style={{ background: '#fff', borderBottomStyle: 'solid', borderBottomColor: '#dddddd', padding: '0px 25px 0px 0px' }}>
                        <Row>
                            {onBreakpoint ? <Col span={4}>
                                <div className='miniLogoMobile'>
                                    <img src={GoServiceMiniLogo} alt="logo" style={{ width: '45px'}} />
                                </div>
                            </Col> : null}
                            <Col span={4} style={{ zIndex: 999, paddingLeft: onBreakpoint ? '10px' : '25px' }}>
                                <Icon
                                    className="trigger"
                                    type={'menu'}
                                    onClick={toggle}
                                />
                            </Col>
                            <Col span={onBreakpoint ? 16 : 20} style={{ display: 'flex', justifyContent: 'flex-end', zIndex: 888 }}>
                                <UserDetails broken={onBreakpoint} />
                            </Col>
                        </Row>
                    </Header>
                    <Content
                        style={{
                            padding: 24,
                            background: '#fff',
                            minHeight: 'auto'
                        }}
                    >
                        <BubbleChat />
                        {/* {isOpenThisTab && getCookie('access_token') ? props.children : <Spin spinning={true} />} */}
                        {props.children}
                    </Content>
                    <Footer />
                </Layout>
            </Layout>
        </>
    )
}
const SidebarMenu = (props: SidebarMenuProps) => {
    return <Link to={props.path}>
        {props.collapsed ? (
            <div style={{ display: 'flex', flexFlow: 'row-reverse', paddingLeft: 10 }}>
                <Icon type={props.icon} style={{ color: '#ffffff' }} />
                <span style={{ color: '#041E42', fontWeight: 500 }}>{props.title}</span>
            </div>) : (<div>
            <Icon type={props.icon} style={{ color: '#ffffff' }} />
            <span style={{ color: '#ffffff', fontWeight: 500 }}>{props.title}</span>
        </div>)
        }
    </Link>
}

interface SidebarMenuProps {
    title: string
    path: string
    collapsed: boolean
    icon: string
}

export default connect(mapStateToProps, {
    loadRolesState,
    getAllSupportTeam
})(Sidebar)
