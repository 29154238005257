/* eslint-disable camelcase */
/* eslint-disable no-useless-escape */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import {
    Button,
    Card,
    Dropdown,
    Form,
    Icon,
    Input,
    InputNumber,
    Menu,
    message,
    Modal,
    Select,
    Spin,
    Switch,
    Table,
    Tooltip
} from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { FormComponentProps } from 'antd/es/form'
import { AssetGroups, CustomFiledAsset } from './model'
// import { DndProvider } from 'react-dnd'
// import { DragableBodyRow, RNDContext } from './DragableBodyRow'
// import update from 'immutability-helper'
import { addOrUpdateCustomFieldAsset, getAllAssetGroup, getAllCustomFieldAsset } from '../msp-wo/asset-management'
import { StoreState } from '../store'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

const mapStateToProps = (state: StoreState) => {
    return {
        customFieldAsset: state.customFiledAsset
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllCustomFieldAsset: (selectTicketType: string) => Promise<number>
    addOrUpdateCustomFieldAsset: (selectTicketType: string, value: CustomFiledAsset[]) => Promise<number>
}

type Props = FormComponentProps & StateProps & DispatchProps
let arr: CustomFiledAsset[] = []
let listsEdit: number[] = []
const { Option } = Select
const { confirm } = Modal
const CardFiled: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue, resetFields, getFieldValue } = props.form
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState(Object), [])
    const [data, setData] = useState<CustomFiledAsset[]>([])
    const [isSpinning, setIsSpinning] = useState(false)
    const [checkIsUpdate, setCheckIsUpdate] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isRedirect, setIsRedirect] = useState<boolean>(false)
    const [selectTicketType, setSelectTicketType] = useState<string>()
    const [nameTicketType, setNameTicketType] = useState<string>('')
    const [selectHeaderGroup] = useState<string>('AssetDetails')
    const [tempListLabel, setTempListLabel] = useState<string[]>([])
    const [duplicatesLabel, setDuplicatesLabel] = useState<string[]>([])
    const [isMoveRow, setIsMoveRow] = useState<boolean>(false)
    let isSubmit = false
    const [assetType, setAssetType] = useState<AssetGroups[]>([])
    // const headerITAssetGroup = [
    //     'AssetDetails'
    //     // 'LocationDetails',
    //     // 'UserDetails',
    //     // 'DateDetails'
    // ]

    // const headerITAssetGroup2 = [
    //     'AssetDetails'
    //     // 'LocationDetails',
    //     // 'UserDetails',
    //     // 'SETPeripheralDetails',
    //     // 'DateDetails'
    // ]

    useEffect(() => {
        if (isMoveRow) {
            const listLabel2 = data.map((obj) => {
                return obj.label?.toLocaleLowerCase() || ''
            })

            if (findDuplicates(listLabel2)) {
                setDuplicatesLabel(findDuplicates(listLabel2))
            } else {
                setDuplicatesLabel([])
            }
            setIsMoveRow(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMoveRow])

    useEffect(() => {
        getAllAssetGroup().then((res) => {
            if (res) {
                setAssetType(res)
                setSelectTicketType(res[0].id?.toString())
                initGeneralIncidentSetting(res[0].id?.toString())
                setNameTicketType(res[0].name)
            }
        })
        // initGeneralIncidentSettingPeri(selectTicketType, selectHeaderGroup)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const findData = assetType.find((it) => it.id?.toString() === selectTicketType)
        setNameTicketType(findData?.name || '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectTicketType])

    useEffect(() => {
        if (props.customFieldAsset) {
            console.log(props.customFieldAsset)
            const fetchCustomFieldData = props.customFieldAsset.map(it => {
                it.createdDate = undefined
                it.createdBy = undefined
                it.lastModifiedBy = undefined
                it.lastModifiedDate = undefined
                it.key = uuid().slice(0, 8)
                return it
            })
            setData(fetchCustomFieldData)
            setIsSpinning(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.customFieldAsset])

    const handleSubmit = (e) => {
        e.preventDefault()
        isSubmit = true
        props.form.validateFieldsAndScroll((err) => {
            if (!err) {
                setIsLoading(true)
                let isValidateOrterPages = false
                const convertData = data.map((it, i) => {
                    const isEdit = [...new Set(listsEdit)].find(list => list === it.id)
                    // @ts-ignore
                    it.name = it.label ? selectHeaderGroup + '_' + it.label!!.replaceAll(' ', '_').toLowerCase() : undefined
                    it.label = it.label ? it.label.replace(/ +/g, ' ').trim() : undefined
                    if (isEdit !== undefined) {
                        it.id = undefined
                    }
                    if (!it.label || !it.inputType || !it.dataType) {
                        isValidateOrterPages = true
                    }
                    if (!it.selectOption) {
                        it.selectOption = []
                    }
                    // delete it.key
                    return { ...it, customFieldOrder: i, active: true, assetGroupId: selectTicketType || '1' }
                })
                console.log(convertData)

                let isError = false

                const uniqueValues = [...new Set(data.map(v => v.label?.toLocaleLowerCase()?.trim()))]
                if (uniqueValues && uniqueValues.length !== data.length) {
                    isError = true
                }

                if (!isError || !isValidateOrterPages) {
                    props.addOrUpdateCustomFieldAsset(selectTicketType || '1', convertData).then((res) => {
                        if (res) {
                            message.success('The Update has finished successfully')
                            setIsLoading(false)
                            setIsRedirect(true)
                        }
                    }).catch((_err) => {
                        message.error(_err?.response?.data?.message || 'Please complete all custom fields.')
                        setIsLoading(false)
                    }).finally(() => {
                        isSubmit = false
                    })
                } else {
                    message.error('Please complete all custom fields.')
                    setIsLoading(false)
                }
            } else {
                console.log('err')
                isSubmit = false
            }
        })
    }

    const initGeneralIncidentSetting = (selectTicketType: string) => {
        setIsSpinning(true)
        props.getAllCustomFieldAsset(selectTicketType).then(() => {
            setCheckIsUpdate(false)
            setIsSpinning(false)
        }).catch((_err) => {
            setCheckIsUpdate(true)
            setData([])
            setIsSpinning(false)
        })
    }

    const AddData = () => {
        arr = []
        const listLabel = data?.map((obj) => {
            return obj.label || ''
        })
        setTempListLabel([...new Set(listLabel)])
        const small_id = uuid().slice(0, 8)
        const initData = {
            id: data?.length || 0,
            dataType: undefined,
            defaultValue: undefined,
            description: undefined,
            inputType: undefined,
            isRequireFields: false,
            label: undefined,
            name: undefined,
            placeholder: undefined,
            selectOption: undefined,
            isShowPm: true,
            createdDatetime: undefined,
            key: small_id
        } as unknown as CustomFiledAsset
        arr.push(initData)
        setData((data || []).concat(arr))
        forceUpdate()
    }

    const validateLabel = (_: any, value: any, callback) => {
        if (value) {
            // eslint-disable-next-line
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            // const pattern = /[A-Za-z0-9ก-๙]+/
            // const checkLower = tempListLabel.map((obj) => {
            //     return obj.toLowerCase() === value.toLowerCase()
            // })
            // const checkArray = checkLower.find((label) => {
            //     return label === true
            // })

            if (format.test(value)) {
                // eslint-disable-next-line
                // callback('Field Label can' + 't contain any of the following characters ' + format)
                callback()
            } else {
                // if ((tempListLabel.includes(value) && !isSubmit) || (checkArray && !isSubmit)) {
                //     callback('This entry already exists in the system.')
                // } else {
                //     callback()
                // }
                callback()
            }
        } else {
            callback()
        }
    }

    const handleBlurLabel = (value: string) => {
        tempListLabel.push(value)
        // if (duplicatesLabel.length !== 0) {
        const listLabel2 = data.map((obj) => {
            return obj.label?.toLocaleLowerCase()?.trim() || ''
        })

        if (findDuplicates(listLabel2)) {
            setDuplicatesLabel(findDuplicates(listLabel2))
        } else {
            setDuplicatesLabel([])
        }
        // }
    }

    const validateMaxTag = (_: any, value: any, callback) => {
        if (value) {
            const valueIncorrect = (value).filter((it) => it.length >= 256)
            if (valueIncorrect.length !== 0) {
                callback('error')
            } else {
                callback()
            }
        } else {
            callback()
        }
    }
    const getDefaultValue = (row: any, index: number) => {
        if (getFieldValue('inputType' + row.id + index) === 'Checkbox' || getFieldValue('inputType' + row.id + index) === 'DatePicker' || getFieldValue('inputType' + row.id + index) === 'RadioButton') {
            return undefined
        } else {
            if (getFieldValue('dataType' + row.id + index)) {
                return (getFieldValue('dataType' + row.id + index) as string).toLowerCase()
            } else {
                return undefined
            }
        }
    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row, myData, index) => (
                <>
                    <Tooltip placement="bottom" title={'Add'}><Icon className="del_icon" type="plus-square"
                        onClick={() => addAfterRow(row.id, data?.findIndex(it => { return it.key?.toString() === myData.key?.toString() }))} /></Tooltip>
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row, data, index) => (
                <>
                    <Tooltip placement="bottom" title={'Delete'}><Icon className="edit_icon" type="minus-square"
                        onClick={() => deleteRow(index, row.label, row)} /></Tooltip>
                </>
            )
        },
        // {
        //     title: <span><span className={'requiredIcon'}>* </span>Field name</span>,
        //     dataIndex: '',
        //     key: '',
        //     render: (row, data, index) => (
        //         <>
        //             <Form.Item>
        //                 {getFieldDecorator('name' + row.name + index, {
        //                     initialValue: row.name,
        //                     rules: [{ required: true, message: 'Field Name is required.' }]
        //                 })(
        //                     <Input onChange={(e) => handleChangeName(row, e.target.value)} />
        //                 )}
        //             </Form.Item>
        //         </>
        //     )
        // },
        {
            title: <span><span className={'requiredIcon'}>* </span>Field label</span>,
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item
                        validateStatus={row.label === '' ? 'error' : duplicatesLabel.includes(row.label?.toLocaleLowerCase()) ? 'error' : undefined}
                        help={row.label === '' ? 'Default value is not valid.' : duplicatesLabel.includes(row.label?.toLocaleLowerCase()) ? 'This entry already exists in the system.' : undefined}
                    >
                        {getFieldDecorator('label' + row.id + index + row.key, {
                            initialValue: row.label,
                            rules: [
                                {
                                    required: true, message: 'Field Label is required.'
                                },
                                {
                                    validator: !isSubmit ? validateLabel : undefined
                                }
                            ]
                        })(
                            <Input maxLength={255} onChange={(e) => handleChangeLabel(row, e.target.value)} onBlur={(e) => handleBlurLabel(e.target.value)} disabled={row.createdDatetime !== undefined}/>
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Field description',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('description' + row.id + index, {
                            initialValue: row.description,
                            rules: [{ required: false }]
                        })(
                            <Input maxLength={255} onChange={(e) => handleChangeDescription(row, e.target.value)} />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: <span><span className={'requiredIcon'}>* </span>Input type</span>,
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('inputType' + row.id + index, {
                            initialValue: row.inputType,
                            rules: [{ required: true, message: 'Input Type is required.' }]
                        })(
                            <Select style={{ width: 135 }} onChange={(e) => handleChangeInputType(row, e, index)} allowClear disabled={row.createdDatetime !== undefined}>
                                <Option value="Checkbox">Checkbox</Option>
                                <Option value="DatePicker">Date picker</Option>
                                <Option value="DropdownSelect">Dropdown Select</Option>
                                <Option value="InputNumber">Input number</Option>
                                <Option value="RadioButton">Radio button</Option>
                                <Option value="TextBox">Text box</Option>
                            </Select>
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: <span><span className={'requiredIcon'}>* </span>Data type</span>,
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('dataType' + row.id + index, {
                            initialValue: row.dataType,
                            rules: [{ required: true, message: 'Data type is required' }]
                        })(
                            <Select style={{ width: 135 }} onChange={(e) => handleChangeDataType(row, e, index)} allowClear disabled={row.createdDatetime !== undefined}>
                                {getFieldValue('inputType' + row.id + index) === 'DropdownSelect' || getFieldValue('inputType' + row.id + index) === 'RadioButton' || getFieldValue('inputType' + row.id + index) === 'TextBox' || getFieldValue('inputType' + row.id + index) === 'Checkbox'
                                    ? <Option value="String" key='1'>String</Option>
                                    : null}
                                {getFieldValue('inputType' + row.id + index) === 'InputNumber'
                                    ? <Option value="Number" key='2'>Number</Option>
                                    : null}
                                {getFieldValue('inputType' + row.id + index) === 'TextBox'
                                    ? <Option value="Email" key='3'>Email</Option> : null}
                                {/* {getFieldValue('inputType' + row.id) === 'Checkbox' ? <Option value="Boolean" key='4'>Boolean</Option> : null} */}
                                {getFieldValue('inputType' + row.id + index) === 'DatePicker'
                                    ? <Option value="Date" key='5'>Date</Option> : null}
                            </Select>
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Select option',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <div key={index}>
                    <Form.Item>
                        {getFieldDecorator('selectOption' + row.id + index, {
                            initialValue: row.selectOption ? (row.selectOption || []).map((it) => { return it.option }) : undefined,
                            rules: [{
                                required: (getFieldValue('inputType' + row.id + index) === 'DropdownSelect' || getFieldValue('inputType' + row.id + index) === 'Checkbox' || getFieldValue('inputType' + row.id + index) === 'RadioButton'),
                                message: 'Select an option is required.'
                            }, {
                                validator: validateMaxTag
                            }]
                        })(
                            <Select
                                maxTagCount={20} maxTagTextLength={20} maxTagPlaceholder={20}
                                disabled={!(getFieldValue('inputType' + row.id + index) === 'DropdownSelect' || getFieldValue('inputType' + row.id + index) === 'Checkbox' || getFieldValue('inputType' + row.id + index) === 'RadioButton')}
                                mode="tags" style={{ width: 135 }} onChange={(e => handleChangeSelectOption(row, e))} />
                        )}
                    </Form.Item>
                </div>
            )
        },
        {
            title: 'Default value',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('defaultValue' + row.id + index, {
                            initialValue: getFieldValue('dataType' + row.id + index) === 'Number' ? Number(row.defaultValue) : row.defaultValue,
                            rules: [
                                {
                                    type: getDefaultValue(row, index),
                                    message: 'Default value is not valid.'
                                }, {
                                    required: false
                                }
                            ]
                        })(
                            getFieldValue('dataType' + row.id + index) === 'Number'
                                ? <InputNumber min={0} onChange={(e) => handleChangeDefaultValue(row, e!!)}
                                    style={{ width: '100%' }} defaultValue={0} maxLength={255}
                                    disabled={getFieldValue('inputType' + row.id + index) === 'Checkbox' || getFieldValue('inputType' + row.id + index) === 'DatePicker' || getFieldValue('inputType' + row.id + index) === 'RadioButton'} />
                                : getFieldValue('inputType' + row.id + index) === 'DropdownSelect' ? <Select
                                    disabled={getFieldValue('inputType' + row.id + index) === 'DatePicker'}
                                    onChange={(e) => handleChangeDefaultValue(row, e?.toString())}
                                    allowClear={true}
                                >
                                    {(getFieldValue('selectOption' + row.id + index) || []).map((data, index) => {
                                        return <Option value={data} key={index}>{data}</Option>
                                    })}
                                </Select> : <Input onChange={(e) => handleChangeDefaultValue(row, e.target.value)} maxLength={255}
                                    disabled={getFieldValue('inputType' + row.id + index) === 'Checkbox' || getFieldValue('inputType' + row.id + index) === 'DatePicker' || getFieldValue('inputType' + row.id + index) === 'RadioButton'}
                                />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Placeholder',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('placeholder' + row.id + index, {
                            initialValue: row.placeholder,
                            rules: [{ required: false }]
                        })(
                            <Input maxLength={255} onChange={(e) => handleChangePlaceholder(row, e.target.value)}
                                disabled={getFieldValue('inputType' + row.id + index) === 'Checkbox' || getFieldValue('inputType' + row.id + index) === 'RadioButton'}
                            />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Require field',
            dataIndex: '',
            key: '',
            render: (row: CustomFiledAsset) => (
                <>
                    <Tooltip placement="bottom" title={row.isRequireFields ? 'Deactivate' : 'Activate'}>
                        <Switch checked={row.isRequireFields} onChange={() => toggleActiveness(row)} />
                    </Tooltip>
                </>
            )
        },
        {
            title: 'Show PM',
            dataIndex: '',
            key: '',
            render: (row: CustomFiledAsset) => (
                <>
                    <Tooltip placement="bottom" title={row.isShowPm ? 'Deactivate' : 'Activate'}>
                        <Switch checked={row.isShowPm} onChange={() => toggleIsShowPm(row)} />
                    </Tooltip>
                </>
            )
        }
    ]

    const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)

    const addAfterRow = (currentRow, index) => {
        arr = []
        data.forEach((obj) => {
            if (Number(obj.id) >= currentRow + 1) {
                obj.id = Number(obj.id)
            }
        })
        const listLabel = data.map((obj) => {
            return obj.label || ''
        })
        const listLabel2 = data.map((obj) => {
            return obj.label?.toLocaleLowerCase() || ''
        })

        if (findDuplicates(listLabel2)) {
            setDuplicatesLabel(findDuplicates(listLabel2))
        }
        setTempListLabel([...new Set(listLabel)])
        const small_id = uuid().slice(0, 8)
        data.splice(index + 1, 0, {
            id: undefined,
            dataType: undefined,
            defaultValue: undefined,
            description: undefined,
            inputType: undefined,
            isRequireFields: false,
            label: undefined,
            name: undefined,
            placeholder: undefined,
            selectOption: [],
            createdDatetime: undefined,
            isShowPm: true,
            assetGroupId: selectTicketType || '1',
            key: small_id
        })
        resetFields()
        setData((data || []).concat(arr))
        // setData(data)
        forceUpdate()
    }

    const deleteRow = (index, value, myData) => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete this data?',
            okText: 'OK',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'Cancel',
            async onOk() {
                const tempData2 = data.filter((_obj) => _obj?.key !== myData?.key)
                if (duplicatesLabel) {
                    const filterDuplicatesLabel = duplicatesLabel.filter((it) => it?.toLowerCase().trim() !== value?.toLowerCase().trim())
                    setDuplicatesLabel(filterDuplicatesLabel)
                }
                setData(tempData2)
                message.success('The data has been deleted successfully.')
            },
            onCancel() {
            }
        })
    }

    const toggleActiveness = (data: CustomFiledAsset) => {
        data.isRequireFields = !data.isRequireFields
        setCheckIsUpdate(!checkIsUpdate)
        forceUpdate()
    }

    const toggleIsShowPm = (data: CustomFiledAsset) => {
        data.isShowPm = !data.isShowPm
        setCheckIsUpdate(!checkIsUpdate)
        forceUpdate()
    }

    const handleChangeLabel = (row: CustomFiledAsset, value: string) => {
        row.label = value
        row.name = value
        if (row.id !== undefined) {
            listsEdit.push(row.id)
        }
    }

    const handleChangeDescription = (row: CustomFiledAsset, value: string) => {
        row.description = value
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const handleChangeInputType = (row: CustomFiledAsset, value, index: number) => {
        row.inputType = value
        const setValue = {}
        setValue['selectOption' + row.id + index] = undefined
        setValue['defaultValue' + row.id + index] = undefined
        setValue['placeholder' + row.id + index] = undefined
        row.defaultValue = ''
        row.selectOption = []
        row.placeholder = ''
        if (value === 'Checkbox' || value === 'DropdownSelect' || value === 'RadioButton') {
            setValue['dataType' + row.id + index] = 'String'
            row.dataType = 'String'
            setFieldsValue(setValue)
        } else if (value === 'DatePicker') {
            setValue['dataType' + row.id + index] = 'Date'
            row.dataType = 'Date'
            setFieldsValue(setValue)
        } else if (value === 'InputNumber') {
            setValue['dataType' + row.id + index] = 'Number'
            row.dataType = 'Number'
            setValue['defaultValue' + row.id + index] = 0
            row.defaultValue = 0
            setFieldsValue(setValue)
        } else {
            setValue['dataType' + row.id + index] = undefined
            row.dataType = undefined
            setFieldsValue(setValue)
        }
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const handleChangeDataType = (row: CustomFiledAsset, value, index: number) => {
        const setValue = {}
        row.dataType = value
        row.defaultValue = ''
        row.selectOption = []
        row.placeholder = ''
        setValue['selectOption' + row.id + index] = undefined
        setValue['defaultValue' + row.id + index] = undefined
        setValue['placeholder' + row.id + index] = undefined
        setFieldsValue(setValue)
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const handleChangeSelectOption = (row: CustomFiledAsset, value) => {
        const selectedValue = (value as Array<string>)
        const pattern = /[A-Za-z0-9ก-๙]+/
        let isErrorPattern = false
        const arr_lower = selectedValue.map(item => item?.toLowerCase().trim())
        const lastValue = selectedValue[selectedValue.length - 1]?.toLowerCase().trim()
        arr_lower.pop()
        if (arr_lower.includes(lastValue)) {
            selectedValue.pop()
        }
        selectedValue.forEach((name) => {
            if (!pattern.test(name.replace(/ +/g, ' ').trim())) {
                selectedValue.pop()
                isErrorPattern = true
            }
        })
        if (isErrorPattern) {
            return
        }
        if (!isErrorPattern) {
            row.selectOption = value.map((it) => {
                return {
                    option: it,
                    moduleName: 'Asset'
                }
            })
            if (row.id) {
                listsEdit.push(row.id)
            }
        }
    }

    const handleChangeDefaultValue = (row: CustomFiledAsset, value: string | number) => {
        row.defaultValue = value
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const handleChangePlaceholder = (row: CustomFiledAsset, value: string) => {
        row.placeholder = value
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    // const components = {
    //     body: {
    //         row: DragableBodyRow
    //     }
    // }

    // const moveRow = useCallback(
    //     (dragIndex, hoverIndex) => {
    //         const dragRow = data[dragIndex]
    //         setData(
    //             update(data, {
    //                 $splice: [
    //                     [dragIndex, 1],
    //                     [hoverIndex, 0, dragRow]
    //                 ]
    //             })
    //         )
    //         setIsMoveRow(true)
    //     },
    //     [data]
    // )

    // const manager = useRef(RNDContext)
    console.log(data)
    const renderTable = () => {
        return (
            // <DndProvider manager={manager.current.dragDropManager!!}>
            <Spin spinning={isSpinning}>
                <Table
                    columns={columns}
                    dataSource={data}
                    // components={components}
                    // rowKey='id'
                    // onRow={(record, index) => ({
                    //     index,
                    //     moveRow
                    // })}
                    scroll={{ x: 768 }}
                    // className='Table-CustomField'
                />
            </Spin>
            // </DndProvider>
        )
    }

    const goBack = () => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you want to leave this page ?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                listsEdit = []
                setIsRedirect(true)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const menu = () => {
        return (
            <Menu>
                {assetType.map((it, index) => {
                    return (<Menu.Item key={index} onClick={() => setTicketTypes(it.id?.toString() || '1')}>
                        <span>{it.name}</span>
                    </Menu.Item>)
                })}
            </Menu>
        )
    }
    // const AssetGroup = () => {
    //     return (
    //         <div style={{ marginBottom: 10 }}>
    //             <span style={{ color: 'red' }}>* <span style={{ color: '#323276' }}>Header Group : </span></span>
    //             <span style={{ color: '#323276', fontWeight: 'bold' }}>
    //                 {selectHeaderGroup}
    //             </span>
    //         </div>
    //     )
    // }

    // const AssetGroupPeripheral = () => {
    //     return (
    //         <div style={{ marginBottom: 10 }}>
    //             <span style={{ color: 'red' }}>* <span style={{ color: '#323276' }}>Header Group : </span></span>
    //             <span style={{ color: '#323276', fontWeight: 'bold' }}>
    //                 {selectHeaderGroup}
    //             </span>
    //         </div>
    //     )
    // }

    const setTicketTypes = (text: string) => {
        setSelectTicketType(text)
        initGeneralIncidentSetting(text)
    }

    // const menuHeader = () => {
    //     return (
    //         <Menu>
    //             {headerITAssetGroup.map((it, index) => {
    //                 return (<Menu.Item key={index} onClick={() => setTicketTypesHerder(it)}>
    //                     <span>{it}</span>
    //                 </Menu.Item>)
    //             })}
    //         </Menu>
    //     )
    // }

    // const menuHeade2 = () => {
    //     return (
    //         <Menu>
    //             {headerITAssetGroup2.map((it, index) => {
    //                 return (<Menu.Item key={index} onClick={() => setTicketTypesHerder(it)}>
    //                     <span>{it}</span>
    //                 </Menu.Item>)
    //             })}
    //         </Menu>
    //     )
    // }

    // const setTicketTypesHerder = (text: string) => {
    //     setSelectHeaderGroup(text)
    //     initGeneralIncidentSetting(selectTicketType || '1')
    // }

    return (
        <>
            {isRedirect ? <Redirect to='Setting' /> : null}
            <Card style={{ overflow: 'auto' }}>
                <h3 className={'main-title text-herder-Orenge'}>Custom Field Setting</h3>
                <div style={{ marginBottom: 10 }}>
                    <span style={{ color: 'red' }}>* <span style={{ color: '#323276' }}>Group : </span></span>
                    <Dropdown overlay={() => menu()} trigger={['click']}>
                        <span style={{ color: '#323276', fontWeight: 'bold' }}>
                            {nameTicketType} <Icon type="down" />
                        </span>
                    </Dropdown>
                </div>
                {/* {nameTicketType === 'IT Asset' ? AssetGroup() : AssetGroupPeripheral()} */}
                {/* <div style={{ marginBottom: 10 }}> */}
                {/*    <span style={{ color: 'red' }}>* <span style={{ color: '#323276' }}>Header Group : </span></span> */}
                {/*    <Dropdown overlay={() => menuHeader()} trigger={['click']}> */}
                {/*        <span style={{ color: '#323276', fontWeight: 'bold' }}> */}
                {/*            {selectHeaderGroup} <Icon type="down" /> */}
                {/*        </span> */}
                {/*    </Dropdown> */}
                {/* </div> */}
                {/* {test2()} */}
                <Button onClick={() => AddData()} htmlType="button" className="addCriteriaButton">Add</Button>
                <br /><br />
                <Form onSubmit={handleSubmit}>
                    {renderTable()}
                    <Form.Item style={{ float: 'right', marginTop: 10 }}>
                        <Button htmlType="button"
                            onClick={() => goBack()}
                            style={{ marginRight: 10 }}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    )
}

const CardFields = Form.create<Props>()(CardFiled)
export default connect(mapStateToProps, {
    getAllCustomFieldAsset, addOrUpdateCustomFieldAsset
})(CardFields)
