import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { StoreState } from '../../store'
import { Breadcrumb, Button, Card, Col, Icon, message, Modal, Row, Switch, Table, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { BusinessHour } from './model'
import { deleteBusinessHour, getAllBusinessHourWithPagination, updateBusinessHour } from './service'
import { FetchingState, fetchWithState, SpinningFetcher } from '../../common-components'
import { useLastLocation } from 'react-router-last-location'
import { UpdateBusinessHourTableCurrentPageEvent, UpdateBusinessHourTableTotalDataEvent } from '../../store/state-event'

const mapStateToProps = (state: StoreState) => {
    return {
        businessHours: state.businessHours,
        tablePagination: state.tablePagination,
        businessHourPagination: state.businessHourPagination
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllBusinessHourWithPagination: (page: number, pageSize: number) => Promise<number>
    updateBusinessHour: (businessHour: BusinessHour) => Promise<number>;
    deleteBusinessHour: (id: string) => Promise<number>;
}

type Props = StateProps & DispatchProps

const BusinessHourList: React.FC<Props> = (props: Props) => {
    const [fetchingState, setFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const { confirm } = Modal
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState<number>(1)
    const lastLocation = useLastLocation()
    const pageSize = 10

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row: any) => (
                <>
                    <Tooltip placement="bottom" title={'Delete'} ><Icon className="del_icon" type="delete" onClick={() => deleteBizH(row.id, row.activate)} /></Tooltip>
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row: any) => (
                <>
                    <Tooltip placement="bottom" title={'Edit'}><Link to={`/BusinessHourSettingFormEdit/${row.id}`} ><Icon className="edit_icon" type="edit" /></Link></Tooltip>
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row: any) => (
                <>
                    <Tooltip placement="bottom" title={'Clone'}><Link to={`/BusinessHourSettingFormClone/${row.id}`} ><Icon className="clone_icon" type="copy" /></Link></Tooltip>
                </>
            )
        },
        {
            title: 'Name',
            dataIndex: '',
            key: '',
            width: '30%',
            // eslint-disable-next-line react/display-name
            render: (businessHour: BusinessHour) => (
                <>
                    <Tooltip placement="bottom" title={businessHour.name.length > 25 ? businessHour.name : null}>
                        <Link to={`/BusinessHourSettingFormView/${businessHour.id}`}>{businessHour.name.length > 25 ? (<>{businessHour.name.substring(0, 25) + ' ...'}</>) : (<>{businessHour.name}</>)}</Link>
                    </Tooltip>
                </>
            )
        },
        {
            title: 'Description',
            dataIndex: '',
            key: '50',
            width: '60%',
            ellipsis: true,
            // eslint-disable-next-line react/display-name
            render: (businessHour: BusinessHour) => (
                <>
                    <span style={{ fontSize: 13, color: '#323276' }}>{businessHour.description}</span>
                    <span style={{ display: 'flex', float: 'right' }}>
                        <Tooltip placement="bottom" title={businessHour.activate ? 'Deactivate' : 'Activate'}>
                            <Switch checked={businessHour.activate} onChange={() => toggleActiveness(businessHour)} />
                        </Tooltip>
                    </span>
                    <br />
                </>
            )
        }
    ]

    useEffect(() => {
        fetchWithState(fetchingState, setFetchingState, (): Promise<number> => {
            return props.getAllBusinessHourWithPagination(1, pageSize)
        })
        initialCurrentPage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.businessHours) {
            if (props.tablePagination.businessHourTableTotalData) {
                if (props.businessHours.length > props.tablePagination.businessHourTableTotalData) {
                    const c = 1
                    setCurrentPage(c)
                    dispatch(UpdateBusinessHourTableCurrentPageEvent.build(c))
                }
                dispatch(UpdateBusinessHourTableTotalDataEvent.build(props.businessHours.length))
            } else {
                dispatch(UpdateBusinessHourTableTotalDataEvent.build(props.businessHours.length))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.businessHours])

    useEffect(() => {
        fetch(currentPage)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])

    const fetch = (page: number) => {
        fetchWithState(FetchingState.NotStarted, setFetchingState,
            () => props.getAllBusinessHourWithPagination(page, pageSize)
                .catch(() => message.error('Data not found.')))
    }

    const handleOnClickPageNumber = (page: number) => {
        dispatch(UpdateBusinessHourTableCurrentPageEvent.build(page))
        setCurrentPage(page)
    }

    const initialCurrentPage = () => {
        const isPrevFromAllPageOfBizHour = lastLocation?.pathname.search('/BusinessHour')!! >= 0
        if (props.tablePagination.businessHourTableCurrentPage && (isPrevFromAllPageOfBizHour || isPrevFromAllPageOfBizHour === undefined)) {
            setCurrentPage(props.tablePagination.businessHourTableCurrentPage)
        }
    }

    const deleteBizH = (id, activate): void => {
        console.log(id)
        try {
            confirm({
                title: 'Are you sure?',
                content: 'Do you confirm Delete this data?',
                okText: 'OK',
                okType: 'danger',
                autoFocusButton: 'cancel',
                cancelText: 'Cancel',
                async onOk() {
                    if (activate) {
                        message.warning('Please deactivate this data before you continue.')
                    } else {
                        props.deleteBusinessHour(id).then(() => {
                            message.success('The data has been deleted successfully.')
                        }).catch(() => {
                            message.warning('This Business Hours is currently being used by SLA Policies.')
                        })
                    }
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        } catch (error: any) {
            console.log(error)
        }
    }

    const toggleActiveness = async (businessHour: BusinessHour) => {
        const proceed = () => {
            props.updateBusinessHour({ id: businessHour.id!!, originId: businessHour.originId, activate: !businessHour.activate } as BusinessHour)
                .catch((err) => {
                    if (err?.response?.status === 409) {
                        message.warning('This Business Hours is currently being used by SLA Policies.')
                    } else {
                        message.error(`Unable to toggle the  businesshour  activeness. Please try again later. ${err}`)
                    }
                })
        }

        if (!businessHour.activate) {
            proceed()
        } else {
            try {
                confirm({
                    title: 'Are you sure?',
                    content: 'Do you confirm deactivating this data ?',
                    okText: 'OK',
                    okType: 'danger',
                    autoFocusButton: 'cancel',
                    cancelText: 'Cancel',
                    async onOk() {
                        proceed()
                    },
                    onCancel() { }
                })
            } catch (error: any) {
                console.log(error)
            }
        }
    }

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Business Hours</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Row>
                <Col span={24} >
                    <Card style={{ overflow: 'auto' }}>
                        <Row>
                            <Col span={12} lg={12} md={12} sm={12} xs={12}>
                                <h3 className={'main-title'}>Business Hours</h3>
                            </Col>
                            <Col span={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'end' }}>
                                <Link to="/BusinessHourSettingForm" ><Button type="primary">Create</Button></Link>
                            </Col>
                        </Row>
                        <br />
                        <div>
                            <SpinningFetcher fetchingState={fetchingState}>
                                <Table
                                    // rowSelection={rowSelection}
                                    dataSource={props.businessHours || []}
                                    columns={columns}
                                    rowKey="id"
                                    pagination={{
                                        pageSize: pageSize,
                                        current: currentPage,
                                        total: props.businessHourPagination.total,
                                        onChange: (event) => { handleOnClickPageNumber(event) }
                                    }}
                                    scroll={{ x: 768 }}
                                />
                            </SpinningFetcher>
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default connect(
    mapStateToProps,
    {
        getAllBusinessHourWithPagination, updateBusinessHour, deleteBusinessHour
    }
)(BusinessHourList)
