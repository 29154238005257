import React, { useState } from 'react'
import { FormComponentProps } from 'antd/es/form'
import moment from 'moment'
import { Button, Col, DatePicker, Icon, message, Row, Select, Spin } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { RoleType, checkRolesState } from '../../authorization-module/permissions'
import { ColumnNormal } from '../../ReportComponent/components/Model'
import ColumnAllTicket from './ColumnAllTicket'
import { convertCamelCaseToSentenceCase } from '../../knowledge-management/util'
import ColumnTicketSLA from './ColumnTicketSLA'
import ColumnTicketRelateTicket from './ColumnTicketRelateTicket'
import ColumnTicketRelateAsset from './ColumnTicketRelateAsset'
import ColumnTicketRelateKM from './ColumnTicketRelateKM'
import ColumnTicketRelateWF from './ColumnTicketRelateWF'
import PageIncidentService from './PageIncidentService'
import { getExportTicketReport, getPreviewTicketReport } from './service'

interface Param {
    name: string
    form: WrappedFormUtils<any>
}

type Props = Param & FormComponentProps
const { RangePicker } = DatePicker
const { Option } = Select
const dateFormat = ('YYYY-MM-DD')
const CommonPanalReport: React.FC<Props> = (props: Props) => {
    const [range, setRange] = useState<any[]>()
    const [columnsNewObjList, setColumnsNewObjList] = useState<ColumnNormal[]>([])
    const [columnsNewList, setColumnsNewList] = useState<string[]>([])
    const [, setisFetchingFirst] = useState<boolean>(false)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingExport, setLoadingExport] = useState<boolean>(false)
    const [tempNewState, setTempNewState] = useState<any[]>([])
    const { getFieldValue } = props.form

    const handleChangeRangePicker = (range: any) => {
        if (range && range.length !== 0) {
            setRange(range)
        } else {
            setColumnsNewList([])
            setColumnsNewObjList([])
            setisFetchingFirst(false)
            setRange(undefined)
            setTotal(0)
        }
    }

    const HandelChangeColumnsNewAllTicketList = (e) => {
        setColumnsNewList(e)
        sessionStorage.setItem(props.name, e)
    }

    const handleOnClickPreviewAllTicket = () => {
        if (range) {
            const startData = range[0].format(dateFormat)
            const endDate = range[1].format(dateFormat)
            // console.log(props.name.toLowerCase())
            // fetchData(startData, endDate, PageIncidentService.getInstance().data.pageAllTicket, PageIncidentService.getInstance().data.pageSizeAllTicket, columnsNewList)
            if (props.name.toLowerCase() === 'allticket') {
                fetchData(startData, endDate, PageIncidentService.getInstance().data.pageAllTicket, PageIncidentService.getInstance().data.pageSizeAllTicket, columnsNewList)
            } else if (props.name.toLowerCase() === 'ticketsla') {
                fetchData(startData, endDate, PageIncidentService.getInstance().data.pageTicketSLA, PageIncidentService.getInstance().data.pageSizeTicketSLA, columnsNewList)
            } else if (props.name.toLowerCase() === 'ticketrelatedticket') {
                fetchData(startData, endDate, PageIncidentService.getInstance().data.pageTicketRelateTicket, PageIncidentService.getInstance().data.pageSizeTicketRelateTicket, columnsNewList)
            } else if (props.name.toLowerCase() === 'ticketrelatedasset') {
                fetchData(startData, endDate, PageIncidentService.getInstance().data.pageTicketRelateAsset, PageIncidentService.getInstance().data.pageSizeTicketRelateAsset, columnsNewList)
            } else if (props.name.toLowerCase() === 'ticketrelatedknowledge') {
                fetchData(startData, endDate, PageIncidentService.getInstance().data.pageTicketRelateKM, PageIncidentService.getInstance().data.pageSizeTicketRelateKM, columnsNewList)
            } else if (props.name.toLowerCase() === 'ticketrelatedworkflow') {
                fetchData(startData, endDate, PageIncidentService.getInstance().data.pageTicketRelateWF, PageIncidentService.getInstance().data.pageSizeTicketRelateWF, columnsNewList)
            }
        } else {
            setColumnsNewList([])
            setColumnsNewObjList([])
            setisFetchingFirst(false)
            setRange(undefined)
        }
    }

    const exportNewAllTicketData = () => {
        if (range) {
            setLoadingExport(true)
            const startData = moment(range[0]).format(dateFormat)
            const endDate = moment(range[1]).format(dateFormat)
            // if (props.name === 'allTicket') {
            //     getExportTicketReport(startData, endDate, props.name, columnsNewList, getFieldValue('Show') , getFieldValue('type') === "0" ? '' : getFieldValue('type')).then((data) => {
            //         console.log('success')
            //     }).finally(() => setLoadingExport(false))
            // } else if (props.name === 'ticketSLA') {

            // } else if (props.name === 'ticketRalateTicket') {

            // } else if (props.name === 'ticketRalateAsset') {

            // } else if (props.name === 'ticketRelatedKnowledge') {

            // } else if (props.name === 'ticketRelatedWorkflow') {

            // }
            getExportTicketReport(startData, endDate, props.name, columnsNewList, getFieldValue('Show'), getFieldValue('type') === '0' ? '' : getFieldValue('type')).then((data) => {
                console.log('success')
            }).finally(() => setLoadingExport(false))
        }
    }

    const getTableHeader = (data) => {
        const columnsExportNewAsset: ColumnNormal[] = []
        if (data.length > 0) {
            for (const keyName of data) {
                columnsExportNewAsset.push(
                    {
                        title: convertCamelCaseToSentenceCase(keyName),
                        dataIndex: keyName
                    }
                )
            }
            // data.map((keyName) => {
            //     columnsExportNewAsset.push(
            //         {
            //             title: convertCamelCaseToSentenceCase(keyName),
            //             dataIndex: keyName
            //         }
            //     )
            // })
        }
        return columnsExportNewAsset
    }

    const fetchData = (startDate: string, endDate: string, page: number, pageSize: number, selectedColumn: string[]) => {
        setLoading(true)
        getPreviewTicketReport(startDate, endDate, page, pageSize, selectedColumn, getFieldValue('Show'), getFieldValue('type') === '0' ? '' : getFieldValue('type'), props.name).then((res) => {
            const assetHeader = getTableHeader(res.header)
            if (res.data.length !== 0) {
                setTotal(res.total)
                setLoading(false)
                setTempNewState(res.data)
                if (!setisFetchingFirst || columnsNewList.length === 0) {
                    setisFetchingFirst(true)
                    if (sessionStorage.getItem(props.name)) {
                        const newAllTicket = sessionStorage.getItem(props.name)?.split(',')
                        setColumnsNewList(newAllTicket || [])
                    } else {
                        setColumnsNewList(assetHeader.map((keyName) => keyName.dataIndex))
                    }
                }
                setColumnsNewObjList(assetHeader)
                const newAllTicketInSesion = sessionStorage.getItem(props.name)?.split(',')
                if (newAllTicketInSesion) {
                    if (newAllTicketInSesion[0] !== '') {
                        const obj = {}
                        obj['ColumnAllTickets' + props.name] = sessionStorage.getItem(props.name)?.split(',')
                        props.form.setFieldsValue(obj)
                    } else {
                        const obj2 = {}
                        obj2['ColumnAllTickets' + props.name] = assetHeader.map((keyName) => keyName.dataIndex)
                        props.form.setFieldsValue(obj2)
                    }
                } else {
                    const obj3 = {}
                    obj3['ColumnAllTickets' + props.name] = assetHeader.map((keyName) => keyName.dataIndex)
                    props.form.setFieldsValue(obj3)
                }
            } else {
                setTotal(res.total)
                message.warning('Data not found.')
                setLoading(false)
                if (!setisFetchingFirst || columnsNewList.length === 0) {
                    setisFetchingFirst(true)
                    if (sessionStorage.getItem(props.name)) {
                        const newAllTicket = sessionStorage.getItem(props.name)?.split(',')
                        setColumnsNewList(newAllTicket || [])
                    } else {
                        setColumnsNewList(assetHeader.map((keyName) => keyName.dataIndex))
                    }
                }
                setColumnsNewObjList(assetHeader)
            }
        }).catch(() => {
            setTempNewState([])
        }).finally(() => setLoading(false))
    }

    const disabledTime = (current) => {
        // Disable times before 10:00 AM and after 6:00 PM
        return current && current > moment().endOf('day')
    }

    return (
        <Row gutter={[16, 16]}>
            <Col md={6} sm={24}>
                <p>Created Date</p>
                <RangePicker onChange={handleChangeRangePicker} placeholder={['Start Date', 'End Date']}
                    disabled={!checkRolesState(RoleType.Incident, 'ViewIncident')}
                    disabledDate={disabledTime} />
            </Col>
            {
                columnsNewObjList.length !== 0
                    ? <>
                        <Col md={8} sm={24}>
                            <p>Selected Column</p>
                            {props.form.getFieldDecorator('ColumnAllTickets' + props.name, {
                                initialValue: columnsNewList,
                                rules: [{ required: false, message: 'Please enter your Activity.' }]
                            })(<Select mode="tags"
                                style={{ width: '100%' }}
                                placeholder="Show"
                                maxTagCount={3}
                                // defaultValue={columnsNewList}
                                onChange={(e) => HandelChangeColumnsNewAllTicketList(e)}
                                allowClear={true}
                                loading={loading}
                            >
                                {(columnsNewObjList || []).map((it, index) => {
                                    return <Option key={index}
                                        value={it.dataIndex}>{it.title}</Option>
                                })}
                            </Select>)}
                        </Col>
                        <Col md={2} sm={24}>
                            <Button htmlType="button" style={{ marginTop: 32 }}
                                onClick={() => handleOnClickPreviewAllTicket()}
                                loading={loading}
                                type='primary'
                            ><Icon type="eye" />Preview</Button>
                        </Col>
                    </>
                    : (<>
                        <Col md={2} sm={24}>
                            <Button htmlType="button" style={{ marginTop: 32 }}
                                onClick={() => handleOnClickPreviewAllTicket()}
                                loading={loading}
                                type='primary'
                            ><Icon type="eye" /> Preview</Button>
                        </Col></>
                    )
            }
            <Col md={8} sm={24} style={{ textAlign: 'end' }}>
                {columnsNewObjList.length > 0
                    ? <Button htmlType="button" style={{ marginTop: 32 }}
                        onClick={() => exportNewAllTicketData()}
                        disabled={!checkRolesState(RoleType.Incident, 'ViewIncident') || columnsNewList.length === 0}
                        loading={loadingExport}
                    ><Icon type="upload" /> Export</Button> : null}
            </Col>
            <Col span={24}>
                <Spin tip="Loading..." spinning={loading}>
                    {props.name === 'allTicket' ? <ColumnAllTicket dataTransfer={tempNewState}
                        columnsAllTicketList={columnsNewList}
                        columnsAllTicketObjList={columnsNewObjList}
                        total={total} handleAllTicketChange={handleOnClickPreviewAllTicket}
                        range={range}
                    /> : null}
                    {props.name === 'ticketSla' ? <ColumnTicketSLA dataTransfer={tempNewState}
                        columnsTicketSLAList={columnsNewList}
                        columnsTicketSLAObjList={columnsNewObjList}
                        total={total} handleTicketSLAChange={handleOnClickPreviewAllTicket}
                        range={range}
                    /> : null}
                    {props.name === 'ticketRelatedTicket' ? <ColumnTicketRelateTicket dataTransfer={tempNewState}
                        columnsTicketRelateTicketList={columnsNewList}
                        columnsTicketRelateTicketObjList={columnsNewObjList}
                        total={total} handleTicketRelateTicketChange={handleOnClickPreviewAllTicket}
                        range={range}
                    /> : null}
                    {props.name === 'ticketRelatedAsset' ? <ColumnTicketRelateAsset dataTransfer={tempNewState}
                        columnsTicketRelateAssetList={columnsNewList}
                        columnsTicketRelateAssetObjList={columnsNewObjList}
                        total={total} handleTicketRelateAssetChange={handleOnClickPreviewAllTicket}
                        range={range}
                    /> : null}
                    {props.name === 'ticketRelatedKnowledge' ? <ColumnTicketRelateKM dataTransfer={tempNewState}
                        columnsTicketRelateKMList={columnsNewList}
                        columnsTicketRelateKMObjList={columnsNewObjList}
                        total={total} handleTicketRelateKMChange={handleOnClickPreviewAllTicket}
                        range={range}
                    /> : null}
                    {props.name === 'ticketRelatedWorkflow' ? <ColumnTicketRelateWF dataTransfer={tempNewState}
                        columnsTicketRelateWFList={columnsNewList}
                        columnsTicketRelateWFObjList={columnsNewObjList}
                        total={total} handleTicketRelateWFChange={handleOnClickPreviewAllTicket}
                        range={range}
                    /> : null}
                </Spin>
            </Col>
        </Row>
    )
}

export default CommonPanalReport
