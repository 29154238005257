/* eslint-disable prefer-const */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-prototype-builtins */
import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row, Spin, message } from 'antd'
import { Link, Redirect } from 'react-router-dom'
import { Asset, AssetGroupType, pmAdjustData, PreventiveWithAsset } from '../Model'
// import CustomInput from './CustomInput'
// import CustomSelect from './CustomSelect'
// import CustomInputGroup from './CustomInputGroup'
// import CustomSelectGroup from './CustomSelectGroup'
import { checkAllObjectsAreNull, compareTwoObjects, replaceName } from '../../common-file'
import { People } from '../../authorization-module/people/model'
import { FormComponentProps } from 'antd/es/form'
import {
    getAllSerialNumber,
    getAssetByID,
    getDataAssetGroup,
    getDataItems,
    getDataLocationBuilding,
    updatePreventiveById
} from '../services'
import { AssetStateContext } from '../ContextAPI/AssetStateContext'
import { BrandContext } from '../ContextAPI/BrandContext'
import { ZoneContext } from '../ContextAPI/ZoneContext'
import { AssetGroupContext } from '../ContextAPI/AssetGroupContext'
import { PeopleContext } from '../ContextAPI/UserHRContext'
// import { getAllPeople } from '../../authorization-module/user-role/duck/action'
import { LocationBuildingContext } from '../ContextAPI/LocationBuildingContext'
// import CustomInputGroup2 from './CustomInputGroup2'
// import CustomSelectWithFilter from './CustomSelectWithFilter'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import { CustomFiledAsset } from '../../configurable-fields'
// import RenderFiledForValidate from '../../configurable-fields/RenderFiledForValidate'
import { FieldDetail } from '../../incident-management/incident'
import moment from 'moment'
// import { convertCamelCaseToSentenceCase } from '../../knowledge-management/util'
// import SyncDataPMToAssetForm from './SyncDataPMToAsset/SyncDataPMToAsset'
import SyncDataPMToAsset from './SyncDataPMToAsset/SyncDataPMToAsset'
// import { SelectedImageAssetContext } from './SyncDataPMToAsset/ContextAPI/SelectedImageAsset'
// import { SelectedImagePMContext } from './SyncDataPMToAsset/ContextAPI/SelectedImagePM'
import SyncPMFieldBox from './SyncDataPMToAsset/SyncPMFieldBox'
import { DataComputerNameContext } from '../ContextAPI/DataComputerNameContext'
import { DataSerialNumberContext } from '../ContextAPI/DataSerialNumberContext'
import { DataModelContext } from '../ContextAPI/DataModelContext'
import { SetNoneAssetDetailFieldListContext } from './SyncDataPMToAsset/ContextAPI/SetNoneAssetDetailFieldList'
import { SetNoneCustomFieldListContext } from './SyncDataPMToAsset/ContextAPI/SetNoneCustomFieldList'

interface Param {
    current?: number
    setCurrent?: Function
    Group?: string
    dataPM?: PreventiveWithAsset
    dataAsset?: Asset
    isOnline: boolean
    getPmAdjustDataId?: Function,
    pmAdjusted?: pmAdjustData
    dataHR?: People
    getInitRemarkOption?: Function
    isFormSyncPM?: boolean
}

type Props = Param & FormComponentProps
// const { Panel } = Collapse
const Step2Form: React.FC<Props> = (props: Props) => {
    const { setFieldsValue, validateFields } = props.form
    const isITAsset = props.Group === 'IT Asset'
    const isPreview = window.location.pathname.includes('CompletePMForm')
    const [isNext, setISNext] = useState<boolean>(false)
    const [isRedirect, setISRedirect] = useState<boolean>(false)
    const { GetAssetState } = useContext<any>(AssetStateContext)
    const { GetBrand } = useContext<any>(BrandContext)
    const { GetZone } = useContext<any>(ZoneContext)
    const { GetAssetGroup } = useContext<any>(AssetGroupContext)
    const { PeopleState } = useContext<any>(PeopleContext)
    const [isCompletedPm, setIsCompletedPm] = useState<boolean>(false)
    // const [tempHR, setTempHR] = useState<People[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [, setIsSubmitSuccess] = useState<boolean>(false)
    const isPreven = window.location.pathname.includes('CompletePMFormView')
    const [tempAdjustData, setTempAdjustData] = useState()
    const [, setDataModel] = useState<string[]>([])
    const [, setDataSerialNumber] = useState<string[]>([])
    const [serialNumber, setSerialNumber] = useState<string[]>([])
    const [, setDataComputerName] = useState<string[]>([])
    const [computerName, setComputerName] = useState<string[]>([])
    const [CustomFiledAssetDetails, setCustomFiledAssetDetails] = useState<CustomFiledAsset[]>([])
    const [, setCustomFiledDateDetails] = useState<CustomFiledAsset[]>([])
    const [, setCustomFiledLoanDetails] = useState<CustomFiledAsset[]>([])
    const [, setCustomFiledLocationDetails] = useState<CustomFiledAsset[]>([])
    const [, setCustomFiledUserDetails] = useState<CustomFiledAsset[]>([])
    const [, setCustomFiledSETPeripheralDetails] = useState<CustomFiledAsset[]>([])
    const [, setDeleteLoanDetail] = useState<boolean>(false)
    const [isVisibleModalSyncdata, setIsVisibleModalSyncdata] = useState<boolean>(false)
    // const { GetSelectedImageAsset, SelectedImageAssetState } = useContext<any>(SelectedImageAssetContext)
    // const { GetSelectedImagePM, SelectedImagePMState } = useContext<any>(SelectedImagePMContext)
    const { GetLocationBuilding, LocationBuildingState } = useContext<any>(LocationBuildingContext)
    const [CustomFiledAssetAdjust, setCustomFiledAssetAdjust] = useState<CustomFiledAsset[]>([])
    const { GetDataComputerName } = useContext<any>(DataComputerNameContext)
    const { GetDataSerialNumber } = useContext<any>(DataSerialNumberContext)
    const { GetDataModel } = useContext<any>(DataModelContext)
    // const loanId = props.dataAsset?.assetLoanId === 'null' || props.dataAsset?.assetLoanId === '' || props.dataAsset?.assetLoanId === null || props.dataAsset?.assetLoanId === '0' || props.dataAsset?.assetLoanId === '-' ||
    //     props.dataAsset?.assetLoanId === undefined
    const { GetSetNoneAssetDetailFieldList } = useContext<any>(SetNoneAssetDetailFieldListContext)
    const { GetSetNoneCustomFieldList } = useContext<any>(SetNoneCustomFieldListContext)
    const [dataOriginalAsset, setdataOriginalAsset] = useState<Asset>()

    useEffect(() => {
        getDataItems('assetStatus').then((res) => {
            if (res) {
                GetAssetState(res)
            }
        })

        getDataItems('model').then((res) => {
            setDataModel(res)
            GetDataModel(res)
        })

        getDataItems('brand').then((res) => {
            if (res) {
                GetBrand(res)
            }
        })

        getDataItems('zone').then((res) => {
            if (res?.length !== 0) {
                GetZone(res)
            } else {
                GetZone(['-'])
            }
        })

        getDataAssetGroup().then((res) => {
            GetAssetGroup(res)
        })
        getDataLocationBuilding().then((res) => {
            GetLocationBuilding(res)
        })
        // getAllPeople().then((res) => {
        //     if (GetPeople) {
        //         GetPeople(res)
        //         setTempHR(res!!)
        //     }
        // })
        getAllSerialNumber().then((res) => {
            if (res) {
                setDataSerialNumber(res)
                setSerialNumber(res.slice(0, 20) || [])
                GetDataSerialNumber(res)
            }
        })
        getDataItems('assetName').then((res) => {
            setDataComputerName(res)
            setComputerName(res.slice(0, 20) || [])
            GetDataComputerName(res)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isITAsset) {
            setDeleteLoanDetail(true)
        } else {
            setDeleteLoanDetail(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isITAsset])

    useEffect(() => {
        if (props.dataPM || props.dataAsset) {
            if (props.dataPM?.preventiveMaintenance.pmAdjust?.originalAsset?.customField) {
                const findDataDate: any[] = []
                const findDataLoan: any[] = []
                const findDataLocation: any[] = []
                const findDataUser: any[] = []
                const findDataAsset: any[] = []
                const findSETPeripheralDetails: any[] = [];
                ((props.dataPM.preventiveMaintenance.pmAdjust.originalAsset.customField)?.filter((ele) => ele.isShowPm === true) || []).forEach((data) => {
                    let newObj = { ...data }
                    newObj.isRequireFields = false
                    findDataAsset.push(newObj)
                    // if (data?.headerGroup === 'LoanDetails') {
                    //     findDataLoan.push(data)
                    // }
                    // if (data?.headerGroup === 'DateDetails') {
                    //     findDataDate.push(data)
                    // }
                    // if (data?.headerGroup === 'LocationDetails') {
                    //     findDataLocation.push(data)
                    // }
                    // if (data?.headerGroup === 'UserDetails') {
                    //     findDataUser.push(data)
                    // }
                    // if (data?.headerGroup === 'SETPeripheralDetails') {
                    //     findSETPeripheralDetails.push(data)
                    // }
                })
                if (findDataAsset) {
                    setCustomFiledAssetDetails(findDataAsset!!)
                }
                if (findDataLocation) {
                    setCustomFiledLocationDetails(findDataLocation!!)
                }
                if (findDataUser) {
                    setCustomFiledUserDetails(findDataUser!!)
                }
                if (findDataLoan) {
                    setCustomFiledLoanDetails(findDataLoan!!)
                }
                if (findDataDate) {
                    setCustomFiledDateDetails(findDataDate!!)
                }
                if (findSETPeripheralDetails) {
                    console.log(findSETPeripheralDetails)
                    setCustomFiledSETPeripheralDetails(findSETPeripheralDetails!!)
                }

                let findDataAdjust: any = (props.dataPM?.preventiveMaintenance.pmAdjust?.pmAdjustData?.customField || props.dataPM.preventiveMaintenance.pmAdjust.originalAsset.customField).map((data) => {
                    let newObj = { ...data }
                    newObj.value = data.valueAdjust
                    newObj.isRequireFields = !data.value && data.isRequireFields ? true : false
                    return newObj
                })
                findDataAdjust = findDataAdjust?.filter((it) => it.isShowPm === true)
                if (findDataAdjust && findDataAdjust?.length > 0) {
                    setCustomFiledAssetAdjust(findDataAdjust)
                }
            }

            setFieldsValue({
                Serial_NumberAd: props.pmAdjusted?.pmAdjustData?.serialNumber || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.serialNumber || props.pmAdjusted?.pmAdjustData?.serialNumber,
                Asset_TagAd: props.pmAdjusted?.pmAdjustData?.assetTag || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.assetTag || props.pmAdjusted?.pmAdjustData?.assetTag,
                Asset_StatusAd: props.pmAdjusted?.pmAdjustData?.status || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.status || props.pmAdjusted?.pmAdjustData?.status,
                Asset_NameAd: props.pmAdjusted?.pmAdjustData?.assetName || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.assetName || props.pmAdjusted?.pmAdjustData?.assetName,
                ModelAd: props.pmAdjusted?.pmAdjustData?.model || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.model || props.pmAdjusted?.pmAdjustData?.model,
                ManufacturerAd: props.pmAdjusted?.pmAdjustData?.manufacturer || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.manufacturer || props.pmAdjusted?.pmAdjustData?.manufacturer,
                BrandAd: props.pmAdjusted?.pmAdjustData?.brand || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.brand || props.pmAdjusted?.pmAdjustData?.brand,
                TypeAd: props.pmAdjusted?.pmAdjustData?.assetGroupType?.type || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.assetGroupType?.type || props.pmAdjusted?.pmAdjustData?.assetGroupType?.type,
                SiteAd: props.pmAdjusted?.pmAdjustData?.site || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.site || props.pmAdjusted?.pmAdjustData?.site,
                LocationAd: props.pmAdjusted?.pmAdjustData?.location || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.location || props.pmAdjusted?.pmAdjustData?.location,
                BuildingAd: props.pmAdjusted?.pmAdjustData?.building || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.building || props.pmAdjusted?.pmAdjustData?.building,
                FloorAd: props.pmAdjusted?.pmAdjustData?.floor || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.floor || props.pmAdjusted?.pmAdjustData?.floor,
                ZoneAd: props.pmAdjusted?.pmAdjustData?.zone || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.zone || props.pmAdjusted?.pmAdjustData?.zone,
                User_NameAd: props.pmAdjusted?.pmAdjustData?.people?.fullName || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.people?.fullName || props.pmAdjusted?.pmAdjustData?.people?.fullName,
                User_IDAd: props.pmAdjusted?.pmAdjustData?.people?.employeeId || props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.people?.employeeId || props.pmAdjusted?.pmAdjustData?.people?.employeeId,
                // Owner_IDAd: props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.ownerId || props.pmAdjusted?.pmAdjustData?.ownerId,
                // Owner_NameAd: props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.ownerName || props.pmAdjusted?.pmAdjustData?.ownerName,
                DepartmentAd: props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.people?.department?.departmentName || props.pmAdjusted?.pmAdjustData?.people?.department?.departmentName,
                // Supervisor_IDAd: props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.supervisorId || props.pmAdjusted?.pmAdjustData?.supervisorId,
                // Supervisor_NameAd: props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.supervisorName || props.pmAdjusted?.pmAdjustData?.supervisorName,
                // Loan_IDAd: props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.assetLoanId || props.pmAdjusted?.pmAdjustData?.assetLoanId,
                // SETAd: props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.peripheralSet || props.pmAdjusted?.pmAdjustData?.peripheralSet,
                Paired_IT_AssetAd: props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.pairedItAsset || props.pmAdjusted?.pmAdjustData?.pairedItAsset,
                Serial_NumberOriginal: props.pmAdjusted?.originalAsset?.serialNumber || props.dataPM?.asset?.serialNumber || props.dataAsset?.serialNumber,
                Asset_TagOriginal: props.pmAdjusted?.originalAsset?.assetTag || props.dataPM?.asset?.assetTag || props.dataAsset?.assetTag,
                Asset_StatusOriginal: props.pmAdjusted?.originalAsset?.status || props.dataPM?.asset?.status || props.dataAsset?.status,
                Asset_NameOriginal: props.pmAdjusted?.originalAsset?.assetName || props.dataPM?.asset?.assetName || props.dataAsset?.assetName,
                ManufacturerOriginal: props.pmAdjusted?.originalAsset?.manufacturer || props.dataPM?.asset?.manufacturer || props.dataAsset?.manufacturer,
                ModelOriginal: props.pmAdjusted?.originalAsset?.model || props.dataPM?.asset?.model || props.dataAsset?.model,
                BrandOriginal: props.pmAdjusted?.originalAsset?.brand || props.dataPM?.asset?.brand || props.dataAsset?.brand,
                TypeOriginal: props.pmAdjusted?.originalAsset?.assetGroupType?.type || props.dataPM?.asset?.assetGroupType?.type || props.dataAsset?.assetGroupType?.type,
                SiteOriginal: props.pmAdjusted?.originalAsset?.site || props.dataPM?.asset?.site || props.dataAsset?.site,
                LocationOriginal: props.pmAdjusted?.originalAsset?.location || props.dataPM?.asset?.location || props.dataAsset?.location,
                BuildingOriginal: props.pmAdjusted?.originalAsset?.building || props.dataPM?.asset?.building || props.dataAsset?.building,
                FloorOriginal: props.pmAdjusted?.originalAsset?.floor || props.dataPM?.asset?.floor || props.dataAsset?.floor,
                ZoneOriginal: props.pmAdjusted?.originalAsset?.zone || props.dataPM?.asset?.zone || props.dataAsset?.zone,
                UserOriginal: props.pmAdjusted?.originalAsset?.people?.fullName || props.dataPM?.asset?.people?.fullName || props.dataAsset?.people?.fullName,
                User_NameOriginal: props.pmAdjusted?.originalAsset?.people?.fullName || props.dataPM?.asset?.people?.fullName || props.dataAsset?.people?.fullName || props.dataHR?.fullName,
                User_IDOriginal: props.pmAdjusted?.originalAsset?.people?.employeeId || props.dataPM?.asset?.people?.employeeId || props.dataAsset?.people?.employeeId || props.dataHR?.employeeId,
                // Owner_IDOriginal: props.dataPM?.asset?.ownerId || props.dataAsset?.ownerId,
                // Owner_NameOriginal: props.dataPM?.asset?.ownerName || props.dataAsset?.ownerName,
                DepartmentOriginal: props.dataPM?.asset?.people?.department?.departmentName || props.dataAsset?.people?.department?.departmentName,
                // Supervisor_IDOriginal: props.dataPM?.asset?.supervisorId || props.dataAsset?.supervisorId,
                // Supervisor_NameOriginal: props.dataPM?.asset?.supervisorName || props.dataAsset?.supervisorName,
                // Loan_IDOriginal: props.dataPM?.asset?.assetLoanId || props.dataAsset?.assetLoanId,
                // SETOriginal: props.dataPM?.asset?.peripheralSet || props.dataAsset?.peripheralSet,
                Paired_IT_AssetOriginal: props.pmAdjusted?.originalAsset?.pairedItAsset || props.dataPM?.asset?.pairedItAsset || props.dataAsset?.pairedItAsset
            })
            // setTempAdjustData(getFieldsValue())
        }
        if (props.dataPM?.preventiveMaintenance?.pmStatus === 'Completed' || props.dataPM?.preventiveMaintenance?.pmStatus === 'Ignored') {
            setIsCompletedPm(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataPM, props.dataAsset, props.pmAdjusted])

    const getKeysWithNonNullValues = (obj): string[] => {
        const keys: string[] = []

        for (const key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined) {
                // if (key !== 'id' && key !== 'serialNumber' && key !== 'createdDate' && key !== 'customField') {
                //     keys.push('Adjust ' + convertCamelCaseToSentenceCase(key))
                // }
                if (key !== 'id' && key !== 'serialNumber' && key !== 'createdDate') {
                    keys.push('Change - Asset Details')
                }
            }
        }

        return keys
    }

    const onFinish = (e: any) => {
        e.preventDefault()
        validateFields(async (err, values) => {
            if (!err) {
                setIsLoading(true)
                const filterPmAdjusted = props.dataPM?.preventiveMaintenance?.pmAdjust
                const convertData = props.dataPM!!
                const fieldDetails: FieldDetail[] = (props.dataPM?.preventiveMaintenance.pmAdjust?.originalAsset?.customField || []).map((obj: FieldDetail) => {
                    let value = ''
                    if (obj.name) {
                        if (obj.inputType === 'Checkbox' && Array.isArray(props.form.getFieldValue(obj.name + 'Ad'))) {
                            value = (props.form.getFieldValue(obj.name + 'Ad') || []).join('|')
                        } else if (obj.inputType === 'DatePicker' && props.form.getFieldValue(obj.name + 'Ad')) {
                            value = moment(props.form.getFieldValue(obj.name + 'Ad')).format('YYYY-MM-DD')
                        } else {
                            value = props.form.getFieldValue(obj.name + 'Ad')
                        }
                    }
                    const fieldDetail: FieldDetail = {
                        ...obj,
                        id: obj.id,
                        valueAdjust: value
                    }
                    return fieldDetail
                })
                const assetGroups = sessionStorage.getItem('assetGroup')
                let findAssetGroupType: AssetGroupType | undefined
                if (assetGroups) {
                    const asset = JSON.parse(assetGroups)
                    if (asset) {
                        findAssetGroupType = asset.find((data: AssetGroupType) => { return data.type === values.TypeAd })
                    }
                }
                let findPeople: People | undefined
                if (PeopleState) {
                    findPeople = PeopleState.find((data: People) => { return data.employeeId === values.User_IDAd })
                }
                console.log('People : ', findPeople)
                const dataAsset: Asset = {
                    id: filterPmAdjusted?.pmAdjustData?.id || props.pmAdjusted?.id || undefined,
                    assetTag: values.Asset_TagAd,
                    serialNumber: values.Serial_NumberAd,
                    assetName: values.Asset_NameAd,
                    manufacturer: values.ManufacturerAd,
                    model: values.ModelAd,
                    status: values.Asset_StatusAd,
                    brand: values.BrandAd,
                    assetGroupType: findAssetGroupType,
                    site: values.SiteAd,
                    location: values.LocationAd,
                    building: values.BuildingAd,
                    floor: values.FloorAd,
                    zone: values.ZoneAd,
                    // employeeName: values.User_NameAd,
                    people: findPeople ? {
                        id: findPeople.id,
                        employeeId: findPeople.employeeId,
                        firstName: findPeople.firstName,
                        lastName: findPeople.lastName,
                        department: { departmentName: findPeople.departmentName }
                    } as any : undefined,
                    // ownerId: values.Owner_IDAd,
                    // ownerName: values.Owner_NameAd,
                    // supervisorName: values.Supervisor_NameAd,
                    // supervisorId: values.Supervisor_IDAd,
                    // department: values.DepartmentAd,
                    // assetLoanId: values.Loan_IDAd,
                    // peripheralSet: values.SETAd,
                    pairedItAsset: values.Paired_IT_AssetAd,
                    createdDatetime: props.pmAdjusted?.createdDatetime || filterPmAdjusted?.pmAdjustData?.createdDatetime || undefined,
                    customField: fieldDetails
                }

                if (!checkAllObjectsAreNull(dataAsset)) {
                    convertData.asset.customField = fieldDetails
                    convertData.preventiveMaintenance.pmAdjust = {
                        id: props.pmAdjusted?.id || convertData.preventiveMaintenance.pmAdjust?.id || undefined,
                        serialNumber: convertData.preventiveMaintenance.asset?.serialNumber,
                        pmId: convertData.preventiveMaintenance?.id!!,
                        pmAdjustData: dataAsset,
                        originalAsset: props.pmAdjusted?.originalAsset || props.dataPM?.asset!!
                    }

                    if (props.getInitRemarkOption) {
                        props.getInitRemarkOption(getKeysWithNonNullValues(dataAsset))
                    }
                }

                convertData.preventiveMaintenance.asset = props.dataPM?.asset!!
                if (convertData.preventiveMaintenance.pmActivityCustomField === undefined) {
                    if (checkAllObjectsAreNull(convertData.preventiveMaintenance.pmActivityCustomField)) {
                        convertData.preventiveMaintenance.pmActivityCustomField = undefined
                    }
                }

                if (isNext) {
                    if (convertData.preventiveMaintenance.currentStep === 1) {
                        convertData.preventiveMaintenance.currentStep = convertData.preventiveMaintenance.currentStep + 1
                    }
                }
                convertData.preventiveMaintenance.actionInStep = 2
                if (props.isOnline) {
                    setIsLoading(true)
                    // update when step 2 had change
                    if (!compareTwoObjects(tempAdjustData, values)) {
                        updatePreventiveById(convertData.preventiveMaintenance?.id!!, convertData.preventiveMaintenance).then((res) => {
                            if (res) {
                                if (props.getPmAdjustDataId) {
                                    props.getPmAdjustDataId(res.pmAdjust)
                                    setTempAdjustData(res.pmAdjust)
                                }
                                message.success('The data has been saved successfully.')
                                if (props.setCurrent && isNext) {
                                    if (props.current) {
                                        props.setCurrent(props.current + 1)
                                    }
                                }
                                setIsLoading(false)
                                setIsSubmitSuccess(true)
                            }
                        }).catch((err) => {
                            message.error('Unable to update ticket. Please check and try again.' + err)
                            setIsLoading(false)
                        }).finally(() => { setIsSubmitSuccess(false) })
                    } else {
                        setIsLoading(false)
                    }
                } else {
                    if (!compareTwoObjects(tempAdjustData, values)) {
                        const getKeys = sessionStorage.getItem('key')
                        if (getKeys) {
                            const keys: string[] = JSON.parse(getKeys)
                            keys.push(convertData.asset.assetTag)
                            sessionStorage.setItem('key', JSON.stringify([...new Set(keys)]))
                        } else {
                            sessionStorage.setItem('key', JSON.stringify([convertData.asset.assetTag]))
                        }
                        convertData.isUpdate = true
                        // updateDataPMWithAssetInDBOffline(convertData)
                        message.success('The data has been saved successfully.')
                        if (props.setCurrent && isNext) {
                            if (props.current) {
                                props.setCurrent(props.current + 1)
                            }
                        }
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                    }
                }
            } else {
                message.error('Please complete all fields marked with an asterisk (*).')
            }
        })
    }

    const BackStep = () => {
        if (props.setCurrent && !isCompletedPm) {
            if (props.current) {
                props.setCurrent(props.current - 1)
            }
        } else {
            setISRedirect(true)
        }
    }

    // const CheckTypeAssetGroup = (): string[] => {
    //     if (isITAsset) {
    //         const assetGroups = sessionStorage.getItem('assetGroup')
    //         if (assetGroups) {
    //             let asset = JSON.parse(assetGroups)
    //             // console.log('asset', asset)
    //             if (asset) {
    //                 asset = asset.filter((data: AssetGroupType) => data.assetGroup.name === 'IT Asset').map((data: AssetGroupType) => data.type)
    //             }
    //             return asset || []
    //         } else {
    //             return []
    //         }
    //     } else {
    //         const assetGroups = sessionStorage.getItem('assetGroup')
    //         if (assetGroups) {
    //             let asset = JSON.parse(assetGroups)
    //             if (asset) {
    //                 asset = asset.filter((data: AssetGroupType) => data.assetGroup.name !== 'IT Asset').map((data: AssetGroupType) => data.type)
    //             }
    //             return asset || []
    //         } else {
    //             return []
    //         }
    //     }
    // }

    const onCancelSyncPMAsset = () => {
        Modal.confirm({
            title: 'Are you sure?',
            content: 'Do you want to leave this page ?',
            onOk: () => {
                setIsVisibleModalSyncdata(false)
                // eslint-disable-next-line no-unused-expressions
                props.dataPM?.preventiveMaintenance.pmAdjust?.originalAsset?.customField?.forEach((obj, idx) => {
                    const tempField = {}
                    tempField[replaceName(obj.name!!)] = obj.value
                    setFieldsValue(tempField)
                    GetSetNoneAssetDetailFieldList([])
                    GetSetNoneCustomFieldList([])
                })
            },
            onCancel: () => { },
            okText: 'Yes',
            cancelText: 'No'
        })
    }

    const handleClickSyncPMToAsset = () => {
        getAssetByID(props.dataPM?.asset.id?.toString()!!).then((res) => {
            setIsVisibleModalSyncdata(true)
            setdataOriginalAsset(res)
        }).catch(() => {
            Modal.warning({
                title: (<b>Warnning</b>),
                content: (<div>
                    <b>The asset could not be synced because the information was Data not found. in the system.</b>
                </div>)
            })
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return (
        <div>
            {isRedirect ? !isCompletedPm ? <Redirect to="/Preventive" /> : <Redirect to="/CompletePM" /> : null}
            {/* <p><UsbOutlined />{props.dataPM?.preventiveMaintenance.pmStatus !== 'Completed' ? 'Validate': null} Asset Details ({props.Group})</p> */}
            <br />
            <Spin spinning={isLoading}>
                <Form
                    name="Step2"
                    layout={'vertical'}
                    onSubmit={onFinish}
                >
                    <Card style={{ backgroundColor: '#F8F8F9', borderRadius: '7px' }}>
                        <br />
                        {/* <CustomInput name={'Serial Number'} form={props.form} isPreview={isPreview} maxInput={100} isEdit={false}
                        disableItem={true} isScan={false} /> */}
                        {/* <CustomSelect name={'Asset Status'} form={props.form} isPreview={isPreview}
                            options={AssetStateState || ['In Use', 'In Store', 'Expired', 'Disposed']} isEdit={true}
                            disableItem={true} isSubmitSuccess={isSubmitSuccess} />
                        <CustomInput name={'Asset Tag'} form={props.form} isPreview={isPreview} maxInput={100} isEdit={true}
                            disableItem={true} isSubmitSuccess={isSubmitSuccess} />
                        <CustomInput name={'Asset Name'} form={props.form} isPreview={isPreview} maxInput={100} isEdit={true} isSubmitSuccess={isSubmitSuccess}
                            disableItem={true} isScan={false} autoCompleteData={computerName} fullData={dataComputerName} setState={setComputerName} />
                        <CustomInput name={'Manufacturer'} form={props.form} isPreview={isPreview} maxInput={100} isEdit={true}
                            disableItem={true} isScan={false} isSubmitSuccess={isSubmitSuccess} />
                        <CustomSelect name={'Model'} form={props.form} isPreview={isPreview} maxInput={100} isEdit={true}
                            disableItem={true} options={dataModel || []} isSubmitSuccess={isSubmitSuccess} />
                        <CustomSelect name={'Brand'} form={props.form} isPreview={isPreview}
                            options={BrandState || []} isEdit={true} maxInput={255} disableItem={true} isSubmitSuccess={isSubmitSuccess} />
                        <CustomSelect name={'Type'} form={props.form} isPreview={isPreview}
                            options={CheckTypeAssetGroup() || []} isEdit={true} maxInput={255} isSubmitSuccess={isSubmitSuccess}
                            disableItem={true} />
                        <CustomSelectGroup form={props.form}
                            isPreview={props.dataPM?.preventiveMaintenance?.pmStatus === 'Completed' || props.dataPM?.preventiveMaintenance?.pmStatus === 'Ignored'}
                            isEdit={true} disableItem={true} dataZone={ZoneState || []} isSubmitSuccess={isSubmitSuccess} />
                        <CustomSelect name={'Zone'} form={props.form} isPreview={isPreview}
                            options={ZoneState || []} isEdit={true} maxInput={255} disableItem={true} isSubmitSuccess={isSubmitSuccess} />

                        <CustomInputGroup2 form={props.form}
                            isPreview={props.dataPM?.preventiveMaintenance?.pmStatus === 'Completed' || props.dataPM?.preventiveMaintenance?.pmStatus === 'Ignored'} isSubmitSuccess={isSubmitSuccess}
                            isEdit={loanId} maxInput={255} isOpenModel={true} DataHR={PeopleState} disableItem={true} />

                        <CustomSelectWithFilter name={'Paired IT Asset'} form={props.form} isPreview={isPreview} isSubmitSuccess={isSubmitSuccess}
                            options={serialNumber || []} isEdit={true} maxInput={255} disableItem={true} autoCompleteData={serialNumber} fullData={dataSerialNumber} setState={setSerialNumber} />

                        {(CustomFiledAssetDetails || []).map((data, id) => {
                            return <div key={id}><RenderFiledForValidate fields={[data]} form={props.form} disableItem={true} colSpan={24} colSM={24} labelColSm={6} wrapperColSm={18} maxWidth={'100%'} genCol={2} disableFields={false} isShowToggle={false} isPreview={isPreview} isSubmit={isSubmitSuccess} /></div>
                        })} */}

                        {/* {(CustomFiledLocationDetails || []).map((data, id) => {
                        return <div key={id}><RenderFiledForValidate fields={[data]} form={props.form} colSpan={24} colSM={24} labelColSm={6} wrapperColSm={18} maxWidth={'100%'} genCol={2} disableFields={false} isShowToggle={false} isPreview={isPreview} isSubmit={isSubmitSuccess}/></div>
                    })}

                    {(CustomFiledUserDetails || []).map((data, id) => {
                        return <div key={id}><RenderFiledForValidate fields={[data]} form={props.form} colSpan={24} colSM={24} labelColSm={6} wrapperColSm={18} maxWidth={'100%'} genCol={2} disableFields={false} isShowToggle={false} isPreview={isPreview} isSubmit={isSubmitSuccess}/></div>
                    })}
                    {(CustomFiledLoanDetails || []).map((data, id) => {
                        return <div key={id}><RenderFiledForValidate fields={[data]} form={props.form} colSpan={24} colSM={24} labelColSm={6} wrapperColSm={18} maxWidth={'100%'} genCol={2} disableFields={false} isShowToggle={false} isPreview={isPreview} isSubmit={isSubmitSuccess}/></div>
                    })}
                    {(CustomFiledSETPeripheralDetails || []).map((data, id) => {
                        return <div key={id}><RenderFiledForValidate fields={[data]} form={props.form} colSpan={24} colSM={24} labelColSm={6} wrapperColSm={18} maxWidth={'100%'} genCol={2} disableFields={false} isShowToggle={false} isPreview={isPreview} isSubmit={isSubmitSuccess}/></div>
                    })} */}

                        {/* <Panel header={'Location Details'} key="2">

                    </Panel>
                    <Panel header={'Owner Details'} key="3">

                    </Panel>
                    <Panel style={{display: deleteLoanDetail ? 'none' : 'block'}} header={isITAsset ? 'Loan Details' : 'SET Peripheral Details'} key="4">

                    </Panel> */}
                        <br />
                        {/* <CustomInput name={'Serial Number'} form={props.form} isPreview={isPreview} maxInput={100} isEdit={false}
                        disableItem={true} isScan={false} /> */}
                        <Row gutter={[32, 24]}>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12}>
                                <SyncPMFieldBox form={props.form}
                                    isOriginalAsset={true}
                                    locationBuilding={LocationBuildingState}
                                    computerName={computerName}
                                    serialNumber={serialNumber}
                                    setComputerName={setComputerName}
                                    setSerialNumber={setSerialNumber}
                                    CustomFiledAssetDetails={CustomFiledAssetDetails}
                                    postfix={'Original'}
                                    isDisableItems={true}
                                />
                            </Col>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12}>
                                <SyncPMFieldBox form={props.form}
                                    isOriginalAsset={false}
                                    locationBuilding={LocationBuildingState}
                                    computerName={computerName}
                                    serialNumber={serialNumber}
                                    setComputerName={setComputerName}
                                    setSerialNumber={setSerialNumber}
                                    CustomFiledAssetDetails={CustomFiledAssetAdjust}
                                    postfix={'Ad'}
                                    isDisableItems={isPreview}
                                />
                            </Col>
                        </Row>
                    </Card>
                    <br />
                    {props.isFormSyncPM
                        ? null : <Form.Item style={{ textAlign: 'end' }}>
                            {isPreview
                                ? <>
                                    {isPreven ? null : props.dataPM?.preventiveMaintenance.pmStatus === 'Ignored' ? null : <Button type="default" htmlType="button" onClick={handleClickSyncPMToAsset} >
                                        Sync Data to Asset
                                    </Button>}
                                    <Button type="primary" htmlType="button" onClick={() => setISRedirect(true)} style={{ marginLeft: 5 }}>
                                        {isPreven ? <Link to="/Preventive">Back</Link> : <Link to="/CompletePM">Back</Link>}
                                    </Button> </> : <>
                                    <Button htmlType="button" onClick={BackStep}>
                                        Back
                                    </Button>
                                    <Button type="primary" htmlType="submit" className="ml5" style={{ marginLeft: 5, marginRight: 5 }} disabled={!checkRolesState(RoleType.PM, 'CreateUpdateDeletePMPlan')}>
                                        Submit
                                    </Button>
                                    <Button type="primary" htmlType="submit" onClick={() => setISNext(true)} className="ml5" disabled={!checkRolesState(RoleType.PM, 'CreateUpdateDeletePMPlan')}>
                                        Next
                                    </Button>
                                </>}
                        </Form.Item>
                    }
                    <Modal
                        title='Sync data to Asset'
                        onCancel={onCancelSyncPMAsset}
                        footer={null}
                        visible={isVisibleModalSyncdata}
                        width={1200}
                        maskClosable={false}
                    >
                        <SyncDataPMToAsset form={props.form} dataPM={props.dataPM} setIsVisibleModalSyncdata={setIsVisibleModalSyncdata} isVisibleModalSyncdata={isVisibleModalSyncdata} dataOriginalAsset={dataOriginalAsset} />
                    </Modal>
                </Form>
            </Spin>
        </div>
    )
}

const MyStep2Form = Form.create<Props>({ name: 'Step2Form' })(Step2Form)
export default MyStep2Form
