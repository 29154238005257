/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Button, Col, Drawer, Empty, Form, Input, message, Row, Select, Spin, Table, Tooltip } from 'antd'
import MyStep from '../../wo-management/stepAndSignature/MyStep'
import { StoreState } from '../../../store'
import { connect } from 'react-redux'
import { addJob, getAllJob, checkIsCurrentJob, getCurrentJobNo, updateJobStatus, getAllJobwithListWorkflow } from './service'
import { useLocation, useParams } from 'react-router-dom'
import { Job } from './model'
import { AssigneesPeople, getSupportTeamUser, SupportTeamList, ticketRunningNumber, WorkOrder } from '../../wo-management'
import { FormComponentProps } from 'antd/lib/form'
import { MyNode, WorkFlow } from '../../../serviceCatalog/workflow/Service/Model'
// import { RoleType, checkRolesState } from '../../../authorization-module/permissions'
import { Asset } from '../Asset'

interface Param {
    getItemJob: Function,
    woId?: string,
    permission: boolean,
    roleId?: number,
    assignedTo: AssigneesPeople[],
    vSpaceIncidentNo: string
    selectWorkFlow?: WorkFlow
    dataAsset?: Asset[]
    wo?: WorkOrder
}

const mapStateToProps = (state: StoreState) => {
    return {
        job: state.job
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllJob: (woId?: string) => Promise<Job[] | undefined>,
}
const { Option } = Select
type Props = Param & DispatchProps & StateProps & FormComponentProps
// const isUpdate: boolean = false
const RelateJob: React.FC<Props> = (props: Props) => {
    const [isCreateJob, setIsCreateJob] = useState<boolean>(false)
    const [Template, setTemplate] = useState<number>(7)
    const [data, setDatas] = useState<Job[] | undefined>([])
    const [, setIsSpinning] = useState<boolean>(false)
    const [isSpinningTable, setIsSpinningTable] = useState<boolean>(false)
    const [isChangeStatus, setIsChangeStatus] = useState<boolean>(false)
    const [username, setUsername] = useState<string>('')
    const [currentJobNo, setCurrentJobNo] = useState<string>('')
    const User = []
    const { id }: any = useParams()
    const location = useLocation()
    const { getFieldDecorator, isFieldValidating, getFieldValue } = props.form
    const [isLoadingCreateJob, setIsLoadingCreateJob] = useState<boolean>(false)
    // const [, updateState] = useState()
    // const forceUpdate = useCallback(() => updateState(Object), [])
    const [AssignedGroup, setAssignedGroup] = useState<SupportTeamList[]>([])
    const [AssignedTo, setAssignedTo] = useState<AssigneesPeople[]>([])

    useEffect(() => {
        const user = sessionStorage.getItem('username')
        setUsername(user!!)
        getSupportTeamUser().then((res) => {
            setAssignedGroup(res)
        }).catch((err) => message.error(`Failed loading initial TicketNumber data. ${err}`))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (id && !isChangeStatus) {
            // props.getAllJob(id).then((data) => {
            //     const tempData = data
            //     setDatas(tempData)
            //     initialData(data)
            // })
            getAllJobwithListWorkflow(id).then((data) => {
                data.forEach((it, index) => {
                    it.tempIndex = index + 1
                })
                const tempData = data
                setDatas(tempData)
                initialData(data)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        if (props.selectWorkFlow) {
            setIsSpinning(true)
            setIsLoadingCreateJob(true)
            setIsSpinningTable(true)
            const tempDateJobPromise = convertDateJob(props.selectWorkFlow.nodes)
            Promise.all([tempDateJobPromise]).then((values) => {
                const temp = values[0]
                temp.forEach((it, index) => {
                    it.tempIndex = index + 1
                })
                console.log(temp)
                setTimeout(() => {
                    setDatas(temp)
                    setIsSpinning(false)
                    setIsLoadingCreateJob(false)
                    setIsSpinningTable(false)
                    setTemplate(8)
                    props.getItemJob(values[0])
                }, 1000)
            })
        }
        if (props.selectWorkFlow === undefined) {
            setDatas([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectWorkFlow])

    useEffect(() => {
        // const jobData = {
        //     lastModifiedBy: username,
        //     jobStatus: ''
        // }
        if (id) {
            // const idWO = id || ''
            const job = data?.find((job) => {
                if (job.jobNo === currentJobNo) {
                    return job
                }
            })
            if (job && job.jobStatus === 'Created') {
                // jobData.jobStatus = job.jobTopic === 'Approve' ? 'Awaiting Approval' : 'Open'
                // updateJobStatus(job.id!!, jobData).then(() => {
                //     props.getAllJob(idWO).then((data) => {
                //         setDatas(data)
                //         initialData(data)
                //     }).catch((err) => message.error(`Failed loading initial job data. ${err}`))
                // })
            } else {
                getAllJobwithListWorkflow(id).then((data) => {
                    if (data) {
                        data.forEach((it, index) => {
                            it.tempIndex = index + 1
                        })
                        props.getItemJob(data)
                        setDatas(data)
                        initialData(data)
                    }
                })
                // props.getAllJob(idWO).then((data) => {
                //     if (data) {
                //         if (data.length > 0) {
                //             setDatas(data)
                //             initialData(data)
                //         }
                //     }
                // const isCreated = data?.some((it) => it.jobStatus === 'Created')
                // if (isCreated) {
                //     const promiseAll2: Promise<Job | void>[] = []
                //     let ConvertDataJob: Job[] = []
                //     const jobfilter = data?.filter((it) => it.jobStatus === 'Created')
                //     console.log('jobFilter : ',jobfilter)
                //     ConvertDataJob = (jobfilter || []).map((it) => {
                //         return { ...it, jobStatus: 'Open' }
                //     })

                //     const newData = data?.map((it) => {
                //         if (it.jobStatus === 'Created') {
                //             return { ...it, jobStatus: 'Open' }
                //         } else {
                //             return it
                //         }
                //     })
                //     setDatas(newData)
                //     if (ConvertDataJob.length !== 0 && !isUpdate) {
                //         isUpdate = true
                //         ConvertDataJob.forEach((job) => {
                //             if (job.id) {
                //                 updateJobStatus(job.id?.toString(), job).catch((err) => {
                //                     message.error(`Unable to save data. Please check and try again. ${err}`)
                //                 })
                //             }
                //         })
                //         if (promiseAll2.length !== 0) {
                //             Promise.all(promiseAll2).finally(() => {
                //                 forceUpdate()
                //             })
                //         }
                //     }
                // }
                // }).catch((err) => message.error(`Failed loading initial job data. ${err}`))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search, isChangeStatus, currentJobNo])

    useEffect(() => {
        if (location.pathname.includes('/WoFormEdit') || location.pathname === '/WoList') {
            if (props.job.length > 0) {
                props.job.forEach((it, index) => {
                    it.tempIndex = index + 1
                })
                setDatas(props.job)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.job])

    useEffect(() => {
        setCurrentJobNo(getCurrentJobNo(data!!))
        if (data?.length!! > 0) {
            data?.forEach((it, index) => {
                it.tempIndex = index + 1
            })
            setDatas(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    // const cancelJob = (item: Job) => {
    //     Modal.confirm({
    //         title: 'Are you sure?',
    //         content: 'You want to cancel jobs ?',
    //         okText: 'Yes',
    //         okType: 'danger',
    //         autoFocusButton: 'cancel',
    //         cancelText: 'No',
    //         onOk() {
    //             if (item.jobStatus !== 'Created') {
    //                 cancelAllJobs([item.id?.toString()!!]).then((res) => {
    //                     setDatas(res)
    //                     props.getItemJob(res)
    //                 }).catch((err) => message.error(`Failed cancel job. ${err}`))
    //             } else {
    //                 const filterData = data?.filter((res) => res.jobNo !== item.jobNo)
    //                 setDatas(filterData)
    //                 props.getItemJob(filterData)
    //             }
    //         }
    //     })
    // }

    const convertDateJob = async (params: MyNode[]) => {
        const tempJobList: Job[] | undefined = []
        for (const it of params) {
            if (it.type === 'Task') {
                const tempJob: Job = {
                    woId: props.woId!!,
                    jobNo: '-',
                    jobTopic: it.properties.topic!!,
                    jobStatus: 'Created',
                    assignee: it.properties.assignee,
                    supportTeam: it.properties.supportTeam,
                    isExpense: it.properties.expense,
                    isNotify: it.properties.notify
                }
                tempJobList.push(tempJob)
            } else if (it.type === 'Approval') {
                const tempJob1: Job = {
                    woId: props.woId!!,
                    jobNo: '-',
                    jobTopic: it.properties.supportTeam || '',
                    jobStatus: 'Created',
                    supportTeam: it.properties.supportTeam,
                    isApprovalJob: true
                }
                tempJobList.push(tempJob1)
            }
        }
        return tempJobList
    }

    const onSubmitCreateJob = () => {
        props.form.validateFields(async (_err: any, values: any) => {
            if (!_err) {
                setIsSpinning(true)
                setIsLoadingCreateJob(true)
                const jobNumber = await ticketRunningNumber('Job')
                const newJob: Job = {
                    woId: props.woId!!,
                    jobNo: jobNumber,
                    jobTopic: values.jobTopic,
                    jobStatus: 'Created',
                    createdBy: username,
                    lastModifiedBy: username,
                    supportTeam: getFieldValue('group'),
                    assignee: getFieldValue('to')
                }
                data?.push(newJob)
                setDatas(data)
                setIsCreateJob(false)
                setIsSpinning(false)
                setIsLoadingCreateJob(false)
                setTemplate(8)
                props.getItemJob(data)
                props.form.resetFields()
            }
        })
    }

    const initialData = (it) => {
        (it || []).forEach((it) => {
            if (it.templateNo) {
                setTemplate(it.templateNo)
            } else {
                setTemplate(7)
            }
        })
    }

    const handleChangeStatus = (change: boolean) => {
        setCurrentJobNo(getCurrentJobNo(data!!))
        setIsChangeStatus(change)
    }

    const onClose = () => {
        // onClose
    }

    const updateAssignGroupAndAssignTo = (listOfItemWithAssignment, jobNo) => {
        if (id) {
            const updateJob = listOfItemWithAssignment.find((it) => it?.jobNo === jobNo)
            if (updateJob?.jobStatus !== 'Created' || location.pathname.includes('/WoFormEdit')) {
                if (updateJob?.id) {
                    updateJobStatus(updateJob?.id?.toString(), { ...updateJob }).then(() => {
                        message.success('The update have finished successfully')
                        if (listOfItemWithAssignment.length !== 0) {
                            props.getItemJob(listOfItemWithAssignment)
                        }
                    }).catch((err) => {
                        message.error(`Unable to save data. Please check and try again. ${err}`)
                    })
                }
            }
        }
        if (listOfItemWithAssignment.length !== 0) {
            props.getItemJob(listOfItemWithAssignment)
        }
    }

    const getAssignmentGroupAndAssignToFromMyStep = (jobNo, group, assign) => {
        const findGroupAssignee = AssignedGroup.find((data) => { return data.name === group })
        const findAssigneeId = findGroupAssignee?.assignees.find((user) => { return user.fullName === assign })
        const listOfItemWithAssignment = (data || []).map((res) => {
            if (jobNo === res.jobNo) {
                delete res.jobSignature
                return { ...res, supportTeam: group, assignee: assign, assigneeId: findAssigneeId?.id, assignmentGroupId: findAssigneeId?.supportTeamId }
            } else {
                return res
            }
        })
        setDatas(listOfItemWithAssignment as Job[])
        updateAssignGroupAndAssignTo(listOfItemWithAssignment, jobNo)
    }

    const columns = [
        {
            title: <span className='bold-titleWo'>No</span>,
            dataIndex: 'id',
            key: 'id',
            width: '7%',
            // eslint-disable-next-line react/display-name
            render: (id, datas, index) => {
                return (
                    <>
                        {datas.tempIndex}
                    </>
                )
            }
        },
        {
            title: <span className='bold-titleWo'>Job number</span>,
            dataIndex: 'jobNo',
            key: 'jobNo',
            width: '20%'
        },
        {
            title: <span className='bold-titleWo'>Topic</span>,
            dataIndex: 'jobTopic',
            key: 'jobTopic',
            width: '48%'
        },
        {
            title: <span className='bold-titleWo'>Job Status</span>,
            dataIndex: 'jobStatus',
            key: 'jobStatus',
            width: '25%'
        }
    ]

    const columns1 = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '7%'
        },
        {
            title: <span className='bold-titleWo'>No</span>,
            dataIndex: 'id',
            key: 'id',
            width: '7%',
            // eslint-disable-next-line react/display-name
            render: (id, datas, index) => {
                return (
                    <>
                        {datas.tempIndex}
                    </>
                )
            }
        },
        {
            title: <span className='bold-titleWo'>Job Number</span>,
            dataIndex: 'jobNo',
            key: 'jobNo',
            width: '20%'
        },
        {
            title: <span className='bold-titleWo'>Approver of group</span>,
            dataIndex: 'supportTeam',
            key: 'supportTeam',
            width: '41%'
        },
        {
            title: <span className='bold-titleWo'>Job Status</span>,
            dataIndex: '',
            key: 'jobStatus',
            render: (data, index) => {
                return (
                    <>
                        <Tooltip placement='bottom' title={data.jobStatus === 'Rejected' ? `Reject Reason : ${data.approvalRecordReason}` : undefined}>
                            {data.jobStatus}
                        </Tooltip>
                    </>
                )
            },
            width: '25%'
        }
    ]

    const expandedRowRender = (expandedRow, index) => {
        const currentJob: boolean = checkIsCurrentJob(index, data!!, expandedRow)
        return <div style={{ padding: 10, backgroundColor: '#F2F2F2' }}>
            {/* eslint-disable-next-line react/prop-types */}
            <MyStep
                woId={props.woId as string || ''}
                isDirection={false}
                isSignature={!(Template === 7 || Template === 6)}
                job={expandedRow}
                isCurrentJob={currentJob}
                handleChangeStatus={handleChangeStatus}
                username={username}
                isStatus={isChangeStatus}
                vSpaceIncidentNo={props.vSpaceIncidentNo}
                getDataFromMyStep={getAssignmentGroupAndAssignToFromMyStep}
                User={User}
                dataAsset={props.dataAsset}
                wo={props.wo}
            />
            <br />
        </div>
    }

    const cancelCreateJob = () => {
        setIsCreateJob(false)
        props.form.resetFields()
    }

    const onAssignment = (value: any) => {
        props.form.setFieldsValue({
            to: undefined
        })
        if (value) {
            (AssignedGroup || []).forEach((data) => {
                if (data.name === value) {
                    setAssignedTo(data.assignees.filter(it => it.fullName?.length > 0))
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    return (
        <>
            <Row>
                <Col span={12} >
                    <Drawer
                        title="Create Job"
                        placement="right"
                        closable={false}
                        onClose={onClose}
                        width={350}
                        visible={isCreateJob}
                    >
                        <Form>
                            <Form.Item label="Job Topic">
                                {getFieldDecorator('jobTopic', {
                                    rules: [{
                                        required: true, message: 'Job Topic is required.'
                                    }]
                                })(
                                    <Input placeholder="Job Topic" style={{ width: '100%' }} maxLength={255} />
                                )}
                            </Form.Item>
                            <Form.Item label="Assignment Group">
                                {getFieldDecorator('group', {
                                    rules: [{
                                        required: true, message: 'Assignment Group is required.'
                                    }]
                                })(
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Select an Assignment Group"
                                        allowClear
                                        onSelect={(value) => onAssignment(value)}
                                    >
                                        {(AssignedGroup || []).map((it, index) => {
                                            return <Option value={it.name} key={index}>{it.name}</Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label="Assigned To">
                                {getFieldDecorator('to', {
                                    rules: [{
                                        required: false
                                    }]
                                })(
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Select an Assigned To"
                                        allowClear
                                    >
                                        {(AssignedTo || []).map((it, index) => {
                                            return <Option value={it.email} key={index}>{it.fullName}</Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                            <div style={{ bottom: 0, right: 0, margin: '5%', textAlign: 'right' }}>
                                <Button htmlType="button" style={{ marginRight: 10 }} onClick={() => cancelCreateJob()}>
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="button"
                                    loading={isLoadingCreateJob}
                                    onClick={() => onSubmitCreateJob()}
                                    disabled={isFieldValidating('jobTopic') && isFieldValidating('group')}>
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    </Drawer>
                </Col>
            </Row>
            {/* <span className='feck-link' onClick={() => setIsCreateJob(!isCreateJob)} >Create Job</span> */}
            <br />
            <div>
                <Spin spinning={isSpinningTable}>
                    {data?.length!! > 0 ? (data || [])?.map((it, index) => {
                        return <div style={{ padding: 10, backgroundColor: '#F2F2F2', marginBottom: 10 }} key={index}>
                            {/* <Row gutter={[0, 8]}>
                                <Col xs={24} sm={24} style={{ textAlign: 'right' }}>
                                    <Button type={'primary'} onClick={() => cancelJob(it)} disabled={props.permission || it.jobStatus === 'Closed' || it.jobStatus === 'Cancelled'}>Cancel</Button>
                                </Col>
                            </Row> */}
                            <br />
                            {/* <Spin spinning={isSpinning}> */}
                            <div style={{ padding: 10, overflow: 'auto' }}>
                                <Table
                                    dataSource={[it]}
                                    columns={it.isApprovalJob === true ? columns1 : columns}
                                    rowKey='jobNo'
                                    expandedRowRender={it.isApprovalJob === true ? undefined : expandedRowRender}
                                    pagination={false}
                                />
                            </div>
                            {/* </Spin> */}
                        </div>
                    }) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </Spin>
            </div>
        </>
    )
}

const RelateJobsWithForm = Form.create<Props>({ name: 'JobForm' })(RelateJob)

export default connect(mapStateToProps,
    {
        getAllJob, addJob
    })(RelateJobsWithForm)
