import React from 'react'
import { Col, Row, Tag, Tooltip } from 'antd'
import { ticketRelated } from './model'
import { Link } from 'react-router-dom'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import { waitingGetSessions } from '../../guard/route'

interface Params {
    ticket: ticketRelated
    isTanentFreshService?: boolean
}

type Props = Params

const RenderTicketRelateOther: React.FC<Props> = (props: Props) => {
    return (
        <div key={props.ticket.id} className='TicketRelateCard'>
            <Row>
                <Col span={24}>
                    <span>Ticket Number : <Link to={!checkRolesState(RoleType.Incident, 'UpdateIncident') ? `/IncidentViewMoreDetails/${props.ticket.id}` : `/IncidentDetailsView/${props.ticket.id}`} target="_blank" onClick={() => waitingGetSessions()}>{props.ticket?.number}</Link>
                        <Tag style={{ backgroundColor: props.isTanentFreshService && props.ticket?.freshserviceTicketPriority !== null ? props.ticket?.freshserviceTicketPriority?.tagColor : props.ticket?.priority?.tagColor, color: '#fff', marginLeft: 10 }}>{props.isTanentFreshService && props.ticket?.freshserviceTicketPriority !== null ? props.ticket?.freshserviceTicketPriority?.name : props.ticket?.priority?.name}</Tag>
                        {props.ticket.relateType === 'parent' ? <Tooltip placement='bottom' title={'Parent of this ticket'}>
                            <Tag style={{ color: '#fff', backgroundColor: '#EB5757' }}>Parent</Tag>
                        </Tooltip> : <Tooltip placement='bottom' title={'Child of this ticket'}>
                            <Tag style={{ color: '#fff', backgroundColor: '#4DC977' }}>Child</Tag>
                        </Tooltip>}
                    </span>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <span>Topic : {props.ticket?.topic}</span>
                </Col>
                <Col span={6}>
                    <span>Status : {props.ticket?.status}</span>
                </Col>
                <Col span={6}>
                    <span>Requester : {props.ticket?.requester}</span>
                </Col>
            </Row>
        </div>
    )
}

export default RenderTicketRelateOther