import React, { useEffect, useState } from 'react'
import { FormComponentProps } from 'antd/es/form'
import moment from 'moment'
import { Button, Col, DatePicker, Icon, message, Row, Select, Spin } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { getExportWOReport, getPreviewWOReport } from '../../../msp-portal'
import { ColumnNormal } from '../../../ReportComponent/components/Model'
import { convertCamelCaseToSentenceCase } from '../../../knowledge-management/util'
import { checkRolesState, RoleType } from '../../../authorization-module/permissions'
import PageWoService from './PageWOService'
import ColumnWOReport from './ColumnWOReport'
import ColumnWORelateJob from './ColumnWORelateJob'
import ColumnWORelateAsset from './ColumnWORelateAsset'
import { WorkFlow } from '../../../serviceCatalog/workflow/Service/Model'
import { getAllWorkflowNoRedux } from '../../../serviceCatalog/workflow/Service/service'
import { findCustomFieldWOByWorkflowID } from '..'

interface Param {
    name: string
    form: WrappedFormUtils<any>
}

type Props = Param & FormComponentProps
const { RangePicker } = DatePicker
const { Option } = Select
const dateFormat = ('YYYY-MM-DD')
const CommonPanalWOReport: React.FC<Props> = (props: Props) => {
    const [range, setRange] = useState<any[]>()
    const [columnsNewObjList, setColumnsNewObjList] = useState<ColumnNormal[]>([])
    const [columnsNewList, setColumnsNewList] = useState<string[]>([])
    const [, setisFetchingFirst] = useState<boolean>(false)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingExport, setLoadingExport] = useState<boolean>(false)
    const [tempNewState, setTempNewState] = useState<any[]>([])
    const [dataWorkflow, setDataWorkflow] = useState<WorkFlow[]>([])
    const [selectWorkflow, setSelectWorkflow] = useState<string[]>([])

    useEffect(() => {
        let tempData: WorkFlow[] = []
        getAllWorkflowNoRedux().then((res) => {
            if (res) {
                const filterActivated = res.filter((it) => { return it.activated === true })
                tempData = filterActivated
                setDataWorkflow(tempData)
            }
        })
    }, [])

    const handleChangeRangePicker = (range: any) => {
        if (range && range.length !== 0) {
            setRange(range)
        } else {
            setColumnsNewList([])
            setColumnsNewObjList([])
            setisFetchingFirst(false)
            setRange(undefined)
            setTotal(0)
        }
    }

    const HandelChangeColumnsNewWOReport = (e) => {
        setColumnsNewList(e)
        sessionStorage.setItem(props.name, e)
    }

    const handleOnClickPreviewWOReport = () => {
        if (range) {
            const startData = range[0].format(dateFormat)
            const endDate = range[1].format(dateFormat)
            if (props.name.toLowerCase() === 'workorder') {
                fetchData(startData, endDate, PageWoService.getInstance().data.pageWo, PageWoService.getInstance().data.pageSizeWo, PageWoService.getInstance().data.sortByWo, PageWoService.getInstance().data.orderByWo, columnsNewList, selectWorkflow)
            } else if (props.name.toLowerCase() === 'relatedjob') {
                fetchData(startData, endDate, PageWoService.getInstance().data.pageWoJob, PageWoService.getInstance().data.pageSizeWoJob, PageWoService.getInstance().data.sortByJob, PageWoService.getInstance().data.orderByJob, columnsNewList)
            } else if (props.name.toLowerCase() === 'relatedasset') {
                fetchData(startData, endDate, PageWoService.getInstance().data.pageWoAsset, PageWoService.getInstance().data.pageSizeWoAsset, PageWoService.getInstance().data.sortByAsset, PageWoService.getInstance().data.orderByAsset, columnsNewList)
            }
        } else {
            setColumnsNewList([])
            setColumnsNewObjList([])
            setisFetchingFirst(false)
            setRange(undefined)
        }
    }

    const exportNewWOReportData = () => {
        if (range) {
            setLoadingExport(true)
            const startData = moment(range[0]).format(dateFormat)
            const endDate = moment(range[1]).format(dateFormat)
            getExportWOReport(startData, endDate, props.name, columnsNewList, props.name === 'workorder' ? selectWorkflow : []).then((data) => {
                console.log('success')
            }).finally(() => setLoadingExport(false))
        }
    }

    const getTableHeader = (data) => {
        const columnsExportNewAsset: ColumnNormal[] = []
        if (data.length > 0) {
            for (const keyName of data) {
                columnsExportNewAsset.push(
                    {
                        title: convertCamelCaseToSentenceCase(keyName),
                        dataIndex: keyName
                    }
                )
            }
            // data.map((keyName) => {
            //     columnsExportNewAsset.push(
            //         {
            //             title: convertCamelCaseToSentenceCase(keyName),
            //             dataIndex: keyName
            //         }
            //     )
            // })
        }
        return columnsExportNewAsset
    }

    const fetchData = (startDate: string, endDate: string, page: number, pageSize: number, sortBy: string, orderBy: string, selectedColumn: string[], selectWorkflow?: string[]) => {
        setLoading(true)
        getPreviewWOReport(startDate, endDate, props.name, page, pageSize, sortBy, orderBy, selectedColumn, selectWorkflow).then((res) => {
            const assetHeader = getTableHeader(res.header)
            if (res.data.length !== 0) {
                setTotal(res.total)
                setLoading(false)
                if (props.name === 'relatedAsset') {
                    // generate unique key for wo ralate asset report
                    res.data.map((it, idx) => {
                        it.id = idx
                        return it
                    })
                }
                setTempNewState(res.data)
                if (!setisFetchingFirst || columnsNewList.length === 0) {
                    setisFetchingFirst(true)
                    if (sessionStorage.getItem(props.name)) {
                        const newWOReport = sessionStorage.getItem(props.name)?.split(',')
                        setColumnsNewList(newWOReport || [])
                    } else {
                        setColumnsNewList(assetHeader.map((keyName) => keyName.dataIndex))
                    }
                }
                setColumnsNewObjList(assetHeader)
                const newWOReportInSesion = sessionStorage.getItem(props.name)?.split(',')
                if (newWOReportInSesion) {
                    if (newWOReportInSesion[0] !== '') {
                        const obj = {}
                        obj['ColumnWOReports' + props.name] = sessionStorage.getItem(props.name)?.split(',')
                        props.form.setFieldsValue(obj)
                    } else {
                        const obj2 = {}
                        obj2['ColumnWOReports' + props.name] = assetHeader.map((keyName) => keyName.dataIndex)
                        props.form.setFieldsValue(obj2)
                    }
                } else {
                    const obj3 = {}
                    obj3['ColumnWOReports' + props.name] = assetHeader.map((keyName) => keyName.dataIndex)
                    props.form.setFieldsValue(obj3)
                }
            } else {
                setTotal(res.total)
                message.warning('Data not found.')
                setLoading(false)
                if (!setisFetchingFirst || columnsNewList.length === 0) {
                    setisFetchingFirst(true)
                    if (sessionStorage.getItem(props.name)) {
                        const newWOReport = sessionStorage.getItem(props.name)?.split(',')
                        setColumnsNewList(newWOReport || [])
                    } else {
                        setColumnsNewList(assetHeader.map((keyName) => keyName.dataIndex))
                    }
                }
                setColumnsNewObjList(assetHeader)
            }
        }).catch(() => {
            setTempNewState([])
        }).finally(() => setLoading(false))
    }

    const disabledTime = (current) => {
        // Disable times before 10:00 AM and after 6:00 PM
        return current && current > moment().endOf('day')
    }

    const onChangeWorkflowName = (value) => {
        if (value.length > 5) {
            return
        } else if (value.length === 0) {
            setSelectWorkflow([])
            setColumnsNewList([])
            setColumnsNewObjList([])
            setRange(undefined)
            props.form.setFieldsValue({
                RangePickerReportsworkorder: undefined
            })
        } else {
            setSelectWorkflow(value)
        }
    }

    const onSelectWorkflowName = (value) => {
        if (selectWorkflow.length > 0 && columnsNewList.length > 0 && value) {
            findCustomFieldWOByWorkflowID(value).then((res) => {
                if (res) {
                    res.forEach((data) => {
                        let formattedDataName = data.name
                        if (formattedDataName.includes('_')) {
                            formattedDataName = formattedDataName.replace(/_/g, ' ')
                        }
                        const findIdWorkflow = data.workflows.find((it) => it.id === Number(value))
                        const tempData =  formattedDataName + ` (${findIdWorkflow?.name})`
                        columnsNewList.push(tempData)
                    })
                }
            })
        }
    }

    const onDeselectWorkflow = (value) => {
        if (selectWorkflow.length > 0 && columnsNewList.length > 0 && value) {
            const findWorkflowName = dataWorkflow.find((it) => it.id === Number(value))
            setColumnsNewList((prevColumn) => prevColumn.filter(it => !it.includes(findWorkflowName?.name!!)))
            setColumnsNewObjList((prevObj) => prevObj.filter(it => !it.title.includes(findWorkflowName?.name!!)))
        }
    }

    return (
        <Row gutter={[16, 16]}>
            {props.name === 'workorder'
                ? <Col md={4} sm={24}>
                    <p>Workflow Name</p>
                    {props.form.getFieldDecorator('workflowName', {
                        rules: [{ required: true, message: 'Please input your workflow name!', max: 5 }]
                    })(
                        <Select
                            mode='tags'
                            maxTagCount={2}
                            placeholder="Select Workflow Name"
                            style={{ width: '100%' }}
                            onChange={onChangeWorkflowName}
                            onSelect={onSelectWorkflowName}
                            onDeselect={onDeselectWorkflow}
                            value={selectWorkflow}
                            allowClear
                        >
                            {dataWorkflow.map((data, index) => {
                                return <Option
                                    value={String(data.id)}
                                    key={index}
                                    disabled={selectWorkflow.length === 5 ? !selectWorkflow.includes(String(data.id)) : undefined}
                                >
                                    {data.name}
                                </Option>
                            })}
                        </Select>
                    )}
                </Col>
                : null
            }
            {props.name === 'workorder'
                ?
                <Col md={6} sm={24}>
                    <p>Created Date</p>
                    {props.form.getFieldDecorator('RangePickerReports' + props.name, {
                        rules: [{ required: true, message: 'Please Select Date!'}]
                    })(
                        <RangePicker className='selectDate-woReport' onChange={handleChangeRangePicker} placeholder={['Start Date', 'End Date']}
                        disabled={!checkRolesState(RoleType.WO, 'ViewWorkOrder') || selectWorkflow.length === 0}
                        disabledDate={disabledTime} />
                    )}
                </Col>
                :
                <Col md={6} sm={24}>
                    <p>Created Date</p>
                    <RangePicker onChange={handleChangeRangePicker} placeholder={['Start Date', 'End Date']}
                        disabled={!checkRolesState(RoleType.WO, 'ViewWorkOrder')}
                        disabledDate={disabledTime} />
                </Col>
            }
            {
                columnsNewObjList.length !== 0
                    ? <>
                        <Col md={6} sm={24}>
                            <p>Selected Column</p>
                            {props.form.getFieldDecorator('ColumnWOReports' + props.name, {
                                initialValue: columnsNewList,
                                rules: [{ required: false, message: 'Please enter your Activity.' }]
                            })(<Select mode="tags"
                                style={{ width: '100%' }}
                                placeholder="Show"
                                maxTagCount={3}
                                // defaultValue={columnsNewList}
                                onChange={(e) => HandelChangeColumnsNewWOReport(e)}
                                allowClear={true}
                                loading={loading}
                            >
                                {(columnsNewObjList || []).map((it, index) => {
                                    return <Option key={index}
                                        value={it.dataIndex}>{it.title}</Option>
                                })}
                            </Select>)}
                        </Col>
                        <Col md={2} sm={12} xs={12}>
                            <Button htmlType="button" style={{ marginTop: 32 }}
                                onClick={() => handleOnClickPreviewWOReport()}
                                loading={loading}
                                type='primary'
                            ><Icon type="eye" />Preview</Button>
                        </Col>
                    </>
                    : (<>
                        <Col md={2} sm={12} xs={12}>
                            <Button htmlType="button" style={{ marginTop: 32 }}
                                onClick={() => handleOnClickPreviewWOReport()}
                                loading={loading}
                                type='primary'
                            ><Icon type="eye" /> Preview</Button>
                        </Col></>
                    )
            }
            <Col md={props.name === 'workorder' ? 6 : 10} sm={12} style={{ textAlign: 'end' }} xs={12}>
                {columnsNewObjList.length > 0
                    ? <Button htmlType="button" style={{ marginTop: 32 }}
                        onClick={() => exportNewWOReportData()}
                        disabled={!checkRolesState(RoleType.WO, 'ViewWorkOrder') || columnsNewList.length === 0}
                        loading={loadingExport}
                    ><Icon type="upload" /> Export</Button> : null}
            </Col>
            <Col span={24}>
                <Spin tip="Loading..." spinning={loading}>
                    {props.name === 'workorder' ? <ColumnWOReport dataTransfer={tempNewState}
                        columnsWOReportList={columnsNewList}
                        columnsWOReportObjList={columnsNewObjList}
                        total={total} handleWOReportChange={handleOnClickPreviewWOReport}
                        range={range}
                    /> : null}
                    {props.name === 'relatedJob' ? <ColumnWORelateJob dataTransfer={tempNewState}
                        columnsRelateJobList={columnsNewList}
                        columnsRelateJobObjList={columnsNewObjList}
                        total={total} handleRelateJobChange={handleOnClickPreviewWOReport}
                        range={range}
                    /> : null}
                    {props.name === 'relatedAsset' ? <ColumnWORelateAsset dataTransfer={tempNewState}
                        columnsRelateAssetList={columnsNewList}
                        columnsRelateAssetObjList={columnsNewObjList}
                        total={total} handleRelateAssetChange={handleOnClickPreviewWOReport}
                        range={range}
                    /> : null}
                </Spin>
            </Col>
        </Row>
    )
}

export default CommonPanalWOReport
