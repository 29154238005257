import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Empty, message, Row, Tooltip, Form } from 'antd'
import HorizontalBarChart from './Chart/HorizontalBarChart'
import DoughnutChart from './Chart/DoughnutChart'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { StoreState } from '../store'
import { DataForChart, DataTotal } from './model'
import { ConvertDataForChart, getEmailSatisfactionNotDone } from './service'
// import { sendSurvey } from '../incident-management/incident/service'
import { FormComponentProps } from 'antd/es/form'
import { waitingGetSessions } from '../guard/route'
import { sentEmailSatisfactionNotDone } from '../incident-management/incident/service'

const mapStateToProps = (state: StoreState) => {
    return {
        DashboardResponded: state.DashboardResponded,
        DashboardRemainder: state.DashboardRemainder,
        EmailSatNoneDone: state.EmailSatNotDoneState
    }
}

interface DispatchProps {
    getEmailSatisfactionNotDone: (create, start, end) => Promise<DataTotal>

}

type StateProps = ReturnType<typeof mapStateToProps>

interface Param {
    // Group?: string,
    onCreate: string,
    onTime: string[]

}

type Props = Param & StateProps & DispatchProps & FormComponentProps

const SatLayoutChart: React.FC<Props> = (props: Props) => {
    const [dataDashboardResponded, setDataDashboardResponded] = useState<DataForChart>()
    const [dataDashboardRemainder, setDataDashboardRemainder] = useState<DataForChart>()
    const [isDisable, setIsDisable] = useState(false)
    // const [dataEmailSatNoneDone, setEmailSatNoneDone] = useState<DataTotal>()
    const [isSending, setIsSending] = useState<boolean>(false)
    const colorResponded = ['#4DC977', '#ED9E28', '#EB5757']
    const colorRemainder = ['#4BC0C0', '#36A2EB']

    useEffect(() => {
        setDataDashboardResponded(ConvertDataForChart(props.DashboardResponded.data, colorResponded))
        setDataDashboardRemainder(ConvertDataForChart(props.DashboardRemainder.data, colorRemainder))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    useEffect(() => {
        props.getEmailSatisfactionNotDone(props.onCreate, props.onTime[0], props.onTime[1]).catch((err) => message.error(`Unable to retrieve all statuses. Please try again later. ${err}`))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.getEmailSatisfactionNotDone])

    useEffect(() => {
        if (props.EmailSatNoneDone) {
            if (props.EmailSatNoneDone?.data?.length === 0) {
                setIsDisable(true)
            } else {
                setIsDisable(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.EmailSatNoneDone])

    const sendEmail = () => {
        sentEmailSatisfactionNotDone(props.onCreate, props.onTime[0], props.onTime[1]).then(() => {
            setIsSending(false)
            message.success('The email has been sent successfully.')
        }).catch((err) => {
            console.log(err)
        })
        // const promiseAll: Promise<void>[] = []
        // let checkSendMessage:boolean = false
        // Promise.all(promiseAll).finally(() => {
        //     for (const value of props.EmailSatNoneDone.data) {
        //         setIsSending(true)
        //         // เดี๋ยวแก้อีก
        //         sendSurvey(value.email, value.incidentId)
        //             .then(() => {
        //                 setIsSending(false)
        //                 // message.success(`Email has been ${props.DashboardRemainder.data ? props.DashboardRemainder.data['Waiting for Respond'] : 0 } ticket successfully sent`)
        //             })
        //             .catch((err) => {
        //                 message.error(`Fail to send email ${err}`)
        //             })
        //         checkSendMessage = true
        //     }

        //     if (checkSendMessage) {
        //         message.success(`Email has been ${props.DashboardRemainder.data ? props.DashboardRemainder.data['Waiting for Respond'] : 0} ticket successfully sent`)
        //         checkSendMessage = false
        //     }
        // })
    }

    return (
        <div>
            <Row className="TopicDashboard">
                <Col span={24}>
                    <span className="main-title">Customer Satisfaction</span>
                </Col>
            </Row>
            <br />
            <Row style={{ height: 'auto' }} gutter={8}>
                <Col span={16} xs={24} sm={24} md={24} lg={15}>
                    <Row style={{ marginBottom: '2%' }}>
                        <Link to="/ReportSatDashBoard" target="_blank" onClick={() => waitingGetSessions()}>
                            <Card className="Box-DashBoard">
                                <span className="sub-title">Responded ({props.DashboardResponded.total!!} Tickets / {props.DashboardRemainder.total!!} Tickets)</span>
                                {props.DashboardResponded.total!! === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <HorizontalBarChart myData={dataDashboardResponded} />}
                            </Card>
                        </Link>
                    </Row>
                </Col>
                <Col span={8} xs={24} sm={24} md={24} lg={9} style={{ marginBottom: '2%' }}>
                    <Card className="Box-DashBoard" >
                        <span className="sub-title">Number of Resolved Ticket ({props.DashboardRemainder.total!!} Tickets)</span>
                        <Button type="primary" style={{ float: 'right' }} onClick={() => sendEmail()} loading={isSending} disabled={isDisable}>Resend</Button>
                        <Link to="/ReportSatDashBoard" target="_blank" onClick={() => waitingGetSessions()}>
                            <Col sm={14}><DoughnutChart showLabels={false} Height={230} marginTop={'10%'} minHeight={250} myData={dataDashboardRemainder} /></Col>
                            <Col sm={10}>
                                <Row style={{ minHeight: 90, marginTop: window.innerWidth <= 576 ? '20%' : '70%' }} gutter={8}>
                                    {dataDashboardRemainder?.labels.map((it, index) => {
                                        return (
                                            <Col span={24} style={{ textAlign: 'left' }} key={index}>
                                                {index < 10 ? <>
                                                    <span style={{ backgroundColor: colorRemainder[index], marginRight: '5%', maxHeight: 5 }}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    <Tooltip title={it}>
                                                        {it.length > 20 ? it.substr(0, 20) + '...' : it}
                                                    </Tooltip>

                                                </> : null}
                                            </Col>
                                        )
                                    })
                                    }
                                </Row>
                            </Col>
                        </Link>
                    </Card>
                </Col>
            </Row>
            <br />
        </div>
    )
}
const wrappedForm = Form.create<Props>()(SatLayoutChart)
export default connect(
    mapStateToProps,
    {
        getEmailSatisfactionNotDone

    }
)(wrappedForm)
