import React, {useEffect, useState} from 'react'
import TableSatisfaction from './TableSatisfaction'
import {Button, Col, DatePicker, Form, Icon, Row, Select, Layout, Modal, message} from 'antd'
import {FormComponentProps} from 'antd/es/form'
import {getSatisfactionExport} from './service'
import {connect} from 'react-redux'
import {StoreState} from '../store'
import XLSX from 'xlsx'
import { fixWidthExcel } from '../common-misc'

const mapStateToProps = (state: StoreState) => {
    return {
        SatisfactionReportState: state.satisfactionReport,
        SatisfactionExportState: state.SatisfactionExport
    }
}

interface DispatchProps {
    getSatisfactionExport: (created?, startDate?, endDate?, sortBy?, orderBy?, skip?, limit?) => Promise<number>
    getSatisfactionReport: (created?, startDate?, endDate?, sortBy?, orderBy?, skip?, limit?) => Promise<number>
}

type StateProps = ReturnType<typeof mapStateToProps>
const { Header, Content } = Layout
const { confirm } = Modal
type Props = FormComponentProps & StateProps & DispatchProps
const { Option } = Select
const { RangePicker } = DatePicker
const ReportSatDashBoard: React.FC<Props> = (props: Props) => {
    const [created, setCreated] = useState<string>('last30Days')
    const [time, setTime] = useState<string[]>([])
    const [, setOrderBy] = useState<string>('desc')
    const [, setSortBy] = useState<string>('ticketNumber')
    const [limit, setLimit] = useState<number>()
    const [loading, setLoading] = useState<boolean>(false)
    const { getFieldDecorator } = props.form

    // useEffect(() => {
    //     setTimeout(() => {
    //         localStorage.clear()
    //     }, 1000)
    // }, [])

    useEffect(() => {
        setLimit(props.SatisfactionReportState.total!!)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.SatisfactionReportState.total])

    useEffect(() => {
        props.getSatisfactionExport(created, time[0], time[1], 'ticketNumber', 'desc', (0) * 10, limit)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.getSatisfactionExport, created, time, limit])

    const exportExcel = () => {
        setLoading(true)
        if (props.SatisfactionExportState.data.length !== 0) {
            const temp: any = []
            message.info('Generating report, please wait...')
            props.SatisfactionExportState.data.forEach((res) => {
                if (res.satisfactionDate) {
                    res.satisfactionDate = res.satisfactionDate.split('T')
                    res.satisfactionDate = res.satisfactionDate[0] + ' ' + res.satisfactionDate[1]
                }
                res.createDate = res.createDate.split('T')
                res.createDate = res.createDate[0] + ' ' + res.createDate[1]
                delete res.incidentId
                temp.push(res)
            })
            const ws1 = XLSX.utils.json_to_sheet(temp)
            ws1['!cols'] = fixWidthExcel(temp, ws1)
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws1, 'CustomerSatisfaction')
            XLSX.writeFile(wb, 'Customer_satisfaction.xlsx')
            setLoading(false)
        } else {
            message.warn('No tickets match this filter. Please adjust the filter.')
            setLoading(false)
        }
    }

    const changeCreated = (e) => {
        if (e !== 'anytime') {
            setTime([])
        }
        setCreated(e)
    }

    const changeTime = (value, dateString) => {
        setTime(dateString)
    }

    const getChangeTable = (sortBy, orderBy, skip, limit) => {
        if (sortBy) {
            setSortBy(sortBy)
        }
        if (orderBy) {
            setOrderBy(orderBy)
        }
        if (limit) {
            setLimit(limit)
        }
    }

    const leavePageModal = () => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you want to close this page ?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                window.close()
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    return (
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 2, width: '100%', backgroundColor: '#fff', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                <Row>
                    <Col span={12}>
                        <div>
                            <h3 style={{color: '#323276'}}>Customer Satisfaction Report</h3>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div style={{ textAlign: 'end'}}>
                            <Icon type="close-square" theme="filled" style={{color: '#FA541C', fontSize: 24}} onClick={() => leavePageModal()} />
                        </div>
                    </Col>
                </Row>
            </Header>
            <Content style={{ padding: '0 50px', marginTop: 64, backgroundColor: '#fff' }}>
                <br/>
                <Row gutter={16} >
                    <Col xs={24} sm={24} md={20} lg={20} style={{ textAlign: 'left' }}>
                        <Form>
                            <Row >
                                {/* <Col xs={24} sm={24} md={18} lg={20}/> */}
                                <Col xs={24} sm={24} md={3} lg={4}>
                                    <span>Created :</span>
                                    <Form.Item >
                                        {getFieldDecorator('Created', {
                                            initialValue: created
                                        })(
                                            <Select onChange={(e) => changeCreated(e)}>
                                                <Option value="last30Days">Last 30 days</Option>
                                                <Option value="today">Today</Option>
                                                <Option value="yesterday">Yesterday</Option>
                                                <Option value="thisWeek">This Week</Option>
                                                <Option value="thisMonth">This Month</Option>
                                                <Option value="anytime">Select Time Period</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    {created === 'anytime' ? (<>
                                        <Form.Item >
                                            {getFieldDecorator('TimePeriod')(
                                                <RangePicker
                                                    // showTime={{ format: 'HH:mm' }}
                                                    onChange={changeTime}
                                                    format="YYYY-MM-DD"
                                                    placeholder={['Start Time', 'End Time']}
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                />
                                            )}
                                        </Form.Item>
                                    </>) : null}
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} style={{ textAlign: 'right' }}>
                        <Button loading={loading} className="addCriteriaButton" style={{ marginTop: 25 }} onClick={() => exportExcel()} htmlType="button" > {!loading ? <Icon type="upload" /> : null}Export</Button>
                    </Col>
                </Row>
                <br />
                <Row className="TopicDashboard">
                    <Col span={24}>
                        <span className="main-title">Customer Satisfaction Report</span>
                    </Col>
                </Row>
                <br />
                <Row className='fix_scroll_md'>
                    <TableSatisfaction create={created} start={time[0]} end={time[1]} getChangeTable={getChangeTable} />
                </Row>
            </Content>

        </Layout>
    )
}

const WrappedNormalReportSatDashBoard = Form.create({ name: 'satReport' })(ReportSatDashBoard)

export default connect(mapStateToProps, { getSatisfactionExport })(WrappedNormalReportSatDashBoard)
