import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Select, message } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { StoreState } from '../../store'
import { SelfServiceCatalog, SelfServiceCatagory, SearchFilterCategory } from '../Catalog/Model'
import { getallCatalog } from '../Catalog/service'
import { connect } from 'react-redux'

interface Params {
    setOpenFilter: Function
    setSearchFilter: Function
    parents?: SelfServiceCatagory[]
    catalog?: SelfServiceCatalog
    category?: SelfServiceCatagory
    isVisible?: boolean
    setPage?: Function
    searchFilter?: SearchFilterCategory
}

const mapStateToProps = (state: StoreState) => {
    return {
        selfServiceCatalogs: state.selfServiceCatalogs,
        selfServiceCatagories: state.selfServiceCatagories
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getallCatalog: () => Promise<number>
}

type Props = FormComponentProps & Params & DispatchProps & StateProps
const { Option } = Select
const FilterCategory: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue } = props.form
    const [parent, setParent] = useState(props.parents)
    // const pageSize = 10
    // const [page, setPage] = useState<number>(1)
    // const [sortBy, setSortBy] = useState<string>('name')
    // const [orderBy, setOrderBy] = useState<string>('asc')
    // const [dataSource, setDataSource] = useState<ResponseContentDetails<SelfServiceCatagory[]>>()

    // useEffect(() => {
    //     resetFields(['parent.id'])
    // }, [props.form.getFieldValue('catalog.id')])

    useEffect(() => {
        // setLoading(true)
        if (props.selfServiceCatalogs.content.length === 0) {
            props.getallCatalog().catch(err => {
                message.error(err)
            }).finally(() => {
                // setLoading(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.isVisible) {
            setParent(props.parents)
            console.log(parent)
            // resetFields()
            if (props.searchFilter) {
                setFieldsValue({
                    'catalog.id': props.searchFilter.CatalogId || undefined,
                    status: props.searchFilter.Status || undefined,
                    'parent.id': props.searchFilter.ParentId || undefined
                })
            } else {
                setFieldsValue({
                    'catalog.id': undefined,
                    status: undefined,
                    'parent.id': undefined
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isVisible])

    useEffect(() => {
        if (props.selfServiceCatagories) {
            setParent(props.selfServiceCatagories.content.filter((category) => category.parent === null))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selfServiceCatagories])

    const handleSubmit = e => {
        e.preventDefault()
        let filtercatalogName: string[] = []
        let filterParentName: string[] = []
        props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values)

                if (props.setOpenFilter) {
                    props.setOpenFilter(false)
                }
                if (values.catalog.id) {
                    filtercatalogName = props.selfServiceCatalogs.content.filter((item) => values.catalog?.id.includes(item.id?.toString())).map((it) => it.name)
                }
                if (values.parent.id) {
                    filterParentName = props.selfServiceCatagories.content.filter((item) => values.parent?.id.includes(item.id?.toString())).map((it) => it.name)
                }
                // const catalogId = values.catalog.id
                // values.catalog = props.selfServiceCatalogs.content.find((cat) => {
                //     return Number(cat.id) === Number(catalogId)
                // })
                // const parentId = values.parent.id
                // values.parent = props.selfServiceCatagories.content.find((catgory) => {
                //     return Number(catgory.id) === Number(parentId)
                // })
                // console.log("Values :",values)
                // console.log(filtercatalogName);
                // console.log(filterParentName);
                const valuesFilter: SearchFilterCategory = {
                    CatalogName: JSON.stringify(filtercatalogName),
                    Parent: JSON.stringify(filterParentName),
                    Status: values.status,
                    CatalogId: values.catalog?.id,
                    ParentId: values.parent?.id
                }
                console.log('ValuesFilter :', valuesFilter)
                props.setSearchFilter(valuesFilter)
                // resetFields()
                if (props.setPage) {
                    props.setPage(1)
                }
            }
        })
    }

    const catalogsOption = () => {
        if (props.selfServiceCatalogs) {
            // console.log(props.selfServiceCatalogs.content)

            return props.selfServiceCatalogs.content.map((catalog) => {
                return (<Option key={catalog.id} value={catalog.id?.toString()}>{catalog.name}</Option>)
            })
        }
        return (<></>)
    }

    const parentOption = () => {
        return (props.selfServiceCatagories.content.filter((category) => category.parent === null).map((parents) => {
            return (<Option key={parents.id} value={parents.id?.toString()}>{parents.name}</Option>)
        }))
        // if (parent) {
        //     return parent.map((parents) => {
        //         return (<Option key={parents.id} value={parents.id?.toString()}>{parents.name}</Option>)
        //     })
        // }
    }

    // const filterParent = (value: string) => {
    //     // console.log(value)
    //     setParent(props.parents?.filter(parent => Number(parent.catalog.id).toString() === value.toString()))
    // }

    const handleCancel = () => {
        // resetFields()
        setParent(props.parents)
        props.setOpenFilter(false)
    }

    return (
        <>
            <Form onSubmit={handleSubmit} >
                <Form.Item label={'Catalog Name'}>
                    {getFieldDecorator('catalog.id', {
                        rules: [{ required: false, message: 'Please enter your username.' }]
                    })(
                        <Select
                            placeholder="Select Catalog Name"
                            // onSelect={(e) => filterParent(e as string) }
                            mode={'tags'}
                            allowClear
                        >
                            {catalogsOption()}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={'Status'}>
                    {getFieldDecorator('status')(
                        <Select
                            placeholder="Select Status"
                            mode={'tags'}
                            allowClear
                        >
                            <Option value="Published">Published</Option>
                            <Option value="Draft">Draft</Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={'Parent'}>
                    {getFieldDecorator('parent.id', {
                        rules: [{ required: false, message: 'Please enter the Parent Category.' }]
                    })(
                        <Select
                            placeholder="Select Parent"
                            mode={'tags'}
                            allowClear
                        >
                            {parentOption()}
                        </Select>

                    )}
                </Form.Item>
                <Col span={24} style={{ marginTop: 10 }}>
                    <Button type="primary" htmlType="submit" style={{ float: 'right', marginLeft: 15 }} >Submit</Button>
                    <Button style={{ float: 'right' }} onClick={() => handleCancel()}>Cancel</Button>
                </Col>
            </Form>
        </>
    )
}
const MyFilterCategory = Form.create<Props>({ name: 'FilterCategory' })(FilterCategory)

export default connect(mapStateToProps,
    { getallCatalog }
)(MyFilterCategory)
