/* eslint-disable quote-props */
import React, { useEffect, useState } from 'react'
import { Col, Form, Icon, message, Row, Modal, Spin } from 'antd'
import logo from '../../assets/images/LogoGoService.png'
import { useParams } from 'react-router-dom'
import { setSatisfaction } from './service'

interface Params {
    incidentId: string
    result: string
    data: string
}

type Props = Params

const SatisfactionDone: React.FC<Props> = (props: Props) => {
    const { incidentId, result, data } = useParams<Params>()
    const [isSuccess, setIsSuccess] = useState<boolean>(true)
    const [isFinish, setIsFinish] = useState<boolean>(false)

    useEffect(() => {
        function showConfirm() {
            Modal.confirm({
                title: 'Are you sure ?',
                content: (
                    <div>
                        You selected "{result}". Do you want to submit your feedback ?
                    </div>
                ),
                iconType: 'info-circle',
                okText: 'Confirm',
                cancelText: 'Cancel',
                okButtonProps: {
                    type: 'primary'
                },
                cancelButtonProps: {
                    type: 'default'
                },
                onOk() {
                    setSatisfaction(incidentId, data)
                        .catch((e: string) => {
                            message.error(e.toString())
                            setIsSuccess(false)
                        })
                        .finally(() => {
                            setIsFinish(true)
                        })
                },
                onCancel() {
                    window.close()
                }
            })
        }

        showConfirm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Row>
                <Col span={24} style={{ backgroundColor: '#323276', color: '#fff' }}><img style={{ margin: '10px' }} alt='logo' src={logo}
                    width={200} /></Col>
            </Row>
            <Row>
                <Col className="DoneLayout">
                    <Spin spinning={!isFinish} size="large" >
                        {isFinish ? isSuccess
                            ? <>
                                <Icon type="check-circle" theme="filled" style={{ fontSize: '50px', color: '#52C41A' }} />
                                <div>ท่านได้ทำการประเมินเรียบร้อยแล้ว</div>
                                <div>ขอบคุณสำหรับการประเมิน</div>
                            </>
                            : <>
                                <Icon type="close-circle" theme="filled" style={{ fontSize: '50px', color: '#EB5757' }} />
                                <div>Ticket นี้ได้ถูกทำไปแล้ว</div>
                                <div>ขอบคุณสำหรับการประเมิน</div>
                            </>
                            : null}
                    </Spin>
                </Col>
            </Row>

        </>
    )
}
const wrappedForm = Form.create({ name: 'SatisfactionDone' })(SatisfactionDone)
export default wrappedForm
