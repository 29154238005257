import React from 'react'
import { Checkbox, Col, DatePicker, Input, InputNumber, Radio, Row, Select, Tag } from 'antd'
import { FieldDetail, Incident } from './model'
import { PriorityLevel } from '../priority'
import moment from 'moment'
import { tokenDecode } from '../../common-misc'


interface Param {
    dataIncident: Incident
    customField?: FieldDetail[] | undefined
    priority: PriorityLevel[]
}

type Props = Param
const { Option } = Select
const idTenantFreshService = process.env.REACT_APP_ID_TENANT_FRESH_SERVICE
const ViewMoreDetailCustomField: React.FC<Props> = (props) => {
    const splitIdTenantFreshService = idTenantFreshService.split(',')
    const isTanentFreshService = splitIdTenantFreshService.includes(tokenDecode()?.tenantID.toString())
    return (
        <div style={{ maxHeight: '450px' ,overflowY: 'auto', overflowX: 'hidden' }}>
            <Row gutter={8}>
                <Col span={12} xs={24} sm={24} md={24} lg={12} style={{ textAlign: 'left' }}>
                    <p className={'sub-title'}>Priority</p>
                    {isTanentFreshService && props.dataIncident?.freshserviceTicketPriority !== null ? <>
                        {props.priority.map((priority: PriorityLevel, index) => {
                            return (
                                (props.dataIncident?.freshserviceTicketPriority?.priority?.name || props.dataIncident?.freshserviceTicketPriority?.name) === priority.name ? (
                                    <Tag style={{ backgroundColor: priority.tagColor, color: '#fff' }}
                                        key={index}>{(props.dataIncident?.freshserviceTicketPriority?.priority?.name || props.dataIncident?.freshserviceTicketPriority?.name)}</Tag>
                                ) : null
                            )
                        })}
                    </> : <>
                        {props.priority.map((value: PriorityLevel, index) => {
                            return (
                                props.dataIncident?.priority?.priority?.name === value.name ? (
                                    <Tag style={{ backgroundColor: value.tagColor, color: '#fff' }} key={index}>{props.dataIncident?.priority?.priority?.name}</Tag>
                                ) : null
                            )
                        })}
                    </>}
                </Col>
                <Col span={12} xs={24} sm={24} md={24} lg={12} style={{ textAlign: 'left' }}>
                    <p className={'sub-title'}>Status</p>
                    {props.dataIncident?.ticketStatus.status}
                </Col>
            </Row>
            <Row gutter={8} style={{ marginTop: '10px' }}>
                <Col span={12} xs={24} sm={24} md={24} lg={12} style={{ textAlign: 'left' }}>
                    <p className={'sub-title'}>Impact</p>
                    <span>{props.dataIncident?.priority?.impact?.name}</span>
                </Col>
                <Col span={12} xs={24} sm={24} md={24} lg={12} style={{ textAlign: 'left' }}>
                    <p className={'sub-title'}>Urgency</p>
                    <span>{props.dataIncident?.priority?.urgency?.name}</span>
                </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
                <Col span={24} xs={24} sm={24} md={24} lg={24}>
                    <p className={'sub-title'}>Assignemnt Group</p>
                    <span>{props.dataIncident?.supportTeam?.name || '-'}</span>
                </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
                <Col span={24} xs={24} sm={24} md={24} lg={24}>
                    <p className={'sub-title'}>Assigned To</p>
                    <span>{props.dataIncident?.user?.fullName || '-'}</span>
                </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
                <Col span={24} xs={24} sm={24} md={24} lg={24}>
                    <p className={'sub-title'}>Category</p>
                    <span>{props.dataIncident?.category || '-'}</span>
                </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
                <Col span={24} xs={24} sm={24} md={24} lg={24}>
                    <p className={'sub-title'}>Subcategory</p>
                    <span>{props.dataIncident?.subcategory || '-'}</span>
                </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
                <Col span={24} xs={24} sm={24} md={24} lg={24}>
                    <p className={'sub-title'}>Item</p>
                    <span>{props.dataIncident?.item || '-'}</span>
                </Col>
            </Row>
            {(props.customField || []).map((obj: FieldDetail, index) => {
                if (obj) {
                    return (
                        <Row key={index}>
                            <Col span={24} lg={24} md={24} sm={24} xs={24} key={index}>
                                {obj.inputType === 'Checkbox' ? (
                                    <>
                                        <p className={'sub-title'}>{obj.label}</p>
                                        <Checkbox.Group value={obj.value || obj.defaultValue} style={{ width: '100%' }} disabled={true}>
                                            <Row>
                                                {
                                                    obj.selectOption?.map((it, i) => {
                                                        return <Col span={12} key={i}>
                                                            <Checkbox value={it.option}>{it.option}</Checkbox>
                                                        </Col>
                                                    })
                                                }
                                            </Row>
                                        </Checkbox.Group>
                                    </>
                                ) : null}
                                {obj.inputType === 'DatePicker' ? (
                                    <>
                                        <p className={'sub-title'}>{obj.label}</p>
                                        <DatePicker
                                            value={obj.value || obj.defaultValue ? moment(obj.value) || moment(obj.defaultValue as string) : undefined}
                                            style={{ minWidth: 'max-content', width: '100%', maxWidth: '250px' }}
                                            showTime={obj.dataType === 'DateTime'}
                                            disabled={true}
                                        />
                                    </>
                                ) : null}
                                {obj.inputType === 'DropdownSelect' ? (
                                    <>
                                        <p className={'sub-title'}>{obj.label}</p>
                                        <Select style={{ minWidth: '250px', maxWidth: '250px' }}
                                            placeholder={obj.placeholder as string || undefined}
                                            value={obj.value || obj.defaultValue || '-'}
                                            disabled={true}
                                            allowClear
                                        >
                                            {
                                                obj.selectOption?.map((it, i) => {
                                                    return <Option value={it.option} key={i}>{it.option}</Option>
                                                })
                                            }
                                        </Select>
                                    </>
                                ) : null}
                                {obj.inputType === 'InputNumber' ? (
                                    <>
                                        <p className={'sub-title'}>{obj.label}</p>
                                        <InputNumber min={0} style={{ minWidth: 'max-content', maxWidth: '250px' }}
                                            disabled={true}
                                            value={obj.value || obj.defaultValue || 0}
                                            placeholder={obj.placeholder as string || undefined}
                                        />
                                    </>
                                ) : null}
                                {obj.inputType === 'RadioButton' ? (
                                    <>
                                        <p className={'sub-title'}>{obj.label}</p>
                                        <Radio.Group value={obj.value || obj.defaultValue} disabled={true}>
                                            {
                                                obj.selectOption?.map((it, i) => {
                                                    return <Radio value={it.option} key={i}>{it.option}</Radio>
                                                })
                                            }
                                        </Radio.Group>
                                    </>
                                ) : null}
                                {obj.inputType === 'TextBox' ? (
                                    <>
                                        <p className={'sub-title'}>{obj.label}</p>
                                        <Input
                                            value={obj.value || obj.defaultValue || '-'}
                                            style={{ minWidth: 'max-content', maxWidth: '250px' }}
                                            placeholder={obj.placeholder as string || undefined}
                                            disabled={true}
                                        />
                                    </>
                                ) : null}
                            </Col>
                        </Row>) 
                } else {
                    return <></>
                }
            })}
        </div>
    )
}

export default ViewMoreDetailCustomField