import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Comment, Form, Button, List, Input, Icon, Tooltip } from 'antd'
import moment from 'moment'
import { FormComponentProps } from 'antd/lib/form'

import { CommentResponseModel, CommentComponentModel, getCommentByKnowledgeId, addComment, likeComment, LikeType } from '.'
import AutoAvatar from '../../../common-components/autoAvatar'

import ReplyForm from './ReplyForm'
import { UserProfile } from '../../../authorization-module/user-role/duck/model'
import { getUserProfile } from '../../../authorization-module/user-role/duck/action'

const { TextArea } = Input

interface Params {
    id: string
}

const CommentList: React.FC<FormComponentProps> = (props: FormComponentProps) => {
    const [comments, setComments] = useState<CommentComponentModel[]>([])
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [commentsValue, setCommentsValue] = useState<string>()
    const [replying, setReplying] = useState<boolean>(false)
    const [replyId, setReplyId] = useState<string>()
    const { id } = useParams<Params>()
    const username = sessionStorage.getItem('username')
    const [dataUser, setDataUser] = useState<UserProfile>()
    const { getFieldDecorator } = props.form

    useEffect(() => {
        getAllComment()
        getUserProfile().then((res) => {
            if (res) {
                setDataUser(res)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllComment = () => {
        getCommentByKnowledgeId(id).then(comments => {
            const commentsComponent = comments.map(commentResponse => {
                console.log(commentResponse)
                return commentComponent(commentResponse)
            })
            console.log(commentsComponent)
            setComments(commentsComponent)
        })
    }

    const commentComponent = (commentResponse: CommentResponseModel) => {
        return {
            id: commentResponse.id,
            author: commentResponse.createdByDisplay || commentResponse.createdBy || '',
            replyByTooltip: commentResponse.createdBy,
            content: <p>{commentResponse.content}</p>,
            datetime: moment(commentResponse.createdDatetime).fromNow(),
            replies: commentResponse.replies,
            replyOf: commentResponse.replyOf,
            createdById: commentResponse.createdById,
            actions: [
                <span key="comment-basic-like">
                    <Tooltip title="Like">
                        <Icon
                            type="like"
                            theme={commentResponse.liked === 1 ? 'filled' : 'outlined'}
                            onClick={() => {
                                likeComment({
                                    type: LikeType.Comment,
                                    idOfType: commentResponse.id!!,
                                    username: username || '',
                                    value: 1,
                                    lastModifiedBy: username || '',
                                    createdBy: username || ''
                                }).then(() => {
                                    getAllComment()
                                }).catch(e => {
                                    console.error(e)
                                })
                            }}
                        />
                    </Tooltip>
                    <span style={{ paddingLeft: 8, cursor: 'auto' }}>{commentResponse.like || 0}</span>
                </span>,
                <span key={commentResponse.id} id={commentResponse.id} onClick={(e) => {
                    if (typeof e.target === 'object') {
                        const element: any = e.target
                        setReplying(true)
                        if (replyId === element.id) {
                            setReplying(false)
                        } else {
                            setReplyId(element.id)
                            setReplying(true)
                        }
                    }
                }}>Reply to</span>]
        }
    }

    const CommentList = () => (
        <List
            dataSource={comments}
            header={`${comments.length} ${comments.length > 1 ? 'comments' : 'comment'}`}
            itemLayout="horizontal"
            // eslint-disable-next-line react/prop-types
            renderItem={(props: CommentComponentModel) => <Row>
                <Comment {...props} avatar={<Tooltip title={props.replyByTooltip}><AutoAvatar title={props.createdById?.pictureProfile ? JSON.parse(props.createdById?.pictureProfile)[0].url : props?.author} broken={false} username={props.author?.toString()} /> </Tooltip>} >
                    {replyList(props)}
                    {replyForm(props)}
                </Comment>
            </Row>}
        />
    )

    const replyList = (props: CommentComponentModel) => {
        return props.replies?.map((reply, i) => {
            const commentComponent = {
                id: reply.id,
                author: reply.createdByDisplay || reply.createdBy || '',
                replyByTooltip: reply.createdBy,
                content: <p>{reply.content}</p>,
                datetime: moment(reply.createdDate).fromNow(),
                replyOf: reply.replyOf,
                createdById: reply?.createdById
            }
            return (<Comment key={i} {...commentComponent} avatar={<Tooltip title={reply.createdBy}><AutoAvatar title={commentComponent.createdById?.pictureProfile ? JSON.parse(commentComponent.createdById?.pictureProfile)[0].url : commentComponent?.author} broken={false} username={commentComponent.author} /> </Tooltip>} />)
        })
    }

    const replyForm = (props: CommentComponentModel) => {
        // console.log('replyId ', replyId)
        // console.log('props.id ', props.id)
        // console.log('!props.replyOf ', !props.replyOf)
        // console.log(replying && (replyId === props.id) && !props.replyOf)
        if (replying && replyId?.toString() === props.id?.toString() && !props.replyOf) {
            return (
                <ReplyForm knowledgeId={id} replyOf={replyId!!} getAllComment={getAllComment} />
            )
        }
    }

    const handleChange = (e: any) => {
        setCommentsValue(e.target.value)
    }

    const handleSubmit = () => {
        if (!commentsValue || commentsValue.length < 3) {
            return
        }
        setSubmitting(true)
        const commentBody: CommentResponseModel = {
            knowledgeId: id,
            content: commentsValue,
            lastModifiedBy: username || '',
            createdBy: username || ''
        }
        props.form.setFieldsValue({
            comment: undefined
        })
        addComment(commentBody).then(response => {
            setSubmitting(false)
            setComments([
                commentComponent(response),
                ...comments
            ])
            setCommentsValue('')
        })
    }

    return (
        <Row>
            <Comment
                avatar={
                    <AutoAvatar title={dataUser?.pictureProfile ? JSON.parse(dataUser?.pictureProfile)[0].url : sessionStorage.getItem('username') || ''} broken={false} username={sessionStorage.getItem('username')?.toString() || ''} />
                }
                content={
                    <div>
                        <Form.Item>
                            {getFieldDecorator('comment',
                                {
                                    rules: [{
                                        required: false
                                    }, { min: 3, message: 'Please enter more than 3 characters.' }
                                    ]
                                })(
                                <TextArea rows={4} onChange={handleChange} value={commentsValue} className={'ql-editor'} />
                            )}

                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" loading={submitting} onClick={handleSubmit} type="primary">
                                Add Comment
                            </Button>
                        </Form.Item>
                    </div>
                }
            />
            {comments.length > 0 && <CommentList />}
        </Row>
    )
}

const CommentListFrom = Form.create<FormComponentProps>()(CommentList)

export default CommentListFrom
