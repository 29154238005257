import React from 'react'
import { FormComponentProps } from 'antd/lib/form'
import { Col, Row, Select, Form, Icon } from 'antd'
import { woSettingColumn } from './model'

interface param {
    fieldsData: woSettingColumn
    columnKey: number
    otherHeader: woSettingColumn[]
    handleChangeField: Function
    handleAddField: Function
    handleRemoveField: Function
}

type Props = FormComponentProps & param
const { Option } = Select
const ModifyColumnWo: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator } = props.form

    return (
        <Row key={props.fieldsData.columnOrder} align='middle' style={{ display: 'flex', alignItems: 'center' }}>
            <Col span={20} xs={16} md={18}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Item
                        style={{ width: '100%' }}
                        required={false}
                    >
                        {getFieldDecorator('columnModify' + props.fieldsData.columnOrder, {
                            initialValue: props.fieldsData.columnLabel,
                            rules: [{ required: true, message: 'Field is required.'}]
                        })(
                            <Select
                                style={{ width: '100%', fontSize: '14px' }}
                                onChange={(value) => {
                                    const tempValue = props.otherHeader.find(option => option.columnLabel === value)
                                    props.handleChangeField(props.fieldsData.columnOrder, tempValue?.columnName)
                                }}
                            >
                                {props.otherHeader.map((it) => {
                                    return <Option value={it.columnLabel} key={it.columnOrder} label={it.columnLabel}>{it.columnLabel}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item>
                </div>
            </Col>
            <Col span={2} xs={4} md={3} style={{ padding: '4px', textAlign: 'center' }}>
                <Icon type='minus-square' onClick={() => props.handleRemoveField(props.fieldsData.columnOrder)} style={{ color: '#5A5A89', fontSize: '20px' }} />
            </Col>
            <Col span={2} xs={4} md={3} style={{ padding: '4px' }}>
                <Icon type='plus-square' onClick={() => props.handleAddField(props.columnKey)} style={{ color: '#5A5A89', fontSize: '20px' }} />
            </Col>
        </Row>
    )
}

export default ModifyColumnWo
