import React, { useState } from 'react'
import { IncidentWithSlaCalculations } from '../model'
import { Row, Col, Empty, Pagination, Spin } from 'antd'
// import { WorkflowRelatedContext } from '../../../Preventive/ContextAPI/WorkflowRelatedContext'
import { Link } from 'react-router-dom'
import { WoRalateIncidentCard } from '../../../msp-wo/wo-management'
import { waitingGetSessions } from '../../../guard/route'

interface Param {
    incident: IncidentWithSlaCalculations
    setIsModalRelatedVisible?: Function
    dataWoRelateIncident: WoRalateIncidentCard[]
    isLoadingDataRelated: boolean
}

type Props = Param;
const DisplayRelateWorkflow: React.FC<Props> = (props: Props) => {
    // const { WorkflowRelatedState } = useContext<any>(WorkflowRelatedContext)
    const [woCurrentPage, setWoCurrentPage] = useState<number>(1)

    // const handleShowWorkflowrelated = () => {
    //     props.setIsModalRelatedVisible(true)
    //     // getWorkflowRelatedByIncidentId(props.incident.id?.toString()).then((data) => {
    //     //     console.log(data)
    //     // })
    // }
    // const tempWorkflowSliceShow = props.dataWoRelateIncident.slice((woCurrentPage - 1) * 10, woCurrentPage * 10)
    return (
        <>
            <Spin spinning={props.isLoadingDataRelated}>
                <Row>
                    <Col span={21}>
                        <p style={{ paddingTop: 2 }} className='sub-title'>Workflow Related</p>
                    </Col>
                    {/* <Col span={3} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <Button disabled={!checkRolesState(RoleType.Incident, 'CreateIncident') || !checkRolesState(RoleType.Incident, 'UpdateIncident') || props.incident?.ticketStatus.status === 'Resolved' || props.incident?.ticketStatus.status === 'Cancelled' || props.incident?.ticketStatus.status === 'Closed' || props.incident?.ticketStatus.status === 'Pending'}
                        className='feck-link-Button'
                        onClick={handleShowWorkflowrelated}
                        style={{ border: 'none', boxShadow: 'none', marginRight: -10 }}
                        type='dashed'
                    >
                        Select WorkFlow
                    </Button>
                </Col> */}
                </Row>
                <br />
                {props.dataWoRelateIncident.length > 0 ? <> <div style={{ overflowY: 'scroll', maxHeight: 600 }}>
                    {props.dataWoRelateIncident.map((it: WoRalateIncidentCard, index: number) => {
                        return <>
                            <div key={index} className='TicketRelateCard'>
                                <Row>
                                    <Col span={8}>
                                        {it.canView
                                            ? (<span>WorkOrder Number : <Link to={'/WoFormEdit/' + it.id}
                                                target="_blank" onClick={() => waitingGetSessions()}>{it.order_id}</Link></span>)
                                            : <span>WorkOrder Number : {it.order_id}</span>}
                                    </Col>
                                    <Col span={8}>
                                        <span>Item Number : {it.item_id}</span>
                                    </Col>
                                    <Col span={8}>
                                        <span>WO Status : {it.wo_status}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={8}>
                                        <span>Workflow : {it.workflow_number} - {it.workflow_name}</span>
                                    </Col>
                                    <Col span={8} >
                                        <span>Description : {it.description}</span>
                                    </Col>
                                    <Col span={8}></Col>
                                </Row>
                            </div>
                        </>
                    })} </div>
                <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 20 }}>
                    <Pagination total={props.dataWoRelateIncident.length} pageSize={10} current={woCurrentPage} onChange={(page) => setWoCurrentPage(page)} />
                </div></> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </Spin>
        </>
    )
}

export default DisplayRelateWorkflow
