/* eslint-disable no-useless-escape */
import React, { useContext, useEffect, useState } from 'react'
import { FormComponentProps } from 'antd/es/form'
import { AutoComplete, Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Switch } from 'antd'
import { Asset, BodySearchAsset, LocationBuilding, ModifyBodySearchAsset, PMPlan, PreventiveWithRelated, related } from '../Model'
import moment from 'moment'
import { LocationBuildingContext } from '../ContextAPI/LocationBuildingContext'
import { AssetStateContext } from '../ContextAPI/AssetStateContext'
import { DepartmentAuth, People } from '../../authorization-module/people/model'
import { getAllUsersByUserId } from '../../authorization-module/user-role/duck/action'
import { addPMPlan, getAllpmTemplate, getDataItems, getDataLocationBuilding, getDetailAssetByIDPM, searchCreatePM, updatePMPlan } from '../services'
import { PeopleContext } from '../ContextAPI/UserHRContext'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import { getAllDepartmentAuth } from '../../authorization-module/user-role/duck/service'
import { DataSourceItemObject } from 'antd/lib/auto-complete'
import CustomTableTranfer from './CustomTableTranfer'
import { PmTemplateStateContext } from '../ContextAPI/PmTemplateCustomfieldContext'

const { Option } = Select

interface Param {
    closeModel: Function
    isClose: boolean
    dataEdit?: PreventiveWithRelated
    listFieldsAsset?: string[]
    isChangeField?: boolean
    setIsChangeField?: Function
}
type Props = Param & FormComponentProps
const { confirm } = Modal
const { Search } = Input
// var dataSource: Asset[] = []
const FormPMPlan: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, getFieldValue, resetFields, setFieldsValue } = props.form
    const [dataTransfer, setDataTransfer] = useState<Asset[]>([])
    const [dataSource2, setDataSource2] = useState<Asset[]>([])
    const { LocationBuildingState, GetLocationBuilding } = useContext<any>(LocationBuildingContext)
    const { AssetStateState, GetAssetState } = useContext<any>(AssetStateContext)
    const { PeopleState, GetPeople } = useContext<any>(PeopleContext)
    const [location, setLocation] = useState<string[]>([])
    const [building, setBuilding] = useState<string[]>([])
    const [floor, setFloor] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [loadDataTable, setLoadDataTable] = useState<boolean>(false)
    const [buildingList, setBuildingList] = useState<string[]>([])
    const [floorList, setFloorList] = useState<string[]>([])
    const [department, setDepartment] = useState<DepartmentAuth[]>([])
    const [dataLocation, setDataLocation] = useState<string[]>([])
    const [body, setBodys] = useState<BodySearchAsset>({
        building: undefined,
        location: undefined,
        floor: undefined,
        department: undefined,
        userIds: undefined,
        assetStatus: undefined
    })
    const [isSearch, setIsSearch] = useState(false)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const [isPmApproved, setIsPmApproved] = useState(true)
    const [dataEditRelated, setDataEditRelated] = useState<related[]>()
    const [sortBy, setSortBy] = useState<string>('createdDatetime')
    const [orderBy, setOrderBy] = useState<string>('desc')
    const [isClear, setIsClear] = useState<boolean>(false)
    const { GetPmTemplateState } = useContext<any>(PmTemplateStateContext)

    useEffect(() => {
        getDataLocationBuilding().then((res) => {
            const tempLocation: string[] = []
            const tempBuilding: string[] = []
            const tempFloor: string[] = []
            if (LocationBuildingState.length === 0) {
                GetLocationBuilding(res)
                const location: string[] = (res || []).map((it: LocationBuilding) => { return it.location }) || []
                setDataLocation([...new Set(location)].slice(0, 20))
            }
            res.forEach((data) => {
                if (data.location) {
                    tempLocation.push(data.location)
                }
                if (data.building) {
                    tempBuilding.push(data.building)
                }
                if (data.floor) {
                    tempFloor.push(data.floor)
                }
            })
            setLocation([...new Set(tempLocation)])
            setBuilding([...new Set(tempBuilding)])
            setFloor([...new Set(tempFloor)])
        })

        getAllDepartmentAuth().then((res) => {
            if (res) {
                setDepartment(res)
            }
        })
        getDataItems('assetStatus').then((res) => {
            if (AssetStateState.length === 0) {
                GetAssetState(res)
            }
        })

        getAllpmTemplate().then((res) => {
            GetPmTemplateState(res)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.dataEdit) {
            setIsPmApproved(props.dataEdit.isPmApproved)
            setFieldsValue({
                PM_Plan: moment(props.dataEdit.pmPlanDate),
                PMLocation: props.dataEdit.pmLocation || undefined,
                Description: props.dataEdit.description,
                UserName: undefined,
                AssetStatus: undefined,
                Location: undefined,
                Building: undefined,
                Floor: undefined,
                Department: undefined,
                tag: undefined,
                isPmApproved: props.dataEdit.isPmApproved
            })
            setLoadDataTable(true)
            getDetailAssetByIDPM(props.dataEdit.pmId, 1, 1000).then((res) => {
                if (res) {
                    setDataTransfer(res.data)
                    setDataSource2(res.data)
                    setDataEditRelated(res.data)
                }
            }).finally(() => {
                setLoadDataTable(false)
            })

            getAllpmTemplate().then((res) => {
                GetPmTemplateState(res)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataEdit])

    useEffect(() => {
        setTotal(0)
        setPage(1)
        if (!props.isClose && props.dataEdit === undefined) {
            setDataSource2([])
        }
        if (props.isClose && props.dataEdit === undefined) {
            setDataTransfer([])
            setPage(1)
            setTotal(0)
            setDataSource2([])
            setFieldsValue({
                PM_Plan: undefined,
                UserName: undefined,
                AssetStatus: undefined,
                Location: undefined,
                Building: undefined,
                Floor: undefined,
                Department: undefined,
                tag: undefined
            })
            body.userIds = undefined
            body.department = undefined
            body.assetStatus = undefined
            resetFields()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isClose])

    useEffect(() => {
        if (getFieldValue('UserName')) {
            const timer = setTimeout(() => {
                setIsSearch(true)
            }, 1000)
            return () => clearTimeout(timer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFieldValue('UserName')])

    useEffect(() => {
        if (isSearch) {
            getAllUsersByUserId(props.form.getFieldValue('UserName')).then((res) => {
                if (res) {
                    console.log(res)
                    if (GetPeople) {
                        GetPeople(res)
                    }
                }
            }).catch(() => {
                message.warning('Data is null')
            }).finally(() => {
                setIsSearch(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSearch])

    useEffect(() => {
        if (getFieldValue('UserName') === '' || getFieldValue('UserName') === undefined) {
            body.userIds = undefined
            setTotal(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFieldValue('UserName')])

    useEffect(() => {
        if (props.isChangeField && getFieldValue('tag')) {
            handelSearchCreatePM(page, pageSize)
            if (props.setIsChangeField) {
                props.setIsChangeField(!props.isChangeField)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isChangeField, getFieldValue('tag')])

    const createOption = (name: string) => {
        let data: string[] = []
        if (name === 'Location') {
            data = (LocationBuildingState || []).map((res: LocationBuilding) => { return res.location }) || []
            return renderOption(data)
        } else if (name === 'Building') {
            data = (LocationBuildingState || []).map((res: LocationBuilding) => { return res.building }) || []
            return renderOption(buildingList || data)
        } else if (name === 'Floor') {
            data = (LocationBuildingState || []).map((res: LocationBuilding) => { return res.floor }) || []
            return renderOption(floorList || data)
        }
    }

    const renderOption = (data: string[]) => {
        return [...new Set(data)].filter(it => it !== null).map((data) => {
            return (<Option value={data} key={data}>{data}</Option>)
        })
    }

    const handleChange = (name: string, value?: string) => {
        if (name === 'Location') {
            resetFields(['Building', 'Floor'])
            setBuildingList([])
            setFloorList([])
            if (value) {
                const BuildingFilter: LocationBuilding[] = (LocationBuildingState || []).filter((res: LocationBuilding) => res.location === value)
                const TempData = BuildingFilter.map((res: LocationBuilding) => {
                    return res.building
                })
                body.building = undefined
                body.floor = undefined
                setBuildingList(TempData)
            } else {
                body.location = undefined
                body.building = undefined
                body.floor = undefined
                // dataSource = []
            }
        } else if (name === 'Building') {
            resetFields(['Floor'])
            setFloorList([])
            if (value) {
                const FloorFilter: LocationBuilding[] = (LocationBuildingState || []).filter((res: LocationBuilding) => res.building === value && res.location === getFieldValue('Location'))
                const TempData = FloorFilter.map((res: LocationBuilding) => {
                    return res.floor
                })
                setFloorList(TempData)
            } else {
                body.building = undefined
                body.floor = undefined
                // dataSource = []
                setDataSource2([])
            }
        }
    }

    const onFinish = (e: any) => {
        e.preventDefault()
        props.form.validateFields(async (_err, values) => {
            if (!_err) {
                if (dataTransfer.length === 0) {
                    message.warning('Please select an Asset')
                } else {
                    if (values.PM_Plan) {
                        setIsLoading(true)
                        const TempData = dataTransfer
                        // { ...res, lastModifiedBy: undefined },
                        const convertData = TempData.map((res) => {
                            return {
                                asset: { id: res.id },
                                pmStatus: 'Open',
                                currentStep: 0,
                                description: values.Description?.replace(/ +/g, ' ')?.trim(),
                                pmPlanDate: moment(values.PM_Plan).format('YYYY-MM-DD'),
                                pmLocation: values.PMLocation,
                                isPmApproved: isPmApproved,
                                pmActivityTemplateId: getFieldValue('PMTemplate' + res.id?.toString())
                            } as unknown as PMPlan
                        })
                        if (props.dataEdit) {
                            updatePMPlan(props.dataEdit.pmId, convertData).then((res) => {
                                if (res) {
                                    message.success('The data has been updated successfully.')
                                    props.closeModel(dataTransfer)
                                    resetFields()
                                    clearData()
                                }
                                setIsLoading(false)
                            }).catch((err) => {
                                message.error(err?.response?.data?.message)
                                setIsLoading(false)
                            }).finally(() => {
                                setTotal(0)
                                setPage(1)
                                setPageSize(10)
                                clearData()
                            })
                        } else {
                            addPMPlan(convertData).then((res) => {
                                if (res) {
                                    message.success('You have Successfully Created PM ID.')
                                    props.closeModel(dataTransfer)
                                    resetFields()
                                    clearData()
                                }
                                setIsLoading(false)
                            }).catch((err) => {
                                message.error(err.response.data.message)
                                setIsLoading(false)
                            }).finally(() => {
                                setTotal(0)
                                setPage(1)
                                setPageSize(10)
                                clearData()
                            })
                        }
                    }
                }
            }
        })
    }

    const getDataTransfer = (value: any) => {
        if (value) {
            setDataTransfer(value)
        }
    }

    // const getUnique = (sourceData: Asset[], index: any) => {
    //     const unique = sourceData
    //         .map((e: any) => e[index])
    //         .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)
    //         .filter((e: any) => sourceData[e]).map((e: any) => sourceData[e])
    //     return unique
    // }

    const handelSearch = (value, name) => {
        // setLoadDataTable(true)
        let tagValue = ''
        if (name === 'UserName' && getFieldValue('UserName')) {
            body.userIds = [Number(value)]
        } else if (name === 'AssetStatus') {
            body.assetStatus = value
        } else if (name === 'Location') {
            body.location = value
        } else if (name === 'Building') {
            body.building = value
        } else if (name === 'Floor') {
            body.floor = value
        } else if (name === 'Department') {
            body.department = value
        } else if (name === 'tag') {
            tagValue = value
            console.log(tagValue)
        }
        const tempBody = body
        setBodys(tempBody)
    }

    const handelSearchAll = () => {
        setPage(1)
        handelSearchCreatePM(1)
    }

    const handleChangePageSize = (pageSize) => {
        setPage(1)
        setPageSize(pageSize)
        handelSearchCreatePM(1, pageSize)
    }

    const handelSearchCreatePM = (currentPage, size?) => {
        setLoadDataTable(true)
        // dataSource = []
        setDataSource2([])
        const convertDataSearch: ModifyBodySearchAsset = {
            filter: body,
            selectedColumn: sessionStorage.getItem('CreatePM') ? sessionStorage.getItem('CreatePM')?.split(',') : ['ownerName', 'assetStatus', 'type', 'group', 'ownerId', 'location', 'building', 'floor', 'department'],
            search: getFieldValue('tag')?.trim()
        }
        console.log(convertDataSearch)
        searchCreatePM(getFieldValue('tag')?.trim(), convertDataSearch, sortBy, orderBy, currentPage, size || pageSize).then((res) => {
            if (res.data.length !== 0) {
                setTotal(res.total)
                setDataSource2(res.data)
                setLoadDataTable(false)
            } else {
                setTotal(0)
            }
        }).finally(() => { setLoadDataTable(false) })
    }

    const clearData = () => {
        setDataTransfer([])
        setTotal(0)
        setDataSource2([])
        setSortBy('createDatetime')
        resetFields(['Floor', 'Building', 'Location', 'AssetStatus', 'Department', 'UserName', 'tag'])
        setBodys({
            building: undefined,
            location: undefined,
            floor: undefined,
            department: undefined,
            userIds: undefined,
            assetStatus: undefined
        })
        setFieldsValue({
            PM_Plan: undefined,
            UserName: undefined,
            AssetState: undefined,
            Location: undefined,
            Building: undefined,
            Floor: undefined,
            Department: undefined,
            tag: undefined
        })
        setDataEditRelated([])
        setIsPmApproved(true)
        // window.location.href = '/Preventive'
    }

    const closeModal = () => {
        confirm({
            title: 'Are you sure ?',
            content: 'Do you want to leave this page ?',
            onOk() {
                props.closeModel()
                // dataSource = []
                setDataSource2([])
                clearData()
            },
            onCancel() {
                // setDataSource2([])
                // dataSource = []
                console.log('Cancel')
            }
        })
    }

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().startOf('day')
    }

    const onChangeCheckbox = () => {
        setIsPmApproved(!isPmApproved)
    }

    const handleClear = () => {
        setSortBy('createDatetime')
        resetFields(['Floor', 'Building', 'Location', 'AssetStatus', 'Department', 'UserName', 'tag'])
        setBodys({
            building: undefined,
            location: undefined,
            floor: undefined,
            department: undefined,
            userIds: undefined,
            assetStatus: undefined
        })
        setIsClear(true)
    }

    const handleSortByPM = (sortBy, orderBy, currentPage, currentpageSize) => {
        setSortBy(sortBy)
        setOrderBy(orderBy)
        setLoadDataTable(true)
        const convertDataSearch: ModifyBodySearchAsset = {
            filter: body,
            selectedColumn: sessionStorage.getItem('CreatePM')?.split(',') || ['ownerName', 'assetStatus', 'type', 'group', 'ownerId', 'location', 'building', 'floor', 'department'],
            search: getFieldValue('tag')?.trim()
        }
        console.log(convertDataSearch)
        searchCreatePM(getFieldValue('tag')?.trim(), convertDataSearch, sortBy, orderBy, currentPage, currentpageSize).then((res) => {
            if (res.data.length !== 0) {
                setTotal(res.total)
                setDataSource2(res.data)
                setLoadDataTable(false)
            } else {
                setTotal(0)
            }
        }).finally(() => { setLoadDataTable(false) })
    }

    const validateDescription = (_: any, value: any, callback) => {
        if (value) {
            const CheckValue = value.replace(/ +/g, '').trim()
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (pattern.test(CheckValue)) {
                if (CheckValue.length >= 1) {
                    callback()
                } else {
                    callback('Enter more than 1 characters')
                }
            } else {
                // eslint-disable-next-line
                if (format.test(CheckValue)) {
                    callback()
                } else {
                    callback('Enter more than 1 characters')
                }
            }
        } else {
            callback()
        }
    }

    const searchFetch = (value: string) => {
        const allData: string[] = (LocationBuildingState || []).map((res: LocationBuilding) => { return res.location }) || []
        if (typeof value === 'string' && value.length >= 1) {
            const filterData: string[] = allData.filter((data: string) => data !== null && data.toUpperCase().includes(value.toUpperCase()))
            if (filterData.length > 0) {
                if (filterData.length < 20) {
                    setDataLocation([...new Set(filterData)])
                } else {
                    setDataLocation([...new Set(filterData)].slice(0, 20))
                }
            }
        } else {
            if (allData) {
                setDataLocation([...new Set(allData)].slice(0, 20))
            }
        }
    }

    return (
        <div>
            <Form
                name="FormPMPlan"
                layout={'vertical'}
            >
                <Row>

                    <Col xs={24} md={24}>
                        <Row gutter={[8, 2]}>
                            <Col xl={8} md={8} xs={24} sm={24}>
                                <Form.Item
                                    label="Plan Date"
                                >
                                    {getFieldDecorator('PM_Plan', {
                                        rules: [{ required: true, message: 'Please enter your Plan Date.' }]
                                    })(
                                        <DatePicker style={{ width: '100%' }} placeholder={'Select Date'} disabledDate={disabledDate} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xl={13} md={13} xs={24} sm={24}>
                                <Form.Item
                                    label="PM Location"
                                >
                                    {getFieldDecorator('PMLocation', {
                                        rules: [{ required: false, message: 'Please enter your PM Location.' }]
                                    })(
                                        <Select
                                            placeholder="Search PM Location"
                                            mode="multiple"
                                            onSearch={(e) => searchFetch(e)}
                                            filterOption={(inputValue, option) =>
                                                String(option.props.children)?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                                            }
                                            allowClear={true}
                                        >
                                            {(dataLocation || []).filter(it => it !== null).map((item, index) => {
                                                return <Option value={item} key={index} title={item}>{item}</Option>
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xl={3} md={3} xs={24} sm={24}>
                                <Form.Item
                                    label="PM Plan Approve"
                                >
                                    <Switch onChange={onChangeCheckbox} checked={isPmApproved}>PM Plan Approve</Switch>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={21} lg={21}>
                                <Form.Item
                                    label="Description"
                                >
                                    {getFieldDecorator('Description', {
                                        rules: [
                                            { required: false, message: 'Please enter your Description.' },
                                            { validator: validateDescription },
                                            { whitespace: true }
                                        ]
                                    })(
                                        <Input placeholder='Description' max={255} maxLength={255} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[8, 2]} style={{ backgroundColor: '#FAFAFA', padding: 10 }}>
                            <h4 style={{ color: '#323276', paddingLeft: 5 }}>Search Asset</h4>
                            <Col span={24}>
                                <Row >
                                    <Col span={8} xs={24} sm={24} md={8} lg={8}>
                                        <Form.Item>
                                            {getFieldDecorator('tag')(
                                                <Search placeholder={'Search Serial Number'} onSearch={() => handelSearchAll()} style={{ width: '100%', minWidth: 170 }} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={8}>
                                <Form.Item
                                    label="Owner Name"
                                >
                                    {getFieldDecorator('UserName')(
                                        // <Select
                                        //     mode="multiple"
                                        //     showSearch
                                        //     loading={PeopleState?.length === 0}
                                        //     optionFilterProp="children"
                                        //     filterOption={(inputValue, option: any) =>
                                        //         (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        //     }
                                        //     onChange={(e) => handelSearch(e, 'UserName')}
                                        //     allowClear
                                        //     placeholder={'Select Owner Name'}
                                        //     // suffixIcon={<Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }} />}
                                        // >
                                        //     {(PeopleState || []).map((res: People, index: number) => {
                                        //         return <Option value={res.id} key={res.id} >{res.fullName}</Option>
                                        //     })}
                                        // </Select>
                                        <AutoComplete
                                            dataSource={(PeopleState).map((it: People) => {
                                                return {
                                                    value: it.id?.toString(),
                                                    text: it.employeeId?.toString() + ' : ' + it.fullName
                                                } as DataSourceItemObject
                                            })}
                                            style={{ width: '100%' }}
                                            onSelect={(e) => handelSearch(e, 'UserName')}
                                            placeholder={'Select Owner Name'}
                                            allowClear
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8}>
                                <Form.Item
                                    label="Department"
                                >
                                    {getFieldDecorator('Department')(
                                        <Select mode="multiple"
                                            showSearch
                                            loading={department?.length === 0}
                                            optionFilterProp="children"
                                            filterOption={(inputValue, option: any) =>
                                                (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                            onChange={(e) => handelSearch(e, 'Department')}
                                            allowClear
                                            placeholder={'Select Department'}
                                        >
                                            {(department || []).map((res, index: number) => {
                                                return <Option value={res.departmentName} key={index} >{res.departmentName}</Option>
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8}>
                                <Form.Item
                                    label="Asset Status"
                                >
                                    {getFieldDecorator('AssetStatus')(
                                        <Select mode="multiple"
                                            showSearch
                                            loading={AssetStateState?.length === 0}
                                            optionFilterProp="children"
                                            filterOption={(inputValue, option: any) =>
                                                (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                            onChange={(e) => handelSearch(e, 'AssetStatus')}
                                            allowClear
                                            placeholder={'Select Asset Status'}
                                        >
                                            {(AssetStateState || []).map((res, index: number) => {
                                                return <Option value={res} key={index} >{res}</Option>
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[8, 2]} style={{ backgroundColor: '#FAFAFA', padding: 10, paddingTop: 0 }}>
                            <Col xs={24} sm={8}>
                                <Form.Item
                                    label="Location"
                                >
                                    {getFieldDecorator('Location')(
                                        <Select
                                            showSearch
                                            loading={location.length === 0}
                                            optionFilterProp="children"
                                            filterOption={(inputValue, option: any) =>
                                                (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                            onChange={(e) => { handleChange('Location', e?.toString()); handelSearch(e, 'Location') }}
                                            allowClear
                                            placeholder={'Select Location'}
                                        >
                                            {createOption('Location')}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} >
                                <Form.Item
                                    label="Building"
                                >
                                    {getFieldDecorator('Building')(
                                        <Select
                                            showSearch
                                            loading={building.length === 0}
                                            optionFilterProp="children"
                                            filterOption={(inputValue, option: any) =>
                                                (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                            onChange={(e) => { handleChange('Building', e?.toString()); handelSearch(e, 'Building') }}
                                            allowClear
                                            placeholder={'Select Building'}
                                        >
                                            {createOption('Building')}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} >
                                <Form.Item
                                    label="Floor"
                                >
                                    {getFieldDecorator('Floor')(
                                        <Select
                                            showSearch
                                            loading={floor.length === 0}
                                            optionFilterProp="children"
                                            filterOption={(inputValue, option: any) =>
                                                (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                            onChange={(e) => { handleChange('Floor', e?.toString()); handelSearch(e, 'Floor') }}
                                            allowClear
                                            placeholder={'Select Floor'}
                                        >
                                            {createOption('Floor')}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24} style={{ textAlign: 'end' }}>
                                <Button type='default' htmlType='button' onClick={handleClear} style={{ marginRight: 5 }}>Clear</Button>
                                <Button type='primary' htmlType='button' onClick={() => handelSearchAll()}>Search</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="dataTrans" style={{ overflow: 'auto' }}>
                    {/* dataSource.length !== 0 ? dataSource : props.dataEdit?.related || [] */}
                    {/* <TransferData
                        dataSource={dataSource}
                        targetKeys={props.dataEdit?.related.length !== 0 ? props.dataEdit?.related.map((res) => { return Number(res?.id!!) }) : dataTransfer.map((res) => { return res.id!! })}
                        getDataTransfer={getDataTransfer}
                        isLoading={loadDataTable}
                        isOpen={props.isClose}
                        page={page}
                        pageSize={pageSize}
                        setPageSize={handleChangePageSize}
                        setPage={setPage}
                        total={total}
                        handelSearchCreatePM={handelSearchCreatePM}
                    /> */}
                    <CustomTableTranfer
                        dataSource={dataSource2}
                        dataEdit={props.dataEdit}
                        dataRelated={dataEditRelated}
                        targetKeys={props.dataEdit?.related?.length !== 0 ? props.dataEdit?.related?.map((res) => { return Number(res?.id!!) }) : dataTransfer.map((res) => { return res.id!! })}
                        getDataTransfer={getDataTransfer}
                        isLoading={loadDataTable}
                        isOpen={props.isClose}
                        page={page}
                        pageSize={pageSize}
                        setPageSize={handleChangePageSize}
                        setPage={setPage}
                        total={total}
                        handelSearchCreatePM={handelSearchCreatePM}
                        isEdit={props.dataEdit !== undefined}
                        handleSortByPM={handleSortByPM}
                        isClear={isClear}
                        setIsClear={setIsClear}
                        form={props.form}
                    />
                </div>
                <br />
                <Form.Item style={{ textAlign: 'end' }}>
                    <Button htmlType="button" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType='button' onClick={onFinish} style={{ marginLeft: 5 }} disabled={!checkRolesState(RoleType.PM, 'CreateUpdateDeletePMPlan')} loading={isLoading}>
                        {props.dataEdit ? 'Submit' : 'Create PM ID'}
                    </Button>
                </Form.Item>
            </Form>
            {/* <Modal title={'Template pm activity'}
                onCancel={() => setIsVisibleTemplateModal(false)}
                visible={isVisibleTemplateModal}
                maskClosable={false}
                footer={null}>
                    <MyFormPMTemplate isVisibleTemplateModal={isVisibleTemplateModal} setIsVisibleTemplateModal={setIsVisibleTemplateModal} pmValue={pmValue} />
            </Modal> */}
        </div>
    )
}

const MyFormPMPlan = Form.create<Props>({ name: 'FormPMPlan' })(FormPMPlan)
export default MyFormPMPlan
