/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect, useState } from 'react'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Empty,
    Form,
    Icon,
    Input,
    InputNumber,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Tooltip
} from 'antd'
import { Link, Redirect, useLocation, useParams } from 'react-router-dom'
import { SlaPolicyFormConditionList } from './SlaPolicyFormConditionList'
import { StoreState } from '../../store'
import { BusinessHour, getAllBusinessHour, getAllBusinessHourWithOrigin } from '../business-hour'
import { addSlaPolicy, getAllSlaPolicy, getSlaPolicyById, updateSlaPolicy } from './service'
import { FormComponentProps } from 'antd/es/form'
import { Condition, ConditionsMatchingRequirement, SlaGoal, SlaPolicy, SlaPolicyFunc } from './model'
import { SelectValue } from 'antd/es/select'
import { TicketType } from '../sla-global-setting'
import { nullOrEmptyObject } from '../../common-misc'
import { FetchingState, fetchWithState, SpinningFetcher } from '../../common-components'
import { connect } from 'react-redux'
import { getAllStatus } from '../../incident-management/status'
import { getAllGeneralIncidentSetting } from '../../incident-management/general-incident-setting/service'
import { TicketStatus } from '../../incident-management/incident'
import { getAllSupportTeam } from '../../authorization-module/support-team-management/service'
import { getAllPriority } from '../../incident-management/priority/service'
import { getAllUserAsigneeSLA } from '../../authorization-module/user-role/duck/action'
import { User } from '../../authorization-module/user-role/duck/model'

const mapStateToProps = (state: StoreState) => {
    return {
        slaPolicies: state.slaPolicies,
        activeBusinessHours: state.businessHours.filter((it) => it.activate),
        statuses: state.statuses,
        allBusinessHours: state.businessHours,
        generalIncidentSetting: state.generalIncidentSetting,
        supportTeamManagement: state.supportTeamManagement,
        priority: state.priorities
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllSlaPolicy: () => Promise<number>
    getAllBusinessHour: () => Promise<number>
    getAllStatus: (status: string | undefined) => Promise<number>
    addSlaPolicy: (slaPolicy: SlaPolicy) => Promise<number>
    updateSlaPolicy: (slaPolicy: SlaPolicy) => Promise<number>
    getAllGeneralIncidentSetting: () => Promise<number>
    getAllSupportTeam: () => Promise<number>
    getAllPriority: () => Promise<number>
    showActiveRoleList: () => Promise<number>
}

interface Params {
    id: string
}

type Props = StateProps & DispatchProps & Params & FormComponentProps

const SlaPolicyFormCreate: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator } = props.form
    const { Option } = Select
    const { id } = useParams<Params>()
    const location = useLocation()
    const [slaPolicy, setSlaPolicy] = useState<SlaPolicy>(SlaPolicyFunc.construct(TicketType.Incident as string))
    const [conditionKey, setConditionKey] = useState<string>('')
    const [conditionValue, setConditionValue] = useState<string>('')
    const isView: boolean = location.pathname.includes('SlaPolicySettingFormView')
    const isClone: boolean = location.pathname.includes('SlaPolicySettingFormClone')
    const isEdit: boolean = location.pathname.includes('SlaPolicySettingFormEdit')
    const isCreate: boolean = !(isView || isClone || isEdit)
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState(Object), [])
    const [statusesFetchingState, setStatusesFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [businessHoursFetchingState, setBusinessHoursFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [slaPolicyFetchingState, setSlaPolicyFetchingState] = useState<FetchingState>(id ? FetchingState.NotStarted : FetchingState.Succeed)
    const [defaultSLAName, setDefaultSLAName] = useState<string>('')
    const [isRedirect, setIsRedirect] = useState(false)
    const [isRequireConditionKey, setIsRequireConditionKey] = useState(false)
    const [isRequireConditionValue, setIsRequireConditionValue] = useState(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [dataCriteria, setDataCriteria] = useState<Condition[]>([])
    const [tempUser, setTempUser] = useState<User[]>([])
    const [businessHours, setBusinessHours] = useState<BusinessHour[]>([])
    const [originalData, setOriginalData] = useState<SlaPolicy>()

    useEffect(() => {
        fetchDataStatus()
        fetchDataBusinessHour()
        fetchTicketType()
        props.getAllPriority()
        if (props.slaPolicies.length === 0) {
            props.getAllSlaPolicy().catch((err) => message.error(`Unable to load the initial SLA policy data. Please try again later. ${err}`))
        }
        // getAllUser().then(res => setTempUser(res || []))
        if (id) {
            fetchSlaPolicyById()
            getSlaPolicyById(id).then((data) => {
                if (data) {
                    setOriginalData(data)
                }
            })
        }
        getAllBusinessHourWithOrigin().then(res => setBusinessHours(res || []))
            .catch((err) => message.error(`Unable to load the initial SLA policy data. Please try again later. ${err}`))

        getAllUserAsigneeSLA('').then((res) => {
            setTempUser(res || [])
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const valueCriteria = props.form.getFieldValue('conditionKey')
        setDataCriteria([])
        if (valueCriteria === 'supportTeamId') {
            if (props.supportTeamManagement) {
                const filterActive = props.supportTeamManagement.filter(it => it.activate)
                const supportTeamOption = filterActive.map(supportTeam => {
                    const obj: Condition = {
                        id: supportTeam?.id,
                        name: supportTeam.name
                    }
                    return obj
                })
                setDataCriteria(supportTeamOption)
            }
        } else if (valueCriteria === 'userId') {
            if (tempUser) {
                const userOption = tempUser.map((user: any) => {
                    const obj: Condition = {
                        id: user?.id,
                        name: user.firstName + ' ' + user.lastName
                    }
                    return obj
                })
                setDataCriteria(userOption)
            }
        } else if (valueCriteria === 'priorityId') {
            if (props.priority) {
                const priorityOption = props.priority.map(priority => {
                    const obj: Condition = {
                        id: priority?.id,
                        name: priority.name
                    }
                    return obj
                })
                setDataCriteria(priorityOption)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.getFieldValue('conditionKey')])

    useEffect(() => {
        if (tempUser) {
            const userOption = tempUser.map((user: any) => {
                const obj: Condition = {
                    id: user?.id,
                    name: user.firstName + ' ' + user.lastName
                }
                return obj
            })
            setDataCriteria(userOption)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempUser])

    const fetchSlaPolicyById = () => {
        fetchWithState(
            slaPolicyFetchingState,
            setSlaPolicyFetchingState,
            async () => {
                const slaPolicy = await getSlaPolicyById(id)
                if (isClone || isEdit) {
                    if (slaPolicy.activate) {
                        slaPolicy.activate = true
                    }
                    slaPolicy.slaGoals.forEach(it => delete it.id)
                    slaPolicy.conditions.forEach(it => delete it.id)
                }
                setSlaPolicy(slaPolicy)
                setDefaultSLAName(slaPolicy.name)
            },
            undefined,
            (err) => message.error(`Unable to load the SLA policy data. Please try again later. ${err}`)
        )
    }

    const fetchDataStatus = () => {
        fetchWithState(statusesFetchingState, setStatusesFetchingState, async () => {
            props.getAllStatus(undefined)
        }, undefined, (err) => {
            message.error(`Unable to retrieve all statuses. Please try again later. ${err}`)
        })
    }

    const fetchDataBusinessHour = () => {
        fetchWithState(businessHoursFetchingState, setBusinessHoursFetchingState, props.getAllBusinessHour, undefined, (err) => {
            message.error(`Unable to retrieve all business hours. Please try again later. ${err}`)
        })
    }

    const fetchTicketType = () => {
        props.getAllGeneralIncidentSetting().catch((err) => message.error(`Unable to retrieve all ticket types. Please try again later. ${err}`))
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        setIsRequireConditionValue(false)
        setIsRequireConditionKey(false)
        props.form.validateFields(async (err: any) => {
            if (nullOrEmptyObject(slaPolicy.conditions)) {
                Modal.warning({
                    title: 'Add at least 1 condition.'
                })
                return
            }
            if (!err) {
                setIsLoading(true)
                let promise: Promise<number>
                if (isCreate) {
                    promise = props.addSlaPolicy(slaPolicy)
                } else if (isClone) {
                    delete slaPolicy.id
                    delete slaPolicy.originId
                    delete slaPolicy.createdDatetime
                    delete slaPolicy.version
                    promise = props.addSlaPolicy(slaPolicy)
                } else {
                    const patch = SlaPolicyFunc.cloneForPatching(slaPolicy)
                    delete patch.id
                    delete patch.createdDatetime
                    delete patch.lastModifiedDatetime
                    if (originalData) {
                        if (checkDuplicate(originalData, patch)) {
                            setIsRedirect(true)
                            return
                        }
                    }
                    promise = props.addSlaPolicy(patch)
                }
                promise.then(() => {
                    isEdit ? message.success('The data has been updated successfully.') : message.success('The data has been saved successfully.')
                    setIsRedirect(true)
                    setIsLoading(false)
                }).catch((err) => {
                    setIsLoading(false)
                    if (err?.response?.status === 400 || err?.response?.status === 409) {
                        message.error('Unable to save data. Please check and try again.')
                        console.log(err)
                    } else {
                        message.error(`Unable to save data. Please check and try again. ${err}`)
                    }
                })
            }
        })
    }

    const checkDuplicate = (origin: SlaPolicy, newInput: SlaPolicy): boolean => {
        let isCheck = false
        delete origin.id
        delete origin.createdDatetime
        delete origin.lastModifiedDatetime
        origin.conditions.forEach((it) => {
            delete it.id
        })
        origin.slaGoals.forEach((it) => {
            delete it.id
        })
        const convertOriginToString = JSON.stringify(origin)
        const convertNewInput = JSON.stringify(newInput)
        if (convertOriginToString === convertNewInput) {
            isCheck = true
        }
        return isCheck
    }

    const onSlaGoalNamesChanged = (value: SelectValue) => {
        const selectedValue = (value as Array<string>)
        const pattern = /[A-Za-z0-9ก-๙]+/
        let isErrorPattern = false
        selectedValue.forEach((name) => {
            if (!pattern.test(name.replace(/ +/g, ' ').trim())) {
                selectedValue.pop()
                isErrorPattern = true
            }
        })
        if (isErrorPattern) {
            return
        }
        if (value !== undefined && !Array.isArray(value)) {
            throw Error(`Expect value of SLA goal names to be undefined or array but found ${typeof value}`)
        }
        const names = value === undefined ? [] : (value as string[])
        const ruleBroken = SlaPolicyFunc.proposeSlaGoalNamesAndCheckWhetherTheLastNameBreakTheRule(slaPolicy, names)
        if (ruleBroken) {
            (value as string[]).pop()
        }
        forceUpdate()
    }

    const submitAddCondition = () => {
        if (props.form.getFieldValue('conditionKey') && props.form.getFieldValue('conditionValue')) {
            if (conditionKey && conditionValue) {
                const result = SlaPolicyFunc.addConditionIfNotExists(slaPolicy, conditionKey, conditionValue)
                if (!result) {
                    message.warning('This entry already exists in the system.')
                }
                setConditionKey('')
                setConditionValue('')
                props.form.setFieldsValue({ conditionKey: undefined, conditionValue: undefined })
            }
        } else {
            if (nullOrEmptyObject(slaPolicy.conditions)) {
                props.form.validateFields(['conditionKey'])
                conditionValue ? setIsRequireConditionValue(false) : setIsRequireConditionValue(true)
            } else {
                conditionKey ? setIsRequireConditionKey(false) : setIsRequireConditionKey(true)
                conditionValue ? setIsRequireConditionValue(false) : setIsRequireConditionValue(true)
            }
        }
    }

    const onSelectConditionValue = (e) => {
        if (e) {
            setConditionValue(e)
            setIsRequireConditionValue(false)
        }
    }

    const onSelectConditionKey = (value: string) => {
        setConditionKey(value)
        setIsRequireConditionKey(false)
        props.form.setFieldsValue({
            conditionValue: undefined
        })
    }

    const onChangeConditionKey = (e: SelectValue) => {
        if (e === undefined) {
            props.form.setFieldsValue({
                conditionValue: undefined
            })
        }
        if (!e) {
            setConditionKey('')
        }
    }

    const handleToUpdate = (value) => {
        if (value) {
            if (value === true) {
                setIsRequireConditionKey(false)
                setIsRequireConditionValue(false)
            }
        }
        forceUpdate()
    }

    const buildConditionForm = () => {
        return <div>
            {nullOrEmptyObject(slaPolicy.conditions)
                ? (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
                : (<SlaPolicyFormConditionList
                    slaPolicy={slaPolicy}
                    isView={isView}
                    handleToUpdate={handleToUpdate}
                    priority={props?.priority}
                    user={tempUser}
                    supportTeam={props?.supportTeamManagement}
                />)
            }
        </div>
    }

    const buildTargetTimeForm = () => {
        if (slaPolicy.slaGoals?.length === 0) {
            return <div><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
        } else {
            return <div>
                {(slaPolicy.slaGoals || []).map((slaGoal: SlaGoal) => {
                    return <Row key={slaGoal.name} gutter={[8, 8]}>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} style={{ paddingTop: 25 }}>
                            <p><span className={'requiredIcon'}>*</span> Any request matching the rules should
                                be {slaGoal.name} within</p>
                        </Col>
                        <Col span={3} xs={24} sm={24} md={6} lg={3}>
                            <Form.Item label={'Days'}>
                                {getFieldDecorator(`${slaGoal.name}day`, {
                                    initialValue: slaGoal.day || 0,
                                    rules: [{ required: true, message: 'Days are required.' }]
                                })(<InputNumber min={0} max={365} disabled={isView} onChange={(value) => {
                                    slaGoal.day = value || 0
                                }} />)}
                            </Form.Item>
                        </Col>
                        <Col span={3} xs={24} sm={24} md={6} lg={3}>
                            <Form.Item label={'Hours'}>
                                {getFieldDecorator(`${slaGoal.name}hour`, {
                                    initialValue: slaGoal.hour || 0,
                                    rules: [{ required: true, message: 'Hours are required.' }]
                                })(<InputNumber min={0} max={23} disabled={isView} onChange={(value) => {
                                    slaGoal.hour = value || 0
                                }} />)}
                            </Form.Item>
                        </Col>
                        <Col span={3} xs={24} sm={24} md={6} lg={3}>
                            <Form.Item label={'Minutes'}>
                                {getFieldDecorator(`${slaGoal.name}minute`, {
                                    initialValue: slaGoal.minute || 0,
                                    rules: [{ required: true, message: 'Minutes are required.' }]
                                })(<InputNumber min={0} max={59} disabled={isView} onChange={(value) => {
                                    slaGoal.minute = value || 0
                                }} />)}
                            </Form.Item>
                        </Col>
                        <Col span={3} xs={24} sm={24} md={3} lg={3} />
                    </Row>
                })}
            </div>
        }
    }

    const modalConfirmCancel = (): void => {
        Modal.confirm({
            title: 'Are you sure?',
            content: 'Do you want to leave this page ?',
            okText: 'Yes',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'No',
            onOk() {
                setIsRedirect(true)
            }
        })
    }

    const validateNameUniqueness = (_: any, value: any, callback) => {
        if (value) {
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (pattern.test(value.replace(/ +/g, '').trim())) {
                if (value.replace(/ +/g, '').trim().length >= 3) {
                    if (props.slaPolicies) {
                        // eslint-disable-next-line array-callback-return
                        const slaPolicyList = props.slaPolicies.map((value: any) => {
                            if (!isEdit || value.id !== Number(id)) {
                                return value.name?.replace(/ +/g, ' ').trim().toLowerCase()
                            }
                        })
                        if (isEdit && value.trim() === defaultSLAName) {
                            callback()
                        } else if (slaPolicyList.includes(value.replace(/ +/g, ' ').trim().toLowerCase())) {
                            callback('The SLA Policy name is already exists. It must be unique. Please try another one.')
                        } else {
                            callback()
                        }
                    }
                } else {
                    callback('Please enter more than 3 characters.')
                }
            } else {
                // eslint-disable-next-line
                if (format.test(value.replace(/ +/g, '').trim())) {
                    callback('Please enter more than 1 characters. and must not contain these characters: ' + format)
                } else {
                    callback('Please enter more than 3 characters.')
                }
            }
        } else {
            callback()
        }
    }

    const validateDescription = (_: any, value: any, callback) => {
        if (value) {
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (pattern.test(value.replace(/ +/g, '').trim())) {
                if (value.replace(/ +/g, '').trim().length >= 3) {
                    callback()
                } else {
                    callback('Please enter more than 3 characters.')
                }
            } else {
                if (format.test(value.replace(/ +/g, '').trim())) {
                    callback('Please enter more than 1 characters. and must not contain these characters: ' + format)
                } else {
                    callback('Please enter more than 3 characters.')
                }
            }
        } else {
            callback()
        }
    }

    const handleSearchPeople = (value) => {
        let tempValue = ''
        setTimeout(() => {
            if (value !== tempValue) {
                tempValue = value
                if (value.length > 2) {
                    getAllUserAsigneeSLA(value).then((res) => {
                        setTempUser(res || [])
                    })
                } else if (!value) {
                    getAllUserAsigneeSLA('').then((res) => {
                        setTempUser(res || [])
                    })
                }
            } else {
                tempValue = value
            }
        }, 1000)
    }

    const buildMeasurementForm = () => {
        if (slaPolicy.slaGoals?.length === 0) {
            return <div><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
        } else {
            return (slaPolicy.slaGoals || []).map((slaGoal: SlaGoal) => {
                // eslint-disable-next-line no-return-assign
                return (
                    <div key={slaGoal.name}>
                        <Row gutter={[8, 8]}>
                            <Col span={3} style={{ paddingTop: 10 }} md={3} lg={3}>
                                <div>
                                    {slaGoal.name}
                                    <span style={{ float: 'right', paddingRight: 5 }}>:</span>
                                </div>
                            </Col>
                            <Col span={4} style={{ paddingTop: 10 }} md={4} lg={4}>
                                <div>
                                    <span className={'requiredIcon'}>*</span>
                                    Start
                                    <span style={{ float: 'right', paddingRight: 17 }}>when</span>
                                </div>
                            </Col>
                            <Col span={4} md={4} lg={4}>
                                <Form.Item>
                                    {getFieldDecorator(`Assign_start[${slaGoal.name}]`, {
                                        initialValue: 'Status'
                                    })(
                                        <Input type="text" disabled className={'disabledStatus'} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={1} style={{ textAlign: 'center', paddingTop: 10 }}>is</Col>
                            <Col span={10} md={10} lg={10}>
                                <Form.Item style={{ width: '80%' }}>
                                    {getFieldDecorator(`${slaGoal.name}start`, {
                                        initialValue: slaGoal.statusToStartClock,
                                        rules: [{ required: true, message: 'Status is required.' }]
                                    })(
                                        <Select
                                            placeholder="Select a status" disabled={isView}
                                            onChange={(value) => {
                                                slaGoal.statusToStartClock = (value as string | undefined)
                                            }}
                                        >
                                            {
                                                props.statuses.map((res: TicketStatus, index: number) => {
                                                    return <Option value={res.status} key={index}>{res.status}</Option>
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[8, 8]}>
                            <Col span={3} md={3} lg={3} />
                            <Col span={4} style={{ paddingTop: 10 }} md={4} lg={4}>
                                <div><span className={'requiredIcon'}>*</span>Stop<span
                                    style={{ float: 'right', paddingRight: 17 }}>when</span></div>
                            </Col>
                            <Col span={4} md={4} lg={4}>
                                <Form.Item>
                                    {getFieldDecorator(`Assign_stop[${slaGoal.name}]`, {
                                        initialValue: 'Status'
                                    })(
                                        <Input type="text" disabled className={'disabledStatus'} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={1} style={{ textAlign: 'center', paddingTop: 10 }}>is</Col>
                            <Col span={10} md={10} lg={10}>
                                <Form.Item style={{ width: '80%' }}>
                                    {getFieldDecorator(`${slaGoal.name}stop`, {
                                        initialValue: slaGoal.statusToStopClock,
                                        rules: [{ required: true, message: 'Status is required.' }]
                                    })(
                                        <Select
                                            placeholder="Select a status" disabled={isView}
                                            onChange={(value) => {
                                                slaGoal.statusToStopClock = (value as string | undefined)
                                            }}
                                        >
                                            {
                                                props.statuses.map((res: TicketStatus, index: number) => {
                                                    return <Option value={res.status} key={index}>{res.status}</Option>
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[8, 8]}>
                            <Col span={3} md={3} lg={3} />
                            <Col span={4} style={{ paddingTop: 10 }} md={4} lg={4}>
                                <div>Pause<span
                                    style={{ float: 'right', paddingRight: 17 }}>when</span></div>
                            </Col>
                            <Col span={4} md={4} lg={4}>
                                <Form.Item>
                                    {getFieldDecorator(`Assign_Pause[${slaGoal.name}]`, {
                                        initialValue: 'Status'
                                    })(
                                        <Input
                                            type="text"
                                            disabled
                                            className={'disabledStatus'}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={1} style={{ textAlign: 'center', paddingTop: 10 }}>is</Col>
                            <Col span={10} md={10} lg={10}>
                                <Form.Item style={{ width: '80%' }}>
                                    {getFieldDecorator(`${slaGoal.name}Pause`, {
                                        initialValue: slaGoal.statusesToPauseClock = 'Pending'
                                    })(
                                        <Select
                                            mode="multiple" placeholder="Select a status" disabled={true}
                                            onChange={() => {
                                                slaGoal.statusesToPauseClock = 'Pending'
                                            }}
                                        >
                                            <Option value="Pending">Pending</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[8, 8]}>
                            <Col span={3} md={3} lg={3} />
                            <Col span={4} style={{ paddingTop: 10 }} md={4} lg={4}>
                                <div>Cancel<span
                                    style={{ float: 'right', paddingRight: 17 }}>when</span></div>
                            </Col>
                            <Col span={4} md={4} lg={4}>
                                <Form.Item>
                                    {getFieldDecorator(`Assign_Cancel[${slaGoal.name}]`, {
                                        initialValue: 'Status'
                                    })(
                                        <Input
                                            type="text"
                                            disabled
                                            className={'disabledStatus'}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={1} style={{ textAlign: 'center', paddingTop: 10 }}>is</Col>
                            <Col span={10} md={10} lg={10}>
                                <Form.Item style={{ width: '80%' }}>
                                    {getFieldDecorator(`${slaGoal.name}Cancel`, {
                                        initialValue: slaGoal.statusToCancel = 'Cancelled'
                                    })(
                                        <Select placeholder="Select a status" disabled={true} allowClear={true}
                                            onChange={() => {
                                                slaGoal.statusToCancel = 'Cancelled'
                                            }}
                                        >
                                            <Option value="Cancelled">Cancelled</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>

                    </div>
                )
            })
        }
    }

    const renderTopicViewOrEdit = (Type?: string) => {
        if (isEdit) {
            if (Type === 'Topic') {
                return <p className="main-title" style={{ paddingLeft: '1%' }}>Edit SLA Policy</p>
            } else {
                return <Breadcrumb.Item>Edit SLA Policy</Breadcrumb.Item>
            }
        } else {
            if (Type === 'Topic') {
                return <p className="main-title" style={{ paddingLeft: '1%' }}>View SLA Policy</p>
            } else {
                return <Breadcrumb.Item>View SLA Policy</Breadcrumb.Item>
            }
        }
    }

    const formBody = (
        <>
            {isRedirect ? (<Redirect to="/SlaPolicySettingList" />) : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={'/SlaPolicySettingList'}>SLA Policy</Link>
                </Breadcrumb.Item>
                {isCreate || isClone
                    ? (
                        <Breadcrumb.Item>New SLA Policy</Breadcrumb.Item>
                    ) : (
                        renderTopicViewOrEdit()
                    )}
            </Breadcrumb>
            <br />
            <Row>
                <Col span={24}>
                    <Card>
                        <Row>
                            <Col span={23}>
                                {isCreate || isClone
                                    ? (
                                        <p className="main-title" style={{ paddingLeft: '1%' }}>New SLA Policy</p>
                                    ) : (
                                        renderTopicViewOrEdit('Topic')
                                    )}
                            </Col>
                            {isView ? (<Col span={1}>
                                <span style={{ float: 'right', marginRight: '1%' }}>
                                    <Tooltip placement="bottom" title={'Edit'}><Link
                                        to={`/SlaPolicySettingFormEdit/${slaPolicy.id}`}><Icon className="edit_icon"
                                            type="edit" /></Link></Tooltip>
                                </span>
                            </Col>
                            ) : null}
                        </Row>
                        <Form onSubmit={handleSubmit} style={{ padding: '1%' }} layout="vertical">
                            <Row gutter={16}>
                                <Col span={12} xs={24} sm={24} md={12} lg={12}>
                                    <Form.Item label={'Name'}>
                                        {getFieldDecorator('name',
                                            {
                                                initialValue: isClone ? '' : slaPolicy.name,
                                                rules: [{
                                                    required: true,
                                                    message: 'SLA Name is required.'
                                                }, { validator: validateNameUniqueness }
                                                ]
                                            })(
                                            <Input type="text" placeholder="Name" maxLength={80} disabled={isView}
                                                onChange={(e) => {
                                                    slaPolicy.name = e.target.value.replace(/ +/g, ' ').trim()
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12} xs={24} sm={24} md={12} lg={12}>
                                    <Form.Item label={'SLA Description'}>
                                        {getFieldDecorator('description', {
                                            initialValue: slaPolicy.description,
                                            rules: [{ validator: validateDescription }]
                                        })(
                                            <Input type="text" placeholder="SLA Description"
                                                disabled={isView} onChange={(e) => {
                                                    slaPolicy.description = e.target.value.replace(/ +/g, ' ').trim()
                                                }}
                                                maxLength={120}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12} xs={24} sm={24} md={12} lg={12}>
                                    <Form.Item label={'SLA Goal'}>
                                        {getFieldDecorator('SLAGoal', {
                                            initialValue: (slaPolicy.slaGoals || []).map((it) => it.name),
                                            rules: [
                                                { required: true, message: 'SLA Goal is required.', type: 'array' }
                                            ]
                                        })(
                                            <Select mode="tags" onChange={onSlaGoalNamesChanged}
                                                placeholder="Select a SLA-Goal" disabled={isView}>
                                                <Option value="Response">Response</Option>
                                                <Option value="Resolution">Resolution</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={5} xs={24} sm={24} md={5} lg={5}>
                                    <SpinningFetcher fetchingState={businessHoursFetchingState}>
                                        <Form.Item label={'Business Hours'}>
                                            {getFieldDecorator('businessHourId', {
                                                initialValue: slaPolicy.businessHourId,
                                                rules: [{ required: true, message: 'Business Hours are required.' }]
                                            })(
                                                <Select placeholder="Select business hours"
                                                    disabled={isView} onChange={(value) => {
                                                        setIsRequireConditionValue(false)
                                                        setIsRequireConditionKey(false)
                                                        slaPolicy.businessHourId = value as string | undefined
                                                    }}>
                                                    {businessHours.map((businessHour: BusinessHour) => {
                                                        if (!businessHour.activate || businessHour.validThrough) {
                                                            return <Option value={businessHour.id}
                                                                style={{ display: 'none' }}
                                                                key={businessHour.id}>{businessHour.name}</Option>
                                                        } else {
                                                            return <Option value={businessHour.id}
                                                                key={businessHour.id}>{businessHour.name}</Option>
                                                        }
                                                    })}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </SpinningFetcher>
                                </Col>
                                <Col span={7} xs={24} sm={24} md={7} lg={7}>
                                    <Form.Item label='Ticket Type'>
                                        {getFieldDecorator('ticketType', {
                                            initialValue: slaPolicy.ticketType,
                                            rules: [{ required: true, message: 'Ticket type is required.' }]
                                        })(
                                            <Select placeholder="Select an Ticket Type" disabled={isView}
                                                onChange={(value) => {
                                                    slaPolicy.ticketType = value as string
                                                }}>
                                                {props.generalIncidentSetting.ticketTypes?.map((item, index) => {
                                                    return (<Option value={item.value} key={index}>{item.value}</Option>)
                                                })}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br />
                            <div>
                                <Row>
                                    <Col span={24}>
                                        <span><p className={'sub-title'}>SLA Targets</p></span>
                                    </Col>
                                </Row>
                                <div className={'background-form-criteria'}>
                                    <Row gutter={[24, 8]}>
                                        <Col span={12} xs={24} sm={24} md={12} lg={12}>
                                            <span><p className={'sub-title'}>Criteria</p></span>
                                            <div>
                                                <Form.Item label='When a new request arrives :'>
                                                    {getFieldDecorator('conditionsMatchingRequirement', {
                                                        initialValue: slaPolicy.conditionsMatchingRequirement,
                                                        rules: [{ required: true, message: 'Required' }]
                                                    })(
                                                        <Radio.Group disabled={isView} onChange={(e) => {
                                                            slaPolicy.conditionsMatchingRequirement = e.target.value
                                                        }}>
                                                            <Radio
                                                                value={ConditionsMatchingRequirement.MeetAllConditions}
                                                                style={{ fontSize: 13 }}>Match
                                                                ALL of the following (AND)</Radio>
                                                            <Radio
                                                                value={ConditionsMatchingRequirement.MeetAnyConditions}
                                                                style={{ fontSize: 13 }}>Match
                                                                ANY of the following (OR)</Radio>
                                                        </Radio.Group>
                                                    )}
                                                </Form.Item>
                                            </div>
                                            <Row gutter={8}>
                                                <Col span={6} xs={24} sm={24} md={10} lg={6}
                                                    className={'deletePadding2'}>
                                                    <Form.Item label={'Criteria'}>
                                                        {getFieldDecorator('conditionKey', {
                                                            rules: [{
                                                                required: nullOrEmptyObject(slaPolicy.conditions),
                                                                message: 'Criteria is required'
                                                            }]
                                                        })(
                                                            <Select placeholder="Select a criteria"
                                                                onSelect={(value) => onSelectConditionKey(value as string)}
                                                                onChange={(e) => onChangeConditionKey(e)}
                                                                disabled={isView}
                                                                className={isRequireConditionKey ? 'selectBorder' : undefined}
                                                                allowClear={true}>
                                                                <Option value="supportTeamId">Group</Option>
                                                                <Option value="userId">Assignee</Option>
                                                                <Option value="priorityId">Priority</Option>
                                                            </Select>
                                                        )}
                                                    </Form.Item>
                                                    {isRequireConditionKey ? (
                                                        <p style={{ color: 'red' }}>Criteria is required</p>) : null}
                                                </Col>
                                                <Col span={1} style={{
                                                    textAlign: 'center',
                                                    paddingTop: 7,
                                                    marginTop: 27
                                                }}><span>is</span></Col>
                                                <Col span={13} xs={24} sm={24} md={13} lg={13} style={{ marginTop: 27 }}
                                                    className={'deletePadding1'}>
                                                    <Form.Item>
                                                        {getFieldDecorator('conditionValue', {})(
                                                            <Select placeholder="Select a option"
                                                                onSelect={(value) => onSelectConditionValue(value as string)}
                                                                disabled={isView}
                                                                style={{ borderColor: isRequireConditionValue ? 'red' : undefined }}
                                                                className={isRequireConditionKey ? 'selectBorder' : undefined}
                                                                allowClear={true}
                                                                showSearch={conditionKey === 'userId'}
                                                                filterOption={(inputValue, option) =>
                                                                    String(option.props.children)?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                                                                }
                                                                onSearch={(value) => { handleSearchPeople(value) }}>
                                                                {dataCriteria.map((res, index) => {
                                                                    return <Option value={res.id} key={index}>{res.name}</Option>
                                                                })}
                                                            </Select>
                                                        )}
                                                    </Form.Item>
                                                    {isRequireConditionValue ? (
                                                        <p style={{ color: 'red' }}>Condition value is
                                                            required</p>) : null}
                                                </Col>
                                                <Col span={3} xs={24} sm={24} md={24} style={{ marginTop: 27 }}>
                                                    <Form.Item>
                                                        <Button onClick={submitAddCondition} htmlType="button"
                                                            disabled={isView} className="addCriteriaButton">Add to
                                                            rules</Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <span className={'verticalLine'} />
                                        <Col span={12} xs={24} sm={24} md={12} lg={12}>
                                            <p className={'sub-title'}>Rules Set</p>
                                            {buildConditionForm()}
                                        </Col>
                                    </Row>
                                    <br />
                                </div>
                                <div>
                                    <Row gutter={[8, 8]}>
                                        <Col span={14}>
                                            <p className={'sub-title'}>Targets Time</p>
                                            {buildTargetTimeForm()}
                                        </Col>
                                        <Col span={10} />
                                    </Row>
                                </div>
                                <br />
                                <div>
                                    <Row>
                                        <Col span={24}>
                                            <p className={'sub-title'}>Measurement</p>
                                            {buildMeasurementForm()}
                                        </Col>
                                        {/* <Col span={6}/> */}
                                    </Row>
                                </div>
                            </div>
                            <div style={{ float: 'right' }}>
                                <Form.Item>
                                    <Button type="default" htmlType="button"
                                        onClick={() => modalConfirmCancel()}
                                        style={{ marginRight: 10 }}>
                                        Cancel
                                    </Button>
                                    <Button type="primary" htmlType="submit"
                                        disabled={isView} loading={isLoading}
                                    >
                                        Submit
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </>
    )

    return (
        <SpinningFetcher fetchingState={[statusesFetchingState, slaPolicyFetchingState]}>{formBody}</SpinningFetcher>)
}

const SlaPolicyForm = Form.create({ name: 'businessHour_Page' })(SlaPolicyFormCreate)

export default connect(mapStateToProps,
    {
        getAllSlaPolicy, getAllBusinessHour, addSlaPolicy, updateSlaPolicy, getAllStatus, getAllGeneralIncidentSetting, getAllSupportTeam, getAllPriority
    })(SlaPolicyForm)
