import { Button, Col, Divider, Dropdown, Form, Icon, Menu, Row, Spin, message } from 'antd'
import React, { useEffect, useState } from 'react'
import ViewSelection from '../incident-view/ViewSelection'
import { AgentFilter, CategoryFilter, CreatedFilter, PriorityFilter, RequesterFilter } from '../incident-view/incident-filter'
import TicketTypeFilter from '../incident-view/incident-filter/TicketTypeFilter'
import StatusFilter from '../incident-view/incident-filter/StatusFilter'
import { IncidentFilter, IncidentView, UserChangeOrderByEvent, UserChangeSortByEvent, notifyCurrentViewCriteriaChanged, setCurrentIncidentView, updateIncidentView } from '../incident-view'
import { TicketStatus } from '.'
import { Categories, getAllCategory } from '../category'
import { PriorityLevel, getAllPriority } from '../priority'
import { IncidentViewState } from '../incident-view/state-model'
import { ResponseValueWithId } from '../general-incident-setting'
import moment from 'moment'
import { getAllStatusUnique, getAllStatus } from '../status'
import { connect, useDispatch } from 'react-redux'
import { StoreState } from '../../store'
import SortAndOrderTicketFilter from '../incident-view/incident-filter/SortAndOrderTicketFilter'
import { FormComponentProps } from 'antd/lib/form'
import { getAllSupportTeamByPermission } from '../support-team'
import { getAllGeneralIncidentSetting } from '../general-incident-setting/service'
import IncidentViewSaveAsModalForm from '../incident-view/IncidentViewSaveAsModalForm'
import DeleteFilterIncident from './DeleteFilterIncident'

const mapStateToProps = (state: StoreState) => {
    return {
        statuses: state.statuses,
        categories: state.categories,
        impacts: state.impacts,
        priorities: state.priorities,
        incidentViewState: state.incidentViewState,
        // criteria: state.incidentViewState.currentView!!.criteria,
        channels: state.generalIncidentSetting?.channels!!,
        ticketTypes: state.generalIncidentSetting?.ticketTypes!!
    }
}

interface StateProps {
    statuses: TicketStatus[]
    categories: Categories[]
    impacts: PriorityLevel[]
    priorities: PriorityLevel[]
    incidentViewState: IncidentViewState
    // criteria: IncidentFilter
    notifyCurrentViewCriteriaChanged: (criteria: IncidentFilter) => Promise<void>
    channels: ResponseValueWithId[]
    ticketTypes: ResponseValueWithId[]
}

interface Param {
    isSearchVisible: boolean
    handleSubmitFilter: Function
}

interface DispatchProps {
    getAllStatus: (status: string | undefined) => Promise<number>
    getAllSupportTeamByPermission: () => Promise<number>
    getAllCategory: () => Promise<number>
    getAllPriority: () => Promise<number>
    getAllGeneralIncidentSetting: () => Promise<number>
    setCurrentIncidentView: (name: string) => Promise<number>
    updateIncidentView: (data: IncidentView) => Promise<number>
}

type Props = StateProps & Param & FormComponentProps & DispatchProps

const FilterIncident: React.FC<Props> = (props: Props) => {
    const [criteria, setCriteria] = useState<IncidentFilter | undefined>()
    const [TicketStatus, setTicketStatus] = useState<TicketStatus[]>([])
    const notifyCurrentViewCriteriaChanged = props.notifyCurrentViewCriteriaChanged as (IncidentFilter) => Promise<undefined>
    // const resolveFieldLabel = (fieldName: string) => IncidentFieldLabel.mapping[fieldName] || ''
    const dispatch = useDispatch()
    const [sortBy, setSortBy] = useState<string>(props.incidentViewState.currentView?.incFilter.sortBy!!)
    const [orderBy, setOrderBy] = useState<string>(props.incidentViewState.currentView?.incFilter.orderBy!!)
    const [isLoadingFilterTemplate, setIsLoadingFilterTemplate] = useState<boolean>(false)
    const [isShowingSaveAsModalForm, setIsShowingSaveAsModalForm] = useState<boolean>(false)
    const [isVisibleDeleteFilter, setIsVisibleDeleteFilter] = useState<boolean>(false)

    useEffect(() => {
        getAllStatusUnique().then((res) => {
            setTicketStatus(res)
        })
        const promiseAll: any[] = []
        promiseAll.push(props.getAllSupportTeamByPermission())
        promiseAll.push(props.getAllCategory())
        promiseAll.push(props.getAllGeneralIncidentSetting())
        Promise.all(promiseAll).catch((err) => {
            message.error(`Unable to retrieve the data. Please try again later. ${err}`)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.incidentViewState?.currentView?.criteria || props.incidentViewState?.currentView?.incFilter) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // criteria = props.incidentViewState?.currentView?.criteria || props.incidentViewState?.currentView?.incFilter
            setCriteria(props.incidentViewState?.currentView?.criteria || props.incidentViewState?.currentView?.incFilter)
            setSortBy(props.incidentViewState.currentView?.incFilter.sortBy!! || 'lastModifiedDatetime')
            setOrderBy(props.incidentViewState.currentView?.incFilter.orderBy!! || 'DESC')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.incidentViewState])

    useEffect(() => {
        if (!props.isSearchVisible) {
            setCriteria(props.incidentViewState?.currentView?.criteria || props.incidentViewState?.currentView?.incFilter)
            setSortBy(props.incidentViewState.currentView?.incFilter.sortBy!! || 'lastModifiedDatetime')
            setOrderBy(props.incidentViewState.currentView?.incFilter.orderBy!! || 'DESC')
            setIsVisibleDeleteFilter(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isSearchVisible])

    const modifyCriteria = (criteria: IncidentFilter) => {
        notifyCurrentViewCriteriaChanged(criteria).catch((error) => message.error(error.toString()))
    }

    const onAgentCriteriaChanged = (value: string[]) => {
        // modifyCriteria({
        //     ...criteria,
        //     assignedToMe: value.includes('assignedToMe'),
        //     unassigned: value.includes('unassigned'),
        //     assignedToMyTeam: value.includes('assignedToMyTeam')
        // })
        setCriteria({
            ...criteria,
            assignedToMe: value.includes('assignedToMe'),
            unassigned: value.includes('unassigned'),
            assignedToMyTeam: value.includes('assignedToMyTeam')
        })
    }

    const onCategoryChanged = (value: string[]) => {
        const tempValue = value.length > 0 ? value.join(',') : undefined
        // modifyCriteria({ ...criteria, categories: tempValue })
        setCriteria({ ...criteria, categories: tempValue })
    }

    const onRequesterChanged = (value: string | undefined) => {
        // modifyCriteria({ ...criteria, requesterName: value })
        if (value) {
            setCriteria({ ...criteria, requesterName: value })
        } else {
            setCriteria({ ...criteria, requesterName: undefined })
        }
    }

    const onTicketTypeChanged = (value: string[]) => {
        const tempValue = value.length > 0 ? value.join(',') : undefined
        // modifyCriteria({ ...criteria, ticketTypes: tempValue })
        setCriteria({ ...criteria, ticketTypes: tempValue })
    }

    const onStatusChanged = (value: string[]) => {
        const tempValue = value.length > 0 ? value.join(',') : undefined
        // modifyCriteria({ ...criteria, statuses: tempValue })
        setCriteria({ ...criteria, statuses: tempValue })
    }

    const onPriorityChanged = (value: string[]) => {
        const tempValue = value.length > 0 ? value.join(',') : undefined
        // modifyCriteria({ ...criteria, priorities: tempValue })
        setCriteria({ ...criteria, priorities: tempValue })
    }

    const onCreatedValueChanged = (value: string | undefined | string[]) => {
        const patch: IncidentFilter = {}
        if (typeof value === 'string' || value === undefined) {
            patch.created = value as string
            if (value === undefined) {
                patch.created = 'anytime'
                patch.createdStart = undefined
                patch.createdEnd = undefined
            }
        } else {
            const format = (time: string) => moment(time).format('YYYY-MM-DD')
            patch.createdStart = format(value[0])
            patch.createdEnd = format(value[1])
            patch.created = undefined
        }
        // modifyCriteria({ ...criteria, ...patch })
        setCriteria({ ...criteria, ...patch })
    }

    const reload = () => {
        // notifyCurrentViewCriteriaChanged(props.incidentViewState?.currentView?.incFilter)
        props.setCurrentIncidentView(props.incidentViewState.currentView?.name!!).finally(() => {
            onChangedView()
        })
        // setCriteria(props.incidentViewState?.currentView?.incFilter)
        // setSortBy(props.incidentViewState.currentView?.incFilter.sortBy!!)
        // setOrderBy(props.incidentViewState.currentView?.incFilter.orderBy!!)
        // setIsLoadingFilterTemplate(true)
        // setTimeout(() => setIsLoadingFilterTemplate(false), 1000)
    }

    const onSubmitFilter = () => {
        dispatch(UserChangeSortByEvent.build(sortBy))
        dispatch(UserChangeOrderByEvent.build(orderBy))
        modifyCriteria({ ...criteria })
        props.handleSubmitFilter()
    }

    const onChangedView = () => {
        // notifyCurrentViewCriteriaChanged(props.incidentViewState?.currentView?.incFilter)
        setCriteria(props.incidentViewState.currentView?.incFilter)
        setSortBy(props.incidentViewState.currentView?.incFilter.sortBy!! || 'lastModifiedDatetime')
        setOrderBy(props.incidentViewState.currentView?.incFilter.orderBy!! || 'DESC')
    }

    const isOverwritingAllowedForCurrentView = () => props.incidentViewState.currentView?.isPredefined === false

    const onSaveIconClicked = () => {
        const tempsenddata = props.incidentViewState.currentView!!
        tempsenddata.incFilter = criteria!!
        // props.updateIncidentView(props.incidentViewState.currentView!!).then((res: number) => {
        //     message.success('The data has been updated successfully.')
        // })
        tempsenddata.incFilter.sortBy = sortBy
        tempsenddata.incFilter.orderBy = orderBy
        props.updateIncidentView(tempsenddata).then((res: number) => {
            message.success('The data has been updated successfully.')
        })
    }

    const onSaveAsIconClicked = () => {
        setIsShowingSaveAsModalForm(true)
    }

    const onSaveAsModalFormClosed = (isSuccess: boolean) => {
        setIsShowingSaveAsModalForm(false)
    }

    const menu = (
        <Menu>
            {isOverwritingAllowedForCurrentView() ? <Menu.Item key="1" onClick={onSaveIconClicked}>
                <Icon type="download" /> Save
            </Menu.Item> : null}
            <Menu.Item key="2" onClick={onSaveAsIconClicked}>
                <Icon type="save" /> Save as
            </Menu.Item>
        </Menu>
    )

    return (
        <Spin spinning={isLoadingFilterTemplate}>
            {!isVisibleDeleteFilter ? <> <Row>
                <Col span={8} />
                <Col span={16}>
                    <span style={{ float: 'right' }}>
                        <span className='text-underline' style={{ marginRight: 10 }}>
                            <Dropdown overlay={menu}>
                                <span ><Icon type="save" /> Save view <Icon type="down" /></span>
                            </Dropdown>
                        </span>
                        <span className='text-underline' onClick={() => setIsVisibleDeleteFilter(true)} ><Icon type='delete' /> Delete view</span>
                    </span>
                </Col>

            </Row>
            <br />
            <ViewSelection reload={reload} criteria={criteria} onChangedView={onChangedView} sortBy={sortBy} orderBy={orderBy} setIsLoadingFilterTemplate={setIsLoadingFilterTemplate} />
            <span className='feck-link' onClick={() => {
                reload()
            }} style={{ float: 'right' }}>Reset to Default</span><br /><br />
            <Divider style={{ margin: '0px 0px 10px 0px' }} />
            <AgentFilter key={0} onChanged={onAgentCriteriaChanged} criteria={criteria} /><br />
            <SortAndOrderTicketFilter key={2} sortBy={sortBy} orderBy={orderBy} onChangedSort={setSortBy} onChangedOrder={setOrderBy} />
            <TicketTypeFilter criteria={criteria} onChanged={onTicketTypeChanged} ticketTypes={props.ticketTypes} /><br />
            <RequesterFilter key={1} onChanged={onRequesterChanged} criteria={criteria} /><br />

            {/* <DueByFilter key={2} onChanged={onDueByChanged} criteria={criteria}/><br/> */}

            <CreatedFilter key={3} onChanged={onCreatedValueChanged} criteria={criteria} /><br />
            <StatusFilter key={4} statuses={TicketStatus} onChanged={onStatusChanged} criteria={criteria} /><br />
            <PriorityFilter key={6} priorities={props.priorities} onChanged={onPriorityChanged} criteria={criteria} /><br />
            <CategoryFilter key={5} categories={props.categories} onChanged={onCategoryChanged} criteria={criteria} /><br />

            <Form.Item style={{ textAlign: 'end' }}>
                <Button type="primary" htmlType="button" onClick={() => { onSubmitFilter() }} style={{ marginLeft: 5 }} >
                        Submit
                </Button>
            </Form.Item>
            {isShowingSaveAsModalForm
                ? <IncidentViewSaveAsModalForm onClose={onSaveAsModalFormClosed} onOpen={isShowingSaveAsModalForm} criteria={criteria} sortBy={sortBy} orderBy={orderBy} />
                : null
            }
            </> : <DeleteFilterIncident isVisibleDeleteFilter={isVisibleDeleteFilter} setIsVisibleDeleteFilter={setIsVisibleDeleteFilter} />}
        </Spin>
    )
}

const wrappedIncidentFilter = Form.create<Props>({ name: 'incidentFIlter' })(FilterIncident)
export default connect(mapStateToProps, {
    notifyCurrentViewCriteriaChanged,
    getAllStatus,
    getAllSupportTeamByPermission,
    getAllCategory,
    getAllPriority,
    getAllGeneralIncidentSetting,
    setCurrentIncidentView,
    updateIncidentView
})(wrappedIncidentFilter)
