import React from 'react'
import { Result, Button } from 'antd'

const listPath = ['/incidentdetailsview', '/reportdashboard', '/reportdashboardnonesla', '/reportsatdashboard', '/workflowformview', '/knowledgerelatedpreview',
    '/home', '/wolist', '/woform', '/woformedit', '/assetlist', '/assetform', '/reportasset', '/assetpreview', '/preventive', '/completepm', '/completepmformview', '/completepmform',
    '/report', '/pmstep', '/locationforsign', '/selectlocationforprint', '/dashboard-all', '/incidentlist', '/incidentreport', '/incidentform', '/setting', '/globalsetting', '/categorysetting',
    '/prioritysetting', '/slapolicysettinglist', '/slapolicysettingform', '/slapolicysettingformview', '/slapolicysettingformedit', '/slapolicysettingformclone', '/businesshoursettinglist',
    '/businesshoursettingform', '/businesshoursettingformview', '/businesshoursettingformedit', '/businesshoursettingformclone', '/knowledge', '/knowledgeform', '/knowledgeformedit',
    '/knowledgepreview', '/knowledge-report', '/approvalcenter', '/approvalpreview', '/supportteamsettinglist', '/supportteamsettingform', '/usersetting', '/usersettingform', '/usersettingpreview',
    '/usersettingeditform', '/peoplesetting', '/roleandpermissionssettinglist', '/roleandpermissionssettingform', '/roleandpermissionssettingeditform', '/roleandpermissionssettingpreview',
    '/generalincidentsettingform', '/configurationfiledsettinglist', '/companysetting', '/cataloglist', '/catalogview', '/itemcatalogview', '/itemcatalog', '/categoryview', '/workflowhome',
    '/workflowform', '/workflowformupdate', '/tenantprofile', '/subscriptionlist', '/packagedetail', '/orderdetail', '/payinvoice', '/paymentconfirmation', '/403', '/404', '/problem-priority',
    '/problem-general', '/problemlist', '/problemform', '/problemdetailview', '/asset-general', '/wodashboard', '/woreport', '/syncdata']

const Page404 = () => {
    return (
        <div style={!listPath.includes('/' + window.location.pathname.split('/')[1].toLowerCase()) ? { display: 'block' } : { display: 'none' }}>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Button type="primary" onClick={() => { window.location.href = '/home' }}>
                    Back Home
                </Button>}
            />
        </div>
    )
}

export default Page404
