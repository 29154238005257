import React, { useEffect, useState } from 'react'
import { UploadLink } from '../../../file-server-storage/model'
import exportIcon from '../../../common-file/icon-file'
import { UploadFile } from 'antd/lib/upload/interface'
import { Icon, Modal, Row, Upload, message, Col, Empty } from 'antd'
import { DownloadAnyFile } from '../../../common-misc'

interface Params {
    Data?: string
    getDataFiles?: Function
    disableItem?: boolean
    isFromSyncPMAsset?: boolean
    customHeader?: string
}

type Props = Params
const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)
const FileListView: React.FC<Props> = (props: Props) => {
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [fileList, setFileList] = useState<UploadFile[]>([])
    let loading = false

    useEffect(() => {
        if (props.Data) {
            const attachFile = JSON.parse(props.Data || '[]')
            const fileList = attachFile?.map((file: UploadLink) => {
                const uploadFile: UploadFile = {
                    uid: file.name,
                    name: file.name,
                    status: 'done',
                    url: file.url,
                    type: file.type,
                    thumbUrl: exportIcon(file.type),
                    size: 0
                }
                return uploadFile
            })
            setFileList(fileList)
        } else {
            if (!props.Data) {
                setFileList([])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.Data, props.disableItem])

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png') || ((file.url) as string).includes('jpeg') || ((file.url) as string).includes('PNG')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const uploadProps = {
        multiple: true,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                if (props.getDataFiles) {
                    props.getDataFiles(newFileList)
                }
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('You are only allowed to upload image files.')
                return false
            }
            loading = true
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                console.log(e)
                if (file.size > fileSize) {
                    message.error('The maximum allowed file size is 5MB.')
                    return false
                }
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                setFileList(fillOffFileList)
                console.log(fileList)
                if (props.getDataFiles) {
                    props.getDataFiles(fileList)
                }
                loading = false
            }
            return false
        },
        showUploadList: { showDownloadIcon: true, showRemoveIcon: true },
        onDownload: (file) => {
            console.log(file)
            if (file.url.startsWith('https://')) {
                window.open(file.url)
            } else {
                message.warning("Unable to download the file. Please try again later.")
            }
        }
    }

    return (
        <div>
            <Row>
                <Col md={1} lg={props.isFromSyncPMAsset ? 2 : 5} />
                <Col md={22} lg={props.isFromSyncPMAsset ? 20 : 14} style={{ backgroundColor: '#F0F0F0', padding: 5, borderRadius: 5 }}>
                    {props.customHeader ? <p className="main-title" style={{ margin: 12 }}>{props.customHeader}</p> : <p className="main-title" style={{ margin: 12 }}>Original Images Asset</p> }
                </Col>
                <Col lg={props.isFromSyncPMAsset ? 2 : 5} />
            </Row>
            <Row >
                <Col md={1} lg={props.isFromSyncPMAsset ? 2 : 5} />
                <Col style={{ zIndex: 5, height: fileList.length <= 9 ? 150 : 230}} lg={props.isFromSyncPMAsset ? 20 : 14} className="Box-Picture" md={22}>
                    <Upload
                        {...uploadProps}
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={(e: any) => handlePreview(e)}
                        style={{ height: '200 px', width: '200 px' }}
                        disabled={props.disableItem}
                    >
                        {props.isFromSyncPMAsset ? null : fileList && fileList.length >= 20 ? null : <div>
                            <Icon type={loading ? 'loading' : 'plus'} />
                            <div className="ant-upload-text">Upload</div>
                        </div>}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={(e: any) => setPreviewVisible(false)} >
                        <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                    {props.isFromSyncPMAsset && fileList.length === 0 ? <Empty /> : null}
                </Col>
                <Col md={1} lg={props.isFromSyncPMAsset ? 2 : 5} />
            </Row>
        </div>

    )
}

export default FileListView
