import React, { useEffect, useState } from 'react'
import { AutoComplete, Button, Col, Form, Layout, message, Modal, Row, Select, Spin, Tooltip } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { Asset } from './model'
import { updateAsset } from './service'
import { StoreState } from '../../../store'
import { connect } from 'react-redux'
import { AssetOwnerLocation, getAssetFilter, searchFetch, updateAssetOwnerLocation } from '../../asset-management'
import { decryptDataVspace } from '../../../common-misc'
import { deleteAssetRelateWo, WorkOrder } from '../../wo-management'
import { RoleType, checkRolesState } from '../../../authorization-module/permissions'
import { LocationBuilding } from '../../../Preventive/Model'
import { getAllUsersByUserId } from '../../../authorization-module/user-role/duck/action'
import { People } from '../../../authorization-module/people/model'
import { DataSourceItemObject } from 'antd/lib/auto-complete'

interface Param {
    data: Asset
    deleteData: Function
    isDisableAsset: boolean
    woId?: string
    setLoadData: Function
    locationBuildings: LocationBuilding[]
    wo?: WorkOrder
}

const mapStateToProps = (state: StoreState) => {
    return {
        asset: state.asset,
        filterAsset: state.filterAsset
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    updateAsset: (value: Asset) => Promise<number>
    getAssetFilter: () => Promise<number>
}

type Props = Param & FormComponentProps & StateProps & DispatchProps
const { Content } = Layout
const { Option } = AutoComplete

const AssetContent: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, getFieldsError, getFieldValue, setFieldsValue } = props.form
    const data = props.data
    const [vSpaceName, setVspaceName] = useState<string>('')
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [location, setLocation] = useState<string[]>(props.filterAsset.location?.slice(0, 20) || [])
    const [employeeID, setEmployeeID] = useState<string[]>(props.filterAsset.employeeID?.slice(0, 20) || [])
    const [isSearchEmployee, setIsSearchEmployee] = useState<boolean>(false)
    const [dataEmployee, setDataEmployee] = useState<People[]>([])
    const [optionLocation, setOptionLocation] = useState<LocationBuilding[]>([])
    const [optionBuilding, setOptionBuilding] = useState<LocationBuilding[]>([])
    const [optionFloor, setOptionFloor] = useState<LocationBuilding[]>([])
    // const [LocationBuildings, setLocationBuildings] = useState<LocationBuilding[]>([])

    useEffect(() => {
        const dataVspace = decryptDataVspace()
        if (typeof dataVspace === 'object') {
            const email = dataVspace.email
            setVspaceName(email)
            console.log(vSpaceName)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (getFieldValue('employeeId') !== undefined || '') {
            const timer = setTimeout(() => {
                setIsSearchEmployee(true)
            }, 1000)
            return () => clearTimeout(timer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFieldValue('employeeId')])

    useEffect(() => {
        if (isSearchEmployee) {
            getAllUsersByUserId(props.form.getFieldValue('employeeId')).then((res) => {
                if (res) {
                    setDataEmployee(res)
                }
            }).catch(() => {
                message.warning('Data is null')
            }).finally(() => {
                setIsSearchEmployee(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSearchEmployee])

    if (Object.keys(props.filterAsset).length === 0) {
        props.getAssetFilter().then(() => {
            setEmployeeID(props.filterAsset.employeeID?.slice(0, 20) || [])
            console.log(employeeID)
            setLocation(props.filterAsset.location?.slice(0, 20) || [])
            console.log('load filter success')
            console.log(location)
        })
    }

    function hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field])
    }

    const save = (e) => {
        e.preventDefault()
        props.form.validateFields(async (_err: any, values: any) => {
            const findIdEmployee = dataEmployee.find((it) => { return it.employeeId === values.employeeId })
            const findLocation = props.locationBuildings.find((it) => { return it.location === values.location })
            setLoading(true)
            const dataUpdate = data
            // dataUpdate.employeeId = values.employeeId
            dataUpdate.people = findIdEmployee ? { id: findIdEmployee.id, employeeId: findIdEmployee.employeeId, fullName: findIdEmployee.fullName } as any : null
            dataUpdate.location = findLocation?.location
            dataUpdate.status = values.status
            dataUpdate.site = values.site || null
            dataUpdate.building = values.building || null
            dataUpdate.floor = values.floor || null
            dataUpdate.createdDatetime = undefined
            // dataUpdate.lastModifiedBy = vSpaceName
            // props.updateAsset(dataUpdate).then(() => {
            //     setLoading(false)
            // }).catch((err) => {
            //     setLoading(false)
            //     message.error(`You have unSuccessfully Update the data. ${err}`)
            // })
            updateAssetOwnerLocation(data.id!!, dataUpdate as unknown as AssetOwnerLocation).then((res) => {
                if (res) {
                    message.success('The update have finished successfully.')
                    setIsEdit(!isEdit)
                    setLoading(false)
                }
            }).catch((err) => {
                setLoading(false)
                message.error(`You have unSuccessfully Update the data. ${err}`)
            })
        })
    }

    const modalConfirmRemove = (): void => {
        Modal.confirm({
            title: <span style={{ fontSize: '25px', fontWeight: 'bold', color: '#323276' }}>Are you sure?</span>,
            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>Do you confirm that you want to delete this data ?</span>,
            okText: 'OK',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'Cancel',
            className: 'custom-modal-wo',
            onOk() {
                // window.location.href = '/Setting'
                if (props.deleteData) {
                    console.log('delete : ', props.data)
                    props.setLoadData(true)
                    props.deleteData(props.data.id)
                    if (window.location.pathname.includes('WoFormEdit')) {
                        deleteAssetRelateWo(props.woId, props.data.serialNumber).catch((err) => {
                            message.error(`Unable to delete data. Please try again later. ${err}`)
                        }).finally(() => {
                            props.setLoadData(false)
                        })
                    } else {
                        props.setLoadData(false)
                    }
                }
            }
        })
    }

    const modalConfirmCancelEdit = (): void => {
        Modal.confirm({
            title: 'Are you sure?',
            content: 'Do you want to cancel this data ?',
            okText: 'Yes',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'No',
            onOk() {
                setIsEdit(!isEdit)
            }
        })
    }

    useEffect(() => {
        const filterSite = props.locationBuildings.filter((it) => it.site === getFieldValue('site'))
        const checkUnique = [...new Map(filterSite.map((item) =>
            [item.location, item])).values()]
        const filterDataNotNull = checkUnique.filter((it) => it.location !== null)
        setOptionLocation(filterDataNotNull)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFieldValue('site')])

    useEffect(() => {
        const filterLocation = props.locationBuildings.filter((it) => it.location === getFieldValue('location'))
        const checkUnique = [...new Map(filterLocation.map((item) =>
            [item.building, item])).values()]
        const filterDataNotNull = checkUnique.filter((it) => it.building !== null)
        setOptionBuilding(filterDataNotNull)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFieldValue('location')])

    useEffect(() => {
        const filterBuilding = props.locationBuildings.filter((it) => it.location === getFieldValue('location') && it.building === getFieldValue('building'))
        const checkUnique = [...new Map(filterBuilding.map((item) =>
            [item.floor, item])).values()]
        const filterDataNotNull = checkUnique.filter((it) => it.floor !== null)
        setOptionFloor(filterDataNotNull)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFieldValue('building')])

    return (
        <Content className='TicketRelateCard' style={{ margin: '0px 0px', padding: 5, marginBottom: 8, borderRadius: '12px' }} >
            {!isEdit ? <>
                {/* <Row>
                    <Col style={{ textAlign: 'right' }}>
                        {props.data.isDeleted !== true
                            ? <>
                                <Button type="default" icon="edit" size="small" onClick={() => setIsEdit(!isEdit)} disabled={(!checkRolesState(RoleType.WO, 'UpdateWorkOrder') && !checkRolesState(RoleType.WO, 'UpdateJob')) || props.isDisableAsset} />&nbsp;
                            </> : null}
                        <Button type="default" icon="delete" size="small" onClick={() => modalConfirmRemove()} disabled={!checkRolesState(RoleType.WO, 'UpdateWorkOrder') || props.isDisableAsset} />
                    </Col>
                </Row> */}
                <Row style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                    <Col span={20} md={20} xl={20} lg={20} style={{ color: '#FA541C', fontSize: '16px', fontWeight: 'bold' }}> Serial Number :
                        <span> {props.data.serialNumber}</span>
                    </Col>
                    <Col span={4} xl={4} lg={4} md={4} style={{ textAlign: 'end' }}>
                        {props.data.isDeleted !== true
                            ? <>
                                <Tooltip placement='bottom' title='Edit'>
                                    <Button type="default" icon="edit" size="default" onClick={() => setIsEdit(!isEdit)} disabled={props.wo?.status === 'Closed' ? true : props.woId ? !checkRolesState(RoleType.WO, 'UpdateWorkOrder') || !checkRolesState(RoleType.WO, 'UpdateJob') || props.isDisableAsset : true} />&nbsp;
                                </Tooltip>
                            </> : null}
                        <Tooltip placement='bottom' title='Delete'>
                            <Button type="default" icon="delete" size="default" onClick={() => modalConfirmRemove()} disabled={props.wo?.status === 'Closed' ? true : props.woId ? !checkRolesState(RoleType.WO, 'UpdateWorkOrder') || props.isDisableAsset : false} />
                        </Tooltip>
                        {/* <Icon onClick={() => modalConfirmRemove()} type="delete" /> */}
                    </Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20 }} gutter={16}>
                    <Col span={12} className="TextFrontDesc"> Asset Name </Col>
                    <Col span={12} className="TextFrontDesc"> Group </Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20 }} gutter={16}>
                    <Col span={12} style={{ borderBottom: '1px solid #ddd', paddingBottom: 10 }} className="Desc_text_gray">{props.data.assetName || '-'}</Col>
                    <Col span={12} style={{ borderBottom: '1px solid #ddd', paddingBottom: 10 }} className="Desc_text_gray">{props.data.assetGroup?.name || '-'}</Col>
                </Row>
                {/* <Row style={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
                    <Col span={12} xs={24} sm={24} md={24} lg={12} className="TextFrontDesc">  Owner ID : <span className="Desc_text_gray_2"> {(props.data.people?.employeeId || '').length > 20
                        ? <Tooltip placement="bottom" title={props.data.people?.employeeId}> {props.data.people?.employeeId?.substring(0, 20)}... </Tooltip> : props.data.people?.employeeId}
                    </span>
                    </Col>
                    <Col span={12} xs={24} sm={24} md={24} lg={12} className="TextFrontDesc"> Location : <span className="Desc_text_gray_2"> {(props.data.location || '').length > 50
                        ? <Tooltip placement="bottom" title={(props.data.location || '')}> {(props.data.location || '').substring(0, 50)}... </Tooltip> : props.data.location}
                    </span>
                    </Col>
                    <Col span={24} xs={24} sm={24} md={24} lg={24} className="TextFrontDesc"> Asset Status : <span className="Desc_text_gray_2"> {(props.data.status || '').length > 50
                        ? <Tooltip placement="bottom" title={props.data.status}> {(props.data.status || '').substring(0, 20)}... </Tooltip> : props.data.status}
                    </span>
                    </Col>
                </Row> */}
                <Row style={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20 }} gutter={16}>
                    <Col span={12} className="TextFrontDesc">Asset Status</Col>
                    <Col span={12} className="TextFrontDesc">Owner</Col>

                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20 }} gutter={16}>
                    <Col span={12} className="Desc_text_gray">{props.data.status || '-'}</Col>
                    <Col span={12} className="Desc_text_gray">{props.data.people ? props.data.people?.employeeId + ' : ' + props.data.people?.fullName : '-'}</Col>

                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20 }} gutter={16}>
                    <Col span={12} className="TextFrontDesc">Site</Col>
                    <Col span={12} className="TextFrontDesc">Location</Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20 }} gutter={16}>
                    <Col span={12} className="Desc_text_gray">{props.data.site || '-'}</Col>
                    <Col span={12} className="Desc_text_gray">{props.data.location || '-'}</Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20 }} gutter={16}>
                    <Col span={12} className="TextFrontDesc">Building</Col>
                    <Col span={12} className="TextFrontDesc">Floor</Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20 }} gutter={16}>
                    <Col span={12} className="Desc_text_gray">{props.data.building || '-'}</Col>
                    <Col span={12} className="Desc_text_gray">{props.data.floor || '-'}</Col>
                </Row>
                <br />
            </> : <>
                <Spin spinning={isLoading}>
                    {/* <Row>
                        <Col style={{ textAlign: 'right' }}>
                            <Button type="default" icon="save" size="small" onClick={save} disabled={hasErrors(getFieldsError())}/>&nbsp;
                            <Button type="default" icon="close" size="small" onClick={() => modalConfirmCancelEdit()} />
                        </Col>
                    </Row> */}
                    <Form layout="horizontal" style={{ padding: 20 }}>
                        <Row style={{ lineHeight: '200%' }}>
                            <Col span={16} xs={24} sm={24} md={24} lg={12} style={{ color: '#FA541C', fontSize: '16px', fontWeight: 'bold' }}>
                                Serial Number : <span>{props.data.serialNumber}</span>
                            </Col>
                            <Col span={8} style={{ width: '50%', textAlign: 'end' }}>
                                <Button type='default' size='default' onClick={() => modalConfirmCancelEdit()}> Cancel</Button>
                                <Button style={{ marginLeft: 5 }} type='primary' size='default' onClick={save} disabled={hasErrors(getFieldsError())}> Save </Button>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }} gutter={16}>
                            <Col span={12} className="TextFrontDesc">Asset Name</Col>
                            <Col span={12} className="TextFrontDesc">Group</Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }} gutter={16}>
                            <Col style={{ borderBottom: '1px solid #ddd', paddingBottom: 10 }} span={12} className="Desc_text_gray">{props.data.assetName || '-'}</Col>
                            <Col style={{ borderBottom: '1px solid #ddd', paddingBottom: 10 }} span={12} className="Desc_text_gray">{props.data.assetGroup?.name || '-'}</Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }} gutter={16}>
                            <Col span={12} className="TextFrontDesc">Asset Status</Col>
                            <Col span={12} className="TextFrontDesc">Owner</Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }} gutter={16}>
                            <Col span={12}>
                                <Form.Item>
                                    {getFieldDecorator('status',
                                        {
                                            initialValue: props.data.status,
                                            rules: [{ required: true, whitespace: true, message: 'Status is required.' }]
                                        })(
                                        <Select placeholder="Select a Status" style={{ width: '100%' }} >
                                            {props.filterAsset.status!!.map((it, index) => {
                                                return <Option value={it} key={index} > {it}</Option>
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item className="TextFrontDesc">
                                    {getFieldDecorator('employeeId',
                                        {
                                            initialValue: props.data.people ? props.data.people?.employeeId + ' : ' + props.data.people?.fullName : undefined,
                                            rules: [{ required: false, whitespace: true, message: 'Owner ID is required.' }]
                                        })(
                                        <AutoComplete
                                            style={{ width: '100%', textAlign: 'left' }}
                                            placeholder="Owner_ID"
                                            onChange={(value) => { searchFetch(value, props.filterAsset.employeeID || [], setEmployeeID) }}
                                            dataSource={dataEmployee.map((it: People) => {
                                                return {
                                                    value: it.employeeId?.toString(),
                                                    text: it.employeeId?.toString() + ' : ' + it.fullName
                                                } as DataSourceItemObject
                                            })}
                                            allowClear
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }} gutter={16}>
                            <Col span={12} className="TextFrontDesc">Site</Col>
                            <Col span={12} className="TextFrontDesc">Location</Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }} gutter={16}>
                            <Col span={12}>
                                <Form.Item className="TextFrontDesc">
                                    {getFieldDecorator('site',
                                        {
                                            initialValue: props.data.site
                                        })(
                                        <Select
                                            style={{ width: '100%', fontWeight: 400 }}
                                            placeholder={'Site'}
                                            onChange={() => setFieldsValue({ location: undefined, building: undefined, floor: undefined })}
                                        >
                                            {[...new Map(props.locationBuildings.map(item =>
                                                [item.site, item])).values()].map((item, index) => {
                                                return <Option value={item.site} key={index}>{item.site}</Option>
                                            })}
                                        </Select>
                                    )}

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item className="TextFrontDesc">
                                    {getFieldDecorator('location',
                                        {
                                            initialValue: props.data.location
                                        })(
                                        <Select
                                            style={{ width: '100%', fontWeight: 400 }}
                                            placeholder={'Location'}
                                            disabled={!getFieldValue('site')}
                                            onChange={() => setFieldsValue({ building: undefined, floor: undefined })}
                                        >
                                            {/* {props.locationBuildings.filter((item) => item.site === getFieldValue('site'))
                                                .map((data, index) => {
                                                    return <Option value={data.location} key={index}>{data.location}</Option>
                                                })
                                            } */}
                                            {optionLocation.map((data, index) => {
                                                return <Option value={data.location} key={data.locationId}>{data.location}</Option>
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }} gutter={16}>
                            <Col span={12} className="TextFrontDesc">Building</Col>
                            <Col span={12} className="TextFrontDesc">Floor</Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }} gutter={16}>
                            <Col span={12}>
                                <Form.Item className="TextFrontDesc">
                                    {getFieldDecorator('building',
                                        {
                                            initialValue: props.data.building
                                        })(
                                        <Select
                                            style={{ width: '100%', fontWeight: 400 }}
                                            placeholder={'Building'}
                                            disabled={!getFieldValue('location')}
                                            onChange={() => setFieldsValue({ floor: undefined })}
                                        >
                                            {/* {props.locationBuildings.filter((item) => item.site === getFieldValue('site') && item.location === getFieldValue('location'))
                                                    .map((data, index) => {
                                                        return <Option value={data.building} key={index}>{data.building}</Option>
                                                    })
                                                } */}
                                            {optionBuilding.map((data, index) => {
                                                return <Option value={data.building} key={index}>{data.building}</Option>
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item className="TextFrontDesc">
                                    {getFieldDecorator('floor',
                                        {
                                            initialValue: props.data.floor
                                        })(
                                        <Select
                                            style={{ width: '100%', fontWeight: 400 }}
                                            placeholder={'Floor'}
                                            disabled={!getFieldValue('building')}
                                            filterOption={(inputValue, option: any) =>
                                                (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                        >
                                            {/* {props.locationBuildings.filter((item) => item.site === getFieldValue('site') && item.location === getFieldValue('location') && item.building === getFieldValue('building'))
                                                    .map((data, index) => {
                                                        return <Option value={data.floor} key={index}>{data.floor}</Option>
                                                    })
                                                } */}
                                            {optionFloor.map((data, index) => {
                                                return <Option value={data.floor} key={index}>{data.floor}</Option>
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* <Row style={{ lineHeight: '200%' }}>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} className="TextFrontDesc"> Group : <span className="Desc_text_gray_2">
                                {props.data.assetGroup?.name !== undefined ? <Tooltip placement="bottom" title={props.data.assetGroup?.name}> {props.data.assetGroup?.name.length > 50 ? props.data.assetGroup.name.substring(0, 50).concat('...') : props.data.assetGroup.name || '-'} </Tooltip>
                                    : null}
                            </span>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} className="TextFrontDesc"> Asset Name : <span className="Desc_text_gray_2">
                                {props.data.assetName !== undefined ? <Tooltip placement="bottom" title={props.data.assetName}> {props.data.assetName?.length > 25 ? props.data.assetName.substring(0, 50).concat('...') : props.data.assetName || '-'} </Tooltip>
                                    : null}
                            </span>
                            </Col>
                        </Row>
                        <Row style={{ lineHeight: '200%' }}>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} className="TextFrontDesc">Model : <span className="Desc_text_gray_2">
                                {props.data.model !== undefined ? <Tooltip placement="bottom" title={props.data.model}> {props.data.model?.length > 25 ? props.data.model.substring(0, 50).concat('...') : props.data.model || '-'} </Tooltip>
                                    : null}
                            </span>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} className="TextFrontDesc">Brand : <span className="Desc_text_gray_2">
                                {props.data.brand !== undefined ? <Tooltip placement="bottom" title={props.data.brand}> {props.data.brand?.length > 25 ? props.data.brand.substring(0, 50).concat('...') : props.data.brand || '-'} </Tooltip>
                                    : null}
                            </span>
                            </Col>
                        </Row> */}
                        {/* <Row style={{ lineHeight: '200%' }}>
                            <Col span={12} className="TextFrontDesc">Dept Name : <span className="Desc_text_gray_2">
                                {props.data.deptName !== undefined ? <Tooltip placement="bottom" title={props.data.deptName}> {props.data.deptName?.length > 250 ? props.data.deptName.substring(0, 50).concat('...') : props.data.deptName} </Tooltip>
                                    : null}
                            </span>
                            </Col>
                        </Row> */}
                        {/* <Row style={{ lineHeight: '200%' }}>
                            <Col span={12} className="TextFrontDesc">Dept Name : <span className="Desc_text_gray_2">
                                {props.data.deptName !== undefined ? <Tooltip placement="bottom" title={props.data.deptName}> {props.data.deptName?.length > 250 ? props.data.deptName.substring(0, 50).concat('...') : props.data.deptName || '-'} </Tooltip>
                                    : null}
                                </span>
                            </Col>
                            <Col span={12} className="TextFrontDesc"> Zone : <span className="Desc_text_gray_2">
                                {props.data.zone !== undefined ? <Tooltip placement="bottom" title={props.data.zone}> {props.data.zone?.length > 50 ? props.data.zone.substring(0, 50).concat('...') : props.data.zone || '-'} </Tooltip>
                                    : null}
                            </span>
                            </Col>
                        </Row> */}
                        {/* <Row style={{ lineHeight: '150%' }}>
                            <Col span={12} className="TextFrontDesc">
                                <Form.Item className="TextFrontDesc">
                                    <span className="TextFrontDesc"> Owner ID : </span>&nbsp;&nbsp;&nbsp;&nbsp;
                                    {getFieldDecorator('employeeId',
                                        {
                                            initialValue: props.data.people?.employeeId || props.data.employeeId || undefined,
                                            rules: [{ required: false, whitespace: true, message: 'Owner ID is required.' }]
                                        })(
                                        <AutoComplete
                                            style={{ width: 150, textAlign: 'left'}}
                                            placeholder="Owner_ID"
                                            onChange={(value) => { searchFetch(value, props.filterAsset.employeeID || [], setEmployeeID) }}
                                            dataSource={dataEmployee.map((it: People) => {
                                                return {
                                                    value: it.employeeId?.toString(),
                                                    text: it.employeeId?.toString() + ' : ' + it.fullName
                                                } as DataSourceItemObject
                                            })}
                                            allowClear
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12} className="TextFrontDesc">
                                <Form.Item className="TextFrontDesc">
                                    <span className="TextFrontDesc"> Location : </span>&nbsp;&nbsp;&nbsp;&nbsp;
                                    {getFieldDecorator('location',
                                        {
                                            initialValue: props.data.location,
                                            rules: [{ required: true, whitespace: true, message: 'Location is required' }]
                                        })(
                                        <Select
                                            onSearch={(value) => { searchFetch(value, props.filterAsset.location || [], setLocation) }}
                                            style={{width: 250}}
                                            placeholder={'Location'}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(inputValue, option) =>
                                                (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                        >
                                            {(props.locationBuildings || []).map((item, index) => {
                                                return <Option value={item.location} key={item.location}>{item.location}</Option>
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className="TextFrontDesc">
                                <Form.Item >
                                    <span className="TextFrontDesc"> Asset Status : </span>&nbsp;&nbsp;&nbsp;&nbsp;
                                    {getFieldDecorator('status',
                                        {
                                            initialValue: props.data.status,
                                            rules: [{ required: true, whitespace: true, message: 'Status is required.' }]
                                        })(
                                        <Select placeholder="Select a Status" style={{ width: 135 }} >
                                            {props.filterAsset.status!!.map((it, index) => {
                                                return <Option value={it} key={index} > {it}</Option>
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row> */}
                        <br />
                    </Form>
                </Spin>
            </>}

        </Content>
    )
}

const MyAssetContent = Form.create<Props>({ name: 'AssetContent' })(AssetContent)
export default connect(mapStateToProps, {
    updateAsset, getAssetFilter
})(MyAssetContent)
