import React, { useContext, useEffect, useState } from 'react'
import Step2Form from './components/Step2Form'
import { Breadcrumb, Card, Col, message, Modal, Row, Spin, Upload, Tooltip, Empty, Divider } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { PreventiveWithAsset, pmUploadLink } from './Model'
import { CompletedPMContext } from './ContextAPI/CompletedPMContext'
import { UploadFile } from 'antd/lib/upload/interface'
import exportIcon from '../common-file/icon-file'
import { UploadLink } from '../file-server-storage/model'
import { getPreventiveById } from './services'
import { DownloadAnyFile } from '../common-misc'
import { CustomFiledAsset } from '../configurable-fields'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import 'react-photo-view/dist/react-photo-view.css'

interface Param {

}
type Props = Param
const CompletePMForm: React.FC<Props> = (props: Props) => {
    const { id, serialNo }: any = useParams()
    const { CompletedPMState } = useContext<any>(CompletedPMContext)
    const [data, setData] = useState<PreventiveWithAsset>()
    const [online, setOnline] = useState(window.navigator.onLine)
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const isPreven = window.location.pathname.includes('CompletePMFormView')
    const [isLoading, setIsLoading] = useState(false)
    const [CustomPictureList, setCustomPictureList] = useState<any[]>([])
    const [CustomFiledAssetAdjust, setCustomFiledAssetAdjust] = useState<CustomFiledAsset[]>([])
    const [CustomFiledAssetDetails, setCustomFiledAssetDetails] = useState<CustomFiledAsset[]>([])

    useEffect(() => {
        message.success('This  PM ID has Completed Preventive Maintenance. ')
        window.addEventListener('online', () => setOnline(true))
        window.addEventListener('offline', () => setOnline(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setIsLoading(true)
        getPreventiveById(id, serialNo).then((res) => {
            if (res) {
                if (res?.attachFile) {
                    const attachFile = JSON.parse(res?.attachFile)
                    const fileList: UploadFile[] = attachFile?.map((file: UploadLink) => {
                        const uploadFile: UploadFile = {
                            uid: file.name,
                            name: file.name,
                            status: 'done',
                            url: file.url,
                            type: file.type,
                            thumbUrl: exportIcon(file.type),
                            size: 0
                        }
                        return uploadFile
                    })
                    setFileList(fileList)
                }
                if (res?.pmUploadLink) {
                    const filterPMUploadLink = res?.pmUploadLink?.filter((it) => { return it.url !== null })
                    const fileList: UploadFile[] = filterPMUploadLink?.map((file: pmUploadLink) => {
                        const uploadFile: pmUploadLink = {
                            uid: file.name,
                            name: file.name,
                            status: 'done',
                            url: file.url,
                            type: file.type,
                            thumbUrl: exportIcon(file.type || ''),
                            size: 0,
                            customPictureId: file.customPictureId,
                            pictureName: file.customField?.pictureName
                        }
                        return uploadFile
                    })
                    setCustomPictureList(fileList)
                }
                const convertData: PreventiveWithAsset = {
                    preventiveMaintenance: res,
                    asset: res.asset
                }
                setData(convertData)
                setCustomFiledAssetDetails(res?.preventiveMaintenance.pmAdjust?.originalAsset?.customField)
                console.log(CustomFiledAssetDetails)
                setCustomFiledAssetAdjust(res?.preventiveMaintenance.pmAdjust?.pmAdjustData?.customField)
                console.log(CustomFiledAssetAdjust)
            }
        }).finally(() => {
            setIsLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CompletedPMState, id])

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const uploadProps = {
        multiple: true,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('You are only allowed to upload image files.')
                return false
            }
            setLoading(true)
            console.log(loading)
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                console.log(e)
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                setFileList(fillOffFileList)
                setLoading(false)
                console.log(fileList)
            }
            return false
        },
        showUploadList: { showDownloadIcon: true, showRemoveIcon: false },
        onDownload: (file) => {
            console.log(file)
            if (file.url.startsWith('https://')) {
                window.open(file.url)
            } else {
                message.warning("Unable to download the file. Please try again later.")
            }
        }
    }

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png') || ((file.url) as string).includes('jpeg') || ((file.url) as string).includes('PNG')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    {isPreven ? <Link to="/Preventive">Preventive Maintenance</Link> : <Link to="/CompletePM">Completed Preventive Maintenance</Link>}
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {isPreven ? <Link to="/Preventive">{data?.preventiveMaintenance?.pmId}</Link> : <Link to="/CompletePM">{data?.preventiveMaintenance?.pmId}</Link>}
                </Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Spin spinning={isLoading}>
                <Card headStyle={{ borderBottom: 'none', paddingTop: '23px' }}>
                    <Row >
                        <Row>
                            <Col md={1} lg={4} />
                            <Col md={22} lg={16} >
                                <Row>
                                    <Col span={4} xs={23} sm={23} md={23} lg={5}>
                                        <p style={{ fontWeight: 'bold' }}>Serial Number </p>
                                        <p style={{ fontWeight: 'bold', color: 'red', fontSize: 16 }}>{data?.asset.serialNumber} ({data?.asset?.assetGroup?.name})</p>
                                    </Col>
                                    <Col span={1} style={{ display: 'flex', justifyContent: 'center' }}><Divider type='vertical' style={{ height: 50, margin: '10px 0px' }} /></Col>
                                    <Col span={4} xs={11} sm={11} md={11} lg={3}>
                                        <p style={{ fontWeight: 'bold' }}>PM ID </p>
                                        <p style={{ fontWeight: 'bold', color: '#FA541C', fontSize: 16 }}>{data?.preventiveMaintenance?.pmId}</p>
                                    </Col>
                                    <Col span={1} style={{ display: 'flex', justifyContent: 'center' }}><Divider type='vertical' style={{ height: 50, margin: '10px 0px' }} /></Col>
                                    <Col span={4} xs={11} sm={11} md={11} lg={3}>
                                        <p style={{ fontWeight: 'bold' }}>PM Status </p>
                                        <p style={{ fontWeight: 'bold', color: data?.preventiveMaintenance?.pmStatus === 'Completed' ? '#4DC977' : '#757F8D', fontSize: 16 }}>{data?.preventiveMaintenance?.pmStatus}</p>
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={4} />
                        </Row>
                        <Row>
                            <Col md={1} lg={4} />
                            <Col md={22} lg={16} style={{ backgroundColor: '#F0F0F0', padding: 5, borderRadius: 5 }}>
                                <p className="main-title" style={{ margin: 12 }}>Original Images Asset</p>
                            </Col>
                            <Col span={4} />
                        </Row>
                        {fileList && fileList?.length !== 0 ? <div>
                            <Row>
                                <Col md={1} lg={4} />
                                <Col md={22} lg={16}>
                                    <div className="Box-Picture" style={{ height: fileList.length <= 8 ? 150 : 330 }}>
                                        <Upload {...uploadProps} style={{ paddingBottom: 24 }}
                                            listType="picture-card"
                                            // fileList={fileList}
                                            onPreview={(e: any) => handlePreview(e)}
                                        >
                                        </Upload>
                                        <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                                            <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                        <Row gutter={[12, 12]}>
                                            <PhotoProvider >
                                                {[...fileList]?.map((value, index) => {
                                                    return <Col span={3} key={index} xs={12} sm={12} md={8} lg={3} xl={3}>
                                                        <div className='image-view-box'>
                                                            <Tooltip title={value.name}>
                                                                <PhotoView src={value.url} >
                                                                    <img src={value.url} alt={value.name} height={80} width={80} />
                                                                </PhotoView>
                                                                <a href={value.url} target="_blank" rel="noopener noreferrer"><div className='feck-link image-view-text-name' >{value.name.length < 20 ? value.name : value.name.slice(0, 19) + '...'}</div></a>
                                                            </Tooltip>
                                                        </div>
                                                    </Col>
                                                })}
                                            </PhotoProvider>
                                        </Row>
                                    </div>
                                </Col>
                                <Col span={4} />
                            </Row>
                        </div> : <Row><Col md={1} lg={4} /><Col md={22} lg={16}><div className="Box-Picture" style={{ height: 180 }}><br /><Empty /><br /></div></Col><Col span={4} /></Row>}

                        <Row>
                            <Col md={1} lg={4} />
                            <Col md={22} lg={16} style={{ backgroundColor: '#F0F0F0', padding: 5, borderRadius: 5 }}>
                                <p className="main-title" style={{ margin: 12 }}>Desired Image for PM</p>
                            </Col>
                            <Col span={4} />
                        </Row>
                        {CustomPictureList.length !== 0 ? <div>
                            <Row>
                                <Col md={1} lg={4} />
                                <Col md={22} lg={16}>
                                    <div className="Box-Picture" style={{ height: CustomPictureList.length <= 9 ? 150 : 330 }}>
                                        {/* <Upload {...uploadProps} style={{ paddingBottom: 24 }}
                                            listType="picture-card"
                                            // fileList={CustomPictureList}
                                            onPreview={(e: any) => handlePreview(e)}
                                        >
                                        </Upload>
                                        <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                                            <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                                        </Modal> */}
                                        <PhotoProvider >
                                            <Row gutter={[12, 12]} >
                                                {[...CustomPictureList]?.sort((a, b) => (a.customPictureId > b.customPictureId) ? 1 : ((b.customPictureId > a.customPictureId) ? -1 : 0)).map((value, index) => {
                                                    return <Col span={3} key={index} xs={12} sm={12} md={8} lg={3} xl={3}>
                                                        <div className='image-view-box'>
                                                            <Tooltip title={value.name}>
                                                                <PhotoView src={value.url} >
                                                                    <img src={value.url} alt={value.name} height={80} width={80} />
                                                                </PhotoView>
                                                                <a href={value.url} target="_blank" rel="noopener noreferrer"><div className='feck-link image-view-text-name'>{value.name.length < 20 ? value.name : value.name.slice(0, 19) + '...'}</div></a>
                                                            </Tooltip>
                                                        </div>
                                                    </Col>
                                                })}
                                            </Row>
                                        </PhotoProvider>
                                    </div>
                                </Col>
                                <Col span={4} />
                            </Row>
                        </div> : <Row><Col md={1} lg={4} /><Col md={22} lg={16}><div className="Box-Picture" style={{ height: 180 }}><br /><Empty /><br /></div></Col><Col span={4} /></Row>}
                        {/* <div style={{ display: 'flex' }}> */}

                        {/* {fileList ? (
                            <Row>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {[...fileList, ...CustomPictureList]?.map((value, index) => {
                                        return <Col key={index}>
                                            <Tooltip title={value?.pictureName || ''}>
                                                <Upload
                                                    {...uploadProps}
                                                    listType="picture-card"
                                                    fileList={[value]}
                                                    onPreview={(e: any) => handlePreview(e)}
                                                />
                                                <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                                                    <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Tooltip>
                                        </Col>
                                    })}
                                </div>
                            </Row>
                        ) : null} */}

                        {/* </div > */}
                        {/* <Row>
                        {fileList.length !== 0 ? <Col span={24} style={{ paddingLeft: 24, paddingTop: 24 }}>
                            <Upload {...uploadProps}
                                listType="picture-card"
                                // className={'upload-list-inline'}
                                fileList={fileList}
                                onPreview={(e: any) => handlePreview(e)}
                                disabled={true}
                            >
                            </Upload>
                            <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)} >
                                <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </Col> : null}
                    </Row> */}
                        <br />
                        <Row>
                            <Col md={1} lg={4} />
                            <Col md={22} lg={16}><Step2Form Group={data?.preventiveMaintenance?.asset?.assetGroupType?.assetGroup.name} isOnline={online} dataPM={data} dataAsset={data?.asset} /></Col>
                            <Col span={4} />
                        </Row>
                        {/* <Row>
                            <Col md={1} lg={4} />
                            <Col md={22} lg={16}>
                            <MyStep2FormRedesign Group={data?.preventiveMaintenance?.asset?.assetGroupType?.assetGroup.name} isOnline={online} dataPM={data} dataAsset={data?.asset} />
                            </Col>
                            <Col span={4} />
                        </Row> */}
                    </Row>
                </Card>
            </Spin>
        </div >
    )
}

export default CompletePMForm
