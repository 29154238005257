import React, { useEffect, useState } from 'react'
import { Breadcrumb, Row, Col, message, Spin } from 'antd'
import { connect } from 'react-redux'
import './knowledge.css'

import { StoreState } from '../store'

import KnowledgeListView from './knowledge/KnowledgeList'
import { Category, CategoryList, getCetagoryById } from './category'
import { Folder, getFolderById } from './folder'
import { getAllKnowledge, KnowledgeList, getKnowledgeBySearch, deleteKnowledgeByTicketNumber, KnowledgeStatus, deleteKnowledgeById, deleteFolderById, deleteCategoryById } from './knowledge'
import { FormComponentProps } from 'antd/es/form'
import { FetchingState, fetchWithState, SpinningFetcher } from '../common-components'
import { getAllCategory } from './category/service'
import { Space } from '../common-components/Space'
import { decryptDataVspace } from '../common-misc'
import { getKmMapRole } from '../authorization-module/role-and-permissions/service'
import ChatGPTShareData from './ChatGPT/ChatGPTShareData'
import { getAllSupportTeam } from '../incident-management/support-team'

const pageSize = require('./knowledge/config.json').pageSize

interface StateProps {
    categories: Category[]
    knowledgeList: KnowledgeList
    kmMapRole: string[]
}

interface DispatchProps {
    getAllCategory: (groupUser?: string[]) => Promise<number>
    getAllKnowledge: (currentPage, size, groupUser?: string[]) => Promise<number>
    deleteKnowledgeByTicketNumber: (ticketNumber: string) => Promise<number>
    getKmMapRole: (email: string) => Promise<string[]>
    deleteKnowledgeById: (ticketId: number) => Promise<number>
    deleteFolderById: (folderId: number) => Promise<number>
    deleteCategoryById: (CategoryId: number) => Promise<number>
    getAllSupportTeam: () => Promise<number>
}

type Props = StateProps & DispatchProps & FormComponentProps

const KnowledgeHome: React.FC<Props> = (props: Props) => {
    const [isFetchingCategories, setFetchingCategories] = useState<FetchingState>(FetchingState.NotStarted)
    const [isFetchingKnowledge, setFetchingKnowledge] = useState<FetchingState>(FetchingState.NotStarted)
    const [knowledgeList, setKnowledgeList] = useState<KnowledgeList>(props.knowledgeList)
    const [categoryList, setCategoryList] = useState<Category[]>(props.categories)
    const [knowledgeListTitle, setKnowledgeListTitle] = useState<string>('')
    const [knowledgeListDesc, setKnowledgeListDesc] = useState<string>('')
    const [category, setCategory] = useState<Category>()
    const [folder, setFolder] = useState<Folder>()
    const [, setRoleVspace] = useState<string>()
    const [isFetchKm, setIsFetchKm] = useState(false)
    const username = sessionStorage.getItem('username') || ''
    const [searchStatus, setSearchStatus] = useState<string>('AllStatus')
    const [searchInput, setSearchInput] = useState<string>('')
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        let role: string | undefined
        const dataVspace = decryptDataVspace()
        if (typeof dataVspace === 'object') {
            role = dataVspace.roleId.toString()
            setRoleVspace(role)
        }
        props.getKmMapRole(username).then((res) => {
            if (res?.length === 0) {
                message.warning('Please create a user for the group.')
            }
        }).catch(() => {
            message.warning('Please create a user for the group.')
        })
        props.getAllSupportTeam()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.kmMapRole.length > 0) {
            fetchData(props.kmMapRole)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.kmMapRole])

    useEffect(() => {
        if (props.categories) {
            setCategoryList(props.categories)
            const keyPath = sessionStorage.getItem('active_key_path')?.split(',')
            if (keyPath) {
                selectFolder(keyPath)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.categories])

    useEffect(() => {
        const keyPath = sessionStorage.getItem('active_key_path')?.split(',')
        if (keyPath) {
            const categoryId = keyPath[0]
            if (!categoryId) {
                setCategory(undefined)
            }
            const folderId = keyPath[1]
            if (!folderId) {
                setFolder(undefined)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionStorage.getItem('active_key_path')])

    useEffect(() => {
        setKnowledgeList(props.knowledgeList)
        ChatGPTShareData.getInstance().data = []
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.knowledgeList])

    const fetchData = (role) => {
        fetchWithState(isFetchingCategories, setFetchingCategories, () => props.getAllCategory(role).catch(() => { message.error('Please add a group in the settings') }))
        fetchWithState(isFetchingKnowledge, setFetchingKnowledge, () => props.getAllKnowledge(1, 10, role))
    }

    const selectFolder = (keyPath: string[]) => {
        const categoryId = keyPath[0]
        const folderId = keyPath[1]

        if (folderId) {
            if (folderId === 'all') {
                setKnowledgeListTitle('All Knowledge')
                setKnowledgeListDesc('Viewing Knowledge across all categories & folders')
            } else {
                getFolderById(folderId).then((folder) => {
                    setKnowledgeListTitle(folder.folderName || '')
                    setKnowledgeListDesc(folder.description || '')
                })
            }
        } else if (categoryId) {
            getCetagoryById(Number(categoryId), props.kmMapRole).then((category) => {
                setKnowledgeListTitle(category.categoryName || '')
                setKnowledgeListDesc(category.description || '')
            })
        }

        setCategoryAndFolder(categoryId, folderId)
        searchKnowledge(categoryId, folderId, 'AllStatus')
    }

    const setCategoryAndFolder = (categoryId?: string, folderId?: string) => {
        props.categories.forEach(category => {
            if (category.id === categoryId) {
                setCategory(category)
                if (folderId) {
                    // eslint-disable-next-line no-unused-expressions
                    category.folderList?.forEach(folder => {
                        if (String(folder.id) === folderId) {
                            setFolder(folder)
                        }
                    })
                } else {
                    setFolder(undefined)
                }
            }
        })
    }

    const searchKnowledge = (categoryId?: string, folderId?: string, kmStatus: string = searchStatus, search?: string) => {
        setSearchStatus(kmStatus)
        setIsFetchKm(true)
        const prevCurrentPage = search ? 1 : parseInt(sessionStorage.getItem('km_current_page') || '1')
        if (folderId === 'all') {
            setKnowledgeList(props.knowledgeList)
            setFolder(undefined)
            setCategory(undefined)
            setIsFetchKm(false)
        } else if (folderId) {
            getKnowledgeBySearch(prevCurrentPage, pageSize, folderId, categoryId, KnowledgeStatus[kmStatus], search, props.kmMapRole).then((result) => {
                console.log(result)
                setKnowledgeList(result)
                // sessionStorage.setItem('km_current_page', '1')
            }).finally(() => {
                setIsFetchKm(false)
            })
        } else {
            setFolder(undefined)
            getKnowledgeBySearch(prevCurrentPage, pageSize, '', categoryId, KnowledgeStatus[kmStatus], search, props.kmMapRole).then((result) => {
                console.log(result)
                setKnowledgeList(result)
                // sessionStorage.setItem('km_current_page', '1')
            }).finally(() => {
                setIsFetchKm(false)
            })
        }
    }

    const deleteKm = (isRetireStatus) => {
        if (isRetireStatus) {
            return props.deleteKnowledgeByTicketNumber
        } else {
            return props.deleteKnowledgeById
        }
    }

    const deleteFolder = (value) => {
        setIsLoading(true)
        props.deleteFolderById(value).then(() => {
            message.success('The data has been deleted successfully.')
            const keyPath = sessionStorage.getItem('active_key_path')?.split(',')
            if (keyPath) {
                sessionStorage.setItem('active_key_path', keyPath[0])
                props.getAllCategory(props.kmMapRole).catch((err) => message.error(`Unable to retrieve category. Please try again later. ${err}`))
                // selectFolder([keyPath[0], ''])
            }
        }).catch(() => {
            message.error('Unable to delete folder. Please try again later.')
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const deleteCategory = (value) => {
        setIsLoading(true)
        props.deleteCategoryById(value).then(() => {
            message.success('The data has been deleted successfully.')
            const keyPath = sessionStorage.getItem('active_key_path')?.split(',')
            if (keyPath) {
                sessionStorage.setItem('active_key_path', ',all')
                props.getAllCategory(props.kmMapRole).catch((err) => message.error(`Unable to retrieve category. Please try again later. ${err}`))
            }
        }).catch(() => {
            message.error('The data has been deleted successfully.')
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>Knowledge Management</Breadcrumb.Item>
                <Breadcrumb.Item>{category?.categoryName || 'All Knowledge'}</Breadcrumb.Item>
                {folder ? (<Breadcrumb.Item>{folder.folderName}</Breadcrumb.Item>) : null}
            </Breadcrumb>
            <Space size={10} />
            <Row gutter={20}>
                <SpinningFetcher fetchingState={isFetchingCategories}>
                    <Col span={6} style={{ height: '100%' }} xs={24} sm={24} md={6} lg={6}>
                        <CategoryList categories={categoryList} selectFolder={selectFolder} />
                    </Col>
                    <Col span={18} xs={24} sm={24} md={18} lg={18}>
                        <Spin tip="Loading..." spinning={isLoading}>
                            <KnowledgeListView
                                knowledge={knowledgeList}
                                category={category}
                                folder={folder}
                                title={knowledgeListTitle}
                                description={knowledgeListDesc}
                                isFetch={isFetchKm}
                                kmRole={props.kmMapRole || []}
                                fetchKm={searchKnowledge}
                                deleteKm={deleteKm}
                                deleteFolder={deleteFolder}
                                deleteCategory={deleteCategory}
                                setSearchInput={setSearchInput}
                                searchStatus={searchStatus}
                                searchInput={searchInput}
                            />
                        </Spin>
                    </Col>
                </SpinningFetcher>
            </Row>
        </>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        categories: state.knowledgeCategories,
        knowledgeList: state.knowledgesList,
        permission: state.currentUserRole,
        kmMapRole: state.kmMapRole
    }
}

export default connect(
    mapStateToProps,
    {
        getAllCategory,
        getAllKnowledge,
        deleteKnowledgeByTicketNumber,
        getKmMapRole,
        deleteKnowledgeById,
        deleteFolderById,
        deleteCategoryById,
        getAllSupportTeam
    }
)(KnowledgeHome)
