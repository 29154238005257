/* eslint-disable react/no-deprecated */
import React, { useEffect } from 'react'
import { Breadcrumb, message} from 'antd'
import { Space } from '../../../common-components/Space'
import MyDrawflow from './FlowComponent/MyDrawflow'
import { StoreState } from '../../../store'
import { connect } from 'react-redux'
import { getAllSupportTeam } from '../../../incident-management/support-team'
import { Link, useParams } from 'react-router-dom'

const mapStateToProps = (state: StoreState) => {
    return {
        supportTeams: state.supportTeams
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllSupportTeam: () => Promise<number>
}

type Props = StateProps & DispatchProps
const WorkFlowForm: React.FC<Props> = (props: Props) => {
    const { id }: any = useParams()
    useEffect(() => {
        props.getAllSupportTeam().catch((err) => message.error(`Unable to load the support team data. Please try again later. ${err}`))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to={'/WorkflowHome'}>Maintain Workflow</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{id ? 'Update Workflow' : 'Create Workflow'}</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={10} />
            <MyDrawflow />
        </>
    )
}

export default connect(mapStateToProps,
    {
        getAllSupportTeam
    })(WorkFlowForm)
