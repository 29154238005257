import React, { useEffect, useState } from 'react'
import { ColumnNormal } from '../../ReportComponent/components/Model'
import PageIncidentService from './PageIncidentService'
import { Table, Tooltip } from 'antd'

interface Param {
    dataTransfer: any[]
    columnsTicketSLAList: string[]
    columnsTicketSLAObjList: ColumnNormal[]
    total: number
    range: any
    handleTicketSLAChange: Function
}

type Props = Param
const ColumnTicketSLA: React.FC<Props> = (props: Props) => {
    const [tempColumns, setTempColumns] = useState<ColumnNormal[]>([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    useEffect(() => {
        if (props.columnsTicketSLAList && props.columnsTicketSLAObjList) {
            const temp: ColumnNormal[] = []
            props.columnsTicketSLAList.forEach((it) => {
                const findDataIndex = props.columnsTicketSLAObjList.find((data) => data.dataIndex === it)
                if (findDataIndex) {
                    temp.push(findDataIndex)
                    if (findDataIndex.dataIndex === 'description' || findDataIndex.dataIndex === 'resolution' || findDataIndex.dataIndex === 'pendingReason') {
                        findDataIndex.render = (row) => {
                            const showText = row?.length > 50
                                ? <span>
                                    <Tooltip placement='bottom' title={row?.length > 300 ? row.slice(0,300) + '...' : row}>
                                        {row.slice(0, 50) + '...'}
                                    </Tooltip>
                                </span>
                                : <span>{row}</span>
                            return showText
                        }
                    }
                    if (findDataIndex.dataIndex === 'topic' || findDataIndex.dataIndex === 'assignmentGroup') {
                        findDataIndex.render = (row) => {
                            const showText = row?.length > 30
                                ? <span>
                                    <Tooltip placement='bottom' title={row?.length > 300 ? row.slice(0,300) + '...' : row}>
                                        {row.slice(0, 30) + '...'}
                                    </Tooltip>
                                </span>
                                : <span>{row}</span>
                            return showText
                        }
                    }
                }
            })
            setTempColumns(temp)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.columnsTicketSLAList, props.columnsTicketSLAObjList])

    useEffect(() => {
        if (pageSize) {
            PageIncidentService.getInstance().data.pageTicketSLA = 1
            setPage(1)
            props.handleTicketSLAChange()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize])

    useEffect(() => {
        if (!props.range) {
            setPage(1)
            PageIncidentService.getInstance().data.pageTicketSLA = 1
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.range])

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
        PageIncidentService.getInstance().data.pageTicketSLA = page
        props.handleTicketSLAChange()
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPageSize(pagination.pageSize)
        PageIncidentService.getInstance().data.pageSizeTicketSLA = pagination.pageSize
    }

    return (
        <div>
            <Table scroll={{x: true}} dataSource={tempColumns.length === 0 ? [] : props.dataTransfer} columns={tempColumns}
                pagination={ props.range && props.columnsTicketSLAList.length !== 0 ? {
                    current: page,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '40'],
                    total: props.dataTransfer.length !== 0 ? props.total : 0,
                    onChange: (event) => {
                        handleOnClickPageNumber(event)
                    }
                } : false}
                onChange={handleTableChange}
                className="ReportTable" rowKey={'serialNumber'}/>
        </div>
    )
}

export default ColumnTicketSLA
