import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { StoreState } from '../../store'
import { Breadcrumb, Button, Card, Col, Form, Icon, message, Modal, Row, Table, Tooltip } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { RoleAndPermissions } from './model'
import { deleteRoleAndPermissions, getAllRoleAndPermissions, getAllRoleAndPermissionsWithPaging } from './service'
import { Link } from 'react-router-dom'
import { GetAllRoleAndPermissionsSuccessEvent, UpdatePageRoleAndPermissionsEvent } from './state-event'
import { useLastLocation } from 'react-router-last-location'
import { checkRolesState, RoleType } from '../permissions'

const mapStateToProps = (state: StoreState) => {
    return {
        roleAndPermissions: state.roleAndPermissions,
        roleAndPermissionsPaging: state.roleAndPermissionsPaging
    }
}

interface DispatchProps {
    roleAndPermissions: RoleAndPermissions
    getAllRoleAndPermissions: () => Promise<number>
    getAllRoleAndPermissionsWithPaging: (page: number, pageSize: number, sortBy?: string, orderBy?: string) => Promise<number>
    deleteRoleAndPermissions: (id: string) => Promise<number>
}

type StateProps = ReturnType<typeof mapStateToProps>

interface OwnProps {
    getAllRoleAndPermissions: () => void
}

type Props = StateProps & OwnProps & FormComponentProps & DispatchProps
const { confirm } = Modal
const RoleAndPermissionsList: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch()
    const [page, setPage] = useState<number>(1)
    const [loading, setLoading] = useState(false)
    const lastLocation = useLastLocation()

    useEffect(() => {
        let isLastView
        if (lastLocation?.pathname.search('/View')) {
            isLastView = lastLocation?.pathname.search('/View') >= 0
        }
        if (isLastView && props.roleAndPermissionsPaging.page) {
            fetchPaging(props.roleAndPermissionsPaging.page)
        } else {
            fetchPaging(page)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const fetchPaging = (page: number) => {
        setLoading(true)
        props.getAllRoleAndPermissionsWithPaging(page, 10).then((res) => { setPage(page) })
            .catch(() => dispatch(GetAllRoleAndPermissionsSuccessEvent.build([]))).finally(() => {
                setLoading(false)
            })
    }

    const handleOnClickPageNumber = (p: number) => {
        dispatch(UpdatePageRoleAndPermissionsEvent.build(p))
        setPage(p)
    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row: any) => (
                <>
                    {checkRolesState(RoleType.Admin, 'ManageRoleAndPermission') ? <Tooltip placement="bottom" title={'Delete'} ><Icon className="del_icon" type="delete" onClick={() => deleteRole(row.id)} /></Tooltip> : null}
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row: any) => (
                <>
                    {checkRolesState(RoleType.Admin, 'ManageRoleAndPermission') ? <Tooltip placement="bottom" title={'Edit'}><Link to={`/RoleAndPermissionsSettingEditForm/${row.id}`} ><Icon className="edit_icon" type="edit" /></Link></Tooltip> : null}
                </>
            )
        },
        {
            title: <span className='bold-titleWo'>Role Name</span>,
            dataIndex: '',
            key: '',
            width: '20%',
            // eslint-disable-next-line react/display-name
            render: (role: RoleAndPermissions) => (
                <>
                    <Link to={`/RoleAndPermissionsSettingPreview/${role.id}/View\``}>{role.name?.length!! > 30 ? (<>{role.name?.substring(0, 30) + ' ...'}</>) : (<>{role.name}</>)}</Link>
                </>
            )
        },
        {
            title: <span className='bold-titleWo'>Description</span>,
            dataIndex: 'description',
            key: 'description'
        }
    ]

    const deleteRole = (id): void => {
        try {
            confirm({
                title: 'Are you sure?',
                content: 'Do you confirm Delete this data?',
                okText: 'OK',
                okType: 'danger',
                autoFocusButton: 'cancel',
                cancelText: 'Cancel',
                onOk() {
                    props.deleteRoleAndPermissions(id).then(() => {
                        message.success('The data has been deleted successfully.')
                        if (props.roleAndPermissions.length === 1 && page !== 0) {
                            fetchPaging(page - 1)
                        } else {
                            fetchPaging(page)
                        }
                    }).catch(() => {
                        message.warning('This role and permission are currently in use.')
                    })
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        } catch (error: any) {
            console.log(error)
        }
    }
    return (
        <div className='fix_scroll_md'>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Role and Permission</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Card style={{ width: '100%', height: '100%' }} >
                <Row>
                    <Col span={12}><h3 className={'main-title text-herder-Orenge'}>Role and Permission</h3></Col>
                    <Col span={12}><Button type='primary' style={{ float: 'right' }}><Link to="/RoleAndPermissionsSettingForm">Create</Link></Button></Col>
                </Row>
                <br />
                <Row>
                    <Table
                        dataSource={props.roleAndPermissions}
                        columns={columns}
                        rowKey='id'
                        pagination={{
                            pageSize: 10,
                            current: page,
                            total: props.roleAndPermissionsPaging.totalContent,
                            onChange: (event) => { handleOnClickPageNumber(event) }
                        }}
                        loading={loading}
                        scroll={{ x: 768 }}
                    />
                </Row>
            </Card>
        </div>
    )
}

const MyRoleAndPermissionsList = Form.create<Props>()(RoleAndPermissionsList)

export default connect(mapStateToProps, { getAllRoleAndPermissions, deleteRoleAndPermissions, getAllRoleAndPermissionsWithPaging })(MyRoleAndPermissionsList)
