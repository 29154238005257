/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import {
    Checkbox,
    Col,
    Icon,
    Input,
    message,
    Modal,
    Row,
    Select,
    Switch,
    Table,
    Tooltip,
    Form,
    Button,
    AutoComplete
} from 'antd'
import { FetchingState, fetchWithState } from '../../common-components'
import {
    SubCategory,
    Item,
    InitSubCategory,
    getAllCategory,
    getAllSubCategory,
    getAllItems,
    addItems,
    updateItems,
    deleteItems
} from '../../incident-management/category'

import { connect } from 'react-redux'
import { StoreState } from '../../store'
// import { encodeSpecialCharacter } from '../../common-misc'
import { FormComponentProps } from 'antd/es/form'

const mapStateToProps = (state: StoreState) => {
    return {
        items: state.items,
        categories: state.categories,
        subCategories: state.subCategories
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllCategory: () => Promise<number>;
    getAllSubCategory: () => Promise<number>;
    getAllItems: () => Promise<number>;
    addItems: (item: InitSubCategory) => Promise<number>;
    updateItems: (item: InitSubCategory) => Promise<number>;
    deleteItems: (id: number) => Promise<number>;
}

interface Params {
    fetchItems: boolean
    setFetchItems: Function
}

type Props = StateProps & DispatchProps & FormComponentProps & Params
const { Search } = Input
const { Option } = Select
const { confirm } = Modal
const ItemTab: React.FC<Props> = (props: Props) => {
    const [fetchingState, setFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [page, setPage] = useState<number>(1)
    const [searchType, setSearchType] = useState<string>('0')
    const pageSize: number = 10
    const [, setSortBy] = useState<string>('createdDate')
    const [orderBy, setOrderBy] = useState<any>()
    const [activateFilter, setActivateFilter] = useState<boolean[]>([true, false])
    const [value] = useState<string>('')
    // const dispatch = useDispatch()
    const [filterValue, setFilterValue] = useState<boolean[]>([true, false])
    const { getFieldDecorator, resetFields, validateFields, getFieldValue, setFields } = props.form
    // const [, setCategoryNames] = useState<string[]>([])
    const [itemName] = useState<string[]>([])
    // const username = sessionStorage.getItem('username') || ''
    // const [RequiredItem, setRequiredItem] = useState<boolean>(false)
    // const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false)
    // const [, setTempCat] = useState<string>('')
    // const [dataUpdate, setDataUpdate] = useState<Categories>()
    const [editItem, setEditItem] = useState<Item | undefined>(undefined)
    const [dataSource, setDataSource] = useState<Item[]>()
    const [isDisableSubCatField, setIsDisableSubCatField] = useState<boolean>(true)
    const [isDisableItemField, setIsDisableItemField] = useState<boolean>(true)
    const [subcatBySelectCategory, setSubcatBySelectCategory] = useState<SubCategory[]>([])
    const [loadActive, setLoadActive] = useState<SubCategory | undefined>(undefined)
    const [editIsLoading, setEditIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (props.fetchItems) {
            fetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fetchItems])

    useEffect(() => {
        if (value !== '') {
            fetch()
            // props.getAllCategoriesWithPagination(page, pageSize, searchType, value, activateFilter, sortBy, orderBy).catch((err) => { console.log('err :', err) })
        } else {
            fetch()
            // props.getAllCategoriesWithPagination(page, pageSize, searchType, '', activateFilter, sortBy, orderBy).catch(() => dispatch(GetAllCategorySuccessEvent.build([])))
            // getAllCategory(getCategoryName(props.categories))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, orderBy])

    useEffect(() => {
        if (activateFilter.includes(true) && activateFilter.includes(false)) {
            setDataSource(props.items)
        } else if (activateFilter.includes(true)) {
            setDataSource(props.items?.filter(items => items.active === true))
        } else if (activateFilter.includes(false)) {
            setDataSource(props.items?.filter(items => items.active === false))
        } else {
            setDataSource(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activateFilter])

    const fetch = () => {
        if (fetchingState === FetchingState.Succeed) {
            fetchWithState(
                FetchingState.NotStarted,
                setFetchingState,
                () => props.getAllItems().finally(() => props.setFetchItems(false))
            )
        } else {
            fetchWithState(
                FetchingState.NotStarted,
                setFetchingState,
                () => props.getAllItems().finally(() => props.setFetchItems(false))
            )
        }
    }

    useEffect(() => {
        if (props.categories?.length === 0) {
            fetchCategory()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.categories])

    useEffect(() => {
        if (props.subCategories?.length === 0) {
            fetchSubCategory()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.subCategories])

    useEffect(() => {
        if (props.items?.length > 0) {
            setDataSource(props.items)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.items])

    // const fetchItems = () => {
    //     props.getAllItems().finally(() => setFetchingState(FetchingState.Succeed))
    // }

    const fetchSubCategory = () => {
        props.getAllSubCategory().finally(() => setFetchingState(FetchingState.Succeed))
    }

    const fetchCategory = () => {
        props.getAllCategory().finally(() => setFetchingState(FetchingState.Succeed))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (getFieldValue('category') === undefined) {
            setFields({ category: { errors: [new Error('Category Name is required.')] } })
            return
        }
        if (getFieldValue('subCat') === undefined && !isDisableSubCatField) {
            setFields({ subCat: { errors: [new Error('Subcatagory Name is required')] } })
            return
        }
        if (getFieldValue('item') === undefined && !isDisableItemField) {
            setFields({ item: { errors: [new Error('Item Name is required.')] } })
            return
        }
        validateFields((err, values) => {
            values.item = values.item ? values.item.replace(/ +/g, ' ').trim() : undefined
            if (!err) {
                const selectedCategory = props.categories.find(cat => cat.id?.toString() === values.category?.toString())
                const selectedSubcat = props.subCategories.find(sub => sub.id?.toString() === values.subCat?.toString())
                const initItem: InitSubCategory = {
                    category: {
                        id: selectedCategory?.id!,
                        name: selectedCategory?.name!
                    },
                    subcategory: {
                        id: selectedSubcat?.id!,
                        name: selectedSubcat?.subCategory!
                    },
                    name: values.item,
                    active: true
                }
                props.addItems(initItem).catch((err) => {
                    message.error(err)
                })
                resetFields()
                setIsDisableSubCatField(true)
                setIsDisableItemField(true)
            }
        })
    }

    const handleEdit = (item: Item) => {
        const tempItem: Item = {
            active: item.active,
            category: item.category,
            categoryId: item.categoryId,
            createdBy: item.createdBy,
            createdByDisplay: item.createdByDisplay,
            createdById: item.createdById,
            createdDatetime: item.createdDatetime,
            id: item.id,
            item: item.item,
            lastModifiedBy: item.lastModifiedBy,
            lastModifiedByDisplay: item.lastModifiedByDisplay,
            lastModifiedById: item.lastModifiedById,
            subCategory: item.subCategory,
            subcategoryId: item.subcategoryId
        }
        // setEditItem({ ...item })
        setEditItem(tempItem)
    }

    const submitEdit = (e) => {
        e.preventDefault()
        if (editItem) {
            validateFields(['editItem'], (err, values) => {
                if (!err) {
                    const initItem: InitSubCategory = {
                        id: editItem.id,
                        category: {
                            id: editItem.categoryId,
                            name: editItem.category
                        },
                        subcategory: {
                            id: editItem.subcategoryId,
                            name: editItem.subCategory
                        },
                        name: values.editItem,
                        active: true
                    }
                    let isExists: Boolean = false
                    const filteredItems = props.items?.filter((item) => item.id !== initItem.id)
                    if (filteredItems) {
                        (filteredItems || []).forEach((it) => {
                            if (it.categoryId?.toString() === initItem.category.id?.toString() &&
                            it.subcategoryId?.toString() === initItem.subcategory?.id?.toString() &&
                            it.item.toUpperCase().trim() === initItem.name.toUpperCase().trim()) {
                                if (it.id?.toString() === initItem.id?.toString()) {
                                    setEditItem(undefined)
                                    isExists = true
                                } else {
                                    message.error('The SubCatagories Name already exists. It must be unique. Please try another one.')
                                    console.log('invalid')
                                    isExists = true
                                }
                            }
                        })
                    }

                    if (!isExists) {
                        setEditIsLoading(true)
                        props.updateItems(initItem).then(() => {
                            message.success('The Update has finished successfully')
                        }).catch((e) => {
                            message.error(e.message)
                        }).finally(() => {
                            setEditItem(undefined)
                            setEditIsLoading(false)
                        })
                    }
                }
            })
        }
    }

    const getPage = (page: number) => {
        setPage(page)
    }

    // const getAllCategory = (category: string[]) => {
    //     setCategoryNames(category)
    // }

    // const validateSubcatAndCatname = (_: any, value: any, callback) => {
    //     if (value) {
    //         // eslint-disable-next-line
    //         const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    //         const pattern = /[A-Za-z0-9ก-๙]+$/
    //         if (format.test(value)) {
    //             // eslint-disable-next-line
    //             callback('Field Label can' + 't contain any of the following characters ' + format)
    //         } else {
    //             if (pattern.test(value.trimEnd())) {
    //                 callback()
    //             } else {
    //                 callback('Default value is not valid.')
    //             }
    //         }
    //     } else {
    //         callback()
    //     }
    // }

    const validateDropdown = (_: any, value: any, callback) => {
        validateFields(['item'])
        callback()
    }

    const validateNameUniqueness = (_: any, value: any, callback) => {
        (props.items || []).forEach((it) => {
            if (it.categoryId?.toString() === props.form.getFieldValue('category')?.toString() &&
                it.subcategoryId?.toString() === props.form.getFieldValue('subCat')?.toString() &&
                it.item.toUpperCase().trim() === props.form.getFieldValue('item').toUpperCase().trim()) {
                callback('This entry already exists in the system.')
            }
        })
        if (value) {
            // eslint-disable-next-line
            const format = /^[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            const pattern = /[A-Za-z0-9ก-๙]+/
            if (format.test(value)) {
                // eslint-disable-next-line
                callback('Field Label can' + 't contain any of the following characters ' + format)
            } else {
                if (pattern.test(value.trimEnd())) {
                    callback()
                } else {
                    callback('Default value is not valid.')
                }
            }
        } else {
            callback()
        }
    }

    const validateEditNameUniqueness = (_: any, value: any, callback) => {
        if (editItem) {
            const filterItem = props.items.filter((it) => it.item?.toUpperCase()?.trim() !== editItem?.item?.toUpperCase()?.trim())
            if (filterItem) {
                (filterItem || []).forEach((it) => {
                    if (it.categoryId?.toString() === editItem?.categoryId?.toString() &&
                it.subcategoryId?.toString() === editItem.subcategoryId?.toString() &&
                it.item.toUpperCase().trim() === value.toUpperCase().trim()) {
                        callback('This entry already exists in the system.')
                    }
                })
            }
        }

        if (value) {
            // eslint-disable-next-line
            const format = /^[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            const pattern = /[A-Za-z0-9ก-๙]+/
            if (format.test(value)) {
                // eslint-disable-next-line
                callback('Field Label can' + 't contain any of the following characters ' + format)
            } else {
                if (pattern.test(value.trimEnd())) {
                    callback()
                } else {
                    callback('Default value is not valid.')
                }
            }
        } else {
            callback()
        }
    }

    const deleteItem = (data: Item) => {
        try {
            confirm({
                title: 'Are you sure?',
                content: 'Do you confirm Delete this data?',
                okText: 'OK',
                okType: 'danger',
                autoFocusButton: 'cancel',
                cancelText: 'Cancel',
                async onOk() {
                    if (data.active) {
                        message.warning('Please deactivate this data before you continue.')
                    } else {
                        props.deleteItems(data.id!).then(() => {
                            message.success('The data has been deleted successfully.')
                        }).catch(err => {
                            message.error(err)
                        })
                    }
                },
                onCancel() {
                }
            })
        } catch (error: any) {
            console.log(error)
        }
    }

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
        getPage(page)
    }

    const handleChangeTypeSearch = (e) => {
        setSearchType(e)
    }

    const handleSearchValue = (value) => {
        if (searchType?.toString() === '0') {
            if (value?.length?.toString() === '0') {
                if (props.subCategories) {
                    setDataSource(props.items)
                }
            } else {
                const searchValue = props.items.filter(item =>
                    item.category.toUpperCase().search(value.toUpperCase()) >= 0 ||
                    item.subCategory.toUpperCase().search(value.toUpperCase()) >= 0 ||
                    item.item.toUpperCase().search(value.toUpperCase()) >= 0
                )
                setDataSource(searchValue)
            }
        } else if (searchType?.toString() === '1') {
            if (value.length?.toString() === '0') {
                if (props.subCategories) {
                    setDataSource(props.items)
                }
            } else {
                const searchValue = props.items.filter(item => item.category.toUpperCase().search(value.toUpperCase()) >= 0)
                setDataSource(searchValue)
            }
        } else if (searchType?.toString() === '2') {
            const searchValue = props.items.filter(item =>
                item.subCategory.toUpperCase().search(value.toUpperCase()) >= 0
            )
            setDataSource(searchValue)
        } else if (searchType?.toString() === '3') {
            const searchValue = props.items.filter(item =>
                item.item.toUpperCase().search(value.toUpperCase()) >= 0
            )
            setDataSource(searchValue)
        }
    }

    const handleSelectCategory = (category: string) => {
        setIsDisableSubCatField(false)
        setSubcatBySelectCategory(props.subCategories.filter((subcat) => subcat.categoryId.toString() === category?.toString()))
        resetFields(['subCat', 'item'])
    }

    const toggleActiveness = (cat) => {
        const proceed = () => {
            setLoadActive(cat)
            props.updateItems({ id: cat.id, active: !cat.active } as InitSubCategory)
                .catch((err) => {
                    if (err?.response?.status === 409) {
                        message.warning('This Category is currently being used by Ticket .')
                    } else {
                        message.error(`Unable to toggle the category's activeness. Please try again later. ${err}`)
                    }
                })
                .finally(() => {
                    setLoadActive(undefined)
                })
        }

        if (!cat.active) {
            proceed()
        } else {
            try {
                confirm({
                    title: 'Are you sure?',
                    content: 'Do you confirm deactivating this data ?',
                    okText: 'OK',
                    okType: 'danger',
                    autoFocusButton: 'cancel',
                    cancelText: 'Cancel',
                    async onOk() {
                        proceed()
                    },
                    onCancel() {
                    }
                })
            } catch (error: any) {
                console.log(error)
            }
        }
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        setSortBy(sorter.field)
        if (sorter.order === 'ascend') {
            setOrderBy('asc')
        } else if (sorter.order === 'descend') {
            setOrderBy('desc')
        } else {
            setOrderBy(undefined)
            setSortBy('createdDate')
        }
    }

    const handleFilter = (value) => {
        setFilterValue(value)
        if (value) {
            if (value.length === 1) {
                if (value[0] === 'true' || value[0] === true) {
                    setActivateFilter([true])
                } else {
                    setActivateFilter([false])
                }
            } else if (value.length === 2) {
                setActivateFilter([true, false])
            } else {
                setActivateFilter([])
            }
            setPage(1)
        }
    }

    const renderFilter = () => {
        return (<>
            <Checkbox.Group style={{ width: '100%' }} onChange={handleFilter} value={filterValue}>
                <Row>
                    <Col span={24} style={{ margin: 10 }}>
                        <Checkbox value={true}>Activate</Checkbox>
                    </Col>
                    <br />
                    <Col span={24} style={{ margin: 10 }}>
                        <Checkbox value={false}>Deactivate</Checkbox>
                    </Col>
                </Row>
            </Checkbox.Group>
        </>)
    }

    const sorter = (a: string, b: string) => (a !== null && b !== null ? (a || '').localeCompare(b || '') : (b || '').localeCompare(a || ''))

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '5%',
            render: (row: any) =>
                <span><Icon type="delete" onClick={() => deleteItem(row)} /></span>
        }, {
            title: '',
            dataIndex: '',
            key: '',
            width: '5%',
            render: (row: any) => {
                if (editItem?.id?.toString() === row.id?.toString()) {
                    return <Form.Item><Button icon='save' style={{ border: 0, background: 'rgb(0,0,0,0)' }} htmlType="submit"></Button> </Form.Item>
                }
                return (
                    <span><Icon type="edit" onClick={() => handleEdit(row)} /> </span>
                )
            }
        },
        {
            title: 'Item',
            dataIndex: '',
            key: 'item',
            sorter: (a, b) => sorter(a.item, b.item),
            render: (row: Item) => {
                if (editItem?.id?.toString() === row.id?.toString()) {
                    return <Form.Item>
                        {getFieldDecorator('editItem',
                            {
                                initialValue: row.item,
                                rules: [
                                    { required: true, message: 'Item Name is required.' },
                                    { max: 250, message: 'Input cannot exceed 250 characters.' },
                                    { validator: validateEditNameUniqueness }
                                ]
                            })(
                            <AutoComplete
                                placeholder="Item Name"
                                disabled={editIsLoading}
                                allowClear
                                // onSelect={(e) => handelSelectCat(e)}
                                filterOption={(inputValue, option) =>
                                    (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                            />
                        )}
                    </Form.Item>
                }
                return (
                    <>{row.item}</>
                )
            }
        },
        {
            title: 'SubCatagory',
            dataIndex: 'subCategory',
            key: 'subCategory',
            sorter: (a, b) => sorter(a.subCategory, b.subCategory),
            render: (data) => {
                return (
                    <>
                        {data}
                    </>
                )
            }
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            sorter: (a, b) => sorter(a.category, b.category)
        },
        {
            title: 'Last Modified',
            dataIndex: '',
            key: '',
            width: '20%',
            render: (row: Item) => {
                return (
                    <>
                        {row.lastModifiedDatetime?.length === 23 ? row.lastModifiedDatetime.substring(0, row.lastModifiedDatetime.length - 4) : row.lastModifiedDatetime}
                    </>
                )
            }
        },
        {
            title: '',
            dataIndex: 'active',
            key: 'active',
            width: '10%',
            filters: [
                {
                    text: 'Activate',
                    value: 'true'
                },
                {
                    text: 'Deactivate',
                    value: 'false'
                }
            ],
            filteredValue: filterValue,
            filterDropdown: renderFilter,
            render: (check, row) => {
                return (
                    <>
                        <Tooltip placement="bottom" title={row.active ? 'Deactivate' : 'Activate'}>
                            <Switch style={{ float: 'right' }} checked={check} loading={loadActive?.id?.toString() === row.id?.toString()} onChange={() => toggleActiveness(row)} />
                        </Tooltip>
                    </>
                )
            }
        }
    ]

    const handleCancle = () => {
        resetFields()
        setIsDisableItemField(true)
        setIsDisableSubCatField(true)
        setFields({ subCat: undefined, item: undefined })
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Row gutter={[10, 10]}>
                    <Col span={6} lg={6} md={5} sm={24} xs={24}>
                        <Form.Item label={'Category Name'}>
                            {getFieldDecorator('category',
                                {
                                    initialValue: undefined,
                                    rules: [{ required: true, message: 'Category Name is required.' },
                                    // { max: 250, message: 'Input cannot exceed 250 characters.' },
                                        { validator: validateDropdown }
                                    ]
                                })(
                                <Select placeholder="Category Name" onChange={handleSelectCategory}>
                                    {props.categories.map(cat => {
                                        if (cat.active) {
                                            return (<Option value={cat.id} key={cat.id}>{cat.name}</Option>)
                                        }
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={6} lg={6} md={5} sm={24} xs={24}>
                        <Form.Item label={'Subcatagory Name'}>
                            {getFieldDecorator('subCat',
                                {
                                    initialValue: undefined,
                                    rules: [{ required: true, message: 'Subcatagory Name is required' },
                                    // { max: 250, message: 'Input cannot exceed 250 characters.' },
                                        { validator: validateDropdown }
                                    ]
                                })(
                                <Select placeholder="Subcatagory Name" onChange={() => {
                                    setIsDisableItemField(false)
                                }} disabled={isDisableSubCatField}>
                                    {subcatBySelectCategory.map(cat => {
                                        if (cat.active) {
                                            return (<Option value={cat.id} key={cat.id}>{cat.subCategory}</Option>)
                                        }
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={6} lg={6} md={5} sm={24} xs={24}>
                        <Form.Item label={'Item Name'} required={true}>
                            {getFieldDecorator('item',
                                {
                                    initialValue: undefined,
                                    rules: [
                                        { required: !isDisableItemField, message: 'Item Name is required.' },
                                        { max: 250, message: 'Input cannot exceed 250 characters.' },
                                        { validator: validateNameUniqueness }
                                    ]
                                })(
                                <AutoComplete
                                    dataSource={itemName}
                                    placeholder="Item Name"
                                    disabled={isDisableItemField}
                                    allowClear
                                    // onChange={(e) => checkRequiredSubCat(e, 'item')}
                                    // filterOption={(inputValue, option) =>
                                    //     (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    // }
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={3} lg={3} md={3} sm={24} xs={24}>
                        <Form.Item style={{ marginTop: 40 }}>
                            <Button type="default" style={{ width: '-webkit-fill-available' }} htmlType="reset"
                                onClick={() => handleCancle()}>Cancel</Button>
                        </Form.Item>
                    </Col>
                    <Col span={3} lg={3} md={3} sm={24} xs={24}>
                        <Form.Item style={{ marginTop: 40 }}>
                            <Button className="addCriteriaButton" style={{ width: '-webkit-fill-available' }}
                                htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <br />
            <div style={{ overflow: window.innerWidth <= 768 ? 'auto' : 'none' }}>
                {/* <TableCategory getAllCategory={getAllCategory} handleOnClickInOtherPage={handleOnClickInOtherPage} getDataToEdit={getDataToEdit} getPage={getPage} /> */}
                <div>
                    <div style={{ float: 'right' }}>
                        <Row gutter={16}>
                            <Col span={7}>
                                <Select defaultValue={searchType} style={{ width: 120 }}
                                    onChange={(e) => handleChangeTypeSearch(e)}>
                                    <Option value="0">All</Option>
                                    <Option value="1">Category</Option>
                                    <Option value="2">Subcategory</Option>
                                    <Option value="3">Item</Option>
                                </Select>
                            </Col>
                            <Col span={7}>
                                <Search
                                    defaultValue={value}
                                    placeholder="Can search Category, Subcategory, or Item"
                                    onSearch={value => handleSearchValue(value)}
                                    id='search_item'
                                    allowClear
                                    style={{ width: 300 }}
                                />
                            </Col>
                        </Row>
                    </div>
                    <br />
                    <br />
                    {/* <SpinningFetcher fetchingState={fetchingState}> */}
                    <Form onSubmit={submitEdit}>
                        <Table
                            columns={columns}
                            dataSource={dataSource || []}
                            // expandedRowRender={expandedRowRender}
                            // defaultExpandAllRows={true}
                            pagination={{
                                pageSize: pageSize,
                                current: page,
                                total: dataSource?.length || 0,
                                onChange: (event) => {
                                    handleOnClickPageNumber(event)
                                }
                            }}
                            rowKey="id"
                            onChange={handleChangeTable}
                            loading={fetchingState !== 2}
                            locale={{
                                filterConfirm: '',
                                filterReset: ''
                            }}
                            expandIconColumnIndex={0}
                            expandIconAsCell={false}
                            scroll={{ x: 768 }}
                        />
                    </Form>
                    {/* </SpinningFetcher> */}
                </div>
            </div>
        </>
    )
}

const ItemTabFrom = Form.create<Props>()(ItemTab)

export default connect(mapStateToProps, {
    getAllCategory, getAllSubCategory, getAllItems, addItems, updateItems, deleteItems
})(ItemTabFrom)
