/* eslint-disable react/display-name */
import React from 'react'
import {Table} from 'antd'

interface Param {
    assetItem: any[]
}

type Props = Param

const TableRelateAsset: React.FC<Props> = (props: Props) => {
    const columns = [
        {
            title: <b>Serial No</b>,
            dataIndex: 'serialNo',
            width: '12%',
            render: (data: string) => (
                <>
                    <div>
                        <p>{data}</p>
                    </div>
                </>
            )
        },
        {
            title: <b>Status</b>,
            dataIndex: 'status',
            width: '11%',
            render: (data: string) => (
                <>
                    <div>
                        <p>{data}</p>
                    </div>
                </>
            )
        },
        {
            title: <b>Asset Tag</b>,
            dataIndex: 'assetTag',
            width: '11%',
            render: (data: string) => (
                <>
                    <div>
                        <p>{data}</p>
                    </div>
                </>
            )
        },
        {
            title: <b>Device Type</b>,
            dataIndex: 'deviceType',
            width: '11%',
            render: (data: string) => (
                <>
                    <div>
                        <p>{data}</p>
                    </div>
                </>
            )
        },
        {
            title: <b>Computer Name</b>,
            dataIndex: 'assetName',
            width: '11%',
            render: (data: string) => (
                <>
                    <div>
                        <p>{data}</p>
                    </div>
                </>
            )
        },
        {
            title: <b>Brand</b>,
            dataIndex: 'brand',
            width: '11%',
            render: (data: string) => (
                <>
                    <div>
                        <p>{data}</p>
                    </div>
                </>
            )
        },
        {
            title: <b>Model</b>,
            dataIndex: 'model',
            width: '11%',
            render: (data: string) => (
                <>
                    <div>
                        <p>{data}</p>
                    </div>
                </>
            )
        },
        {
            title: <b>Employee ID</b>,
            dataIndex: 'employeeId',
            width: '11%',
            render: (data: string) => (
                <>
                    <div>
                        <p>{data}</p>
                    </div>
                </>
            )
        },
        {
            title: <b>Location</b>,
            dataIndex: 'location',
            width: '11%',
            render: (data: string) => (
                <>
                    <div>
                        <p>{data}</p>
                    </div>
                </>
            )
        }

    ]

    return (
        <>
            <Table
                dataSource={props.assetItem}
                columns={columns}
                pagination={false}
                rowKey='id'
            />
        </>
    )
}

export default TableRelateAsset
