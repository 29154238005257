import { Breadcrumb, Button, Card, Col, Icon, Row } from 'antd'
import React from 'react'
import {Link} from 'react-router-dom'
import BtnSyncData from './Components/BtnSync'

interface Props {
    
}

const SyncData: React.FC<Props> = (props: Props) => {
    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Sync Data</Breadcrumb.Item>
            </Breadcrumb>
            <br/>
            <Card style={{minHeight: '535px'}}>
                <div className="text-herder">Sync Data</div>
                <br/>
                <Row gutter={[32,32]} style={{ padding: '15px 5px 30px' }}>
                    <Col span={8} lg={8} md={12} sm={24} xs={24}>
                        <BtnSyncData name='Sync SLA Policy' timeToSync='2026-03-26 12:00:00' />
                    </Col>
                    <Col span={8} lg={8} md={12} sm={24} xs={24}>
                        <BtnSyncData name='Sync Business Hour' timeToSync='2026-03-26 12:00:00' />
                    </Col>
                    <Col span={8} lg={8} md={12} sm={24} xs={24}>
                        <BtnSyncData name='Sync Custom fields' timeToSync='2026-03-26 12:00:00' />
                    </Col>
                    <Col span={8} lg={8} md={12} sm={24} xs={24}>
                        <BtnSyncData name='Sync Department' timeToSync='2026-03-26 12:00:00' />
                    </Col>
                    <Col span={8} lg={8} md={12} sm={24} xs={24}>
                        <BtnSyncData name='Sync Group' timeToSync='2026-03-26 12:00:00' />
                    </Col>
                    <Col span={8} lg={8} md={12} sm={24} xs={24}>
                        <BtnSyncData name='Sync Support By' timeToSync='2026-03-26 12:00:00' />
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default SyncData