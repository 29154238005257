/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ReactQuill, { Quill } from 'react-quill'

import { StoreState } from '../../store'

import { Breadcrumb, Button, Checkbox, Col, Form, Input, message, Modal, Row, Select, Tag, Tooltip } from 'antd'
import { SelectValue } from 'antd/es/select'
import { FormComponentProps } from 'antd/lib/form'
import { defaultKnowledge, dropdownDataType } from './mock'
import { convertCamelCaseToSentenceCase, statusColor } from '../util'
import { Category } from '../category'
import { Folder, getFolderById } from '../folder'
import { Knowledge, KnowledgeStatus, MyUploadLink, TagsList } from './model'
import KnowledgeFormUpload from './uploads/KnowledgeFormUpload'
import { Space } from '../../common-components/Space'
import { Link, useParams } from 'react-router-dom'
import { UploadFile } from 'antd/lib/upload/interface'
import { createKnowledge, getKnowledgeById, updateKnowledge } from './service'
import { getAllCategory } from '../category/service'
import { UploadLink } from './uploads'
import FSS from '../../file-server-storage'
import { getAssignmentUserWithRole } from '../../msp-wo/wo-management/service'
import { AssignmentGroups } from '../../msp-wo/wo-management/model'
import { decryptDataVspace } from '../../common-misc'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import { getKmMapRole } from '../../authorization-module/role-and-permissions/service'
import ImageResize from 'quill-image-resize-module-react'
import ChatGPTShareData from '../ChatGPT/ChatGPTShareData'
import CustomTextEditor from '../../problem-management/problems/components/Related/AnalysisAndSolution/CustomTextEditor'

const { Option } = Select

interface Props extends FormComponentProps {
    categories: Category[]
    Knowledge: Knowledge
    getAllCategory: (groupUser?: string[]) => void
    kmMapRole: string[]
    getKmMapRole: (email: string) => Promise<string[]>
}

interface dropdownData {
    id: string
    value: string
}

enum FormType {
    Topic = 'Topic',
    Detail = 'Details',
    Category = 'Category',
    Folder = 'Folder',
    Type = 'Type',
    Tags = 'Tags',
}

Quill.register('modules/imageResize', ImageResize)
const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }, { color: [] }],
        ['link', 'image'],
        ['clean']
    ],
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
}

const formats = [
    'alt', 'height', 'width', 'style', 'size',
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'align', 'color',
    'link', 'image'
]

const KnowledgeForm: React.FC<Props> = (props: Props) => {
    const [knowledge, setKnowledge] = useState<Knowledge>(defaultKnowledge)
    const [textError, setTextError] = useState<string>('')
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [loadingSaveButton, setLoadingSaveButton] = useState<boolean>(false)
    const [loadingSaveToDraftButton, setLoadingSaveToDraftButton] = useState<boolean>(false)

    const [isSelectCategory, setIsSelectCategory] = useState<boolean>(false)

    const [knowledgeStatus, setKnowledgeStatus] = useState<KnowledgeStatus>(KnowledgeStatus.MyNote)

    const [isDisableComment, setIsDisableComment] = useState<boolean>(false)
    const [detail, setDetail] = useState<string>('')
    const [dataRoleVspace, setDataRoleVspace] = useState<AssignmentGroups>()
    const [isCanEditKM, setIsCanEditKM] = useState<boolean>(false)
    const [, setRoleVspace] = useState<string>()
    const [dataCauseNote, setDataCauseNote] = useState()
    const [dataWorkaround, setDataWorkaround] = useState()
    const [dataFixNotes, setDataFixNotes] = useState()
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isValidateActive, setIsValidateActive] = useState(true)
    const username: string | undefined = sessionStorage.getItem('username') || undefined

    const params = new URLSearchParams(window.location.search)

    const { id, categoryId, folderId }: any = useParams()

    const { confirm } = Modal

    const { getFieldDecorator, setFieldsValue, getFieldValue, validateFields, resetFields } = props.form

    useEffect(() => {
        let role: string | undefined
        const dataVspace = decryptDataVspace()
        if (typeof dataVspace === 'object') {
            role = dataVspace.roleId.toString()
            setRoleVspace(role)
            fetchAssignmentUserEngineer(role)
        }
        if (!id) {
            setKnowledgeStatus(KnowledgeStatus.MyNote)
            return
        }
        props.getKmMapRole(username!!)
        getKnowledgeById(id).then(knowledge => {
            if (knowledge) {
                setKnowledge(knowledge)
                setKnowledgeStatus(KnowledgeStatus[knowledge.status || KnowledgeStatus.MyNote])
                initKnowledgeData(knowledge)
                const uploadFile = knowledge.uploadLink?.map((file) => {
                    const uploadFile: UploadFile = {
                        uid: file.name,
                        name: file.name,
                        status: 'done',
                        url: file.url,
                        type: file.type,
                        size: 0
                    }
                    return uploadFile
                })
                setFileList(uploadFile || [])
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (ChatGPTShareData.getInstance().data) {
            setDetail(ChatGPTShareData.getInstance().data?.join('') || '')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ChatGPTShareData.getInstance().data])

    useEffect(() => {
        if (props.kmMapRole) {
            props.getAllCategory(props.kmMapRole)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.kmMapRole])

    useEffect(() => {
        if (isSelectCategory || !getFieldValue(FormType.Category)) {
            resetFields([FormType.Folder])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFieldValue(FormType.Category)])

    useEffect(() => {
        if (categoryId) {
            setField({ Category: categoryId.toString() }, categoryId.toString())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId])

    useEffect(() => {
        if (folderId) {
            setField({ Folder: folderId.toString() }, folderId.toString())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [folderId])

    useEffect(() => {
        if (params.get('validation') === '1') {
            validateFields([FormType.Category, FormType.Folder])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.get('validation')])

    useEffect(() => {
        if (id) {
            if (props.categories && knowledge) {
                const filterCategory = props.categories.find(res => res.id === String(knowledge.categoryId))
                const filterFolder = filterCategory?.folderList?.find(res => res.id === knowledge.folderId)
                if (filterFolder) {
                    if (knowledge.folderId) {
                        setField({ Folder: knowledge.folderId.toString() }, knowledge.folderId.toString())
                    }
                    setIsCanEditKM(checkPermissionEdit(filterFolder))
                } else {
                    resetFields([FormType.Folder])
                }
                FolderList(String(knowledge.categoryId))
            }
        } else {
            const categoryId = getFieldValue('Category')
            const folderId = getFieldValue('Folder')
            if (folderId) {
                const filterCategory = props.categories.find(res => res.id === String(categoryId))
                const filterFolder = filterCategory?.folderList?.find(res => res.id === folderId)
                if (filterFolder) {
                    setIsCanEditKM(checkPermissionEdit(filterFolder))
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.categories, dataRoleVspace, knowledge])

    const fetchAssignmentUserEngineer = (role: string) => {
        getAssignmentUserWithRole(role).then((res) => {
            (res || []).map((data) => {
                if (role === data.roleId.toString()) {
                    setDataRoleVspace(data)
                }
                return { id: data.name, value: data.name }
            })
        }).catch((err) => message.error(`Failed loading initial TicketNumber data. ${err}`))
    }

    const checkPermissionEdit = (folder: Folder): boolean => {
        if (knowledge.createdBy?.trim() === username || checkRolesState(RoleType.Knowledge, 'ManageCategoryFolderArticle')) {
            return false
        } else {
            if (folder?.managedBy.length !== 0 && folder?.managedBy) {
                if (folder?.managedBy?.some(it => props.kmMapRole.includes(it.groupName))) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        }
    }

    const initKnowledgeData = (knowledge: Knowledge) => {
        const tagList = knowledge.tagsList?.map((res) => { return res.tag })
        if (knowledge.categoryId) {
            setField({ Category: knowledge.categoryId.toString() }, knowledge.categoryId.toString())
        }
        if (knowledge.folderId) {
            setField({ Folder: knowledge.folderId.toString() }, knowledge.folderId.toString())
        }
        setField({ Type: knowledge.type }, knowledge.type)
        setField({ Tags: tagList }, tagList)
        setIsDisableComment(knowledge.disableComment || false)
        knowledge.topic = knowledge.topic ? knowledge.topic.replace(/ +/g, ' ').trim() : undefined
        setFieldsValue({ Topic: knowledge.topic })
        if (knowledge.detail) {
            const temp = knowledge.detail?.replace(/width="(\d+)"/g, 'width="$1px"')
            setDetail(temp || '')
        }
        if (knowledge.causeNote) {
            const temp1:any = knowledge.causeNote?.replace(/width="(\d+)"/g, 'width="$1px"')
            setDataCauseNote(temp1 || '')
        }
        if (knowledge.workaround) {
            const temp2:any = knowledge.workaround?.replace(/width="(\d+)"/g, 'width="$1px"')
            setDataWorkaround(temp2 || '')
        }
        if (knowledge.fixNote) {
            const temp3:any = knowledge.fixNote?.replace(/width="(\d+)"/g, 'width="$1px"')
            setDataFixNotes(temp3 || '')
        }
    }

    const setField = (object: Object, value?: any) => {
        if (value) {
            setFieldsValue(object)
        }
    }

    const CategoriesList = props.categories.map((category) => {
        return {
            id: category.id!,
            value: category.categoryName!
        }
    })

    const FolderList = (categoryID: string) => {
        const category = props.categories.find((category) => category.id === categoryID)
        if (category) {
            return category.folderList!.map((folder) => {
                return {
                    id: folder.id!,
                    value: folder.folderName
                }
            })
        } else {
            return []
        }
    }

    const createMenu = (data: dropdownData[], id: FormType, isRequire = true) => {
        return getFieldDecorator(id.toString(), { rules: [{ required: isRequire }] })(
            <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select"
                onChange={(selectItem) => {
                    setFieldsValue({ id: selectItem })
                }}
                onSelect={() => {
                    setIsSelectCategory(true)
                }}
                allowClear={true}
            >
                {data.map((data) => {
                    return (
                        <Option value={`${data.id}`} key={data.id}>
                            {data.value}
                        </Option>
                    )
                })}
            </Select>
        )
    }
    const stringSaveTo = (status: KnowledgeStatus) => {
        if (status === KnowledgeStatus.MyNote) {
            return 'Save to Draft'
        } else {
            return 'Save and Publish'
        }
    }

    const validateKnowledge = (e) => {
        validateFields(async (err: any) => {
            const detailValue = detail || ''
            const CheckValue = (detailValue).replace(/<[^>]*>/g, '')
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/

            if (detail !== '') {
                if (err || CheckValue === '' || CheckValue.length <= 2 || !pattern.test(CheckValue.replace(/ +/g, '').trim())) {
                    setLoadingSaveButton(false)
                    setLoadingSaveToDraftButton(false)
                    if (CheckValue === '') {
                        const element = document.getElementsByClassName('ql-editor')?.item(0) as HTMLElement
                        element.style.border = '1px solid red'
                        setTextError('Detail is required')
                        return
                    } else {
                        const element = document.getElementsByClassName('ql-editor')?.item(0) as HTMLElement
                        if (pattern.test(CheckValue.replace(/ +/g, '').trim())) {
                            if (CheckValue.replace(/ +/g, '').trim().length >= 3) {
                                setTextError('')
                            } else {
                                setTextError('Please enter more than 3 characters.')
                                element.style.border = '1px solid red'
                            }
                        } else {
                            if (format.test(CheckValue.replace(/ +/g, '').trim())) {
                                setTextError('Please enter more than 1 characters. and must not contain these characters: ' + format)
                                element.style.border = '1px solid red'
                            } else {
                                setTextError('Please enter more than 3 characters.')
                                element.style.border = '1px solid red'
                            }
                        }
                    }
                    return
                }
            } else {
                const element = document.getElementsByClassName('ql-editor')?.item(0) as HTMLElement
                element.style.border = '1px solid red'
                setTextError('Description is required')
                return
            }

            const newKnowledge = knowledge
            newKnowledge.detailText = CheckValue
            let tempStatus = ''
            const promiseAll: Promise<any>[] = []
            switch (e.target.value) {
            case 'Save':
                setLoadingSaveButton(true)
                if (!newKnowledge.status) {
                    tempStatus = KnowledgeStatus.MyNote
                } else {
                    tempStatus = newKnowledge.status
                }
                break
            case 'Save to Draft':
                setLoadingSaveToDraftButton(true)
                tempStatus = KnowledgeStatus.Draft
                break
            case 'Save and Publish':
                setLoadingSaveToDraftButton(true)
                // eslint-disable-next-line no-case-declarations
                const getFolder = getFolderById(getFieldValue(FormType.Folder)).then((folder) => {
                    if (folder.publicWorkflow === '0') {
                        tempStatus = KnowledgeStatus.Published
                    } else {
                        tempStatus = KnowledgeStatus.AwaitingApproval
                    }
                })
                promiseAll.push(getFolder)
                break
            default:
                tempStatus = knowledge.status || KnowledgeStatus.MyNote
                break
            }
            await Promise.all(promiseAll)

            if (tempStatus !== newKnowledge.status && tempStatus !== KnowledgeStatus.MyNote) {
                validateFields([FormType.Category, FormType.Folder], (error) => {
                    if (error) {
                        setLoadingSaveButton(false)
                        setLoadingSaveToDraftButton(false)
                        return
                    }
                    newKnowledge.status = tempStatus
                    saveKnowledge(newKnowledge)
                })
            } else {
                if (!newKnowledge.status) {
                    newKnowledge.status = tempStatus
                }
                saveKnowledge(newKnowledge)
            }
        })
    }

    const saveKnowledge = (knowledge: Knowledge) => {
        uploadImageInTextArea(detail, (newDetail) => {
            knowledge.detail = newDetail
            if (fileList.length > 0 && fileList.length <= 5) {
                FSS.putFile(fileList, 'knowledge', (uploadLink: UploadLink[]) => {
                    console.debug(uploadLink)
                    if (knowledge.id) {
                        knowledge.uploadLink = uploadLink.map((it) => {
                            return {
                                knowledgeId: knowledge.id,
                                name: it.name,
                                url: it.url,
                                type: it.type
                            } as MyUploadLink
                        })
                    } else {
                        knowledge.uploadLink = uploadLink
                    }
                    submitKnowledge(knowledge)
                })
            } else if (fileList.length > 5) {
                message.error('Upload File Max 5')
                setLoadingSaveButton(false)
                setLoadingSaveToDraftButton(false)
            } else {
                knowledge.uploadLink = []
                submitKnowledge(knowledge)
            }
        })
    }

    const submitKnowledge = (knowledge: Knowledge) => {
        knowledge.categoryId = getFieldValue(FormType.Category)
        knowledge.folderId = getFieldValue(FormType.Folder)
        knowledge.type = getFieldValue(FormType.Type) || null
        // knowledge.tagsList = getFieldValue(FormType.Tags)
        knowledge.disableComment = isDisableComment
        knowledge.topic = getFieldValue(FormType.Topic.replace(/ +/g, ' ').trim())
        knowledge.fixNote = dataFixNotes
        knowledge.causeNote = dataCauseNote
        knowledge.workaround = dataWorkaround
        // knowledge.detail = detail
        knowledge.used = 0
        // knowledge.createdBy = username || ''
        // knowledge.lastModifiedBy = username || ''
        // knowledge.isKmVspace = !!roleVspace
        knowledge.tagsList = (getFieldValue(FormType.Tags) || []).map((it) => {
            return {
                id: undefined,
                knowledgeId: undefined,
                tag: it
            } as unknown as TagsList
        })
        setKnowledge(knowledge)
        setIsSubmitted(true)
        console.log(knowledge)
        if (knowledge.id) {
            // knowledge.createdBy = undefined
            knowledge.createdDate = undefined
            knowledge.createdByDisplay = undefined
            knowledge.lastModifiedBy = undefined
            // knowledge.lastModifiedByDisplay = undefined
            knowledge.lastModifiedDate = undefined
            setIsValidateActive(false)
            updateKnowledge(knowledge).then(response => {
                message.success('The data has been updated successfully.')
                setLoadingSaveButton(false)
                setLoadingSaveToDraftButton(false)
                ChatGPTShareData.getInstance().data = []
                setTimeout(() => {
                    window.location.href = `/KnowledgePreview/${response.id!}`
                    setIsValidateActive(true)
                }, 1500)
            }).catch((error) => {
                setLoadingSaveButton(false)
                setLoadingSaveToDraftButton(false)
                message.error(error.toString())
            }).finally(() => {
                setIsSubmitted(false)
            })
        } else {
            setIsValidateActive(false)
            createKnowledge(knowledge).then(response => {
                message.success('The data has been saved successfully.')
                setLoadingSaveButton(false)
                setLoadingSaveToDraftButton(false)
                ChatGPTShareData.getInstance().data = []
                setTimeout(() => {
                    window.location.href = `/KnowledgePreview/${response.id!}`
                    setIsValidateActive(true)
                }, 1500)
            }).catch((error) => {
                setLoadingSaveButton(false)
                setLoadingSaveToDraftButton(false)
                message.error(error.toString())
            }).finally(() => {
                setIsSubmitted(false)
            })
        }
    }

    const uploadImageInTextArea = (value: string, callBack: Function) => {
        if (value.search('<img')) {
            const MyFileList: UploadFile[] = []
            const temp: string[] = []
            const pattern = /<([A-Z][A-Z0-9]*)\b[^>]*>(.*?)<\/\1>|<([A-Z][A-Z0-9]*).*?\/>/gi
            const HTMLTags = value.match(pattern)
            if (HTMLTags) {
                // when new image is not last
                for (let i = 0; i < HTMLTags.length; i++) {
                    if (HTMLTags[i].search('<img') !== -1) {
                        const tagDatasource = HTMLTags[i].split('<img src="data:')
                        tagDatasource.forEach(tagData => {
                            if (tagData.search('image') !== -1) {
                                temp.push(tagData.split('"')[0])
                            }
                        })
                    } else {
                        temp.push(HTMLTags[i])
                    }
                }
            }

            const newValue: string[] = []
            temp.forEach((it, index) => {
                if (it && it.startsWith('image')) {
                    const fileType = it.split(';')[0]
                    const fileExtention = fileType.split('/')[1] || ''
                    const deleteTagHtml = 'data:' + it.split('">')[0]
                    const ts = `${Math.round((new Date()).getTime() / 1000)}-${index}`
                    const ConvertUploadLink: UploadFile = {
                        uid: `${id}-${ts}`,
                        name: `${id}-${ts}.${fileExtention}`,
                        type: fileType,
                        url: deleteTagHtml,
                        size: deleteTagHtml.length
                    }
                    MyFileList.push(ConvertUploadLink)
                }
            })

            if (MyFileList.length === 0) callBack(value)

            FSS.putFile(MyFileList, 'knowledgeEditor', (uploadLink) => {
                if (uploadLink) {
                    // eslint-disable-next-line no-unused-expressions
                    uploadLink?.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
                    if (HTMLTags) {
                        let imgIndex = 0
                        HTMLTags.forEach((it, index) => {
                            if (HTMLTags[index].search('<img') !== -1) {
                                const inside64 = HTMLTags[index].split('<img src="data:')
                                console.log('inside64', inside64)
                                inside64.forEach(tagData => {
                                    if (tagData.startsWith('image')) {
                                        const insideReplace = tagData.split('"')[0]
                                        imgIndex++
                                        newValue.push(...tagData.replace(insideReplace, `<img src="${uploadLink[imgIndex - 1].url}`))
                                    } else {
                                        newValue.push(...tagData)
                                    }
                                })
                            } else {
                                newValue.push(HTMLTags[index])
                            }
                        })
                    }
                    const joinV = newValue.join('')
                    console.log(joinV)
                    setDetail(joinV.replace(/width="(\d+)"/g, 'width="$1px"'))
                    callBack(joinV.replace(/width="(\d+)"/g, 'width="$1px"'))
                }
            })
        }
    }

    const onTextEditorChange = (value: string) => {
        const CheckValue = (value).replace(/<[^>]*>/g, '')
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        const pattern = /^[A-Za-z0-9ก-๙]{1}/
        const element = document.getElementsByClassName('ql-editor')?.item(0) as HTMLElement
        if (value) {
            setDetail(value)
            if (CheckValue === '') {
                if (isValidateActive) {
                    element.style.border = '1px solid red'
                    setTextError('Detail is required')
                }
            } else {
                if (pattern.test(CheckValue.replace(/ +/g, '').trim())) {
                    if (CheckValue.replace(/ +/g, '').trim().length >= 3) {
                        element.style.border = '0px solid red'
                        setTextError('')
                    } else {
                        element.style.border = '1px solid red'
                        setTextError('Please enter more than 3 characters.')
                    }
                } else {
                    element.style.border = '1px solid red'
                    if (format.test(CheckValue.replace(/ +/g, '').trim())) {
                        setTextError('Please enter more than 1 characters. and must not contain these characters: ' + format)
                    } else {
                        setTextError('Please enter more than 3 characters.')
                    }
                }
            }
        }
    }

    const leavePageModal = () => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you want to leave this page ?',
            okText: 'Yes',
            onOk() {
                ChatGPTShareData.getInstance().data = []
                if (id) {
                    window.location.href = `/KnowledgePreview/${id}`
                } else {
                    window.location.href = '/Knowledge'
                }
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const onChangeTags = (value: SelectValue) => {
        const selectedValue = (value as Array<string>)
        const pattern = /[A-Za-z0-9ก-๙]+/
        let isErrorPattern = false
        selectedValue.forEach((name) => {
            if (!pattern.test(name.replace(/ +/g, ' ').trim())) {
                selectedValue.pop()
                isErrorPattern = true
            }
        })
        if (isErrorPattern) {

        }
    }

    const validateTopic = (_rule: any, value: any, callback) => {
        if (value) {
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (pattern.test(value.replace(/ +/g, '').trim())) {
                if (value.replace(/ +/g, '').trim().length >= 3) {
                    callback()
                } else {
                    callback('Please enter more than 3 characters.')
                }
            } else {
                // eslint-disable-next-line
                if (format.test(value.replace(/ +/g, '').trim())) {
                    callback('Please enter more than 1 characters. and must not contain these characters: ' + format)
                } else {
                    callback('Please enter more than 3 characters.')
                }
            }
        } else {
            callback()
        }
    }

    const getDataCausenote = (data) => {
        setDataCauseNote(data)
    }

    const getDataWorkaround = (data) => {
        setDataWorkaround(data)
    }

    const getDataFixNotes = (data) => {
        setDataFixNotes(data)
    }

    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item onClick={() => { ChatGPTShareData.getInstance().data = [] }}><Link to={'/Knowledge'} >Knowledge</Link></Breadcrumb.Item>
                {id ? (<Breadcrumb.Item onClick={() => { ChatGPTShareData.getInstance().data = [] }}><Link to={`/KnowledgePreview/${id}`}>{getFieldValue('Topic')}</Link></Breadcrumb.Item>) : null}
                <Breadcrumb.Item>{id ? 'Edit Knowledge' : 'Create Knowledge'}</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={20} />
            <Row>
                <Col>
                    <h3 className={'main-title'}>
                        {id ? 'Edit Knowledge' : 'Create Knowledge'}
                        <Tag color={statusColor(knowledgeStatus)} style={{ marginLeft: 10 }}>{convertCamelCaseToSentenceCase(knowledgeStatus)}</Tag>
                        {knowledgeStatus === KnowledgeStatus.Rejected ? (<Tooltip title={knowledge.rejectReason}><Tag>...</Tag></Tooltip>) : ''}
                    </h3>
                </Col>
            </Row>
            <Space size={10} />
            <Form className={!knowledge.status || knowledge.status === KnowledgeStatus.MyNote ? 'km-form' : ''}>
                <Row gutter={[20, 10]}>
                    {knowledge.ticketNumber ? (<Col>
                        {knowledge.ticketNumber}
                    </Col>) : null}
                    <Col span={16} xs={24} sm={24} md={16} lg={8}>
                        <Form.Item label={FormType.Category} className='custom-formLabel-wo'>
                            {createMenu(CategoriesList, FormType.Category)}
                        </Form.Item>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={8}>
                        <Form.Item label={FormType.Folder} className='custom-formLabel-wo'>
                            {createMenu(FolderList(getFieldValue(FormType.Category)), FormType.Folder)}
                        </Form.Item>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={8}>
                        <Form.Item label={FormType.Type} className='custom-formLabel-wo'>
                            {createMenu(dropdownDataType.dataList, FormType.Type, false)}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={FormType.Tags} className='custom-formLabel-wo'>
                            {getFieldDecorator(FormType.Tags)(
                                <Select mode="tags" style={{ width: '100%' }} placeholder="Type Tags"
                                    onChange={onChangeTags} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Checkbox checked={isDisableComment} onChange={(e) => {
                            setIsDisableComment(e.target.checked)
                        }}>Disable Comment</Checkbox>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={FormType.Topic} className='custom-formLabel-wo'>
                            {getFieldDecorator(FormType.Topic, { rules: [{ required: true }, { validator: validateTopic }] })(
                                <Input placeholder='input topic' maxLength={255} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        {getFieldValue(FormType.Type) === '3' || getFieldValue(FormType.Type) === 'KnowError' ? <div>
                            <Row gutter={[8, 8]}>
                                <Col span={24}>
                                    <CustomTextEditor label='Cause note' isUploadFile={false} isrequired={true} getData={getDataCausenote} isSubmit={isSubmitted} displayIndex={0} intializeData={dataCauseNote}/>
                                </Col>
                                <Col span={24}>
                                    <CustomTextEditor label='Workaround' isUploadFile={false} isrequired={false} getData={getDataWorkaround} isSubmit={isSubmitted} displayIndex={1} intializeData={dataWorkaround}/>
                                </Col>
                                <Col span={24}>
                                    <CustomTextEditor label='Fix notes' isUploadFile={false} isrequired={true} getData={getDataFixNotes} isSubmit={isSubmitted} displayIndex={2} intializeData={dataFixNotes}/>
                                </Col>
                            </Row>
                        </div>
                            : <Form.Item label={FormType.Detail} required className='custom-formLabel-wo'>
                                <ReactQuill modules={modules} formats={formats} value={detail} onChange={(e: any) => { onTextEditorChange(e) }} style={{ overflow: 'hidden' }} />
                                <span style={{ color: 'red' }}>{textError}</span>
                            </Form.Item>}
                    </Col>
                    <Col span={24}>
                        <KnowledgeFormUpload fileList={fileList} setFileList={(fileList: UploadFile[]) => { setFileList(fileList) }} />
                    </Col>
                    <Col span={24}>
                        <Button htmlType="submit" disabled={false} type="primary" loading={loadingSaveToDraftButton} onClick={validateKnowledge} value={stringSaveTo(knowledgeStatus)} style={{ float: 'right', marginRight: 10 }}>{stringSaveTo(knowledgeStatus)}</Button>
                        <Button htmlType="submit" disabled={isCanEditKM} type="primary" loading={loadingSaveButton} onClick={validateKnowledge} value='Save' style={{ float: 'right', marginRight: 10 }}>Save</Button>
                        <Button onClick={leavePageModal} style={{ float: 'right', marginRight: 10 }}>Cancel</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        categories: state.knowledgeCategories,
        kmMapRole: state.kmMapRole
    }
}

const MyKnowledgeForm = Form.create<Props>()(KnowledgeForm)

export default connect(mapStateToProps, { getAllCategory, getKmMapRole })(MyKnowledgeForm)
