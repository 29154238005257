import React, { useContext, useEffect, useState } from 'react'
import { Asset, AssetGroupType } from '../../../Preventive/Model'
import { FormComponentProps } from 'antd/es/form'
import { Button, Col, Form, Icon, message, Radio, Row, Tooltip, Modal } from 'antd'
import FileListView from './FileListView'
import CustomInput from './CustomInput'
import CustomSelect from './CustomSelect'
import { AssetGroups, CustomFiledAsset } from '../../../configurable-fields/model'
import RenderFiled from '../../../configurable-fields/RenderFiled'
import { updateAssetOwnerLocation, deleteAssetOwnerLocation } from '../service'
import { checkRolesState, RoleType } from '../../../authorization-module/permissions'
import { getAllSerialNumber, getDataAssetGroup, getDataItems } from '../../../Preventive/services'
import { AssetOwnerLocation } from '../model'
import { UploadFile } from 'antd/lib/upload/interface'
import FSS from '../../../file-server-storage'
import { UploadLink } from '../../../file-server-storage/model'
import { replaceName } from '../../../common-file'
import moment from 'moment'
import CustomSelectGroupAsset from '../CustomSelectGroupAsset'
import { LocationBuildingContext } from '../../../Preventive/ContextAPI/LocationBuildingContext'
import { PeopleContext } from '../../../Preventive/ContextAPI/UserHRContext'
import { getAllUsersByUserId } from '../../../authorization-module/user-role/duck/action'
import CustomInputDate from './CustomInputDate'
// import { People } from '../../../authorization-module/people/model'
import CustomInputGroupAsset2 from '../CustomInputGroupAsset2'
import CustomSelectWithFilter from './CustomSelectWithFilter'
import { Redirect } from 'react-router-dom'
import exportIcon from '../../../common-file/icon-file'
import { tokenDecode } from '../../../common-misc'

interface Params {
    assetType: AssetGroups[]
    assetData?: Asset
    tagUpdate?: Function
    isfromsyncPMAsset?: boolean
}

type Props = Params & FormComponentProps
const TabAssetDetails: React.FC<Props> = (props: Props) => {
    const { setFieldsValue, getFieldValue } = props.form
    const { LocationBuildingState } = useContext<any>(LocationBuildingContext)
    const [dataZone, setDataZone] = useState<string[]>([])
    const [assetStateState, setAssetStateState] = useState<string[]>([])
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [CustomFiledAssetDetails, setCustomFiledAssetDetails] = useState<CustomFiledAsset[]>([])
    const [isEditAll, setIsEditAll] = useState<boolean>(true)
    const [assetGroups, setAssetGroups] = useState<AssetGroupType[]>([])
    const [dataBrand, setDataBrand] = useState<string[]>([])
    const [dataModel, setDataModel] = useState<string[]>([])
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const { PeopleState, GetPeople } = useContext<any>(PeopleContext)
    const [dataSerialNumber, setDataSerialNumber] = useState<string[]>([])
    const [serialNumber, setSerialNumber] = useState<string[]>([])
    const [isRedirect, setIsRedirect] = useState<boolean>(false)

    useEffect(() => {
        getDataItems('assetStatus').then((res) => {
            setAssetStateState(res)
        })
        getDataItems('model').then((res) => {
            if (res) {
                const filterData = res.filter((data) => data !== '')
                setDataModel(filterData)
            }
        })
        getDataItems('brand').then((res) => {
            setDataBrand(res)
        })
        getDataAssetGroup().then((res) => {
            setAssetGroups(res)
        })
        getDataItems('zone').then((res) => {
            setDataZone(res)
        })
        // getAllPeople().then((res) => {
        //     if (GetPeople) {
        //         GetPeople(res)
        //     }
        // })
        getAllSerialNumber().then((res) => {
            if (res) {
                setDataSerialNumber(res)
                setSerialNumber(res.slice(0, 20) || [])
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const dateService = moment(getFieldValue('Date_in_Service'))
        const expiryDate = moment(getFieldValue('Expiry_Date'))
        if (!getFieldValue('Date_in_Service')) {
            props.form.resetFields(['Expiry_Date'])
        }
        if (dateService > expiryDate) {
            props.form.resetFields(['Expiry_Date'])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.getFieldValue('Date_in_Service')])

    useEffect(() => {
        if (props.form.getFieldValue('User_ID') === undefined) {
            props.form.resetFields(['User_Name'])
            props.form.resetFields(['Department'])
        } else {
            const timer = setTimeout(() => {
                getAllUsersByUserId(props.form.getFieldValue('User_ID')).then((res) => {
                    if (res) {
                        GetPeople(res)
                    }
                }).catch(() => {
                    message.warning('Data is null')
                })
            }, 500)
            return () => clearTimeout(timer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.getFieldValue('User_ID')])

    useEffect(() => {
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.assetData])

    useEffect(() => {
        if (CustomFiledAssetDetails.length !== 0) {
            CustomFiledAssetDetails.forEach((data) => {
                const obj = {}
                if (data.value || data.defaultValue) {
                    let value
                    if (data.inputType === 'Checkbox') {
                        value = data.value?.join('|') || (data.defaultValue as any)?.join('|')
                    } else if (data.inputType === 'DatePicker') {
                        value = data.defaultValue || data.value ? moment(data.value as string || data.defaultValue as string) : undefined
                    } else {
                        value = data.value
                    }
                    obj[replaceName(data.name!!)] = value
                    setFieldsValue(obj)
                } else {
                    obj[replaceName(data.name!!)] = undefined
                    setFieldsValue(obj)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CustomFiledAssetDetails])

    const handleSubmit = (e: any) => {
        e.preventDefault()
        props.form.validateFields(async (_err: any, values: any) => {
            if (!_err) {
                const convertData = props.assetData
                const fieldDetails: CustomFiledAsset[] = (CustomFiledAssetDetails || []).map((obj: CustomFiledAsset) => {
                    let value = ''
                    if (obj.name) {
                        if (obj.inputType === 'Checkbox' && Array.isArray(props.form.getFieldValue(replaceName(obj.name)))) {
                            value = (props.form.getFieldValue(replaceName(obj.name)) || [])?.join('|')
                        } else if (obj.inputType === 'DatePicker' && props.form.getFieldValue(obj.name)) {
                            value = moment(props.form.getFieldValue(obj.name)).format('YYYY-MM-DD')
                        } else {
                            if (props.form.getFieldValue(replaceName(obj.name)) && typeof props.form.getFieldValue(replaceName(obj.name)) === 'string') {
                                value = props.form.getFieldValue(replaceName(obj.name))?.replace(/ +/g, ' ')?.trim()
                            } else {
                                value = props.form.getFieldValue(replaceName(obj.name))
                            }
                        }
                    }
                    const fieldDetail: CustomFiledAsset = {
                        id: obj.id,
                        name: obj.name,
                        value: obj.name ? value : undefined,
                        label: obj.label,
                        description: obj.description,
                        inputType: obj.inputType,
                        dataType: obj.dataType,
                        selectOption: obj.selectOption,
                        defaultValue: obj.defaultValue,
                        placeholder: obj.placeholder,
                        isRequireFields: Boolean(obj.isRequireFields),
                        assetGroup: obj.assetGroup,
                        headerGroup: obj.headerGroup,
                        isShowPm: obj.isShowPm
                    }
                    return fieldDetail
                })
                let assetGroupType
                if (values.Type) {
                    assetGroupType = assetGroups.find((item) => item.type.toString() === values.Type.toString())
                }
                const findId = PeopleState?.find((it) => { return it.employeeId === values.User_ID })
                if (convertData) {
                    setIsLoading(true)
                    convertData.status = values.Asset_Status
                    convertData.assetName = values.Asset_Name?.trim() || null
                    convertData.assetTag = values.Asset_Tag?.trim() || null
                    convertData.manufacturer = values.Manufacturer?.trim() || null
                    convertData.model = values.Model || null
                    convertData.brand = values.Brand || null
                    convertData.assetGroupType = assetGroupType || null
                    convertData.site = values.Site || null
                    convertData.location = values.Location || null
                    convertData.building = values.Building || null
                    convertData.floor = values.Floor || null
                    convertData.zone = values.Zone || null
                    convertData.people = findId ? { id: findId.id } as any : null
                    convertData.pairedItAsset = values.Paired_IT_Asset || null
                    convertData.poNumber = values.PO_Number?.trim() || null
                    convertData.dateInService = values.Date_in_Service ? moment(values.Date_in_Service).format('YYYY-MM-DD') : null
                    convertData.expiryDate = values.Expiry_Date ? moment(values.Expiry_Date).format('YYYY-MM-DD') : null
                    convertData.customField = fieldDetails
                    console.log('convertData : ', convertData)
                    if (fileList.length > 0 && fileList.length <= 20) {
                        FSS.putFile(fileList, 'asset', (uploadLink: UploadLink[]) => {
                            convertData.attachFile = JSON.stringify(uploadLink)
                            updateData(convertData)
                        })
                    } else if (fileList.length > 20) {
                        message.error('You can upload up to 20 files.')
                        setIsLoading(false)
                    } else {
                        convertData.attachFile = null!!
                        updateData(convertData)
                    }
                }
            }
        })
    }

    const updateData = (convertData) => {
        delete convertData.createdDatetime
        updateAssetOwnerLocation(String(convertData.id!!), convertData as AssetOwnerLocation).then((res) => {
            if (res) {
                // console.log('Update Asset :', convertData)
                if (props.tagUpdate) {
                    props.tagUpdate(true)
                }
                setIsSubmitSuccess(true)
                message.success('The data has been updated successfully.')
            }
        }).catch(() => {
            message.error('Unable to save data. Please check and try again.')
        }).finally(() => {
            setIsSubmitSuccess(false)
            setIsLoading(false)
            setIsEditAll(true)
        })
    }

    const getEditAll = () => {
        setIsEditAll(false)
    }

    const init = () => {
        if (props.assetData) {
            // console.log('props.assetData : ', props.assetData)
            setFieldsValue({
                Serial_Number: props.assetData.serialNumber,
                Asset_Tag: props.assetData.assetTag,
                Asset_Status: props.assetData.status,
                Asset_Name: props.assetData.assetName,
                Manufacturer: props.assetData.manufacturer,
                Model: props.assetData.model || undefined,
                Brand: props.assetData.brand || undefined,
                Type: props.assetData.assetGroupType?.type,
                Site: props.assetData.site || null,
                Location: props.assetData.location || null,
                Building: props.assetData.building || null,
                Floor: props.assetData.floor || null,
                Zone: props.assetData.zone || null,
                User_ID: props.assetData?.people?.employeeId,
                User_Name: props.assetData?.people?.fullName,
                Department: props.assetData?.people?.department?.departmentName,
                Paired_IT_Asset: props.assetData.pairedItAsset || undefined,
                PO_Number: props.assetData.poNumber,
                Date_in_Service: props.assetData.dateInService ? moment(props.assetData.dateInService) : undefined,
                Expiry_Date: props.assetData.expiryDate ? moment(props.assetData.expiryDate) : undefined
            })
            if (props.assetData.customField) {
                const findData: any[] = []
                props.assetData.customField.forEach((data) => {
                    // if (data?.headerGroup === 'AssetDetails') {
                    if (data.inputType === 'Checkbox') {
                        if (data.defaultValue !== undefined && data.defaultValue !== '') {
                            data.defaultValue = data.defaultValue?.toString().split('|')
                        }
                        if (data.value !== undefined && data.value !== '') {
                            data.value = data.value?.toString().split('|')
                        }
                    }
                    findData.push(data)
                    // }
                })
                if (findData) {
                    console.log(findData)
                    setCustomFiledAssetDetails(findData)
                }
            }
            if (props.assetData.attachFile) {
                const attachFile = JSON.parse(props.assetData.attachFile!!)
                const fileList: UploadFile[] = attachFile?.map((file: UploadLink) => {
                    const uploadFile: UploadFile = {
                        uid: file.name,
                        name: file.name,
                        status: 'done',
                        url: file.url,
                        type: file.type,
                        thumbUrl: exportIcon(file.type!!),
                        size: 0
                    }
                    return uploadFile
                })
                setFileList(fileList)
            }
        }
    }

    const cancelEdit = () => {
        setIsEditAll(true)
        init()
    }

    const getDataFiles = (data) => {
        if (data) {
            setFileList(data)
        }
    }

    // const getDataSelection = (data, peopleData) => {
    //     if (data && peopleData) {
    //         setTempPeopleData(peopleData)
    //         const findData = peopleData.find((it) => { return it.employeeId === data })
    //         setFieldsValue({
    //             User_ID: findData?.employeeId,
    //             User_Name: findData?.fullName,
    //             Department: findData?.department?.departmentName
    //         })
    //     }
    // }

    const checkServicePM = (): boolean => {
        let check = false
        const service = tokenDecode()?.service.serviceId
        if (service) {
            const findService = service.includes(3)
            if (findService) {
                check = true
            }
        }
        return check
    }

    const deleteAsset = (id: number) => {
        Modal.confirm({
            width: 400,
            icon: <Icon type="exclamation-circle" />,
            title: <b style={{ color: '#323276' }}>Warning</b>,
            content: (
                <div>
                    {checkServicePM() ? <p style={{ color: '#323276' }}>This asset is being used in PM. If you delete this asset, any asset being used with an 'open' status will also be deleted.
                        <br /> Do you confirm delete of this data?</p> : <p style={{ color: '#323276' }}>Do you confirm delete of this data?</p>}
                </div>
            ),
            okText: 'Confirm',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk() {
                deleteAssetOwnerLocation(id).then((res) => {
                    message.success('The data has been deleted successfully.')
                    setIsRedirect(true)
                }).catch((err) => {
                    message.error(err.message)
                    console.log(err)
                })
            },
            onCancel() {
                console.log('Cancel')
                setIsRedirect(false)
            }
        })
    }

    return (
        <div>
            {isRedirect ? (<Redirect to="/assetList" />) : null}
            <Row gutter={10} style={{ padding: 24 }}>
                {!props.isfromsyncPMAsset ? <Col span={24}>
                    <h3 style={{ color: '#323276' }}>
                        {(props.assetData?.serialNumber || '-') + ' / ' + (props.assetData?.assetGroup?.name || '-')}
                        {isEditAll && checkRolesState(RoleType.Asset, 'UpdateAsset')
                            ? <Tooltip placement='bottom' title='Edit Asset'>
                                <Icon type='edit' style={{ margin: '0px 5px' }} onClick={getEditAll} />
                            </Tooltip>
                            : null}
                        {checkRolesState(RoleType.Asset, 'DeleteAsset')
                            ? <Tooltip placement='bottom' title='Delete Asset'>
                                <Icon type='delete' style={{ margin: '0px 5px' }} onClick={() => deleteAsset(props.assetData?.id!!)} />
                            </Tooltip>
                            : null}
                    </h3>
                </Col> : null}

                {/* <Col span={22} style={{textAlign: 'right', zIndex: 2}}>
                    {isEditAll && checkRolesState(RoleType.Asset, 'UpdateAsset') ?
                        <Tooltip placement='bottom' title='Edit Asset'>
                            <Icon type='edit' style={{ marginTop: window.innerWidth < 768 ? 15 : 25, marginLeft: 10 }} className="feck-link" onClick={getEditAll}/>
                        </Tooltip>
                        : null}
                </Col>
                <Col span={2} style={{zIndex: 2}}>
                    {checkRolesState(RoleType.Asset, 'DeleteAsset') ?
                        <Tooltip placement='bottom' title='Delete Asset'>
                            <Icon type='delete' style={{ marginTop: window.innerWidth < 768 ? 15 : 25, marginLeft: 10 }} className="feck-link" onClick={() => deleteAsset(props.assetData?.id!!)}/>
                        </Tooltip>
                    : null }
                </Col> */}
                <Col span={24}>
                    <FileListView Data={props.assetData?.attachFile} getDataFiles={getDataFiles} disableItem={isEditAll} isFromSyncPMAsset={props.isfromsyncPMAsset} />
                </Col>
                <Col span={24}>
                    <Radio.Group disabled={true} value={props.assetData?.assetGroup?.name} style={{width: '100%'}}>
                        <Row type='flex' justify='center'>
                            {props.assetType.map((data, index) => {
                                return <Radio value={data.name} key={index}>{data.name}</Radio>
                            })}
                        </Row>
                    </Radio.Group>
                    <br /> <br />
                </Col>
                <br /><br />
                <Form onSubmit={handleSubmit}>
                    <CustomInput form={props.form} name={'Serial Number'} editable={false} required={true} autoCompleteData={[]} fullData={[]} disableItem={true} />
                    <CustomSelect form={props.form} name={'Asset Status'} required={true} options={assetStateState} isSubmitSuccess={isSubmitSuccess} disableItem={isEditAll} />
                    <CustomInput form={props.form} name={'Asset Tag'} editable={false} required={false} autoCompleteData={[]} fullData={[]} disableItem={isEditAll} />
                    <CustomInput form={props.form} name={'Asset Name'} editable={false} required={false} autoCompleteData={[]} fullData={[]} disableItem={isEditAll} />
                    <CustomInput form={props.form} name={'Manufacturer'} editable={false} required={false} autoCompleteData={[]} fullData={[]} disableItem={isEditAll} />
                    <CustomSelect form={props.form} name={'Model'} required={false} options={dataModel} disableItem={isEditAll} />
                    <CustomSelect form={props.form} name={'Brand'} required={false} options={dataBrand} disableItem={isEditAll} />
                    <CustomSelect form={props.form} name={'Type'} required={false} options={assetGroups.length !== 0 ? (assetGroups?.filter((it) => it.assetGroup?.name === props.assetData?.assetGroup?.name) || [])?.map((data) => {
                        return data.type
                    }) : []} disableItem={isEditAll} />
                    <CustomSelectGroupAsset form={props.form} isPreview={true} isEdit={true} locationBuildingList={LocationBuildingState} isSubmitSuccess={isSubmitSuccess} dataZone={dataZone} disableItem={isEditAll} />
                    {/* <CustomSelect form={props.form} name={'User ID'} required={false} options={PeopleState.map((data) => { return data.employeeId })} disableItem={isEditAll} getDataSelection={getDataSelection}/>
                    <CustomInput form={props.form} name={'User Name'} editable={false} required={false} autoCompleteData={[]} fullData={[]} disableItem={true}/>
                    <CustomInput form={props.form} name={'Department'} editable={false} required={false} autoCompleteData={[]} fullData={[]} disableItem={true}/> */}
                    <CustomInputGroupAsset2 form={props.form} isPreview={true} isEdit={true} maxInput={255} isOpenModel={true} DataHR={PeopleState} disableItem={isEditAll} />
                    {/* <CustomSelect form={props.form} name={'Paired IT Asset'} required={false} options={dataSerialNumber} disableItem={isEditAll}/> */}
                    <CustomSelectWithFilter form={props.form} name={'Paired IT Asset'} required={false} autoCompleteData={serialNumber} fullData={dataSerialNumber} disableItem={isEditAll} setState={setSerialNumber} isNotShowArrow={true} />
                    <CustomInput form={props.form} name={'PO Number'} editable={false} required={false} autoCompleteData={[]} fullData={[]} disableItem={isEditAll} />
                    <CustomInputDate form={props.form} name={'Date in Service'} dateService={getFieldValue('Expiry_Date')} editable={false} required={false} disableItem={isEditAll} />
                    <CustomInputDate form={props.form} name={'Expiry Date'} dateService={getFieldValue('Date_in_Service')} editable={false} required={false} disableItem={isEditAll} />
                    <Row>
                        <Col xl={3} md={0}></Col>
                        <Col xl={16} md={24}>
                            <RenderFiled fields={CustomFiledAssetDetails} form={props.form} colSpan={24} colSM={24} labelColSm={4} wrapperColSm={20} maxWidth={'100%'} genCol={2} disableFields={isEditAll} isShowToggle={false} />
                        </Col>
                        <Col xl={5} md={0}></Col>
                    </Row>
                    <Row>
                        <Col xl={5} md={0}></Col>
                        <Col xl={14} md={24}>
                            {!isEditAll ? <Button type="primary" htmlType="submit" style={{ float: 'right', marginTop: 10, marginRight: 5 }} disabled={!checkRolesState(RoleType.Asset, 'UpdateAsset')} loading={isLoading}>Submit</Button> : null}
                            {!isEditAll ? <Button htmlType="button" onClick={() => cancelEdit()} style={{ float: 'right', marginRight: window.innerWidth < 768 ? 10 : 20, marginTop: 10 }} disabled={!checkRolesState(RoleType.Asset, 'UpdateAsset')} >Cancel</Button> : null}
                        </Col>
                        <Col xl={5} md={0}></Col>
                    </Row>
                </Form>
            </Row>
        </div>
    )
}

const TabAssetDetailsForm = Form.create<Props>({ name: 'TabAssetDetails' })(TabAssetDetails)
export default TabAssetDetailsForm
