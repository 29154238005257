import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Input, Table, Tooltip, Switch, Icon, Modal, message, Checkbox } from 'antd'
import { Link } from 'react-router-dom'
import { Space } from '../../../common-components/Space'
import { searchWorkFlow, deleteWorkFlow, updateWorkFlow } from '../Service/service'
import { WorkFlow } from '../Service/Model'
import { StoreState } from '../../../store'
import { connect } from 'react-redux'
import { waitingGetSessions } from '../../../guard/route'

const mapStateToProps = (state: StoreState) => {
    return {
        workFlow: state.workFlow,
        workFlowPagination: state.workFlowPagination
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    searchWorkFlow: (page: number, pageSize: number, sort?: string, order?: string, search?: string, activated?: boolean[]) => Promise<number>
    deleteWorkFlow: (id: number) => Promise<number>
    updateWorkFlow: (id: number, workFlow: WorkFlow) => Promise<number>;
}

type Props = StateProps & DispatchProps

const { Search } = Input
const { confirm } = Modal
const WorkFlowList: React.FC<Props> = (props: Props) => {
    const [pageSize, setPageSize] = useState<number>(10)
    const [sortBy, setSortBy] = useState<string>('createdDatetime')
    const [orderBy, setOrderBy] = useState<string>('desc')
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState<number>(1)
    const [filterActivated, setFilterActivated] = useState<boolean[] | undefined>([true, false])
    const [searchData, setSearchData] = useState<string>('')

    useEffect(() => {
        if (props.workFlow.length === 0 && page > 1) {
            setPage(page - 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.workFlow])

    useEffect(() => {
        setIsLoading(true)
        props.searchWorkFlow(page, pageSize, sortBy, orderBy, searchData.trim(), filterActivated).finally(() => setIsLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, sortBy, orderBy, filterActivated])

    const renderFilter = () => {
        return (<>
            <Checkbox.Group style={{ width: '100%' }} onChange={handleFilter} value={filterActivated}>
                <Row>
                    <Col span={24} style={{ margin: 10 }}>
                        <Checkbox value={true}>Activate</Checkbox>
                    </Col>
                    <br />
                    <Col span={24} style={{ margin: 10 }}>
                        <Checkbox value={false}>Deactivate</Checkbox>
                    </Col>
                </Row>
            </Checkbox.Group>
        </>)
    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row: any) => (
                <>
                    <Tooltip placement="bottom" title={'Delete'} ><Icon className="del_icon" type="delete" onClick={() => HandleDeleteWorkFlow(row.id, row.activated)} /></Tooltip>
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row: any) => (
                <>
                    <Tooltip placement="bottom" title={'Edit'}><Link to={`/WorkflowFormUpdate/${row.id}`} ><Icon className="edit_icon" type="edit" /></Link></Tooltip>
                </>
            )
        },
        {
            title: 'Workflow Number',
            dataIndex: 'workflowNumber',
            key: 'workflowNumber',
            sorter: true,
            render: (data: string, row) => (
                <>
                    <div onClick={() => waitingGetSessions()}>
                        <Link to={'/WorkflowFormView/' + row.id} target="_blank" onClick={() => waitingGetSessions()}>{data}</Link>
                    </div>
                </>
            )
        },
        {
            title: 'Workflow Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            render: (data: string, row) => (
                <>
                    <div>
                        {data?.length!! > 30 ? <Tooltip title={data} placement='bottom'>{data?.substring(0, 30) + ' ...'}</Tooltip> : data}
                    </div>
                </>
            )
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: true,
            render: (data: string, row) => (
                <>
                    <div>
                        {data?.length!! > 50 ? <Tooltip title={data} placement='bottom'>{data?.substring(0, 50) + ' ...'}</Tooltip> : data}
                    </div>
                </>
            )
        },
        {
            title: 'Updated By',
            dataIndex: 'lastModifiedByDisplay',
            key: 'lastModifiedByDisplay',
            sorter: true
        },
        {
            title: 'Updated Date',
            dataIndex: 'lastModifiedDatetime',
            key: 'lastModifiedDatetime',
            sorter: true
        },
        {
            title: 'Active',
            dataIndex: '',
            key: 'activated',
            filters: [{ text: 'Activate', value: 'true' }, { text: 'Deactivate', value: 'false' }],
            filteredValue: filterActivated,
            filterDropdown: renderFilter,
            render: (row) => (
                <>
                    <Tooltip placement="bottom" title={row.activated ? 'Deactivate' : 'Activate'}>
                        <Switch checked={row.activated} onChange={() => toggleActiveness(row)} />
                    </Tooltip>
                </>
            )
        }
    ]

    const HandleDeleteWorkFlow = (id, activate): void => {
        try {
            confirm({
                title: 'Are you sure?',
                content: 'Do you confirm Delete this data?',
                okText: 'OK',
                okType: 'danger',
                autoFocusButton: 'cancel',
                cancelText: 'Cancel',
                async onOk() {
                    if (activate) {
                        message.warning('Please deactivate this data before you continue.')
                    } else {
                        props.deleteWorkFlow(id).then(() => {
                            message.success('The data has been deleted successfully.')
                        })
                    }
                }
            })
        } catch (error: any) {
            console.log(error)
        }
    }

    const toggleActiveness = (row: WorkFlow) => {
        const convertData: WorkFlow = {
            name: row.name || '',
            description: row.description || '',
            renderingAttributes: row.renderingAttributes,
            nodes: row.nodes,
            activated: !row.activated,
            lastModifiedByDisplay: row.lastModifiedByDisplay
        }

        if (!row.activated) {
            props.updateWorkFlow(row.id!!, convertData).then(() => {
                message.success('The data has been updated successfully.')
            })
        } else {
            confirm({
                title: 'Are you sure?',
                content: 'Do you confirm deactivating this data ?',
                onOk() {
                    props.updateWorkFlow(row.id!!, convertData).then(() => {
                        message.success('The data has been updated successfully.')
                    })
                }
            })
        }
    }

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        if (sorter.field) {
            setSortBy(sorter.field)
            if (sorter.order === 'ascend') {
                setOrderBy('ASC')
            } else if (sorter.order === 'descend') {
                setOrderBy('DESC')
            } else {
                setSortBy('createdDatetime')
                setOrderBy('DESC')
            }
        }
        if (Object.keys(filters).length > 0) {
            setFilterActivated(filters.activated)
            // if (filters.activated.length > 1) {
            //     setFilterActivated(undefined)
            // } else {
            //     setFilterActivated(filters.activated)
            // }
        }
        setPageSize(pagination.pageSize)
    }

    const onSearch = value => {
        const pageSearch = 1
        setIsLoading(true)
        setSearchData(value.trim())
        props.searchWorkFlow(pageSearch, pageSize, sortBy, orderBy, value?.trim(), filterActivated).then(() => {
            setIsLoading(false)
        }).catch(() => {
            message.error('Unable to retrieve the data. Please try again later.')
            setIsLoading(false)
        })
    }

    const handleFilter = (value) => {
        if (value) {
            if (value.length === 1) {
                if (value[0] === 'true' || value[0] === true) {
                    setFilterActivated([true])
                } else {
                    setFilterActivated([false])
                }
            } else if (value.length === 2) {
                setFilterActivated([true, false])
            } else {
                setFilterActivated([])
            }
            setPage(1)
        }
    }

    return (
        <>
            <Row>
                <Col span={24} style={{ textAlign: 'end' }}>
                    <Link to={'/WorkflowForm'}><Button type={'primary'} >New Workflow</Button></Link>
                </Col>
            </Row>
            <Space size={10} />
            <Row>
                <Col span={24}>
                    <div><p className={'HerderText'}>All Workflow </p></div>
                    <Search
                        placeholder="Can search Workflow Number or Workflow Name and Description"
                        onSearch={onSearch}
                        style={{ width: '100%' }}
                        id='search_workflow'
                        allowClear
                        onChange={(value) => { setSearchData(value.target.value) }}
                        value={searchData}
                    />
                </Col>
            </Row>
            <br />
            <Table
                dataSource={props.workFlow}
                rowKey="id"
                columns={columns}
                pagination={{
                    pageSize: pageSize,
                    current: page,
                    total: props.workFlowPagination?.total,
                    onChange: (event) => {
                        handleOnClickPageNumber(event)
                    }
                }}
                onChange={handleChangeTable}
                loading={isLoading}
            />
        </>
    )
}

export default connect(mapStateToProps, {
    searchWorkFlow,
    deleteWorkFlow,
    updateWorkFlow
})(WorkFlowList)
