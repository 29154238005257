/* eslint-disable no-useless-escape */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Card, Col, Empty, Form, Icon, Input, message, Modal, Row, Select, Spin, Tag } from 'antd'
import { connect } from 'react-redux'
import { StoreState } from '../../store'
import { FormComponentProps } from 'antd/lib/form'
import { SelectValue } from 'antd/es/select'
import { CompactPicker } from 'react-color'
import { PriorityMatrixProblem, PriorityLevelProblem } from './model'
import { createPriorityMatrixNewVersionProblem, getAllPriorityProblem, getPriorityConfigurationProblem, createNewProblemPriorityLevel, getPriorityCount } from './service'
import { Link, Redirect } from 'react-router-dom'
import { getAllUrgencyPriorty } from '../urgency'
import { getAllImpactPriorty } from '../impact'
// import { getCountIncident } from '../incident/service'
// import { getAllIncident } from '../incident/service'

const mapStateToProps = (state: StoreState) => {
    return {
        priorityMatrixProblems: state.priorityMatrixProblems,
        incidents: state.incidentState.allIncident || [],
        priorityProblem: state.priorityProblem,
        impactProblem: state.impactProblem,
        urgencieProblem: state.urgencieProblem
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    createPriorityMatrixNewVersionProblem: (priorityConfiguration: PriorityMatrixProblem[] | undefined) => Promise<number>
    getPriorityConfigurationProblem: () => Promise<number>
    // getAllIncident: () => Promise<number>
    getAllPriorityProblem: () => Promise<number>
    getAllImpactPriorty: () => Promise<number>
    getAllUrgencyPriorty: () => Promise<number>
}

type Props = StateProps & DispatchProps & FormComponentProps

let tempPriorityMatrix: PriorityMatrixProblem[] = []
let priorityMatrixObj: any[] = []
let originMatrix: any[] = []
let tempPriorityVersion: number | undefined = 0
const { Option } = Select
const PriortyConfigPage: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator } = props.form
    const [tagColor, setTagColor] = useState('#4D4D4D')
    const [showTag, setShowTag] = useState(true)
    const [listOfTagPriority, setListOfTagPriority] = useState<any[]>([])
    const [listOfImpact, setListOfImpacted] = useState<any[]>([])
    const [listOfUrgency, setListOfUrgencies] = useState<any[]>([])
    const [isRequirePriority, setIsRequirePriority] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [selectedPriorityTag, setSelectedPriorityTag] = useState('')
    const [isRedirect] = useState(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [countIncident, setCountIncident] = useState(0)
    const [isLoadingCountIncident, setIsLoadingCountIncident] = useState(false)

    useEffect(() => {
        setIsLoadingCountIncident(true)
        getPriorityCount().then((res) => {
            setCountIncident(Number(res))
        }).finally(() => {
            setIsLoadingCountIncident(false)
        })
        const promiseAll: any[] = []
        promiseAll.push(fetchPriorityConfiguration())
        promiseAll.push(fetchDataPriority())
        promiseAll.push(fetchDataImpact())
        promiseAll.push(fetchDataUrgency())
        Promise.all(promiseAll).catch((err) => {
            message.error(`Unable to retrieve the data. Please try again later. ${err}`)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.priorityMatrixProblems && props.priorityProblem.length !== 0 &&
            props.impactProblem.length !== 0 && props.urgencieProblem.length !== 0) {
            initialPriorityData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.priorityProblem, props.impactProblem, props.urgencieProblem, props.priorityMatrixProblems])

    const fetchDataPriority = () => {
        return props.getAllPriorityProblem()
    }

    const fetchDataImpact = () => {
        return props.getAllImpactPriorty()
    }

    const fetchDataUrgency = () => {
        return props.getAllUrgencyPriorty()
    }

    const fetchPriorityConfiguration = () => {
        return props.getPriorityConfigurationProblem()
    }

    const initialPriorityData = () => {
        tempPriorityMatrix = []
        priorityMatrixObj = []
        originMatrix = []
        const listOfImpactName = props.impactProblem.map((item) => item.name)
        const listOfUrgencyName = props.urgencieProblem.map((item) => item.name)

        props.form.setFieldsValue({
            Impact: listOfImpactName,
            Urgency: listOfUrgencyName
        })
        setListOfImpacted(props.impactProblem)
        setListOfUrgencies(props.urgencieProblem)
        setListOfTagPriority(props.priorityProblem)
        tempPriorityMatrix = props.priorityMatrixProblems
        tempPriorityVersion = props.priorityMatrixProblems.map(it => it.priorityVersion)[0]
        props.priorityMatrixProblems.forEach((item) => {
            if (item) {
                priorityMatrixObj.push({
                    impact: {
                        id: item.impact?.id,
                        name: item.impact?.name
                    },
                    urgency: {
                        id: item.urgency?.id,
                        name: item.urgency?.name
                    },
                    priority: {
                        id: item.priority?.id,
                        name: item.priority?.name
                    },
                    priorityVersion: item.priorityVersion
                })
                originMatrix.push({
                    impact: {
                        id: item.impact?.id,
                        name: item.impact?.name
                    },
                    urgency: {
                        id: item.urgency?.id,
                        name: item.urgency?.name
                    },
                    priority: {
                        id: item.priority?.id,
                        name: item.priority?.name
                    },
                    priorityVersion: item.priorityVersion
                })
            }
        })
    }

    const modifyPriorityMatrixToDisplay = (impact, urgency, priority) => {
        if (!priority) {
            const obj: PriorityMatrixProblem = {
                impact: {
                    name: impact,
                    number: 0,
                    tagColor: '',
                    type: 'impact'
                },
                urgency: {
                    name: urgency,
                    number: 0,
                    tagColor: '',
                    type: 'urgency'
                },
                priority: {
                    name: '',
                    number: 0,
                    tagColor: '',
                    type: 'priority'
                },
                priorityVersion: tempPriorityVersion
            }
            tempPriorityMatrix.push(obj)
            priorityMatrixObj.push(obj)
        }
    }

    const generateMatrixToCreate = (impact, urgency, priority) => {
        const isPriorityId = typeof priority === 'number'
        const index = priorityMatrixObj.findIndex(origin => (origin.impact?.name === impact.name && origin.urgency?.name === urgency.name))
        priorityMatrixObj[index] = {
            impact: {
                id: 0,
                name: ''
            },
            urgency: {
                id: 0,
                name: ''
            },
            priority: {
                id: 0,
                name: ''
            },
            priorityVersion: priorityMatrixObj[index].priorityVersion
        }
        if (impact.id) { priorityMatrixObj[index].impact.id = impact.id } else { priorityMatrixObj[index].impact.name = impact.name }
        if (urgency.id) { priorityMatrixObj[index].urgency.id = urgency.id } else { priorityMatrixObj[index].urgency.name = urgency.name }
        if (isPriorityId) { priorityMatrixObj[index].priority.id = priority } else { priorityMatrixObj[index].priority.name = priority }
        if (impact.name) priorityMatrixObj[index].impact.name = impact.name
        if (urgency.name) priorityMatrixObj[index].urgency.name = urgency.name
        if (priority) {
            if (typeof priority === 'string') {
                priorityMatrixObj[index].priority.name = priority
            } else if (typeof priority === 'number') {
                priorityMatrixObj[index].priority.id = priority
                if (props.priorityProblem.length > 0) {
                    priorityMatrixObj[index].priority.name = props.priorityProblem?.find(p => p.id === priority)?.name
                }
            }
        }
    }
    const onImpactChanged = (value: SelectValue) => {
        const selectedValue = (value as Array<string>)
        const pattern = /[A-Za-z0-9ก-๙]+/
        let isErrorPattern = false

        if (hasLowerCaseAndDuplicates(value)) {
            selectedValue.pop()
        }

        selectedValue.forEach((name) => {
            if (!pattern.test(name.replace(/ +/g, ' ').trim())) {
                selectedValue.pop()
                isErrorPattern = true
            }
        })
        if (isErrorPattern) {
            return
        }
        selectedValue.forEach((name: string, index: number) => {
            if (pattern.test(name.replace(/ +/g, ' ').trim())) {
                const obj: PriorityLevelProblem = {
                    tagColor: '',
                    name: name.replace(/ +/g, ' ').trim(),
                    number: index.toString(),
                    type: 'impact'
                }
                setListOfImpacted([...listOfImpact, obj])
            }
        })
        /* if origin impact < new impact [add impact] */
        if (listOfImpact.length < selectedValue.length) {
            listOfUrgency.forEach((urgency) => {
                modifyPriorityMatrixToDisplay(value[selectedValue.length - 1].replace(/ +/g, ' ').trim(), urgency.name.replace(/ +/g, ' ').trim(), '')
            })
        } else {
            /* [delete impact] */
            modifyImpactValueBeforeGenerateMatrix(value)
        }
    }

    const modifyImpactValueBeforeGenerateMatrix = (selectedValue) => {
        listOfImpact.forEach((it, index) => {
            const isNotDeleted = selectedValue.includes(it.name)
            if (isNotDeleted === false) {
                for (let i = tempPriorityMatrix.length - 1; i >= 0; --i) {
                    if (tempPriorityMatrix[i].impact!!.name === it.name) {
                        tempPriorityMatrix.splice(i, 1)
                    }
                }
                for (let i = priorityMatrixObj.length - 1; i >= 0; --i) {
                    if (priorityMatrixObj[i].impact!!.name === it.name) {
                        priorityMatrixObj.splice(i, 1)
                    }
                }
                listOfImpact.splice(index, 1)
                const tempListOfImpact = listOfImpact
                setListOfImpacted(tempListOfImpact)
            }
        })
    }

    const onUrgencyChanged = (value: SelectValue) => {
        const selectedValue = (value as Array<string>)
        const pattern = /[A-Za-z0-9ก-๙]+/
        let isErrorPattern = false

        if (hasLowerCaseAndDuplicates(value)) {
            selectedValue.pop()
        }

        selectedValue.forEach((name) => {
            if (!pattern.test(name.replace(/ +/g, ' ').trim())) {
                selectedValue.pop()
                isErrorPattern = true
            }
        })
        if (isErrorPattern) {
            return
        }
        selectedValue.forEach((name: string, index: number) => {
            if (pattern.test(name)) {
                const obj: PriorityLevelProblem = {
                    tagColor: '',
                    name: name.replace(/ +/g, ' ').trim(),
                    number: index.toString(),
                    type: 'urgency'
                }
                setListOfUrgencies([...listOfUrgency, obj])
            }
        })
        if (listOfUrgency.length < selectedValue.length) {
            listOfImpact.forEach((impact) => {
                modifyPriorityMatrixToDisplay(impact.name.replace(/ +/g, ' ').trim(), value[selectedValue.length - 1].replace(/ +/g, ' ').trim(), '')
            })
        } else {
            modifyUrgencyValueBeforeGenerateMatrix(value)
        }
    }

    const modifyUrgencyValueBeforeGenerateMatrix = (urgency) => {
        listOfUrgency.forEach((it, index) => {
            if (listOfUrgency.length > urgency.length) {
                const isDeleted = urgency.includes(it.name)
                if (isDeleted === false) {
                    listOfImpact.forEach(() => {
                        for (let i = tempPriorityMatrix.length - 1; i >= 0; --i) {
                            if (tempPriorityMatrix[i].urgency!!.name === it.name) {
                                tempPriorityMatrix.splice(i, 1)
                            }
                        }
                        for (let i = priorityMatrixObj.length - 1; i >= 0; --i) {
                            if (priorityMatrixObj[i].urgency!!.name === it.name) {
                                priorityMatrixObj.splice(i, 1)
                            }
                        }
                    })
                    const filterData = listOfUrgency.filter((data) => it.id === undefined ? data.name !== it.name : data.id !== it.id)
                    setListOfUrgencies(filterData)
                    const tempListOfImpact = listOfImpact
                    setListOfImpacted(tempListOfImpact)
                }
            }
        })
    }

    const handleAddPriority = () => {
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        const newPriority = props.form.getFieldValue('priority')
        if (format.test(newPriority)) {
            return
        }
        const existPriority = listOfTagPriority.map(it => it.name)
        props.form.setFieldsValue({
            priority: undefined
        })
        if (newPriority) {
            if (!existPriority.includes(newPriority.trim())) {
                setIsRequirePriority(false)
                setShowTag(true)
                const priority: PriorityLevelProblem = {
                    name: newPriority.replace(/ +/g, ' ').trim(),
                    tagColor: tagColor,
                    number: (listOfTagPriority?.length + 1)?.toString() || '0',
                    type: 'priority'
                }
                setListOfTagPriority([...listOfTagPriority, priority])
            }
        } else {
            setIsRequirePriority(true)
        }
    }

    const handleCloseTag = (removedTag: any) => {
        const tags = listOfTagPriority.filter(tag => tag !== removedTag)
        setIsRequirePriority(false)
        tags.forEach((tags, i) => tags.order = i + 1)
        /* remove value from field that have priority value that deleted */
        tempPriorityMatrix.forEach((matrix) => {
            const json = {}
            if (matrix.priority?.name === removedTag.name) {
                const key = `${matrix.urgency?.name}priority${matrix.impact?.name}`
                json[key] = undefined
                props.form.setFieldsValue(json)
                matrix.priority = {
                    name: '',
                    tagColor: '',
                    number: 0,
                    type: 'priority'
                }
            }
        })
        setListOfTagPriority(tags)
    }

    const renderPriorityTag = () => {
        return listOfTagPriority.map((item) => {
            return (
                <Tag
                    closable={countIncident === 0}
                    key={item.name}
                    color={item.tagColor}
                    style={{ margin: '5px' }}
                    onClick={() => onClickTag(item)}
                    onClose={() => handleCloseTag(item)}
                >
                    {item.name}
                </Tag>
            )
        })
    }

    const onClickTag = (priorityTag) => {
        setShowModal(true)
        setSelectedPriorityTag(priorityTag.name)
        setTagColor(priorityTag.tagColor)
    }

    const handleSubmitTable = () => {
        setIsRequirePriority(false)
        props.form.validateFields((err: any) => {
            if (!err) {
                // const promiseAll: any[] = []
                setIsLoading(true)
                const convertDataImpact = listOfImpact.map((impact) => {
                    return {
                        id: impact.id || undefined,
                        name: impact.name,
                        orderNumber: Number(impact.orderNumber) || Number(impact.number) || 0,
                        tagColor: impact.tagColor,
                        type: 'Impact'
                    }
                })
                const convertDataUrgency = listOfUrgency.map((Urgency) => {
                    return {
                        id: Urgency.id || undefined,
                        name: Urgency.name,
                        orderNumber: Number(Urgency.orderNumber) || Number(Urgency.number) || 0,
                        tagColor: Urgency.tagColor,
                        type: 'Urgency'
                    }
                })
                const convertDataPriority = listOfTagPriority.map((Priority) => {
                    return {
                        id: Priority.id || undefined,
                        name: Priority.name,
                        orderNumber: Number(Priority.orderNumber) || Number(Priority.number) || 0,
                        tagColor: Priority.tagColor,
                        type: 'Priority'
                    }
                })
                const convertData = {
                    priority: convertDataPriority,
                    impact: convertDataImpact,
                    urgency: convertDataUrgency
                }
                createNewProblemPriorityLevel(convertData).then((res: any) => {
                    if (JSON.stringify(priorityMatrixObj) !== JSON.stringify(originMatrix)) {
                        priorityMatrixObj.forEach((matrix) => {
                            if (matrix.impact.id === 0 || matrix.impact.id === undefined) {
                                matrix.impact.id = res.impact?.find(n => n.name === matrix.impact.name)?.id
                            }
                            if (matrix.urgency.id === 0 || matrix.urgency.id === undefined) {
                                matrix.urgency.id = res.urgency?.find(n => n.name === matrix.urgency.name)?.id
                            }
                            if (matrix.priority.id === 0 || matrix.priority.id === undefined) {
                                matrix.priority.id = res.priority?.find(n => n.name === matrix.priority.name)?.id
                            }
                            if (matrix.priority.id !== 0 && matrix.priority.name === '') {
                                matrix.priority.name = res.priority?.find(n => n.id === matrix.priority.id)?.name
                            }
                            matrix.priorityVersion = (matrix.priorityVersion || 0) + 1
                        })
                        props.createPriorityMatrixNewVersionProblem(priorityMatrixObj)
                            .then(() => {
                                setIsLoading(false)
                                message.success('The data has been saved successfully.')
                                // setIsRedirect(true)
                            })
                            .catch((err) => {
                                setIsLoading(false)
                                message.error(`Unable to save data. Please check and try again. ${err}`)
                            })
                    } else {
                        // setIsRedirect(true)
                        setIsLoading(false)
                        message.success('The data has been saved successfully.')
                    }
                })
            }
        })
    }

    const modalConfirmCancel = (): void => {
        setIsRequirePriority(false)
        Modal.confirm({
            title: 'Are you sure?',
            content: 'Do you want to leave this page ?',
            okText: 'Yes',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'No',
            onOk() {
                window.location.href = '/Setting'
            }
        })
    }

    const validateTagPriority = (_: any, value: any, callback) => {
        setIsRequirePriority(false)
        const existPriority = listOfTagPriority.map(it => it.name)
        if (value) {
            if (countIncident !== 0) {
                callback()
            } else if (existPriority.includes(value.trim()) && value.length >= 3) {
                callback('This entry already exists in the system.')
            } else {
                const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
                const pattern = /[A-Za-z0-9ก-๙]+/
                if (format.test(value.trim().charAt(0))) {
                    // eslint-disable-next-line
                    callback(`Please enter more than 1 characters. and must not contain these characters: ${format}`)
                } else {
                    if (pattern.test(value.trimEnd())) {
                        callback()
                    } else {
                        callback('Default value is not valid.')
                    }
                }
                callback()
            }
        } else {
            callback()
        }
    }

    const onSubmitChangePriorityColor = () => {
        setShowModal(false)
        listOfTagPriority.forEach((it) => {
            if (it.name === selectedPriorityTag) {
                it.tagColor = tagColor
            }
        })
    }

    const hasLowerCaseAndDuplicates = (arr) => {
        const lowercaseArr = arr.map(str => str.toLowerCase())
        const uniqueValues = new Set(lowercaseArr)
        return uniqueValues.size !== lowercaseArr.length
    }

    const validateImpact = (_: any, value: any, callback) => {
        if (value) {
            let isOneChar = false
            let isCharStartSpecial = false
            const reg = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            value.forEach(data => {
                if (data.length <= 1) {
                    isOneChar = true
                } else if (reg.test(data.charAt(0))) {
                    isCharStartSpecial = true
                }
            })
            if (isOneChar) {
                callback(`Field Label cant contain any of the following characters ${reg}`)
            } else if (hasLowerCaseAndDuplicates(value)) {
                callback('This entry already exists in the system.')
            } else if (isCharStartSpecial) {
                callback(`Please enter more than 1 characters. and must not contain these characters: ${reg}`)
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    const validateUrgency = (_: any, value: any, callback) => {
        if (value) {
            let isOneChar = false
            let isCharStartSpecial = false
            const reg = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            value.forEach(data => {
                if (data.length <= 1) {
                    isOneChar = true
                } else if (reg.test(data.charAt(0))) {
                    isCharStartSpecial = true
                }
            })
            if (isOneChar) {
                callback(`Field Label cant contain any of the following characters ${reg}`)
            } else if (hasLowerCaseAndDuplicates(value)) {
                callback('This entry already exists in the system.')
            } else if (isCharStartSpecial) {
                callback(`Please enter more than 1 characters. and must not contain these characters: ${reg}`)
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    return (
        <Spin spinning={isLoadingCountIncident}>
            {isRedirect ? (<Redirect to="/Setting" />) : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Priority</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Card style={{ overflow: 'auto' }}>
                <Row style={{ minWidth: 550 }} gutter={[12, 12]}>
                    <Row>
                        <Col span={20} xs={24} sm={24} md={20} lg={20}>
                            <h3 className="text-herder-Orenge">Problem Priority</h3>
                            <p className='common-font-topic'>Configure the matrix to automate Problem Priority assignment based on Urgency and Impact.</p>
                        </Col>
                        <Col span={4} xs={24} sm={24} md={4} lg={4}>
                            <Row>
                                <div style={{ float: 'right' }}>
                                    <Form.Item>
                                        <Button type="default" htmlType="button"
                                            style={{ marginRight: 10 }} onClick={() => modalConfirmCancel()}>
                                            Cancel
                                        </Button>
                                        <Button type="primary" htmlType="submit" loading={isLoading}
                                            onClick={handleSubmitTable}>
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <Col span={12} lg={12} md={21} sm={24} xs={24}>
                        <div style={{ width: '95%', backgroundColor: '#F8F8F9', padding: '20px', height: 600, borderRadius: '15px' }}>
                            <Row>
                                <h3 className="sub-title">Priority Setting</h3>
                                <Col span={24}>
                                    <Form.Item label={'Impact'} className='custom-formLabel-wo'>
                                        {getFieldDecorator('Impact', {
                                            rules: [
                                                { required: true, message: 'Impact is required', type: 'array' },
                                                {
                                                    validator: validateImpact
                                                }
                                            ]
                                        })(
                                            <Select mode="tags"
                                                placeholder="Input your impact"
                                                style={{ width: '100%' }}
                                                onChange={(value => onImpactChanged(value))}
                                                disabled={countIncident !== 0}
                                                onFocus={() => setIsRequirePriority(false)}
                                            >
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item label={'Urgency'} className='custom-formLabel-wo'>
                                        {getFieldDecorator('Urgency', {
                                            rules: [
                                                { required: true, message: 'Urgency is required', type: 'array' },
                                                {
                                                    validator: validateUrgency
                                                }
                                            ]
                                        })(
                                            <Select mode="tags"
                                                onChange={onUrgencyChanged}
                                                onFocus={() => setIsRequirePriority(false)}
                                                placeholder="Input your urgency"
                                                disabled={countIncident !== 0}
                                                style={{ width: '100%' }}>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form>
                                        <Form.Item label="Priority" className='custom-formLabel-wo'>
                                            {getFieldDecorator('priority', {
                                                rules: [{
                                                    required: listOfTagPriority.length === 0,
                                                    message: 'Priority is required'
                                                },
                                                { validator: validateTagPriority }
                                                ]
                                            })(<Input placeholder="Input your priority"
                                                style={{ width: '100%' }}
                                                maxLength={15}
                                                disabled={countIncident !== 0}
                                                className={isRequirePriority ? 'inputRequire' : undefined} />)}
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                            {isRequirePriority ? (
                                <p style={{ color: 'red', fontSize: '13px' }}>Priority is required</p>) : null}
                            <Row>
                                <Col span={24} style={{ marginTop: '5px', overflow: 'auto' }}>
                                    <CompactPicker
                                        color={tagColor}
                                        disabled={countIncident !== 0}
                                        onChangeComplete={(color) => setTagColor(color.hex)}
                                        className='custom-compact-picker'
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} style={{ marginTop: '10px', textAlign: 'right' }}>
                                    <Button type="primary" htmlType="submit" onClick={handleAddPriority}
                                        disabled={countIncident !== 0}
                                    ><Icon type='plus' /> Add</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} style={{ marginTop: '10px' }}>
                                    {/* <fieldset style={{ padding: 10, borderRadius: 10, borderColor: '#B3B3B3' }}>
                                        <legend id='my-legend' className={'sub-title'} style={{ paddingLeft: 10, paddingRight: 10 }}> Display Order </legend>
                                        {showTag ? (
                                            renderPriorityTag()
                                        ) : null}
                                    </fieldset> */}
                                    <div className='bold-titleWo'>Display Priority</div>
                                    <div style={{ backgroundColor: '#fff', borderRadius: '15px', height: '100px', padding: 5, marginTop: 10, border: '1px solid #323276' }}>
                                        { showTag ? renderPriorityTag() : null}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={12} className='box-priority' lg={12} md={12} sm={24} xs={24} style={{ padding: 20 }}>
                        <h3 className="sub-title">Priority Matrix</h3>
                        <hr />
                        <Row style={{ textAlign: 'center' }}>
                            <Col span={8}><h3 className={'ant-table-column-title bold-titleWo'}>Impact</h3></Col>
                            <Col span={8}><h3 className={'ant-table-column-title bold-titleWo'}>Urgency</h3></Col>
                            <Col span={8}><h3 className={'ant-table-column-title bold-titleWo'}>Priority</h3></Col>
                        </Row>
                        <hr />
                        {tempPriorityMatrix.length !== 0 ? (
                            tempPriorityMatrix.map((priorityMatrix, index) => {
                                return (
                                    <Row style={{ paddingTop: '5px', borderBottom: '1px solid #D0D5DD' }} key={index}>
                                        <Col span={8}><p style={{
                                            marginLeft: '95px',
                                            marginTop: '10px',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            color: '#475467'
                                        }}>{priorityMatrix.impact?.name}</p></Col>
                                        <Col span={8}><p style={{
                                            marginLeft: '90px',
                                            marginTop: '10px',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            color: '#475467'
                                        }}>{priorityMatrix.urgency?.name}</p>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item style={{ marginLeft: '40px' }}>
                                                {getFieldDecorator(`${priorityMatrix.urgency?.name}priority${priorityMatrix.impact?.name}`, {
                                                    initialValue: priorityMatrix.priority?.name || undefined,
                                                    rules: [{ required: true, message: 'Priority is required' }]
                                                })(
                                                    <Select placeholder={'Select priority'}
                                                        style={{ width: '70%' }}
                                                        onChange={(e) => generateMatrixToCreate(priorityMatrix.impact, priorityMatrix.urgency, e)}
                                                        onFocus={() => setIsRequirePriority(false)}
                                                    >
                                                        {listOfTagPriority.map((it, index) => {
                                                            return <Option value={it.id ? it.id as number : it.name}
                                                                key={index}>{it.name}</Option>
                                                        })}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )
                            })
                        ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )
                        }
                        {/* modal change priority color */}
                        <Modal
                            title="Priority Color"
                            onCancel={() => setShowModal(!showModal)}
                            visible={showModal}
                            width={'400px'}
                            footer={[
                                <Button key="back" onClick={() => setShowModal(!showModal)}>
                                    Cancel
                                </Button>,
                                <Button key="submit" type="primary" onClick={onSubmitChangePriorityColor}>
                                    Save
                                </Button>
                            ]}
                        >
                            <Form onSubmit={onSubmitChangePriorityColor}>
                                <Row style={{ textAlign: 'center', overflow: 'auto' }}>
                                    <CompactPicker
                                        color={tagColor}
                                        className={'customWidth'}
                                        disabled={countIncident !== 0}
                                        onChangeComplete={(color) => setTagColor(color.hex)}
                                    />
                                </Row>
                                <Row style={{ marginTop: '10px', marginLeft: '53px' }}>
                                    <Tag color={tagColor}>{selectedPriorityTag}</Tag>
                                </Row>
                            </Form>
                        </Modal>
                        < hr />
                    </Col>
                </Row>

            </Card>
        </Spin>
    )
}

const PriorityConfigurationPage = Form.create(
    {}
)(PriortyConfigPage)

export default connect(mapStateToProps,
    {
        createPriorityMatrixNewVersionProblem,
        getPriorityConfigurationProblem,
        // getAllIncident,
        getAllPriorityProblem,
        getAllImpactPriorty,
        getAllUrgencyPriorty
    }
)(PriorityConfigurationPage)
