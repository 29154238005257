import { Breadcrumb, Button, Col, Form, Icon, Input, message, Modal, Row, Spin, Tag, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import {Link, useParams} from 'react-router-dom'
import { StoreState } from '../../store'
import { connect } from 'react-redux'
import { UploadFile } from 'antd/lib/upload/interface'
import { UploadLink } from '../../file-server-storage/model'
import exportIcon from '../../common-file/icon-file'
import { DownloadAnyFile, tokenDecode } from '../../common-misc'
import ModalEditTitle from './components/ModalEditTitle'
import ProblemUpdatedForm from './components/ProblemUpdated'
import TabDetailViewProblem from './components/TabDetailViewProblem'
import DisplayListComment from './components/Related/DisplayListComment'
import { Problem, ProblemStatus, PTask, WorkNoteProblems } from './Model'
import { getAllCategory, getAllItems, getAllSubCategory } from '../../incident-management/category'
import { getAllSupportTeamByPermission } from '../../incident-management/support-team'
import { getAllPriorityProblem, getPriorityConfigurationProblem } from '../priority'
import { getAllImpactPriorty } from '../impact'
import { getAllUrgencyPriorty } from '../urgency'
import { getAllCustomFieldProblem } from '../../configurable-fields/problems/state/services'
import { addAllTypeProblems, getAllGeneralProblems } from '../general/states/service'
import { generalProblem, ProblemType } from '../general/states/model'
import { getAllPTask, getAllPTaskById, GetAllStatusProblem, getAllWorknoteByProblemsId, GetProblemById, updateProblem, updatePTaskById } from './service'
import { FormComponentProps } from 'antd/lib/form'
import { useLastLocation } from 'react-router-last-location'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import { ProblemWebSocketChannel } from './components/Web-socket'
import { ProblemCloseWebSocketChannel } from './components/Web-socket/wsClosePTask'
import ReactHtmlParser from 'react-html-parser'

const mapStateToProps = (state: StoreState) => {
    return {
        incident: state.incidentState?.incident,
        priorityMatrixProblems: state.priorityMatrixProblems,
        impactProblem: state.impactProblem,
        urgencieProblem: state.urgencieProblem,
        priorityProblem: state.priorityProblem,
        generalProblems: state.generalProblems,
        categories: state.categories,
        subCategories: state.subCategories,
        items: state.items,
        supportTeamsWithPermission: state.supportTeamsWithPermission,
        customFieldProblems: state.customFieldProblems,
        closeAndLengthPTasks: state.closeAndLengthPTasks
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllCategory: () => Promise<number>
    getAllSubCategory: () => Promise<number>
    getAllItems: () => Promise<number>
    getAllSupportTeamByPermission: () => Promise<number>
    getAllPriorityProblem: () => Promise<number>
    getAllImpactPriorty: () => Promise<number>
    getAllUrgencyPriorty: () => Promise<number>
    getPriorityConfigurationProblem: () => Promise<number>
    getAllCustomFieldProblem: (value: string) => Promise<number>
}

interface Params {
    getPTaskNumber?: Function
}

type Props = StateProps & DispatchProps & FormComponentProps & Params;
const { confirm } = Modal
const ProblemsDetails: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, getFieldValue, resetFields, validateFields } = props.form
    const [isLoading, setIsLoading] = useState(false)
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [isVisibleModalEdit, setIsVisibleModalEdit] = useState<boolean>()
    const [, setTabName] = useState('')
    const [getStatus, setStatus] = useState<ProblemStatus>()
    const [typePTaskProblems, setPTaskTypeProblems] = useState<ProblemType[]>([])
    const [typeProblems, setTypeProblems] = useState<ProblemType>()
    const [allStatus, setAllStatus] = useState<ProblemStatus[]>([])
    // const [myStatus, setMyStatus] = useState<TicketStatusProblems>()
    const [dataWorkNote, setDataWorkNote] = useState<WorkNoteProblems[]>([])
    const [dataProblems, setDataProblems] = useState<Problem>()
    const [, setIsLoadingWorkNote] = useState(false)
    const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false)
    const [isLoadingForButton, setIsLoadingForButton] = useState<boolean>(false)
    const [isRootCause, setIsRootCause] = useState<boolean>(false)
    const [typeAction, setTypeAction] = useState('')
    const [dataPTask, setDataPTask] = useState<PTask[]>()
    const [tempCloseStatus, setTempCloseStatus] = useState<generalProblem>()
    const [idPaskRootCause, setIdPaskRootCause] = useState<string>()
    const [PTaskLength, setPTaskLength] = useState(0)
    const [isReloadWorknote, setIsReloadWorknote] = useState(false)
    const { id }: any = useParams()
    const isRenderPTask = window.location.pathname.includes('/ProblemTaskDetailView')
    const lastLocation = useLastLocation()

    useEffect(() => {
        setIsLoading(true)
        const promiseAll: any[] = []
        promiseAll.push(props.getAllSupportTeamByPermission())
        promiseAll.push(props.getAllPriorityProblem())
        promiseAll.push(props.getAllImpactPriorty())
        promiseAll.push(props.getAllUrgencyPriorty())
        promiseAll.push(props.getPriorityConfigurationProblem())
        promiseAll.push(props.getAllCategory())
        promiseAll.push(props.getAllSubCategory())
        promiseAll.push(props.getAllItems())
        addAllTypeProblems().then((res: ProblemType[]) => {
            if (res) {
                const filterTypyProblems = res.find((p) => { return p.type === 'Problem' && p.active === true })
                setTypeProblems(filterTypyProblems)
                const filterTypyPTask = res.filter((p) => { return p.type === 'PTask' && p.active === true })
                setPTaskTypeProblems(filterTypyPTask)
                const findIdPTaskRootCause = filterTypyPTask.find((data) => { return data.subType === 'Root cause analysis' })
                setIdPaskRootCause(findIdPTaskRootCause?.id)
            }
        })
        Promise.all(promiseAll).catch((err) => {
            message.error(`Unable to retrieve the data. Please try again later. ${err}`)
        }).finally(() => { setIsLoading(false) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        reloadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, props.closeAndLengthPTasks.ClosePTask])

    useEffect(() => {
        if (props.closeAndLengthPTasks.countPTask) {
            setPTaskLength(props.closeAndLengthPTasks.countPTask)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.closeAndLengthPTasks.countPTask])

    useEffect(() => {
        if (isRenderPTask) {
            if (dataProblems && typePTaskProblems) {
                const findPTaskTypes = typePTaskProblems.find((data) => { return data.id?.toString() === dataProblems.pbType?.toString() })
                if (findPTaskTypes?.subType === 'Root cause analysis') {
                    setIsRootCause(true)
                } else {
                    setIsRootCause(false)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataProblems, typePTaskProblems])

    useEffect(() => {
        if (isRenderPTask) {
            if (dataProblems) {
                props.getAllCustomFieldProblem(dataProblems.pbType.toString())
                GetAllStatusProblem(dataProblems.pbType.toString()).then((status) => {
                    setAllStatus(status)
                })
            }
        } else {
            if (typeProblems && typeProblems.id) {
                props.getAllCustomFieldProblem(typeProblems.id)
                GetAllStatusProblem(typeProblems.id!!).then((status) => {
                    setAllStatus(status)
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeProblems, dataProblems])

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png') || ((file.url) as string).includes('jpeg') || ((file.url) as string).includes('PNG')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const handleDownload = (file) => {
        const link = document.createElement('a')
        link.href = file.url
        link.download = file.name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const fileListView = () => {
        const fileList = dataProblems?.pbUpload!!?.map((file: UploadLink) => {
            const uploadFile: UploadFile = {
                uid: file.name,
                name: file.name,
                status: 'done',
                url: file.url,
                type: file.type,
                size: 0,
                thumbUrl: exportIcon(file.type)
            }
            return uploadFile
        })
        return (
            <Upload
                listType="picture"
                className={'upload-list-inline'}
                fileList={fileList}
                onPreview={(e: any) => handlePreview(e)}
                onDownload={(e: any) => handleDownload(e)}
                showUploadList={{ showRemoveIcon: false, showDownloadIcon: true }}
            />
        )
    }

    const getTabName = (data) => {
        setTabName(data)
    }

    const sentStatus = (status) => {
        setStatus(status)
    }

    const ReloadData = (isReload) => {
        if (isReload) {
            setIsLoading(true)
            setDataProblems(undefined)
            if (isRenderPTask) {
                getAllPTaskById(id).then((data) => {
                    if (data) {
                        setDataProblems(data)
                    }
                }).finally(() => { setIsLoading(false) })
            } else {
                GetProblemById(id).then((data) => {
                    if (data) {
                        setDataProblems(data)
                    }
                }).finally(() => { setIsLoading(false) })
            }
        }
    }

    const reloadWorknoteMain = (isReload) => {
        if (isReload) {
            setIsLoadingWorkNote(true)
            getAllWorknoteByProblemsId(id, isRenderPTask).then((workNotes) => {
                if (workNotes) {
                    console.log(workNotes)
                    setDataWorkNote(workNotes)
                }
            }).finally(() => { setIsLoadingWorkNote(false) })
        }
    }

    const handleCancel = () => {
        setIsVisibleModal(true)
        setTypeAction('cancel')
    }

    const onReject = () => {
        setIsVisibleModal(true)
        setTypeAction('reject')
    }

    const onSubmitApprove = () => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm the approval of this data ?',
            onOk() {
                setIsLoading(true)
                const filterStatusNew = allStatus.find((status) => status.status.toLowerCase() === 'root cause analysis')
                const convertData:any = { pbStatus: { id: filterStatusNew?.id }}
                if (dataProblems?.id) {
                    if (isRenderPTask) {
                        updatePTaskById(dataProblems?.id.toString(), convertData).then(() => {
                            setIsLoading(true)
                            getAllPTaskById(id).then((data) => {
                                if (data) {
                                    setDataProblems(data)
                                }
                            }).finally(() => { setIsLoading(false) })
                        }).finally(() => {
                            setIsLoading(false)
                        })
                    } else {
                        updateProblem(dataProblems?.id.toString(), convertData).then(() => {
                            setIsLoading(true)
                            GetProblemById(id).then((data) => {
                                if (data) {
                                    setDataProblems(data)
                                }
                            }).finally(() => { setIsLoading(false) })
                        }).finally(() => {
                            setIsLoading(false)
                        })
                    }
                }
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const onCancel = () => {
        setIsVisibleModal(false)
        resetFields()
    }

    const onApprove = (event: React.MouseEvent<HTMLElement, MouseEvent>, dataProblems?: Problem) => {
        console.log('onApprove', event.currentTarget.id)
        validateFields((error, row) => {
            if (error) {
                return
            }
            setIsLoadingForButton(true)
            const filterStatusNew = allStatus.find((status) => typeAction === 'cancel' ? status.status.toLowerCase() === 'cancel' : status.status.toLowerCase() === 'new')
            let convertData
            if (typeAction === 'cancel') {
                convertData = {
                    cancelReason: getFieldValue('Reason'),
                    pbStatus: { id: filterStatusNew?.id }
                }
            } else {
                convertData = {
                    rejectReason: getFieldValue('Reason'),
                    pbStatus: { id: filterStatusNew?.id }
                }
            }
            if (dataProblems) {
                setIsLoading(true)
                if (dataProblems?.id) {
                    if (isRenderPTask) {
                        updatePTaskById(dataProblems?.id.toString(), convertData).then(() => {
                            resetFields(['Reason'])
                            setIsLoading(true)
                            getAllPTaskById(id).then((data) => {
                                if (data) {
                                    setDataProblems(data)
                                }
                            }).finally(() => { setIsLoading(false) })
                        }).then(() => {
                            message.success('The data has been saved successfully.')
                        }).catch(() => {
                            message.error('Unable to update data. Please try again later.')
                        }).finally(() => {
                            setIsLoading(false)
                            setIsVisibleModal(false)
                            setIsLoadingForButton(false)
                        })
                    } else {
                        updateProblem(dataProblems?.id.toString(), convertData).then(() => {
                            resetFields(['Reason'])
                            setIsLoading(true)
                            GetProblemById(id).then((data) => {
                                if (data) {
                                    setDataProblems(data)
                                }
                            }).finally(() => { setIsLoading(false) })
                        }).then(() => {
                            message.success('The data has been saved successfully.')
                        }).catch(() => {
                            message.error('Unable to update data. Please try again later.')
                        }).finally(() => {
                            setIsLoading(false)
                            setIsVisibleModal(false)
                            setIsLoadingForButton(false)
                        })
                    }
                }
            }
        })
    }

    const getPTask = (data) => {
        setDataPTask(data)
    }

    const reloadData = () => {
        if (id) {
            setIsLoading(true)
            if (isRenderPTask) {
                getAllPTaskById(id).then((result) => {
                    getAllGeneralProblems(result?.pbType?.toString()).then((it) => {
                        setTempCloseStatus(it)
                    })
                    setDataProblems(result)
                    if (props.getPTaskNumber) {
                        props.getPTaskNumber(result.number)
                    }
                })
            } else {
                setIsLoading(true)
                ProblemCloseWebSocketChannel.subscribeClose(tokenDecode()?.sub || '', id.toString())
                ProblemWebSocketChannel.subscribe(tokenDecode()?.sub || '', id.toString())
                GetProblemById(id).then((data) => {
                    if (data) {
                        setDataProblems(data)
                        getAllGeneralProblems(data?.pbType?.toString()).then((it) => {
                            setTempCloseStatus(it)
                        })
                        if (data.viewed === true) {
                            const updateView:any = { viewed: false}
                            updateProblem(id, updateView)
                        }
                        getAllPTask(data?.id?.toString()).then((it2) => {
                            if (it2) {
                                setDataPTask(it2)
                                setPTaskLength(it2.length)
                            }
                        })
                    }
                }).finally(() => { setIsLoading(false) })
            }

            setIsLoadingWorkNote(true)
            getAllWorknoteByProblemsId(id, isRenderPTask).then((workNotes) => {
                if (workNotes) {
                    console.log(workNotes)
                    setDataWorkNote(workNotes)
                }
            }).finally(() => { setIsLoadingWorkNote(false) })
        }
    }

    const forceReload = (data: boolean) => {
        if (data === true) {
            reloadData()
        }
    }

    const getIsReloadWorknote = (data: boolean) => {
        setIsReloadWorknote(data)
        setTimeout(() => {
            setIsReloadWorknote(false)
        }, 1500)
    }

    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    {lastLocation?.pathname === '/ApprovalCenter' ? <Link to="/ApprovalCenter">Approval Center</Link> : <Link to="/ProblemList">Problem Management</Link>}
                </Breadcrumb.Item>
                <Breadcrumb.Item>{dataProblems?.number}</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Spin spinning={isLoading}>
                <Row gutter={[16, 16]}>
                    <Col span={16} xs={24} sm={24} lg={16} xl={16}>
                        <div style={{ backgroundColor: !isRenderPTask ? dataProblems?.pbStatus.status === 'Assess' && lastLocation?.pathname !== '/ApprovalCenter' ? '#FFFAF1' : '#F5F9FF' : '#F5F9FF', borderRadius: '5px', padding: 10 }}>
                            <Row>
                                <Col span={3} className='feck-link'>
                                    <b style={{ fontSize: '20px' }}>{dataProblems?.number}</b>
                                </Col>
                                <Col span={3}>
                                    {/* <div style={{ color: '#323276' }}><b>{dataProblems?.topic}</b></div> */}
                                </Col>
                                <Col span={2}>
                                    {/* <Tag style={{ marginLeft: 10 }} color={dataProblems?.pbPriority.priority?.tagColor}>{dataProblems?.pbPriority.priority?.name}</Tag> */}
                                </Col>
                                <Col span={16} style={{ textAlign: 'end' }}>
                                    {!isRenderPTask ? <div>
                                        {dataProblems?.pbStatus.status === 'Assess' && lastLocation?.pathname === '/ApprovalCenter' ? <div>
                                            <Button type="default" onClick={handleCancel} disabled={!checkRolesState(RoleType.Problem, 'UpdateProblem')}><Icon type="stop" /> Cancal</Button> &nbsp;
                                            <Button type="primary" onClick={onReject} disabled={!checkRolesState(RoleType.Problem, 'UpdateProblem')}><Icon type="close" /> Reject</Button> &nbsp;
                                            <Button style={{ backgroundColor: '#4DC977', color: '#fff', border: '1px solid #4DC977'}} onClick={onSubmitApprove} disabled={!checkRolesState(RoleType.Problem, 'UpdateProblem')}><Icon type="check" /> Approve</Button>
                                        </div> : null}
                                        {dataProblems?.pbStatus.status === 'Assess' && lastLocation?.pathname !== '/ApprovalCenter' ? <Tag color='#ED9E28'>Awaiting Approval</Tag> : null }
                                        {dataProblems?.pbStatus.status !== 'Assess' ? <Button onClick={() => setIsVisibleModalEdit(true)} disabled={!checkRolesState(RoleType.Problem, 'UpdateProblem') || dataProblems?.pbStatus.status === 'Closed' || dataProblems?.pbStatus.status === 'Cancelled'}><Icon type="form" /></Button> : null}
                                    </div> : <div>
                                        <Button onClick={() => setIsVisibleModalEdit(true)} disabled={!checkRolesState(RoleType.Problem, 'UpdateProblem') || dataProblems?.pbStatus.status === 'Closed' || dataProblems?.pbStatus.status === 'Cancelled'}><Icon type="form" /></Button>
                                    </div>}
                                </Col>
                            </Row>
                            <Modal title={<span><b>Edit Topic and Description</b></span>} visible={isVisibleModalEdit} footer={null} onCancel={() => setIsVisibleModalEdit(false)} closable={false}>
                                <ModalEditTitle id={id} problem={dataProblems} setClosed={setIsVisibleModalEdit} reloadData={ReloadData} isRenderPTask={isRenderPTask} visible={isVisibleModalEdit}/>
                            </Modal>
                            <Row style={{ paddingTop: 7 }}>
                                <Col span={12}>
                                    <p style={{ color: '#323276'}}><b>Created</b></p>
                                    <div style={{ color: '#667085' }}><p><b>{dataProblems?.createdDatetime}</b></p></div>
                                </Col>
                                <Col span={12}>
                                    <p style={{ color: '#323276'}}><b>Topic</b></p>
                                    <div style={{ color: '#667085'}}><b>{dataProblems?.topic}</b></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <p style={{ color: '#323276'}}><b>Description</b></p>
                                    <div style={{ color: '#667085' }}><p><b>{ReactHtmlParser((dataProblems?.description || '')?.split('\n').join('<br/>'))}</b></p></div>
                                    <div>
                                        {dataProblems?.pbUpload != null ? (<Row>
                                            {fileListView()}
                                            <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)} >
                                                <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Row>) : null}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <br/>
                        <Modal
                            title={<div style={{ fontSize: '16px', fontWeight: 600}}><Icon type="question-circle" style={{ color: '#FAAD14', fontSize: '18px' }}/>  Are you sure?</div>}
                            visible={isVisibleModal}
                            onCancel={onCancel}
                            closable={false}
                            footer={[
                                <Button key="back" onClick={onCancel}>
                            Cancel
                                </Button>,
                                <Button key="submit" loading={isLoadingForButton} type="primary" onClick={(e) => onApprove(e, dataProblems)}>
                            Submit
                                </Button>
                            ]}>
                            <div style={{ marginLeft: 15 }}>
                                <div><b>Do you confirm {typeAction === 'cancel' ? 'cancel' : 'reject'} of this data ?</b></div>
                                <Form>
                                    <Form.Item label='reason' className='custom-formLabel-wo'>
                                        {getFieldDecorator('Reason', {
                                            rules: [{ required: true, message: 'Approve reason is required.' },
                                                { min: 3, message: 'Please enter more than 3 characters.' }]
                                        })(
                                            <Input.TextArea rows={4} placeholder="Reason" autoSize={{ minRows: 4, maxRows: 4 }} />
                                        )}
                                    </Form.Item>
                                </Form>
                            </div>
                        </Modal>
                        <div>
                            <TabDetailViewProblem getTabName={getTabName} getStatus={getStatus} problem={dataProblems} id={id} reloadWorknoteMain={reloadWorknoteMain} isRenderPTask={isRenderPTask} isRootCause={isRootCause} getPTask={getPTask} PTaskLength={PTaskLength} getIsReloadWorknote={getIsReloadWorknote}/>
                            {/* lopp comment here */}
                            <DisplayListComment data={dataWorkNote} pbNumber={dataProblems?.number} reloadWorknote={reloadWorknoteMain} isRenderPTask={isRenderPTask} isScroll={isReloadWorknote}/>
                        </div>
                    </Col>
                    <Col span={8} xs={24} sm={24} lg={8} xl={8}>
                        <ProblemUpdatedForm sentStatus={sentStatus} problem={dataProblems} allStatus={allStatus} ReloadData={ReloadData} isRenderPTask={isRenderPTask} id={id} dataPTask={dataPTask} tempCloseStatus={tempCloseStatus} idPTypeRootCause={idPaskRootCause} forceReload={forceReload}/>
                    </Col>
                </Row>
            </Spin>
        </div>
    )
}
const ProblemsDetailsForm = Form.create<Props>()(ProblemsDetails)
export default connect(mapStateToProps, {
    getAllCategory,
    getAllSubCategory,
    getAllItems,
    getAllSupportTeamByPermission,
    getAllPriorityProblem,
    getAllImpactPriorty,
    getAllUrgencyPriorty,
    getPriorityConfigurationProblem,
    getAllCustomFieldProblem
})(ProblemsDetailsForm)
